import BaseApi from './BaseApi';
import Api from './Api';
/**
 * Service for user management.
 * 
 * @class
 */
class SocketService extends BaseApi {

    constructor(parentStore) {
        super(null, parentStore);
    }
    /**
     * mapping current user with connection id
     * @param   {number} connection_id  connection id
     */
    subcribeTopic = (connection_id, topics) => {
        return new Promise((resolve, reject) => {
            const api = new Api("web-socket-connections", this.parentStore);
            api.postRequest(
                {
                    connection_id: connection_id,
                    topics: topics
                },
                (response) => {
                    resolve(response?.data);
                },
                (err) => {
                    reject(err);
                }
            );
        });
    };

    unsubcribeTopic = (connection_id, topics) => {
        return new Promise((resolve, reject) => {
            const api = new Api("web-socket-connections", this.parentStore);
            api.patchRequest(
                {
                    connection_id: connection_id,
                    topics: topics
                },
                (response) => {
                    resolve(response?.data);
                },
                (err) => {
                    reject(err);
                }
            );
        });
    };

}

export default SocketService;