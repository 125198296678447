import { Spin } from "antd";
import { inject, observer } from "mobx-react";
import React from "react";
import { TIMEOUT_RESIZE } from "utils/constants";
import AogVolumeBarChart from "./AogVolumeBarChart";
@inject("rootStore")
@observer
class CorridorBarChartView extends React.Component {
  chartLegend = [];
  timeoutResize = null;
  constructor(props) {
    super(props);
    this.state = {
      resizing: false,
    };
  }
  handleResize = () => {
    this.setState({ resizing: true });
    if (this.timeoutResize) clearTimeout(this.timeoutResize);
    this.timeoutResize = setTimeout(() => {
      this.setState({
        resizing: false,
      });
    }, TIMEOUT_RESIZE);
  };
  /**
   * render
   *
   * @return  {Component}
   */

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  render() {
    const {
      rootStore: {
        optimizerStore: {
          corridorOffsetStore: {
            tranformCorridorOffsetData: {
              result: { intersections },
            },
          },
        },
      },
    } = this.props;

    return (
      <div className="w-full h-full bg-background">
        {this.state.resizing ? (
          <div className="w-full h-full flex items-center justify-center">
            <Spin spinning></Spin>
          </div>
        ) : (
          <>
            <div className="chart-title mt-16">By AOG Volume - Raw Count</div>
            <div className="bar-chart-detail">
              <AogVolumeBarChart data={intersections} />
            </div>
            {/* <div className="chart-title">By % AOG</div>
            <AOGPieChart data={intersections} /> */}
          </>
        )}
      </div>
    );
  }
}

export default CorridorBarChartView;
