import BaseApi from './BaseApi';
import Api from './Api';

/**
 * Service for intersections
 * 
 * @class
 */
class JobProgressService extends BaseApi {

    constructor(parentStore) {
        super("intersections", parentStore);
    }
      /**
         * getAllProgressExport
         * @return  {Promise} -  return Promise
         */
    getAllProgressExport = () => {
        return new Promise((resolve, reject) => {
            const api = new Api("task-progresses", this.parentStore);
            api.getRequest(null, (response) => {
                resolve(response?.data)
            }, (response) => {
                reject(response)
            });
        })
    }
        /**
         * downloadIntersectionWithPassword
         * @param   {object} params  parans of cacel what ? 
         * @return  {Promise} -  return Promise
         */
    cancelProgress = (params) => {
        return new Promise((resolve, reject) => {
            const api = new Api("task-progresses/"+params+"/cancel", this.parentStore);
            api.putRequest({},(response) => {
                resolve(response?.data)
            }, (response) => {
                reject(response)
            })
        });
    }
      /**
         * downloadIntersectionWithPassword
         * @param   {object} params  parans of clear what ? 
         * @return  {Promise} -  return Promise
         */
    clearProgress = (params) => {
        return new Promise((resolve, reject) => {
            const api = new Api("task-progresses/"+params, this.parentStore);
            api.deleteRequest((response) => {
                resolve(response?.data)
            }, (response) => {
                reject(response)
            })
        });
    }
}

export default JobProgressService;