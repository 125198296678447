import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Button, Typography, Select, DatePicker, Checkbox } from "antd";
import "../../style.scss";
import { inject, observer } from "mobx-react";
import {
  DASHBOARD_WIDGETS,
  MAP_PERIODS,
  TIME_PERIODS,
} from "../../../../utils/constants/dashboardConstant";
import RightSideBar from "../../../../components/RightSideBar";
import { DATE_FORMAT, TIMESTAMP } from "../../../../utils/constants";
import moment from "moment";

const { Option } = Select;
const { RangePicker } = DatePicker;

/**
 * Component for showing user info pannel
 *
 * @component
 */
@inject("rootStore")
@observer
class NotificationSetting extends Component {
  constructor(props) {
    super(props);
  }

  cancelNotificationSetting = () => {
    const {
      rootStore: {
        dashboardStore: { resetNotificationSetting, toggleWidgetSetting },
      },
      onClose,
    } = this.props;

    resetNotificationSetting && resetNotificationSetting();
    toggleWidgetSetting(false);
    onClose && onClose();
  };

  saveNotificationSetting = () => {
    const {
      rootStore: {
        dashboardStore: { patchDashboardSetting },
      },
      onClose,
    } = this.props;

    patchDashboardSetting &&
      patchDashboardSetting(DASHBOARD_WIDGETS.NOTIFICATION);
    onClose && onClose();
  };

  isValidPeriod = (periodType, timeRange) => {
    const { t } = this.props;
    if (periodType === TIME_PERIODS.CUSTOM) {
      if (timeRange?.length !== 2 || !timeRange[0] || !timeRange[1]) {
        return t("dashboard.time_period_in_valid");
      }
      if (
        moment.unix(timeRange[1].unix()) - moment.unix(timeRange[0].unix()) ===
        0
      ) {
        return t("dashboard.time_in_valid");
      }
      if (timeRange[1] - timeRange[0] >= TIMESTAMP.ONE_DAY) {
        return t("dashboard.time_period_in_valid");
      }
    }
    return "";
  };

  handleNotificationEnable = (type, enabled) => {
    const {
      rootStore: {
        dashboardStore: {
          notificationSetting: { notificationTypes },
          changeNotificationSetting,
        },
      },
    } = this.props;
    changeNotificationSetting({
      notificationTypes: notificationTypes.map((el) => {
        if (el.notification_type === type.notification_type) {
          return { ...el, enabled: enabled };
        }
        return el;
      }),
    });
  };

  /**
   * render
   *
   * @return  {Component}
   */
  render() {
    const {
      t,
      rootStore: {
        dashboardStore: {
          widgetSetting,
          notificationSetting: {
            period,
            timeRange,
            notificationTypes,
            changed,
          },
          changeNotificationSetting,
          savingSetting,
        },
      },
    } = this.props;

    return (
      <RightSideBar
        visible={widgetSetting}
        title={t("dashboard.widget_settings")}
        onClose={this.cancelNotificationSetting}>
        <div className="setting-info-panel">
          <Typography.Paragraph>
            {t("notification.notification_title") +
              " - " +
              t("dashboard.widget_settings_subtitle")}
          </Typography.Paragraph>
          <div className="setting-section">
            <span className="setting-label">{t("map_setting.period")}:</span>
            <Select
              className="setting-select"
              size="small"
              defaultValue={period}
              onChange={(val) => changeNotificationSetting({ period: val })}
              // labelInValue
            >
              {MAP_PERIODS?.map((e) => {
                return (
                  <Option key={e.value} value={e.value}>
                    {t(e.label)}
                  </Option>
                );
              })}
            </Select>
            {period === TIME_PERIODS.CUSTOM && (
              <>
                <RangePicker
                  style={{ width: "100%", marginTop: "0.5rem" }}
                  format={DATE_FORMAT.full}
                  value={timeRange}
                  allowClear={false}
                  onChange={(val) =>
                    changeNotificationSetting({ timeRange: val })
                  }
                  showTime
                />
                <Typography.Text type="danger">
                  {this.isValidPeriod(period, timeRange)}
                </Typography.Text>
              </>
            )}
            <div className="setting-section">
              {t("notification.notification_type")}:
            </div>
            <div className="setting-section">
              {notificationTypes.map((notification) => {
                return (
                  <div
                    key={notification.notification_type.id}
                    className="setting-checkbox">
                    <Checkbox
                      className="w-full"
                      onChange={(event) =>
                        this.handleNotificationEnable(
                          notification,
                          event.target.checked
                        )
                      }
                      checked={notification.enabled}>
                      {t(notification.notification_type.name)}
                    </Checkbox>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="flex justify-end w-full mt-8 setting-footer">
            <Button
              type="default"
              onClick={this.cancelNotificationSetting}
              disabled={savingSetting}>
              {t("cancel")}
            </Button>
            <Button
              type="primary"
              style={{ marginLeft: 5 }}
              onClick={this.saveNotificationSetting}
              loading={savingSetting}
              disabled={!changed || this.isValidPeriod(period, timeRange)}>
              {t("save")}
            </Button>
          </div>
        </div>
      </RightSideBar>
    );
  }
}

export default withTranslation()(NotificationSetting);
