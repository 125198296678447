import UserService from "../services/UserService";
import AgencyService from "../services/AgencyService";
import RoleService from "../services/RoleService";
import BaseStore from "./BaseStore";
import { observable, action, runInAction, toJS } from "mobx";
import i18n from "../i18n";
import { SUPPER_ADMIN_GROUP, RESOURCE_LIST } from "utils/constants";

/**
 * Store for user management.
 *
 * @class
 */
class UserStore extends BaseStore {
  constructor(parentStore) {
    super(new UserService(parentStore), parentStore);
    this.agencyService = new AgencyService(parentStore);
    this.RoleService = new RoleService(parentStore);
    this.filterData = {
      ...this.filterData,
      ...{
        text: "",
        agency_id: "null",
        status: "null",
        sort: "",
      },
    };
    this.defaultItemData = {
      user_id: 0,
      current_role: this.myStore?.getCurrentRoleName(),
      isSuperAdmin: this.commonStore?.isSuperAdmin(),
      roles: [{ role_name: this.myStore?.getCurrentRoleName() }],
    };
    this.resourceName = RESOURCE_LIST.USER;
  }

  //list of all roles
  @observable listAllRoles = [];

  //list of agencies belong to current item in table
  @observable listAllUserAgency = [];

  //list of agencies belong to current item in table
  @observable listNormalRoles = [];

  /**
   * deActiveUser
   * this function deactive users
   * @param   {Array} data  list id of users, default null
   * @return  {null} -
   */
  @action deActiveUser = (data = null) => {
    if (!data) {
      data = this.selectedItems;
    }
    this.loading = true;
    this.moduleService?.updateUsersStatus(
      "/inactive",
      { ids: data },
      () => {
        this.getData();
      },
      () => {
        runInAction(() => {
          this.loading = false;
        });
      }
    );
  };

  /**
   * activeUser
   * this function active users
   * @param   {Array} data  list id of users, default null
   * @return  {null} -
   */
  @action activeUser = (data = null) => {
    if (!data) {
      data = this.selectedItems;
    }
    this.loading = true;
    this.moduleService?.updateUsersStatus(
      "/active",
      { ids: data },
      () => {
        this.getData();
      },
      () => {
        runInAction(() => {
          this.loading = false;
        });
      }
    );
  };

  /**
   * sendActivationEmail
   * this function sends an activation email to an unactivated user
   * @param   {Array} data  list id of users, default null
   * @return  {null} -
   */
  @action sendActivationEmail = (data = null) => {
    if (!data) {
      data = this.selectedItems;
    }
    this.loading = true;
    this.moduleService?.sendActivationEmail(
      { ids: data },
      () => {
        this.getData();
      },
      () => {
        runInAction(() => {
          this.loading = false;
        });
      }
    );
  }

  /**
   * deleteUser
   * this function deletes users
   * @param   {Array} data  list id of users, default null
   * @return  {null} -
   */
  @action deleteUser = (data = null) => {
    if (!data) {
      data = this.selectedItems;
    }
    this.loading = true;
    this.moduleService?.deleteUsers(
      { ids: data },
      () => {
        this.getData();
      },
      () => {
        runInAction(() => {
          this.loading = false;
        });
      }
    );
  };

  /**
   * getAllAgencies
   * get all agencies belong to current users
   *
   * @return {null}
   */
  @action getAllAgencies = () => {
    this.loading = true;
    this.agencyService.fetchDataWithoutParams(
      (items) => {
        runInAction(() => {
          this.loading = false;
          this.listAllUserAgency = items;
          //find default value for agency
          if (Array.isArray(this.listAllUserAgency)) {
            let defaultAgency = this.listAllUserAgency.find(
              (item) => item.id === this.filterData.agency_id
            );
            if (!defaultAgency) {
              this.filterData.agency_id = "null";
            }
          }
        });
      },
      () => {
        runInAction(() => {
          this.loading = false;
        });
      }
    );
  };

  /**
   * getData
   * this function get all data from server with filter value
   * setpage on loading beforload and clean it after loading done
   * after get the data done: update the total item for paging compoment
   *
   * @return  {null} -
   */
  @action getData = (cb) => {
    this.loading = true;
    this.moduleService?.fetchDataWithParams(
      this.filterData,
      (items, totalCount) => {
        runInAction(() => {
          this.loading = false;
          this.listData = items.map((item) => {
            if (
              item.user_id === this.parentStore?.myStore?.currentProfile?.id
            ) {
              item.disable = true;
            }
            return item;
          });
          this.listData.map((item) => item);
          this.filterData.pagination.total = totalCount;
        });
        cb && cb();
      },
      () => {
        runInAction(() => {
          this.loading = false;
        });
      }
    );
  };

  /**
   * getAllRoles
   * get all roles of the application
   * we put roles into caching cuz it's not usually change
   * @param   {fucntion} cb  callback funtion
   *
   * @return  {null}
   */
  @action getAllRoles = (cb) => {
    this.loading = true;
    this.RoleService?.fetchDataWithoutParams(
      (items) => {
        runInAction(() => {
          this.loading = false;
          this.listAllRoles = items;
          this.listNormalRoles = items;
          for (var i = 0; i < this.listNormalRoles.length; i++) {
            if (
              this.listNormalRoles[i] &&
              SUPPER_ADMIN_GROUP.includes(this.listNormalRoles[i].name)
            ) {
              this.listNormalRoles.splice(i, 1);
              i--;
            }
          }
          if (!this.parentStore?.commonStore?.isSuperAdmin()) {
            this.listAllRoles = this.listNormalRoles;
          }
          cb && cb();
        });
      },
      () => {
        runInAction(() => {
          this.loading = false;
        });
      }
    );
  };

  /**
   * tranformDataToFormData
   * @param   {Object} item  user info from UserStore
   * @return  {Object}            Clone of user with isSuperAdmin and current_role
   */
  tranformDataToFormData = (item) => {
    //get the first role of list roles to current role
    if (item.roles.length > 0 && item.roles[0]) {
      item.current_role = item.roles[0].role_name;
    }
    //check is supper admin with current role
    if (item.current_role) {
      item.isSuperAdmin = this.commonStore?.isSuperAdmin(item.current_role);
    }

    return item;
  };

  /**
   * tranformDataBeforeSave
   *
   * @param   {Object} item  item of data before save
   * @return  {Object} item of data after change format
   */
  tranformDataBeforeSave = (item) => {
    //remap roles as array, cuz roles comeout from custom filter is object
    if (!item.roles.length) {
      item.roles = Object.keys(item.roles).map((i) => item.roles[i]);
    }
    //lowcase email before saving
    item.email = item.email?.toLowerCase();
    return item;
  };

  /**
   * handleRoleChange
   *
   * @param   {Object} e  event of add new role action
   * @return  {Object}
   */
  @action handleAddNewRole = (cb) => {
    let roles = this.itemData.roles;
    if (this.listAllUserAgency.length > 0) {
      roles.push({
        agency_name: this.listAllUserAgency[0]?.name,
        agency_id: this.listAllUserAgency[0]?.id,
        role_name: this.itemData.current_role,
      });
    }
    cb && cb(toJS(roles));
  };

  /**
   * handleRoleChange
   *
   * @param   {string} value  key value of new role
   * @return  {Object}
   */
  @action handleRoleChange = (value, cb) => {
    let iscurrentAdmin = this.commonStore?.isSuperAdmin();
    let isAdmin = this.commonStore?.isSuperAdmin(value);
    this.itemData.current_role = value;
    this.itemData.isSuperAdmin = isAdmin;
    if (isAdmin || !iscurrentAdmin) {
      this.itemData.roles = [{ role_name: value }];
    } else {
      this.itemData.roles = [];
    }
    cb && cb(toJS(this.itemData.roles));
  };

  /**
   * handleAgencyChange
   *
   * @param   {Object} e value of new agency
   * @param   {int} index index of agency on list roles
   * @return  {Object}
   */
  @action handleAgencyChange = (value, index, cb) => {
    let roles = this.itemData?.roles;
    let role = roles[index];
    if (role) {
      role.agency_id = value?.key;
      role.agency_name = value?.label;
      cb && cb(toJS(roles));
    }
  };

  /**
   * handleAgencyRoleChange
   *
   * @param   {Object} e  value of new agency
   * @param   {int} index  index of agency on list roles
   * @return  {Object}
   */
  @action handleAgencyRoleChange = (value, index, cb) => {
    let roles = this.itemData?.roles;
    let role = roles[index];
    if (role) {
      role.role_name = value.key;
      cb && cb(toJS(roles));
    }
  };

  /**
   * handleDeleteAgencyRole
   *
   * @param   {int} index  index of agency in list roles
   * @return  {Object}
   */
  @action handleDeleteAgencyRole = (index, cb) => {
    let roles = this.itemData?.roles;

    if (roles && index > -1 && roles[index]) {
      roles.splice(index, 1);
      cb && cb(toJS(roles));
    }
  };

  /**
   * checkRoles
   * custom check role rules
   *
   * @param   {Object} rule  rule info
   * @param   {Object} value  new roles aray
   * @param   {function} callback  callback funtion after validation
   * @return  {Object}
   */
  checkRoles = (rule, data, callback) => {
    let value = Object.keys(data).map((i) => data[i]);
    let user = this.itemData;
    if (!user.current_role) {
      callback(i18n.t("user.role_required"));
    } else if (!user.isSuperAdmin && this.commonStore?.isSuperAdmin()) {
      if (value.length > 0) {
        let listAgencyNames = value.map(function (item) {
          return item.agency_name;
        });
        let isDuplicate = listAgencyNames.some(function (item, idx) {
          return listAgencyNames.indexOf(item) != idx;
        });
        if (isDuplicate) {
          callback(i18n.t("user.role_duplicate_agency"));
        } else {
          return callback();
        }
      } else {
        callback(i18n.t("user.agency_role_required"));
      }
    } else {
      callback();
    }
  };
}

export default UserStore;
