import React, { Component } from "react";
import "./style.scss";

/**
 * Component for Footer Application layout
 *
 * @component
 */
class Footer extends Component {
  render() {
    return <div className="wrap-footer"></div>;
  }
}

export default Footer;
