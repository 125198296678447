import BaseApi from "../../services/BaseApi";
/**
 * Service for user management.
 *
 * @class
 */
class EventHistoryService extends BaseApi {
  constructor(parentStore) {
    super("tsp/events", parentStore);
    this.moduleName = "tsp/events";
    this.dataKey = "tsp_events";
    this.keyField = "id";
  }
}

export default EventHistoryService;
