export default {
  translation: {
    //API messsage
    invalid_data: "Invalid request data",
    bad_request: "Invalid request data",
    api_not_found: "API not found",
    server_error: "Some thing wrong with server.",
    success: "Success",
    update_success: "Update successfully",
    create_success: "Create successfully",
    completed: "Completed",
    in_progress: "In progress",
    error: "Error",
    result: "Result",
    run: "Run",

    show: "Show {{item}}",
    //status
    available: "Available",
    unavailable: "Unavailable",
    active: "Active",
    inactive: "Inactive",
    unactivated: "Unactivated",
    //validate
    field_required: "{{field}} is required!",
    min_value: "Min of {{field}} is {{min}}",
    must_integer: "{{field}} must be integer",
    range_beetwen: "{{field}} is from {{min}} to {{max}}",
    // try to move common translate
    actions: "Actions",
    save: "Save",
    cancel: "Cancel",
    delete: "Delete",
    value: "Value",
    is_required: " is required.",
    all: "All",

    edit: "Edit",
    add_new: "Add new",
    add_template: "Add template",
    with: "with",
    name: "Name",
    module_name: "{{module}} name",
    create_item: "Create new {{item}}",
    edit_item: "Edit {{item}}",
    add_item: "Add {{item}}",
    active_item: "Active {{item}}",
    activate_item: "Activate {{item}}",
    select_item: "Select {{item}}",
    deactive_item: "Deactive {{item}}",
    active_confirm_content: "Are you sure to active this {{item}}",
    deactive_confirm_content: "Are you sure to deactive this {{item}}",
    deactivate_item: "Deactivate {{item}}",
    status: "Status",
    search: "Search",
    speed: "Speed",

    filter_by: "Filter by {{field}}",
    number_of_intersection: "Number of Intersections",
    last_updated_by: "Last Updated By",
    last_updated_at: "Last Updated At",
    created_by: "Created by",
    created_at: "Created at",
    timezone: "Time zone",
    metric_type: "Metric type",

    time_range: "Time range",
    from_time: "From time",
    to_time: "To time",
    from_date: "From date",
    agency_name: "Agency",
    to_date: "To date",
    start_time: "Start time",
    end_time: "End time",
    date: "Date",
    time: "Time",
    agency: "Agency",
    intersection: "Intersection",
    upstream: "Upstream",
    downstream: "Downstream",
    //header
    "header.theme": "Change theme",
    "header.go_spm": "Go To YUTRAFFIC",
    "header.help": "Help",
    "header.notification": "Notification",
    "header.logo_description": "A Siemens Business",
    "localpopup.version": "Version",
    "localpopup.got-it": "Got it",
    //print
    "print.title": "Analysis",
    "print.name": "Intersection",
    "print.time": "Time",
    "print.date": "Date",
    "print.analysis_name": "Analysis",
    "print.site": "yunextraffic.com/global/en/",
    "print.modal-title": "Print Preview",
    "print.header": "YUTRAFFIC INSIGHTS",
    "print.header-reporter": "Reporter",
    "print.footer": "Yunex Traffic | All rights reserved ",
    "print.footer-page": "Page",
    PEDESTRIAN_DELAY: "Delay",
    PEDESTRIAN_ACTIVITIES: "Activities",

    //helper
    "helper.expired_error":
      "The download link has expired, please try to create your export again.",
    //common
    "common.user_key": "User Key",
    "common.generate_key": "Generate New Key",
    "common.get_key": "Get Current Key",
    "common.user_agency": "Agency",
    "common.switch_agency": "Switch Agency",
    "common.password_rule":
      "Passwords must be at least 8 characters long and include 1 uppercase and 1 lowercase alpha character, 1 number and 1 special character. Passwords are case sensitive.",
    "common.please_select_action": "Please select action",
    "common.confirm_redirect_title": "Are you sure want to leave this page?",
    "common.confirm_redirect_content": "Your changed data will be lost.",
    "common.leave": "Leave",
    "common.stay": "Cancel",
    "common.analysis": "Analysis",
    "message.403": "Sorry, you are not authorized to access this page.",
    "message.401": "Your session has timed out. Please login again",
    "message.404": "Sorry, the page you visited does not exist.",
    "message.500": "Sorry, the server is down.",
    "common.back_home": "Home",
    "common.back_login": "Login Again",

    //job progress
    "job_progress.cancel_btn": "Cancel",
    "job_progress.clear_btn": "Clear",
    "job_progress.download_btn": "Download",
    "job_progress.download_progress_created": "Created at",
    "job_progress.header_title": "All job progress",
    "job_progress.buble_btn_drag_tooltip": "Drag to move this button",
    "job_progress.buble_btn_tooltip": "Show all job progress.",
    "job_progress.header_progress_detail_tooltip":
      "The history will be automatically cleaned after 1 day since the created time",
    //profile page
    "profile.page-title": "YUTRAFFIC - Change Pass",
    "profile.title_expire":
      "Your password has been expired. Please input new password",
    "profile.account_settings": "Account settings",
    "profile.name": "Name",
    "profile.email": "Email",
    "profile.change_avatar": "Change Avatar",
    "profile.user_profile": "User profile",
    "profile.current_agency": "Current Agency",
    "profile.confirm_password": "Confirm Password",
    "profile.edit_profile": "Edit Information",
    "profile.save_profile": "Save",
    "profile.default_agency": "Default",
    "profile.user_key": "User Key",
    "profile.agency_role": "Role",
    "profile.display_name_required": "Please input your display name",
    "profile.phone": "Phone",
    "profile.display_name": "Display Name",
    "profile.change_password": "Change Password",
    "profile.switch_agency": "Switch Agency",
    "profile.input_your_password": "Please Input Your Password",
    "profile.image_over_size": "Your image size should less than 4 mb",
    "profile.image_worng_type": "Your image type should be jpg or png",
    "profile.name_required": "Username is required!",
    "profile.phone_required": "Phone number is required!",
    "profile.current_password": "Current password",
    "profile.current_password_required": "Please input your current password",
    "profile.new_password": "Password",
    "profile.new_password_requried": "Please input your new password",
    "profile.confirm_password_requried": "Please confirm your password",
    "profile.userData": "User Data",
    "profile.agency": "Agency",
    "profile.password": "Password",
    "profile.userKey": "User Key",
    "profile.alarm_summary_settings": "Alarm Summary Settings",
    "profile.alarm_enabled": "Alarm summary email",
    "profile.alarm_enabled_opt_in": "Opt-in",
    "profile.alarm_frequency": "Alarm summary frequency",
    "profile.alarm_setting_required": "Alarm summary setting is required!",
    "profile.alarm_frequency_required": "Alarm summary frequency is required!",
    "profile.current_password_placeholder":
      "Please input your current password",
    "profile.new_password_placeholder": "Please input your new password",
    "profile.confirm_password_placeholder": "Please re-type your new password",

    "profile.sent": "Send",
    "profile.cancel": "Cancel",
    "profile.user_key_placholer": "Click link below to get your user key.",
    "profile.language": "Language",
    //access download intersection page
    "access_download.confirm_password_download":
      "Please type access password to download",
    "access_download.link_not_automatic":
      "Click here if your download not be automatic",
    "access_download.download": "Download",
    //login page
    "login.page-title": "YUTRAFFIC - Login",
    "login.email_required":
      "Please input your email in the following format, 'something@example.com'",
    "login.password_required": "Please input your password",
    "login.email_placeholder": "Email",
    "login.password_placeholder": "Password",
    "login.keepMe": "Keep me signed in",
    "login.login": "Log in",
    "login.email": "Email",
    "login.password": "Password",
    "login.title_login": "Log in to your YUTRAFFIC account",
    "login.change_password": "Change Password",
    "login.view_personal_infor": "Personal Information",
    "login.switch_other_agency": "Switch Agency",
    "login.change_password_success":
      "Your password has been successfully changed",
    "login.forgotpass": "Forgot password",
    //otp page
    "otp.page-title": "YUTRAFFIC - Two Factor Authentication",
    "otp.otp_title": "Two Factor Authentication",
    "otp.otp_description": "OTP code has been sent to your email.",
    "otp.otp": "OTP Code",
    "otp.otp_required": "Please enter OTP code",
    "otp.otp_placholder": "Enter your OTP Code",
    "otp.verify": "Verify",
    "otp.re-sent": "Resend OTP Code",
    //forgot,reset password flow
    "forgot_pass.page-title": "YUTRAFFIC - Forgot Pass",
    "forgot_pass.recovery_password_title": "Recover Password",
    "forgot_pass.email": "Email address",
    "forgot_pass.email_required": "Please enter your email.",
    "forgot_pass.email_format": "Please enter email format.",
    "forgot_pass.send": "Please send me the password reset link",
    "forgot_pass.login": "Log in",
    "reset_pass.page-title": "YUTRAFFIC - Reset Pass",
    "reset_pass.pass_inconsistent":
      "The two passwords that you entered are not the same.",
    "reset_pass.password": "Password",
    "reset_pass.requried": "Please enter your password.",
    "reset_pass.confrim_password": "Please re-enter your new password",
    "reset_pass.confirm_requried": "Please enter your new password.",
    "reset_pass.change": "Change",
    "reset_pass.title": "Please input your new password",
    //navigation
    "nav.dashboard": "Dashboard",
    "nav.configuration": "Configuration",
    "nav.user": "User",
    "nav.report": "Report",
    "nav.intersection": "Intersection",

    //analysis page
    "analysis.turning_movement": "Turning Movement Count",
    "analysis.red_light_violation": "Red Light Violation",
    "analysis.hightline_actual": "Only show actual Phase Termination",
    "analysis.gap-data": "Gap data",
    "analysis.print-title": "Print Analysis",
    "analysis.print-tooltip":
      "Please select 'A3' paper size and landscape orientation for printing Analysis.",
    "analysis.perfomance-metric": "Performance Metric",
    "analysis.page-title": "YUTRAFFIC - Performance Metric",
    "analysis.compare_interval": "Comparing interval",
    "analysis.filter_signal": "Filter signal",
    "analysis.phase_name": "Phase",
    "analysis.all_phases": "All Phases",
    "analysis.avg_per_day": "Detector Actuations",
    "analysis.total_aor": "Total AoR",
    "analysis.gap_warning": "Please note, this chart has some data gaps!",
    "analysis.appoarch_warning":
      "Please note, the approach configuration is missing!",
    "analysis.max_out": "Max Out",
    "analysis.phase_skip": "Phase Skip",
    "analysis.gap_out": "Gap Out",
    "analysis.force_off": "Force Off",
    "analysis.pedestrian_call": "Pedestrian Call",
    "analysis.pt": "Phase Termination",
    "analysis.pt_default": "Phase Termination",
    "analysis.pt_highlight": "Phase Termination(Actual)",
    "analysis.cd": "Coordination",
    "analysis.bins": "Volume",
    "analysis.create": "Create",
    "analysis.green": "Green",
    "analysis.point": "Det Act",
    "analysis.red": "Red",

    "analysis.yellow": "Yellow",
    "analysis.signal": "Signal",
    "analysis.aor_percent": "Percentage of AoR",
    "analysis.volume": "Volume",
    "analysis.aor_volume": "AoR Volume",
    "analysis.percent_aor": "Percent AoR for select period",
    "analysis.report": "Analysis",
    "analysis.arrival_on_red": "Arrival On Red",
    "analysis.aor": "Arrival On Red",
    "analysis.aog": "Arrival On Green",
    "analysis.ped": "Pedestrian Delay",
    "analysis.ped_delay": "Pedestrian Delay",
    "analysis.ped_act": "Pedestrian Activities",
    "analysis.PED_DELAY": "Pedestrian Delay",
    "analysis.PED_ACTIVITIES": "Pedestrian Activities",
    "analysis.coord_health": "Coordination Health",
    "analysis.app_delay": "Approach Delay",
    "analysis.moe_analysis": "MOE Analysis",
    "analysis.summary_view": "Summary",
    "analysis.detail_view": "Detail View",
    "analysis.export_in_excel": "Export in Excel",
    "analysis.cycle_length": "CYCLE LENGTH",

    "analysis.moe.approach": "Approach",
    "analysis.moe.force_off": "Force off (Number)",
    "analysis.moe.gap_out": "Gap (Number)",
    "analysis.moe.max_out": "Max (Number)",
    "analysis.moe.actuation": "Actuation",
    "analysis.moe.omits": "Omits",
    "analysis.moe.average_green": "Average Green",
    "analysis.moe.utilization": "Utilization (G/C %)",
    "analysis.moe.min_green": "Min Green",
    "analysis.moe.max_green": "Max Green",
    "analysis.moe.std_dev": "Std Dev",
    "analysis.moe.num_of_pedestrian_calls": "Number of Pedestrian Calls",
    "analysis.moe.95_percent_of_util": "95% of Utilization ",

    //custom_analysis
    custom_analysis: "Custom analysis",
    "custom_analysis.name": "Name",
    "custom_analysis.validate_field_agency": "Agency is required",
    "custom_analysis.validate_field_name": "Custom Analysis name is required!",

    PEDESTRIAN_DELAY_LABEL: "Overview",
    PEDESTRIAN_ACTIVITIES_LABEL: "Detailed Events",

    APPOARCH_DELAY_PHASE_VIEW: "Phase View",
    APPOARCH_DELAY_LANE_VIEW: "Lanes View",
    QUEUE_LENGTH_PHASE_VIEW: "Phase View",
    QUEUE_LENGTH_LANE_VIEW: "Lanes View",

    "analysis.pp": "Preemption/TSP Request",
    PREEMPTION_PRIORITY_REQUEST: "Preempt/TSP Request",
    TSP_PRIORITY_REQUEST: "TSP Request",
    "analysis.preempt_request_tooltip":
      "Preempt Request: the time a request for preemption was received by the controller (event 102: Preempt (Call) Input On) ",
    "analysis.tsp_check_in_list_tooltip":
      "TSP Check-in: the time an advanced detector for the Priority routine was triggered (event 112: TSP Check-in) ",

    "analysis.d_well_time_tooltip":
      "Dwell Time:  the elapsed time during preempt service, dwell interval until exit ",
    "analysis.entry_delay_tooltip":
      "Entry Delay:  the elapsed time from preempt input active until begin entry. ",
    "analysis.track_clear_time_tooltip":
      "Track Clear: the time preemption begin track clearance ",
    "analysis.track_clear_interval_tooltip":
      "Track Clearance Interval: the elapsed time from entry/track interval until dwell start",

    "analysis.max_out_time_tooltip":
      "Max Out: the time the input detector exceeds a programmed threshold ",
    "analysis.exit_time_tooltip": "End Call: the time a preempt call ends ",
    "analysis.tsp_check_in_tooltip":
      "TSP Check-in: the time an advanced detector for the Priority routine was triggered ",
    "analysis.adj_extend_green_tooltip":
      "Adjustment to Extend Green:  the time the controller is adjusting active cycle to accommodate extended service to TSP phases ",

    "analysis.adj_early_green_tooltip":
      "Adjustment to Early Green: the time the controller is adjusting active cycle to accommodate early service to TSP phases ",

    "analysis.tsp_check_out_tooltip":
      "TSP Check-out:  the time an exit detector for the priority routine was triggered. This is typically on the far side of the intersection, opposite the advanced detector ",

    "analysis.preempt_requests": "Preempt Request",
    "analysis.tsp_check_in_list": "TSP checkin",

    PREEMPTION_PRIORITY_TSP_DETAIL: "TSP Details",
    PREEMPTION_PRIORITY_DETAIL: "Preemption Details",
    "analysis.preemption_detail": "Preemption Details",

    "analysis.preempt_number": "Preempt ",

    "analysis.app_delay_phase": "Appoarch Delay (Phase View)",
    "analysis.app_delay_lane": "Appoarch Delay (Lane Vierw)",

    "analysis.from_time": "From time",
    "analysis.from_time_greater_to_time":
      "From time cannot be greater than to time.",
    "analysis.range": "Date Range",
    "analysis.to_time": "To time",
    "analysis.max_time_range_one_hour":
      "The time range cannot be larger than 1 hour",
    "analysis.phase_chart": "Phase Termination",
    "analysis.coordinate": "Coordinate",
    "analysis.coord": "Coordination",
    "analysis.other_paramater": "Other paramater",
    "analysis.volumn_bin_size": "Bin Size",
    "analysis.please_select_intersection": "Please select Intersection",
    "analysis.please_select_analysis": "Please select Analysis type",
    "analysis.please_select_time": "Please select Start and End time",
    "analysis.create_schedule": "Create template",
    "analysis.create_schedule_tooltip": "Create Scheduled Report",

    "analysis.percentage": "Percentage",
    "analysis.split_monitor": "Split Monitor Analysis",
    "analysis.programmed_cycle": "PROGRAMMED CYCLE",
    "analysis.actual_cycle": "ACTUAL CYCLE",
    "analysis.select_a_plan": "Select a plan:",
    "analysis.number_of_cycles": "Number of cycles: ",
    "analysis.min_cycle_length": "Min Cycle Length: ",
    "analysis.max_cycle_length": "Max Cycle Length: ",
    "analysis.number_skipped": "Number of skipped phases: ",
    "analysis.total_time_skipped": "Total time of skipped phases: ",
    "analysis.by_time_range": "By time range",
    "analysis.by_cycle": "By cycle",

    //analysis filter
    "analysis.agencies": "Agency",
    "analysis.gency_required": "Agency is required",
    "analysis.analysis": "Analysis",
    "analysis.analysis_required": "Analysis is required",
    "analysis.intersection": "Intersection",
    "analysis.intersection_required": "Intersection is required",
    "analysis.intersection_placholder": "Please select a intersection",
    "analysis.date": "Date",
    "analysis.date_required": "Date is required",
    "analysis.compare": "Compare same times on two dates",
    "analysis.time": "Time",
    "analysis.from_time_required": "From time is required",
    "analysis.to_time_placeholder": "To time",
    "analysis.from_time_placeholder": "From time",
    "analysis.reset-filter": "Reset filter",
    "analysis.create-analysis": "Create Analysis",
    "analysis.legend_ped_walk":
      "Representing when the pedestrian phase changes to 'Walk' state. Unit: second (phase time)",
    "analysis.legend_ped_clearance":
      "Representing when the pedestrian phase changes to 'Clearance' state. Unit: second (phase time)",
    "analysis.legend_ped-dont-walk":
      "Representing when the pedestrian phase changes to 'Don’t Walk' state. Unit: second (phase time)",
    "analysis.legend_detector_activation":
      "The time moment a pedestrian arrives. Unit: second (phase time)",
    "analysis.legend_ped-delay":
      "The duration in seconds from the moment pedestrians push the button until pedestrian receive a walk indication. Unit: second",
    "analysis.legend_aor_percent":
      "The percentage of AoR versus all vehicles. Unit: % (Percentage)",
    "analysis.legend_aor_volumn":
      "The number of vehicles approaching the intersection in each phases. Unit: VPH (vehicle per hour)",
    "analysis.legend_aor_aor_volumn":
      "The number of vehicles arriving on red phase. Unit: VPH (vehicle per hour)",
    "analysis.legend_pt_gap_out":
      "If a phase is terminated clue to gap out, a green dot is displayed along the line of that phase. Unit: second (phase time)",
    "analysis.legend_pt_max_out":
      "If a phase is terminated due to max out, a red dot is displayed a long the line of that phase. Unit: second (phase time)",
    "analysis.legend_pt_force_off":
      "If a phase is terminated due to force off, a blue (light) dot is displayed a long the line of that phase. Unit: second (phase time)",
    "analysis.legend_pt_programmed_split":
      "As a configuration, the programmed split length is displayed as an gray line. Unit: second (phase time)",
    "analysis.legend_pt_phase_skip":
      "An event for skipped phases at the first second of the next duration, it is displayed as a red or yellow dot",
    "analysis.legend_coord_detector":
      "The time moment a vehicle arrives. Unit: second (phase time)",
    "analysis.legend_coord_aog_volume":
      "The number of vehicles arriving on the green phase. Unit: VPH (vehicle per hour)",
    "analysis.legend_coord_green":
      "Representing when the phase changes to green. Unit: second (phase time)",
    "analysis.legend_coord_yellow":
      "Representing when the phase changes to yellow. Unit: second (phase time)",
    "analysis.legend_coord_red":
      "Representing when the phase changes to red. Unit: second (phase time)",
    "analysis.legend_coord_volumn":
      "The number of vehicles approaching the intersection in each phases. Unit: VPH (vehicle per hour)",
    "analysis.legend_not_available_data": "Not available data",
    "analysis.zoom_explaination": "Click to reset the zoom level.",
    "analysis.legend_aog_aog_volumn":
      "The number of vehicles arriving on green phase. Unit: VPH (vehicle per hour)",
    "analysis.legend_aog_volumn":
      "The number of vehicles approaching the intersection in each phases. Unit: VPH (vehicle per hour)",
    "analysis.legend_aog_percent":
      "The percentage of AoG versus all vehicles. Unit: % (Percentage)",
    "analysis.split": "Split Failure",
    "analysis.legend_in_step":
      "In-step: Percentage of cycles that were in in-step",
    "analysis.legend_transition":
      "Transition: Percentage of cycles that were in transition",
    "analysis.legend_gor":
      "GOR: The detector occupancy during the green time of each cycle. Unit: % (Percentage)",
    "analysis.legend_ror":
      "ROR5: The detector occupancy during the first five seconds of red time of each cycle. Unit: % (Percentage)",
    "analysis.legend_poor_coord": "Poor Coordination: GoR < 60% and RoR5 > 80%",
    "analysis.legend_express_cap":
      "Excess Capacity: Both GoR and RoR5 are less than 60%",
    "analysis.legend_split_failure":
      "Split Failure: Both GoR and RoR5 are greater than 80%",
    "analysis.legend_excellent_green":
      "Excellent Green Utilization: GoR > 80% and RoR5 < 60%",
    "analysis.legend_delay_percent":
      "Approach Delay: the average duration of delay per vehicle during a bin. Unit: second (s)",
    "analysis.legend_volume_app":
      "Count the detector activation during the red phase to the time the phase returns green. Unit: raw count.",
    "analysis.legend_delay_oversaturated":
      "To mark the time moment that occurs the case: oversaturation of approach",
    "analysis.legend_delay_occupied":
      "To mark the time moment that occurs the case: over occupation of detector.",
    "analysis.legend_queue_length":
      "The average queue length at queue-end (back-of-queue, or QE). Unit: Vehicle",
    "analysis.legend_yellow_time": "Yellow Time",
    "analysis.legend_red_time": "Red Time",
    "analysis.legend_green_time": "Green Time",
    "analysis.legend_green_time_coord": "Green Time of Coord Phase",
    "analysis.vol": "Volume",
    "analysis.avg_count_per_hour": "Avg count per hour: ",
    "analysis.avg_count_per_day": "Avg count per day: ",
    "analysis.queue_length": "Queue Length",
    "analysis.queue_length_phase": "Queue Length (Phase View)",
    "analysis.queue_length_lane": "Queue Length (Lane View)",
    //analysis filter map
    "map_popup.title": "Intersections",
    "calendar_popup.title": "Calendar",
    "calendar_popup.matrix_name": "Name of metrics",

    //optimization
    "optimization.page-title": "YUTRAFFIC - Optimizer",
    "optimization.to_time_required": "To time is required",
    "optimization.plan_number_required": "Plan number is required",
    "optimization.num_of_profiles": "Number of profiles",
    "optimization.num_of_profiles_invalid":
      "Number Of Profiles must be from 2-10",
    "optimization.min_profile_duration":
      "Min. duration for a profile (Minutes)",
    "optimization.min_profile_duration_invalid":
      "Duration must be ≥ bin size and ≤  24 hours!",
    "optimization.bin_size": "Bin size",
    "optimization.date_range_invalid": "Date range must be >= 7 days.",
    "optimization.bin_size.900": "15 minutes",
    "optimization.bin_size.3600": "1 hours",
    "optimization.cluster_types": "Cluster type",
    "optimization.cluster_type": "Cluster type",
    "optimization.offset_optimization": "Intersection Offset Optimization",
    "optimization.corridor_offset_optimization": "Corridor Offset Optimization",
    "optimization.historic_time_space": "Historic Timespace",
    "optimization.intersection_name": "Intersection Name",
    "optimization.state": "State: ",
    "optimization.existing_offset": "Existing Offset",
    "optimization.new_offset": "New Offset",
    "optimization.approaches": "Approaches",
    "optimization.upstream": "Upstream",
    "optimization.downstream": "Downstream",
    "optimization.upstream_aog": "Upstream AoG",
    "optimization.upstream_band": "Upstream band",
    "optimization.downstream_band": "Downstream band",
    "optimization.downstream_aog": "Downstream AoG",
    "optimization.total_aog": "Total AoG",
    "optimization.existing": "Existing",
    "optimization.predicted": "Predicted",
    "optimization.predicted_gain": "Predicted Gain",
    "optimization.predicted_loss": "Predicted Loss",
    "optimization.plan_number": "Plan number",
    "optimization.which_approach": "Which approach",
    "optimization.type": "Type",
    "optimization.adjusment_detail": "Adjusment Details",
    "optimization.adjusment_detail_chart": "Adjusment Result",
    "optimization.adjusment_detail_table":
      "AoG Comparison Table (Coordinated Phase)",
    "optimization.view_result": "View result",
    "optimization.table_view": "Table view",
    "optimization.optimization_history": "Advanced Analytics History",
    "optimization.filter_by_optimization_type": "Filter by Optimization Type",
    "optimization.filter_all_optimization_type": "All Optimization Type",
    "optimization.filter_status": "Filter by Status",
    "optimization.all_status": "All Status",
    "optimization.begin_optimize": "Start Advanced Analytics",
    "optimization.reset": "Reset",
    "optimization.is_selected_phase": "Is selected phase",
    "optimization.view_aog_table": "AoG Comparison Table",
    "optimization.is_coord_phase": "Is Coordinated Phase",
    "optimization.created_by": "Created by",
    "optimization.phase": "Phase",
    "optimization.week_days": "Days of week",
    "optimization.disable_delete": "You can not delete this item.",

    "optimization.aog_volume_count": "AoG Volume Count",
    "optimization.offset_print_title": "Offset Optimization Result",
    "optimization.view_adjustment_table": "Max AoG By Seconds",
    "optimization.existing_aog_0": "Existing AoG",
    "optimization.existing_aog": "Existing AoG (Raw Count)",
    "optimization.existing_aog_percent": "Existing AoG (Percent)",
    "optimization.predict_aog": "Predicted AoG (Raw Count)",
    "optimization.predicted_aog_percent": "Predicted AoG (Percent)",
    "optimization.predicted_aog": "Predicted AoG",
    "optimization.offset": "Offset",
    "optimization.phases": "Phase",
    "optimization.bias": "Bias",
    "optimization.intersection_uuid": "Intersection UUID",
    "optimization.optimization_type": "Optimization Type",
    "optimization.start_time": "Start time",
    "optimization.end_time": "End time",
    "optimization.start_date": "Start date",
    "optimization.end_date": "End date",
    "optimization.status": "Status",
    "optimization.created_at": "Created at",
    "optimization.id": "Id",
    "optimization.bias_direction": "Bias Direction",
    "optimization.owner": "Created by",
    DAY_OF_WEEK: "Day of week",
    WEEKDAY_WEEKEND: "Weekday Weekend",
    MAX_MIN: "Max-Min Band",
    DATA_TABLE: "Data Table",
    DETAIL: "Details view",

    //title csv traffic prifile
    "optimization.title_from_time": "From time",
    "optimization.title_to_time": "To time",
    "optimization.title_min": "Min",
    "optimization.title_max": "Max",
    "optimization.title_avg": "AVG",
    "optimization.title_group1_min": "Min. Group 1",
    "optimization.title_group1_max": "Max. Group 1",
    "optimization.title_group1_avg": "Avg. Group 1",
    "optimization.title_group2_min": "Min. Group 2",
    "optimization.title_group2_max": "Max. Group 2",
    "optimization.title_group2_avg": "Avg. Group 2",
    "optimization.title_cluster": "Profile",
    "optimization.title_group1": "Group 1",
    "optimization.title_group2": "Group 2",
    "optimization.title_Date": "Date",
    "optimization.title_traffic_profile": "Traffic Profile Result",
    "optimization.title_cluster_details":
      "Cluster Details,,,Unit: % green demand",
    "optimization.title_green-demand":
      "Green Demand - Data Table,,Unit: % green demand",
    "optimization.title_nameCSV": "TrafficProfile_ExportedTemplate",
    //traffic profile
    "optimization.profile": "Profile",
    "optimization.traffic_profile": "Traffic Profile",
    "optimization.title_day": "",

    //base table
    "table.selected": "Selected ",
    "table.items_in": " item(s) in ",
    "table.total": " Total ",
    "table.item": " item(s)",
    "table.actions": "Action(s)",
    "table.search": "Search",
    "table.create": "Create",

    //historic time space
    "optimization.show_programmed": "Show programmed",

    "optimization.historic_timespace": "Historic Time Space",
    "optimization.timespace.PROGRAMMED": "Programmed Time Space",
    "optimization.timespace.ACTUAL": "Actual Time Space",
    "optimization.timespace_line": "timespace line",
    "optimization.greenwave": "Greenwave",
    "optimization.the_time": "the time",

    //offset optimization
    "optimization.offset.BAR": "Bar Chart View",
    "optimization.offset.CHART": "Chart View",
    "optimization.offset.PIE": "Pie Chart View",
    "optimization.direction": "Direction",

    //detail
    "detail.save": "Save",
    "detail.next": "Next",
    "detail.back": "Back",
    "detail.cancel": "Cancel",

    //user table
    "user.page-title": "YUTRAFFIC - Users",
    "user.create": "Create New User",
    "user.username": "User Name",
    "user.name": "Name",
    "user.email": "Email",
    "user.phone": "Phone",
    "user.agencies": "Agencies",
    "user.status": "Status",
    "user.search_placholer": "Search by Email, Name, Phone",
    "user.all_agencies": "All Agencies",
    "user.all_status": "All Status",
    "user.active": "Active",
    "user.in_active": "Inactive",
    "user.select_action": "Select an action(s)",
    "user.active_all": "Activate all",
    "user.deactive_all": "Deactivate all",
    "user.deactive": "Deactivate",
    "user.delete": "Delete",
    "user.edit": "Edit",
    "user.cancel": "Cancel",
    "user.yes": "Yes",
    "user.inactive_user_confirm_title": "Deactivate User",
    "user.inactive_user_confirm_content":
      "Are you sure you want to deactivate this user(s)?",
    "user.active_user_confirm_title": "Activate User",
    "user.active_user_confirm_content":
      "Are you sure you want to activate this user(s)?",
    "user.delete_user_confirm_title": "Delete User",
    "user.delete_user_confirm_content":
      "Are you sure you want to delete this user(s)?",
    "user.roles": "Roles",
    "user.agency": "Agency",
    "user.role": "Role",
    "user.show_form": "User Detail",
    "user.edit_form_tile": "Edit User Infomation",
    "user.add_agency": "Assign an Agency",
    "user.role_duplicate_agency": "A user can have only one role per Agency",
    "user.role_required": "Role is required",
    "user.username_placeholder": "Plese input user name",
    "user.name_placeholder": "Please input user name",
    "user.email_placeholder": "Please input email",
    "user.phone_placeholder": "Please input phone",
    "user.create_form_title": "Create New User",
    "user.username_required": "The user name is required",
    "user.name_required": "Name is required",
    "user.email_required": "Email is required",
    "user.phone_required": "Phone is required",
    "user.email_format":
      "Please input email in the following format, 'something@example.com'",
    "user.agency_role_required": "This role must be assingned to an agency.",
    "users.search_tooltip": "Search by Name, Email, Phone. ",
    "users.agency_tooltip": "Filter by Agency",
    "users.status_tooltip": "Filter by Status",
    // Usertab
    user_management: "User Management",
    "user.add_user": "Add user",
    "user.add_user_form_title": "Add New User",
    "user.remove_all": "Remove all",
    "user.remove": "Remove",
    "user.remove_user_confirm_title": "Remove user",
    "user.remove_user_confirm_content": "Do you want to remove this user(s)?",

    //agency table
    agency_management: "Agency Management",
    "agencies.create": "Create New Agency",
    "agencies.show_form": "Agency Detail",
    "agency.api-key": "DataHub API Key",
    "agency.page-title": "YUTRAFFIC - Agencies",
    "agency.matrix_title":
      "Input criteria and corresponding colors for each metric in the dropdown list.  Each metic can have up to 5 levels and the value entered is the lower bound of that level.",
    "agency.matrix-unit-none": "None",
    "agency.timezone": "Agency time zone",
    "agency.toggle-intersection": "Show N/A dashboard data",
    "agency.time-zone-placeholder": "Agency time zone",
    "agency.id": "Agency ID",
    "agency.name": "Agency Name",
    "agency.name_required": "The agency name is required",
    "agency.name_placeholder": "input agency name",
    "agency.email": "Email",
    "agency.email_format": "Invalid email format",
    "agency.email_placeholder": "input email",
    "agency.phone": "Phone",
    "agency.phone_placeholder": "input phone",
    "agency.organization": "Organization",
    "agency.organization_placeholder": "input organization",
    "agency.address": "Address",
    "agency.address_placeholder": "input address",
    "agency.agencies": "Agencies",
    "agency.status": "Status",
    "agency.search_placholer": "Search...",
    "agency.all_agencies": "All Agencies",
    "agency.all_status": "All Status",
    "agency.max_crawl_fails": "Configured interval",
    "agency.active": "Active",
    "agency.in_active": "Inactive",
    "agency.select_action": "Select an action(s)",
    "agency.active_all": "Activate all",
    "agency.deactive_all": "Deactivate all",
    "agency.deactive": "Deactivate",
    "agency.delete": "Delete",
    "agency.edit": "Edit",
    "agency.cancel": "Cancel",
    "agency.yes": "Yes",
    "agency.inactive_agency_confirm_title": "Deactivate Agency",
    "agency.inactive_agency_confirm_content":
      "Do you want to denactivate this agency(s)?",
    "agency.active_agency_confirm_title": "Activate Agency",
    "agency.active_agency_confirm_content":
      "Do you want to activate this agency(s)?",
    "agency.delete_agency_confirm_title": "Delete Agency",
    "agency.delete_agency_confirm_content":
      "All information (intersections, reports, alarms, optimizations and users) relating to the selected agency will be deleted, excluding user information belonging to multiple agencies. \nAre you sure you want to delete this agency?",
    "agencies.create_form_title": "Create New Agency",
    "agency.search_tooltip": "Search by Agency Name, Address",
    "agency.status_tooltip": "Filter by Status",
    // Agency detail
    "agencydetail.general_tab": "General",
    "agency.alarm_category": "Alarm category",
    "agencydetail.users_tab": "Users",
    "agencydetail.intersections_tab": "Intersections",
    "agency.edit_page": "Edit Agency",
    "agency.save": "Save",
    "agencydetail.setting_tab": "Settings",
    "agency.matrix": "Define levels of following metrics",
    "agency.center-point": "Choosing center point",
    "agency.unit": "Metric unit",
    "agency.unit_matrix_placeholder": "Mertrix unit",
    "agency.matrix-item": "Level",
    "agency.map_search": "Search places...",
    "agency.matrix-item-default": "Default",
    "agency.add-metrix": "Add New",
    "agency.duplicate-matrix-value":
      "Duplicate level(s) value or level(s) color.",
    "agency.location-info": "Location Information",
    "agency.latitude": "Latitude",
    "agency.longitude": "Longitude",
    "agency.set_saturation_label": "Set the saturation flow value (Unit: VPH)",
    "agency.straight_movement": "Straight movement",
    "agency.left_movement": "Left movement",
    "agency.right_movement": "Right movement",
    "agency.straight_left_movement": "Straight + Left movement",
    "agency.straight_right_movement": "Straight + Right movement",
    "agencydetail.license_tab": "License",

    "alarm_helper.title":
      "Below is details of alarm parameters in the YUTRAFFIC Insights.",
    "alarm_helper.table_title":
      "Metrics, Units of Measure and Target (Phase, Detector number) available for each.",
    "alarm_helper.no": "No.",
    "alarm_helper.name": "Name",
    "alarm_helper.unit": "Unit",
    "alarm_helper.target": "Target",
    "alarm_helper.unit_title": "The following units will be used:",
    "alarm_helper.list_unit_raw_count":
      "-	Raw count (RC): Count number of events happened during a sampling period",
    "alarm_helper.list_unit_vehicle_hour":
      "-	Vehicle per hour (VPH):  RC/number of sampling periods",
    "alarm_helper.list_unit_percentage":
      "-	Percentage (%): | min value = 0 | max value = 100",
    "alarm_helper.list_unit_Hour":
      "-	Hour: Number of hours to evaluate whenever SiWave does not receive the traffic data. It’s used to the metric “No Data.”",
    "alarm_helper.list_unit_second":
      "-	Second:  It is used to two metrics “Max. Ped Delay” & “Avg. Ped Delay”",
    "alarm_helper.list_unit_vehicle":
      "-	Vehicle: Number of vehicles. Be used to metric: Queue Length",
    "alarm_helper.table_name_detector": "Detector Actuation (DA)",
    "alarm_helper.table_name_phase_calls": "Phase Calls",
    "alarm_helper.table_name_ped_phase_calls": "Ped Phase Calls",
    "alarm_helper.table_name_gap_out": "Gap Out (GO)",
    "alarm_helper.table_name_max_out": "Max Out (MO)",
    "alarm_helper.table_name_force": "Force Off (FO)",
    "alarm_helper.table_name_arrival_green": "Arrival on Green (AoG)",
    "alarm_helper.table_name_arrival_yellow": "Arrival on Yellow (AoY)",
    "alarm_helper.table_name_arrival_red": "Arrival on Red (AoR)",
    "alarm_helper.table_name_max_ped_delay": "Max. Ped Delay",
    "alarm_helper.table_name_avg_ped_delay": "Avg. Ped Delay",
    "alarm_helper.table_name_no_data": "No Data",
    "alarm_helper.table_name_transition": "% Transition",
    "alarm_helper.table_name_max_gor": "Max. % GOR",
    "alarm_helper.table_name_avg_gor": "Avg. % GOR",
    "alarm_helper.table_name_max_ror5": "Max. % ROR5",
    "alarm_helper.table_name_avg_ror5": "Avg. % ROR5",
    "alarm_helper.table_name_split": "Split failure",
    "alarm_helper.table_name_poor": "Poor Coordination",
    "alarm_helper.table_name_excess": "Excess capacity",
    "alarm_helper.table_name_max_approach_delay": "Max. Approach delay",
    "alarm_helper.table_name_avg_approach_delay": "Avg. Approach delay",
    "alarm_helper.table_name_max_queue_length": "Max. Queue length",
    "alarm_helper.table_name_avg_queue_length": "Avg. Queue length",
    "alarm_helper.table_unit_detector": "Raw count (RC)/ VPH",
    "alarm_helper.table_unit_detector_percent": "Raw count (RC)/ VPH/ %",
    "alarm_helper.table_target_detector": "Detector/All",
    "alarm_helper.table_unit_raw_count": "Raw count (RC)",
    "alarm_helper.table_unit_second": "Second",
    "alarm_helper.table_unit_hours": "Hours",
    "alarm_helper.table_unit_vehicle": "Vehicle",
    "alarm_helper.table_target_phase_all": "Phase/All",
    "alarm_helper.table_target_percent": "%",
    "alarm_helper.table_target_all": "All",
    //alarm rule mng

    "alarm_rules.select_all_intersection": "Select all intersections",
    "alarm_rules.select_specific_intersection": "Select specific intersection",
    "alarm_rules_sub.create_form_title": "Add more subscribers",
    "alarm_rules_sub.create": "Add more",
    "alarm_rules_inter.create_form_title": "Add more intersections",
    "alarm_rules_inter.create": "Add more",
    "alarm_rules.filter_by_weekday": "Filter by Days of week.",
    "alarm_rules.weekdays": "Weekdays",
    "alarm_rules.page-title": "YUTRAFFIC - Alarm Rules",
    "alarm_rules.search_tooltip": "Search",
    "alarm_rules.search_inter_tooltip": "Search by name",
    "alarm_rules.search_sub_tooltip": "Search by name, email",
    "alarm_rules.add_subscribers": "Add subscribers",
    "alarm_rules.add_intersections": "Add intersections",
    "alarm_rules.name": "Rule name",
    "alarm_rules.save": "Save",
    "alarm_rules.next_step": "Next step",
    "alarm_rules.back": "Back",
    "alarm_rules.name_required": "Alarm rule name is required",
    "alarm_rules.intersection_empty":
      "Alarm rule intersection list should not be empty",
    "alarm_rules.status_required": "Status is required",
    "alarm_rules.description_required": "Description is required",
    "alarm_rules.category_required": "Category is required",
    "alarm_rule.time_range_invalid": "Time range is invalid",
    "alarm_rules.analysis_required": "Analysis is required",
    "alarm_rules.show_form": "Alarm rule detail",
    "alarm_rules.alarm_rules": "Alarm Rules",
    "alarm_rules.filter_text": "Filter by Name",
    "alarm_rules.created": "Created at",
    "alarm_rules.create_rule_title": "Create Alarm Rule",
    "alarm_rules.edit_rule_title": "Edit Alarm Rule",
    "alarm_rules.week_days_required": "Week days is required",
    "alarm_rules.start_required": "Start time is required",
    "alarm_rules.over_night": "(Over night)",
    "alarm_rules.duration_required": "Duration time is required",
    "alarm_rules.end_required": "End time is required",
    "alarm_rules.sampling_period": "Sample period",
    "alarm_rules.select_day": "Select days",
    "alarm_rules.agency_required": "Agency is required",
    "alarm_rules.start": "Start time",
    "alarm_rules.timezone": "Timezone",
    "alarm_rules.duration": "Select duration (hour)",
    "alarm_rules.end": "End time",
    "alarm_rules.delete_confirm_title": "Remove item(s)",
    "alarm_rules.delete_confirm_content": "Do you want to remove this item(s)?",
    "alarm_rules.subscribe_confirm_title": "Subscribe Rule",
    "alarm_rules.subscribe_email_confirm_title": "Subscribe Rule",
    "alarm_rules.subscribe_confirm_content":
      "Do you want to subscribe this rule(s)?",
    "alarm_rules.subscribe_email_confirm_content":
      "Do you want to subscribe email this rule(s)?",
    "alarm_rules.unsubscribe_confirm_title": "Unsubscribe Rule",
    "alarm_rules.unsubscribe_email_confirm_title": "Unsubscribe email",
    "alarm_rules.unsubscribe_confirm_content":
      "Do you want to unsubscribe this rule(s)?",
    "alarm_rules.unsubscribe_email_confirm_content":
      "Do you want to unsubscribe email this rule(s)?",
    "alarm_rules.filter_analysis": "Filter by Analysis type",
    "alarm_rules.filter_date": "Filter by Created time",
    "alarm_rules.filter_owner": "Filter by Owner",
    "alarm_rules.filter_category": "Filter by Category",
    "alarm_rules.filter_agency": "Filter by Agency",
    "alarm_rules.filter_status": "Filter by Status",
    "alarm_rules.analysis_id": "Analysis type",
    "alarm_rules.created_at": "Created at",
    "alarm_rules.status": "Status",
    "alarm_rules.trigger": "Trigger",
    "alarm_rules.duplicate": "Duplicate",
    "alarm_rules.actions": "Actions",
    "alarm_rules.search_placholer": "Search",
    "alarm_rules.all_status": "All Status",
    "alarm_rules.active": "Active",
    "alarm_rules.deactive": "Deactivate",
    "alarm_rules.inactive": "Inactive",
    "alarm_rules.edit": "Edit",
    "alarm_rules.create_form_title": "Alarm Rule Detail",
    "alarm_rules.edit_form_title": "Edit Alarm Rule Detail",
    "alarm_rules.active_confirm_title": "Active alarm rule",
    "alarm_rules.inactive_confirm_title": "Deactivate alarm rule",
    "alarm_rules.active_confirm_content": "Are you sure to activate this rule?",
    "alarm_rules.inactive_confirm_content":
      "Are you sure to deactivate this rule?",
    "alarm_rules.cancel": "Cancel",
    "alarm_rules.triggered_alarm": "Triggered",
    "alarm_rules.yes": "Yes",
    "alarm_rules.remove": "Remove",
    "alarm_rules.remove_all": "Remove All",
    "alarm_rules.create": "Create Alarm Rule",
    "alarm_rules.hours": "Hours",
    "alarm_rules.rule_condition": "Condition",
    "alarm_rules.condition_required": "Rule condition is required",
    "rules.create": "Create Alarm Rule",
    "alarm_rules.add_user": "Add User",
    "alarm_rules.add_intersection": "Add Intersection",
    "alarm_rules.all_analysis_type": "All Analysis Types",
    "alarm_rules.analysis_type.arrival_on_red": "Arrival on Red",
    "alarm_rules.analysis_type.phase_termination": "Phase Termination",
    "alarm_rules.analysis_type.coordination": "Coordination",
    "alarm_rules.all_categories": "All Categories",
    "alarm_rules.category": "Category",
    "alarm_rules.all_owner": "All Owners",
    "alarm_rules.owner": "Owner",
    "alarm_rules.all_intersection": "All Intersections",
    "alarm_rules.delete": "Delete",
    "alarm_rules.agency_id": "Agency",
    "alarm_rules.agency_placeholder": "Select Agency",
    "alarm_rules.active_all": "Activate all",
    "alarm_rules.deactive_all": "Deactivate all",
    "alarm_rules.subscribe_all": "Subscribe all",
    "alarm_rules.subscribe_email_all": "Subscribe email all",
    "alarm_rules.unsubscribe_all": "Unsubscribe all",
    "alarm_rules.unsubscribe_email_all": "Unsubscribe email all",
    "alarm_rules.subscribe": "Subscribe",
    "alarm_rules.subscribe_email": "Subscribe email",
    "alarm_rules.need_subscibe_first": "You need subscibe first",
    "alarm_rules.unsubscribe": "Unsubscribe",
    "alarm_rules.unsubscribe_email": "Unsubscribe email",
    "alarm_rules.subscribers": "Subscribers",
    "alarm_rules.delete_all": "Delete all",
    "alarm_rules.undefined": "Undefined",
    "alarm_rules.intersections": "Intersections",
    "alarm_rules.name_placeholder": "Please input a Name",
    "alarm_rules.description_placeholder": "Type description",
    "alarm_rules.analysis_placeholder": "Select Analysis",
    "alarm_rules.category_placeholder": "Select Category",
    "alarm_rules.status_placeholder": "Select Status",
    "alarm_rules.show_condition_info":
      "More details about the alarm parameters",
    "alarm_rules.run_alarm_rule_manually": "Run alarm rule manually",
    "alarm_rules.select_date_trigger_alarm_rule":
      "This function allows you to manually trigger an alarm rule on a specific date." +
      " Please select a date to trigger alarm: {{ name }}",
    "alarm_rules.time_zone": "Time Zone",

    //traffic metric
    "traffic_metric.arrival_on_red": "Arrival on Red",
    "traffic_metric.percent_arrival_on_red": "Percent Arrival on Red",
    "traffic_metric.total_vehicle": "Total vehicle",
    "traffic_metric.number_of_gap_out": "Number of gap out",
    "traffic_metric.number_of_max_out": "Number of max out",
    "traffic_metric.number_of_force_off": "Number of force off",
    "traffic_metric.number_of_unknown_termination_cause":
      "Unknown termination cause",
    "traffic_metric.number_of_ped_activity": "Number of ped activity",
    "traffic_metric.arrival_on_green": "Arrival on Green",
    "traffic_metric.green_time": "Green time",
    "traffic_metric.platoon_ratio": "Platoon ratio",

    // intersection
    "intersection.label_list_all_intersection": "Available Intersections",
    "intersection.label_list_selected_intersection": "Selected Intersections",
    "intersection.page-title": "YUTRAFFIC - Intersections",
    "intersection.select_category_event": "Group event",
    "intersection.select_event": "Event",
    "intersection.enter_params_type": "Select {{value}}:",
    "intersection.param_type": "Params type",
    "intersection.filter_label": "Filter the perflog by:",
    "intersection.add_filter": "Add filter",
    "intersection.remove_all_filters": "Remove all filters",
    "intersection.params_value": "Params values",
    "intersection.you_are_select_all_event":
      "You are exporting with all events.",
    "intersection.all_event_category": "All events",
    "intersection.deviation": "Deviation",
    //intersection progress detail
    "intersection.download_btn": "Download",
    "intersection.clear_btn": "Clear",
    "intersection.cancel_btn": "Cancel",
    "intersection.coppy_btn": "Copy from another",
    "intersection.download_raw_data_title": "Raw Data",
    "intersection.time_range": "Select time range to export data",
    "intersection.time_equal": "From time and to time must not be equal",
    "intersection.cancel": "Cancel",
    "intersection.export": "Export to CSV",
    "intersection.download_raw": "Download Data",
    "intersection.download_done":
      "We have received your download request. We will send an email with a link to download once the request is completed. This box will close automatically after 3 seconds.",
    "intersection.time_range_over": "Your time range is over 30 days",
    "intersection.id": "Intersection ID",
    "intersection.number": "Number",
    "intersection.name": "Intersection Name",
    "intersection.status": "Status",
    "intersection.address": "Address",
    "intersection.model": "Model",
    "intersection.version": "Version",
    "intersection.agencies": "Agencies",
    "intersection.updated_time": "Updated time",
    "intersection.perflog_updated_time": "Perflog updated time",
    "intersection.search_placholer": "Search...",
    "intersection.all_agencies": "All Agencies",
    "intersection.all_status": "All Status",
    "intersection.detail": "Intersection Detail",
    "intersection.search_tooltip": "Search by Intersection Id, Name",
    "intersection.new_param": "New param",
    intersection_management: "Intersections",
    "intersection.list": "List",
    "intersection.map": "Map",
    "intersection.show_form": "Intersection Detail",
    "intersection.agency_tooltip": "Filter by Agency",
    "intersection.status_tooltip": "Filter by Status",
    "intersection.signal_id": "Signal ID",
    "intersection.reports": "Availables Reports",
    "intersection.goto": "Go to",
    "intersection.download_progress_created": "Created at",
    "intersection.reload_btn": "Reload",
    "intersection.show_progress_btn": "Export Data History",
    "intersection.download_progress_title": "Export Data History",
    "intersection.list_selected_event": "List of selected events",
    // for intersection tab
    "intersection_tab.uuid": "Intersection UUID",
    "intersection_tab.name": "Intersection Name",
    "intersection_tab.status": "Status",
    "intersection_tab.address": "Address",
    "intersection_tab.updated_time": "Updated time",
    "intersection_tab.search_placholer": "Search...",
    "intersection_tab.add": "Add",
    "intersection_tab.remove": "Remove",
    "intersection_tab.add-all": "Add all",
    "intersection_tab.remove-all": "Remove all",

    export_json: "Export to JSON",
    //intersection config:
    "intersection_config.uuid_config_topology": "Intersection UUID",
    "intersection_config.page_title":
      "This is a list of intersections whose topology is defined in Insights. Click Copy to clone the topology configuration of an intersection",
    "intersection_config.name_config_topology": "Intersection Name",
    "intersection_config.config_history_topology": "Choose intersection",
    "intersection_config.uuid": "Config ID",
    "intersection_config.config_history": "Configuration History",
    "intersection_config.update_time": "Update time",
    "intersection_config.see_another_config": "See history configurations",
    "intersection_config.valid_period": "Valid period",
    "intersection_config.general_info": "General Information",
    "intersection_config.cycle_length": "Cycle Length",
    "intersection_config.offset_time": "Offset Time",
    "intersection_config.sequense_number": "Sequence Number",
    "intersection_config.split_mode": "Split Mode",
    "intersection_config.coord_data": "Coord Data",
    "intersection_config.ring_structure": "Ring Structure",
    "intersection_config.detector": "Detector",
    "intersection_config.detectordescription":
      "Lists the phase number that will be called by each detector. The index of the outer array is the detector number, the numbers stored in the nested array are the phases that detector will call.",
    "intersection_config.valid_from_time": "Valid from time",
    "intersection_config.valid_to_time": "Valid to time",
    "intersection_config.show": "Show this config",
    "intersection_config.copy": "Copy this config",
    "intersection_config.filter_pattern": "Filter by pattern number",
    "intersection_config.filter_range_valid_text":
      "Filter by valid date ranges",
    "intersection.config_edit": "Show intersection config",
    "intersection.date_written": "Date written",
    "intersection.time_written": "Time written",
    "intersection.timezone": "Timezone",
    "intersection_config.topology_switch":
      "Using Insights Intersection Topology",
    "intersection_config.refer_other_config": "Refer to other config",
    "intersection_config.topology_info":
      "The intersection topology provided by Insights, is not specified for a particular configuration. It is also used to calculate relavant data in the analysis and reports!",
    "intersection_config.topology_mismatch":
      "Missing detector and phase information in intersection topology compare to Config ID: {{config_id}}",
    "intersection_config.cycle_length_description":
      "Lists the length of the cycle in seconds for each pattern, with the index of the array being the pattern number.",
    "intersection_config.offset_time_description":
      "Lists by how many seconds the local time zero shall lag the system time zero for each patter, with the index of the array being the pattern number.",
    "intersection_config.sequence_number_description":
      "Lists which sequence each pattern will use, with the index of the array being the pattern number. A value of '0', denotes default sequence.",
    "intersection_config.split_mode_description":
      "Lists the time in seconds the split or phase is allowed receive when constant demands exist on all phases. The index of the outer array is the pattern number, and index of the nested array is the time in seconds for each phase & Lists the mode for each split or phase for each pattern",
    "intersection_config.coord_data_description":
      "Lists whether or not a phase is coordinated (denoted by '1'). The index of the outer array is the pattern number, and index of the nested array is coordinated flag for each phase.",
    "intersection_config.initial_state_description":
      "Defines the initial state of each phase.",
    "intersection_config.sequence_config_description":
      "Lists each sequence configuration. The index of the outer array is the sequence number. The nested arrays within the sequence are each ring. The ring array stores the order of phases that the controller will run.",
    "intersection_config.default_sequence_des":
      "Default Sequence (Stores which sequence is the default sequence of the controller) ",
    //topology
    "intersection_config.assign_detector": "Assign detector",
    "intersection_config.add_movement": "Add new Movement",
    "intersection_config.insights_topology": "Insights Topology",
    "intersection_config.overlapse": "This field is overlapse!",
    "intersection_config.required": "This field is required!",
    "intersection_config.invalid": "This field is invalid!",
    "intersection_config.Direction": "Direction",
    "intersection_config.ApproachSpeed": "ApproachSpeed",
    "intersection_config.UpstreamEntityID": "UpstreamEntityID",
    "intersection_config.UpstreamDistance": "UpstreamDistance",
    "intersection_config.DownstreamEntityID": "DownstreamEntityID",
    "intersection_config.DownstreamDistance": "DownstreamDistance",
    "intersection_config.add_lanes": "Add new lane",
    "intersection_config.add_phase": "Add new phase",
    "intersection_config.DetectorNumber": "Detector",
    "intersection_config.Type": "DownstreTypeamDistance",
    "intersection_config.Distance": "Distance",
    "intersection_config.PhaseNumber": "PhaseNumber",
    "intersection_config.Movement": "Movement",
    "intersection_config.Lane": "Lane",
    "intersection_config.invalid_form":
      "Any field is invalid. Please check again!",
    "intersection_config.add_direction": " Add new Direction",
    "intersection_config.topology": "Intersection Topology",
    "intersection_config.Detectors": "List detectors",
    "intersection_config.Phase": "Phase",
    "intersection_config.is_empty": " is empty!",
    "intersection_config.det_phase_mapping": "Detector and phases mapping",
    "intersection_config.tactic_config": "The TACTIC configuration",
    "intersection_config.int_topology": "The intersection topology",

    //corridor
    corridor: "corridor",
    Corridor: "Corridor",
    corridors: "Corridors",
    "corridor.create": "Create new corridor",
    "corridor.global_corridor_default": "Global Corridor Default (Coord phase)",
    "corridor.global_upstream": "Global - Upstream phase",
    "corridor.global_downstream": "Global - Downstream phase",
    "corridor.coordphase_upstream": "Coord Phase (Upstream phase)",
    "corridor.coordphase_downstream": "Coord Phase (Downstream phase)",
    "corridor.distance": "Distance (feet)",
    "corridor.speed": "Speed (MPH)",
    "corridor.last_distance_list_int_err":
      "Distance of last intersection in the list must be 0",
    "corridor.min_num_int_error":
      "The corridor must have at least 2 intersections",

    "alarm_rules.description": "Description",
    "alarm_rules.condition": "Condition",
    "alarm_rules.collapse": "Collapse",
    "alarm_rules.expand": "Expand",

    //alarm category
    "alarm.category.safety": "Safety",
    "alarm.new_category": "New ",
    "alarm.category.pedestrian": "Pedestrian",
    "alarm.category.oversaturation": "Over saturation",
    "alarm.category.coordination": "Coordination",

    //alarm records
    "alarm_records.page-title": "YUTRAFFIC - Alarm Records",
    "alarm_records.analysis_go": "Go to Analysis",
    alarm_records_management: " Alarm Records",
    "alarm_records.intersection_search_tooltip":
      "Search by Intersection UUID or Name",
    "alarm_records.intersection_search_plachoder": "Search Intersection",
    "alarm_records.intersection": "Intersection",
    "alarm_records.search": "Search",
    "alarm_records.search_tooltip": "Search by Name",
    "alarm_records.search_plachoder": "Search... ",
    "alarm_records.time_tooltip": "Filter by Alarm time",
    "alarm_records.categories_tooltip": "Filter by Categories",
    "alarm_records.all_categories": "All Categories",
    "alarm_records.analysis_type_tooltip": "Filter by Analysis types",
    "alarm_records.all_analysis_type": "All Analysis types",
    "alarm_records.intersection_tooltip": "Filter by Intersection",
    "alarm_records.all_intersection": "All Intersections",
    "alarm_records.agency_tooltip": "Filter by Agency",
    "alarm_records.id": "Id",
    "alarm_records.name": "Name",
    "alarm_records.category_name": "Category",
    "alarm_records.analysis_name": "Analysis Type",
    "alarm_records.intersection_name": "Intersection",
    "alarm_records.content": "Content",
    "alarm_records.alarm_time": "Alarm Time",
    "alarm_records.agency_name": "Agency Name",
    "alarm_records.rule_condition": "Condition",
    "alarm_records.show_form": "Alarm Record Detail",
    "alarm_records.forward": "Forward",
    "alarm_records.forward_message_form": "Forward Message",
    "alarm_records.forward_users": "Users",
    "alarm_records.forward_users_placeholder": "Please select users",
    "alarm_records.forward_users_required": "Users is requried",
    "alarm_records.summary": "Summary",
    "alarm_records.reporter": "Reporter",
    "alarm_records.from_date": "From date",
    "alarm_records.to_date": "To date",
    "alarm_records.no": "No",
    "alarm_records.description": "Description",
    "alarm_records.trigged_alarm": "No. of trigged Alarm",
    "alarm_records.export_summary_title": "Summary Preview",
    "alarm_records.export": "Export",
    "alarm_records.cancel": "Cancel",
    "alarm_records.save": "Send",
    "alarm_records.category": "Category",
    "alarm_records.analysis": "Analysis Type",
    "alarm_records.agency": "Agency Name",

    //Report
    "summary-report.create": "Create new template",
    "report_template.create_template": "New template",
    "report_template.agency_required": "Agency is required",
    "report_template.page-title": "YUTRAFFIC - Report Templates",
    "report_template.show_form": "Report template detail",
    "report_template.create_template_title": "Create new template",
    "report_template.edit_template_title": "Edit template",
    "report_template.report_template": "Report Templates",
    "report_template.remove_all": "Remove all",
    "report_template.search_inter_tooltip": "Search",

    "report_template.whole_day": "Whole day",
    create: "Create",
    "report_template.add_intersection_btn": "Add more intersection",
    "report_template.add_intersections": "Add intersections",
    "report_template.name": "Report name",
    "report_template.description": "Description",
    "report_template.aggregation_unit": "Aggregation unit",
    "report_template.aggregation_unit_note":
      "Your date range need to be greater than your aggregation unit",
    "report_template.status": "Status",
    "report_template.all_aggregation_unit": "Aggregation Unit",
    "report_template.all_owner": "All owner",
    "report_template.all_status": "All status",
    "report_template.created_by": "Created by",
    "report_template.day_of_week": "Days of week",

    "report_template.search_placholer": "Search by name or description",
    "report_template.filter_agency": "Filter by Agency",
    "report_template.filter_text": "Search",
    "report_template.filter_date": "Filter by Created time",
    "report_template.filter_aggregration_unit": "Filter by Aggregation units",
    "report_template.filter_owner": "Filter by Owner",
    "report_template.filter_status": "Filter by Status",
    "report_template.filter_by_weekday": "Filter by Weekday",
    "report_template.all_aggregration_unit": "All Aggregation units",

    "report_template.active_confirm_title": "Active report template",
    "report_template.active_confirm_content":
      "Are you sure to activate this template?",
    "report_template.inactive_confirm_content":
      "Are you sure to deactivate this template?",
    "report_template.inactive_confirm_title": "Deactive report template",
    "report_template.run_confirm_content":
      "Are you sure you want to run this template?",
    "report_template.run_confirm_title": "Run report template",
    "report_template.duplicate": "Duplicate",
    "report_template.run_template": "Run the report",
    "report_template.deactive": "Deactive",
    "report_template.active": "Active",
    "report_template.edit": "Edit",
    "report_template.delete": "Delete",
    "report_template.active_all": "Active all",
    "report_template.deactive_all": "Deactive all",
    "report_template.delete_all": "Delete all",
    "report_template.created_at": "Created at",
    "report_template.timezone": "Timezone",
    "report_template.select_date_range": "Select date range",
    "report_template.previous": "Previous ",
    "report_template.previous_day": "Previous (x) days",
    "report_template.previous_week": "Previous (x) weeks",
    "report_template.previous_month": "Previous (x) months",
    "report_template.select_time_range": "Select time range",
    "report_template.start": "Start time",
    "report_template.end": "End time",
    "report_template.select_day": "Select day of week",
    "report_template.agency_name": "Select agency",
    "report_template.daily": "Daily",
    "report_template.hourly": "Hourly",
    "report_template.weekly": "Weekly",
    "report_template.monthly": "Monthly",

    "report_template.tooltip.remove_specific_intersection":
      "Remove selected intersection",
    "report_template.tooltip.remove_all_intersection":
      "Remove all selected intersection",
    "report_template.tooltip.add_specific_intersection":
      "Add specific intersection",
    "report_template.tooltip.add_all_intersection": "Add all intersection",
    "report_template.tooltip.limit_day": "Days: Max 7 days, min  1 days.",
    "report_template.tooltip.limit_week": "Week: Max 4 weeks, min  1 week.",
    "report_template.tooltip.limit_month":
      "Month: Max 12 months, min  1 month.",
    "report_template.tooltip.compare_previous_example":
      "Example: A user selects Friday, August 26, 2022, 10:00-11:00 AM. Insights will compare the previous X Fridays for that same time range",

    "report_template.day_of_week_out_range_warning":
      "Some weekdays is not available in your time ranges!",

    "report_template.perflog_filter_empty":
      "The perflog filter should not be empty",
    "report_template.name_placeholder": "Report name",
    "report_template.intersection_empty":
      "List intersection list should not be empty",
    "report_template.select_manualy_label": "Options to run template",
    "report_template.select_manualy_switch": "Run manually",
    "report_template.time_of_day_label": "Select time of day",
    "report_template.select_frequency_label": "Recurrence pattern",
    "report_template.ONCE": "Once",
    "report_template.EVERY_DAY": "Every (x) days",
    "report_template.DAY_OF_WEEK": "Days of week",
    "report_template.DAY_OF_MONTH": "Days of month",
    "report_template.label_set_trigger_date": "Set trigger date",
    "report_template.label_set_trigger_time": "Set trigger time",

    "report_template.delete_confirm_title": "Remove item(s)",
    "report_template.week_days_required": "Weekdays is required",
    "report_template.delete_confirm_content":
      "Do you want to remove this item(s)?",
    "report_template.time_of_day_required": "Time of day is required",
    "report_template.date_range_offset_required": "Date offset is required",
    "report_template.end_time_must": "End time must greater than start",
    "report_template.name_required": "Report name is required",
    "report_template.timezone_required": "Timezone is required",
    "report_template.select_date_range_required": "Please select date range",
    "report_template.start_end_date_required": "Start/End date are required",
    "report_template.date_range_unit_required": "Date unit is required",
    "report_template.start_time_required": "Start time is required",
    "report_template.end_time_required": "End time is required",
    "report_template.end_required": "End time is required",
    "report_template.aggregation_unit_required":
      "Aggregastion unit is required",
    "report_template.schedule_once_required": "Please select one day",
    "report_template.set_recurrence_required": "Please select one recurrence",
    "report_template.schedule_offset_required": "Day offset is required",
    "report_template.schedule_date_required": "Schedule date is required",
    "report_template.schedule_days_from": " days starting from ",
    "report_template.schedule_day_month_required":
      "Schedule days of month required",
    "summary-report.templates.show-form": "Report template detail",
    "report_template.trigger_type": "Set trigger type",
    "report_template.define_metric_setting_hepler":
      "This definition is only applicable to the 'Recurring scheduled trigger' type",
    "report_template.manual_trigger": "Manual trigger",
    "report_template.once_time_scheduled_trigger": "One-time scheduled trigger",
    "report_template.recurring_scheduled_trigger":
      "Recurring scheduled trigger",
    "report_template.set_recurring": "Set recurrence",
    "report_template.define_metric_setting": "Configure trends:",
    "report_template.change_date_range_warning":
      "The schedule will be reset to 'Run manually' mode.",
    "report_template.compare_previous":
      "Compare the previous (x) number of days to the target date",
    "report_template.compare_previous_required":
      "The number of previous days is required",
    "report_template.relative_description":
      "Is the date on which the report is triggered based on the recurrence pattern configuration in the Schedule tab",
    "report_template.fixed_date": "Fixed Date",

    //analysis schedule
    "performance-metric.templates.show-form":
      "Performance Metric Template Info",
    "report_template.create_analysis_schedule": "New analysis schedule",
    "performance-metric.create": "Create new template",
    "analysis_schedule.performace_metric_report": "Performance metric report",
    "analysis_schedule.analysis_params_label":
      "Base on the analysis type, the list of corresponding parameters is shown",
    "analysis_schedule.page-title": "Report Templates",
    "analysis_schedule.create_template_title":
      "Create new performance metric report",
    "analysis_schedule.edit_template_title":
      "Edit performance metric report template",
    "analysis_schedule.receive_email": "Notification email",
    "analysis_schedule.receive_email_checkbox":
      "I want to receive an email notification.",
    "analysis_schedule.filter_text": "Search by description",
    "analysis_schedule.time_range_over": "Your time range is over 7 days",

    //yellow trap
    "analysis_schedule.left_turn_phase_num": "Left Turn Phase Number",
    "analysis_schedule.opposing_through_phase_num":
      "Opposing Through Phase Number",
    "analysis_schedule.left_turn_phase_events": "Left Turn Phase Events",
    "analysis_schedule.opposing_through_phase_event":
      "Opposing Through Phase Events",

    //report result
    "report_result.page_title": "Report Results",
    "report_result.page-title": "YUTRAFFIC - Report Results",
    "report_result.analysis_go": "Go to Analysis",
    report_result_management: " Alarm Records",
    "report_result.intersection_search_tooltip":
      "Search by Intersection UUID or Name",
    "report_result.intersection_search_plachoder": "Search Intersection",
    "report_result.intersection": "Intersection",
    "report_result.search": "Search",
    "report_result.search_tooltip": "Search by Name",
    "report_result.search_plachoder": "Search... ",
    "report_result.time_tooltip": "Filter by Created time",
    "report_result.agency_tooltip": "Filter by Agency",
    "report_result.name": "Name",
    "report_result.agency_name": "Agency Name",
    "report_result.filter_aggregration_unit": "Filter by Aggreggation units",
    "report_result.all_aggregration_unit": "All Aggregation unit",
    "report_result.all_owner": "All owner",
    "report_result.filter_owner": "Filter by Owner",
    "report_result.summary": "Summary",
    "report_result.reporter": "Reporter",
    "report_result.from_date": "From date",
    "report_result.to_date": "To date",
    "report_result.no": "No",
    "report_result.description": "Description",
    "report_result.cancel": "Cancel",
    "report_result.id": "Id",
    "report_result.agency": "Agency Name",
    "report_result.report_detail_title": "SUMMARY REPORT",
    "report_result.print_title": "Summary Report",
    "report_result.agency_print": "Agency",
    "report_result.report_name": "Report name",
    "report_result.time_range": "Time range",
    "report_result.short_exclude_day_one": ".excl {{day}} day",
    "report_result.short_exclude_day_other": ".excl {{day}} days",
    "report_result.exclude_day_one": "Excluding: {{day}} day",
    "report_result.exclude_day_other": "Excluding: {{day}} days",
    "report_result.from_time": "From time",
    "report_result.date_range": "Date range",
    "report_result.to_time": "To time",
    "report_result.aggregation": "Aggregation unit",
    "report_result.created_at": "Created at",
    "report_result.owner": "Created by",
    "report_result.selected_intersection": "Number of intersections",
    "report_result.print_table": "Export PDF",
    "report_result.export_csv": "Export CSV",
    "report_result.day_of_week": "Days of week",
    "report_result.redirect": "Go to result page",
    "report_result.delete": "Delete this result",
    "report_result.run_confirm_content": "Are you sure to delete this result?",
    "report_result.run_confirm_title": "Delete report result",
    "report_result.prev_result_time": "Prev",
    "report_result.week_days": "Week days",
    "report_result.view_chart": "View Chart",
    "report_result.event_raw_count": "Event Raw Count",
    "report_result.param_number": "Param number: ",
    "report_result.target_date": "Target Date:",
    "report_result.compare_previous":
      "Compare the previous ({{days}}) days to target date:",

    //Report popup trend
    BAD: "Bad",
    GOOD: "Good",
    NORMAL: "Normal",
    NONE: "None",

    nomal_map_setting_text:
      "Click 'Save' button if you want to keep the current zoom level and dot size!",
    heat_map_setting_text:
      "Click 'Save' button if you want to keep the current zoom level and Radius & Blur settings!",
    map_setting_success: "Saved successfully",
    //for web view
    "result_table.intersection_name": "Intersection name",
    "result_table.aor": "%AoR",
    "result_table.aog": "%AoG",
    "result_table.veh_act": "Vehicle. Activation (Raw count)",
    "result_table.vehicle_activation": "Vehicle. Activation (Raw count)",
    "result_table.gap_out": "%Gap Out",
    "result_table.max_out": "%Max Out",
    "result_table.force_off": "%Force Off",
    "result_table.avg_ped_delay": "Ped Delay (seconds)",
    "result_table.ped_delay": "Ped Delay (seconds)",
    "result_table.ped_delay_act": "Ped Delay Activation (Raw Count)",
    "result_table.ped_delay_activation": "Ped Delay Activation (Raw Count)",
    "result_table.max": "Max: ",
    "result_table.min": "Min: ",
    "result_table.avg": "Avg: ",
    "result_table.transition": "% Transition",
    "result_table.gor": "% GOR",
    "result_table.ror5": "% ROR5",
    "result_table.sf_indicator": "Split failure",
    "result_table.pc_indicator": "Poor coordination",
    "result_table.ec_indicator": "Excess capacity",
    "result_table.queue_length": "Queue length",
    "result_table.approach_delay": "Approach delay",
    "result_table.red_light_violation": "Red Light Violation Count",
    "result_table.rlv_count": "Red Light Violation Count",
    fluctuation: "Fluctuation: ",
    trending: "Trending",

    "report_result_print.header_created_by": "Created by",
    "report_result_print.header_created_at": "Created at",
    "report_result.time_zone": "Timezone",
    "report_result.agency_id": "Agency id",
    // for popup trend
    "result_popup_trend.decrease": "Decrease",
    "result_popup_trend.increase": "Increase",
    "result_popup_trend.none": "None",
    //for csv
    "result_table.aor_max": "Max %AoR",
    "result_table.aor_min": "Min %AoR",
    "result_table.aor_avg": "Avg %AoR",

    "result_table.aog_max": "Max %AoG",
    "result_table.aog_min": "Min %AoG",
    "result_table.aog_avg": "Avg %AoG",

    "result_table.veh_act_max": "Max Veh Act",
    "result_table.veh_act_min": "Min Veh Act",
    "result_table.veh_act_avg": "Avg Veh Act",

    "result_table.gap_out_max": "Max %Gap Out",
    "result_table.gap_out_min": "Min %Gap Out",
    "result_table.gap_out_avg": "Avg %Gap Out",

    "result_table.max_out_max": "Max %Max Out",
    "result_table.max_out_min": "Min %Max Out",
    "result_table.max_out_avg": "Avg %Max Out",

    "result_table.force_off_max": "Max %Force Off",
    "result_table.force_off_min": "Min %Force Off",
    "result_table.force_off_avg": "Avg %Force Off",

    "result_table.ped_delay_max": "Max Ped Delay",
    "result_table.ped_delay_min": "Min Ped Delay",
    "result_table.ped_delay_avg": "Avg Ped Delay",

    "result_table.ped_delay_act_max": "Max Ped Delay Activation",
    "result_table.ped_delay_act_min": "Min Ped Delay Activation",
    "result_table.ped_delay_act_avg": "Avg Ped Delay Activation",

    "result_table.rlv_count_max": "Max Red Light Violation Count",
    "result_table.rlv_count_min": "Min Red Light Violation Count",
    "result_table.rlv_count_avg": "Avg Red Light Violation Count",

    // Notification
    "alm-noti.show_more": "Show More",
    "alm-noti.last_modified_at": "Last Modified At",
    "alm-noti.page-title": "YUTRAFFIC - Notifications",
    "alm-noti.title_explane":
      "Your notifications will be automatically deleted after 7 days since the last day they were modified. This rule only applies for notification with status is read",
    "alm-noti.title": "Notification",
    "alm-noti.intersection_name": "Intersection",
    "alm-noti.alm_noti_management": "Notification",
    "alm-noti.flag_all": "Flag",
    "alm-noti.click_to_unflag": "Click to unflag",
    "alm-noti.click_to_flag": "Mark as flag",
    "alm-noti.click_to_read": "Mark as read",
    "alm-noti.click_to_unread": "Click to unread",
    "alm-noti.flag_all_confirm_title": "Flag Notification",
    "alm-noti.flag_all_confirm_content":
      "Are you sure you want to flag this notification(s)?",
    "alm-noti.un_flag_all": "Unflag",
    "alm-noti.un_flag_all_confirm_title": "Unflag Notification",
    "alm-noti.un_flag_all_confirm_content":
      "Are you sure you want to unflag this notification(s)?",
    "alm-noti.read_all": "Read",
    "alm-noti.read_all_confirm_title": "Read Notification",
    "alm-noti.read_all_confirm_content":
      "Are you sure you want to read this notification(s)?",
    "alm-noti.un_read_all": "Unread",
    "alm-noti.un_read_all_confirm_title": "Unread Notification",
    "alm-noti.un_read_all_confirm_content":
      "Are you sure you want to unread this notification(s)?",
    "alm-noti.delete_all": "Delete",
    "alm-noti.delete_all_confirm_title": "Delete Notification",
    "alm-noti.delete_all_confirm_content":
      "Are you sure you want to delete this notification(s)?",
    "alm-noti.name": "Name",
    "alm-noti.category": "Category",
    "alm-noti.content": "Content",
    "alm-noti.created_time": "Created at",
    "alm-noti.actions": "Actions",
    "alm-noti.edit": "Edit",
    "alm-noti.delete": "Delete",
    "alm-noti.read": "Read",
    "alm-noti.unread": "Unread",
    "alm-noti.flag": "Flag",
    "alm-noti.unflag": "Unflag",
    "alm-noti.category_tooltip": "Search by Category",
    "alm-noti.category-all": "All Types",
    "alm-noti.search_tooltip": "Search by Name, Content",
    "alm-noti.search_placholer": "Search...",
    "alm-noti.filter_date": "Filter by From date and to date",
    "alm-noti.read_status_tooltip": "Search by Read status",
    "alm-noti.read_status_all": "Read/Unread",
    "alm-noti.flag_status_tooltip": "Search by Flag status",
    "alm-noti.flag_status_all": "Flag/Unflag",
    "alm-noti.id": "ID",
    "alm-noti.description": "Content",
    "alm-noti.type": "Type",
    "alm-noti.chart": "Chart Type",
    "alm-noti.time": "Time",
    "alm-noti.deleted_by": "Deleted by",
    "alm-noti.intersection": "Intersection",
    "alm-noti.condition": "Alarm Condition",
    "alm-noti.show_form": "Notification details",
    "alm-noti.view_more": "View more",
    "alm-noti.mark_all": "Mark all as read",
    "alm-noti.analysis": "Analysis",
    "alm-noti.info": "Info",
    "alm-noti.new_message": "You have a new Message.",
    //socket
    "socket.error": "Error",
    "socket.warning": "Error",
    "socket.connect_fail": "Can't connect socket server",
    "socket.connect_error": "Socket error",
    "socket.worng_fomat": "Recived a message from socket but unknown format",

    //tsp dashboard
    "tsp_dashboard.page_title": "YUTRAFFIC - TSP Dashboard",
    "tsp_dashboard.mapview_page_title": "YUTRAFFIC - Map View",
    "tsp_dashboard.summary_table_page_title": "YUTRAFFIC - Summary Table",
    "tsp_dashboard.tsp_event_history_page_title":
      "YUTRAFFIC - TSP Event History ",
    "tsp_dashboard.int_event_history_page_title":
      "YUTRAFFIC - Intersection Event History",
    "tsp_dashboard.map_view": "Map View",
    "tsp_dashboard.summary_table": "Summary Table",
    "tsp_dashboard.event_history": "Event History",
    "tsp_dashboard.tsp_event_history": "Request History",
    "tsp_dashboard.intersection_event_history": "Intersection Event History",
    "tsp_dashboard.last_24h": "LAST 24 HOURS",
    "tsp_dashboard.int_event_history": "Intersection Event History",
    "tsp_dashboard.tsp_intersection": "Intersection",
    "tsp_dashboard.tsp_unavailable": "TSP EVENT UNAVAILABLE",
    "tsp_dashboard.name": "Name",
    "tsp_dashboard.query_time": "Query time",
    "tsp_dashboard.go_to_tsp_event": "Intersection Event History",
    "intersection.most_recent_tsp": "Most recent TSP event: ",

    "tsp_dashboard.minute": " mins",
    "tsp_dashboard.hour": " hour",
    "tsp_dashboard.hours": " hours",
    "tsp_dashboard.custom": " Custom",
    "tsp_dashboard.from_time": "From",
    "tsp_dashboard.to_time": "To",
    "tsp_dashboard.custom_query": "Custom query time",

    "tsp_dashboard.invalid_range": "The time range is invalid.",

    "tsp_dashboard.checkin": "Check-in",

    "tsp_dashboard.checkout": "Check-out",
    "tsp_dashboard.adjust_early": "Adjust Early",
    "tsp_dashboard.adjust_late": "Adjust Late",
    "tsp_dashboard.priority_req_enable": "Enable Priority Request ",
    "tsp_dashboard.priority_req_cancel": "Cancel Priority Request ",
    "tsp_dashboard.last_checkin": "Last Check-in",
    "tsp_dashboard.last_checkout": "Last Check-out",
    "tsp_dashboard.filter_by_tsp_event": "Filter by TSP Event Type",
    "tsp_dashboard.search_by_event_details": "Search by event details",
    "tsp_dashboard.search_by_int_id": "Search by intersection UUID",
    "tsp_dashboard.search_by_int_name": "Search by intersection name",
    "tsp_dashboard.filter_by_intersection": "Filter by Intersection",
    "tsp_dashboard.filter_by_route_number": "Filter by route number",
    "tsp_dashboard.filter_by_route_number_placeholder": "Route No.",
    "tsp_dashboard.lat": "Lat",
    "tsp_dashboard.lon": "Long",
    "tsp_dashboard.heading": "Heading",
    "tsp_dashboard.tsp_event_show_form": "TSP Event Details",
    "tsp_dashboard.summary_table_show_form": "Summary Details",
    "tsp_dashboard.all_tsp_event": "All Event Types",

    "tsp_dashboard.int_name": "Intersection Name ",
    "tsp_dashboard.int_uuid": "Intersection UUID",
    "tsp_dashboard.time_stamp": "Timestamp",
    "tsp_dashboard.event_type": " Event Type",
    "tsp_dashboard.route_number": "Route Number",
    "tsp_dashboard.vehicle_info": "Vehicle ID | Vehicle Location",
    "tsp_dashboard.request_id": "Request ID",
    "tsp_dashboard.priority_des":
      "Priority (This info is only available in TSP perlog event)",
    "tsp_dashboard.priority": "Priority",
    "tsp_dashboard.vehicle_id": "Vehicle ID",
    "tsp_dashboard.network_id": "Network ID",
    "tsp_dashboard.vehicle_location": "Vehicle Location",
    "tsp_dashboard.estimation_arrivaltime": "Estimation Arrival Time",
    "tsp_dashboard.passenger_loading": "Passenger Loading",
    "tsp_dashboard.time_behind_ahead": "Time Behind Ahead",
    "tsp_dashboard.block_number": "Block Number",
    "tsp_dashboard.trip_direction": "Trip Direction",
    "tsp_dashboard.direction_next_int": "Direction Next Intersection",
    "tsp_dashboard.detail": "Details",

    //dashboard
    save_map_setting: "Save",
    save_map_setting_tooltip:
      "Save your settings for map (Include zoom level, current view).",
    "heatmap.map_title": "Map View ",
    "heatmap.metric_filter": "Metric",
    "heatmap.metric_filter-default": "Metric default",
    "heatmap.intersection_filter": "Intersection",
    "heatmap.7_day": "LAST 7 DAYS",
    "heatmap.24_hours": "LAST 24 HOURS",
    "heatmap.alarm": "Open Alarm",
    "heatmap.aor": "AoR",
    "heatmap.alarmRecord": "Alarm Record",
    "heatmap.swith": "HEAT MAP VIEW",
    "dashboard.map-header": "Map View",
    "dashboard.map-description":
      "View the list of intersections managed by your agency and located in the map area. " +
      "The map view shows the intersection with the level of a specific traffic metric: " +
      "AOR, AOG, Volume, Pedestrian Delay....",
    "dashboard.top-ten-header": 'Top 10 Intersections by "Open Alarm"',
    "dashboard.top-ten-description":
      "Identify the 10 intersections with the most open alarm notifications",
    "dashboard.chart-header": "Open Alarm",
    "dashboard.chart-description":
      "Provide statistics on the number of your open alarm notification by alarm category",
    "dashboard.chart-title": "Amount",
    "dashboard.updated-time": "Controller Data Last Updated: ",
    "dashboard.refresh_tooltip": "Refresh Data",
    "dashboard.agency": "Agency",
    "dashboard.page-title": "YUTRAFFIC - Dashboard",
    "heatmap.default": "Default",
    "heatmap.from": "> ",
    "heatmap.higher": " and Higer",
    "heatmap.setting": "Settings",
    "heatmap.setting_dot_size": "Dot Size:",
    "heatmap.radius": "Radius:",
    "heatmap.setting-blur": "Blur:",
    "heatmap.last-update": "Last updated: ",
    "heatmap.intersection_placeholder": "Select Intersection..",
    "heatmap.intersection_filter_tooltip": "Search intersection by name",
    "dashboard.edit_widgets": "Edit Widgets",
    "dashboard.done_editing": "Done Editing",
    "dashboard.add_widgets": "Add Widgets",
    "dashboard.widget_settings": "Widget Settings",
    "dashboard.widget_settings_subtitle":
      "The widget settings include the following:",

    "map_setting.period": "Period",
    "map_setting.save_zoom_level": "Save the current zoom level",
    "map_setting.reset_zoom_level": "Reset the zoom level",
    "map_setting.dot_size": "Dot size in Normal Map",
    "map_setting.radius": "Radius in Heat Map",
    "map_setting.blur": "Blur in Heat Map",
    "map_setting.last_24_hours": "Last 24 hours",
    "map_setting.last_12_hours": "Last 12 hours",
    "map_setting.custom": "Custom",
    "map_setting.open_alarms": "Open Alarms",
    "map_setting.aor_percent": "%AoR",
    "map_setting.aog_percent": "%AoG",
    "map_setting.vehicle_volumn": "Vehicle Volumn",
    "map_setting.avg_per_delay": "Avg. Per Delay",
    "map_setting.pp": "Preemption/Priority Requests",
    "map_setting.rlv_count": "Red Light Violation Count",

    "dashboard.selected_intersection_list": "Selected intersection list",
    "dashboard.selected_intersection_description":
      "(Only for Include and Exclude specific intersection option)",
    "dashboard.include_all_intersections": "Include all intersections",
    "dashboard.include_specific_intersections":
      "Include specific intersections",
    "dashboard.exclude_specific_intersections":
      "Exclude specific intersections",
    "dashboard.select_intersections": "Select intersections",
    "dashboard.remove_widget": "Remove Widget",
    "dashboard.widget_setting": "Widget Setting",
    "dashboard.summary-report-header": "Summary Report",
    "dashboard.summary-report-description":
      "Show the summary report with all traffic indicators on the platform. " +
      "These values are summarized by hourly during a defined period (default: last 24 hours)",
    "dashboard.volume-count-header": "Volume Count",
    "dashboard.volume-count-description":
      "Identify the 10 intersections with the most volume accounts. (default: last 24 hours)",
    "dashboard.notification-header": "Notification",
    "dashboard.new-notification-header": "New Notification",
    "dashboard.flagged-notification-header": "Flagged Notification",
    "dashboard.notification-description":
      "Provide an overview about the statistic of the user’s notifications on the platform  (default: last 24 hours)",
    "dashboard.summary-report-aggregation-unit": "Aggregation unit",
    "dashboard.summary-report-hourly": "Hourly",
    "dashboard.summary-report-queried-time": "Queried Time",
    "dashboard.summary-report-total-intersection": "Total Intersection",
    "dashboard.summary-report-search_tooltip": "Seach by Intersection name",
    "dashboard.time_period_in_valid":
      "Time period is required and must be less than one day!",
    "dashboard.time_in_valid": "Invalid time range!",

    "volume_counts.page-title": "Volume Counts per Intersection",
    "volume_counts.no": "No.",
    "volume_counts.intersection_name": "Intersection name",
    "volume_counts.total": "Total (Raw Count)",
    "notification.notification_title": "NOTIFICATION",
    "notification.notification_type":
      "Currently, there are the following notification types",
    "notification_setting.alam_notification": "Alam notification",
    "notification_setting.alam_management": "Alam management",
    "notification_setting.report_notification": "Report notification",
    "notification_setting.report_management": "Report management",
    "notification_setting.release_notification": "Release note notification",
    "notification_setting.optimization": "Optimization",
    "notification_setting.performance_metrics_notification":
      "Performance metrics notification",
    "notification_setting.performance_metrics_management":
      "Performance metrics management",
    "notification_setting.other": "Other",
    "notification_setting.custom": "Custom",
    "summary_report.summary_report_title": "SUMMARY REPORT",
    "summary_report.summary_report_metrics":
      "You can select which metrics you want to see in the summary report and configure the " +
      "condition for displaying warning values. Note: The condition is only applied for average values",
    "summary_report.summary_report_header_enable": "Enable",
    "summary_report.summary_report_header_metrics": "Metrics",
    "summary_report.summary_report_header_condition":
      "Condition for the warning",
    "summary_report.avg": "Average",
    "summary_report.min": "Min",
    "summary_report.max": "Max",
    "summary_report.intersection_name": "Intersection name",
    "summary_report.aor": "% AoR",
    "summary_report.aog": "% AoG",
    "summary_report.veh_act": "Veh. Activation",
    "summary_report.gap_out": "% Gap Out",
    "summary_report.max_out": "% Max Out",
    "summary_report.force_off": "% Force Off",
    "summary_report.avg_ped_delay": "Ped Delay",
    "summary_report.ped_delay_act": "Ped Delay Activation",
    "summary_report.transition": "% Transition",
    "summary_report.gor": "% GOR",
    "summary_report.ror5": "% ROR5",
    "summary_report.split_failure": "Split Failure",
    "summary_report.avg_queue_length": "Avg Queue Length",
    "summary_report.avg_approach_delay": "Avg Approach Delay",
    "summary_report.red_light_violation_count": "RLV Count",

    "yellow_trap.occupied": "Yellow Trap Occupied On",
    "yellow_trap.date": "Date",
    "yellow_trap.left_turn_phase": "Left Turn Phase",
    "yellow_trap.opposing_through_phase": "Opposing Through Phase",
    "yellow_trap.split_visualization": "Split Visualization",

    // Notification types
    ALARM_NOTIFICATION: "Alarm notification",
    ALARM_MANAGEMENT: "Alarm management",
    SUMMARY_REPORT_NOTIFICATION: "Summary report notification",
    SUMMARY_REPORT_MANAGEMENT: "Summary report management",
    RELEASE_NOTE_NOTIFICATION: "Release note notification",
    OPTIMIZATION: "Optimization",
    PERFORMANCE_METRIC_NOTIFICATION: "Performance metric notification",
    PERFORMANCE_METRIC_MANAGEMENT: "Performance metric management",
    OTHER: "Other",
  },
};
