import * as d3 from "d3";
import React, { Component, createRef } from "react";
import "./style.scss";

const MiniMapStyle = {
  marginLeftRight: 40,
  marginTopBottom: 2,
};
class MiniMap extends Component {
  constructor(props) {
    super(props);
    //current SVG object
    this.ref = createRef();
    this.scaleObject = null;
  }
  /**
   * componentDidMount
   * inital mini map control
   * we draw brush and axisX on here
   */
  componentDidMount() {
    //get data range from props and change to control format
    const { fullState, dataState } = this.props;
    const fromTime = new Date(fullState[0])?.getTime();
    const toTime = new Date(fullState[1])?.getTime();

    const fromState = new Date(dataState[0])?.getTime();
    const toState = new Date(dataState[1])?.getTime();
    //get the target SVG to add brush
    let svg = d3.select(this.ref?.current);
    if (svg && fromTime && toTime) {
      //dynamic cal witdh and height of screen to draw data
      const witdh =
        parseFloat(window.getComputedStyle(svg.node())?.width) -
        MiniMapStyle.marginLeftRight * 2;
      const height =
        parseFloat(window.getComputedStyle(svg.node())?.height) / 2 -
        MiniMapStyle.marginTopBottom * 2;

      //Create axisX
      this.renderXAxisObject(svg, witdh, height, [fromTime, toTime]);
      //Create brush
      this.renderBrushObject(svg, witdh, height, [fromState, toState]);
      //add event resize of windows, incase we resize screens
      //@TODO
    }
  }
  /**
   * renderBrushObject
   * @params {Object} svg Parent Object to add brush
   * @params {Float} witdh of brush
   * @params {Float} height of brush
   * @params {Array} dataRange inital value of brush
   *
   */
  renderBrushObject = (svg, witdh, height, dataState) => {
    let x = this.scaleObject(dataState[0]);
    let y = this.scaleObject(dataState[1]);
    let dataRange = [0, witdh];
    if (x && y) {
      dataRange = [x, y];
    }
    const position = [
      [0, 0],
      [witdh, height],
    ];
    let brush = d3
      .brushX()
      .extent(position)
      .on("start", this.handleBrushStart)
      .on("end", this.handleBrushStop);
    svg
      .append("g")
      .attr("class", "brush")
      .attr("transform", "translate(" + MiniMapStyle.marginLeftRight + ",0)")
      .call(brush)
      .call(brush.move, dataRange)
      .selectAll(".overlay")
      .remove();
    const rect = svg.select("rect")
    rect.style("cursor", "auto")


  };
  /**
   * renderXAxisObject
   * @params {Object} svg Parent Object to add Axis
   * @params {Float} witdh of Axis
   * @params {Float} height of Axis
   * @params {Array} dataRange : data Time Range of Axis
   *
   */
  renderXAxisObject = (svg, witdh, height, dataRange) => {
    this.scaleObject = d3.scaleTime().domain(dataRange).range([0, witdh]);
    // Add scales to axis
    let axisObject = d3.axisBottom(this.scaleObject);
    //add and move axis to centerl
    svg
      .append("g")
      .attr("class", "wrapper-mini-map")
      .attr(
        "transform",
        "translate(" +
        MiniMapStyle.marginLeftRight +
        "," +
        (height + MiniMapStyle.marginTopBottom) +
        ")"
      )
      .call(axisObject);
  };
  /**
   * handleBrushStop
   *
   * update the minimap range for showing chart data
   *
   */
  handleBrushStop = () => {
    let svg = d3.select(this.ref?.current);
    const rect = svg.select("rect")
    const { updateMapState } = this.props;
    //update miniMap data to showing chart data
    const selection = d3.event.selection;
    if (selection && updateMapState) {
      let startDate = this.scaleObject?.invert(selection[0]);
      let endtDate = this.scaleObject?.invert(selection[1]);
      if (startDate && endtDate) {
        updateMapState(startDate, endtDate);
      }
    } else {
      console.log("WARNING selectionObject is NUll");
    }
    const width =
      parseFloat(window.getComputedStyle(svg.node())?.width) -
      MiniMapStyle.marginLeftRight * 2;
    if (selection[1] - selection[0] === width) {
      rect.style("cursor", "auto")
    }
    else {
      rect.style("cursor", "move")
    }
  };
  /**
   * handleBrushStart
   * setLoading for chart
   */
  handleBrushStart = () => {
    const { onStartZoom } = this.props;
    onStartZoom && onStartZoom();
  };

  render() {
    return (
      <div className="wrap-mini-map">
        <div className="chart-map-container">
          <svg ref={this.ref} />
        </div>
      </div>
    );
  }
}

export default MiniMap;
