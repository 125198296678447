import { CHART_COLOR_SCHEMA, GRAPHIC_CHART_TYPE } from "./colorSchema";

export const CUSTOM_ANALYSIS_CONSTANT = [
  {
    key: "active_phase",
    label: "Active phase",
    color: CHART_COLOR_SCHEMA.GAP_OUT_PERCENT,
  },
  {
    key: "active_pedestrian",
    label: "Active pedestrian",
    color: CHART_COLOR_SCHEMA.LIGHT_GREY,
  },
  {
    key: "barrier_ring",
    label: "Barrier ring",
    color: CHART_COLOR_SCHEMA.AOR_PERCENT,
  },
  {
    key: "cabinet_system",
    label: "Cabinet system",
    color: CHART_COLOR_SCHEMA.PINK,
  },
  {
    key: "coordination_group",
    label: "Coordination group",
    color: CHART_COLOR_SCHEMA.AVERAGE_SPLIT,
  },
  {
    key: "detector_group",
    label: "Detector group",
    color: CHART_COLOR_SCHEMA.BLUE,
  },
  {
    key: "overlap",
    label: "Overlap",
    color: CHART_COLOR_SCHEMA.BLACK,
  },
  {
    key: "phase_control",
    label: "Phase control",
    color: CHART_COLOR_SCHEMA.YELLOW,
  },
  {
    key: "preemption",
    label: "Preemption",
    color: CHART_COLOR_SCHEMA.PURPIL,
  },
];

export const ANALYSIS_TYPE = {
  AOG_TYPE: "aog",
  CH_TYPE: "coord_health",
  SPLIT_FAILURE: "split",
  AOR_TYPE: "aor",
  CD_TYPE: "coord",
  YTO: "yellow_trap_occurrence",
  ABNORMAL: "abnormal_data",

  //volume group
  VOLUMNE: "vol",
  VOLUME_ALL_PHASE: "volume_all_phase",
  VOLUME_ONE_PHASE: "volume_one_phase",
  //Phase Terminated group
  PT_TYPE: "pt",
  DEFAULT_PT: "pt_default",
  PT_HIGHLIGHT_TYPE: "pt_highlight",

  // Pedestrian Group
  PED_TYPE: "ped",
  PED_DELAY: "ped_delay",
  PED_ACTIVITIES: "ped_act",

  //appoarch delay group
  QUEUE_LENGTH: "queue_length",
  QUEUE_LENGTH_PHASE_VIEW: "queue_length_phase",
  QUEUE_LENGTH_LANE_VIEW: "queue_length_lane",

  //queue length group
  APPOARCH_DELAY: "app_delay",
  APPOARCH_DELAY_PHASE_VIEW: "app_delay_phase",
  APPOARCH_DELAY_LANE_VIEW: "app_delay_lane",

  //turning movement
  TURNING_MOVEMENT: "turning_movement",

  //turning movement
  RLV_COUNT: "red_light_violation",

  //split monitor
  SPLIT_MONITOR: "split_monitor",

  //Preempt / Priority
  PREEMPTION_PRIORITY: "pp",
  PREEMPTION_PRIORITY_REQUEST: "pp_request",
  PREEMPTION_PRIORITY_DETAIL: "preemption_detail",
  PREEMPTION_PRIORITY_TSP_DETAIL: "priority_detail",

  // MOE
  MOE_ANALYSIS: "moe_analysis",
  MOE_SUMMARY: "summary",
  MOE_DETAIL_VIEW: "detail_view",
};

export const ANALYSIS_PLAN_INFO = {
  aor: [
    {
      key: "rt_percent",
      color: CHART_COLOR_SCHEMA.RT_PERCENT,
      label: (value) => Math.round(value?.rt_percent) + "% Red Time",
    },
    {
      key: "aor_percent",
      color: CHART_COLOR_SCHEMA.AOR_PERCENT,
      label: (value) => Math.round(value?.aor_percent) + "% AoR",
    },
  ],
  aog: [
    {
      key: "gt_percent",
      color: CHART_COLOR_SCHEMA.GREEN,
      label: (value) => Math.round(value?.gt_percent) + "% Green Time",
    },
    {
      key: "aog_percent",
      color: CHART_COLOR_SCHEMA.AOG_VOLUME,
      label: (value) => Math.round(value?.aog_percent) + "% AoG",
    },
  ],
  split: [
    {
      key: "SF",
      color: CHART_COLOR_SCHEMA.YELLOW,
      label: (value) => Math.round(value?.SF) + " SF",
    },
    {
      key: "EGU",
      color: CHART_COLOR_SCHEMA.GREEN_SIEMENS,
      label: (value) => Math.round(value?.EGU) + " EGU",
    },
    {
      key: "PC",
      color: CHART_COLOR_SCHEMA.BLUE,
      label: (value) => Math.round(value?.PC) + " PC",
    },
  ],
  coord_health: [
    {
      key: "cycle",
      color: CHART_COLOR_SCHEMA.DEFAULT,
      label: (value) => "Cycle Count: " + Math.round(value?.cycle),
    },
    {
      key: "instep_percent",
      color: CHART_COLOR_SCHEMA.GREEN_SIEMENS,
      label: (value) => "In-step: " + Math.round(value?.instep_percent) + "%",
    },
    {
      key: "transition_percent",
      color: CHART_COLOR_SCHEMA.PINK,
      label: (value) =>
        "Transition: " + Math.round(value?.transition_percent) + "%",
    },
  ],
  pt_default: [
    {
      key: "gap_out_percent",
      color: CHART_COLOR_SCHEMA.GAP_OUT_PERCENT,
      label: (value) =>
        Math.round(value.gap_out_percent) + "% (" + value.gap_out + ") GO",
    },
    {
      key: "max_out_percent",
      color: CHART_COLOR_SCHEMA.MAX_OUT_PERCENT,
      label: (value) =>
        Math.round(value.max_out_percent) + "% (" + value.max_out + ") MO",
    },
    {
      key: "force_off_percent",
      color: CHART_COLOR_SCHEMA.FORCE_OFF_PERCENT,
      label: (value) =>
        Math.round(value.force_off_percent) + "% (" + value.force_off + ") FO",
    },
    {
      key: "average_split",
      color: CHART_COLOR_SCHEMA.AVERAGE_SPLIT,
      label: (value) => Math.round(value.average_split) + "s AVG. Split",
    },
  ],
  pt_highlight: [
    {
      key: "actual_gap_out_percent",
      color: CHART_COLOR_SCHEMA.GAP_OUT_PERCENT,
      label: (value) =>
        Math.round(value.actual_gap_out_percent) +
        "% (" +
        value.actual_gap_out +
        ") GO",
    },
    {
      key: "actual_max_out_percent",
      color: CHART_COLOR_SCHEMA.MAX_OUT_PERCENT,
      label: (value) =>
        Math.round(value.actual_max_out_percent) +
        "% (" +
        value.actual_max_out +
        ") MO",
    },
    {
      key: "actual_force_off_percent",
      color: CHART_COLOR_SCHEMA.FORCE_OFF_PERCENT,
      label: (value) =>
        Math.round(value.actual_force_off_percent) +
        "% (" +
        value.actual_force_off +
        ") FO",
    },
    {
      key: "average_split",
      color: CHART_COLOR_SCHEMA.AVERAGE_SPLIT,
      label: (value) => Math.round(value.average_split) + "s AVG. Split",
    },
  ],
  coord: [
    {
      key: "aog_percent",
      color: CHART_COLOR_SCHEMA.AOG_PERCENT,
      label: (value) => Math.round(value.aog_percent) + "% AoG",
    },
    {
      key: "aog_volume",
      color: CHART_COLOR_SCHEMA.AOG_VOLUME,
      label: (value) => Math.round(value.aog_volume) + "Volume",
    },
    {
      key: "gt_percent",
      color: CHART_COLOR_SCHEMA.GT_PERCENT,
      label: (value) => Math.round(value.gt_percent) + "% GT",
    },
    {
      key: "pr",
      color: CHART_COLOR_SCHEMA.PR,
      label: (value) => Math.round(value.pr) + "% PR",
    },
    {
      key: "volume",
      color: CHART_COLOR_SCHEMA.VOLUME,
      label: (value) => Math.round(value.volume) + " Volume",
    },
  ],
  ped_act: [
    {
      key: "ped_actuation",
      color: CHART_COLOR_SCHEMA.VOLUME,
      label: (value) => Math.round(value.ped_actuation) + " PA",
    },
    {
      key: "avg_ped_delay",
      color: CHART_COLOR_SCHEMA.DURATION,
      label: (value) => Math.round(value.avg_ped_delay) + "s avg.PD",
    },
  ],
  ped_delay: [
    {
      key: "ped_actuation",
      color: CHART_COLOR_SCHEMA.VOLUME,
      label: (value) => Math.round(value.ped_actuation) + " PA",
    },
    {
      key: "avg_ped_delay",
      color: CHART_COLOR_SCHEMA.DURATION,
      label: (value) => Math.round(value.avg_ped_delay) + "s avg.PD",
    },
  ],
  app_delay_lane: [
    {
      key: "plan",
      color: CHART_COLOR_SCHEMA.DEFAULT,
      label: (value) => `Plan ${value?.plan}`,
    },
    {
      key: "avg_delay",
      color: CHART_COLOR_SCHEMA.GREEN_SIEMENS,
      label: (value) => Math.round(value?.avg_delay) + " (s) " + "avg.AD",
    },
  ],
  app_delay_phase: [
    {
      key: "plan",
      color: CHART_COLOR_SCHEMA.DEFAULT,
      label: (value) => `Plan ${value?.plan}`,
    },
    {
      key: "avg_delay",
      color: CHART_COLOR_SCHEMA.GREEN_SIEMENS,
      label: (value) => Math.round(value?.avg_delay) + " (s) " + "avg.AD",
    },
  ],
  queue_length_phase: [
    {
      key: "avg_queue_length",
      color: CHART_COLOR_SCHEMA.GREEN_SIEMENS,
      label: (value) =>
        parseFloat(value?.avg_queue_length).toFixed(2) + " avg. QL",
    },
  ],
  queue_length_lane: [
    {
      key: "avg_queue_length",
      color: CHART_COLOR_SCHEMA.GREEN_SIEMENS,
      label: (value) =>
        parseFloat(value?.avg_queue_length).toFixed(2) + " avg. QL",
    },
  ],
  vol: [
    {
      key: "volume",
      color: CHART_COLOR_SCHEMA.DEFAULT,
      label: (value) => parseFloat(value?.volume).toFixed(2) + " Volume",
    },
  ],
  turning_movement: [
    {
      key: "total",
      color: CHART_COLOR_SCHEMA.DEFAULT,
      label: (value) => "Total: " + Number(value?.total),
    },
  ],
  [ANALYSIS_TYPE.RLV_COUNT]: [
    {
      key: "total_violation_count",
      color: CHART_COLOR_SCHEMA.DEFAULT,
      label: (value) => "Total: " + Number(value?.total_violation_count),
    },
  ],
  pp_request: [
    {
      key: "num_of_preempt_request",
      color: CHART_COLOR_SCHEMA.BLUE,
      label: (value) => value.num_of_preempt_request + " Preempt",
    },
    {
      key: "num_of_tsp_checklist",
      color: CHART_COLOR_SCHEMA.GREEN_SIEMENS,
      label: (value) => value.num_of_tsp_checklist + " TSP",
    },
  ],

  preemption_detail: [],
  priority_detail: [],
};

export const ANALYSIS_LEGEND = {
  aor: [
    {
      key: "volume",
      label: "Volume",
      color: CHART_COLOR_SCHEMA.VOLUME,
      unit: "VPH",
      yAxis: "left",
      toolTip: (value) => Math.round(value),
      type: GRAPHIC_CHART_TYPE.line,
      legendTooltip: "analysis.legend_aor_volumn",
    },
    {
      key: "aor_volume",
      label: "AoR Volume",
      color: CHART_COLOR_SCHEMA.AOR_VOLUME,
      unit: "VPH",
      yAxis: "left",
      toolTip: (value) => Math.round(value),
      type: GRAPHIC_CHART_TYPE.line,
      legendTooltip: "analysis.legend_aor_aor_volumn",
    },
    {
      key: "aor_percent",
      label: "% AoR",
      color: CHART_COLOR_SCHEMA.AOR_PERCENT,
      unit: "%",
      yAxis: "right",
      toolTip: (value) => Math.round(value),
      type: GRAPHIC_CHART_TYPE.line,
      legendTooltip: "analysis.legend_aor_percent",
    },
  ],
  coord_health: [
    {
      key: "instep",
      label: "In-step",
      color: CHART_COLOR_SCHEMA.GREEN_SIEMENS,
      unit: "%",
      yAxis: "left",
      disabled: true,
      toolTip: (value) => Math.round(value),
      type: GRAPHIC_CHART_TYPE.line,
      legendTooltip: "analysis.legend_in_step",
    },
    {
      key: "transition",
      label: "Transition",
      disabled: true,
      color: CHART_COLOR_SCHEMA.PINK,
      unit: "%",
      yAxis: "left",
      toolTip: (value) => Math.round(value),
      type: GRAPHIC_CHART_TYPE.line,
      legendTooltip: "analysis.legend_transition",
    },
  ],
  split: [
    {
      key: "split_failure",
      color: CHART_COLOR_SCHEMA.YELLOW,
      label: " Split Failure",
      disabled: true,
      unit: "%",
      yAxis: "left",
      toolTip: (value) => Math.round(value),
      type: GRAPHIC_CHART_TYPE.ziczac,
      legendTooltip: "analysis.legend_split_failure",
    },
    {
      key: "excellent_green",
      color: CHART_COLOR_SCHEMA.GREEN_SIEMENS,
      disabled: true,
      label: " Excellent Green Utilization",
      unit: "%",
      yAxis: "left",
      toolTip: (value) => Math.round(value),
      type: GRAPHIC_CHART_TYPE.ziczac,
      legendTooltip: "analysis.legend_excellent_green",
    },
    {
      key: "poor_coord",
      color: CHART_COLOR_SCHEMA.BLUE,
      label: "Poor Coordination",
      unit: "%",
      yAxis: "left",
      disabled: true,
      toolTip: (value) => Math.round(value),
      type: GRAPHIC_CHART_TYPE.ziczac,
      legendTooltip: "analysis.legend_poor_coord",
    },
    // {
    //   key: "excess_capacity",
    //   color: CHART_COLOR_SCHEMA.GREY,
    //   label: " Excess capacity",
    //   disabled: true,
    //   unit: "%",
    //   yAxis: "left",
    //   toolTip: (value) => Math.round(value),
    //   type: GRAPHIC_CHART_TYPE.ziczac,
    //   legendTooltip: "analysis.legend_express_cap",
    // },
    {
      key: "gor",
      label: "GOR",
      color: CHART_COLOR_SCHEMA.GREEN,
      unit: "%",
      yAxis: "left",
      toolTip: (value) => Math.round(value),
      type: GRAPHIC_CHART_TYPE.ziczac,
      legendTooltip: "analysis.legend_gor",
    },
    {
      key: "ror5",
      label: "ROR5",
      color: CHART_COLOR_SCHEMA.RED,
      unit: "%",
      yAxis: "left",
      toolTip: (value) => Math.round(value),
      type: GRAPHIC_CHART_TYPE.ziczac,
      legendTooltip: "analysis.legend_ror",
    },
  ],
  custom_analysis: [
    {
      key: "active_phase",
      label: "Event code",
      color: CHART_COLOR_SCHEMA.GAP_OUT_PERCENT,
      // unit: "s into phase",
      yAxis: "left",
      toolTip: (value) => value,
      legendTooltip: "analysis.legend_pt_gap_out",
      type: GRAPHIC_CHART_TYPE.point,
    },
    {
      key: "active_pedestrian",
      label: "Event code",
      color: CHART_COLOR_SCHEMA.LIGHT_GREY,
      // unit: "s into phase",
      yAxis: "left",
      toolTip: (value) => value,
      legendTooltip: "analysis.legend_pt_gap_out",
      type: GRAPHIC_CHART_TYPE.point,
    },
    {
      key: "barrier_ring",
      label: "Event code",
      color: CHART_COLOR_SCHEMA.AOR_PERCENT,
      // unit: "s into phase",
      yAxis: "left",
      toolTip: (value) => {
        return value;
      },
      legendTooltip: "analysis.legend_pt_gap_out",
      type: GRAPHIC_CHART_TYPE.point,
    },
    {
      key: "cabinet_system",
      label: "Event code",
      color: CHART_COLOR_SCHEMA.PINK,
      // unit: "s into phase",
      yAxis: "left",
      toolTip: (value) => value,
      legendTooltip: "analysis.legend_pt_gap_out",
      type: GRAPHIC_CHART_TYPE.point,
    },
    {
      key: "coordination_group",
      label: "Event code",
      color: CHART_COLOR_SCHEMA.AVERAGE_SPLIT,
      // unit: "s into phase",
      yAxis: "left",
      toolTip: (value) => value,
      legendTooltip: "analysis.legend_pt_gap_out",
      type: GRAPHIC_CHART_TYPE.point,
    },
    {
      key: "detector_group",
      label: "Event code",
      color: CHART_COLOR_SCHEMA.BLUE,
      // unit: "s into phase",
      yAxis: "left",
      toolTip: (value) => value,
      legendTooltip: "analysis.legend_pt_gap_out",
      type: GRAPHIC_CHART_TYPE.point,
    },
    {
      key: "overlap",
      label: "Event code",
      color: CHART_COLOR_SCHEMA.BLACK,
      // unit: "s into phase",
      yAxis: "left",
      toolTip: (value) => value,
      legendTooltip: "analysis.legend_pt_gap_out",
      type: GRAPHIC_CHART_TYPE.point,
    },
    {
      key: "phase_control",
      label: "Event code",
      color: CHART_COLOR_SCHEMA.YELLOW,
      // unit: "s into phase",
      yAxis: "left",
      toolTip: (value) => value,
      legendTooltip: "analysis.legend_pt_gap_out",
      type: GRAPHIC_CHART_TYPE.point,
    },
    {
      key: "preemption",
      label: "Event code",
      color: CHART_COLOR_SCHEMA.PURPIL,
      // unit: "s into phase",
      yAxis: "left",
      toolTip: (value) => value,
      legendTooltip: "analysis.legend_pt_gap_out",
      type: GRAPHIC_CHART_TYPE.point,
    },
  ],
  aog: [
    {
      key: "volume",
      label: "Volume",
      color: CHART_COLOR_SCHEMA.VOLUME,
      unit: "VPH",
      yAxis: "left",
      toolTip: (value) => Math.round(value),
      type: GRAPHIC_CHART_TYPE.line,
      legendTooltip: "analysis.legend_aog_volumn",
    },
    {
      key: "aog_volume",
      label: "AoG Volume",
      color: CHART_COLOR_SCHEMA.BLUE,
      unit: "VPH",
      yAxis: "left",
      toolTip: (value) => Math.round(value),
      type: GRAPHIC_CHART_TYPE.line,
      legendTooltip: "analysis.legend_aog_aog_volumn",
    },
    {
      key: "aog_percent",
      label: "% AoG",
      color: CHART_COLOR_SCHEMA.AOG_PERCENT,
      unit: "%",
      yAxis: "right",
      toolTip: (value) => Math.round(value),
      type: GRAPHIC_CHART_TYPE.line,
      legendTooltip: "analysis.legend_aog_percent",
    },
  ],
  pt_highlight: [
    {
      key: "gap_out",
      label: "Gap Out",
      color: CHART_COLOR_SCHEMA.GAP_OUT_PERCENT,
      unit: "s into phase",
      yAxis: "left",
      toolTip: (value) => value,
      legendTooltip: "analysis.legend_pt_gap_out",
      type: GRAPHIC_CHART_TYPE.point,
    },
    {
      key: "max_out",
      label: "Max Out",
      color: CHART_COLOR_SCHEMA.MAX_OUT_PERCENT,
      unit: "s into phase",
      yAxis: "left",
      toolTip: (value) => value,
      legendTooltip: "analysis.legend_pt_max_out",
      type: GRAPHIC_CHART_TYPE.point,
    },
    {
      key: "force_off",
      label: "Force Off",
      color: CHART_COLOR_SCHEMA.FORCE_OFF_PERCENT,
      unit: "s into phase",
      yAxis: "left",
      legendTooltip: "analysis.legend_pt_force_off",
      toolTip: (value) => value,
      type: GRAPHIC_CHART_TYPE.point,
    },
    {
      key: "programmed_split",
      label: "Programmed Split",
      color: CHART_COLOR_SCHEMA.PROGRAM_SPLIT,
      unit: "s into phase",
      legendTooltip: "analysis.legend_pt_programmed_split",
      yAxis: "left",
      type: GRAPHIC_CHART_TYPE.line,
      toolTip: (value) => value,
    },
    {
      key: "phase_skip",
      label: "Phase Skip",
      color: CHART_COLOR_SCHEMA.PHASE_SKIP,
      unit: "s into phase",
      yAxis: "left",
      legendTooltip: "analysis.legend_pt_phase_skip",
      toolTip: (value) => value,
      type: GRAPHIC_CHART_TYPE.point,
    },
  ],
  pt_default: [
    {
      key: "gap_out",
      label: "Gap Out",
      color: CHART_COLOR_SCHEMA.GAP_OUT_PERCENT,
      unit: "s into phase",
      yAxis: "left",
      toolTip: (value) => value,
      legendTooltip: "analysis.legend_pt_gap_out",
      type: GRAPHIC_CHART_TYPE.point,
    },
    {
      key: "max_out",
      label: "Max Out",
      color: CHART_COLOR_SCHEMA.MAX_OUT_PERCENT,
      unit: "s into phase",
      yAxis: "left",
      toolTip: (value) => value,
      legendTooltip: "analysis.legend_pt_max_out",
      type: GRAPHIC_CHART_TYPE.point,
    },
    {
      key: "force_off",
      label: "Force Off",
      color: CHART_COLOR_SCHEMA.FORCE_OFF_PERCENT,
      unit: "s into phase",
      yAxis: "left",
      legendTooltip: "analysis.legend_pt_force_off",
      toolTip: (value) => value,
      type: GRAPHIC_CHART_TYPE.point,
    },
    {
      key: "programmed_split",
      label: "Programmed Split",
      color: CHART_COLOR_SCHEMA.PROGRAM_SPLIT,
      unit: "s into phase",
      legendTooltip: "analysis.legend_pt_programmed_split",
      yAxis: "left",
      type: GRAPHIC_CHART_TYPE.line,
      toolTip: (value) => value,
    },
    {
      key: "phase_skip",
      label: "Phase Skip",
      color: CHART_COLOR_SCHEMA.PHASE_SKIP,
      unit: "s into phase",
      yAxis: "left",
      legendTooltip: "analysis.legend_pt_phase_skip",
      toolTip: (value) => value,
      type: GRAPHIC_CHART_TYPE.point,
    },
  ],
  coord: [
    {
      key: "detector",
      label: "Detector Activation",
      color: CHART_COLOR_SCHEMA.DETECTOR,
      unit: "(Phase Time)",
      yAxis: "left",
      legendTooltip: "analysis.legend_coord_detector",
      toolTip: (value) => value,
      type: GRAPHIC_CHART_TYPE.point,
    },
    {
      key: "green",
      label: "Change to Green",
      color: CHART_COLOR_SCHEMA.GREEN,
      unit: "(Phase Time)",
      yAxis: "left",
      legendTooltip: "analysis.legend_coord_green",
      toolTip: (value) => value,
      type: GRAPHIC_CHART_TYPE.line,
    },
    {
      key: "yellow",
      label: "Change to Yellow",
      color: CHART_COLOR_SCHEMA.YELLOW,
      unit: "(Phase Time)",
      yAxis: "left",
      legendTooltip: "analysis.legend_coord_yellow",
      toolTip: (value) => value,
      type: GRAPHIC_CHART_TYPE.line,
    },
    {
      key: "red",
      label: "Change to Red",
      color: CHART_COLOR_SCHEMA.RED,
      unit: "(Phase Time)",
      yAxis: "left",
      legendTooltip: "analysis.legend_coord_red",
      toolTip: (value) => value,
      type: GRAPHIC_CHART_TYPE.line,
    },
    {
      key: "aog_volume",
      label: "AoG Volume",
      color: CHART_COLOR_SCHEMA.AOR_VOLUME,
      unit: "VPH",
      yAxis: "right",
      legendTooltip: "analysis.legend_coord_aog_volume",
      toolTip: (value) => value,
      type: GRAPHIC_CHART_TYPE.line,
    },
    {
      key: "volume",
      label: "Volume",
      color: CHART_COLOR_SCHEMA.VOLUME,
      unit: "VPH",
      yAxis: "right",
      legendTooltip: "analysis.legend_coord_volumn",
      toolTip: (value) => value,
      type: GRAPHIC_CHART_TYPE.line,
    },
  ],

  ped_delay: [
    {
      key: "duration",
      label: "Pedestrian Delay",
      color: CHART_COLOR_SCHEMA.DURATION,
      unit: "(s)",
      yAxis: "left",
      legendTooltip: "analysis.legend_ped-delay",
      toolTip: (value) => value,
      type: GRAPHIC_CHART_TYPE.bar,
    },
  ],
  ped_act: [
    {
      key: "green",
      label: "Ped Begin Walk",
      color: CHART_COLOR_SCHEMA.GREEN,
      unit: "(s)",
      yAxis: "left",
      legendTooltip: "analysis.legend_ped_walk",
      toolTip: (value) => value,
      type: GRAPHIC_CHART_TYPE.line,
    },
    {
      key: "yellow",
      label: "Ped Clearance",
      color: CHART_COLOR_SCHEMA.YELLOW,
      unit: "(s)",
      yAxis: "left",
      legendTooltip: "analysis.legend_ped_clearance",
      toolTip: (value) => value,
      type: GRAPHIC_CHART_TYPE.line,
    },
    {
      key: "red",
      label: "Ped Don’t Walk",
      color: CHART_COLOR_SCHEMA.RED,
      unit: "(s)",
      yAxis: "left",
      legendTooltip: "analysis.legend_ped-dont-walk",
      toolTip: (value) => value,
      type: GRAPHIC_CHART_TYPE.line,
    },
    {
      key: "det_acts",
      label: "Detector Activation",
      color: CHART_COLOR_SCHEMA.VOLUME,
      unit: "(s)",
      yAxis: "left",
      legendTooltip: "analysis.legend_detector_activation",
      toolTip: (value) => value,
      type: GRAPHIC_CHART_TYPE.point,
    },
  ],
  app_delay_lane: [
    {
      key: "delay_per_vehicle",
      label: "Approach Delay",
      color: CHART_COLOR_SCHEMA.GREEN_SIEMENS,
      unit: "(s)",
      strokeWidth: "8",
      yAxis: "left",
      toolTip: (value) => Math.round(value),
      type: GRAPHIC_CHART_TYPE.line,
      legendTooltip: "analysis.legend_delay_percent",
    },
    {
      key: "bin_volume",
      label: "Volume",
      color: CHART_COLOR_SCHEMA.VOLUME,
      unit: "",
      yAxis: "right",
      toolTip: (value) => Math.round(value),
      type: GRAPHIC_CHART_TYPE.line,
      legendTooltip: "analysis.legend_volume_app",
    },
    {
      key: "oversaturated",
      label: "Oversaturated",
      color: CHART_COLOR_SCHEMA.RED,
      strokeWidth: "4",
      unit: "(s)",
      yAxis: "left",
      toolTip: (value) => Math.round(value),
      type: GRAPHIC_CHART_TYPE.line,
      legendTooltip: "analysis.legend_delay_oversaturated",
    },
    {
      key: "detector_occupied_during_green",
      label: "Detector occupied during green",
      color: CHART_COLOR_SCHEMA.YELLOW,
      strokeWidth: "2",
      unit: "VPH",
      yAxis: "left",
      toolTip: (value) => Math.round(value),
      type: GRAPHIC_CHART_TYPE.line,
      legendTooltip: "analysis.legend_delay_occupied",
    },
  ],
  app_delay_phase: [
    {
      key: "delay_per_vehicle",
      label: "Approach Delay",
      color: CHART_COLOR_SCHEMA.GREEN_SIEMENS,
      unit: "(s)",
      strokeWidth: "8",
      yAxis: "left",
      toolTip: (value) => Math.round(value),
      type: GRAPHIC_CHART_TYPE.line,
      legendTooltip: "analysis.legend_delay_percent",
    },
    {
      key: "bin_volume",
      label: "Volume",
      color: CHART_COLOR_SCHEMA.VOLUME,
      unit: "",
      yAxis: "right",
      toolTip: (value) => Math.round(value),
      type: GRAPHIC_CHART_TYPE.line,
      legendTooltip: "analysis.legend_volume_app",
    },
    {
      key: "oversaturated",
      label: "Oversaturated",
      color: CHART_COLOR_SCHEMA.RED,
      strokeWidth: "4",
      unit: "(s)",
      yAxis: "left",
      toolTip: (value) => Math.round(value),
      type: GRAPHIC_CHART_TYPE.line,
      legendTooltip: "analysis.legend_delay_oversaturated",
    },
    {
      key: "detector_occupied_during_green",
      label: "Detector occupied during green",
      color: CHART_COLOR_SCHEMA.YELLOW,
      strokeWidth: "2",
      unit: "VPH",
      yAxis: "left",
      toolTip: (value) => Math.round(value),
      type: GRAPHIC_CHART_TYPE.line,
      legendTooltip: "analysis.legend_delay_occupied",
    },
  ],

  queue_length_phase: [
    {
      key: "queue_length",
      label: "Queue length",
      color: CHART_COLOR_SCHEMA.GREEN_SIEMENS,
      unit: "",
      yAxis: "left",
      toolTip: (value) => parseFloat(value).toFixed(2),
      type: GRAPHIC_CHART_TYPE.line,
      legendTooltip: "analysis.legend_queue_length",
    },
  ],
  queue_length_lane: [
    {
      key: "queue_length",
      label: "Queue length",
      color: CHART_COLOR_SCHEMA.GREEN_SIEMENS,
      unit: "",
      yAxis: "left",
      toolTip: (value) => parseFloat(value).toFixed(2),
      type: GRAPHIC_CHART_TYPE.line,
      legendTooltip: "analysis.legend_queue_length",
    },
  ],
  vol: [],
  [ANALYSIS_TYPE.RLV_COUNT]: [
    {
      key: "violation_count",
      label: "Violation Count",
      color: CHART_COLOR_SCHEMA.GREEN_SIEMENS,
      unit: "",
      yAxis: "left",
      toolTip: (value) => parseFloat(value).toFixed(2),
      type: GRAPHIC_CHART_TYPE.line,
      legendTooltip: null,
    },
  ],
  turning_movement: [
    {
      key: "Left",
      label: "Left",
      color: CHART_COLOR_SCHEMA.BLUE,
      unit: "",
      yAxis: "left",
      toolTip: (value) => parseFloat(value).toFixed(2),
      type: GRAPHIC_CHART_TYPE.line,
      legendTooltip: null,
    },
    {
      key: "Right",
      label: "Right",
      color: CHART_COLOR_SCHEMA.RED,
      unit: "",
      yAxis: "left",
      toolTip: (value) => parseFloat(value).toFixed(2),
      type: GRAPHIC_CHART_TYPE.line,
      legendTooltip: null,
    },
    {
      key: "Through",
      label: "Through",
      color: CHART_COLOR_SCHEMA.VOLUME,
      unit: "",
      yAxis: "left",
      toolTip: (value) => parseFloat(value).toFixed(2),
      type: GRAPHIC_CHART_TYPE.line,
      legendTooltip: null,
    },
    {
      key: "Through/Left",
      label: "Through/Left",
      color: CHART_COLOR_SCHEMA.LOWER_GREEN,
      unit: "",
      yAxis: "left",
      toolTip: (value) => parseFloat(value).toFixed(2),
      type: GRAPHIC_CHART_TYPE.line,
      legendTooltip: null,
    },
    {
      key: "Through/Right",
      label: "Through/Right",
      color: CHART_COLOR_SCHEMA.PURPIL,
      unit: "",
      yAxis: "left",
      toolTip: (value) => parseFloat(value).toFixed(2),
      type: GRAPHIC_CHART_TYPE.line,
      legendTooltip: null,
    },
  ],

  split_monitor: [
    {
      key: "yellow_time",
      color: CHART_COLOR_SCHEMA.YELLOW,
      label: " Yellow Time",
      disabled: true,
      // unit: "%",
      yAxis: "left",
      // toolTip: (value) => Math.round(value),
      type: GRAPHIC_CHART_TYPE.bar,
      legendTooltip: "analysis.legend_yellow_time",
    },
    {
      key: "red_time",
      color: CHART_COLOR_SCHEMA.RED,
      disabled: true,
      label: " Red Time",
      // unit: "%",
      yAxis: "left",
      // toolTip: (value) => Math.round(value),
      type: GRAPHIC_CHART_TYPE.bar,
      legendTooltip: "analysis.legend_red_time",
    },
    {
      key: "green_time",
      color: CHART_COLOR_SCHEMA.LOWER_GREEN,
      label: " Green Time",
      // unit: "%",
      yAxis: "left",
      disabled: true,
      // toolTip: (value) => Math.round(value),
      type: GRAPHIC_CHART_TYPE.bar,
      legendTooltip: "analysis.legend_green_time",
    },
    {
      key: "green_time_coord",
      color: CHART_COLOR_SCHEMA.GREEN_SIEMENS,
      label: " Green Time of Coord Phase",
      // unit: "%",
      yAxis: "left",
      disabled: true,
      // toolTip: (value) => Math.round(value),
      type: GRAPHIC_CHART_TYPE.bar,
      legendTooltip: "analysis.legend_green_time_coord",
    },
  ],
  //preempt priority
  pp_request: [
    {
      key: "preempt_requests",
      label: "Preempt Request",
      color: CHART_COLOR_SCHEMA.BLUE,
      unit: "",
      yAxis: "left",
      toolTip: (value) => value,
      type: GRAPHIC_CHART_TYPE.point,
      legendTooltip: "analysis.preempt_request_tooltip",
    },
    {
      key: "tsp_check_in_list",
      label: "TSP Check-in",
      color: CHART_COLOR_SCHEMA.GREEN,
      unit: "",
      yAxis: "left",
      toolTip: (value) => parseFloat(value).toFixed(2),
      type: GRAPHIC_CHART_TYPE.point,
      legendTooltip: "analysis.tsp_check_in_list_tooltip",
    },
  ],

  preemption_detail: [
    //105
    {
      key: "entry_delay",
      label: "Entry Delay",
      color: CHART_COLOR_SCHEMA.RED,
      unit: "",
      yAxis: "left",
      toolTip: (value) => parseFloat(value).toFixed(2),
      type: GRAPHIC_CHART_TYPE.bar,
      legendTooltip: "analysis.entry_delay_tooltip",
    },

    // 106 bar
    {
      key: "track_clear_time",
      label: "Track Clearance 106",
      color: CHART_COLOR_SCHEMA.GRAY,
      hidden: true,
      unit: "",
      yAxis: "left",
      toolTip: (value) => parseFloat(value).toFixed(2),
      type: GRAPHIC_CHART_TYPE.bar,
      legendTooltip: "analysis.track_clear_interval_tooltip",
    },
    //106
    {
      key: "d_well_time",
      label: "Track Clearance",
      color: CHART_COLOR_SCHEMA.YELLOW,
      unit: "",
      yAxis: "left",
      toolTip: (value) => parseFloat(value).toFixed(2),
      type: GRAPHIC_CHART_TYPE.bar,
      legendTooltip: "analysis.entry_delay_tooltip",
    },
    //107 time
    {
      key: "exit_time",
      label: "Dwell Time",
      color: CHART_COLOR_SCHEMA.GREEN_SIEMENS,
      unit: "",
      yAxis: "left",
      toolTip: (value) => parseFloat(value).toFixed(2),
      type: GRAPHIC_CHART_TYPE.bar,
      legendTooltip: "analysis.d_well_time_tooltip",
    },

    // //111
    // {
    //   key: "exit_time_point",
    //   label: "End Call",
    //   color: CHART_COLOR_SCHEMA.PINK,
    //   unit: "",
    //   yAxis: "left",
    //   toolTip: (value) => parseFloat(value).toFixed(2),
    //   type: GRAPHIC_CHART_TYPE.point,
    //   legendTooltip: "analysis.exit_time_tooltip",
    // },

    {
      key: "track_clear_interval",
      label: "Track Clear(106)",
      color: CHART_COLOR_SCHEMA.DURATION,
      unit: "",
      yAxis: "left",
      toolTip: (value) => parseFloat(value).toFixed(2),
      type: GRAPHIC_CHART_TYPE.point,
      legendTooltip: "analysis.track_clear_time_tooltip",
    },
    {
      key: "max_out_time",
      label: "Max out(110)",
      color: CHART_COLOR_SCHEMA.PURPIL,
      unit: "",
      yAxis: "left",
      toolTip: (value) => parseFloat(value).toFixed(2),
      type: GRAPHIC_CHART_TYPE.point,
      legendTooltip: "analysis.max_out_time_tooltip",
    },
    {
      key: "input_off_times",
      label: "Input off(104)",
      color: CHART_COLOR_SCHEMA.GREY,
      unit: "",
      yAxis: "left",
      toolTip: (value) => parseFloat(value).toFixed(2),
      type: GRAPHIC_CHART_TYPE.point,
      legendTooltip: "analysis.exit_time_tooltip",
    },
  ],

  priority_detail: [
    {
      key: "tsp_check_in",
      label: "TSP Check-in",
      color: CHART_COLOR_SCHEMA.GREEN_SIEMENS,
      unit: "",
      yAxis: "left",
      toolTip: (value) => parseFloat(value).toFixed(2),
      type: GRAPHIC_CHART_TYPE.point,
      legendTooltip: "analysis.tsp_check_in_tooltip",
    },
    {
      key: "adj_early_green",
      label: "Adjustment to Early Green",
      color: CHART_COLOR_SCHEMA.YELLOW,
      unit: "",
      yAxis: "left",
      toolTip: (value) => parseFloat(value).toFixed(2),
      type: GRAPHIC_CHART_TYPE.point,
      legendTooltip: "analysis.adj_early_green_tooltip",
    },
    {
      key: "adj_extend_green",
      label: "Adjustment to Extend Green",
      color: CHART_COLOR_SCHEMA.GREEN,
      unit: "",
      yAxis: "left",
      toolTip: (value) => parseFloat(value).toFixed(2),
      type: GRAPHIC_CHART_TYPE.point,
      legendTooltip: "analysis.adj_extend_green_tooltip",
    },
    {
      key: "tsp_check_out",
      label: "TSP Checkout",
      color: CHART_COLOR_SCHEMA.PINK,
      unit: "",
      yAxis: "left",
      toolTip: (value) => parseFloat(value).toFixed(2),
      type: GRAPHIC_CHART_TYPE.point,
      legendTooltip: "analysis.tsp_check_out_tooltip",
    },
  ],
};

export const ANALYSIS_EXTRA_TYPE = {
  [ANALYSIS_TYPE.PED_TYPE]: [
    {
      key: ANALYSIS_TYPE.PED_DELAY,
      value: ANALYSIS_TYPE.PED_DELAY,
      label: "PEDESTRIAN_DELAY_LABEL",
    },
    {
      key: ANALYSIS_TYPE.PED_ACTIVITIES,
      value: ANALYSIS_TYPE.PED_ACTIVITIES,
      label: "PEDESTRIAN_ACTIVITIES_LABEL",
    },
  ],
  [ANALYSIS_TYPE.PT_TYPE]: [
    {
      key: ANALYSIS_TYPE.PT_HIGHLIGHT_TYPE,
      value: ANALYSIS_TYPE.PT_HIGHLIGHT_TYPE,
      label: "PT_HIGHLIGHT_TYPE",
    },
    {
      key: ANALYSIS_TYPE.DEFAULT_PT,
      value: ANALYSIS_TYPE.DEFAULT_PT,
      label: "DEFAULT_PT",
    },
  ],

  [ANALYSIS_TYPE.APPOARCH_DELAY]: [
    {
      key: ANALYSIS_TYPE.APPOARCH_DELAY_PHASE_VIEW,
      value: ANALYSIS_TYPE.APPOARCH_DELAY_PHASE_VIEW,
      label: "APPOARCH_DELAY_PHASE_VIEW",
    },
    {
      key: ANALYSIS_TYPE.APPOARCH_DELAY_LANE_VIEW,
      value: ANALYSIS_TYPE.APPOARCH_DELAY_LANE_VIEW,
      label: "APPOARCH_DELAY_LANE_VIEW",
    },
  ],

  [ANALYSIS_TYPE.PREEMPTION_PRIORITY]: [
    {
      key: ANALYSIS_TYPE.PREEMPTION_PRIORITY_REQUEST,
      value: ANALYSIS_TYPE.PREEMPTION_PRIORITY_REQUEST,
      label: "PREEMPTION_PRIORITY_REQUEST",
    },
    {
      key: ANALYSIS_TYPE.PREEMPTION_PRIORITY_DETAIL,
      value: ANALYSIS_TYPE.PREEMPTION_PRIORITY_DETAIL,
      label: "PREEMPTION_PRIORITY_DETAIL",
    },
    {
      key: ANALYSIS_TYPE.PREEMPTION_PRIORITY_TSP_DETAIL,
      value: ANALYSIS_TYPE.PREEMPTION_PRIORITY_TSP_DETAIL,
      label: "PREEMPTION_PRIORITY_TSP_DETAIL",
    },
  ],

  [ANALYSIS_TYPE.QUEUE_LENGTH]: [
    {
      key: ANALYSIS_TYPE.QUEUE_LENGTH_PHASE_VIEW,
      value: ANALYSIS_TYPE.QUEUE_LENGTH_PHASE_VIEW,
      label: "QUEUE_LENGTH_PHASE_VIEW",
    },
    {
      key: ANALYSIS_TYPE.QUEUE_LENGTH_LANE_VIEW,
      value: ANALYSIS_TYPE.QUEUE_LENGTH_LANE_VIEW,
      label: "QUEUE_LENGTH_LANE_VIEW",
    },
  ],
  [ANALYSIS_TYPE.MOE_ANALYSIS]: [
    {
      key: ANALYSIS_TYPE.QUEUE_LENGTH_PHASE_VIEW,
      value: ANALYSIS_TYPE.QUEUE_LENGTH_PHASE_VIEW,
      label: "QUEUE_LENGTH_PHASE_VIEW",
    },
    {
      key: ANALYSIS_TYPE.QUEUE_LENGTH_LANE_VIEW,
      value: ANALYSIS_TYPE.QUEUE_LENGTH_LANE_VIEW,
      label: "QUEUE_LENGTH_LANE_VIEW",
    },
  ],
};

export const TLC_EVENTS = {
  GREEN: 1,
  YELLOW: 8,
  RED: 10,
};

export const CUSTOM_ANALYSIS_KEY = {
  DETECTOR: "detector_group",
  COORDINATION: "coordination_group",
  CABINET: "cabinet_system",
};

export const PATTERNS = {
  CODE_FOR_ALL: -1
}