import React, { Component } from "react";
import { Select } from "antd";
// import RingChart from "./RingChart/index";
import { withTranslation } from "react-i18next";
import moment from "moment";
import RingChartProgrammed from "./RingChart/RingChartProgrammed";

const Option = Select.Option;

class SplitMonitorProgrammed extends Component {
  constructor(props) {
    super(props)
    this.state = {
      key: Object.keys(props.data)[0],
    }
  }
  
  changeKey = (value) => {
    this.setState({key: value})
  }

  calculateChartWidth = (cycle) => {
    const chartScale = (650 - 180 - 25)/120000
    const cycleDuration = moment.duration(moment(cycle.ring1.slice(-1)[0].end_time) - moment(cycle.ring1[0].start_time));
    const width = 180 + 25 + cycleDuration * chartScale

    return width;
  }

  
  render() {
    const {t, data, isPrintMode} = this.props

    return(
      <div height="100px">
        {isPrintMode ? (
          <>
            <div className="font-bold" style={{paddingBottom: "20px"}}>
              {t("analysis.programmed_cycle")}
            </div>
            
            {Object.keys(data).map(key => (
              data[key] 
                &&
              <div key={key} display="flex">
                <div>Plan {data[key].no_plan}: </div>
                {/* <RingChart width={this.calculateChartWidth(data[key])} type="programmed" data={data[key]}/> */}
              </div>  
            ))}
          </>
        ) : (
          <div style={{display: "flex", gap: "20px", alignItems: "center", height:"100px"}}>
            <div className="font-bold" style={{paddingRight: "40px"}}>
              {t("analysis.programmed_cycle")}
            </div>
            <div>{t("analysis.select_a_plan")}</div>
            <div style={{width: "80px"}}>
              <Select
                onChange={(value) => this.changeKey(value)}
                defaultValue={data[this.state.key] ? this.state.key : 254}
              >
                {Object.keys(data).map(key => (
                  data[key] ? (
                    <Option value={key} key={key}>
                      {data[key].no_plan}
                    </Option>
                  ) : (
                    <Option value={254} key={254}>
                      {254}
                    </Option>
                  ) 
                ))}
              </Select>
            </div>
            {/* {data[this.state.key] && <RingChart key={this.state.key} width={this.calculateChartWidth(data[this.state.key])} type="programmed" data={data[this.state.key]}/>} */}
            {data[this.state.key] && <RingChartProgrammed key={this.state.key} width={this.calculateChartWidth(data[this.state.key])} data={data[this.state.key]}/>}
          </div>
        )}
      </div>
      
      
    )
  }
}

export default withTranslation()(SplitMonitorProgrammed);