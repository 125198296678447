import React, { Component } from "react";
import "./style.scss";

class HorizontalTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: [],
    };
  }
  componentDidMount() {
    this.refactorData();
  }
  refactorData = (rerender = false) => {
    let w = window.innerWidth;
    let arrayTranform = [];
    let cellPerRow = Math.ceil((w - 280) / 72);
    this.props.data?.forEach((element, id) => {
      if (id % cellPerRow === 0) arrayTranform.push([]);
      arrayTranform[arrayTranform.length - 1].push(element);
    });
    if (rerender)
      this.setState({
        tableData: arrayTranform,
      });
    return arrayTranform;
  };
  componentDidMount() {
    // this.refactorData()
  }
  render() {
    const tableData = this.refactorData();
    return (
      <div
        className="horizontal-table-pane"
        style={{
          maxHeight: this.props.maxHeight ? this.props.maxHeight : null,
        }}
      >
        <table className="horizontal-table">
          <tbody>
            {tableData?.map((breakRow) => {
              return (this.props?.tableColumn).map((keyObject) => (
                <tr
                  key={keyObject.key}
                  className={
                    keyObject.key === this.props.highlightRowKey
                      ? "highlight-row"
                      : ""
                  }
                >
                  <th>{keyObject.label}</th>
                  {breakRow?.map((cell, cellId) => (
                    <td key={cellId}>{cell[keyObject?.key]}</td>
                  ))}
                </tr>
              ));
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

export default HorizontalTable;
