import { Button, Col, Form, Row, Select } from "antd";
import React from "react";
const { Option } = Select;

/**
 * RoleCompoenent
 *
 *
 * @component
 */
class RoleCompoenent extends React.Component {
  /**
   * handleDelete
   * @param   {Interger} index  index of item to delete
   * @return  {null} -  trigger the data validation
   */
  handleDelete = (index) => {
    this.props.handleDeleteAgencyRole(index, (data) => {
      this.triggerChange(data);
    });
  };

  /**
   * handleAdd
   * @return  {null} -  trigger the data validation
   */
  handleAdd = () => {
    this.props.handleAddNewRole((data) => {
      this.triggerChange(data);
    });
  };

  /**
   * handleAgencyChange
   * @param   {Interger} value  new value
   * @param   {Interger} index  index of item
   * @return  {null} -  trigger the data validation
   */
  handleAgencyChange = (value, index) => {
    this.props.handleAgencyChange(value, index, (data) => {
      this.triggerChange(data);
    });
  };
  /**
   * handleRoleChange
   * @param   {Interger} value  new value
   * @return  {null} -  trigger the data validation
   */
  handleRoleChange = (value) => {
    this.props.handleRoleChange(value, (data) => {
      this.triggerChange(data);
    });
  };
  /**
   * handleAgencyRoleChange
   * @param   {Interger} value  new value
   * @param   {Interger} index  index of item
   * @return  {null} -  trigger the data validation
   */
  handleAgencyRoleChange = (value, index) => {
    this.props.handleAgencyRoleChange(value, index, (data) => {
      this.triggerChange(data);
    });
  };

  /**
   * handleAgencyRoleChange
   * @param   {Object} changedValue  new value
   * @return  {null} -  trigger the data validation
   */
  triggerChange = (changedValue) => {
    const { onChange } = this.props;
    if (onChange) {
      onChange({
        ...changedValue,
      });
    }
  };

  /**
   * renderAgencyRoleItem.
   * @param   {Object} data  Data of one agencyRole row
   * @param   {index} index  index of the data in roles list
   *
   */
  renderAgencyRoleItem = (data, index) => {
    const { t, editable, listNormalRoles, listAllUserAgency } = this.props;
    if (!data.agency_id || !data.role_name) return;
    return (
      <Row key={index} gutter={8}>
        <Col span={editable ? 10 : 12}>
          <Form.Item label={index === 0 ? t("user.agency") : null}>
            {editable ? (
              <Select
                labelInValue
                value={{ key: data.agency_name }}
                onChange={(value) => {
                  this.handleAgencyChange(value, index);
                }}
              >
                {listAllUserAgency?.map((e) => (
                  <Option key={e.id} value={e.id}>
                    {e.name}
                  </Option>
                ))}
              </Select>
            ) : (
              data.agency_name
            )}
          </Form.Item>
        </Col>
        <Col span={editable ? 14 : 12}>
          <Form.Item label={index === 0 ? t("user.role") : null}>
            {editable ? (
              <Row key={index + "_" + data.role_name} gutter={8}>
                <Col span={20}>
                  <Select
                    labelInValue
                    value={{ key: data.role_name }}
                    onChange={(value) => {
                      this.handleAgencyRoleChange(value, index);
                    }}
                  >
                    {listNormalRoles?.map((e) => (
                      <Option key={e.name} value={e.name}>
                        {e.name}
                      </Option>
                    ))}
                  </Select>
                </Col>
                <Col span={4}>
                  <Button
                    span={4}
                    type="link"
                    icon="delete"
                    onClick={() => {
                      this.handleDelete(index);
                    }}
                  />
                </Col>
              </Row>
            ) : (
              data.role_name
            )}
          </Form.Item>
        </Col>
      </Row>
    );
  };

  /**
   * render
   *
   * @return  {Component}
   */
  render() {
    const { formData, editable, commonStore, listAllRoles, t } = this.props;
    let isCurrentAdmin = commonStore.isSuperAdmin();
    return (
      <Row>
        {editable ? (
          <Select
            onChange={this.handleRoleChange}
            value={formData.current_role}
          >
            {listAllRoles?.map((e) => (
              <Option key={e.name} value={e.name}>
                {e.name}
              </Option>
            ))}
          </Select>
        ) : (
          commonStore.isSuperAdmin(formData.current_role) &&
          formData.current_role
        )}

        {editable && isCurrentAdmin && !formData.isSuperAdmin && (
          <Button type="link" icon="plus" onClick={this.handleAdd}>
            {" "}
            {t("user.add_agency")}
          </Button>
        )}
        {!formData?.isSuperAdmin &&
          isCurrentAdmin &&
          formData?.roles?.map((data, index) => {
            return this.renderAgencyRoleItem(data, index);
          })}
      </Row>
    );
  }
}

export default RoleCompoenent;
