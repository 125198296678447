import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import {
  Button,
  Typography,
  Select,
  Checkbox,
  DatePicker,
  Col,
  List,
  InputNumber,
} from "antd";
import "../../style.scss";
import { inject, observer } from "mobx-react";
import {
  DASHBOARD_WIDGETS,
  MAP_PERIODS,
  TIME_PERIODS,
} from "../../../../utils/constants/dashboardConstant";
import RightSideBar from "../../../../components/RightSideBar";
import {
  DATE_FORMAT,
  DEFAULT_CONDITION_SUMMARY_REPORT,
  TIMESTAMP,
} from "../../../../utils/constants";
import moment from "moment";

const { Option } = Select;
const { RangePicker } = DatePicker;

/**
 * Component for showing user info pannel
 *
 * @component
 */
@inject("rootStore")
@observer
class SummaryReportSetting extends Component {
  constructor(props) {
    super(props);
  }

  cancelSummaryReportSetting = () => {
    const {
      rootStore: {
        dashboardStore: { resetSummaryReportSetting, toggleWidgetSetting },
      },
      onClose,
    } = this.props;

    resetSummaryReportSetting && resetSummaryReportSetting();
    toggleWidgetSetting(false);
    onClose && onClose();
  };

  saveSummaryReportSetting = () => {
    const {
      rootStore: {
        dashboardStore: { patchDashboardSetting },
      },
      onClose,
    } = this.props;

    patchDashboardSetting &&
      patchDashboardSetting(DASHBOARD_WIDGETS.SUMMARY_REPORT);
    onClose && onClose();
  };

  isValidPeriod = (periodType, timeRange) => {
    const { t } = this.props;
    if (periodType === TIME_PERIODS.CUSTOM) {
      if (timeRange?.length !== 2 || !timeRange[0] || !timeRange[1]) {
        return t("dashboard.time_period_in_valid");
      }
      if (
        moment.unix(timeRange[1].unix()) - moment.unix(timeRange[0].unix()) ===
        0
      ) {
        return t("dashboard.time_in_valid");
      }
      if (timeRange[1] - timeRange[0] >= TIMESTAMP.ONE_DAY) {
        return t("dashboard.time_period_in_valid");
      }
    }
    return "";
  };

  handleMetricEnable = (item, enabled) => {
    const {
      rootStore: {
        dashboardStore: { summaryReportSetting, changeSummaryReportSetting },
      },
    } = this.props;
    const newMetrics = summaryReportSetting.metrics.map((el) => {
      if (el.id === item.id) {
        return {
          ...el,
          enabled: enabled,
        };
      }
      return el;
    });
    changeSummaryReportSetting({ metrics: newMetrics });
  };

  handleMetricChange = (item, change) => {
    const {
      rootStore: {
        dashboardStore: { summaryReportSetting, changeSummaryReportSetting },
      },
    } = this.props;
    const newMetrics = summaryReportSetting.metrics.map((el) => {
      if (el.id === item.id) {
        return {
          ...el,
          warningCondition: {
            ...el.warningCondition,
            ...change,
          },
        };
      }
      return el;
    });
    changeSummaryReportSetting({ metrics: newMetrics });
  };

  renderItems = (item) => {
    return (
      <List.Item>
        <Col span={4}>
          <Checkbox
            className="w-full"
            style={{ marginLeft: "8px" }}
            checked={item.enabled}
            onChange={(event) =>
              this.handleMetricEnable(item, event.target.checked)
            }
          />
        </Col>
        <Col span={8}>{item.label_text}</Col>
        <Col>
          <Select
            style={{ width: 80 }}
            onChange={(value) =>
              this.handleMetricChange(item, { operator: value })
            }
            value={
              item.warningCondition?.operator
                ? item.warningCondition?.operator
                : DEFAULT_CONDITION_SUMMARY_REPORT
            }>
            {item.operators.map((operator) => {
              return (
                <Option key={operator} value={operator}>
                  {operator}
                </Option>
              );
            })}
          </Select>
          <InputNumber
            style={{ width: 80, marginLeft: "10px" }}
            value={item.warningCondition?.value}
            onChange={(value) =>
              this.handleMetricChange(item, { value: value })
            }
            max={item.max_value || Number.MAX_SAFE_INTEGER}
            min={item.min_value || Number.MIN_SAFE_INTEGER}
          />
        </Col>
      </List.Item>
    );
  };

  renderHeader = () => {
    const { t } = this.props;

    return (
      <List.Item>
        <Col span={4}>{t("summary_report.summary_report_header_enable")}</Col>
        <Col span={8}>{t("summary_report.summary_report_header_metrics")}</Col>
        <Col>{t("summary_report.summary_report_header_condition")}</Col>
      </List.Item>
    );
  };

  /**
   * render
   *
   * @return  {Component}
   */
  render() {
    const {
      t,
      rootStore: {
        dashboardStore: {
          widgetSetting,
          summaryReportSetting: { changed, period, timeRange, metrics },
          changeSummaryReportSetting,
          savingSetting,
        },
      },
    } = this.props;

    return (
      <RightSideBar
        visible={widgetSetting}
        title={t("dashboard.widget_settings")}
        onClose={this.cancelSummaryReportSetting}>
        <div className="setting-info-panel setting-summary-report">
          <Typography.Paragraph>
            {t("summary_report.summary_report_title") +
              " - " +
              t("dashboard.widget_settings_subtitle")}
          </Typography.Paragraph>
          <div className="setting-section">
            <span className="setting-label">{t("map_setting.period")}:</span>
            <Select
              className="setting-select"
              size="small"
              defaultValue={period}
              onChange={(val) => changeSummaryReportSetting({ period: val })}>
              {MAP_PERIODS?.map((e) => {
                return (
                  <Option key={e.value} value={e.value}>
                    {t(e.label)}
                  </Option>
                );
              })}
            </Select>
            {period === TIME_PERIODS.CUSTOM && (
              <>
                <RangePicker
                  style={{ width: "100%", marginTop: "0.5rem" }}
                  format={DATE_FORMAT.full}
                  value={timeRange}
                  allowClear={false}
                  onChange={(val) =>
                    changeSummaryReportSetting({ timeRange: val })
                  }
                  showTime
                />
                <Typography.Text type="danger">
                  {this.isValidPeriod(period, timeRange)}
                </Typography.Text>
              </>
            )}
            <div className="setting-section">
              {t("summary_report.summary_report_metrics")}
            </div>
            <div className="setting-section">
              <List
                className="redundant-padding-list"
                dataSource={metrics}
                itemLayout="horizontal"
                renderItem={this.renderItems}
                header={this.renderHeader()}
              />
            </div>
          </div>
          <div className="flex justify-end w-full mt-8 setting-footer">
            <Button
              type="default"
              onClick={this.cancelSummaryReportSetting}
              disabled={savingSetting}>
              {t("cancel")}
            </Button>
            <Button
              type="primary"
              style={{ marginLeft: 5 }}
              onClick={this.saveSummaryReportSetting}
              loading={savingSetting}
              disabled={!changed || this.isValidPeriod(period, timeRange)}>
              {t("save")}
            </Button>
          </div>
        </div>
      </RightSideBar>
    );
  }
}

export default withTranslation()(SummaryReportSetting);
