import { Badge, Col, Empty, List, Row } from "antd";
import * as d3 from "d3";
import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import DoughnutChart from "../../../components/DoughnutChart";
import "./style.scss";
import { TIME_PERIODS } from "../../../utils/constants/dashboardConstant";
import { DATE_FORMAT } from "../../../utils/constants";

@inject("rootStore")
@observer
class Notification extends Component {
  constructor(props) {
    super(props);
    this.colors = d3.scaleOrdinal(d3.schemeCategory10);
    this.format = d3.format(".1f");
    this.chartContainerRef = React.createRef();
    this.wrapRef = React.createRef();
    this.state = {
      chartSize: 200,
      compact: true,
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = () => {
    const { width: wrapWidth } = this.wrapRef.current?.getBoundingClientRect();
    try {
      const { width, height } =
        this.chartContainerRef.current?.getBoundingClientRect();
      this.setState({
        chartSize: Math.min(width, height),
        compact: wrapWidth < 450,
      });
    } catch (e) {
      this.setState({
        chartSize: 200,
        compact: wrapWidth < 450,
      });
    }
  };

  render() {
    const {
      t,
      rootStore: {
        dashboardStore: {
          notification: { data },
          notificationSetting: { period, timeRange },
        },
      },
      skeleton,
    } = this.props;
    const { chartSize, compact } = this.state;
    const chartData =
      data.notification_types?.map((el) => ({
        name: t(el.name),
        value: el.total,
        color: this.colors(el.type_id),
      })) || [];
    return (
      <div className="wrap-notification" ref={this.wrapRef}>
        {skeleton ? (
          <div className="widget-header-title">
            {t("dashboard.notification-header")}
          </div>
        ) : (
          <>
            <div className="widget-header">
              <div className="widget-header-title">
                {t("dashboard.notification-header")}
              </div>
              <div className="widget-header-subtitle">
                {period === TIME_PERIODS.CUSTOM
                  ? timeRange[0]?.utc().format(DATE_FORMAT.withTimeZone) +
                    " - " +
                    timeRange[1]?.utc().format(DATE_FORMAT.withTimeZone)
                  : t("map_setting." + period?.toLowerCase())}
              </div>
            </div>
            {chartData.length > 0 ? (
              <div className="wrap-chart styled-scroll">
                <div
                  className={compact ? "chart-box-compact" : "chart-box"}
                  ref={this.chartContainerRef}
                >
                  <DoughnutChart
                    key={`size-${chartSize}px`}
                    data={chartData}
                    title={t("dashboard.chart-title")}
                    totalData={data.total_count}
                    width={chartSize}
                    height={chartSize}
                    innerRadius={chartSize / 3 - 10}
                    outerRadius={chartSize / 2 - 10}
                  />
                </div>

                <div
                  className={
                    compact ? "chart-info-box-compact" : "chart-info-box"
                  }
                >
                  <div>
                    <div className="flex align-items-center">
                      <div className="content">
                        {t("dashboard.new-notification-header")}:
                      </div>
                      <div className="number">{data.new_notification || 0}</div>
                    </div>
                    <div className="flex  align-items-center">
                      <div className="content">
                        {t("dashboard.flagged-notification-header")}:{" "}
                      </div>
                      <div className="number">
                        {data.flag_notification || 0}
                      </div>
                    </div>
                  </div>
                  <List
                    className="notification"
                    itemLayout="horizontal"
                    dataSource={chartData}
                    renderItem={(item) =>
                      item && (
                        <List.Item>
                          <Row className="chart-description">
                            <Col span={2} className="pie-color">
                              <Badge
                                style={{ height: "14px" }}
                                color={item?.color}
                              />
                            </Col>
                            <Col span={14} className="pie-name">
                              {item?.name}
                            </Col>
                            <Col span={3} className="pie-value">
                              {item?.value}
                            </Col>
                          </Row>
                        </List.Item>
                      )
                    }
                  />
                </div>
              </div>
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
          </>
        )}
      </div>
    );
  }
}

export default withTranslation()(Notification);
