import { observable, action, runInAction } from "mobx";
import Api from '../services/Api';

class InitStore {
    @observable userList = [];

    constructor(parentStore) {
        this.parentStore = parentStore;
    }

    @action("init data") initData = async () => {
        // this.fetchUserList();
    }

    @action("reset data") resetData = async () => {
        runInAction(() => {
            this.userList = [];
        });
    }

    fetchUserList = () => {
        const api = new Api("users", this.parentStore);
        if (this.userList.length === 0) {
            api.getRequest(null, (response) => {
                runInAction(() => {
                    this.userList = response?.data;
                });
            });
        }
    }

}

export default InitStore;