import { Button, Modal, PageHeader, Tabs } from "antd";
import SelectIntersectionTab from "components/SelectIntersectionTab";
import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import AvailableIntersectionStore from "stores/AvailableIntersectionStore";
import SelectIntersectionStore from "stores/SelectIntersectionStore";
import { ACTIONS_LIST, RESOURCE_LIST } from "utils/constants";
import AlarmRuleDetail from "../AlarmRuleDetail";
import AlarmRuleSubscriber from "../AlarmRuleDetail/AlarmRuleSubscriber";
import "./style.scss";
const { confirm } = Modal;
const { TabPane } = Tabs;
const tabIndex = {
  firstTab: "1",
  secondTab: "2",
  thirdTab: "3",
};

/**
 * Component for showing details of the user.
 *
 * @component
 */
@inject("rootStore")
@observer
class AlarmRuleDetailPage extends Component {
  constructor(props) {
    super(props);
    this.selectIntersectionStore = new SelectIntersectionStore(
      props.match.params.id,
      "alarm/rules",
      props.rootStore,
      "intersection_ids"
    );
    this.addMoreIntersectionStore = new AvailableIntersectionStore(
      props.rootStore,
      "alarm/rules",
      "ALARM_RULES"
    );
  }

  ruleDetail = null;
  ruleSub = null;
  ruleIntersection = null;

  componentDidMount() {
    const {
      match: { params },
      rootStore: {
        alarmRuleStore: { changeCreateRuleTab, setTemplateId },
      },
    } = this.props;
    setTemplateId(params.id);
    changeCreateRuleTab(tabIndex.firstTab);
  }

  renderHeaderAction = () => {
    const {
      t,
      history,
      rootStore: {
        alarmRuleStore: { activeCreateTab },
        myStore,
      },
    } = this.props;
    return (
      activeCreateTab == tabIndex.firstTab && (
        <div className="header-function">
          <Button onClick={() => history.push("/alarms/rules")}>
            {t("alarm_rules.cancel")}
          </Button>
          {myStore.hasPermission(
            ACTIONS_LIST.CREATE,
            RESOURCE_LIST.ALARM_RULE,
            true
          ) && (
            <Button onClick={() => this.ruleDetail?.handleDuplicate()}>
              {t("alarm_rules.duplicate")}
            </Button>
          )}
          {myStore.hasPermission(
            ACTIONS_LIST.EDIT,
            RESOURCE_LIST.ALARM_RULE_DETAIL,
            true
          ) && (
            <Button onClick={() => this.ruleDetail?.handleUpdate()}>
              {t("alarm_rules.save")}
            </Button>
          )}
        </div>
      )
    );
  };
  hanldeChangeTab = (key) => {
    const {
      t,
      rootStore: {
        alarmRuleStore: {
          activeCreateTab,
          changeCreateRuleTab,
          isEditFormDirty,
          confirmLeaveRuleDetail,
        },
      },
    } = this.props;
    let ruleDetail = this.ruleDetail;
    if (key !== activeCreateTab) {
      if (activeCreateTab === tabIndex.firstTab && isEditFormDirty) {
        confirm({
          title: t("common.confirm_redirect_title"),
          content: t("common.confirm_redirect_content"),
          okText: t("common.leave"),
          okType: "danger",
          cancelText: t("common.stay"),
          onOk() {
            ruleDetail?.props?.form?.resetFields();
            confirmLeaveRuleDetail(key);
          },
        });
      } else {
        changeCreateRuleTab(key);
      }
    }
  };
  render() {
    const {
      t,
      match: { params },
      rootStore: {
        myStore,
        alarmRuleStore,
        alarmRuleStore: { activeCreateTab, context_id, createRuleDetail },
      },
    } = this.props;
    const isOperator = myStore.getCurrentRoleName() == "OPERATOR";
    return (
      <div className="wrap-alarm-rule-detail-page">
        <PageHeader
          title={t("alarm_rules.edit_rule_title")}
          extra={this.renderHeaderAction()}
        >
          <Tabs
            className="edit-rule-tab"
            defaultActiveKey={tabIndex.firstTab}
            type="card"
            activeKey={activeCreateTab}
            onTabClick={this.hanldeChangeTab}
          >
            <TabPane tab="Rule Details" key={tabIndex.firstTab}>
              <AlarmRuleDetail
                editingMode={ACTIONS_LIST.EDIT}
                childRef={(ref) => (this.ruleDetail = ref)}
                id={params?.id}
              ></AlarmRuleDetail>
            </TabPane>
            <TabPane
              tab="Subscribers"
              key={tabIndex.secondTab}
              disabled={isOperator}
            >
              <AlarmRuleSubscriber
                editingMode={ACTIONS_LIST.EDIT}
                childRef={(ref) => (this.ruleSub = ref)}
                ruleId={params?.id}
              ></AlarmRuleSubscriber>
            </TabPane>
            <TabPane tab="Intersections" key={tabIndex.thirdTab}>
              {/* <AlarmRuleIntersection
                context_id={context_id}
                addMoreIntersectionStore={this.addMoreIntersectionStore}
                editingMode={ACTIONS_LIST.EDIT}
                childRef={(ref) => (this.ruleIntersection = ref)}
                ruleId={params?.id}></AlarmRuleIntersection> */}
              <SelectIntersectionTab
                addMoreIntersectionStore={this.addMoreIntersectionStore}
                context_id={context_id}
                idFieldName={"rule_id"}
                targetStore={alarmRuleStore}
                agency_id={createRuleDetail.agency_id}
                moduleName={"alarm_rule"}
                resourceList={{
                  main: "ALARM_RULE",
                  detail: "ALARM_RULE_DETAIL",
                  detailsIntersection: "ALARM_RULE_DETAIL_INTERSECTION",
                }}
                childRef={(ref) => (this.ruleIntersection = ref)}
                currentStore={this.selectIntersectionStore}
                pathApi="alarm/rules"
              ></SelectIntersectionTab>
            </TabPane>
          </Tabs>
        </PageHeader>
      </div>
    );
  }
}

export default withRouter(withTranslation()(AlarmRuleDetailPage));
