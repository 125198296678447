import { DATE_FORMAT, ANALYSIS_TYPE } from "utils/constants";
import moment from "moment";
import { observable, action, runInAction } from "mobx";
import {
  tranferAppoarchDelayData,
  tranferTurningMovementData,
  tranferQueueLengthData,
  tranferVolumeData,
  tranferPreemptPriorityRequestData,
  tranferRLVCountData,
  transferMOEData,
} from "utils/helper";

class ChartStore {
  //filter data of current chart
  @observable filterData = {
    from_time: "", //minimap filter for chart
    to_time: "", //minimap filter for chart
    comapre_from_time: "", //minimap filter for compare chart. should be the same time(not date) with from_time
    comapre_to_time: "", //minimap filter for compare chart. should be the same time(not date) with from_time
    hiddenPhase: [], //array of hidden phase
    hiddenLegend: [], //array of hidden legend
  };
  //generate chart info
  @observable chartInfo = null; //hold chart general information
  //current display chart data(with filter value)
  @observable chartData = [];
  //current display compare chart data(with filter value)
  @observable compareChartData = [];
  //current display gap data(with filter value)
  @observable gapData = [];
  //current display compare gap data(with filter value)
  @observable compareGapData = [];
  //all Chart data(get from backend - never change)
  @observable allChartData = [];
  //all compare Chart data(get from backend - never change)
  @observable allCompareChartData = [];
  //all Gap data(get from backend - never change)
  @observable allGapData = [];
  //all compare Gap data(get from backend - never change)
  @observable allCompareGapData = [];
  @observable currentChartTypeFrontEnd = null;

  // additional data for chart
  @observable chartAdditionalData = null;

  rootChartData = null;
  rootCompareChartData = null;
  /**
   * updateChartData
   * get chart data from API response
   * convert chart data to store object format
   *
   * @param {Object} chartData: chart data
   * @param {Object} compareChartData: compare chart data
   * @return  {null} -
   */
  preTranformChartData = (chartData, compareChartData = null) => {
    this.rootChartData = chartData;
    this.rootCompareChartData = compareChartData;
    if (!chartData?.analysis_type) this.clearChartData();
    else this.updateChartData(this.rootChartData, this.rootCompareChartData);
  };
  @action updateChartData = (chartData, compareChartData = null) => {
    //convert data as chart info
    this.chartInfo = {
      from_time: chartData?.from_time,
      to_time: chartData?.to_time,
      analysis_type: chartData?.analysis_type,
      title: chartData?.int_name,
      isCompareMode: compareChartData ? true : false,
      comapre_from_time: compareChartData?.from_time,
      comapre_to_time: compareChartData?.to_time,
      isFullyAppoarch: chartData?.is_fully_approach,
      isGapData:
        chartData?.gaps?.length > 0 || compareChartData?.gaps?.length > 0,
    };

    // get saved hidden phase
    const hiddenPhaseString = localStorage.getItem("siwave_hidden_phase");

    try {
      const hiddenPhase = JSON.parse(hiddenPhaseString);
      if (hiddenPhase && Array.isArray(hiddenPhase))
        runInAction(() => {
          this.filterData.hiddenPhase = hiddenPhase;
        });
    } catch (error) {}

    this.filterData.from_time = new Date(chartData?.from_time);
    this.filterData.to_time = new Date(chartData?.to_time);
    if (this.chartInfo?.isCompareMode) {
      //update for compare mode
      this.filterData.comapre_from_time = new Date(compareChartData?.from_time);
      this.filterData.comapre_to_time = new Date(compareChartData?.to_time);
    }

    if (Array.isArray(chartData.charts)) {
      this.allChartData = chartData.charts; //assign data for all chart
      this.allCompareChartData = compareChartData?.charts
        ? compareChartData?.charts
        : []; //assign data for all compare chart
      if (this.chartInfo?.isGapData) {
        this.allGapData = chartData.gaps;
        this.allCompareGapData = compareChartData?.gaps
          ? compareChartData.gaps
          : [];
      }
    }
    this.setCurrentChartTypeFrontEnd(chartData?.analysis_type, null);
  };
  /**
   * set chart type for front end
   *
   *
   */

  @action setCurrentChartTypeFrontEnd = (type, extraOptions) => {
    switch (type) {
      case ANALYSIS_TYPE.PT_TYPE: {
        this.currentChartTypeFrontEnd = extraOptions
          ? extraOptions
          : ANALYSIS_TYPE.DEFAULT_PT;
        break;
      }

      case ANALYSIS_TYPE.PED_TYPE: {
        this.currentChartTypeFrontEnd = extraOptions
          ? extraOptions
          : ANALYSIS_TYPE.PED_DELAY;
        break;
      }

      case ANALYSIS_TYPE.APPOARCH_DELAY: {
        const currentType =
          extraOptions === ANALYSIS_TYPE.APPOARCH_DELAY_LANE_VIEW
            ? ANALYSIS_TYPE.APPOARCH_DELAY_LANE_VIEW
            : ANALYSIS_TYPE.APPOARCH_DELAY_PHASE_VIEW;

        this.allChartData = tranferAppoarchDelayData(
          this.rootChartData,
          currentType
        );
        this.allCompareChartData = tranferAppoarchDelayData(
          this.rootCompareChartData,
          currentType
        );

        this.updateFilterData(null, null, true);
        this.currentChartTypeFrontEnd = currentType;
        break;
      }
      case ANALYSIS_TYPE.QUEUE_LENGTH: {
        const currentType =
          extraOptions === ANALYSIS_TYPE.QUEUE_LENGTH_LANE_VIEW
            ? ANALYSIS_TYPE.QUEUE_LENGTH_LANE_VIEW
            : ANALYSIS_TYPE.QUEUE_LENGTH_PHASE_VIEW;
        this.allChartData = tranferQueueLengthData(
          this.rootChartData,
          currentType
        );
        this.allCompareChartData = tranferQueueLengthData(
          this.rootCompareChartData,
          currentType
        );

        this.updateFilterData(null, null, true);
        this.currentChartTypeFrontEnd = currentType;
        break;
      }
      case ANALYSIS_TYPE.VOLUMNE: {
        this.allChartData = tranferVolumeData(this.rootChartData, false);
        this.allCompareChartData = tranferVolumeData(
          this.rootCompareChartData,
          true
        );
        this.currentChartTypeFrontEnd = type;
        break;
      }

      case ANALYSIS_TYPE.TURNING_MOVEMENT: {
        this.allChartData = tranferTurningMovementData(
          this.rootChartData,
          false
        );
        this.allCompareChartData = tranferTurningMovementData(
          this.rootCompareChartData,
          true
        );
        this.currentChartTypeFrontEnd = type;
        break;
      }

      case ANALYSIS_TYPE.RLV_COUNT: {
        this.allChartData = tranferRLVCountData(this.rootChartData, false);
        this.allCompareChartData = tranferRLVCountData(
          this.rootCompareChartData,
          true
        );
        this.currentChartTypeFrontEnd = type;
        break;
      }

      case ANALYSIS_TYPE.PREEMPTION_PRIORITY: {
        const currentType =
          extraOptions === ANALYSIS_TYPE.PREEMPTION_PRIORITY_DETAIL
            ? ANALYSIS_TYPE.PREEMPTION_PRIORITY_DETAIL
            : extraOptions === ANALYSIS_TYPE.PREEMPTION_PRIORITY_TSP_DETAIL
            ? ANALYSIS_TYPE.PREEMPTION_PRIORITY_TSP_DETAIL
            : ANALYSIS_TYPE.PREEMPTION_PRIORITY_REQUEST;

        this.allChartData = tranferPreemptPriorityRequestData(
          this.rootChartData,
          currentType
        );

        this.updateFilterData(null, null, true);
        this.currentChartTypeFrontEnd = currentType;
        break;
      }

      case ANALYSIS_TYPE.SPLIT_MONITOR: {
        this.chartData = [
          this.rootChartData.charts[0].programed_time_space,
          this.rootChartData.charts[0].actual_time_space,
        ];
        this.allChartData = [
          this.rootChartData.charts[0].programed_time_space,
          this.rootChartData.charts[0].actual_time_space,
        ];
        this.currentChartTypeFrontEnd = type;
        break;
      }

      case ANALYSIS_TYPE.MOE_ANALYSIS: {
        this.currentChartTypeFrontEnd = extraOptions
          ? extraOptions
          : ANALYSIS_TYPE.MOE_SUMMARY;

        const { allChartData, allPattern } = transferMOEData(
          this.rootChartData,
          this.currentChartTypeFrontEnd
        );

        this.allChartData = allChartData;

        this.chartAdditionalData = {
          ...this.rootChartData?.charts?.[0]?.cycle_length,
          allPattern,
        };
        break;
      }

      default: {
        this.currentChartTypeFrontEnd = type;
        break;
      }
    }
  };

  /**
   * filterGapDataWithNewTimeRange
   * change the Gap data value by current filter value
   *
   * @param {Array} allGapData: full data of the gap
   * @param {Boolean} compareMode: compare chart data
   * @return  {Array} new gap data value
   */
  filterGapDataWithNewTimeRange = (allGapData, compareMode = false) => {
    //prepare data for filter time
    let from_time = this.filterData.from_time;
    let to_time = this.filterData.to_time;
    if (compareMode) {
      from_time = this.filterData.comapre_from_time;
      to_time = this.filterData.comapre_to_time;
    }
    let gapData = [];
    if (Array.isArray(allGapData)) {
      const filterFromTime = from_time.getTime();
      const filterToTime = to_time.getTime();
      allGapData.map((item) => {
        const planFromTime = new Date(item.from_time).getTime();
        const planToTime = new Date(item.to_time).getTime();
        if (
          (planFromTime >= filterFromTime && planFromTime <= filterToTime) ||
          (planToTime >= filterFromTime && planToTime <= filterToTime)
        ) {
          gapData.push({
            from_time: item.from_time,
            to_time: item.to_time,
          });
        }
      });
      //overide the start / end time exactly same with filter time
      gapData.map((item) => {
        const planFromTime = new Date(item.from_time).getTime();
        const planToTime = new Date(item.to_time).getTime();
        if (planFromTime < filterFromTime) {
          item.from_time = moment(from_time).format(DATE_FORMAT.backend);
        }
        if (planToTime > filterToTime) {
          item.to_time = moment(to_time).format(DATE_FORMAT.backend);
        }
      });
    }
    return gapData;
  };
  /**
   * filterDataWithNewTimeRange
   * change the chartdata value by current filter value
   *
   * @param {Array} allChartData: full data of the chart
   * @param {Boolean} compareMode: compare chart data
   * @return  {Array} new chartdata value
   */
  filterDataWithNewTimeRange = (allChartData, compareMode = false) => {
    //filter all data with phare first
    let availableChartData = allChartData.filter(
      (item) => !this.filterData.hiddenPhase.includes(item.phase)
    );
    //prepare data for filter time
    let from_time = this.filterData.from_time;
    let to_time = this.filterData.to_time;
    if (compareMode) {
      from_time = this.filterData.comapre_from_time;
      to_time = this.filterData.comapre_to_time;
    }
    //filter data by time
    let chartData = availableChartData.map((item) => {
      let result = {
        ...item,
      }; //Note:clone object to avoid override the master data
      let newPlanStatistics = [];
      const filterFromTime = from_time.getTime();
      const filterToTime = to_time.getTime();
      //find the plan in time range first
      newPlanStatistics = result?.plan_statistics.filter((plan) => {
        const planFromTime = new Date(plan.from_time).getTime();
        const planToTime = new Date(plan.to_time).getTime();
        return (
          (planFromTime >= filterFromTime && planFromTime <= filterToTime) ||
          (planToTime >= filterFromTime && planToTime <= filterToTime)
        );
      });
      //overide the start / end time exactly same with filter time
      newPlanStatistics = newPlanStatistics.map((e) => {
        let plan = { ...e }; //Note:clone object to avoid override the master data
        const planFromTime = new Date(plan.from_time).getTime();
        const planToTime = new Date(plan.to_time).getTime();
        if (planFromTime < filterFromTime) {
          plan.from_time = moment(from_time).format(DATE_FORMAT.backend);
        }
        if (planToTime > filterToTime) {
          plan.to_time = moment(to_time).format(DATE_FORMAT.backend);
        }
        return plan;
      });
      //update the plan_statistics
      result.plan_statistics = newPlanStatistics;
      //NOTE: we just have update the plan statics here
      //the data of the chart will filter by getDataObject function on chart detail
      //cuz data format will be diff between chart type. so we do it at view logic
      return result;
    });
    return chartData;
  };
  /**
   * updateFilterData
   * this function will trigger when user input filtering  data
   * @param   {String} target  Field name wanna filter
   * @param   {String} value   Filtering data
   * @param   {boolean} isUpdateData   should we filter data right away
   *
   * @return  {Promise} -  A promise function to let client update render after done
   */
  @action updateFilterData = (target, value, isUpdateData = true) => {
    //update filter data
    if (this.filterData && target) {
      this.filterData[target] = value;
    }
    if (isUpdateData) {
      this.chartData = this.filterDataWithNewTimeRange(this.allChartData);
      if (this.chartInfo?.isGapData) {
        this.gapData = this.filterGapDataWithNewTimeRange(this.allGapData);
      }
      if (this.chartInfo?.isCompareMode) {
        //update from_time/ to_time in compare mode
        //cuz we dont update directly for comapre_from_time/comapre_to_time
        this.filterData.comapre_from_time.setHours(
          this.filterData.from_time.getHours()
        );
        this.filterData.comapre_from_time.setMinutes(
          this.filterData.from_time.getMinutes()
        );
        this.filterData.comapre_from_time.setSeconds(
          this.filterData.from_time.getSeconds()
        );
        this.filterData.comapre_to_time.setHours(
          this.filterData.to_time.getHours()
        );
        this.filterData.comapre_to_time.setMinutes(
          this.filterData.to_time.getMinutes()
        );
        this.filterData.comapre_to_time.setSeconds(
          this.filterData.to_time.getSeconds()
        );

        //filter compare data with new filter value
        this.compareChartData = this.filterDataWithNewTimeRange(
          this.allCompareChartData,
          true
        );
        if (this.chartInfo?.isGapData) {
          this.comgapData = this.filterGapDataWithNewTimeRange(
            this.allCompareChartData
          );
        }
      }
      //filter data with new filter value
    }
  };
  /**
   * clearChartData
   * this function trigger from Analysis Store when ever client click on Create new Analysis
   */
  @action clearChartData() {
    //filter data of current chart
    this.filterData = {
      from_time: "",
      to_time: "",
      comapre_from_time: "",
      comapre_to_time: "",
      hiddenPhase: [],
      hiddenLegend: [],
    };
    //generate chart info
    this.chartInfo = null;
    //chart data
    this.chartData = [];
    //all Chart data
    this.allChartData = [];
    //all Chart data
    this.allCompareChartData = [];
    //compare chart data
    this.compareChartData = [];
  }

  /**
   * getChartByIndex
   * @param {Int} index index of target chart
   *
   * @return  {Object} -  target chart
   */
  getChartByIndex = (index) => {
    if (Array.isArray(this.chartData) && this.chartData.length > index) {
      return this.chartData[index];
    }
    return null;
  };
  /**
   * getCompareChartByIndex
   * @param {Int} index index of target chart
   *
   * @return  {Object} -  target chart
   */
  getCompareChartByIndex = (index) => {
    if (
      Array.isArray(this.compareChartData) &&
      this.compareChartData.length > index
    ) {
      return this.compareChartData[index];
    }
    return null;
  };
}
export default ChartStore;
