// import { get, put, post, patch, delete as axiosDelete } from 'axios';
import axios from "axios";
import helper from "../utils/helper";
import queryString from "query-string";
import {
  HTTP_REQUEST_MENTHOD,
  HTTP_CODE_RESPONSE,
  NOTIFICATION_TYPE,
} from "utils/constants";
import i18n from "../i18n";
axios.defaults.withCredentials = true;
const LOGOUT_PATH = "auth/logout";
class Api {
  /**
   * constructor
   * auto generate url will configuration host , port, and prefix (check .env file)
   * @return  {null} -
   */
  constructor(path, store, port) {
    let apiPort = "";
    if (process.env.REACT_APP_API_PORT !== undefined) {
      apiPort = ":" + process.env.REACT_APP_API_PORT;
    }
    if (port) apiPort = ":" + port;
    this.url =
      process.env.REACT_APP_API_HOST +
      apiPort +
      process.env.REACT_APP_API_PREFIX +
      path;
    this.path = path;
    this.store = store;
  }
  getErrorMessages = (message) => {
    if (typeof message === "object") {
      return message?.errors?.map((el) => el.message).join("\n");
    }

    return message;
  };
  /**
   * handleErrorCode
   * handle error when calling api
   * @return  {null} -
   */
  handleErrorCode = (response) => {
    if (response?.response?.status) {
      switch (response?.response?.status) {
        case HTTP_CODE_RESPONSE.UNAUTHORIZED:
          this.store?.history?.push("/403");
          break;
        case HTTP_CODE_RESPONSE.UNAUTHENTICATE:
          this.store?.history?.push("/401");
          // this.store?.authStore?.logout();
          break;
        case HTTP_CODE_RESPONSE.BADREQUEST:
          //if response with error message go to call back
          //if reponse with general message go to global notify
          break;
        case HTTP_CODE_RESPONSE.NOTFOUND:
          if (process.env.NODE_ENV != "production") {
            helper.showNotification(
              NOTIFICATION_TYPE.ERROR,
              i18n.t("api_not_found"),
              this.getErrorMessages(response?.response?.data?.message)
            );
          } else {
            this.store?.history?.push("/500");
          }
          break;
        case HTTP_CODE_RESPONSE.ERROR:
          // if (process.env.NODE_ENV != "production") {
          helper.showNotification(
            NOTIFICATION_TYPE.ERROR,
            i18n.t("error"),
            this.getErrorMessages(response?.response?.data?.message)
          );
          // }
          // else {
          //   this.store?.history?.push("/500");
          // }
          break;
        case HTTP_CODE_RESPONSE.CONFLICT:
          helper.showNotification(
            NOTIFICATION_TYPE.ERROR,
            i18n.t("error"),
            this.getErrorMessages(response?.response?.data?.message)
          );

          break;
        default:
          if (process.env.NODE_ENV != "production") {
            helper.showNotification(
              NOTIFICATION_TYPE.ERROR,
              i18n.t("httpcode_not_handle"),
              this.getErrorMessages(response?.response?.data?.message)
            );
          } else {
            // this.store?.history?.push("/500");
            helper.showNotification(
              NOTIFICATION_TYPE.ERROR,
              i18n.t("error"),
              this.getErrorMessages(response?.response?.data?.message)
            );
          }
      }
    } else {
      console.log("DEBUG_PRODUCTION", this.url, response);
      helper.showNotification(
        NOTIFICATION_TYPE.ERROR,
        i18n.t("error"),
        response.message
      );
    }
  };

  /**
   * handlerResponseCode
   * handle genetal case when calling api successfully
   * @return  {null} -
   */
  handlerResponseCode = (response, method, callback) => {
    // const notif = new NotificationService();
    // switch (response?.status) {
    //     case HTTP_CODE_RESPONSE.GET_SUCCESS:
    //         if (method == HTTP_REQUEST_MENTHOD.GET) {
    //             // notif.show(NOTIFICATION_TYPE.SUCCESS, i18n.t("success"), i18n.t("get_success"));
    //         } else
    //             notif.show(NOTIFICATION_TYPE.WARNING, i18n.t("warning"), i18n.t("http_code_not_match"));
    //         break;
    //     case HTTP_CODE_RESPONSE.PUT_SUCCESS:
    //         if (method == HTTP_REQUEST_MENTHOD.PUT || method == HTTP_REQUEST_MENTHOD.PATCH || method == HTTP_REQUEST_MENTHOD.DELETE)
    //             notif.show(NOTIFICATION_TYPE.SUCCESS, i18n.t("success"), i18n.t("update_success"));
    //         else
    //             notif.show(NOTIFICATION_TYPE.WARNING, i18n.t("warning"), i18n.t("http_code_not_match"));
    //         break;
    //     case HTTP_CODE_RESPONSE.POST_SUCCESS:
    //         if (method == HTTP_REQUEST_MENTHOD.POST)
    //             notif.show(NOTIFICATION_TYPE.SUCCESS, i18n.t("success"), i18n.t("create_sucess"));
    //         else
    //             notif.show(NOTIFICATION_TYPE.WARNING, i18n.t("warning"), i18n.t("http_code_not_match"));
    //         break;
    //     // default:
    //         // notif.show(NOTIFICATION_TYPE.WARNING, i18n.t("warning"), i18n.t("http_code_not_handle"));
    //     //we can handle failback here
    // }
    callback && callback(response?.data);
  };
  /**
   * generateRequestHeader
   * handle genetal header for every request to attached
   * @param {object} customHeader customize hearder for request
   * @return  {null} -
   */
  generateRequestHeader = (customHeader = null) => {
    let header = {
      headers: customHeader
        ? customHeader
        : {
            "Content-Type": "application/json",
            // "Access-Control-Allow-Credentials": true,
            "Access-Control-Allow-Origin": process.env.REACT_APP_CLIENT_HOST,
          },
    };
    // if (token) header.headers.Authorization = token;
    return header;
  };

  /**
   * getRequest
   * make a get request to server
   * show mini loading
   * hide mini loading if done
   * @return  {null} -
   */
  getRequest = (params, callback, failback) => {
    let self = this;
    self.store.commonStore.showMiniLoading();
    axios
      .get(this.url, {
        params: params,
        paramsSerializer: (params) => {
          return queryString.stringify(params, { arrayFormat: "brackets" });
        },
        ...this.generateRequestHeader(),
      })
      .then(function (response) {
        self.handlerResponseCode(
          response,
          HTTP_REQUEST_MENTHOD.GET,
          callback,
          failback
        );
      })
      .catch(function (error) {
        self.handleErrorCode(error);
        failback && failback(error?.response?.data?.message);
      })
      .finally(function () {
        self.store.commonStore.hideMiniLoading();
      });
  };

  /**
   * putRequest
   * make a put request to server
   * show mini loading
   * hide mini loading if done
   * @return  {null} -
   */
  putRequest = (body, callback, failback, customHeader = null) => {
    let self = this;
    self.store.commonStore.showMiniLoading();
    axios
      .put(this.url, body, this.generateRequestHeader(customHeader))
      .then(function (response) {
        self.handlerResponseCode(
          response,
          HTTP_REQUEST_MENTHOD.PUT,
          callback,
          failback
        );
      })
      .catch(function (error) {
        self.handleErrorCode(error);
        failback && failback(error?.response?.data?.message);
      })
      .finally(function () {
        self.store.commonStore.hideMiniLoading();
      });
  };

  /**
   * patchRequest
   * make a patch request to server
   * show mini loading
   * hide mini loading if done
   * @return  {null} -
   */
  patchRequest = (body, callback, failback) => {
    let self = this;
    self.store.commonStore.showMiniLoading();
    axios
      .patch(this.url, body, this.generateRequestHeader())
      .then(function (response) {
        self.handlerResponseCode(
          response,
          HTTP_REQUEST_MENTHOD.PATCH,
          callback,
          failback
        );
      })
      .catch(function (error) {
        self.handleErrorCode(error);
        failback && failback(error?.response?.data?.message);
      })
      .finally(function () {
        self.store.commonStore.hideMiniLoading();
      });
  };

  /**
   * postRequest
   * make a post request to server
   * show mini loading
   * hide mini loading if done
   * @return  {null} -
   */
  postRequest = (body, callback, failback) => {
    let self = this;
    self.store.commonStore.showMiniLoading();
    axios
      .post(this.url, body, this.generateRequestHeader())
      .then(function (response) {
        self.handlerResponseCode(
          response,
          HTTP_REQUEST_MENTHOD.POST,
          callback,
          failback
        );
      })
      .catch(function (error) {
        //add special case: not handle error for logout request
        if (self.path != LOGOUT_PATH) {
          self.handleErrorCode(error);
        }
        failback && failback(error?.response?.data?.message);
      })
      .finally(function () {
        self.store.commonStore.hideMiniLoading();
      });
  };

  /**
   * deleteRequest
   * make a delete request to server
   * show mini loading
   * hide mini loading if done
   * @return  {null} -
   */
  deleteRequest = (callback, failback, body) => {
    let self = this;
    self.store.commonStore.showMiniLoading();
    axios
      .delete(this.url, body && {data: body}, this.generateRequestHeader())
      .then(function (response) {
        self.handlerResponseCode(
          response,
          HTTP_REQUEST_MENTHOD.DELETE,
          callback,
          failback
        );
      })
      .catch(function (error) {
        self.handleErrorCode(error);
        failback && failback(error?.response?.data?.message);
      })
      .finally(function () {
        self.store.commonStore.hideMiniLoading();
      });
  };
}

export default Api;
