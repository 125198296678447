import React, { Component, createRef } from "react";
import * as d3 from "d3";
import { CHART_COLOR_SCHEMA } from "utils/constants/colorSchema";
import moment from 'moment';
import i18n from "i18n";
import { FONT_STRING_CAVAS } from "utils/constants";
import "./style.scss";

const COLOR_LIGHT = {
  YELLOW: CHART_COLOR_SCHEMA.YELLOW,
  RED: CHART_COLOR_SCHEMA.RED,
  GREEN: CHART_COLOR_SCHEMA.LOWER_GREEN,
  COORD_PHASE: CHART_COLOR_SCHEMA.GREEN_SIEMENS,
};

class BaseRingChart extends Component {
  constructor(props) {
    super(props);
    this.chartRef = createRef();
    this.areaRef = createRef();
    this.ctx = null;
    this.canvas = null;
    this.rangeX = [0, 0];
    this.rangeY = [0, 0];
    this.xScale = null;
    this.yScale = null;
    this.margin = {left: 240, right: 25}
    this.data = props.data;
    this.width = props.width;
    this.height = 100;
    this.earliestTime = null;
    this.latestTime = null;
    this.isLastCycle = props.isLastCycle;
    this.lastCycleEnds = props.lastCycleEnds;
    this.dataRing1 = this.data.ring1.light;
    this.dataRing2 = this.data.ring2.light;
  }

  drawCanvas = () => {
    this.canvas = d3.select(this.chartRef.current);

    this.canvas = d3.select(this.chartRef.current)
      .append('canvas')
      .attr('width', this.width)
      .attr('height', this.height);
  }

  drawRings = () => {
    let ctx = this.ctx;
    ctx = this.canvas.node().getContext('2d');
    ctx.translate(this.margin.left, 0);

    if (this.dataRing1.length !== 0) {
      this.drawRing1();
    }
    if (this.dataRing2.length !== 0) {
      this.drawRing2();
    }
  }

  drawRing1() {
    let ctx = this.ctx;
    ctx = this.canvas.node().getContext('2d');

    const lineRing1Ctx = d3
      .line()
      .x((d) => this.xScale(d.x))
      .y((d) => this.yScale(d.y) + 45)
      .context(ctx);

    if (this.isLastCycle) {
      const startTimeRing1 = moment.duration(moment(this.dataRing1[0].start_time).format("HH:mm:ss").toString()) - moment.duration(moment(this.earliestTime).format("HH:mm:ss").toString())  
      const endTime = moment.duration(moment(this.lastCycleEnds).format("HH:mm:ss").toString()) - moment.duration(moment(this.earliestTime).format("HH:mm:ss").toString())
      
      const lineCoordRing1 = [
        {
          x: startTimeRing1,
          y: this.height,
        },
        {
          x: endTime,
          y: this.height,
        },
      ];
  
      ctx.beginPath();
      lineRing1Ctx(lineCoordRing1);
      ctx.lineWidth = 20;
      ctx.strokeStyle = COLOR_LIGHT["RED"];
      ctx.stroke();
      ctx.closePath();
    }

    this.dataRing1.forEach((line) => {
      let startTime = moment.duration(moment(line.start_time).format("HH:mm:ss").toString()) - moment.duration(moment(this.earliestTime).format("HH:mm:ss").toString())

      let duration;
      if (!this.isLastCycle) {
        duration = this.data.duration ? this.data.duration*1000 : (moment(this.latestTime) - moment(this.earliestTime))
      } else {
        duration = moment.duration(moment(this.lastCycleEnds).format("HH:mm:ss").toString()) - moment.duration(moment(this.earliestTime).format("HH:mm:ss").toString())
      }

      if (startTime >= duration) return;

      const timeDiff = moment.duration(moment(line.end_time).format("HH:mm:ss").toString()) - moment.duration(moment(this.earliestTime).format("HH:mm:ss").toString())
      let endTime;
      if (!this.isLastCycle && (timeDiff > duration)) {
        endTime = this.data.duration*1000 || (moment(this.latestTime) - moment(this.earliestTime))
      } else {
        endTime = moment.duration(moment(line.end_time).format("HH:mm:ss").toString()) - moment.duration(moment(this.earliestTime).format("HH:mm:ss").toString())
      }

      const lineCoord = [
        {
          x: startTime,
          y: this.height,
        },
        {
          x: endTime,
          y: this.height,
        },
      ];

      ctx.beginPath();
      lineRing1Ctx(lineCoord);
      ctx.lineWidth = 20;
      ctx.strokeStyle =
        line.is_coord_phase &&
        line.color === "GREEN"
          ? COLOR_LIGHT.COORD_PHASE
          : COLOR_LIGHT[line.color];

      ctx.stroke();
      ctx.closePath();
      ctx.beginPath();
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillStyle = "#111111";
      ctx.font = `12px ${FONT_STRING_CAVAS}`;
      const spaceXscale = this.xScale(endTime - startTime);
      const space = (endTime - startTime) / 1000;
      if (spaceXscale >= 40 && line.color !== "YELLOW" && line.color !== "RED") {
        ctx.fillText(
          `P${line.phase} (${space}s)`,
          this.xScale(
            new Date(
              (startTime + endTime) / 2
            )
          ),
          this.yScale(lineCoord[0].y) + 45
        );
      }

      ctx.closePath();
    });
  }

  drawRing2() {
    let ctx = this.ctx;
    ctx = this.canvas.node().getContext('2d');

    const lineRing2Ctx = d3
      .line()
      .x((d) => this.xScale(d.x))
      .y((d) => this.yScale(d.y) + 69)
      .context(ctx);

    if (this.isLastCycle) {
      const startTimeRing2 = moment.duration(moment(this.dataRing2[0].start_time).format("HH:mm:ss").toString()) - moment.duration(moment(this.earliestTime).format("HH:mm:ss").toString())
      const endTime = moment.duration(moment(this.lastCycleEnds).format("HH:mm:ss").toString()) - moment.duration(moment(this.earliestTime).format("HH:mm:ss").toString())
      
      const lineCoordRing2 = [
        {
          x: startTimeRing2,
          y: this.height,
        },
        {
          x: endTime,
          y: this.height,
        },
      ];
  
      ctx.beginPath();
      lineRing2Ctx(lineCoordRing2);
      ctx.lineWidth = 20;
      ctx.strokeStyle = COLOR_LIGHT["RED"];
      ctx.stroke();
      ctx.closePath();
    }

    this.dataRing2.forEach((line) => {
      const startTime = moment.duration(moment(line.start_time).format("HH:mm:ss").toString()) - moment.duration(moment(this.earliestTime).format("HH:mm:ss").toString())
      let duration;
      if (!this.isLastCycle) {
        duration = this.data.duration ? this.data.duration*1000 : (moment(this.latestTime) - moment(this.earliestTime))
      } else {
        duration = moment.duration(moment(this.lastCycleEnds).format("HH:mm:ss").toString()) - moment.duration(moment(this.earliestTime).format("HH:mm:ss").toString())
      }

      if (startTime >= duration) return; 

      const timeDiff = moment.duration(moment(line.end_time).format("HH:mm:ss").toString()) - moment.duration(moment(this.earliestTime).format("HH:mm:ss").toString())
      let endTime;
      if (!this.isLastCycle && (timeDiff > duration)) {
        endTime = this.data.duration*1000 || (moment(this.latestTime) - moment(this.earliestTime))
      } else {
        endTime = moment.duration(moment(line.end_time).format("HH:mm:ss").toString()) - moment.duration(moment(this.earliestTime).format("HH:mm:ss").toString())
      }

      const lineCoord = [
        {
          x: startTime,
          y: this.height,
        },
        {
          x: endTime,
          y: this.height,
        },
      ];

      ctx.beginPath();
      lineRing2Ctx(lineCoord);
      ctx.lineWidth = 20;
      ctx.strokeStyle =
        line.is_coord_phase &&
        line.color === "GREEN"
          ? COLOR_LIGHT.COORD_PHASE
          : COLOR_LIGHT[line.color];

      ctx.stroke();
      ctx.closePath();
      ctx.beginPath();
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillStyle = "#111111";
      ctx.font = `12px ${FONT_STRING_CAVAS}`;
      const spaceXscale =
        this.xScale(endTime - startTime);
      const space =
        (endTime - startTime) / 1000;
      if (spaceXscale >= 40 && line.color !== "YELLOW" && line.color !== "RED") {
        ctx.fillText(
          `P${line.phase} (${space}s)`,
          this.xScale(
            new Date(
              (startTime + endTime) / 2
            )
          ),
          this.yScale(lineCoord[0].y) + 69
        );
      }
      ctx.closePath();
    });
  }

  drawScatterLine() {
    let ctx = this.ctx;
    ctx = this.canvas.node().getContext('2d');

    const rangeX = this.xScale.domain();
    const upperLineCtx = d3
      .line()
      .x((d) => this.xScale(d.x))
      .y((d) => this.yScale(d.y) + 25)
      .context(ctx);

    ctx.beginPath();
    upperLineCtx([
      { x: rangeX[0], y: 100 },
      { x: rangeX[1], y: 100 },
    ]);

    ctx.lineWidth = 1;
    ctx.strokeStyle = "black";
    ctx.stroke();
    ctx.closePath();
  };

  drawEvents = () => {
    let ctx = this.ctx;
    ctx = this.canvas.node().getContext('2d');

    const eventData = this.data.events.map(event => {
      return {...event, time: moment.duration(moment(event.time) - moment(this.earliestTime))}
    })

    let patternChanges = [];

    eventData.forEach((point) => {
      if (point.param === 255) {
        patternChanges.push(point);
        return;
      }

      switch(point.event) {
        case "GAP_OUT": 
          ctx.fillStyle = "#35cb6d";
          break;
        case "MAX_OUT":
          ctx.fillStyle = "#fc4850";
          break;
        case "FORCE_OFF":
          ctx.fillStyle = "#77cace";
          break;
        default:
          return;
      }

      // start a new path for drawing
      ctx.beginPath();
      // paint an arc based on information from the DOM node
      ctx.arc(
        this.xScale(point.time),
        this.yScale(100) + 25,
        3,
        0,
        2 * Math.PI
      );
      // fill the point
      ctx.fill();
      ctx.closePath();
    });

    if (patternChanges.length === 2) {
      this.drawFlashing(patternChanges);
    }
  };

  drawFlashing = (patternChanges) => {
    let ctx = this.ctx;
    ctx = this.canvas.node().getContext('2d');

    const startTime = patternChanges[0].time;
    const endTime = patternChanges[1].time

    const flashingLineCtx = d3
      .line()
      .x((d) => this.xScale(d.x))
      .y((d) => this.yScale(d.y) + 57)
      .context(ctx);

    const lineCoord = [
      {
        x: startTime,
        y: this.height,
      },
      {
        x: endTime,
        y: this.height,
      },
    ];

    ctx.beginPath();
    flashingLineCtx(lineCoord);
    ctx.lineWidth = 44;
    ctx.strokeStyle = COLOR_LIGHT["YELLOW"]
    ctx.stroke();
    ctx.closePath();

    ctx.beginPath();
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.fillStyle = "#111111";
    ctx.fillText(
      `FLASHING`,
      this.xScale(
        new Date(
          (startTime + endTime) / 2
        )
      ),
      this.yScale(lineCoord[0].y) + 57
    );

    ctx.closePath();
  }


  handleMouseMove = () => {
    const width = this.areaRef.current.offsetWidth;
    const height = this.areaRef.current.offsetHeight;

    var mouseX = d3.event.layerX || d3.event.offsetX;
    var mouseY = d3.event.layerY || d3.event.offsetY;

    const realXCoord = mouseX - this.margin.left;

    d3.event.preventDefault();
    this.hoverCtx.clearRect(0, 0, width, height);

    const lineCtx = d3
      .line()
      .x((d) => d.x)
      .y((d) => d.y)
      .context(this.hoverCtx);
    this.hoverCtx.beginPath();
    lineCtx([
      {
        x: mouseX,
        y: 0,
      },
      {
        x: mouseX,
        y: this.height,
      },
    ]);
    lineCtx([
      {
        x: 0,
        y: mouseY,
      },
      {
        x: width,
        y: mouseY,
      },
    ]);
    this.hoverCtx.lineWidth = 0.5;
    this.hoverCtx.strokeStyle = "black";
    this.hoverCtx.stroke();
    this.hoverCtx.closePath();
    
    const eventData = this.data.events.map(event => {
      return {...event, time: moment.duration(moment(event.time) - moment(this.earliestTime))}
    })

    this.dataRing1.length && this.dataRing1.forEach((point) => {
      if (
          this.xScale(moment.duration(moment(point.start_time) - moment(this.earliestTime))) <= realXCoord 
          && this.xScale(moment.duration(moment(point.end_time) - moment(this.earliestTime))) >= realXCoord 
          && mouseY >= 35 
          && mouseY <= 55
          && realXCoord <= (this.width - this.margin.left - this.margin.right)
      ) {
        //draw x y axis of mouse
        this.hoverCtx.beginPath();
        this.hoverCtx.fillStyle = "white";
        this.hoverCtx.fillRect(
          mouseX > width / 2 ? mouseX - 240 : mouseX + 40,
          mouseY > height / 2 ? mouseY - 30 : mouseY + 10,
          230,
          20
        );
        this.hoverCtx.closePath();
        
        this.hoverCtx.beginPath();
        this.hoverCtx.font = `12px sans-serif`;
        this.hoverCtx.fillStyle = "black";
        const startFillText =
          mouseX > width / 2 ? mouseX - 230 : mouseX + 50;
        this.hoverCtx.fillText(
          `P${point.phase}: ${moment(point.start_time).format("HH:mm:ss.S")} - ${moment(point.end_time).format("HH:mm:ss.S")} (${((moment.duration(moment(point.end_time).format("HH:mm:ss").toString()) - moment.duration(moment(point.start_time).format("HH:mm:ss").toString()))/1000)}s)`,
          startFillText,
          mouseY > height / 2 ? mouseY - 15 : mouseY + 25
        );
        this.hoverCtx.closePath();
      }
    })

    this.dataRing2.length && this.dataRing2.forEach((point) => {
      if (
          this.xScale(moment.duration(moment(point.start_time) - moment(this.earliestTime))) <= realXCoord 
          && this.xScale(moment.duration(moment(point.end_time) - moment(this.earliestTime))) >= realXCoord 
          && mouseY >= 59 
          && mouseY <= 79
          && realXCoord <= (this.width - this.margin.left - this.margin.right)
      ) {
        //draw x y axis of mouse
        this.hoverCtx.beginPath();
        this.hoverCtx.fillStyle = "white";
        this.hoverCtx.fillRect(
          mouseX > width / 2 ? mouseX - 240 : mouseX + 40,
          mouseY > height / 2 ? mouseY - 30 : mouseY + 10,
          230,
          20
        );
        this.hoverCtx.closePath();
        
        this.hoverCtx.beginPath();
        this.hoverCtx.font = `12px sans-serif`;
        this.hoverCtx.fillStyle = "black";
        const startFillText =
          mouseX > width / 2 ? mouseX - 230 : mouseX + 50;
        this.hoverCtx.fillText(
          `P${point.phase}: ${moment(point.start_time).format("HH:mm:ss.S")} - ${moment(point.end_time).format("HH:mm:ss.S")} (${((moment.duration(moment(point.end_time).format("HH:mm:ss").toString()) - moment.duration(moment(point.start_time).format("HH:mm:ss").toString()))/1000)}s)`,
          startFillText,
          mouseY > height / 2 ? mouseY - 15 : mouseY + 25
        );
        this.hoverCtx.closePath();
      }
    })

    eventData.forEach((point, index) => {

      if (this.xScale(point.time) <= realXCoord + 4 && this.xScale(point.time) >= realXCoord - 4) {

        switch(point.event) {
          case "GAP_OUT": 
            this.hoverCtx.fillStyle = "#35cb6d";
            break;
          case "MAX_OUT":
            this.hoverCtx.fillStyle = "#fc4850";
            break;
          case "FORCE_OFF":
            this.hoverCtx.fillStyle = "#77cace";
            break;
          default:
            return
        }
  
        this.hoverCtx.beginPath();
        this.hoverCtx.arc(
          this.xScale(point.time) + this.margin.left,
          this.yScale(100) + 25,
          8,
          0,
          2 * Math.PI
        );
  
        // fill the point
        this.hoverCtx.fill();
        this.hoverCtx.closePath();

        //draw x y axis of mouse
        this.hoverCtx.beginPath();
        this.hoverCtx.fillStyle = "white";
        this.hoverCtx.fillRect(
          mouseX > width / 2 ? mouseX - 240 : mouseX + 40,
          mouseY > height / 2 ? mouseY - 15 : mouseY - 5,
          230,
          20
        );
        this.hoverCtx.closePath();
        
        this.hoverCtx.beginPath();
        this.hoverCtx.font = `12px sans-serif`;
        this.hoverCtx.fillStyle = "black";
        const startFillText =
          mouseX > width / 2 ? mouseX - 230 : mouseX + 50;
        this.hoverCtx.fillText(
          `${i18n.t(`analysis.${this.data.events[index].event.toLowerCase()}`)}: P${this.data.events[index].param} - ${moment(this.data.events[index].time).format("HH:mm:ss.S")}`,
          startFillText,
          mouseY > height / 2 ? mouseY : mouseY + 10
        );
        this.hoverCtx.closePath();
      }
    });
  };

  handleMouseOut = () => {
    this.hoverCtx.clearRect(0, 0, this.width, this.height);
  };

  drawHiddenCanvas = () => {
    const base = d3.select(this.areaRef.current);
    base.selectAll(".hiddenCanvasSplitMonitor").remove();

    this.hiddenCanvas = base
      .append("canvas")
      .classed("hiddenCanvasSplitMonitor", true)
      .attr("width", this.width)
      .attr("height", this.height);
    this.hoverCtx = this.hiddenCanvas.node().getContext("2d");
    d3.select(this.hiddenCanvas.node()).on("mousemove", this.handleMouseMove);
    d3.select(this.hiddenCanvas.node()).on("mouseleave", this.handleMouseOut);
  };

  render() {
    return(
      <div className="split-monitor-container">
        <div ref={this.areaRef} width={this.width} className="hidden-content-split-monitor"></div>
        <div className="ring-chart" ref={this.chartRef} style={{width:"100%", height: "100%"}}></div>
      </div>
    )
  }
}

export default BaseRingChart;