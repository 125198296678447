import React from "react";
import { Badge, Button, Col, Input, Tag, Tooltip } from "antd";
import { observer, inject } from "mobx-react";
import { withTranslation } from "react-i18next";

import BaseManagementComponents from "../../BaseManagementComponents";
import "./style.scss";
import { AVAILABLE } from "utils/constants";

/**
 * Component for showing details.
 *
 * @component
 */
@inject("rootStore")
@observer
class AddMoreIntersection extends BaseManagementComponents {
  constructor(props) {
    super(props.addMoreIntersectionStore, props);
    this.rowKey = "id";
    this.moduleName = "user";
    this.enableCreateButton = false;
    this.forceEnableRowSelection = true;
    // this.pageCustomClass = 'wrap-alarm-add-more-intersection';
    this.columns = this.getTableColumns();
  }

  /**
   * return column set of table
   */
  getTableColumns = () => {
    const { t, isShowColumnStatus } = this.props;
    if (isShowColumnStatus) {
      return [
        {
          title: t("intersection.name"),
          key: "name",
          ellipsis: true,
          render: (item) => <Button type="link">{item.name}</Button>,
          sorter: true,
          sortDirections: ["descend", "ascend"],
        },
        {
          title: t("intersection.status"),
          dataIndex: "status",
          key: "status",
          render: (status = AVAILABLE) => (
            <Tag
              color={status === AVAILABLE ? "green" : "volcano"}
              key={status}>
              <Badge color={status === AVAILABLE ? "green" : "volcano"} />
              {status?.toUpperCase()}
            </Tag>
          ),
          // sorter: context_id ? true :  false,
          // sortDirections: ["descend", "ascend"],
          ellipsis: true,
        },
      ];
    } else {
      return [
        {
          title: t("user.name"),
          dataIndex: "name",
          key: "name",
          sorter: true,
          sortDirections: ["descend", "ascend"],
          ellipsis: true,
        },
      ];
    }
  };

  checkFormDirty = () => {
    const { addMoreIntersectionStore } = this.props;
    return addMoreIntersectionStore.selectedItems.length > 0;
  };

  //-------- render common component ----------
  /**
   * renderFilterComponent
   * this function return the filter box
   * @return  {Component}
   */
  renderFilterComponent = () => {
    const { t } = this.props;
    return (
      <div>
        <Col span={16}>
          <Tooltip placement="top" title={t("alarm_rules.search_tooltip")}>
            <Input
              defaultValue={this.currentStore?.filterData?.text}
              onChange={(e) =>
                this.currentStore?.handleFilterDataChange(
                  "text",
                  e.target.value
                )
              }
              placeholder={t("alarm_rules.search_placholer")}
              maxLength={128}
            />
          </Tooltip>
        </Col>
      </div>
    );
  };

  componentDidMount() {
    super.componentDidMount();
    const { childRef } = this.props;

    childRef(this);
  }

  render() {
    return super.render();
  }
}

export default withTranslation()(AddMoreIntersection);
