import { Button, PageHeader, Tabs } from "antd";
import SelectIntersectionTab from "components/SelectIntersectionTab";
import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import AvailableIntersectionStore from "stores/AvailableIntersectionStore";
import SelectIntersectionStore from "stores/SelectIntersectionStore";
import { ACTIONS_LIST } from "utils/constants";
import helper from "../../../../utils/helper";
import AlarmRuleDetail from "../AlarmRuleDetail";
import AlarmRuleSubscriber from "../AlarmRuleDetail/AlarmRuleSubscriber";
import "./style.scss";

const tabIndex = {
  firstTab: "1",
  secondTab: "2",
  thirdTab: "3",
};
const { TabPane } = Tabs;

/**
 * Component for showing create page of alarm rule.
 *
 * @component
 */
@inject("rootStore")
@observer
class AlarmRuleCreatePage extends Component {
  constructor(props) {
    super(props);
    this.selectIntersectionStore = new SelectIntersectionStore(
      props.match.params.id,
      "alarm/rules",
      props.rootStore,
      "intersection_ids"
    );
    this.addMoreIntersectionStore = new AvailableIntersectionStore(
      props.rootStore,
      "alarm/rules",
      "ALARM_RULES"
    );
  }

  ruleDetail = null;
  ruleSub = null;
  ruleIntersection = null;

  componentDidMount() {
    const {
      rootStore: {
        alarmRuleStore: { changeCreateRuleTab, duplicate, resetCreateRuleForm },
      },
    } = this.props;
    changeCreateRuleTab(tabIndex.firstTab);
    const cloneId = helper.getQueryString(location.search, "cloneid");
    if (cloneId) {
      duplicate(cloneId);
    } else {
      resetCreateRuleForm();
    }
  }

  renderHeaderAction = () => {
    const {
      t,
      rootStore: {
        alarmRuleStore: { activeCreateTab },
      },
    } = this.props;
    return (
      <div className="header-function">
        <Button onClick={this.goToPrevStep}>{t("alarm_rules.back")}</Button>
        <Button onClick={this.goToNextStep}>
          {activeCreateTab == 3
            ? t("alarm_rules.save")
            : t("alarm_rules.next_step")}
        </Button>
      </div>
    );
  };

  goToNextStep = () => {
    const {
      history,
      rootStore: {
        myStore,
        alarmRuleStore: {
          activeCreateTab,
          changeCreateRuleTab,
          addSubscriber,
          addIntersection,
          submitCreateRule,
          createRuleDetail,
        },
      },
    } = this.props;
    const isOperator = myStore.getCurrentRoleName() == "OPERATOR";
    if (activeCreateTab == tabIndex.firstTab) {
      if (isOperator) changeCreateRuleTab(tabIndex.thirdTab);
      else this.ruleDetail?.handleNext();
    } else if (activeCreateTab == tabIndex.secondTab) {
      addSubscriber(this.ruleSub?.currentStore?.listData);
      changeCreateRuleTab(tabIndex.thirdTab);
    } else {
      addIntersection(this.ruleIntersection?.currentStore?.listData);
      if (this.ruleIntersection?.currentStore?.listData?.length > 0)
        submitCreateRule(
          () => {
            history.push("/alarms/rules");
          },
          (error) => {
            changeCreateRuleTab(tabIndex.firstTab);
            this.ruleDetail.setFormError(error);
          }
        );
    }
    if (activeCreateTab === tabIndex.secondTab) {
      this.addMoreIntersectionStore.handleFilterDataReset({
        agency_id: createRuleDetail?.agency_id,
      });
    }
  };

  goToPrevStep = () => {
    const {
      history,
      rootStore: {
        myStore,
        alarmRuleStore: { activeCreateTab, changeCreateRuleTab },
      },
    } = this.props;
    const isOperator = myStore.getCurrentRoleName() == "OPERATOR";
    if (activeCreateTab == tabIndex.firstTab) {
      history.push("/alarms/rules");
    } else if (activeCreateTab == tabIndex.secondTab) {
      changeCreateRuleTab(tabIndex.firstTab);
    } else {
      if (isOperator) changeCreateRuleTab(tabIndex.firstTab);
      else changeCreateRuleTab(tabIndex.secondTab);
    }
  };

  render() {
    const {
      t,
      rootStore: {
        alarmRuleStore,
        alarmRuleStore: { activeCreateTab, context_id, createRuleDetail },
      },
    } = this.props;
    return (
      <div className="wrap-alarm-rule-create-page">
        <PageHeader
          title={t("alarm_rules.create_rule_title")}
          extra={this.renderHeaderAction()}>
          <div className="alarm-rule-create">
            <Tabs
              className="create-rule-tab"
              type="card"
              defaultActiveKey={tabIndex.firstTab}
              activeKey={activeCreateTab}
              // onTabClick={(key) => { changeCreateRuleTab(key) }}
            >
              <TabPane
                tab="Rule Details"
                key={tabIndex.firstTab}
                disabled={activeCreateTab === tabIndex.firstTab ? false : true}>
                <AlarmRuleDetail
                  editingMode={ACTIONS_LIST.CREATE}
                  childRef={(ref) => (this.ruleDetail = ref)}></AlarmRuleDetail>
              </TabPane>
              <TabPane
                tab="Subscribers"
                key={tabIndex.secondTab}
                disabled={
                  activeCreateTab === tabIndex.secondTab ? false : true
                }>
                <AlarmRuleSubscriber
                  editingMode={ACTIONS_LIST.CREATE}
                  childRef={(ref) =>
                    (this.ruleSub = ref)
                  }></AlarmRuleSubscriber>
              </TabPane>
              <TabPane
                tab="Intersections"
                key={tabIndex.thirdTab}
                disabled={activeCreateTab === tabIndex.thirdTab ? false : true}>
                {/* <AlarmRuleIntersection
                  context_id={context_id}
                  addMoreIntersectionStore={this.addMoreIntersectionStore}
                  editingMode={ACTIONS_LIST.CREATE}
                  childRef={(ref) =>
                    (this.ruleIntersection = ref)
                  }></AlarmRuleIntersection> */}
                <SelectIntersectionTab
                  addMoreIntersectionStore={this.addMoreIntersectionStore}
                  context_id={context_id}
                  idFieldName={"rule_id"}
                  targetStore={alarmRuleStore}
                  agency_id={createRuleDetail.agency_id}
                  moduleName={"alarm_rule"}
                  resourceList={{
                    main: "ALARM_RULE",
                    detail: "ALARM_RULE_DETAIL",
                    detailsIntersection: "ALARM_RULE_DETAIL_INTERSECTION",
                  }}
                  childRef={(ref) => (this.ruleIntersection = ref)}
                  currentStore={this.selectIntersectionStore}
                  pathApi="alarm/rules"></SelectIntersectionTab>
              </TabPane>
            </Tabs>
          </div>
        </PageHeader>
      </div>
    );
  }
}

export default withRouter(withTranslation()(AlarmRuleCreatePage));
