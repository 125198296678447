import React, { Component } from "react";
import { observer, inject } from "mobx-react";

import { withTranslation } from "react-i18next";
import "./style.scss";
import { Col, Input, Radio, Table, Tooltip } from "antd";
import {
  DATE_FORMAT,
  SUMMARY_REPORT_TAB_INDEX,
} from "../../../utils/constants";
import SpinLoading from "../../../components/PrivateLayout/SpinLoading";
import { TIME_PERIODS } from "../../../utils/constants/dashboardConstant";

@inject("rootStore")
@observer
class SummaryReport extends Component {
  constructor(props) {
    super(props);
    this.rowKey = "int_uuid";
  }
  /**
   * return column set of table
   */
  getTableColumns = () => {
    const {
      rootStore: { dashboardStore },
      t,
    } = this.props;
    const enabledMetrics = dashboardStore.summaryReportSetting.metrics.filter(
      (el) => el.enabled
    );
    const type = dashboardStore?.typeSummaryReport
      ? dashboardStore?.typeSummaryReport
      : SUMMARY_REPORT_TAB_INDEX.AVERAGE;
    return [
      {
        title: t("summary_report.intersection_name"),
        dataIndex: "int_name",
        key: "int_name",
      },
      {
        title: t("summary_report.aor"),
        dataIndex: "statistics.aor_percent." + type,
        key: "aor_percent",
        render: (aor_percent) => this.renderValueColumn(aor_percent),
        sorter: true,
        sortDirections: ["descend", "ascend"],
        ellipsis: true,
      },
      {
        title: t("summary_report.aog"),
        dataIndex: "statistics.aog_percent." + type,
        key: "aog_percent",
        render: (aog_percent) => this.renderValueColumn(aog_percent),
        sorter: true,
        sortDirections: ["descend", "ascend"],
        ellipsis: true,
      },
      {
        title: t("summary_report.veh_act"),
        dataIndex: "statistics.detector_actuation." + type,
        key: "detector_actuation",
        render: (detector_actuation) =>
          this.renderValueColumn(detector_actuation),
        sorter: true,
        sortDirections: ["descend", "ascend"],
        ellipsis: true,
      },
      {
        title: t("summary_report.max_out"),
        dataIndex: "statistics.max_out_percent." + type,
        key: "max_out_percent",
        render: (max_out_percent) => this.renderValueColumn(max_out_percent),
        sorter: true,
        sortDirections: ["descend", "ascend"],
        ellipsis: true,
      },
      {
        title: t("summary_report.gap_out"),
        dataIndex: "statistics.gap_out_percent." + type,
        key: "gap_out_percent",
        render: (gap_out_percent) => this.renderValueColumn(gap_out_percent),
        sorter: true,
        sortDirections: ["descend", "ascend"],
        ellipsis: true,
      },
      {
        title: t("summary_report.force_off"),
        dataIndex: "statistics.force_off_percent." + type,
        key: "force_off_percent",
        render: (force_off_percent) =>
          this.renderValueColumn(force_off_percent),
        sorter: true,
        sortDirections: ["descend", "ascend"],
        ellipsis: true,
      },
      {
        title: t("summary_report.avg_ped_delay"),
        dataIndex: "statistics.avg_ped_delay." + type,
        key: "avg_ped_delay",
        render: (avg_ped_delay) => this.renderValueColumn(avg_ped_delay),
        sorter: true,
        sortDirections: ["descend", "ascend"],
        ellipsis: true,
      },
      {
        title: t("summary_report.ped_delay_act"),
        dataIndex: "statistics.ped_delay_activation." + type,
        key: "ped_delay_activation",
        render: (ped_delay_activation) =>
          this.renderValueColumn(ped_delay_activation),
        sorter: true,
        sortDirections: ["descend", "ascend"],
        ellipsis: true,
      },
      {
        title: t("summary_report.transition"),
        dataIndex: "statistics.coord_health_trans_percent." + type,
        key: "coord_health_trans_percent",
        render: (coord_health_trans_percent) =>
          this.renderValueColumn(coord_health_trans_percent),
      },
      {
        title: t("summary_report.gor"),
        dataIndex: "statistics.avg_gor_percent." + type,
        key: "avg_gor_percent",
        render: (gor_percent) => this.renderValueColumn(gor_percent),
      },
      {
        title: t("summary_report.ror5"),
        dataIndex: "statistics.avg_ror5_percent." + type,
        key: "avg_ror5_percent",
        render: (ror5_percent) => this.renderValueColumn(ror5_percent),
      },
      {
        title: t("summary_report.split_failure"),
        dataIndex: "statistics.split_failure_volume." + type,
        key: "split_failure_volume",
        render: (split_failure_volume) =>
          this.renderValueColumn(split_failure_volume),
      },
      {
        title: t("summary_report.avg_queue_length"),
        dataIndex: "statistics.avg_app_delay." + type,
        key: "avg_app_delay",
        render: (avg_app_delay) => this.renderValueColumn(avg_app_delay),
      },
      {
        title: t("summary_report.avg_approach_delay"),
        dataIndex: "statistics.avg_queue_length." + type,
        key: "avg_queue_length",
        render: (avg_queue_length) => this.renderValueColumn(avg_queue_length),
      },
      {
        title: t("summary_report.red_light_violation_count"),
        dataIndex: "statistics.red_light_violation_count." + type,
        key: "red_light_violation_count",
        render: (red_light_violation_count) =>
          this.renderValueColumn(red_light_violation_count),
        sorter: true,
        sortDirections: ["descend", "ascend"],
        ellipsis: true,
      },
    ].filter((el) => {
      return (
        enabledMetrics.findIndex(
          (metric) => metric.id === el.key || el.key === "int_name"
        ) >= 0
      );
    });
  };

  renderValueColumn(data) {
    const value = parseFloat(data?.value);
    return (
      <div className="column-value">
        {data?.is_warning ? <p className="dot-summary-report" /> : ""}
        <div>{isNaN(value) ? "-" : value.toFixed(2)}</div>
      </div>
    );
  }

  handleTabChange = (e) => {
    const {
      rootStore: { dashboardStore },
    } = this.props;
    const activetab = e.target.value;
    if (activetab === SUMMARY_REPORT_TAB_INDEX.AVERAGE) {
      dashboardStore?.updateSummaryReportIndexTab(
        SUMMARY_REPORT_TAB_INDEX.AVERAGE
      );
    }
    if (activetab === SUMMARY_REPORT_TAB_INDEX.MIN) {
      dashboardStore?.updateSummaryReportIndexTab(SUMMARY_REPORT_TAB_INDEX.MIN);
    }
    if (activetab === SUMMARY_REPORT_TAB_INDEX.MAX) {
      dashboardStore?.updateSummaryReportIndexTab(SUMMARY_REPORT_TAB_INDEX.MAX);
    }
  };

  render() {
    const {
      t,
      rootStore: {
        dashboardStore: {
          summaryReport: { pagination, data, loading },
          summaryReportSetting: { period, timeRange },
          changeSummaryReportPagination,
          handleFilterSummaryReportChange,
          handleFilerAction,
          summaryReport,
        },
      },
      skeleton,
      columns = this.getTableColumns(),
      rowSelection,
    } = this.props;
    if (skeleton) {
      return (
        <div className="wrap-summary-report">
          <div className="widget-header-title">
            {t("dashboard.summary-report-header")}
          </div>
        </div>
      );
    }

    return (
      <div className="wrap-summary-report">
        <div className="widget-header flex">
          <div>
            <div className="widget-header-title">
              {t("dashboard.summary-report-header")}
            </div>
            <div className="widget-header-subtitle">
              {period === TIME_PERIODS.CUSTOM
                ? timeRange[0]?.utc().format(DATE_FORMAT.withTimeZone) +
                  " - " +
                  timeRange[1]?.utc().format(DATE_FORMAT.withTimeZone)
                : t("map_setting." + period?.toLowerCase())}
            </div>
          </div>
        </div>
        <div className="summary-table">
          <div className="summary-report-title-content">
            <Col span={4}>
              <div className="color-header">
                {t("dashboard.summary-report-aggregation-unit")}
              </div>
            </Col>
            <Col span={2}>
              <div>{t("dashboard.summary-report-hourly")}</div>
            </Col>
            <Col span={3} offset={2}>
              <div className="color-header">
                {t("dashboard.summary-report-total-intersection")}
              </div>
            </Col>
            <Col span={2}>
              <div>{pagination.total}</div>
            </Col>
            <Col className="tab-summary-report">
              <Radio.Group
                onChange={this.handleTabChange}
                defaultValue={SUMMARY_REPORT_TAB_INDEX.AVERAGE}
              >
                <Radio.Button value={SUMMARY_REPORT_TAB_INDEX.AVERAGE}>
                  {t("summary_report.avg")}
                </Radio.Button>
                <Radio.Button value={SUMMARY_REPORT_TAB_INDEX.MIN}>
                  {t("summary_report.min")}
                </Radio.Button>
                <Radio.Button value={SUMMARY_REPORT_TAB_INDEX.MAX}>
                  {t("summary_report.max")}
                </Radio.Button>
              </Radio.Group>
            </Col>
            <Col span={6} style={{ marginBottom: "20px" }}>
              <Tooltip
                placement="top"
                title={t("dashboard.summary-report-search_tooltip")}
              >
                <Input
                  style={{ height: "40px", padding: "4px 11px" }}
                  maxLength={255}
                  defaultValue={summaryReport?.text}
                  onChange={(e) =>
                    handleFilterSummaryReportChange("text", e.target?.value)
                  }
                  placeholder={t("dashboard.summary-report-search_tooltip")}
                  onPressEnter={handleFilerAction}
                />
              </Tooltip>
            </Col>
          </div>
          <div>
            <Table
              className="spm-table"
              rowSelection={rowSelection}
              columns={columns}
              rowKey={this.rowKey}
              dataSource={data}
              pagination={pagination}
              loading={
                loading && {
                  indicator: <SpinLoading visible={true}></SpinLoading>,
                }
              }
              onChange={(pagination, filters, sorter) =>
                changeSummaryReportPagination(pagination, filters, sorter)
              }
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(SummaryReport);
