import BaseApi from "./BaseApi";
import Api from "./Api";

class CustomAnalysisService extends BaseApi {
  constructor(parentStore) {
    super("analysis", parentStore);
  }

  postNewCustomAnalysis = (data) => {
    return new Promise((resolve, reject) => {
      const api = new Api(
        this.moduleName + "/custom-analysis",
        this.parentStore
      );
      api.postRequest(
        { ...data },
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  };
  //Get List all custom-analysis in the current agency.

  getListAllCustomAnalysis = (agency_uuid) => {
    return new Promise((resolve, reject) => {
      const api = new Api(
        this.moduleName + "/custom-analysis?agency_uuid=" + agency_uuid,
        this.parentStore
      );
      api.getRequest(
        {},
        (response) => {
          resolve(response?.data);
        },
        (error) => {
          reject(error);
        }
      );
    });
  };
  updateCurrentCustomAnalysis = (id, payload) => {
    return new Promise((resolve, reject) => {
      const api = new Api(
        this.moduleName + "/custom-analysis" + "/" + id,
        this.parentStore
      );
      api.putRequest(payload, (response) => {
        resolve(response?.data);
      }),
        (error) => {
          reject(error);
        };
    });
  };
  deleteCurrentCustomAnalysis = (id) => {
    return new Promise((resolve, reject) => {
      const api = new Api(
        this.moduleName + "/custom-analysis" + "/" + id,
        this.parentStore
      );
      api.deleteRequest(() => {
        resolve();
      }),
        (error) => {
          reject(error);
        };
    });
  };
  getAllIntersectionsByAgency = (params) => {
    return new Promise((resolve, reject) => {
      let url = "intersections/all";
      const api = new Api(url, this.parentStore);
      api.getRequest(
        params,
        (response) => {
          resolve(response.data);
        },
        (error) => {
          reject(error);
        }
      );
    });
  };
  runCustomAnalysis = (values) => {
    return new Promise((resolve, reject) => {
      const api = new Api(
        this.moduleName +
          "/custom-analysis/" +
          values.id +
          "/run?int_uuid=" +
          values.int_uuid +
          "&from_time=" +
          values.from_time +
          "&to_time=" +
          values.to_time +
          "&agency_uuid=" +
          values.agency_uuid,
        this.parentStore
      );
      api.getRequest(
        {},
        (response) => {
          resolve(response.data);
        },
        (error) => {
          reject(error);
        }
      );
    });
  };
}

export default CustomAnalysisService;
