import { action, observable, runInAction } from "mobx";
import moment from "moment";
import AgencyService from "services/AgencyService";
import IntersectionService from "services/IntersectionService";
import ReportResultService from "services/TemplateGroup/ReportResultService";
import UserService from "services/UserService";
import BaseStore from "stores/BaseStore";
import { DATE_FORMAT, RESOURCE_LIST } from "utils/constants";

class ReportResultStore extends BaseStore {
  @observable allAgencies = [];
  @observable allUsers = [];
  constructor(parentStore) {
    super(new ReportResultService(parentStore), parentStore);
    this.agencyService = new AgencyService(parentStore);
    this.userService = new UserService(parentStore);
    this.intersectionService = new IntersectionService(parentStore);
    this.resourceName = RESOURCE_LIST.ALARM_RECORD;
    this.filterData = {
      ...this.filterData,
      ...{
        text: "",
        owner_id: null,
        aggregation: null,
        agency_id: "",
        from_time: null,
        to_time: null,
        from_date: null,
        to_date: null,
        sort: "",
      },
    };
    this.resourceName = RESOURCE_LIST.REPORT_RESULT;
  }
  @observable reportDetail = {};
  @observable resultData = [];
  @observable prev_result_time = null;
  /**
   * getCategoryName
   *
   * @return  {string} -
   */
  @action resetData() {
    this.reportDetail = {};
    this.resultData = [];
  }
  @action getAllUsers = (agency_id) => {
    if (this.allUsers?.length == 0) {
      this.loading = true;
      this.userService?.getAllUserByAgency(
        agency_id,
        (response) => {
          runInAction(() => {
            this.allUsers = [
              ...response?.data,
              {
                id: -1,
                name: "System",
                email: "SYSTEM",
              },
            ];
          });
        },
        () => {
          runInAction(() => {
            this.loading = false;
          });
        }
      );
    }
  };

  @action setDetaultAgencyFilter = (isSuperAdmin, cb) => {
    if (isSuperAdmin)
      this.agencyService.getAll((data) => {
        runInAction(() => {
          this.allAgencies = data;
          //set default agency
          if (Array.isArray(this.allAgencies) && this.allAgencies[0]) {
            let defaultAgency = this.allAgencies.find(
              (item) => item.id === this.filterData.agency_id
            );
            if (!defaultAgency) {
              this.filterData.agency_id = this.allAgencies[0].id;
            }
          }
          cb && cb();
        });
      });
    else {
      const currentAgency = this.parentStore.myStore.currentAgency;
      this.filterData = {
        ...this.filterData,
        ...{ agency_id: currentAgency.agency_id },
      };
      cb && cb();
    }
  };

  @action delete = (id = null) => {
    this.loading = true;
    if (!id) {
      id = this.selectedItems;
    }
    this.moduleService?.delete(
      { result_ids: id },
      () => {
        //redirect into page 1 avoid load wrong data
        this.filterData.pagination.current = 1;
        this.getData();
      },
      () => {
        runInAction(() => {
          this.loading = false;
        });
      }
    );
  };

  @action getResultData = (id) => {
    this.loading = true;
    this.moduleService?.getDetail(
      id,
      (response) => {
        runInAction(() => {
          this.reportDetail = {
            ...response?.summary_report,
            from_time: moment(
              response?.summary_report?.from_time,
              DATE_FORMAT.time_only_full
            ).format(DATE_FORMAT.hour),
            to_time: moment(
              response?.summary_report?.to_time,
              DATE_FORMAT.time_only_full
            ).format(DATE_FORMAT.hour),
            from_date: moment(
              response?.summary_report?.from_date,
              DATE_FORMAT.day_only_backend
            ).format(DATE_FORMAT.day_only),
            to_date: moment(
              response?.summary_report?.to_date,
              DATE_FORMAT.day_only_backend
            ).format(DATE_FORMAT.day_only),
            created_at: moment(response?.summary_report?.created_at).format(
              DATE_FORMAT.full_with_tz
            ),
            // selected_intersection: 45
          };
          this.resultData = response?.report_results;
          this.prev_result_time = response?.prev_result_time;
          this.loading = false;
        });
      },
      () => {
        runInAction(() => {
          this.loading = false;
        });
      }
    );
  };
}

export default ReportResultStore;
