import React, { Component } from "react";
import { Tag } from "antd";
import i18n from "i18n";
import AbnormalChartSVG from "./AbnormalChartComponent/AbnormalChartSVG";

class AbnormalDataChart extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      selectedParams: props.params,
      formattedData: null,
    };
  }

  componentDidMount() {
    this.formatData(this.state.selectedParams);
  }

  formatData = (paramsList) => {
    const { data, rawCounts } = this.props;
    
    const dates = rawCounts.map(item => item.data);
    const formattedData = dates.map(() => []);

    dates.forEach((date, index) => {
      data.forEach(point => {
        if (point.data === date && paramsList.includes(point.param)) {
          formattedData[index].push({...point});
        }
      })
    })

    let chartData = dates.map((date) => {return {date: date}});

    formattedData.forEach((day, index) => {
      const hours = [];
      
      day.forEach(point => {
        if (!hours.includes(point.hours)) {
          hours.push(point.hours);
        }
      })

      day.forEach(point => {
        hours.forEach(hour => {
          if (point.hours === hour) {
            if (chartData[index][hour]) {
              chartData[index][hour] += point.raw_count;
            } else {
              chartData[index][hour] = point.raw_count;
            }
          }  
        })
      })
    })

    // return chartData;
    this.setState(() => ({formattedData: chartData}));
  }

  handleFilterParams = (param) => {
    const { selectedParams } = this.state;

    let newParamsList;

    if (selectedParams.includes(param)) {
      newParamsList = selectedParams.filter(item => (item !== param));
    } else {
      newParamsList = [...selectedParams, param];
    }

    this.setState({
      selectedParams: newParamsList,
    });

    this.formatData(newParamsList);
  }

  render() {
    const { params, metadata } = this.props;
    const { selectedParams, formattedData } = this.state;

    return(
      <div style={{display: "flex", flexDirection: "column", width: "100%"}}>
        <div style={{width: "100%", display: "flex", justifyContent: "flex-end"}}>
          <div style={{marginRight: "10px"}}>{i18n.t('report_result.param_number')}</div> 
           {params.map((param) => (
            <Tag
              className="mb-8"
              key={param}
              onClick={() => this.handleFilterParams(param)}
              color={selectedParams?.includes(param) ? "#009999" : "gray"}
            >
              {param}
            </Tag>
          ))}
        </div>
        
        <AbnormalChartSVG data={formattedData} metadata={metadata}/>
      </div>
    )
  }
}

export default AbnormalDataChart;