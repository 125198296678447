import React, { Component, createRef } from "react";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import {
  Row,
  Col,
  Form,
  Select,
  InputNumber,
  Button,
  Alert,
  Input,
  Tag,
  Tooltip,
  Icon,
  Switch,
} from "antd";
import ColorPicker from "rc-color-picker";
import "./style.scss";
import { TIMEZONES } from "../../../../utils/timezone";
import {
  ACTIONS_LIST,
} from "utils/constants";
const MAX_LENGTH_TAG = 16;
const { Option } = Select;
/**
 * Component for showing agency detail (or edit form)
 *
 * @component
 */
const intergerParser = (value) => {
  return value?.replace(/[^0-9]/g, "");
};

@inject("rootStore")
@observer
class SettingTab extends Component {
  /**
   * componentDidMount
   * this function get detail data of agency setting
   * @return   {null}
   */
  constructor(props) {
    super(props);
    this.map = createRef();
  }
  state = {
    inputVisible: false,
    inputValue: "",
  };
  componentDidMount = () => {
    const { store } = this.props;
    store.getAgencySettings();
  };
  /**
   * onSearchResult
   * handle when user select on searching result(map) for new agency center
   * @param {Object} value new location object
   * @return   {null}
   */
  onSearchResult = (value) => {
    const { store } = this.props;
    store.handlerAgencyLocationChange(value.lat, value.lng);
  };

  /**
   * handleMarkerMove
   * handle when user move the pin to select new location of agency
   * @param {Object} value new location object
   * @return   {null}
   */
  handleMarkerMove = (value) => {
    let newLocation = value.target.getLatLng();
    if (newLocation) {
      this.onSearchResult(newLocation);
    }
  };
  handleClose = (removedTag) => {
    const { store } = this.props;
    store.handleChangeAlarmCategory(removedTag, ACTIONS_LIST.REMOVE);
  };

  showInput = () => {
    this.setState({ inputVisible: true }, () => this.input.focus());
  };

  handleInputChange = (e) => {
    this.setState({ inputValue: e.target.value });
  };

  handleInputConfirm = () => {
    const { store } = this.props;
    const { inputValue } = this.state;
    if (inputValue)
      store.handleChangeAlarmCategory(inputValue, ACTIONS_LIST.ADD);
    this.setState({
      inputVisible: false,
      inputValue: "",
    });
  };

  saveInputRef = (input) => (this.input = input);
  /**
   * render
   * @return  {Component}
   */
  render() {
    const {
      t,
      store,
      rootStore: {},
    } = this.props;
    const { inputVisible, inputValue } = this.state;
    return (
      <Row className="wrap-agency-setting">
        <div className="wrap-login-error-comp">
          {store.errorList && (
            <Alert
              message={store.errorList.message}
              type="error"
              closable={false}
              showIcon
              className="spm-error-comp"
            />
          )}
        </div>
        <Col xl={8} xs={24} className="color-matrix">
          <Form onSubmit={this.handleSubmit}>
            <Form.Item
              colon={false}
              label={
                <span>
                  {t("agency.matrix")}&nbsp;
                  <Tooltip title={t("agency.matrix_title")}>
                    <Icon type="exclamation-circle" />
                  </Tooltip>
                </span>
              }
              className="color-matrix-item"
            >
              <div>
                <Select
                  className="color-matrix-value"
                  onChange={store.changeCurrentColorMatrix}
                  value={store.selectedMatrix.label}
                >
                  {store.agencyColorMatrix.map((e) => (
                    <Option key={e.key} value={e.key}>
                      {e.label}
                    </Option>
                  ))}
                </Select>
                <div>{store.selectedMatrix.unit}</div>
              </div>
            </Form.Item>
            {store.selectedMatrix?.color?.map((item, index) => (
              <Form.Item
                colon={false}
                key={index}
                className="color-matrix-item"
                label={
                  item.from_value === 0
                    ? t("agency.matrix-item-default")
                    : t("agency.matrix-item") + " " + index
                }
              >
                <div>
                  {item.from_value !== 0 && (
                    <InputNumber
                      className="color-matrix-value"
                      min={1}
                      parser={intergerParser}
                      value={item.from_value}
                      onChange={(value) => {
                        if (value > 0)
                          store.handlerColorMatrixAttributeChange(
                            index,
                            "from_value",
                            value
                          );
                      }}
                    />
                  )}
                  <ColorPicker
                    color={item.color}
                    onClose={(value) =>
                      store.handlerColorMatrixAttributeChange(
                        index,
                        "color",
                        value.color
                      )
                    }
                    placement="topLeft"
                    className="color-matrix-value"
                  />
                  {item.from_value !== 0 && (
                    <Button
                      className="color-matrix-action"
                      type="danger"
                      icon="close"
                      onClick={() => store.handlerRemoveColorMatrix(index)}
                      ghost
                    />
                  )}
                </div>
              </Form.Item>
            ))}
            <Form.Item>
              <Button
                type="primary"
                icon="plus"
                className="color-matrix-action"
                onClick={store.handlerAddNewColorMatrix}
                disabled={
                  store.selectedMatrix?.color?.length >
                  store.MAX_ITEM_PER_MATRIX
                }
              >
                {t("agency.add-metrix")}
              </Button>
            </Form.Item>
          </Form>
        </Col>
        <Col xl={16} xs={24} className="center-point">
          <Form name="text_setting ">
            <Row className="mb-8">
              <Col span={24}>
                <Form.Item label={t("agency.alarm_category")}>
                  <div className="agencty-tag-input">
                    <div className="disable-tag">
                      {store?.currentAgencySetting?.alarm_categories?.map(
                        (item) => {
                          const tag = item?.name;
                          const isLongTag = tag.length > MAX_LENGTH_TAG;
                          const tagElem = (
                            <Tag key={tag} closable={!item.is_default}>
                              {isLongTag
                                ? `${tag.slice(0, MAX_LENGTH_TAG)}...`
                                : tag}
                            </Tag>
                          );
                          if (item.is_default)
                            return isLongTag ? (
                              <Tooltip title={tag} key={tag}>
                                {tagElem}
                              </Tooltip>
                            ) : (
                              tagElem
                            );
                        }
                      )}
                    </div>

                    {store?.currentAgencySetting?.alarm_categories?.map(
                      (item) => {
                        const tag = item?.name.trim();
                        const isLongTag = tag.length > MAX_LENGTH_TAG;
                        const tagElem = (
                          <Tag
                            key={tag}
                            closable={!item.is_default}
                            onClose={() => this.handleClose(tag)}
                          >
                            {isLongTag
                              ? `${tag.slice(0, MAX_LENGTH_TAG)}...`
                              : tag}
                          </Tag>
                        );
                        if (!item.is_default)
                          return isLongTag ? (
                            <Tooltip title={tag} key={tag}>
                              {tagElem}
                            </Tooltip>
                          ) : (
                            tagElem
                          );
                      }
                    )}
                    {inputVisible && (
                      <Input
                        ref={this.saveInputRef}
                        type="text"
                        size="default"
                        style={{ width: "150px" }}
                        value={inputValue}
                        onChange={this.handleInputChange}
                        onBlur={this.handleInputConfirm}
                        onPressEnter={this.handleInputConfirm}
                      />
                    )}
                    {!inputVisible && (
                      <Tag
                        onClick={this.showInput}
                        style={{ background: "#fff", borderStyle: "dashed" }}
                      >
                        <Icon type="plus" /> {t("alarm.new_category")}
                      </Tag>
                    )}
                  </div>
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col xs={12} md={6}>
                <Form.Item
                  label={t("agency.set_saturation_label")}
                  style={{ marginBottom: 40 }}
                ></Form.Item>
                <Form.Item label={t("agency.straight_movement")}>
                  <InputNumber
                    parser={intergerParser}
                    min={1}
                    value={store.currentAgencySetting?.saturation.straight}
                    onChange={(value) => {
                      if (value > 0)
                        store.setFormField(["saturation", "straight"], value);
                    }}
                    className="w-160"
                  />
                </Form.Item>
                <Form.Item label={t("agency.left_movement")}>
                  <InputNumber
                    parser={intergerParser}
                    min={1}
                    value={store.currentAgencySetting?.saturation.left}
                    onChange={(value) => {
                      if (value > 0)
                        store.setFormField(["saturation", "left"], value);
                    }}
                    className="w-160"
                  />
                </Form.Item>
                <Form.Item label={t("agency.right_movement")}>
                  <InputNumber
                    parser={intergerParser}
                    min={1}
                    value={store.currentAgencySetting?.saturation.right}
                    onChange={(value) => {
                      if (value > 0)
                        store.setFormField(["saturation", "right"], value);
                    }}
                    className="w-160"
                  />
                </Form.Item>
                <Form.Item label={t("agency.straight_left_movement")}>
                  <InputNumber
                    parser={intergerParser}
                    min={1}
                    value={store.currentAgencySetting?.saturation.straight_left}
                    onChange={(value) => {
                      if (value > 0)
                        store.setFormField(
                          ["saturation", "straight_left"],
                          value
                        );
                    }}
                    className="w-160"
                  />
                </Form.Item>
                <Form.Item label={t("agency.straight_right_movement")}>
                  <InputNumber
                    parser={intergerParser}
                    min={1}
                    value={
                      store.currentAgencySetting?.saturation.straight_right
                    }
                    onChange={(value) => {
                      if (value > 0)
                        store.setFormField(
                          ["saturation", "straight_right"],
                          value
                        );
                    }}
                    className="w-160"
                  />
                </Form.Item>
              </Col>
              <Col xs={12} md={18}>
                <Form.Item label={t("agency.timezone")} style={{ marginBottom: 4 }}>
                  <Select
                    className="timezone"
                    onChange={(value) =>
                      store.setFormField(["timezone"], value)
                    }
                    value={store.currentAgencySetting.timezone}
                    placeholder={t("agency.time-zone-placeholder")}
                  >
                    {TIMEZONES.map((e) => (
                      <Option value={e?.value} key={e?.value}>
                        {e?.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  label={t("agency.toggle-intersection")}
                  className="switch-title"
                >
                  <Switch
                    checked={store.currentAgencySetting.show_unavailable_map_data}
                    onChange={(value) => {
                      store.setFormField(["show_unavailable_map_data"], value);
                    }}
                  />
                </Form.Item>

                {/* <Form.Item label={t("agency.center-point")}>
                  <Map
                    ref={this.map}
                    center={store.currentCenterPoint}
                    zoom={DEFAULT_ZOOM_LEVEL}
                    className="center-point-map"
                    onViewportChange={this.handlerViewPortChange}
                  >
                    <TileLayer
                      url={process.env.REACT_APP_OPEN_STREET_MAP}
                      attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    />
                    <ReactLeafletSearch
                      position="topright"
                      inputPlaceholder={t("agency.map_search")}
                      showMarker={false}
                      showPopup={false}
                      closeResultsOnClick={true}
                      openSearchOnLoad={true}
                      mapStateModifier={this.onSearchResult}
                      // default provider OpenStreetMap
                      // provider="BingMap"
                      // providerKey="AhkdlcKxeOnNCJ1wRIPmrOXLxtEHDvuWUZhiT4GYfWgfxLthOYXs5lUMqWjQmc27"
                    />
                    <Marker
                      icon={SELECTED_PIN}
                      onmoveend={this.handleMarkerMove}
                      position={store.currentCenterPoint}
                      draggable={true}
                    >
                      <Popup>
                        <div>
                          <p>{t("agency.location-info")}</p>
                          <p>
                            {t("agency.latitude")} :{" "}
                            {store.currentCenterPoint[0]}
                            <br />
                            {t("agency.longitude")} :{" "}
                            {store.currentCenterPoint[1]}
                          </p>
                        </div>
                      </Popup>
                    </Marker>
                  </Map>
                </Form.Item> */}
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    );
  }
}
const SettingAgencyForm = Form.create({ name: "agency_setting" })(SettingTab);
export default withRouter(withTranslation()(SettingAgencyForm));
