import { observable, action, runInAction } from "mobx";
import Api from '../services/Api';
class ReportStore {
  constructor(parentStore) {
    this.parentStore = parentStore;
  }

  @observable aorData = null;
  @observable filterChart;
  @observable intersections = [
    { intersection_num: 335, intersection_name: "intersection1", position: [50.505, -0.03], "chart": [{ "aor": "Arrival on red" }, { "pt": "phase termination" }, { "coord": "coordination" }] },
    { intersection_num: 365, intersection_name: "intersection2", position: [50.505, -0.03], "chart": [{ "aor": "Arrival on red" }, { "pt": "phase termination" }, { "coord": "coordination" }] }
  ];
  //set boolean compareInterval
  @action setfetchParams = (value) => {
    this.fetchParams = value;
  }
  //set aordata
  @action setaorData = () => {
    this.aorData = null;
  }
  //set filterChart
  @action setFilterChart = (value) => {
    this.filterChart = value;
  }
  @action fetchAor = (cb, fetchParams) => {
    var fetch = {
      from_time: new Date(fetchParams.timeInterval1.start),
      to_time: new Date(fetchParams.timeInterval1.end),
      bin_size: fetchParams.bin_size,
    }
    const api = new Api("analysis/" + fetchParams.analysis_type + "/" + fetchParams.intersection_id, this.parentStore);
    api.getRequest(fetch, (response) => {
      runInAction(() => {
        this.aorData = response;
        cb && cb();
      });
    });
  }
  //fetch intersection after filter signal
  @action fetchIntersections = (cb) => {
    runInAction(() => {
      this.intersections = [{
        "intersection_num": 345,
        "intersection_name": "Moggill and Russell",
        "position": [51.505, -0.09],
        "chart": [{ "chart_id": "aor", "chart_name": "Arrival on Red" },
        { "chart_id": "pt", "chart_name": "Phase Termination" },
        { "chart_id": "coord", "chart_name": "Coordination Diagram" }]
      },
      {
        "intersection_num": 385,
        "intersection_name": "ho chi minh",
        "position": [51.523, -0.03],
        "chart": [{ "chart_id": "aor", "chart_name": "Arrival on Red" },
        { "chart_id": "coord", "chart_name": "Coordination Diagram" }]
      },
      {
        "intersection_num": 335,
        "intersection_name": "pho ha",
        "position": [45, -0.023],
        "chart": [
          { "chart_id": "coord", "chart_name": "Coordination Diagram" }]
      }
      ];
      cb && cb();
    });
  }

}

export default ReportStore;