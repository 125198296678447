import BaseApi from "../../services/BaseApi";
/**
 * Service for user management.
 *
 * @class
 */
class IntersectionEventHistoryService extends BaseApi {
  constructor(parentStore) {
    super("tsp/intersection-events", parentStore);
    this.moduleName = "tsp/intersection-events";
    this.dataKey = "tsp_events";
    this.keyField = "id";
  }
}

export default IntersectionEventHistoryService;
