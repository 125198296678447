import { SEARCH_TEXT_FIELD } from "utils/constants";
import { observable, action } from "mobx";
import moment from "moment";
import IntersectionEventHistoryService from "../../services/TSPServices/IntersectionEventHistoryService";
import BaseStore from "../BaseStore";

/**
 * Store for intersections
 *
 * @class
 */
class IntersectionEventHistoryStore extends BaseStore {
  constructor(parentStore) {
    super(new IntersectionEventHistoryService(parentStore), parentStore);
    this.filterData = {
      ...this.filterData,
      ...{
        text: "",
        agency_id: parentStore?.myStore?.currentAgency?.agency_id,
        event_type: null,
        intersection_uuid: "",
        from_time: moment().valueOf() - 86400000,
        to_time: moment().valueOf(),
        range: 86400000,
        sort: "",
      },
    };
    // this.listData =
  }
  @observable currentIntersection = null;
  @observable listAllIntersections = [];
  @action setListAllIntersections(data) {
    this.listAllIntersections = data;
  }
  //data for filtering
  //data for map
  @action getDataById = (item_id, cb) => {
    this.itemData = this.listData.find((e) => e.id === item_id);
    cb && cb();
  };

  /**
   * handleFilterDataChange override
   * this function will trigger when user input filtering or searching data
   * if filter data is search text and search length >=3 or length == 0 automatic start search
   * @param   {String} target  Field name wanna filter
   * @param   {String} value   Filtering data
   * @param   {Boolean} forceLoad   need to refetch data
   * @return  {null} -  setState of current filter data
   */
  @action handleFilterDataChange = (target, value, forceLoad = true) => {
    if (this.filterData) {
      this.filterData[target] = value;
    }
    if (target === "intersection_uuid") {
      this.currentIntersection = this.listAllIntersections.find(
        (e) => e.id === value
      );
    }
    if (target === SEARCH_TEXT_FIELD) {
      if (value?.length !== 0 && value?.length <= MAX_NUMBER_START_SEARCH) {
        return;
      }

      if (this.timeoutSearchString) clearTimeout(this.timeoutSearchString);
      if (forceLoad)
        this.timeoutSearchString = setTimeout(() => {
          this.handleFilerAction();
        }, DELAY_SEARCH_STRING);
    } else if (forceLoad) {
      this.handleFilerAction();
    }
  };
}

export default IntersectionEventHistoryStore;
