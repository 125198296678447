import { action, observable, runInAction } from "mobx";
import {
  DELAY_SEARCH_STRING,
  MAX_NUMBER_START_SEARCH,
  SEARCH_TEXT_FIELD,
} from "utils/constants";
import i18n from "../i18n";

class BaseStore {
  constructor(moduleService, parentStore) {
    this.parentStore = parentStore;
    this.moduleService = moduleService;
    this.commonStore = parentStore?.commonStore;
    this.myStore = parentStore?.myStore;
  }

  resourceName = null;
  currentModuleRoute = null;
  @observable errorList = null;
  timeoutSearchString = null;
  //default item data
  defaultItemData = {};
  //list of items in table
  @observable listData = [];
  //item object in item detail page
  @observable itemData = {};
  //when request to api loading will set to true to visible action button
  @observable loading = false;
  //list of selected items in table
  @observable selectedItems = [];
  //for show and hide item detail popup
  @observable drawerVisible = false;
  //item detail form title
  @observable createEditFromTile = "";
  //data of filtering, searching, sorting of table(should overide by child component)
  @observable filterData = {
    pagination: {
      current: 1,
      pageSize: parseInt(process.env.REACT_APP_PAGE_SIZE) || null,
      total: 0,
      showQuickJumper: true,
      showTotal: this.renterTotalItemsInfo,
    },
  };
  //editable item detail form
  @observable formEditable = false;
  //-------- Handle Actions on list screen ----------
  /**
   * clearErrorList
   * this function will clear all error message
   * @return  {null} -
   */
  @action clearErrorList = () => {
    this.errorList = null;
  };

  /**
   * setErrorList
   * this function will tranfer error format from backend to front-end
   * @return  {null} -
   */

  @action setErrorList = (response) => {
    if (response?.errors?.length > 0) {
      this.errorList = null;
      let message = "";
      response.errors.forEach((error) => {
        message += error.message + "\n";
      });
      if (message != "") {
        this.errorList = { message };
      }
    }
  };
  /**
   * setLoading
   * @param {*} value
   */
  @action setLoading = (value) => {
    this.loading = value;
  };
  /**
   * showCreateOrEditForm
   * this function will trigger when user wanna show item detail form
   * @param   {String} formTitle  tile of the form
   * @param   {Int} id  id of the item
   * @param   {Boolean} editable  is form editable (default true)
   * @return  {null} -  setState of current drawerVisible, createEditFromTile, formEditable
   */
  @action showCreateOrEditForm = (formTitle, id, editable = true) => {
    if (id) {
      this.getDataById(id, () => {
        runInAction(() => {
          this.drawerVisible = true;
          this.createEditFromTile = formTitle;
          this.formEditable = editable;
        });
      });
    } else {
      this.itemData = this.defaultItemData;
      this.drawerVisible = true;
      this.createEditFromTile = formTitle;
      this.formEditable = editable;
    }
  };

  /**
   * handleFilerAction
   * @return  {null} -  setState of current filter data
   */
  @action handleFilerAction = () => {
    this.filterData.pagination = {
      ...this.filterData.pagination,
      current: 1,
      pageSize: parseInt(process.env.REACT_APP_PAGE_SIZE) || null,
      total: 0,
    };
    this.getData();
  };

  /**
   * handleTableChange
   * this function will trigger when ever click paging, sorting on table (filtering we defined by our seft)
   * @param   {Object} pagination  paging object
   * @param   {Object} filters  filtering object -  not use
   * @param   {Object} sorter  sorting object
   * @param   {Object} extra  extra object - not use
   * @return  {null} -  setState of current filter data
   */
  @action handleTableChange = (pagination, filters, sorter) => {
    this.filterData.pagination.current = pagination.current;
    let sortString = "";
    if (sorter.order) {
      sortString = sorter.columnKey + ":" + sorter.order;
    }
    this.filterData = { ...this.filterData, sort: sortString };
    this.getData();
  };

  /**
   * handleFilterDataChange
   * this function will trigger when user input filtering or searching data
   * if filter data is search text and search length >=3 or length == 0 automatic start search
   * @param   {String} target  Field name wanna filter
   * @param   {String} value   Filtering data
   * @param   {Boolean} forceLoad   need to refetch data
   * @return  {null} -  setState of current filter data
   */
  @action handleFilterDataChange = (target, value, forceLoad = true) => {
    if (this.filterData) {
      this.filterData[target] = value;
    }
    if (target === SEARCH_TEXT_FIELD) {
      if (value?.length !== 0 && value?.length <= MAX_NUMBER_START_SEARCH) {
        return;
      }

      if (this.timeoutSearchString) clearTimeout(this.timeoutSearchString);
      if (forceLoad)
        this.timeoutSearchString = setTimeout(() => {
          this.handleFilerAction();
        }, DELAY_SEARCH_STRING);
    } else if (forceLoad) {
      this.handleFilerAction();
    }
  };

  /**
   * handleFilterDataChange
   * this function will trigger when user input filtering or searching data
   * if filter data is search text and search length >=3 or length == 0 automatic start search
   * @param   {String} target  Field name wanna filter
   * @param   {String} value   Filtering data
   * @param   {Boolean} forceLoad   need to refetch data
   * @return  {null} -  setState of current filter data
   */
  @action handleRestFilterChange = (filter, forceLoad = true) => {
    if (this.filterData) {
      this.filterData = { ...this.filterData, ...filter };
    }
    if (filter[SEARCH_TEXT_FIELD]) {
      if (
        filter[SEARCH_TEXT_FIELD]?.length !== 0 &&
        filter[SEARCH_TEXT_FIELD]?.length <= MAX_NUMBER_START_SEARCH
      ) {
        return;
      }

      if (this.timeoutSearchString) clearTimeout(this.timeoutSearchString);
      if (forceLoad)
        this.timeoutSearchString = setTimeout(() => {
          this.handleFilerAction();
        }, DELAY_SEARCH_STRING);
    } else if (forceLoad) {
      this.handleFilerAction();
    }
  };

  /**
   * onSelectRowChange
   * this function will trigger when user select or unselect item row on table
   * @param   {Array} selectedItems  List of selected ITem
   * @return  {null} -  setState of current selectedItems
   */
  @action onSelectRowChange = (selectedItems) => {
    this.selectedItems = selectedItems;
  };

  /**
   * closeCreateOrEditForm
   * this function will trigger when user wanna close item detail form
   * @return  {null} -  setState of current drawerVisible, createEditFromTile, formEditable
   */
  @action closeCreateOrEditForm = (defaultObject = null) => {
    this.clearErrorList();
    this.drawerVisible = false;
    if (defaultObject) {
      this.itemData = defaultObject;
    } else {
      this.itemData = this.defaultItemData;
    }
  };

  /**
   * clearSelectedItems
   * this function will trigger when user wanna clear list selected items
   * @return  {null} -
   */
  @action clearSelectedItems = () => {
    this.selectedItems = [];
  };

  /**
   * getDataById
   * this function get data by the Id
   * setpage on loading beforload and clean it after loading done
   *
   * @return  {null} -
   */
  @action getDataById = (item_id, cb) => {
    this.loading = true;
    this.moduleService?.fetchItemById(
      item_id,
      (item) => {
        runInAction(() => {
          this.loading = false;
          this.itemData = this.tranformDataToFormData(item);
        });
        cb && cb();
      },
      () => {
        runInAction(() => {
          this.loading = false;
        });
      }
    );
  };
  @action resetListData = () => {
    runInAction(() => {
      this.listData = [];
      this.filterData.pagination.total = 0;
    });
  };

  /**
   * getData
   * this function get all data from server with filter value
   * setpage on loading beforload and clean it after loading done
   * after get the data done: update the total item for paging compoment
   *
   * @return  {null} -
   */
  @action getData = (cb) => {
    this.loading = true;
    this.moduleService?.fetchDataWithParams(
      this.filterData,
      (items, totalCount) => {
        runInAction(() => {
          if (items.length === 0) {
            if (totalCount !== 0) {
              this.filterData.pagination.current = 1;
              this.getData();
            } else {
              this.loading = false;
              this.listData = items;
              this.filterData.pagination.total = totalCount;
            }
          } else {
            this.loading = false;
            this.listData = items;
            this.filterData.pagination.total = totalCount;
          }
          // this.filterData.pagination.total = 100;
        });
        cb && cb();
      },
      () => {
        runInAction(() => {
          this.loading = false;
          this.listData = [];
          this.filterData.pagination = {
            ...this.filterData.pagination,
            current: 1,
            pageSize: parseInt(process.env.REACT_APP_PAGE_SIZE) || null,
            total: 0,
          };
        });
      }
    );
  };

  /**
   * renterTotalItemsInfo
   * this function render the text of sumary item on table
   * @return  {string} -
   */
  renterTotalItemsInfo = (total, range) => {
    return (
      range[0] +
      "-" +
      range[1] +
      " " +
      i18n.t("of") +
      " " +
      total +
      " " +
      i18n.t("items")
    );
  };

  /**
   * tranformDataToFormData
   * @param   {Object} item  user info from UserStore
   * @return  {Object}            Clone of user with isSuperAdmin and current_role
   */
  tranformDataToFormData = (item) => {
    return item;
  };

  /**
   * tranformDataBeforeSave
   * @param   {Object} item  item of data before save
   * @return  {Object} item of data after change format
   */
  tranformDataBeforeSave = (item) => {
    return item;
  };

  /**
   * saveData
   * @param   {Object} data  item of data for save
   * @param   {Object} cb  callback after save data successfull
   * @param   {Object} fb  callback after fail
   * @return  {null}
   */
  @action saveData = (data, cb, fb) => {
    this.loading = true;
    let itemData = { ...this.itemData, ...data };
    this.moduleService?.saveData(
      this.tranformDataBeforeSave(itemData),
      () => {
        runInAction(() => {
          this.loading = false;
        });
        cb && cb();
      },
      (error) => {
        if (error?.errors?.length > 0) {
          fb && fb(error?.errors);
        }
        runInAction(() => {
          this.loading = false;
        });
      }
    );
  };

  /**
   * setFilterData
   * setting filer data for auto selected data
   *
   * @return  {null} -  call the callback function
   */
  @action setFilterData = (data) => {
    this.filterData = { ...this.filterData, ...data };
  };

  /**
   * handleRedirectFromNotification
   * redirect to current module
   * open current detail tab with dataId and the form title => delay to list load all data
   *
   * @return  {null} -
   */
  @action handleRedirectFromNotification = (data) => {
    if (data && this.currentModuleRoute) {
      let query = "";
      if (data.url_param) {
        query = "?" + new URLSearchParams(data.url_param);
      }
      this.parentStore.history.push({
        pathname: this.currentModuleRoute,
        search: query,
      });
      this.showCreateOrEditForm(i18n.t(data.title), data.id, data.editable);
    }
  };
}

export default BaseStore;
