import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./local/en";
import de from "./local/de";
import vi from "./local/vi";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: en,
  de: de,
  vi: vi
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: process.env.REACT_APP_DEFAULT_LANG,
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
