import * as d3 from "d3";
import React, { Component, createRef } from "react";
import { withTranslation } from "react-i18next";
import { CHART_COLOR_SCHEMA } from "utils/constants";
import { toFixed2Offset } from "utils/helper";

const BAR_CHART_ITEM = {
  up_stream_object: [
    {
      key: "existing_aog",

      offsetX: -66,
      color: CHART_COLOR_SCHEMA.GREEN,
    },
    {
      key: "predicted_aog",
      offsetX: -56,
      color: CHART_COLOR_SCHEMA.DURATION,
    },
  ],
  down_stream_object: [
    {
      key: "existing_aog",
      offsetX: -26,
      color: CHART_COLOR_SCHEMA.GREEN,
    },
    {
      key: "predicted_aog",
      offsetX: -16,
      color: CHART_COLOR_SCHEMA.DURATION,
    },
  ],
  total_object: [
    {
      key: "existing_aog",
      offsetX: 16,
      color: CHART_COLOR_SCHEMA.GREEN,
    },
    {
      key: "predicted_aog",
      offsetX: 26,
      color: CHART_COLOR_SCHEMA.DURATION,
    },
  ],
};
class AogVolumeBarChart extends Component {
  fullWidth = 0;
  fullHeight = 0;
  marginX = 64;
  marginY = 16;
  yOffsetInfo = 48;
  tooltipWidth = 200;
  tooltipMargin = 20;
  crossHairObject = {};
  constructor(props) {
    super(props);
    this.chartRef = createRef();
    this.elmChartRef = createRef();
    this.axisRef = createRef();
    this.tickRef = createRef();
  }

  renderChartCrossHair = () => {
    let currentContext = this;
    //render overlay rect cover all chart + add crosshair on that
    let overLayGroup = this.chartObject
      .append("g")
      .attr("class", "overlay-group");
    let crossHairGroup = overLayGroup
      .append("g")
      .attr("class", "cross-hair-group");
    //add crosshair line
    this.crossHairObject.crosshair_x = crossHairGroup
      .append("line")
      .attr("class", "cross-hair-line")
      .attr("stroke", "var(--text)")
      .attr("display", "none")
      .attr("x1", 0)
      .attr("y1", this.marginY)
      .attr("x2", 0)
      .attr("y2", this.fullHeight + this.marginY);
    this.crossHairObject.crosshair_y = crossHairGroup
      .append("line")
      .attr("class", "cross-hair-line")
      .attr("stroke", "var(--text)")
      .attr("display", "none")
      .attr("x1", this.marginX)
      .attr("y1", 0)
      .attr("x2", this.fullWidth + this.marginX)
      .attr("y2", 0);
    //add overlay rect
    overLayGroup
      .append("rect")
      .attr("class", "overlay-area")
      .attr("width", this.fullWidth)
      .attr("height", this.fullHeight)
      .attr("fill-opacity", "0")
      .attr("transform", "translate(" + this.marginX + "," + this.marginY + ")")
      .on("mousemove", function () {
        let mouse = d3.mouse(this);
        currentContext.handleMouseMove(mouse);
      })
      .on("mouseout", this.handleMouseOut);

    //handler mouse hover /movein/ moveout of overlay to draw cross hair and detail box
  };

  drawHightLightIntersection = (targetInt, x, y) => {
    if (targetInt) {
      this.hightLineObject = this.chartObject
        .append("g")
        .attr("class", "hightline-group");
      const tooltipHeight = 120;
      const xTooltipPosition =
        x < this.fullWidth / 2
          ? this.marginX + x + this.tooltipMargin
          : this.marginX + x - this.tooltipWidth - this.tooltipMargin;
      const yTooltipPosition =
        y < this.fullHeight / 2
          ? y + tooltipHeight / 2 + this.tooltipMargin
          : y - tooltipHeight / 2 - this.tooltipMargin;

      this.hightLineObject
        .append("rect")
        .attr("width", this.tooltipWidth) //hard code the width of box
        .attr("height", tooltipHeight)
        .attr("class", "tooltip-box")
        .attr("stroke", "var(--text)")
        .attr("fill", "var(--background)")
        .attr(
          "transform",
          "translate(" + xTooltipPosition + "," + yTooltipPosition + ")"
        );
      let labelIndex = 1;
      const textArray = [
        {
          text: `Upstream existing: ${targetInt.up_stream_object.existing_aog}`,
          color: CHART_COLOR_SCHEMA.GREEN_SIEMENS,
        },
        {
          text: `Downstream existing: ${targetInt.down_stream_object.existing_aog}`,

          color: CHART_COLOR_SCHEMA.GREEN_SIEMENS,
        },
        {
          text: `Total existing: ${targetInt.total_object.existing_aog}`,
          color: CHART_COLOR_SCHEMA.GREEN_SIEMENS,
        },

        {
          text: ``,
          color: "#fff",
        },
        {
          text: `Upstream predict: ${targetInt.up_stream_object.predicted_aog}`,

          color: CHART_COLOR_SCHEMA.DURATION,
        },
        {
          text: `Downstream predict: ${targetInt.down_stream_object.predicted_aog}`,

          color: CHART_COLOR_SCHEMA.DURATION,
        },
        {
          text: `Total predict: ${targetInt.total_object.predicted_aog}`,
          color: CHART_COLOR_SCHEMA.DURATION,
        },
      ];
      textArray.forEach((txt) => {
        this.hightLineObject
          .append("text")
          .text(txt.text) //@TODO:hard code here
          .attr("text-anchor", "start")
          .attr("class", "tooltip-info-text")
          .attr(
            "transform",
            "translate(" +
            (xTooltipPosition + 16) +
            "," +
            (yTooltipPosition + 16 * labelIndex) +
            ")"
          )
          .attr("fill", txt.color);
        labelIndex += 1;
      });
    }
  };
  handleMouseMove = (mousePosition) => {
    this.hightLineObject?.remove();

    const { data } = this.props;
    if (Array.isArray(mousePosition) && mousePosition.length === 2) {
      let xPosition = mousePosition[0];
      let yPosition = mousePosition[1];
      //find intersection
      const findIntersection = data.find(
        (_int, id) => Math.abs(this.xScaleObject(id + 1) - xPosition) <= 60
      );
      if (findIntersection)
        this.drawHightLightIntersection(findIntersection, xPosition, yPosition);
      //move crossHair
      this.crossHairObject?.crosshair_x
        ?.attr("display", "block")
        .attr("x1", xPosition + this.marginX)
        .attr("x2", xPosition + this.marginX);
      this.crossHairObject?.crosshair_y
        ?.attr("display", "block")
        .attr("y1", yPosition + this.marginY)
        .attr("y2", yPosition + this.marginY);
    }
  };
  handleMouseOut = () => {
    //hide crossHair
    this.crossHairObject?.crosshair_x?.attr("display", "none");
    this.crossHairObject?.crosshair_y?.attr("display", "none");
    //hide hightline data point
    this.hightLineObject?.remove();
  };
  drawXAxis = () => {
    const { data } = this.props;

    this.xScaleObject = d3
      .scaleLinear()
      .domain([0, data.length + 1])
      .range([0, this.fullWidth]);

    //create axisObject
    let xAxisObject = d3
      .axisBottom(this.xScaleObject)
      .tickSize(0)
      .tickFormat(() => null);

    //draw x axis line
    this.xAxis = this.chartObject
      .append("g")
      .attr("class", "x-axis-botttom")
      .attr(
        "transform",
        "translate(" +
        this.marginX +
        "," +
        (this.fullHeight + this.marginY) +
        ")"
      )
      .call(xAxisObject);
  };

  drawYAxis = () => {
    const { data } = this.props;
    let maxYValue = 0;
    data.forEach((int) => {
      maxYValue = Math.max(
        int.up_stream_object.existing_aog + int.down_stream_object.existing_aog,
        int.up_stream_object.predicted_aog +
        int.down_stream_object.predicted_aog,
        maxYValue
      );
    });

    this.yScaleObject = d3
      .scaleLinear()
      .domain([maxYValue * 1.15, 0])
      .range([0, this.fullHeight]);

    let yAxisObject = d3
      .axisLeft(this.yScaleObject)
      .tickSize(-this.fullWidth - this.marginX);

    d3.select(this.axisRef.current)
      .append("svg")
      .attr("width", 65)
      .style("display", "block")
      .style("width", "auto")
      .append("g")
      .attr("clip-path", "url(#clip-plan)")
      .attr(
        "transform",
        "translate(" + this.marginX + " ," + this.marginY + ")"
      )
      .attr("class", "y-axis")

      .call(yAxisObject);

    d3.select(this.tickRef.current)
      .append("svg")
      .attr(
        "width",
        this.elmChartRef.current.getBoundingClientRect().width - this.marginX
      )
      .style("display", "block")
      .style("width", "auto")
      .append("g")
      .attr("clip-path", "url(#clip-plan)")
      .attr(
        "transform",
        "translate(" + this.marginX + " ," + this.marginY + ")"
      )
      .attr("class", "y-axis")

      .call(yAxisObject);
  };
  drawAxis() {
    this.drawXAxis();
    this.drawYAxis();
  }

  drawIntersectionBarChart = (int, index, barChartGroup) => {
    const defaultBarWidth = 10;
    const pointX = this.xScaleObject(index + 1);

    Object.keys(BAR_CHART_ITEM).forEach((groupKey) => {
      BAR_CHART_ITEM[groupKey].forEach((item) => {
        const pointItem = this.yScaleObject(int[groupKey][item.key]);
        barChartGroup
          .append("rect")
          .style("fill", item.color)
          .attr("clip-path", "url(#clip)")
          .attr(
            "transform",
            "translate(" + this.marginX + ", " + this.marginY + ")"
          )
          .attr("x", pointX - 10 + item.offsetX)
          .attr("y", pointItem)
          .attr("width", defaultBarWidth)
          .attr("class", "bar")
          .attr("height", this.fullHeight - pointItem);
      });
    });
  };
  drawIntersectionInfo = (int, index, barChartGroup) => {
    const { t } = this.props;
    const pointX = this.xScaleObject(index + 1);
    // up down total
    barChartGroup
      .append("text")
      .text("Up")
      .attr("class", "intersection-name")

      .attr("x", pointX - 10)
      .attr("y", this.fullHeight + this.marginY + 20)
      .attr("fill", "var(--text)");
    barChartGroup
      .append("text")
      .text("Down")
      .attr("class", "intersection-name")

      .attr("x", pointX + 20)
      .attr("y", this.fullHeight + this.marginY + 20)
      .attr("fill", "var(--text)");
    barChartGroup
      .append("text")
      .text("Total")
      .attr("class", "intersection-name")

      .attr("x", pointX + 64)
      .attr("y", this.fullHeight + this.marginY + 20)
      .attr("fill", "var(--text)");

    barChartGroup
      .append("text")
      .attr("class", "intersection-name")

      .text("---------------------")
      .attr("x", pointX - 20)
      .attr("y", this.fullHeight + this.marginY + 32)
      .attr("fill", "var(--text)");
    //int info
    barChartGroup
      .append("text")
      .text(
        int.int_name.length > 15
          ? int.int_name.substring(0, 15) + "..."
          : int.int_name
      )
      .attr("class", "intersection-name")
      .attr("text-anchor", "middle")
      .attr("x", pointX + 40)
      .attr("y", this.fullHeight + this.marginY + 48)
      .attr("fill", "var(--text)")
      .append("title")
      .text(int.int_name);

    barChartGroup
      .append("text")
      .attr("class", "intersection-name")
      .attr("text-anchor", "middle")
      .text(`${t("optimization.existing_offset")}: ${int.current_offset}`)
      .attr("x", pointX + 40)
      .attr("y", this.fullHeight + this.marginY + 68)
      .attr("fill", "var(--text)");

    barChartGroup
      .append("text")
      .attr("class", "intersection-name")
      .attr("text-anchor", "middle")
      .text(`${t("optimization.new_offset")}: ${int.best_offset}`)
      .attr("x", pointX + 40)
      .attr("y", this.fullHeight + this.marginY + 88)
      .attr("fill", "var(--text)");
  };
  drawIntersectionTrending = (int, index, barChartGroup) => {
    // symbolTriangle
    const pointX = this.xScaleObject(index + 1);

    Object.keys(BAR_CHART_ITEM).forEach((groupKey) => {
      const xOffset =
        (BAR_CHART_ITEM[groupKey][0].offsetX +
          BAR_CHART_ITEM[groupKey][1].offsetX) /
        2 -
        5;
      const pointY = this.yScaleObject(
        int[groupKey].existing_aog > int[groupKey].predicted_aog
          ? int[groupKey].existing_aog
          : int[groupKey].predicted_aog
      );
      if (int[groupKey].bias > 0) {
        barChartGroup
          .append("text")
          .text("+" + toFixed2Offset(int.total_object.bias) + "%")
          .attr("text-anchor", "middle")
          .style("font-size", "12px")
          .attr("x", pointX + this.marginX + xOffset)
          .attr("y", pointY + this.marginY - 16)
          .attr("fill", CHART_COLOR_SCHEMA.GREEN);
      } else if (int[groupKey].bias < 0) {
        barChartGroup
          .append("text")
          .text("+" + toFixed2Offset(int.total_object.bias) + "%")
          .attr("text-anchor", "middle")
          .style("font-size", "12px")
          .attr("x", pointX + this.marginX + xOffset)
          .attr("y", pointY + this.marginY - 16)
          .attr("fill", CHART_COLOR_SCHEMA.RED);
      }
    });
  };
  drawIntersectionData(int, index) {
    let barChartGroup = this.chartObject
      .append("g")
      .attr("class", "lines-chart-group")
      .style("width", "150px")
      .attr("transform", "translate(0,0)")
      .attr("width", "150px");

    barChartGroup.attr("style", "width:150px");
    this.drawIntersectionBarChart(int, index, barChartGroup);
    this.drawIntersectionInfo(int, index, barChartGroup);
    this.drawIntersectionTrending(int, index, barChartGroup);
  }

  drawAogData = () => {
    const { data } = this.props;
    data.forEach((int, id) => {
      this.drawIntersectionData(int, id);
    });
  };
  componentDidMount() {
    this.chartObject = d3.select(this.chartRef?.current).append("svg");

    const elmCharft = this.elmChartRef.current.getBoundingClientRect();

    this.fullWidth =
      this.props.data.length * 150 < elmCharft?.width
        ? elmCharft?.width - this.marginX
        : this.props.data.length * 150;
    this.fullHeight = elmCharft?.height - this.marginY * 3 - this.yOffsetInfo;

    this.drawAxis();
    this.drawAogData();
    this.renderChartCrossHair();

    this.chartObject
      .attr("width", this.fullWidth)
      .style("display", "block")
      .style("width", "auto");
  }
  render() {
    return (
      <div
        className="relative overflow-x-auto flex-1-1-auto"
        style={{
          height: "100%",
          width: "100%",
        }}
        ref={this.elmChartRef}
      >
        <div
          ref={this.axisRef}
          style={{
            height: "100%",
            backgroundColor: "var(--background)",
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 110,
            width: this.marginX,
          }}
        />
        <div
          ref={this.tickRef}
          style={{
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 32,
            width: `calc(100% - ${this.marginX}px)`,
          }}
        />
        <div
          style={{
            width: this.marginX,
            height: "100%",
            backgroundColor: "var(--background)",
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 100,
          }}
        ></div>
        <div
          ref={this.chartRef}
          className="overflow-x-auto"
          style={{
            height: "100%",
            width: `calc(100% - ${this.marginX}px)`,
            position: "absolute",
            top: 0,
            zIndex: 64,
          }}
        />
      </div>
    );
  }
}

export default withTranslation()(AogVolumeBarChart);
