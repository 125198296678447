import { action, observable, runInAction } from "mobx";
import moment from "moment";
import AgencyService from "services/AgencyService";
import AnalysisScheduleService from "services/TemplateGroup/AnalysisScheduleService";
import UserService from "services/UserService";
import BaseStore from "stores/BaseStore";
import {
  DATE_FORMAT,
  INTERSECTION_SCOPE,
  REPORT_TAB,
  RESOURCE_LIST,
  SYSTEM_ROUTES_PATH,
} from "utils/constants";

const DEFAULT_TEMPLATE_FORM = {
  general_data: {
    id: null,
    description: null,
    agency_id: null,
    time_zone: "Z",
    start_time: null,
    metric_type: null,
    week_days: [
      "MONDAY",
      "TUESDAY",
      "WEDNESDAY",
      "THURSDAY",
      "FRIDAY",
      "SATURDAY",
      "SUNDAY",
    ],
    date_range: {
      scope: "RELATIVE",
      start_date: null,
      end_date: null,
      unit: "DAY",
      offset: null,
      target_date: null,
    },
    aggregation: "HOURLY",
    metadata: {
      bin_size: null,
      from_time: "00:00",
      to_time: "23:59",
      from_date: null,
      to_date: null,
      abnormal_data_filter: [],
    },
  },

  intersection: {
    scope: "SPECIFIC_INTERSECTION",
    uuids: [],
  },
  schedule: {
    scope: "MANUAL",
    value: {
      date: moment().format(DATE_FORMAT.day_only_backend),
      offset: 1,
      day_of_week: [
        "MONDAY",
        "TUESDAY",
        "WEDNESDAY",
        "THURSDAY",
        "FRIDAY",
        "SATURDAY",
        "SUNDAY",
      ],
      day_of_month: ["1"],
    },
    mail_receive: true,
  },
};
class AnalysisScheduleTemplate extends BaseStore {
  constructor(parentStore) {
    super(new AnalysisScheduleService(parentStore), parentStore);
    this.agencyService = new AgencyService(parentStore);
    this.filterData = {
      ...this.filterData,
      ...{
        text: "",
        metric_type: null,
        agency_id: "",
        from_date: null,
        to_date: null,
        owner_id: null,
        status: null,
        sort: "",
      },
    };
    this.currentModuleRoute = SYSTEM_ROUTES_PATH.AnalysisSchedule;
    this.resourceName = RESOURCE_LIST.REPORT_TEMPLATE;
  }
  isEditFormDirty = false; // make sure formData is not be edited
  presetItemFromAnalysis = null;
  @observable context_id = null;
  @observable allIntersections = [];
  @observable allAgencies = [];
  @observable allUsers = [];
  @observable timeValueSelectedOccuring = null;
  @observable isSelectedReoccuring = false;
  @observable currentTab = REPORT_TAB.GENERAL_TAB;
  @observable formData = {
    ...DEFAULT_TEMPLATE_FORM,
  };
  @observable allAnalysisTypes = [];
  @observable eventList = [];

  setPresetItemFromAnalysis = (item) => {
    this.presetItemFromAnalysis = item;
  };
  @action setTemplateId = (id) => {
    this.context_id = id;
  };
  @action resetForm() {
    this.context_id = null;
    this.formData = {
      ...DEFAULT_TEMPLATE_FORM,
    };
  }
  @action setTimeValueSelectedOccuring = (value) => {
    this.timeValueSelectedOccuring = value;
  };

  @action setOccuringTime = () => {
    this.isSelectedReoccuring = true;
  };

  @action setInitialForm = (initialformData) => {
    this.formData = {
      ...this.formData,
      general_data: {
        ...this.formData.general_data,
        agency_id: initialformData.generalData.agency_id,
        metric_type: initialformData.generalData.analysis_type,
        metadata: {
          ...initialformData.generalData,
          from_time: moment(initialformData.generalData.from_time).format(
            DATE_FORMAT.hour
          ),
          to_time: moment(initialformData.generalData.to_time).format(
            DATE_FORMAT.hour
          ),
        },
      },
    };
  };
  //Set isEdit to false when going to the general tab once
  @action setIsEditToFalse = () => {
    this.isEditFormDirty = false;
  };
  @action setFormField = (field, value) => {
    this.isEditFormDirty = true;
    const fieldTree = field.split(".");
    switch (fieldTree.length) {
      case 1:
        {
          this.formData[field] = value;
        }
        break;
      case 2:
        {
          this.formData[fieldTree[0]][fieldTree[1]] = value;
        }
        break;
      case 3:
        {
          this.formData[fieldTree[0]][fieldTree[1]][fieldTree[2]] = value;
        }
        break;
    }
    return value;
  };
  @action getAllUsers = (agency_id) => {
    const userService = new UserService(this.parentStore);
    if (this.allUsers?.length === 0) {
      this.loading = true;
      userService?.getAllUserByAgency(
        agency_id,
        (response) => {
          runInAction(() => {
            this.allUsers = response?.data;
          });
        },
        () => {
          runInAction(() => {
            this.loading = false;
          });
        }
      );
    }
  };
  @action getAllAgencies = (cb) => {
    this.agencyService.getAll((data) => {
      runInAction(() => {
        this.allAgencies = data;
        if (!this.formData.general_data.agency_id)
          this.formData.general_data.agency_id = data[0]?.id;
        cb && cb();
      });
    });
  };

  @action runTemplate = (id, cb) => {
    this.moduleService.runTemplate(
      id,
      () => {
        runInAction(() => {
          cb && cb();
        });
      },
      () => {}
    );
  };
  @action setDetaultAgencyFilter = (isSuperAdmin, cb) => {
    if (isSuperAdmin)
      this.agencyService.getAll((data) => {
        runInAction(() => {
          this.allAgencies = data;
          //set default agency
          if (Array.isArray(this.allAgencies) && this.allAgencies[0]) {
            let defaultAgency = this.allAgencies.find(
              (item) => item.id === this.filterData.agency_id
            );
            if (!defaultAgency) {
              this.filterData.agency_id = this.allAgencies[0].id;
            }
          }
          cb && cb();
        });
      });
    else {
      const currentAgency = this.parentStore.myStore.currentAgency;
      this.filterData = {
        ...this.filterData,
        ...{ agency_id: currentAgency.agency_id },
      };
      cb && cb();
    }
  };
  @action handleChangeAnalysisType = (type) => {
    const currentType = this.allAnalysisTypes?.find((item) => item.id === type);
    currentType?.extra_fields?.forEach((extraField) => {
      this.formData.general_data.metadata[extraField.id] =
        extraField.default_value;
    });
    return this.setFormField("general_data.metric_type", type);
  };
  @action getAllAnalysisTypes = (cb) => {
    this.moduleService.getAllAnalysistypeReport().then((data) => {
      runInAction(() => {
        this.allAnalysisTypes = data.analyses;
        this.handleChangeAnalysisType(data.analyses[0].id);
      });
      cb && cb();
    });
  };

  @action getTemplateDetail = (id, cb) => {
    this.isEditFormDirty = false;
    this.moduleService?.getDetail(id, (response) => {
      runInAction(() => {
        this.formData = {
          ...DEFAULT_TEMPLATE_FORM,
          general_data: {
            ...DEFAULT_TEMPLATE_FORM.general_data,
            ...response,
          },
        };
      });
      this.moduleService.getScheduleDetail(id, (response) => {
        runInAction(() => {
          this.formData.schedule = {
            ...DEFAULT_TEMPLATE_FORM.schedule,
            ...response,
            value: {
              ...DEFAULT_TEMPLATE_FORM.schedule.value,
              ...response?.value,
            },
          };
          cb && cb();
        });
      });
    });
  };

  @action changeReportTemplateTab = (tab) => {
    this.currentTab = tab;
  };
  @action setIntersectionList = (scope, ids) => {
    if (scope === INTERSECTION_SCOPE.SPECIFIC) {
      this.formData.intersection = {
        scope: scope,
        uuids: ids.map((e) => e?.id),
      };
    } else
      this.formData.intersection = {
        scope: scope,
        uuids: "*",
      };
  };
  @action submitUpdateSchedule = (cb, fb) => {
    this.moduleService?.updateSchedule(
      {
        id: this.formData.general_data.id,
        data: this.formData.schedule,
      },
      (id) => {
        cb && cb(id);
      },
      (error) => {
        runInAction(() => {
          fb && fb(error?.errors);
          this.loading = false;
        });
      }
    );
  };
  @action submitUpdateTemplate = (cb, fb) => {
    this.moduleService?.update(
      this.formData,
      (id) => {
        // helper.showNotification(NOTIFICATION_TYPE.SUCCESS, i18n.t("success"), i18n.t("update_success"))
        cb && cb(id);
      },
      (error) => {
        runInAction(() => {
          fb && fb(error?.errors);
          this.loading = false;
        });
      }
    );
  };
  @action submit = (cb, fb) => {
    this.loading = true;

    this.moduleService?.create(
      this.formData,
      () => {
        cb && cb();
      },
      (error) => {
        runInAction(() => {
          fb && fb(error?.errors);
          this.loading = false;
        });
      }
    );
  };
  @action setStatus = (data = null, status) => {
    this.loading = true;
    if (!data) {
      data = this.selectedItems;
    }
    this.moduleService?.updateStatus(
      "/active",
      { ids: data, status },
      () => {
        this.getData();
      },
      () => {
        runInAction(() => {
          this.loading = false;
        });
      }
    );
  };
  @action delete = (data = null) => {
    this.loading = true;
    if (!data) {
      data = this.selectedItems;
    }
    this.moduleService?.delete(
      data,
      () => {
        //redirect into page 1 avoid load wrong data
        this.filterData.pagination.current = 1;
        this.getData();
      },
      () => {
        runInAction(() => {
          this.loading = false;
        });
      }
    );
  };

  @action getAllPerflogEvent = () => {
    this.moduleService
      ?.getAllPerflogEvent()
      .then((data) => {
        runInAction(() => {
          this.eventList = data;
        });
      })
      .catch(() => {});
  };
}

export default AnalysisScheduleTemplate;
