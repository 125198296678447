import React from "react";
import { Col, DatePicker, Icon, Input, Radio, Select, Tooltip } from "antd";
import BaseManagementComponents from "components/BaseManagementComponents";
import StatusTag from "components/StatusTag";
import { inject, observer } from "mobx-react";
import moment from "moment";
import queryString from "query-string";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import {
  ACTIONS_LIST,
  ACTIVE,
  DATE_FORMAT,
  INACTIVE,
  REPORT_TEMPLATE_TYPE,
  RESOURCE_LIST,
  STATUS_LIST,
  WEEK_DAY_OPTION,
} from "utils/constants";
import ReportTemplateDetailPopup from "./AnalysisScheduleDetailPopup";
import "./style.scss";

const { Option } = Select;
const { RangePicker } = DatePicker;

@inject("rootStore")
@observer
class AnalysisSchedule extends BaseManagementComponents {
  constructor(props) {
    super(props.rootStore.analysisScheduleStore, props);
    const { t } = props;
    this.rowKey = "id";
    this.moduleName = "performance-metric";
    this.resourceName = RESOURCE_LIST.PERFORMANCE_METRIC_REPORT;
    this.pageTitle = t("analysis_schedule.page-title");
    this.columns = this.getTableColumns();
    this.enableRedirectPage = true;
    this.state = {
      expand: true,
    };
    this.buttonActions = [
      {
        key: "active_all",
        title: t("report_template.active_all"),
        action: (ids) => this.currentStore?.setStatus(ids, ACTIVE),
        resource: RESOURCE_LIST.PERFORMANCE_METRIC_REPORT_TEMPLATE_DETAIL,
        permission: ACTIONS_LIST.EDIT,
        type: "check",
        confirm: true,
        confirmTitle: t("report_template.active_confirm_title"),
        confirmContent: t("report_template.active_confirm_content"),
      },
      {
        key: "deactive_all",
        title: t("report_template.deactive_all"),
        resource: RESOURCE_LIST.PERFORMANCE_METRIC_REPORT_TEMPLATE_DETAIL,
        action: (ids) => this.currentStore?.setStatus(ids, INACTIVE),
        permission: ACTIONS_LIST.EDIT,
        type: "stop",
        confirm: true,
        confirmTitle: t("report_template.inactive_confirm_title"),
        confirmContent: t("report_template.inactive_confirm_content"),
      },
      {
        key: "delete_all",
        title: t("report_template.delete_all"),
        type: "delete",
        resource: RESOURCE_LIST.PERFORMANCE_METRIC_REPORT,
        permission: ACTIONS_LIST.DELETE,
        action: this.currentStore?.delete,
        confirm: true,
        confirmTitle: t("report_template.delete_confirm_title"),
        confirmContent: t("report_template.delete_confirm_content"),
      },
    ];

    this.tableInlineActions = [
      {
        key: "duplicate",
        tooltip: t("report_template.duplicate"),
        action: this.handleDuplicate,
        resource: RESOURCE_LIST.PERFORMANCE_METRIC_REPORT,
        permission: ACTIONS_LIST.CREATE,
        icon: "copy",
        passingData: (item) => {
          return [item?.id];
        },
      },
      {
        key: "run_template",
        tooltip: t("report_template.run_template"),
        action: this.handleRunTemplate,
        resource: RESOURCE_LIST.PERFORMANCE_METRIC_REPORT,
        permission: ACTIONS_LIST.CREATE,
        child: (
          <Icon
            style={{ fontSize: "16px" }}
            type="play-square"
            theme="filled"
          />
        ),
        passingData: (item) => {
          return [item?.id];
        },
      },
      {
        key: "deactive_all",
        tooltip: t("report_template.deactive"),
        action: this.handleTableAction,
        resource: RESOURCE_LIST.PERFORMANCE_METRIC_REPORT_TEMPLATE_DETAIL,
        permission: ACTIONS_LIST.EDIT,
        icon: "stop",
        passingData: (item) => {
          return ["deactive_all", [item?.id]];
        },
        visible: (item) => {
          return item?.status === ACTIVE;
        },
      },
      {
        key: "active_all",
        tooltip: t("report_template.active"),
        action: this.handleTableAction,
        resource: RESOURCE_LIST.PERFORMANCE_METRIC_REPORT_TEMPLATE_DETAIL,
        permission: ACTIONS_LIST.EDIT,
        icon: "check",
        passingData: (item) => {
          return ["active_all", [item?.id]];
        },
        visible: (item) => {
          return item?.status !== ACTIVE;
        },
      },
      {
        key: "edit",
        tooltip: t("report_template.edit"),
        resource: RESOURCE_LIST.PERFORMANCE_METRIC_REPORT_TEMPLATE_DETAIL,
        action: this.goToDetailPage,
        permission: ACTIONS_LIST.EDIT,
        icon: "edit",
        passingData: (item) => {
          return [item?.id];
        },
      },
      {
        key: "delete_all",
        tooltip: t("report_template.delete"),
        action: this.handleTableAction,
        resource: RESOURCE_LIST.PERFORMANCE_METRIC_REPORT,
        permission: ACTIONS_LIST.DELETE,
        child: <Icon style={{ fontSize: "16px" }} type="delete"/>,
        passingData: (item) => {
          return ["delete_all", [item?.id]];
        },
      },
    ];
  }

  componentDidMount = () => {
    const {
      t,
      rootStore: { commonStore },
    } = this.props;
    document.title = t("report_template.page-title");
    if (this.props.location?.search?.length > 0) {
      let filterObject = queryString?.parse(this.props.location.search);
      this.currentStore?.setFilterData(filterObject);
    }
    this.fetchReportTemplateByAgency(commonStore.isSuperAdmin());
    this.currentStore.clearSelectedItems();
  };
  handleRunTemplate = (templateId) => {
    const { t } = this.props;
    this.showConfirmBox(
      t("report_template.run_confirm_title"),
      t("report_template.run_confirm_content"),
      this.currentStore.runTemplate,
      templateId
    );
  };

  fetchReportTemplateByAgency = (isSuperAdmin) => {
    this.currentStore?.getAllUsers();
    this.currentStore.getAllAnalysisTypes();
    this.currentStore?.setDetaultAgencyFilter(isSuperAdmin, () => {
      this.currentStore?.getData(() => {
      });
    });
  };

  getTableColumns = () => {
    const {
      t,
      rootStore: { myStore },
    } = this.props;
    return [
      {
        title: t("alarm_rules.analysis_id"),
        key: "metric_type",
        ellipsis: true,
        render: (item) => {
          return myStore.hasPermission(
            ACTIONS_LIST.VIEW,
            RESOURCE_LIST.REPORT_TEMPLATE_DETAIL,
            false,
            item.owner_id
          ) ? (
            <div>
              <Tooltip title={item.metric_type_label} placement={"topRight"}>
                <a
                  onClick={() =>
                    this.currentStore?.showCreateOrEditForm(
                      t("performance-metric.templates.show-form"),
                      item.id,
                      false
                    )
                  }>
                  {item.metric_type_label}
                </a>
              </Tooltip>
            </div>
          ) : (
            item.metric_type_label
          );
        },
      },
      {
        title: t("report_template.description"),
        key: "description",
        ellipsis: true,
        render: (item) => t(item?.description),
      },
      {
        title: t("report_template.day_of_week"),
        key: "week_days",
        render: (item) =>
          item.week_days
            .map((e) => {
              return WEEK_DAY_OPTION.find((i) => i.value == e).label;
            })
            .join(", "),
      },
      {
        title: t("report_result.time_range"),
        key: "time_range",
        ellipsis: true,
        render: (item) => {
          return (
            moment(item.metadata.from_time, DATE_FORMAT.time_only_full).format(
              DATE_FORMAT.hour
            ) +
            " - " +
            moment(item.metadata.to_time, DATE_FORMAT.time_only_full).format(
              DATE_FORMAT.hour
            )
          );
        },
        width: 120,
      },

      {
        title: t("report_template.created_by"),
        key: "owner",
        ellipsis: true,
        render: (item) => item?.owner?.name,
      },

      {
        title: t("report_template.created_at"),
        key: "created_at",
        sorter: true,
        sortDirections: ["descend", "ascend"],
        ellipsis: true,
        render: (item) =>
          moment(item.created_at).format(DATE_FORMAT.full_with_tz),
      },
      {
        title: t("report_template.status"),
        dataIndex: "status",
        key: "status",
        width: 150,
        render: (status) => <StatusTag status={status}/>,
      },
    ];
  };
  /**
   * renderDetailData
   * @return  {Component}
   */
  renderDetailData = () => {
    return (
      <ReportTemplateDetailPopup
        childRef={(ref) =>
          (this.defailFromRef = ref)
        }></ReportTemplateDetailPopup>
    );
  };

  goToDetailPage = (id) => {
    const { history } = this.props;
    history.push("/report/template/performance-metric/" + id);
  };

  handleDuplicate = (id) => {
    const { history } = this.props;
    history.push(
      "/report/template/performance-metric/create" + "?cloneid=" + id
    );
  };
  handleChangeActiveTab = (value) => {
    const { history } = this.props;
    switch (value) {
      case REPORT_TEMPLATE_TYPE.ANALYSIS_SCHEDULE:
        history.push("/report/template/performance-metric");
        break;
      case REPORT_TEMPLATE_TYPE.REPORT_TEMPLATE:
        history.push("/report/template/summary-report");
        break;

      default:
        history.push("/report/template/summary-report");
        break;
    }
  };
  renderFilterComponent = () => {
    const {
      t,
      rootStore: { commonStore },
      activeTab,
    } = this.props;
    const {
      allAgencies,
      allUsers,
      allAnalysisTypes,
      filterData,

      handleFilterDataChange,
    } = this.currentStore;

    return (
      <div className="wrap-filter">
        <Col lg={8} xl={4}>
          <Radio.Group
            buttonStyle="solid"
            value={activeTab}
            onChange={(e) => this.handleChangeActiveTab(e.target.value)}>
            <Radio.Button value={REPORT_TEMPLATE_TYPE.REPORT_TEMPLATE}>
              {t("report_result.print_title")}
            </Radio.Button>
            <Radio.Button value={REPORT_TEMPLATE_TYPE.ANALYSIS_SCHEDULE}>
              {t("analysis.perfomance-metric")}
            </Radio.Button>
          </Radio.Group>
        </Col>
        <Col lg={8} xl={3}>
          <Tooltip title={t("report_template.filter_text")}>
            <Input
              value={filterData?.text}
              onChange={(e) => handleFilterDataChange("text", e?.target.value)}
              placeholder={t("analysis_schedule.filter_text")}
            />
          </Tooltip>
        </Col>
        <Col lg={16} xl={5}>
          <Tooltip title={t("report_template.filter_date")}>
            <RangePicker
              value={[
                filterData?.from_date &&
                moment.unix(filterData?.from_date / 1000),
                filterData?.to_date && moment.unix(filterData?.to_date / 1000),
              ]}
              format={DATE_FORMAT.day_only}
              onChange={(e) => {
                handleFilterDataChange(
                  "from_date",
                  e?.[0]?.startOf("day").utc().format("x"),
                  false
                );
                handleFilterDataChange(
                  "to_date",
                  e?.[1]?.endOf("day").utc().format("x")
                );
              }}
            />
          </Tooltip>
        </Col>
        <Col lg={8} xl={3}>
          <Tooltip title={t("alarm_records.analysis_type_tooltip")}>
            <Select
              onChange={(value) => handleFilterDataChange("metric_type", value)}
              value={filterData?.metric_type}>
              <Option value={null}>{t("alarm_rules.all_analysis_type")}</Option>
              {allAnalysisTypes.map((e) => (
                <Option value={e.id} key={e.id}>
                  {e.name}
                </Option>
              ))}
            </Select>
          </Tooltip>
        </Col>
        <Col lg={8} xl={4}>
          <Tooltip title={t("report_template.filter_owner")}>
            <Select
              onChange={(value) => handleFilterDataChange("owner_id", value)}
              value={filterData?.owner_id}
              showSearch
              optionFilterProp="filter"
              filterOption={(input, option) => {
                return (
                  option?.props?.filter
                    ?.toLowerCase()
                    ?.indexOf(input?.toLowerCase()) >= 0
                );
              }}>
              <Option value={null}>{t("report_template.all_owner")}</Option>
              {allUsers.map((e) => (
                <Option value={e?.id} key={e?.id} filter={e?.name}>
                  <b>{e?.name}</b> ({e?.email})
                </Option>
              ))}
            </Select>
          </Tooltip>
        </Col>
        <Col
          lg={8}
          xl={2}
          style={{ display: this.state.expand ? "block" : "none" }}>
          <Tooltip placement="top" title={t("report_template.filter_status")}>
            <Select
              onChange={(value) => handleFilterDataChange("status", value)}
              value={filterData?.status}>
              <Option value={null}>{t("report_template.all_status")}</Option>
              {STATUS_LIST?.map((e) => (
                <Option key={e.key} value={e.key}>
                  {t(e.value)}
                </Option>
              ))}
            </Select>
          </Tooltip>
        </Col>
        {commonStore.isSuperAdmin() && (
          <Col lg={8} xl={3}>
            <Tooltip
              title={t("report_template.filter_agency")}
              key={allAgencies.length && allAgencies?.[0]?.id}>
              {filterData.agency_id && (
                <Select
                  showSearch
                  onChange={(value) =>
                    handleFilterDataChange("agency_id", value)
                  }
                  value={filterData.agency_id}
                  optionFilterProp="filter"
                  filterOption={(input, option) => {
                    return (
                      option?.props?.filter
                        ?.toLowerCase()
                        ?.indexOf(input?.toLowerCase()) >= 0
                    );
                  }}>
                  {allAgencies?.map((e) => (
                    <Option key={e?.id} value={e?.id} filter={e?.name}>
                      {e?.name}
                    </Option>
                  ))}
                </Select>
              )}
            </Tooltip>
          </Col>
        )}
      </div>
    );
  };

  render() {
    return <div className="wrap-alarm-rule">{super.render()}</div>;
  }
}

export default withRouter(withTranslation()(AnalysisSchedule));
