import { Table } from "antd";
import React from "react";
import i18n from "../../../../../i18n";

const columns = [
  {
    title: i18n.t("analysis.phase_name"),
    dataIndex: "phases",
    key: "phases",
  },
  {
    title: i18n.t("optimization.existing_aog"),
    dataIndex: "existing_aog",
    key: "existing_aog",
  },
  {
    title: i18n.t("optimization.existing_aog_percent"),
    dataIndex: "existing_aog_percent",
    key: "existing_aog_percent",
    render: (item) => (item % 1 === 0 ? item : Number(item).toFixed(2)),
  },
  {
    title: i18n.t("optimization.predicted_aog"),
    dataIndex: "predicted_aog",
    key: "predicted_aog",
  },
  {
    title: i18n.t("optimization.predicted_aog_percent"),
    dataIndex: "predicted_aog_percent",
    key: "predicted_aog_percent",
    render: (item) => (item % 1 === 0 ? item : Number(item).toFixed(2)),
  },
];

class TableResult extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="wrap-table w-full">
        <Table
          pagination={false}
          rowClassName={(record, index) => {
            if ((index + 1) % 2 == 0) return "odd-row";
            else return "even-row";
          }}
          scroll={{ y: "50vh" }}
          rowKey="phases"
          className="spm-table"
          dataSource={this.props.data.filter((e) => e.is_coord_phase)}
          columns={columns}
        />
      </div>
    );
  }
}

export default TableResult;
