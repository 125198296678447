import { Alert, Button, Icon, Popover, Row, Slider, Tooltip } from "antd";
import L from "leaflet";
import { inject, observer } from "mobx-react";
import moment from "moment";
import React, { Component, createRef } from "react";
import { CircleMarker, Map, Marker, Popup, TileLayer } from "react-leaflet";
import { DATE_FORMAT, MAP_DELAY_LOAD_DATA, TSP_KEY_TAB } from "utils/constants";
import { createPulseIcon } from "../../../libs/PulseIcon";
import { rotateIconFeature } from "../../../libs/rotateIcon";
import srcIconBus from "../../../svgs/bus-top.svg";
// import MapHeader from "./MapHeader";
import MapFilter from "./MapFilter";
import "./style.scss";

const busIcon = L.icon({
  iconUrl: srcIconBus,
  iconSize: [50, 50],
  iconAnchor: [25, 25],
});

createPulseIcon(L);
rotateIconFeature(L);
@inject("rootStore")
@observer
class TSPMapViewSVG extends Component {
  currentTopLeft = {}; //current toplef position of map => use for get data by area(rectangle)
  currentBottomRight = {}; //current rightbutton position of map => use for get data by area(rectangle)
  timeoutCheckInterval = null;
  indexArray = 0;
  markers = {};
  map = createRef();

  updateBusLocation = (tsp_event) => {
    if (this.markers[tsp_event?.vehicle_id]) {
      this.markers[tsp_event.vehicle_id].setLatLng([
        tsp_event.vehicle_location.latitude,
        tsp_event.vehicle_location.longitude,
      ]);
      this.markers[tsp_event.vehicle_id].setRotationAngle(
        tsp_event.vehicle_location.heading
      );
    } else
      this.markers[tsp_event.vehicle_id] = L.marker(
        [
          tsp_event.vehicle_location.latitude,
          tsp_event.vehicle_location.longitude,
        ],
        {
          icon: busIcon,
          rotationAngle: tsp_event.vehicle_location.heading,
        }
      ).addTo(this.map.current.leafletElement);
  };
  componentWillUnmount() {
    if (this.intervalDemo) clearInterval(this.intervalDemo);
  }

  /**
   * handlerViewPortChange
   * this function triggered whenever map location change(position/zoom level)
   *
   * we will get new current top/left buttom/right position to get new intersection belong to that area
   *
   * we not execute get intersection immediately cuz maybe current change is not what customer want
   * we push current position to delay function ( settimeout) and execute in next 1.5s
   * if any new position is comming we clean the old delay function.
   *
   * @return {null}
   */
  handlerViewPortChange = () => {
    let mapInst = this.map.current?.leafletElement;
    if (mapInst) {
      let currentTopLeft = mapInst.getBounds()?.getNorthWest();
      this.currentBottomRight = mapInst.getBounds()?.getSouthEast();
      this.currentTopLeft = currentTopLeft;
      let context = this;
      if (this.timeoutCheckInterval) {
        clearTimeout(this.timeoutCheckInterval);
      }
      this.timeoutCheckInterval = setTimeout(function () {
        context.loadIntersectionData(currentTopLeft);
      }, MAP_DELAY_LOAD_DATA);
    }
  };

  /**
   * loadIntersectionData
   * load intersection list by target postion
   *
   * cuz this function runby settimeout function
   * => may be target position is not lastest => have to check that
   * @params {Object} currentTopLeft : target topleft position
   *
   * @return {null} call store to get intersection
   */
  loadIntersectionData = (currentTopLeft) => {
    const {
      rootStore: { TSPStore },
    } = this.props;
    //check target topleft is the lastest one or not
    //if don't ignore that
    if (
      currentTopLeft.lat === this.currentTopLeft?.lat &&
      currentTopLeft.lng === this.currentTopLeft?.lng
    ) {
      TSPStore?.setMapBounder(this.currentTopLeft, this.currentBottomRight);
    }
    //after call don't need other delay request running
    this.timeoutCheckInterval = null;
  };
  handleRedirectToHistoryTab = (item) => {
    const {
      t,
      rootStore: { TSPStore, intersectionEventHistoryStore },
    } = this.props;
    intersectionEventHistoryStore.handleFilterDataChange(
      "intersection_uuid",
      item.id,
      false
    );
    const now = moment().valueOf();
    intersectionEventHistoryStore.handleFilterDataChange("to_time", now, false);
    intersectionEventHistoryStore.handleFilterDataChange(
      "from_time",
      now - 86400000,
      false
    );
    intersectionEventHistoryStore.handleFilterDataChange(
      "range",
      86400000,
      false
    );
    intersectionEventHistoryStore.handleFilterDataChange(
      "intersection_uuid",
      item.id
    );
    TSPStore.setActiveTab(TSP_KEY_TAB.INT_EVENT_HISTORY);
    document.title = t("tsp_dashboard.int_event_history_page_title");
  };
  render() {
    const {
      t,
      rootStore: {
        TSPStore: {
          tspMapSetting,
          listFilterIntersections,
          isSave,

          handleMapSettingChange,
          saveMapSetting,
          resetIsSave,
          clearTimeoutSucces,
        },
      },
    } = this.props;

    const pulsingIntersection = L.icon.pulse({
      iconSize: [tspMapSetting.radius * 2.19, tspMapSetting.radius * 2.19],
      color: "#0f9f9f",
    });
    return (
      <div>
        <div className="flex justify-space-beetwen tsp-map-header">
          <div>{t("tsp_dashboard.last_24h")}</div>
          <div className="flex helper-badge">
            <div className="setting-btn-group">
              <Tooltip placement="bottom" title={t("heatmap.setting")}>
                <Popover
                  content={
                    <div className="map-setting-info-panel">
                      <Row>
                        <h4>{t("heatmap.setting_dot_size")}</h4>
                        <Slider
                          value={tspMapSetting.radius}
                          min={1}
                          max={40}
                          onChange={(value) =>
                            handleMapSettingChange("radius", parseInt(value))
                          }
                        />
                      </Row>
                      <div>
                        <Icon type="info-circle" className="mr-4" />
                        <span>{t("nomal_map_setting_text")}</span>
                      </div>
                      {isSave && (
                        <Alert
                          className="mt-8"
                          message={t("map_setting_success")}
                          showIcon
                          type="success"
                        />
                      )}
                      <div className="flex justify-end w-full mt-8">
                        <Button type="primary" onClick={saveMapSetting}>
                          {t("save_map_setting")}
                        </Button>
                      </div>
                    </div>
                  }
                  placement="left"
                  trigger="click">
                  <Icon
                    type="setting"
                    onClick={() => {
                      resetIsSave();
                      clearTimeoutSucces();
                    }}
                  />
                </Popover>
              </Tooltip>
            </div>
          </div>
        </div>
        <Map
          ref={this.map}
          center={[
            tspMapSetting?.center_pointer.lat,
            tspMapSetting?.center_pointer.lon,
          ]}
          zoom={Math.floor(tspMapSetting?.zoom_level)}
          style={{ height: "calc(100vh - 192px)", borderRadius: "4px" }}
          onViewportChanged={this.handlerViewPortChange}
          preferCanvas={true}>
          <TileLayer
            url={process.env.REACT_APP_OPEN_STREET_MAP}
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />
          <MapFilter />
          {listFilterIntersections?.map((intersection) => {
            if (!intersection.last_event_time) return null;
            if (!intersection.hasNewEvent)
              return (
                <CircleMarker
                  key={intersection?.id}
                  center={[intersection?.latitude, intersection?.longitude]}
                  color={"#0f9f9f"}
                  fill={true}
                  fillOpacity={1}
                  radius={tspMapSetting.radius}>
                  <Popup>
                    <div className="">
                      <div>
                        <p style={{ fontSize: 14, marginBottom: 4 }}>
                          <b> {intersection.name}</b>
                        </p>
                        <p style={{ marginTop: 4 }}>
                          {t("intersection.most_recent_tsp")}{" "}
                          {moment
                            .utc(intersection.last_event_time)
                            .format(DATE_FORMAT.tsp_mapview_zone)}
                        </p>
                      </div>
                      <hr
                        style={{
                          borderBottom: "1px solid #e8e8e8",
                          borderTop: 0,
                        }}
                      />
                      <div>
                        {t("intersection.goto")} :{" "}
                        <a
                          onClick={() =>
                            this.handleRedirectToHistoryTab(intersection)
                          }>
                          {t("tsp_dashboard.go_to_tsp_event")}
                        </a>
                      </div>
                    </div>
                  </Popup>
                </CircleMarker>
              );
            else
              return (
                <Marker
                  icon={pulsingIntersection}
                  key={intersection?.id}
                  position={[intersection?.latitude, intersection?.longitude]}>
                  <Popup>
                    <div className="">
                      <div>
                        <p style={{ fontSize: 14, marginBottom: 4 }}>
                          <b> {intersection.name}</b>
                        </p>
                        <p style={{ marginTop: 4 }}>
                          {t("intersection.most_recent_tsp")}{" "}
                          {moment
                            .utc(intersection.last_event_time)
                            .format(DATE_FORMAT.tsp_mapview_zone)}
                        </p>
                      </div>
                      <hr
                        style={{
                          borderBottom: "1px solid #e8e8e8",
                          borderTop: 0,
                        }}
                      />
                      <div>
                        {t("intersection.goto")} :{" "}
                        <a
                          onClick={() =>
                            this.handleRedirectToHistoryTab(intersection)
                          }>
                          {t("tsp_dashboard.go_to_tsp_event")}
                        </a>
                      </div>
                    </div>
                  </Popup>
                </Marker>
              );
          })}
        </Map>
      </div>
    );
  }
}

export default TSPMapViewSVG;
