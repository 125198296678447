import { Avatar, Button, Col, Drawer, Icon, Modal, Row } from "antd";
import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import helper from "../../../../utils/helper";
import AgencySwitcher from "../AgencySwitcher";
import UserSidePane from "../UserSidePane";
import "./style.scss";

const { confirm } = Modal;
/**
 * Component for showing user info pannel
 *
 * @component
 */
@inject("rootStore")
@observer
class UserInfoPanel extends Component {
  userSidePanelForm = null;

  /**
   * goToProfile
   * this function show profile side pannel and hide user panel box
   * @param   {boolean} status  true/false
   * @return  {null} -
   */
  handleGoToProfile = () => {
    const {
      rootStore: { myStore, commonStore },
    } = this.props;
    commonStore?.setVisibleUserPanel(false);
    myStore?.setVisibleUserSidePanel(true);
  };
  /**
   * handleLogout
   * this function show hide user panel box then logout
   * @param   {boolean} status  true/false
   * @return  {null} -
   */
  handleLogout = () => {
    const {
      rootStore: { authStore },
    } = this.props;
    authStore?.logout();
  };
  /**
   * handleOnCloseUserSidePanel
   * check form is dirty
   * if form dirty show confirm redirect page then close the side page
   *
   * @param   {boolean} status  true/false
   * @return  {null} -
   */
  handleOnCloseUserSidePanel = () => {
    const {
      t,
      rootStore: { myStore },
    } = this.props;
    if (this.userSidePanelForm?.checkFormIsChanged()) {
      confirm({
        title: t("common.confirm_redirect_title"),
        content: t("common.confirm_redirect_content"),
        okText: t("common.leave"),
        okType: "danger",
        cancelText: t("common.stay"),
        onOk() {
          myStore?.setVisibleUserSidePanel(false);
        },
      });
    } else {
      myStore?.setVisibleUserSidePanel(false);
    }
  };

  /**
   * render
   *
   * @return  {Component}
   */
  render() {
    const {
      t,
      rootStore: { commonStore, myStore },
    } = this.props;
    let user = this.props.currentUser;
    return (
      <div className="wrap-user-info-panel">
        <Row className="user-info-content">
          <Col span={8}>
            <Avatar size={64} src={user?.avatar_url}>
              {!user?.avatar_url
                ? helper.getFirstsLetterFromWords(user?.name)?.substring(0, 2)
                : ""}
            </Avatar>
          </Col>
          <Col span={16}>
            <div className="user-name">{user?.name}</div>
            <div className="spm-ellipsis">{user?.email}</div>
            <div>
              <Button icon="user" type="link" onClick={this.handleGoToProfile}>
                {t("profile.account_settings")}
              </Button>
            </div>
          </Col>
        </Row>
        {!commonStore?.isSuperAdmin() && (
          <div>
            <AgencySwitcher />
          </div>
        )}
        <div className="profile-footer" onClick={this.handleLogout}>
          <a className="logout-link">
            <Icon type="logout" />
            <span className="nav-text">{t("logout")}</span>
          </a>
        </div>
        <Drawer
          title={t("profile.account_settings")}
          className="spm-drawer-user-side-pane"
          placement="right"
          destroyOnClose
          onClose={this.handleOnCloseUserSidePanel}
          visible={myStore?.showUserSidePane}
        >
          <UserSidePane
            childRef={(ref) => (this.userSidePanelForm = ref)}
            defaultTab={myStore.defaultUserSidePanelTab}
          />
        </Drawer>
      </div>
    );
  }
}

export default withRouter(withTranslation()(UserInfoPanel));
