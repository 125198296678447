import React, { Component } from "react";

class WrapChartSplitMonitor extends Component {
  constructor(props) {
    super(props);
    this.chartData = React.createRef();
  }

  render() {
    const { isPrintMode, children } = this.props;
    return (
      <div
        style={{ height: this.props.height, position: "relative" }}
      >
        <div
          className={
            isPrintMode ? "chart-container compare-chart" : "chart-container"
          }>
          <div className="chart-detail-container-split-monitor">
            {" "}
            {React.cloneElement(children, {
              ref: this.chartData,
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default WrapChartSplitMonitor;
