import { CHART_COLOR_SCHEMA } from "./colorSchema";

export const REPORT_TEMPLATE_TYPE = {
  REPORT_TEMPLATE: "REPORT_TEMPLATE",
  ANALYSIS_SCHEDULE: "ANALYSIS_SCHEDULE",
};
export const REPORT_TAB = {
  GENERAL_TAB: "GENERAL_TAB",
  INTERSECTION_TAB: "INTERSECTION_TAB",
  SCHEDULE_TAB: "SCHEDULE_TAB",
};
export const TIME_TO_RUN_REPORT = {
  EVERY_DAY: "EVERY_DAY",
  DAY_OF_WEEK: "DAY_OF_WEEK",
  DAY_OF_MONTH: "DAY_OF_MONTH",
};

export const TRIGGER_TYPE = {
  manual_trigger: "MANUAL",
  once_time_scheduled_trigger: "ONCE",
  recurring_scheduled_trigger: "RECURRING_SCHEDULED_TRIGGER",
};

export const RELATIVE_DATE_UNIT = [
  { key: "previous_day", value: "DAY", unit: "Days", max: 7 },
  { key: "previous_week", value: "WEEK", unit: "Weeks", max: 4 },
  { key: "previous_month", value: "MONTH", unit: "Months", max: 12 },
];
export const TRENDING = {
  GOOD: {
    key: "GOOD",
    icon: "arrow-up",
    color: CHART_COLOR_SCHEMA.GREEN,
  },
  BAD: {
    key: "BAD",
    icon: "arrow-down",
    color: CHART_COLOR_SCHEMA.RED,
  },
  NORMAL: {
    key: "NORMAL",
    icon: "arrow-none",
    text: "",
    color: CHART_COLOR_SCHEMA.YELLOW,
  },
};
export const REPORT_RESULT_TITLE_COLUMN_CSV = {
  intersection_name: "result_table.intersection_name",
  aor_max: "result_table.aor_max",
  aor_avg: "result_table.aor_avg",
  aor_min: "result_table.aor_min",

  aog_max: "result_table.aog_max",
  aog_avg: "result_table.aog_avg",
  aog_min: "result_table.aog_min",

  veh_act_max: "result_table.veh_act_max",
  veh_act_avg: "result_table.veh_act_avg",
  veh_act_min: "result_table.veh_act_min",

  max_out_max: "result_table.max_out_max",
  max_out_min: "result_table.max_out_min",
  max_out_avg: "result_table.max_out_avg",

  gap_out_max: "result_table.gap_out_max",
  gap_out_avg: "result_table.gap_out_avg",
  gap_out_min: "result_table.gap_out_min",

  force_off_max: "result_table.force_off_max",
  force_off_avg: "result_table.force_off_avg",
  force_off_min: "result_table.force_off_min",

  avg_ped_delay_max: "result_table.avg_ped_delay_max",
  avg_ped_delay_avg: "result_table.avg_ped_delay_avg",
  avg_ped_delay_min: "result_table.avg_ped_delay_min",

  ped_delay_act_max: "result_table.ped_delay_act_max",
  ped_delay_act_avg: "result_table.ped_delay_act_avg",
  ped_delay_act_min: "result_table.ped_delay_act_min",
};
export const AGGREGATION_UNIT = [
  { key: "hourly", value: "HOURLY", text: "report_template.hourly" },
  { key: "daily", value: "DAILY", text: "report_template.daily" },
  { key: "weekly", value: "WEEKLY", text: "report_template.weekly" },
];

export const STATUS_LIST = [
  { key: "ACTIVE", value: "active" },
  { key: "INACTIVE", value: "inactive" }
];
