import { Badge, Form, Select, Tooltip } from "antd";
import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Control from "react-leaflet-control";
import "./style.scss";

@inject("rootStore")
@observer
class MapFilter extends Component {
  render() {
    const {
      t,
      rootStore: { TSPStore },
    } = this.props;
    return (
      <Control position="topright" className="heatmap-switch-icon">
        <Tooltip
          placement="rightBottom"
          title={t("heatmap.intersection_filter_tooltip")}
        >
          <Form.Item
            label={
              <Badge
                color={"#0f9f9f"}
                text={t("tsp_dashboard.tsp_intersection")}
              />
            }
          >
            <Select
              showSearch
              showArrow={false}
              allowClear
              placeholder={t("heatmap.intersection_placeholder")}
              filterOption={false}
              value={TSPStore.searchString}
              autoClearSearchValue={false}
              defaultActiveFirstOption={false}
              onSearch={(value) => {
                TSPStore.updateSerachString(value);
              }}
              onChange={(value, obj) => {
                TSPStore.updateSerachString(obj?.props?.children);
              }}
            >
              {TSPStore.listFilterIntersections.map((d) => (
                <Select.Option key={d.id}>{d.name}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Tooltip>
      </Control>
    );
  }
}

export default withTranslation()(MapFilter);
