import { action, observable, runInAction } from "mobx";
import {
  ACTIONS_LIST,
  EGNOR_PERMISSION_MODULE,
  FULL_PERMISSION,
  NOTIFICATION_TYPE,
  SCOPE_SELF,
  USER_KEY_ACTION,
  USER_SIDE_PANE_TABS,
} from "utils/constants";
import i18n from "../i18n";
import AccountService from "../services/AccountService";
import helper from "../utils/helper";
import BaseStore from "./BaseStore";
const DELAY_SET_DEFAULT = 500;
class MyStore extends BaseStore {
  constructor(parentStore) {
    super(new AccountService(parentStore), parentStore);
  }

  //logined user infomation
  @observable currentProfile = null;
  //current agency that user logined
  @observable currentAgency = null;
  //current agency that user logined
  defaultUserSidePanelTab = USER_SIDE_PANE_TABS.UserDataTab;
  //default user key
  @observable userKeyObj = {
    key: "",
    needConfirm: true,
    requestConfirm: false,
    action: USER_KEY_ACTION.USER_KEY_VIEW,
  };
  //list of agencies user belong to
  @observable availableAgencies = [];

  //show or hide the profile bar on the side
  @observable showUserSidePane = false;

  //alarm summary settings
  @observable alarmSummaryFrequency = null;

  //list of agencies user belong to
  currentPermission = null;
  /**
   * setVisibleUserSidePanel
   * this function show/hile profile pannel
   * @param   {boolean} show  true/false
   * @return  {null} -
   */
  @action setVisibleUserSidePanel = (show) => {
    this.showUserSidePane = show;
  };

  /**
   * handleRedirectFromNotification
   *
   * open user panel from header(have to open this first cuz it will init the user side panel)
   * open user side panel
   * set the defaul tab to user key section
   *
   * close user panel from header => delay after 0.5s to totally open user side panel
   * update the default tab to user data tab for the next time open => delay after 0.5s to totally open user side panel
   * @return  {null} -
   */
  @action handleRedirectFromNotification = (targetTab) => {
    this.parentStore.commonStore.showUserPanel = true;
    this.defaultUserSidePanelTab = targetTab;
    this.showUserSidePane = true;
    let context = this;
    setTimeout(() => {
      context.parentStore.commonStore.setVisibleUserPanel(false);
      this.defaultUserSidePanelTab = USER_SIDE_PANE_TABS.UserDataTab;
    }, DELAY_SET_DEFAULT);
  };

  /**
   * cleanCurrentData
   * this function clear all data in store and local storage
   * @return  {null} -
   */
  @action cleanCurrentData = () => {
    this.currentProfile = null;
    this.currentAgency = null;
    this.userKeyObj = {
      key: "",
      needConfirm: true,
      requestConfirm: false,
      currentRequest: USER_KEY_ACTION.USER_KEY_VIEW,
    };
    this.availableAgencies = [];
    this.currentPermission = [];
  };

  /**
   * setCurrentProfile
   * this function save user to storage
   * @param   {object} user  user data
   * @return  {null} -
   */
  @action setCurrentProfile = (user) => {
    this.currentProfile = user;
  };

  /**
   * getCurrentProfile
   * this function return current logined user to
   * @return  {object} -
   */
  getCurrentProfile = () => {
    return this.currentProfile;
  };

  /**
   * setCurrentAgency
   * this function save agency, action, permission to storage
   * @param   {object} currentAgency  agency data
   * @return  {null} -
   */
  @action setCurrentAgency = (currentAgency) => {
    this.currentAgency = currentAgency;
    let permissions = [];
    this.currentPermission = {};
    currentAgency?.permissions.forEach((item) => {
      if (item) {
        //default permission_name format is [parentmodulename].[modulename]
        permissions.push(item.resource);
        // let per = item.resource?.split(".");
        // if (per.length > 1) {
        //   //auto push parent permission on list
        //   permissions.push(per[0]);
        //   this.currentPermission[per[0]] += ACTIONS_LIST.VIEW;
        // }
        if (!this.currentPermission[item.resource])
          this.currentPermission[item.resource] = [];

        this.currentPermission[item.resource].push({
          actions: item.actions,
          scope: item.scope,
        });
      }
    });
  };

  /**
   * getCurrentAgency
   * this function return current agency of logined user
   * @return  {object} -
   */
  getCurrentAgency = () => {
    return this.currentAgency;
  };

  /**
   * getCurrentRoleName
   * this function return current user role for current logined agency
   * @return  {string} -
   */
  getCurrentRoleName = () => {
    return this.currentAgency?.role_name;
  };

  /**
   * getDefaultAgency
   * this function return default agency of current user
   * @return  {string} -
   */
  getDefaultAgency = () => {
    return this.availableAgencies?.filter((e) => {
      return e.default_agency;
    })[0];
  };

  /**
   * switchAgency
   * this function return default agency of current user
   * @param   {object} payload  new agency
   * @return  {null} -
   */
  @action switchAgency = (payload) => {
    this.moduleService
      ?.switchAgency(payload)
      .then(() => {
        // this.parentStore?.authStore?.saveToken(response?.data);
        // this.setCurrentAgency(response?.data?.authority);
        //this is importance to get new permission of next agency
        //cuz after reload the screen we call auth/check to get the new permission
        window.location.href = "/";
      })
      .catch((error) => {
        helper.showNotification(
          NOTIFICATION_TYPE.ERROR,
          i18n.t(error.key),
          error?.errors[0].message
        );
      });
  };

  /**
   * updateCurrentProfileInfo
   * this function update some infomation of current user
   * @param   {object} newData  new Data
   * @return  {null} -
   */
  @action updateCurrentProfileInfo = (newData) => {
    this.currentProfile.name = newData?.name;
    this.currentProfile.phone = newData?.phone;
    if (newData?.avatar) {
      helper
        .fileToBase64(newData?.avatar)
        .then((result) => {
          runInAction(() => {
            this.currentProfile.avatar_url = result;
          });
        })
        .catch(() => {});
    }
  };

  /**
   * refreshCurrentProfile
   * this function re-get user detail information
   * after load success update current language as lastest setting
   * @param   {object} payload  new agency
   * @return  {null} -
   */
  @action refreshCurrentProfile = () => {
    this.moduleService
      ?.getUserDetail()
      .then((response) => {
        this.setCurrentProfile(response);
        i18n.changeLanguage(response?.language);
      })
      .catch(() => {
        //@TODO: handler error
      });
  };

  /**
   * getAllAvaiableAgencies
   * get all available agencies for current user
   * @return  {null} -
   */
  @action getAllAvaiableAgencies = () => {
    this.moduleService
      ?.getAllAvaiableAgencies()
      .then((response) => {
        runInAction(() => {
          this.availableAgencies = response;
        });
      })
      .catch(() => {
        //@TODO: handler error
      });
  };

  /**
   * saveData
   * tranform data prom json to FormData before save
   * @param   {Object} data  item of data for save
   * @param   {Object} cb  callback after save data successfull
   * @param   {Object} fb  callback after fail
   * @return  {null}
   */
  @action saveUserProfile = (data, cb, fb) => {
    this.loading = true;
    let formData = new FormData();
    formData.append("name", data.name);
    formData.append("phone", data.phone);
    if (data.avatar) {
      formData.append("avatar", data.avatar);
    }
    this.moduleService
      ?.saveCurrentProfile(formData)
      .then((response) => {
        cb && cb(response);
      })
      .catch((error) => {
        fb && fb(error?.errors);
      })
      .finally(() => {
        runInAction(() => {
          this.loading = false;
        });
      });
  };

  /**
   * saveData
   * tranform data prom json to FormData before save
   * @param   {Object} data  item of data for save
   * @param   {Object} cb  callback after save data successfull
   * @param   {Object} fb  callback after fail
   * @return  {Promise}
   */
  @action setDefaultAgency = (payload) => {
    this.loading = true;
    return new Promise((resolve, reject) => {
      this.moduleService
        ?.changeDefaultAgency(payload)
        .then((response) => {
          resolve(response);
          runInAction(() => {
            this.showUserSidePane = false;
            this.availableAgencies = this.availableAgencies?.map((e) => {
              e.default_agency = e.id == payload?.default_agency;
              return e;
            });
          });
        })
        .catch((error) => {
          reject(error?.errors);
        })
        .finally(() => {
          runInAction(() => {
            this.loading = false;
          });
        });
    });
  };

  /**
   * changePassword
   *
   * @param   {Object} value  new password object
   * @return  {Promise}
   */
  @action changePassword = (value) => {
    this.loading = true;
    return new Promise((resolve, reject) => {
      this.moduleService
        ?.changePassword(value)
        .then((response) => {
          resolve(response);
          runInAction(() => {
            this.showUserSidePane = false;
          });
          //this is importance to get new permission of next agency
          //cuz after reload the screen we call auth/check to get the new permission
          window.location.href = "/";
        })
        .catch((error) => {
          reject(error?.errors);
        })
        .finally(() => {
          runInAction(() => {
            this.loading = false;
          });
        });
    });
  };
  /**
   * updateUserKeyObj
   * update userkey object infomation
   *
   * @param   {object} newvalue  new value of current field
   * @return  {null}
   */
  @action updateUserKeyObj(newvalue) {
    this.userKeyObj = { ...this.userKeyObj, ...newvalue };
  }

  /**
   * cleanUserKeyObj
   * clean  userkey object infomation
   *
   * @param   {object} newvalue  new value of current field
   * @return  {null}
   */
  @action cleanUserKeyObj() {
    this.userKeyObj = {
      key: "",
      needConfirm: true,
      requestConfirm: false,
      action: USER_KEY_ACTION.USER_KEY_VIEW,
    };
  }

  /**
   * requestAccessUserKey
   * request change or view user key by giving current password
   *
   * @param   {object} current_password  current pass of logined user
   * @return  {Promise}
   */
  @action requestAccessUserKey(current_password) {
    this.loading = false;
    return new Promise((resolve, reject) => {
      if (this.userKeyObj.action === USER_KEY_ACTION.USER_KEY_VIEW) {
        this.moduleService
          ?.accessViewUserKey(current_password)
          .then((newKey) => {
            resolve(newKey);
            runInAction(() => {
              this.userKeyObj.key = newKey.user_key;
              this.userKeyObj.requestConfirm = false;
              this.userKeyObj.needConfirm = false;
            });
          })
          .catch((error) => {
            reject(error?.errors);
          })
          .finally(() => {
            runInAction(() => {
              this.loading = false;
            });
          });
      } else {
        this.moduleService
          ?.renewUserKey(current_password)
          .then((newKey) => {
            resolve(newKey);
            runInAction(() => {
              this.userKeyObj.key = newKey.user_key;
              this.userKeyObj.requestConfirm = false;
              this.userKeyObj.needConfirm = false;
            });
          })
          .catch((error) => {
            reject(error?.errors);
          })
          .finally(() => {
            runInAction(() => {
              this.loading = false;
            });
          });
      }
    });
  }

  /**
   * changeLanguage
   * request change default language of current user
   *
   * after change language the new token will be generated
   *
   * @param   {string} language  new language
   * @return  {Promise}
   */
  @action changeLanguage = (language) => {
    this.moduleService?.updateLanguage(language.key, () => {
      i18n.changeLanguage(language.key);
      runInAction(() => {
        this.currentProfile.language = language.key;
        //this is importance to get new permission of next agency
        //cuz after reload the screen we call auth/check to get the new permission
        window.location.href = "/";
      });
    });
  };

  /**
   * hasPermission
   * check for current login user has action on this resource or not
   *
   * @param   {string} action
   * @param   {string} module
   * @param   {boolean} ignore_owner
   * @param   {string} owner_id
   * @return  {boolean} -
   */
  hasPermission = (action, module, ignore_owner = false, owner_id = null) => {
    if (
      this.currentPermission?.hasOwnProperty(FULL_PERMISSION) ||
      EGNOR_PERMISSION_MODULE.includes(module)
    )
      return true;
    if (!action || !module || !this.currentPermission) return false;
    let resource = this.findResourceInlistCurrentPermission(module);
    if (resource && Array.isArray(resource)) {
      let permission = resource?.find((item) =>
        item?.actions?.includes(action)
      );
      if (permission) {
        if (
          !ignore_owner &&
          (action === ACTIONS_LIST.EDIT || action === ACTIONS_LIST.DELETE) &&
          permission.scope === SCOPE_SELF
        ) {
          return owner_id === this.currentProfile?.id;
        }

        return true;
      }

      return false;
    } else return false;
  };

  findResourceInlistCurrentPermission = (resource) => {
    let permission = null;
    if (resource) {
      let allResource = [];
      let lastITem = "";
      resource?.split(".").forEach((item) => {
        allResource.push(lastITem + item + ".*");
        lastITem += item + ".";
      });
      allResource = allResource.reverse();
      allResource[0] = resource;
      allResource.find((item) => {
        if (this.currentPermission[item]) {
          permission = this.currentPermission[item];
          return true;
        }
      });
    }
    return permission;
  };

  @action setAlarmSummaryFrequency = (frequency) => {
    this.alarmSummaryFrequency = frequency;
  }

  /**
   * getAlarmSummaryFrequency
   * gets the alarm summary setting for a user, sets the object to the observable state and executes the callback function
   *
   * @param   {function} cb
   * @return  {null}
   */
  @action getAlarmSummaryFrequency = (cb) => {
    this.moduleService
      ?.getAlarmSummaryFrequency(this.currentAgency.agency_id)
      .then((response) => {
        this.setAlarmSummaryFrequency(response)
      })
      .catch((error) => {
        helper.showNotification(
          NOTIFICATION_TYPE.ERROR,
          i18n.t(error.key),
          error?.errors[0].message
        );
      })
      .finally(() => {
        cb && cb()
      });
  }

  /**
   * changeAlarmSummaryFrequency
   * sets alarm summary setting for a user and then calls either the cb or fb functions
   *
   * @param   {object} alarmFrequencyObject
   * @param   {function} cb
   * @param   {function} fb
   * @return  {null}
   */
  @action changeAlarmSummaryFrequency = (alarmFrequencyObject, cb, fb) => {
    this.loading = true;
    this.moduleService
      ?.changeAlarmSummaryFrequency(alarmFrequencyObject)
      .then(() => {
        cb && cb();
      })
      .catch((errors) => {
        fb && fb(errors);
      })
      .finally(() => {
        runInAction(() => {
          this.loading = false;
        })
      })
  }

}

export default MyStore;
