import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import { List, Icon, Button, Tooltip } from "antd";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";
import Helper from "../../../../utils/helper";
import {
  DATE_FORMAT,
  NOTIF_STATUS,
  NOTIFICATION_ACTION_TYPE,
} from "utils/constants";

import "./style.scss";

const { UNFLAG, FLAG, UNREAD, READ } = NOTIF_STATUS;

@inject("rootStore")
@observer
class NotificationListPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasMore: true,
      clickAble: true,
    };
  }

  //change logic to goto notification detail for every notification
  defaultActionObjectForItem = {
    type: NOTIFICATION_ACTION_TYPE.OPEN_SIDE_PANEL,
    target: "NOTI_DETAIL",
    data: {
      title: "alm-noti.show_form",
      editable: false,
    },
  };
  handleInfiniteOnLoad = () => {
    const {
      rootStore: {
        unreadNotificationStore: { loadMoreData, listData, filterData },
      },
    } = this.props;
    const totalUnread = filterData?.pagination?.total;
    if (listData.length >= totalUnread) {
      this.setState({
        hasMore: false,
      });
      return;
    }
    loadMoreData(() => {});
  };

  componentDidMount() {
    const {
      rootStore: {
        unreadNotificationStore: { getData, listData, filterData },
      },
    } = this.props;
    getData(() => {
      const totalUnread = filterData?.pagination?.total;
      if (listData.length >= totalUnread) {
        this.setState({
          hasMore: false,
        });
      }
    });
  }

  handleReadAll = () => {
    const {
      rootStore: {
        commonStore: { setVisibleNotificaionPanel },
        unreadNotificationStore: { readAll },
      },
    } = this.props;
    readAll().then(() => {
      setVisibleNotificaionPanel(false);
    });
  };

  handleViewMore = () => {
    const {
      history,
      rootStore: {
        commonStore: { setVisibleNotificaionPanel },
      },
    } = this.props;
    history.push("/notifications");
    setVisibleNotificaionPanel(false);
  };

  handleNotifRead = (ids, status) => {
    const { clickAble } = this.state;
    const {
      rootStore: {
        unreadNotificationStore: { updateNotifRead },
      },
    } = this.props;
    if (clickAble) {
      this.setState({ clickAble: false });
      updateNotifRead(ids, status, () => {
        this.setState({ clickAble: true });
      });
    }
  };

  render() {
    const {
      t,
      history,
      rootStore: {
        myStore,
        unreadNotificationStore: { listData, updateNotifFlag },
        commonStore,
      },
    } = this.props;
    const { hasMore } = this.state;
    const currentLanguage = myStore?.currentProfile?.language;

    return (
      <div className="wrap-notification-list-panel">
        <InfiniteScroll
          dataLength={listData.length}
          hasMore={hasMore}
          loader={
            <div className="loading-icon">
              <Icon type="loading" />
            </div>
          }
          next={this.handleInfiniteOnLoad}
          height={430}
          className={"styled-scroll"}
        >
          <List
            itemLayout="horizontal"
            dataSource={listData}
            className="wrap-list-items"
            id={"list-notif-scroll"}
            renderItem={(item) => {
              let name = Helper.getTranslateText(item.name, currentLanguage);
              let description = Helper.getTranslateText(
                item.description,
                currentLanguage
              );

              return (
                <List.Item
                  className={item?.read_status == READ && "fade-out"}
                  actions={[
                    item?.flag_status == FLAG ? (
                      <Tooltip
                        key="click_to_unflag"
                        placement="top"
                        title={t("alm-noti.click_to_unflag")}
                      >
                        <Icon
                          type="flag"
                          theme="filled"
                          className="filled-icon"
                          onClick={() => updateNotifFlag([item.id], UNFLAG)}
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip
                        key="click_to_flag"
                        placement="top"
                        title={t("alm-noti.click_to_flag")}
                      >
                        <Icon
                          type="flag"
                          onClick={() => updateNotifFlag([item.id], FLAG)}
                        />
                      </Tooltip>
                    ),
                    <Tooltip
                      key={"click_to_read"}
                      placement="top"
                      title={t("alm-noti.click_to_read")}
                    >
                      <Icon
                        type="read"
                        onClick={() => {
                          item?.read_status == UNREAD &&
                            this.handleNotifRead([item.id], READ);
                        }}
                      />
                    </Tooltip>,
                  ]}
                >
                  <List.Item.Meta
                    title={
                      <a
                        onClick={() => {
                          commonStore.setVisibleNotificaionPanel(false);
                          let actionObj = {
                            ...this.defaultActionObjectForItem,
                          };
                          actionObj.data.id = item.id;
                          Helper.handleLinkAction(
                            actionObj,
                            history,
                            this.props.rootStore
                          );
                        }}
                      >
                        {name}
                      </a>
                    }
                    description={
                      <div>
                        <div className="notif-descript">{description}</div>
                        <div className="notif-time">
                          {moment(item.created_at).format(DATE_FORMAT.full)}
                        </div>
                      </div>
                    }
                  />
                </List.Item>
              );
            }}
          />
        </InfiniteScroll>
        <div className="wrap-notif-footer">
          <Button type="link" onClick={this.handleReadAll}>
            {t("alm-noti.mark_all")}
          </Button>
          <Button type="link" onClick={this.handleViewMore}>
            {t("alm-noti.view_more")}
          </Button>
        </div>
      </div>
    );
  }
}

export default withRouter(withTranslation()(NotificationListPanel));
