import { Icon, Select, Table } from "antd";
import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { CHART_COLOR_SCHEMA, SELECT_TREND, TIME_TO_RUN_REPORT, TRENDING } from "utils/constants";
import "./style.scss";
const { Option } = Select;

@inject("rootStore")
@observer
class PopupTrend extends Component {
  onChange = (value, record, action) => {
    const {
      rootStore: {
        reportTemplateStore: {
          formData: {
            schedule: { metric_settings },
          },
          setFormField,
        },
      },
    } = this.props;
    let dataChange = metric_settings.map((e) => {
      if (e?.key_name === record?.key_name) {
        if (action === TRENDING.GOOD.key) {
          return {
            key_name: e.key_name,
            good_condition: value,
            bad_condition:
              value === SELECT_TREND.decrease
                ? SELECT_TREND.increase
                : value === SELECT_TREND.increase
                  ? SELECT_TREND.decrease
                  : SELECT_TREND.none,
            enabled: e.enabled
          };
        } else {
          return {
            key_name: e.key_name,
            bad_condition: value,
            good_condition:
              value === SELECT_TREND.decrease
                ? SELECT_TREND.increase
                : value === SELECT_TREND.increase
                  ? SELECT_TREND.decrease
                  : SELECT_TREND.none,
            enabled: e.enabled
          };
        }
      }
      return e;
    });
    setFormField("schedule.metric_settings", dataChange);
  };

  renderTableRows = () => {
    const { t, disabled } = this.props;
    return [
      {
        title: "Key Name",
        dataIndex: "label",
        key: "key_name",
      },
      {
        title: "Action Good",
        key: "good_condition",
        render: (text, record) => (
          <div className="flex w-full justify-start">
            <div className="flex items-center mr-8">
              <Icon
                className="mr-4"
                size="large"
                type="caret-up"
                style={{ color: CHART_COLOR_SCHEMA.GREEN, fontSize: 20 }}
              />
              <p className="mb-0">{t("GOOD")}</p>
            </div>
            <div className="w-120">
              <Select
                disabled={disabled}
                value={[text.good_condition]}
                onChange={(value) =>
                  this.onChange(value, record, TRENDING.GOOD.key)
                }
              >
                <Option value="DECREASE">
                  {t("result_popup_trend.decrease")}
                </Option>
                <Option value="INCREASE">
                  {t("result_popup_trend.increase")}
                </Option>
                <Option value="NONE">{t("result_popup_trend.none")}</Option>
              </Select>
            </div>
          </div>
        ),
      },
      {
        title: "Action Bad",
        key: "bad_condition",
        render: (text, record) => (
          <div className="flex w-full justify-start">
            <div className="flex items-center mr-8">
              <Icon
                className="mr-4"
                type="caret-down"
                style={{ color: CHART_COLOR_SCHEMA.RED, fontSize: 20 }}
              />
              <p className="mb-0">{t("BAD")}</p>
            </div>
            <div className="w-120">
              <Select
                disabled={disabled}
                value={[text.bad_condition]}
                onChange={(value) =>
                  this.onChange(value, record, TRENDING.BAD.key)
                }
              >
                <Option value="DECREASE">
                  {t("result_popup_trend.decrease")}
                </Option>
                <Option value="INCREASE">
                  {t("result_popup_trend.increase")}
                </Option>
                <Option value="NONE">{t("result_popup_trend.none")}</Option>
              </Select>
            </div>
          </div>
        ),
      },
    ];
  };

  render() {
    const {
      t,
      rootStore: {
        reportTemplateStore: {
          formData: {
            schedule: { metric_settings, recordSelected, scope },
          },
          setFormField,
          setNewRecordSelectScheduleTab
        },
      },
    } = this.props;
    let columns = this.renderTableRows();
    let data = metric_settings.map((e) => {
      return {
        key_name: e.key_name,
        label: t("result_table." + e.key_name),
        good_condition: e.good_condition,
        bad_condition: e.bad_condition,
        enabled: e.enabled,
      };
    });
    const onSelectChange = (newSelectedRowKeys, mockData) => {
      setNewRecordSelectScheduleTab(newSelectedRowKeys)
      const dataSelected = metric_settings.map((item) => {
        const itemChange = mockData.find((e) => e.key_name === item.key_name)
        return {
          ...item,
          enabled: itemChange ? true : false
        }
      })
      setFormField("schedule.metric_settings", dataSelected);
    };
    let rowSelection = {
      selectedRowKeys: recordSelected,
      onChange: onSelectChange,
    };

    return (
      <div>
        <div className="infor-trend-table">
          <p fontSize="18px"><b>{t("report_template.define_metric_setting")}</b></p>
          {scope !== TIME_TO_RUN_REPORT.EVERY_DAY && <div className='flex items-center gap-1'>
            <Icon type="info-circle" />  
            <span>{t("report_template.define_metric_setting_hepler")}</span>
          </div>}
        </div>
        <Table
          rowSelection={rowSelection}
          className="spm-table"
          pagination={false}
          columns={columns}
          dataSource={data}
          rowKey={(record) => record.key_name}
        ></Table>
      </div >
    );
  }
}

export default withTranslation()(PopupTrend);
