import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import "./style.scss";
import {
  DatePicker,
  Col,
  Row,
  Select,
  Typography,
  TimePicker,
  Button,
  Form,
} from "antd";
import {
  DATE_FORMAT, TIME_PICKER_FORMAT,
} from "utils/constants";
import { inject, observer } from "mobx-react";
import ModalAnalysis from "components/ModalAnalysis";
import { withRouter } from "react-router";
import * as moment from "moment";
const { Option } = Select;

@inject("rootStore")
@observer

class CustomAnalysisFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      idSelectedItem: null,
      isEdit: false,
      tableData: []
    };
  }

	closeModal = () => {
		this.setState({
			openModal: false,
			isEdit: false,
			tableData: []
		})
	}

	resetFieldsDeleteCustomAnalysis = () => {
		const {
			form: { resetFields }
		} = this.props;
		resetFields()
	}

  handleAdd = (value) => {
    if (!this.state.allTags?.find((e) => e === value))
      this.setState({
        allTags: [...this.state.allTags, value],
        inputVisible: false,
        inputValue: "",
      });
    else
      this.setState({
        inputVisible: false,
      });
  };

  handleEditCustomAnalysis = () => {
    const {
      rootStore: { customAnalysisStore: {
        listAllEvent,
        selectedAnalysis,
        setDownloadFormData,
      },
      }

    } = this.props;
    if (selectedAnalysis) {
      const initTable = selectedAnalysis.events.map(filter => {
        for (let key of Object.keys(listAllEvent)) {
          for (let i = 0; i < listAllEvent[key].event_list.length; i += 1) {
            if (listAllEvent[key].event_list[i].event_code === filter.event_code) {
              return { ...listAllEvent[key].event_list[i], params: filter.params, event_name: listAllEvent[key].event_list[i].event_label };
            }
          }
        }
      })
      setDownloadFormData({
        formDownload: initTable,
      });
      this.setState({
        tableData: initTable,
        isEdit: true,
        openModal: true
      })
    }
  }

  componentDidMount() {
    const {
		rootStore: {
			customAnalysisStore: {
				getAllFilterData,
			},
		},
	} = this.props;
    const { t } = this.props;
    document.title = t("intersection.page-title");
    getAllFilterData();
  }

  checkToTime = (time, value, callback) => {
    const {
      t,
      rootStore: { customAnalysisStore },
    } = this.props;
    if (
      moment
        .duration(
          customAnalysisStore.filterData.time_start -
          customAnalysisStore.filterData.time_end
        )
        .as("minutes") < 1 && moment
          .duration(
            customAnalysisStore.filterData.time_start -
            customAnalysisStore.filterData.time_end
          )
          .as("minutes") != 0
    ) {

      return callback();
    }
    else {
      callback(t("analysis.from_time_greater_to_time"));
    }
  }

  checkVaildTime = (time, value, callback) => {
    const {
      t,
      rootStore: { customAnalysisStore },
    } = this.props;
    if (
      customAnalysisStore.filterData.time_start.isBefore(
        customAnalysisStore.filterData.time_end
      )
    ) {
      return callback();
    } else {
      callback(t("analysis.from_time_greater_to_time"));
    }
  };

  handleRun = (e) => {
    e.preventDefault();
    const {
		rootStore: {
			customAnalysisStore: {
				runCustomAnalysisStore,
				setNameIntersectionData,
				listIntersectionByAgency,
				filterData,
				getCustomAnalysisById,
			},
		},
		form: { validateFields },
		} = this.props;
    validateFields((err, values) => {
      if (!err) {
        const nameIntersection = (listIntersectionByAgency?.find((e) => e.id === values.intersection)).name
        const date = filterData.date.format("YYYY-MM-DD");
        const fromTime = filterData.time_start.format("HH:mm:ss")
        const toTime = filterData.time_end.format("HH:mm:ss")
        const newValue = {
          id: values.id,
          int_uuid: values.intersection,
          from_time: `${date}T${fromTime}`,
          to_time: `${date}T${toTime}`,
          agency_uuid: values.agency
        }
        runCustomAnalysisStore(newValue)
        setNameIntersectionData(nameIntersection)
        getCustomAnalysisById(values.id)
      }
    })
  }
  render() {
    const {
			t,
			rootStore: {
				customAnalysisStore,
			},
			form: { getFieldDecorator, validateFields, resetFields },
		} = this.props;
    const {
			rootStore: {
				customAnalysisStore: {
					listCustomAnalysis,
					getCustomAnalysisById,
					listAgencies,
					filterData,
					selectedAnalysis,
					getAllIntersectionsByAgencyStore,
					listIntersectionByAgency,
					handleFilterDataChange,
					getListAllCustomAnalysisStore,
					resetForm
				},
				commonStore: { isSuperAdmin, isFromStatic },
			},
    } = this.props;
    return (
      <div>
			<Form id='custom-analysis-form' onSubmit={this.handleRun}>
				<Row gutter={5}>
					<Col xl={19} md={24}>
						<Row gutter={[18, 8]}>
							{isSuperAdmin() && !isFromStatic && listAgencies.length > 0 && (
								<Col xl={4}>
									<Typography>{t('analysis.agencies')}</Typography>
									<Form.Item>
										{getFieldDecorator('agency', {
											initialValue: validateFields.id ?? listAgencies.find(
												(e) => e.id === filterData.agency_id
											)?.id,
										})(
											<Select
												onChange={(e) => {
													handleFilterDataChange('agency_id', e)
													getAllIntersectionsByAgencyStore(e);
													getListAllCustomAnalysisStore(e);
													resetFields();
												}}
												showSearch
												optionFilterProp='filter'
												filterOption={(input, option) => {
													return (
														option?.props?.filter
															?.toLowerCase()
															?.indexOf(input?.toLowerCase()) >= 0
													);
												}}
											>
												{listAgencies?.map((e, id) => (
													<Option filter={e.name} key={id} value={e.id}>
														{e.name}
													</Option>
												))}
											</Select>
										)}
									</Form.Item>
								</Col>
							)}
							<Col xl={4}>
								<Typography>{t('custom_analysis')}</Typography>
								<Form.Item>
									{getFieldDecorator('id', {
										initialValue: listCustomAnalysis[0]?.id,
									})(
										<Select
											onChange={(e) => getCustomAnalysisById(e)}
											showSearch
											optionFilterProp='filter'
											filterOption={(input, option) => {
												return (
													option?.props?.filter
														?.toLowerCase()
														?.indexOf(input?.toLowerCase()) >= 0
												);
											}}
										>
											{listCustomAnalysis?.map((e, id) => (
												<Option filter={e.name} key={id} value={e.id}>
													{e.name}
												</Option>
											))}
										</Select>
									)}
								</Form.Item>
							</Col>
							<Col xl={4}>
								<Typography>{t('analysis.intersection')}</Typography>
								<Form.Item>
									{getFieldDecorator('intersection', {
										initialValue: listIntersectionByAgency[0]?.id,
									})(
										<Select
											showSearch
											optionFilterProp='filter'
											filterOption={(input, option) => {
												return (
													option?.props?.filter
														?.toLowerCase()
														?.indexOf(input?.toLowerCase()) >= 0
												);
											}}
										>
											{listIntersectionByAgency?.map((e, id) => (
												<Option filter={e.name} key={id} value={e.id}>
													{e.name}
												</Option>
											))}
										</Select>
									)}
								</Form.Item>
							</Col>
							<Col xl={4}>
								<Typography>{t('date')}</Typography>
								<Form.Item>
									{getFieldDecorator('date', {
										rules: [
											{
												required: true,
												message: t('field_required', {
													field: t('date'),
												}),
											},
										],
										initialValue: customAnalysisStore.filterData.date,
									})(
										<DatePicker
											mode={'date'}
											allowClear={false}
											format={DATE_FORMAT.day_only_backend}
											onChange={(value) => {
												handleFilterDataChange('date', value);

												this.props.form.resetFields(['date']);
												this.props.form.validateFields(['date']);
											}}
											showToday={true}
										/>
									)}
								</Form.Item>
							</Col>
							<Col xl={3}>
								<Typography>{t('time_range')}</Typography>
								<Row style={{ display: 'flex' }}>
									<Form.Item>
										{getFieldDecorator('time_start', {
											rules: [
												{
													required: true,
													message: t('field_required', {
														field: t('from_time'),
													}),
												},
												{ validator: this.checkVaildTime },
											],
											initialValue: customAnalysisStore.filterData.time_start ?? null,
										})(
											<TimePicker
												allowClear={false}
												onChange={(time, timeString) => {
													handleFilterDataChange(
														'time_start',
														timeString ? moment(timeString, TIME_PICKER_FORMAT) : null
													);

													this.props.form.resetFields(['time_end']);
													this.props.form.validateFields(['time_end']);
												}}
												placeholder='Time start'
												format={'HH:mm'}
												size='large'
											/>
										)}
									</Form.Item>
									<Form.Item>
										{getFieldDecorator('time_end', {
											rules: [{ validator: this.checkToTime }],
											validateTrigger: ['onChange', 'onLoad'],
											initialValue: customAnalysisStore.filterData.time_end,
										})(
											<TimePicker
												allowClear={false}
												placeholder='Time end'
												format={'HH:mm'}
												onChange={(time, timeString) => {
													handleFilterDataChange(
														'time_end',
														timeString ? moment(timeString, TIME_PICKER_FORMAT) : null
													);

													this.props.form.resetFields(['time_start']);
													this.props.form.validateFields(['time_start']);
												}}
												size='large'
											/>
										)}
									</Form.Item>
								</Row>
							</Col>
						</Row>
					</Col>
					<Col className='group-btn' xl={4} md={24}>
						<Form.Item>
							<Button
								onClick={() => {
									this.setState({ openModal: true });
									resetForm();
								}}
							>
								{t('add_template')}
							</Button>
							<Button
								disabled={selectedAnalysis ? false : true}
								onClick={() => this.handleEditCustomAnalysis()}
							>
								{t('edit')}
							</Button>
							<Button
								form='custom-analysis-form'
								htmlType='submit'
								type='primary'
							>
								{t('run')}
							</Button>
						</Form.Item>
					</Col>
				</Row>
			</Form>
			<ModalAnalysis
				tableData={this.state.tableData}
				isEdit={this.state.isEdit}
				idSelectedItem={this.state.idSelectedItem}
				customAnalysisStore={customAnalysisStore}
				openModal={this.state.openModal}
				closeModal={this.closeModal}
				resetFieldsDeleteCustomAnalysis={this.resetFieldsDeleteCustomAnalysis}
			/>
		</div>
	);
  }
}
const CustomAnalysisFilterForm = Form.create({ name: 'custom_analysis' })(CustomAnalysisFilter)
export default withRouter(withTranslation()(CustomAnalysisFilterForm));
