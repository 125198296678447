import * as d3 from "d3";
import React, { Component, createRef } from "react";
import "./style.scss";

const margin = { top: 0, right: 5, bottom: 0, left: 5 };

class VolumeCountsChart extends Component {
  constructor(props) {
    super(props);
    //current SVG object
    this.ref = createRef();
  }
  /**
   * componentDidMount
   * inital mini map control
   * we draw brush and axisX on here
   */
  componentDidMount() {
    const [width, height] = this.getContext();
    this.drawChart(width, height);
  }

  getContext = () => {
    const svg = d3.select(this.ref?.current);

    if (svg) {
      return [
        parseFloat(window.getComputedStyle(svg.node().parentNode)?.width),
        parseFloat(window.getComputedStyle(svg.node())?.height),
      ];
    }

    return [];
  };

  drawChart = (width, height) => {
    const { volumeCounts } = this.props;
    const svg = d3.select(this.ref?.current);
    const data = volumeCounts.map((el) => ({
      total: el.detector_hit,
      fromTime: d3.isoParse(el.from_time),
      toTime: d3.isoParse(el.to_time),
      milestone: new Date(
        (d3.isoParse(el.from_time).getTime() +
          d3.isoParse(el.to_time).getTime()) /
          2
      ),
    }));
    const values = volumeCounts.map((el) => el.detector_hit);

    const x = d3
      .scaleTime()
      .domain([data[0].milestone, data[data.length - 1].milestone])
      .range([margin.left, width - margin.right]);

    const y = d3
      .scaleLinear()
      .domain([0, d3.max(values)])
      .nice()
      .rangeRound([height - margin.bottom, margin.top]);

    const area = d3
      .area()
      .defined((d) => !isNaN(d))
      .x((d, i) => x(data[i].milestone))
      .y0(y(0))
      .y1(y);

    const xAxis = (g) =>
      g
        .attr("transform", `translate(0,${height - margin.bottom})`)
        .call(d3.axisBottom(x).tickSizeOuter(0))
        .call((g) => g.selectAll(".tick line").attr("visibility", "hidden"));

    const yAxis = (g) =>
      g
        .attr("transform", `translate(${margin.left},0)`)
        .call(d3.axisLeft(y))
        .call((g) => g.select(".domain").remove())
        .call((g) => g.selectAll(".tick line").attr("visibility", "hidden"));

    svg.attr("viewBox", [0, 0, width, height]);

    svg.append("g").call(xAxis);

    svg.append("g").call(yAxis);

    svg.append("path").attr("fill", "steelblue").attr("d", area(values));

    // tooltip
    const tooltip = svg.append("g").attr("opacity", 0);

    tooltip
      .append("rect")
      .attr("x", width / 2 - 50)
      .attr("y", 6)
      .attr("width", 100)
      .attr("height", 18)
      .style("background", "#000")
      .attr("class", "tooltip-box")
      .attr("stroke", "var(--text)")
      .attr("fill", "#ffffff70");

    tooltip
      .append("text")
      .text("111")
      .attr("text-anchor", "middle")
      .attr("class", "tooltip-value")
      .attr("width", 100)
      .attr("height", 18)
      .attr("dominant-baseline", "middle")
      .attr("x", width / 2)
      .attr("y", 16)
      .style("font-size", "14px")
      .style("fill", "#333");

    // highlight
    const highlight = svg
      .append("g")
      .selectAll("g")
      .data(data)
      .enter()
      .append("g")
      .on("mouseover", function (d) {
        d3.select(this).select("rect").style("fill", "#aeaeae30");
        d3.select(this).select("circle").style("fill", "red");
        tooltip.attr("opacity", 1);
        tooltip
          .select(".tooltip-value")
          .text(
            `${d.fromTime
              .getUTCHours()
              .toString()
              .padStart(2, "0")} - ${d.toTime
              .getUTCHours()
              .toString()
              .padStart(2, "0")}: ${d.total}`
          );
      })
      .on("mouseleave", function () {
        d3.select(this).select("rect").style("fill", "transparent");
        d3.select(this).select("circle").style("fill", "transparent");
        tooltip.attr("opacity", 0);
      });
    highlight
      .append("rect")
      .attr("x", function (d) {
        return x(d.milestone) - 3;
      })
      .attr("y", 0)
      .attr("width", 6)
      .attr("height", height - margin.bottom)
      .style("fill", "transparent");
    highlight
      .append("circle")
      .attr("cx", function (d) {
        return x(d.milestone);
      })
      .attr("cy", function (d) {
        return y(d.total);
      })
      .attr("r", 3)
      .style("fill", "transparent");
  };

  render() {
    return (
      <div className="volume-count-chart-wrap">
        <div className="volume-count-chart-container">
          <svg ref={this.ref} />
        </div>
      </div>
    );
  }
}

export default VolumeCountsChart;
