import { Spin } from "antd";
import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import {
  TIMEOUT_RESIZE,
  TRAFFICE_PROFILE_TYPE,
  TRAFFIC_PROFILE_CHART_MODE,
} from "utils/constants";
import DayOfWeekChartList from "./DayOfWeekChartList";
import HeaderTrafficProfile from "./HeaderTrafficProfile";
import WeekdayWeekendChartList from "./WeekdayWeekendChartList";
import DataTableTrafficProfile from "./DataTable/DataTableTrafficProfile";

@inject("rootStore")
@observer
class TrafficProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableChartHeight: 300,
      printMode: false,
      resizing: false,
      chartMode: TRAFFIC_PROFILE_CHART_MODE.MAX_MIN,
      visiblePrint: false,
      hiddenLegend: {
        MAX_MIN: [],
        DETAIL: [],
      },
    };
    this.headerOffsetTable = React.createRef();
    this.offsetTable = React.createRef();
  }
  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }
  handleResize = () => {
    if (
      this.offsetTable.current &&
      this.headerOffsetTable?.current?._dom?.current
    ) {
      this.setState({ resizing: true });
      if (this.timeoutResize) clearTimeout(this.timeoutResize);
      this.timeoutResize = setTimeout(() => {
        this.setState({
          resizing: false,
          tableChartHeight:
            this.offsetTable.current?.offsetHeight -
            this.headerOffsetTable?.current?._dom?.current?.offsetHeight -
            8,
        });
      }, TIMEOUT_RESIZE);
    }
  };
  onChangeLegend = (value) => {
    this.setState({
      hiddenLegend: value,
    });
  };
  setPrintModeVisible = (value) => {
    this.setState({
      visiblePrint: value,
    });
  };
  render() {
    const {
      t,
      rootStore: {
        optimizerStore: { selectedItem, dataTable },
      },
      history,
    } = this.props;

    return (
      <div className="h-full" ref={this.offsetTable}>
        <HeaderTrafficProfile
          history={history}
          ref={this.headerOffsetTable}
          t={t}
          selectedItem={selectedItem}
          chartMode={this.state.chartMode}
          setChartMode={(value) =>
            this.setState({
              chartMode: value,
            })
          }
          setPrintModeVisible={this.setPrintModeVisible}
        />

        <div
          className="wrap-table-chart "
          style={{ height: this.state.tableChartHeight }}>
          {this.state.chartMode === TRAFFIC_PROFILE_CHART_MODE.DATA_TABLE ? (
            <DataTableTrafficProfile dataSource={dataTable} />
          ) : this.state.resizing ? (
            <div className="w-full h-full flex justify-center">
              <Spin size="large" className="flex items-center" />
            </div>
          ) : selectedItem.general_data.cluster_type ===
            TRAFFICE_PROFILE_TYPE.WEEKEND_WEEKDAY ? (
            <WeekdayWeekendChartList
              from_time={selectedItem.general_data.start_time}
              to_time={selectedItem.general_data.end_time}
              hiddenLegend={this.state.hiddenLegend}
              onChangeLegend={this.onChangeLegend}
              chartMode={this.state.chartMode}
              height={this.state.tableChartHeight}
              setPrintModeVisible={this.setPrintModeVisible}
              visiblePrint={this.state.visiblePrint}
            />
          ) : (
            <DayOfWeekChartList
              from_time={selectedItem.general_data.start_time}
              to_time={selectedItem.general_data.end_time}
              hiddenLegend={this.state.hiddenLegend}
              onChangeLegend={this.onChangeLegend}
              chartMode={this.state.chartMode}
              height={this.state.tableChartHeight}
              setPrintModeVisible={this.setPrintModeVisible}
              visiblePrint={this.state.visiblePrint}
            />
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(withTranslation()(TrafficProfile));
