import React, { Component } from "react";
import { Form, Input, Button, Alert, Spin } from "antd";
import { withRouter } from "react-router";
import { inject, observer } from "mobx-react";
import { ReactComponent as Logo } from "../../svgs/logo.svg";
import { withTranslation } from "react-i18next";
import Helper from "../../utils/helper";

import { NOTIFICATION_ACTION_TYPE } from "utils/constants";

@inject("rootStore")
@observer
class ExportDownload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      actionObject: null,
    };
    this.export_name = "";
  }
  //set default downloadResource from params
  componentDidMount() {
    this.export_name = this.props.match.params.id;
  }
  /**
   * trimString
   * @param {String} text
   * @returns {String} value with trim
   */
  trimString = (value) => {
    return value?.trim();
  };

  /**
   * handleSubmit
   * validation input then send back to store
   *
   */
  handleSubmit = (e) => {
    e.preventDefault();
    const {
      rootStore: { downloadStore },
      history,
      t,
    } = this.props;

    this.props.form.validateFields((err, values) => {
      if (!err) {
        values.export_name = this.export_name;
        downloadStore.handleDownloadAccess(values).then((download_link) => {
          let actionObject = {
            type: NOTIFICATION_ACTION_TYPE.OPEN_LINK,
            data: download_link,
          };
          this.setState({ actionObject: actionObject });
          Helper.handleLinkAction(
            actionObject,
            history,
            this.props.rootStore,
            downloadStore,
            t
          );
        });
      }
    });
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      t,
      rootStore: {
        downloadStore: { loading, errorList, clearErrorList },
      },
    } = this.props;
    return (
      <div className="pubic-form-layout fullscreen">
        <div className="wrap-login-comp-bg">
          <div className="wrap-login-error-comp">
            {errorList && (
              <Alert
                message={errorList.message}
                type="error"
                closable
                showIcon
                onClose={clearErrorList}
                className="spm-error-comp"
              />
            )}
          </div>

          <div className="wrap-login-comp">
            <Spin size="large" spinning={loading}>
              <Form
                className="login-form"
                autoComplete="off"
                onSubmit={this.handleSubmit}
              >
                <div className="logo-login">
                  <Logo className="logo-img"></Logo>
                </div>
                <Form.Item
                  required={true}
                  label={t("access_download.confirm_password_download")}
                >
                  {getFieldDecorator("password", {
                    normalize: this.trimString,
                    rules: [
                      {
                        required: true,
                        message: t("access_download.confirm_password_download"),
                      },
                    ],
                  })(
                    <Input.Password
                      maxLength={50}
                      placeholder={t("profile.password")}
                    />
                  )}
                </Form.Item>
                <Form.Item>
                  <Button
                    icon="download"
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                    disabled={this.state.download_link}
                    loading={loading}
                  >
                    {t("access_download.download")}
                  </Button>
                </Form.Item>
                <Form.Item>
                  {this.state?.actionObject && (
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        Helper.handleLinkAction(
                          this.state?.actionObject,
                          history,
                          this.props?.rootStore,
                          this.props?.rootStore?.downloadStore,
                          t
                        );
                      }}
                    >
                      {t("access_download.link_not_automatic")}
                    </a>
                  )}
                </Form.Item>
              </Form>
            </Spin>
          </div>
        </div>
      </div>
    );
  }
}
const DownloadVerificationForm = Form.create({ name: "access_download" })(
  ExportDownload
);
export default withRouter(withTranslation()(DownloadVerificationForm));
