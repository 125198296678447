import * as d3 from "d3";
import { Component, createRef } from "react";
import React from "react";
import { CHART_COLOR_SCHEMA, DATE_FORMAT } from "utils/constants";
import moment from "moment";
const COLOR_LIGHT = {
  YELLOW: CHART_COLOR_SCHEMA.YELLOW,
  RED: CHART_COLOR_SCHEMA.RED,
  GREEN: CHART_COLOR_SCHEMA.LOWER_GREEN,
  COORD_PHASE: CHART_COLOR_SCHEMA.GREEN_SIEMENS,
};
class BaseRightContent extends Component {
  constructor(props) {
    super(props);
    this.ctx = null;
    this.chartRef = createRef();
    this.brushRef = createRef();
    this.axisScroll = createRef();
    this.axisAreaRef = createRef();
    this.xScale = null;
    this.yScale = null;
    this.rightConfig = {
      width: 400,
      paddingX: 64,
      paddingY: 32,
      height: 200,
    };
    this.currentBrushRange = [0, 0];
  }

  drawXaxis = () => {
    const [startX, endX] = [0, this.rightConfig.width];
    // xScale.tickSize(rightConfig.width);
    let tickSize = 4,
      xTicks = this.xScale.ticks(10); // You may choose tick counts. ex: this.xScale.ticks(20)
    this.axisCtx.lineWidth = 0.5;
    this.axisCtx.strokeStyle = "black";
    this.axisCtx.beginPath();
    xTicks.forEach((d) => {
      this.axisCtx.moveTo(this.xScale(d), 15);
      this.axisCtx.lineTo(this.xScale(d), 8 + tickSize);
    });
    this.axisCtx.stroke();
    this.axisCtx.beginPath();
    this.axisCtx.moveTo(startX, 15 + tickSize);
    this.axisCtx.lineTo(startX, 15);
    this.axisCtx.lineTo(endX, 15);
    this.axisCtx.lineTo(endX, 15 + tickSize);
    this.axisCtx.stroke();

    this.axisCtx.textAlign = "center";
    this.axisCtx.textBaseline = "top";
    this.axisCtx.fillStyle = "black";
    // this.axisCtx.rotate(-Math.PI / 2);
    xTicks.forEach((d, id) => {
      this.axisCtx.beginPath();
      this.axisCtx.fillText(
        moment(d).format(DATE_FORMAT.time_only_full),
        id ? this.xScale(d) : this.xScale(d) + 20,
        14 - 3 * tickSize
      );

      this.axisCtx.closePath();
    });

    this.axisCtx.closePath();
  };
  drawLineData = () => {
    const { dataSourceIntersection, showUpstreamBand, showDownstreamBand } =
      this.props;

    this.props;
    this.ctx.fillStyle = "black";
    dataSourceIntersection.forEach((dataSourceLineIntersection) => {
      const dataSourceLineRing1 = dataSourceLineIntersection.ring1.lights;
      const dataSourceLineRing2 = dataSourceLineIntersection.ring2.lights;
      if (
        (dataSourceLineIntersection.upstream_phase ===
          dataSourceLineIntersection.ring2.coord_phase &&
          showUpstreamBand) ||
        (dataSourceLineIntersection.downstream_phase ===
          dataSourceLineIntersection.ring2.coord_phase &&
          showDownstreamBand)
      )
        dataSourceLineRing2.forEach((line) => {
          this.drawTimeSpaceLine(
            line.time_space,

            dataSourceLineIntersection.upstream_phase ===
              dataSourceLineIntersection.ring2.coord_phase
              ? "#c0c0c080"
              : "#acd6ff80",
            56,
            line
          );
        });

      if (
        (dataSourceLineIntersection.upstream_phase ===
          dataSourceLineIntersection.ring1.coord_phase &&
          showUpstreamBand) ||
        (dataSourceLineIntersection.downstream_phase ===
          dataSourceLineIntersection.ring1.coord_phase &&
          showDownstreamBand)
      )
        dataSourceLineRing1.forEach((line) => {
          this.drawTimeSpaceLine(
            line.time_space,
            dataSourceLineIntersection.downstream_phase ===
              dataSourceLineIntersection.ring1.coord_phase
              ? "#acd6ff80"
              : "#c0c0c080",
            36,
            line
          );
        });
      this.drawOffsetTimeLine(dataSourceLineIntersection);
    });
    dataSourceIntersection.forEach((dataSourceLineIntersection) => {
      const dataSourceLineRing1 = dataSourceLineIntersection.ring1.lights;
      const dataSourceLineRing2 = dataSourceLineIntersection.ring2.lights;

      const lineRing1Ctx = d3
        .line()
        .x((d) => this.xScale(d.x))
        .y((d) => this.yScale(d.y) + 32)
        .context(this.ctx);
      const lineRing2Ctx = d3
        .line()
        .x((d) => this.xScale(d.x))
        .y((d) => this.yScale(d.y) + 56)
        .context(this.ctx);
      //draw time space first

      dataSourceLineRing1.forEach((line) => {
        //draw all line
        const lineCoord = [
          {
            x: line.start_time,
            y: dataSourceLineIntersection.coordY,
          },
          {
            x: line.end_time,
            y: dataSourceLineIntersection.coordY,
          },
        ];

        this.ctx.beginPath();
        lineRing1Ctx(lineCoord);
        this.ctx.lineWidth = 20;
        this.ctx.strokeStyle =
          line.phase === dataSourceLineIntersection.ring1.coord_phase &&
          line.color === "GREEN"
            ? COLOR_LIGHT.COORD_PHASE
            : COLOR_LIGHT[line.color];

        this.ctx.stroke();
        this.ctx.closePath();
        this.ctx.beginPath();
        this.ctx.textAlign = "center";
        this.ctx.textBaseline = "middle";
        this.ctx.fillStyle = "#111111";
        const spaceXscale =
          this.xScale(line.end_time) - this.xScale(line.start_time);
        const space =
          (line.end_time.getTime() - line.start_time.getTime()) / 1000;
        if (spaceXscale >= 40) {
          this.ctx.fillText(
            `P${line.phase} (${space}s)`,
            this.xScale(
              new Date(
                (line.start_time.getTime() + line.end_time.getTime()) / 2
              )
            ),
            this.yScale(lineCoord[0].y) + 32
          );
        }
        this.ctx.closePath();
      });

      dataSourceLineRing2.forEach((line) => {
        //draw all line
        const lineCoord = [
          {
            x: line.start_time,
            y: dataSourceLineIntersection.coordY,
          },
          {
            x: line.end_time,
            y: dataSourceLineIntersection.coordY,
          },
        ];
        this.ctx.beginPath();
        lineRing2Ctx(lineCoord);
        this.ctx.lineWidth = 20;
        this.ctx.strokeStyle =
          line.phase === dataSourceLineIntersection.ring2.coord_phase &&
          line.color === "GREEN"
            ? COLOR_LIGHT.COORD_PHASE
            : COLOR_LIGHT[line.color];
        this.ctx.stroke();
        this.ctx.closePath();
        this.ctx.beginPath();

        this.ctx.textAlign = "center";
        this.ctx.textBaseline = "middle";
        this.ctx.fillStyle = "#111111";
        const spaceXscale =
          this.xScale(line.end_time) - this.xScale(line.start_time);
        const space =
          (line.end_time.getTime() - line.start_time.getTime()) / 1000;
        if (spaceXscale >= 40) {
          this.ctx.fillText(
            `P${line.phase} (${space}s)`,
            this.xScale(
              new Date(
                (line.start_time.getTime() + line.end_time.getTime()) / 2
              )
            ),
            this.yScale(lineCoord[0].y) + 56
          );
        }
        this.ctx.closePath();
      });
    });
  };
  drawOffsetTimeLine = (dataSourceLineIntersection) => {
    const lineCtx = d3
      .line()
      .x((d) => this.xScale(d.x))
      .y((d) => this.yScale(d.y) + 10)
      .context(this.ctx);
    const { start_time, end_time } = this.props;

    this.ctx.beginPath();
    const lineCoord = [
      {
        x: start_time,
        y: dataSourceLineIntersection.coordY,
      },
      {
        x: new Date(
          dataSourceLineIntersection.offset * 1000 + start_time.getTime()
        ),
        y: dataSourceLineIntersection.coordY,
      },
    ];
    this.ctx.strokeStyle = "black";

    this.ctx.beginPath();

    lineCtx(lineCoord);
    this.ctx.lineWidth = 1;

    this.ctx.stroke();
    this.ctx.fillStyle = "#111111";
    this.axisCtx.textAlign = "right";
    this.ctx.direction = "ltr";
    const textString = `Offset Time: ${dataSourceLineIntersection.offset}(s)`,
      textWidth = this.ctx.measureText(textString).width;
    this.ctx.fillText(
      textString,
      this.xScale(
        new Date(
          dataSourceLineIntersection.offset * 1000 + start_time.getTime()
        )
      ) - textWidth,
      this.yScale(dataSourceLineIntersection.coordY) + 6
    );
    this.ctx.closePath();

    //draw circle marker
    let currentTime =
      start_time.getTime() + dataSourceLineIntersection.offset * 1000;
    while (currentTime <= end_time.getTime()) {
      this.ctx.fillStyle = CHART_COLOR_SCHEMA.RED;

      this.ctx.beginPath();
      const dateCurrentTime = new Date(currentTime);

      // Go to the starting coordinate
      this.ctx.moveTo(
        this.xScale(dateCurrentTime) - 8,
        this.yScale(dataSourceLineIntersection.coordY) + 10
      );

      // Draw 2 segments
      this.ctx.lineTo(
        this.xScale(dateCurrentTime) + 6,
        this.yScale(dataSourceLineIntersection.coordY) + 10
      );
      this.ctx.lineTo(
        this.xScale(dateCurrentTime) - 1,
        this.yScale(dataSourceLineIntersection.coordY) + 20
      );

      // Fill the shape

      this.ctx.fill();
      this.ctx.closePath();
      currentTime += dataSourceLineIntersection.cycle * 1000;
    }
  };

  drawTimeSpaceLine = (time_space_line, color, space) => {
    var area = d3
      .area()
      .x0((d) => {
        return this.xScale(d.x_start);
      })
      .x1((d) => {
        return this.xScale(d.x_end);
      })
      .y((d) => {
        return this.yScale(d.y) + space;
      }) //<-- y1
      .context(this.ctx);
    if (time_space_line) {
      this.ctx.beginPath();
      area(time_space_line);
      this.ctx.fillStyle = color;
      this.ctx.fill();

      this.ctx.closePath();
    }
  };
  reDrawContent({ xScale, yScale }) {
    this.xScale = xScale;
    this.yScale = yScale;
    // this.svg?.selectAll("*").remove();
    //reset canvas
    // this.ctx.beginPath();
    this.axisCtx.clearRect(0, 0, this.axisCtx.canvas.width, 16);
    this.ctx.clearRect(0, 0, this.ctx.canvas.width, this.ctx.canvas.height);

    // this.svg.selectAll("*").remove();
    // this.renderBrush();

    this.axisCtx.canvas.width = this.chartRef.current.offsetWidth;
    this.ctx.canvas.width = this.chartRef.current.offsetWidth;
    this.ctx.canvas.height = this.chartRef.current.offsetHeight;
    // const width = this.chartRef.current.offsetWidth;
    // const height = this.chartRef.current.offsetHeight;
    // this.ctx.closePath();

    // this.renderBrush();
    // this.drawContent();
    this.drawXaxis();
    this.drawLineData();
  }
  drawContent = () => {
    this.renderBrush();
  };
  updateBrush() {
    const { start_time, end_time } = this.props;

    const rangeX = end_time.getTime() - start_time.getTime();

    const brushStart =
      ((this.xScale.domain()[0].getTime() - start_time.getTime()) / rangeX) *
      this.axisScroll.current.offsetWidth;
    const brushEnd =
      ((this.xScale.domain()[1].getTime() - start_time.getTime()) / rangeX) *
      this.axisScroll.current.offsetWidth;
    const rootBBox = {
      x: brushStart,
      y: 0,
      width: brushEnd - brushStart,
    };

    this.scrollBar.attr("width", rootBBox.width).attr("x", rootBBox.x);
  }
  componentDidUpdate() {
    this.rightConfig.width = this.chartRef.current.offsetWidth;
    this.rightConfig.height = this.chartRef.current.offsetHeight;

    this.updateBrush();
    this.reDrawContent({
      xScale: this.xScale,
      yScale: this.yScale,
    });
  }

  componentDidMount() {
    const width = this.chartRef.current.offsetWidth;
    const height = this.chartRef.current.offsetHeight;

    this.rightConfig.width = this.chartRef.current.offsetWidth;
    this.rightConfig.height = this.chartRef.current.offsetHeight;
    var base = d3.select(this.chartRef.current);
    var chart = base
      .append("canvas")
      .attr("width", width)
      .attr("height", height);
    this.ctx = chart.node().getContext("2d");
    this.ctx.imageSmoothingQuality = "high";
    this.svg = d3.select(this.brushRef.current);
    var axisComponent = d3.select(this.axisAreaRef.current);
    var axis = axisComponent
      .append("canvas")
      .attr("width", width)
      .attr("height", 16);
    this.axisCtx = axis.node().getContext("2d");
    this.renderBrush();
  }

  renderBrush = () => {
    const { start_time, end_time, handleBrushZoom } = this.props;
    const rangeX = end_time.getTime() - start_time.getTime();
    const rootBBox = {
      x: 0,
      y: 0,
      width: 200,
    };
    const scrollBarHeight = 8;
    this.scrollBar = this.svg
      .append("rect")
      .attr("width", 200)
      .attr("height", scrollBarHeight)
      .attr("rx", scrollBarHeight / 2)
      .attr("fill", "#ccc")
      .attr("ry", scrollBarHeight / 2)
      .attr("x", rootBBox.x)
      .attr("x", rootBBox.y);
    const dragBehaviour = d3.drag().on("drag", () => {
      const startOffset =
        Number(this.scrollBar.attr("x")) + d3.event.dx > 0
          ? Number(this.scrollBar.attr("x")) +
              Number(this.scrollBar.attr("width")) +
              d3.event.dx <
            this.axisScroll.current.offsetWidth
            ? Number(this.scrollBar.attr("x")) + d3.event.dx
            : this.axisScroll.current.offsetWidth -
              Number(this.scrollBar.attr("width"))
          : 0;

      const endOffset =
        Number(this.scrollBar.attr("x")) +
          Number(this.scrollBar.attr("width")) +
          d3.event.dx <
        this.axisScroll.current.offsetWidth
          ? Number(this.scrollBar.attr("x")) +
            Number(this.scrollBar.attr("width")) +
            d3.event.dx
          : this.axisScroll.current.offsetWidth;

      const newXStart =
        start_time.getTime() +
        (rangeX * startOffset) / this.axisScroll.current.offsetWidth;
      const newXEnd =
        start_time.getTime() +
        (rangeX * endOffset) / this.axisScroll.current.offsetWidth;

      this.scrollBar.attr("x", startOffset);
      handleBrushZoom(newXStart, newXEnd);
    });
    this.scrollBar.call(dragBehaviour);
  };

  handleBrushZoom = (xStart, xEnd) => {
    const { start_time, end_time, handleBrushZoom } = this.props;
    if (
      xEnd.getTime() - xStart.getTime() < 60000 ||
      xEnd.getTime() - xStart.getTime() > 1800000
    )
      return;
    const rangeX = end_time.getTime() - start_time.getTime();

    const brushStart =
      ((xStart.getTime() - start_time.getTime()) / rangeX) *
      this.axisScroll.current.offsetWidth;
    const brushEnd =
      ((xEnd.getTime() - start_time.getTime()) / rangeX) *
      this.axisScroll.current.offsetWidth;
    const rootBBox = {
      x: brushStart,
      y: 0,
      width: brushEnd - brushStart,
    };
    handleBrushZoom(xStart, xEnd);
    this.scrollBar.attr("width", rootBBox.width).attr("x", rootBBox.x);
  };

  seekTo = (value) => {
    const { rangeX } = this.props;
    const currentBrushRange = [
      Number(this.brush.select(".selection").attr("x")),
      Number(this.brush.select(".selection").attr("x")) +
        Number(this.brush.select(".selection").attr("width")),
    ];

    const change =
      ((this.xScale(new Date(rangeX[0].getTime() + value)) -
        this.xScale(rangeX[0])) *
        this.axisScroll.current.offsetWidth) /
      this.rightConfig.width;
    if (currentBrushRange[1] + change >= this.axisScroll.current.offsetWidth) {
      this.brush
        .transition()
        .call(this.brushObject.move, [
          this.axisScroll.current.offsetWidth -
            currentBrushRange[1] +
            currentBrushRange[0],
          this.axisScroll.current.offsetWidth,
        ]);
      return;
    }
    if (currentBrushRange[1] + change <= 0) {
      this.brush
        .transition()
        .call(this.brushObject.move, [
          0,
          currentBrushRange[1] - currentBrushRange[0],
        ]);
      return;
    }
    this.brush
      .transition()
      .call(this.brushObject.move, [
        currentBrushRange[0] + change,
        currentBrushRange[1] + change,
      ]);
  };

  render() {
    const { height } = this.props;
    return (
      <div style={{ width: "calc(100% - 300px)" }}>
        <div
          style={{
            position: "sticky",
            top: 0,
            left: 300,
            zIndex: 25,
            background: "white",
          }}
          ref={this.axisScroll}>
          <svg width="100%" height="16" ref={this.brushRef}></svg>
          <div style={{ overflowX: "hidden" }} ref={this.axisAreaRef}></div>
        </div>
        <div
          style={{
            height: height,
            overflowX: "hidden",
            overflowY: "hidden",
          }}
          ref={this.chartRef}></div>
      </div>
    );
  }
}

export default BaseRightContent;
