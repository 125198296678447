import ChartDetail from "components/ChartDetail";
class TurningMovement extends ChartDetail {
  constructor(props) {
    super(props);
    this.value_field = "to_time";
    this.data_field = "bins";

    this.CHART_AXIS_SETTING = [
      {
        position: "left",
        key: "Left",
        label: "Vehicle",
        data: this.props.data.bins,
        tick: true,
      },
    ];
  }

  /**
   * findMaxValueInObjectArray
   *
   * @param {Array} array  data to find the max value
   * @param {String} key key of compare field
   * @param {Object} defaultValue defaultValue
   */
  findMaxValueInObjectArray = (array) => {
    let maxValue = 0;
    if (Array.isArray(array)) {
      array.forEach((item) => {
        this.chartLegend.forEach((legend) => {
          if (item[legend.key])
            maxValue =
              maxValue > item[legend.key] ? maxValue : item[legend.key];
        });
      });
    }
    return maxValue;
  };

  render() {
    return super.render();
  }
}

export default TurningMovement;
