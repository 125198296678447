import { Icon, Tooltip } from "antd";
import ChartPlan from "components/ChartPlan";
import React, { Component } from "react";
import TableRLV from "./TableRLV";

class WrapChartRLV extends Component {
  constructor(props) {
    super(props);
    // create a ref to store the textInput DOM element
    this.chartPlan = React.createRef();
    this.chartData = React.createRef();
    this.state = {
      isZomming: false,
    };
  }
  handleChartZoom = (newXScale) => {
    this.chartPlan?.current?.updateChart(newXScale);
    this.setState({
      isZomming: true,
    });
  };
  clearZoomingChart = () => {
    this.chartData?.current?.resetZoom();
    this.setState({
      isZomming: false,
    });
  };

  render() {
    const {
      t,
      isCompareMode,
      isPrintMode,
      children,
      dataProps,
      parentHeight,
      height,
    } = this.props;
    const pageSize =
      height === "50%"
        ? Math.floor((parentHeight - 96) / 128)
        : Math.floor((parentHeight - 86) / 64);

    return (
      <div
        style={{ height: height, position: "relative" }}
        className="mb-16 wrap-chart-turning-movement"
        ref>
        {!isCompareMode && (
          <div className="chart-info-wrap flex justify-space-beetwen">
            <div className="font-bold">{dataProps.data.phase}</div>
          </div>
        )}

        {!isCompareMode ? (
          <div className="flex h-full ">
            <div
              className="chart-container relative"
              style={{ width: isPrintMode ? "100%" : "calc(100% - 400px)" }}>
              {this.state.isZomming && (
                <Tooltip
                  placement="top"
                  title={t("analysis.zoom_explaination")}>
                  <Icon
                    onClick={this.clearZoomingChart}
                    className="chart-zoom-mark"
                    type="reload"
                  />
                </Tooltip>
              )}
              <div className="chart-plan-container">
                <ChartPlan {...dataProps} ref={this.chartPlan} />
              </div>

              <div className="chart-detail-container">
                {" "}
                {React.cloneElement(children, {
                  onChartZoom: this.handleChartZoom,
                  ref: this.chartData,
                })}
              </div>
            </div>
            {!isPrintMode ? (
              <div style={{ width: 400 }} className="relative">
                {/* hard code here to set page size by height */}
                <TableRLV pageSize={pageSize} dataSource={dataProps.data} />
              </div>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <div className={isPrintMode ? "flex h-full " : "flex h-full mt-64"}>
            <div
              className={
                isPrintMode
                  ? "chart-container compare-chart"
                  : "chart-container"
              }
              style={{ width: isPrintMode ? "100%" : "calc(100% - 400px)" }}>
              <div className="chart-detail-container">
                {" "}
                {React.cloneElement(children, {
                  onChartZoom: this.handleChartZoom,
                  ref: this.chartData,
                })}
              </div>
              <div className="chart-plan-container">
                <ChartPlan {...dataProps} ref={this.chartPlan} />
              </div>
            </div>
            {!isPrintMode ? (
              <div style={{ width: 400 }} className="relative mt-32">
                {/* hard code here to set page size by height */}
                <TableRLV pageSize={pageSize} dataSource={dataProps.data} />
              </div>
            ) : (
              <></>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default WrapChartRLV;
