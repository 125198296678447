import { Table } from "antd";
import { inject, observer } from "mobx-react";
import moment from "moment";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";

@inject("rootStore")
@observer
class DetailView extends Component {
  constructor(props) {
    super(props);

    this.rowKey = "from_time";
  }

  getTableColumns = () => {
    const { data } = this.props;
    const phaseColumns =
      data?.[0]?.phase_cycle.map((phase) => ({
        title: `Phase ${phase.phase} ${
          phase.approach?.length ? `(${phase.approach?.join(", ")})` : ""
        }`,
        width: 100,
        key: `phase_${phase.phase}`,
        dataIndex: `phase_${phase.phase}`,
        render: (phase) =>
          phase?.actual_length && phase?.programmed_length
            ? `${phase?.actual_length}/${
                phase?.programmed_length
              } (${phase?.status.map((stt) => stt?.[0]).join(", ")})`
            : "0/0",
      })) || [];
    return [
      {
        title: "Date/Time",
        key: "from_time",
        dataIndex: "from_time",
        render: (text) => {
          return moment(text).format("M/D/YYYY LTS");
        },
        width: 180,
        ellipsis: true,
      },
      {
        title: "Pattern",
        key: "pattern",
        dataIndex: "pattern",
        width: 90,
      },
      {
        title: "Cycle",
        key: "cycle",
        dataIndex: "cycle",
        render: (text) => {
          return `${text?.actual_length ?? "N/A"}/${
            text?.programmed_length ?? "N/A"
          }`;
        },
        width: 90,
      },
      ...phaseColumns,
      {
        title: "Transition",
        key: "transition",
        dataIndex: "transition",
        render: (text) => {
          return `${text ? text : "-"}`;
        },
        width: 90,
      },
      {
        title: "TSP",
        key: "TSP",
        dataIndex: "TSP",
        render: (text) => (text ? "Yes" : "No"),
        width: 90,
      },
      {
        title: "PE",
        key: "PE",
        dataIndex: "PE",
        render: (text) => (text ? "Yes" : "No"),
        width: 90,
      },
    ];
  };

  render() {
    const { t, data } = this.props;
    return (
      <div>
        <div className="flex justify-end mb-8">
          <table className="bordered ">
            <tbody>
              <tr>
                <th className="p-4 font-12">Acronym</th>
                <td className="p-4 font-12">F</td>
                <td className="p-4 font-12">M</td>
                <td className="p-4 font-12">G</td>
                <td className="p-4 font-12">P</td>
              </tr>
              <tr>
                <th className="p-4 font-12">Description</th>
                <td className="p-4 font-12">{t("analysis.force_off")}</td>
                <td className="p-4 font-12">{t("analysis.max_out")}</td>
                <td className="p-4 font-12">{t("analysis.gap_out")}</td>
                <td className="p-4 font-12">{t("analysis.pedestrian_call")}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <Table
          dataSource={data}
          columns={this.getTableColumns()}
          rowKey={(_e, id) => id}
          scroll={{
            x: 1200,
            y: 400,
          }}
          pagination={{
            defaultCurrent: 1,
            defaultPageSize: 100,
            pageSizeOptions: ["100", "200", "500", "1000"],
            showSizeChanger: true,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} ${t("of")} ${total} ${t("items")}`,
            showQuickJumper: true,
          }}
          // eslint-disable-next-line unused-imports/no-unused-vars
          rowClassName={(_record, _index) => {
            return "cell-full-text";
          }}
          className="table-moe-detail-view"
        />
      </div>
    );
  }
}
export default withTranslation()(DetailView);
