import { Form } from "antd";
import { inject } from "mobx-react";
import moment from "moment";
import React from "react";
import { withTranslation } from "react-i18next";
import { DATE_FORMAT, FORM_ITEM_LAYOUT } from "utils/constants";
import BaseDetailPopupComponent from "../../../components/BaseDetailPopupComponent";

/**
 * Component for showing create agency form
 *
 * @component
 */
@inject("rootStore")
class EventHistoryDetailPopup extends BaseDetailPopupComponent {
  /**
   * contructor of component
   * @param   {object} props  props of component
   */
  constructor(props) {
    super(props.currentStore, props);
    this.formFields = ["name"];
  }

  renderReadOnlyForm(formData) {
    const { t } = this.props;
    return (
      formData?.intersection_uuid && (
        <div>
          <Form.Item
            label={t("tsp_dashboard.event_type")}
            {...FORM_ITEM_LAYOUT}
          >
            {formData.event_type}
          </Form.Item>
          <Form.Item
            label={t("tsp_dashboard.time_stamp")}
            {...FORM_ITEM_LAYOUT}
          >
            {moment(formData.date_time_stamp)
              .local()
              .format(DATE_FORMAT.tsp_full_zone)}
          </Form.Item>
          <Form.Item
            label={t("tsp_dashboard.priority_des")}
            {...FORM_ITEM_LAYOUT}
          >
            {formData.priority}
          </Form.Item>
          <Form.Item label={t("tsp_dashboard.vehicle_id")}>
            {formData.vehicle_id}
          </Form.Item>
          <Form.Item label={t("tsp_dashboard.request_id")}>
            {formData.request_id}
          </Form.Item>
          <Form.Item label={t("tsp_dashboard.network_id")}>
            {formData.network_id}
          </Form.Item>
          <Form.Item label={t("tsp_dashboard.int_uuid")}>
            {formData.intersection_uuid}
          </Form.Item>
          <Form.Item label={t("tsp_dashboard.vehicle_location")}>
            {t("agency.latitude")}: {formData.vehicle_location.latitude},{" "}
            {t("agency.longitude")}: {formData.vehicle_location.longitude},{" "}
            {t("tsp_dashboard.heading")}: {formData.vehicle_location.heading}
          </Form.Item>
          <Form.Item label={t("tsp_dashboard.estimation_arrivaltime")}>
            {formData.est_arrival_time}
          </Form.Item>
          <Form.Item label={t("tsp_dashboard.passenger_loading")}>
            {formData.passenger_loading}
          </Form.Item>
          <Form.Item label={t("tsp_dashboard.time_behind_ahead")}>
            {formData.time_behind_ahead}
          </Form.Item>
          <Form.Item label={t("tsp_dashboard.route_number")}>
            {formData.route_number}
          </Form.Item>
          <Form.Item label={t("tsp_dashboard.block_number")}>
            {formData.block_number}
          </Form.Item>
          <Form.Item label={t("tsp_dashboard.trip_direction")}>
            {formData.trip_direction}
          </Form.Item>
          <Form.Item label={t("tsp_dashboard.direction_next_int")}>
            {formData.direction_at_next_intersection}
          </Form.Item>
        </div>
      )
    );
  }

  /**
   * renderDetailData
   * @param   {object} formData
   * @param   {object} getFieldDecorator
   * this function return the detail box
   * @return  {Component}
   */
  renderDetailData(formData) {
    return this.renderReadOnlyForm(formData);
  }
}
const FormWrap = Form.create({ name: "alarm-rule-detail" })(
  EventHistoryDetailPopup
);

export default withTranslation()(FormWrap);
