import BaseApi from "./BaseApi";
import Api from "./Api";

class AnalysisService extends BaseApi {
  constructor(parentStore) {
    super("analysis", parentStore);
  }

  getChartData = (path, intersectionId, agency_id, payload) => {
    return new Promise((resolve, reject) => {
      const api = new Api(
        this.moduleName + "/" + agency_id + "/" + path + "/" + intersectionId,
        this.parentStore
      );
      api.getRequest(
        payload,
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  };

  /**
   * getAllAnalysisTypes
   * get all analysis type
   * @return  {null} -  call the callback function
   */
  getAllAnalysisTypes = () => {
    return new Promise((resolve, reject) => {
      const api = new Api(this.moduleName, this.parentStore);
      api.getRequest(
        {},
        (response) => {
          resolve(response.data);
        },
        (error) => {
          reject(error);
        }
      );
    });
  };

  /**
   * getAvailableDays
   * get all analysis type
   * @return  {null} -  call the callback function
   */
  getAvailableDays = (params) => {
    return new Promise((resolve, reject) => {
      const api = new Api(this.moduleName + "/days", this.parentStore);
      api.getRequest(
        params,
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  };
}

export default AnalysisService;
