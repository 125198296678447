import { Icon } from "antd";
import { inject, observer } from "mobx-react";
import React from "react";
import { withTranslation } from "react-i18next";
import { ACTIONS_LIST, RESOURCE_LIST } from "utils/constants";
import BaseManagementComponents from "../../../../components/BaseManagementComponents";
import "./style.scss";
@inject("rootStore")
@observer
class ChoosingAvalableConfigs extends BaseManagementComponents {
  constructor(props) {
    const { t } = props;
    super(props.rootStore.topologyConfigStore, props);
    this.state = {
      displayModal: true,
    };
    this.rowKey = "_id";
    this.enableRowSelection = false;
    this.enableCreateButton = false;
    this.enableFilterBox = false;
    this.pageTitle = (
      <div className="flex items-center" style={{ gap: "10px" }}>
        <Icon type="info-circle" className="font-15" />
        <span className="font-14 line-height-0 text-lighter">
          {t("intersection_config.page_title")}
        </span>
      </div>
    );
    this.columns = this.getTableColumns();
    this.tableInlineActions = [
      {
        key: "copy",
        tooltip: t("intersection_config.copy"),
        action: this.handleChangeConfig,
        resource: RESOURCE_LIST.REPORT_TEMPLATE,
        permission: ACTIONS_LIST.CREATE,
        child: "Copy",
        passingData: (item) => {
          return [item];
        },
      },
    ];
  }
  handleChangeConfig = (item) => {
    this.currentStore?.getTopologyDetail(item);
    this.props.closeModal();
  };
  getTableColumns = () => {
    const { t } = this.props;
    return [
      {
        title: t("intersection_config.uuid_config_topology"),
        key: "int_uuid",
        render: (item) => item?.int_uuid,
      },
      {
        title: t("intersection_config.name_config_topology"),
        key: "int_name",
        render: (item) => item?.int_name,
      },
    ];
  };
  componentDidMount() {
    // super.componentDidMount();
  }
  render() {
    /**this code cause when click to open handle action, will only receive actionObject!
     *
     *  */

    return <div>{super.render()}</div>;
  }
}

export default withTranslation()(ChoosingAvalableConfigs);
