import React, { Component, createRef } from "react";
import * as d3 from "d3";
import "./style.scss";
import { FONT_STRING_CAVAS } from "utils/constants";

class SplitMonitorRuler extends Component {
  constructor(props) {
    super(props);
    this.chartRef = createRef();
    this.canvas = null;
    this.xScale = null;
    this.yScale = null;
    this.width = 2000;
    this.height = 30;
    this.margin = {left: 5};
  }

  drawCanvas = () => {
    this.canvas = d3.select(this.chartRef.current);

    this.canvas = d3.select(this.chartRef.current)
      .append('canvas')
      .attr('width', this.width)
      .attr('height', this.height);
  }

  getInitialAxis = () => {
    this.rangeX = [0, 120/(650 - 180 - 25)*2000];
    this.rangeY = [0, 100]
    
    this.xScale = d3
      .scaleLinear()
      .domain(this.rangeX)
      .range([0, this.width - this.margin.left]);

    this.yScale = d3
      .scaleLinear()
      .domain(this.rangeY)
      .range([this.height, 0]);
  };

  drawRuler = () => {
    //Drawing the numbers on the ruler:
    let ctx = this.ctx;
    ctx = this.canvas.node().getContext('2d');
    ctx.translate(this.margin.left, 0);

    const calculateNumberTicks = Math.floor(this.width * 7/650);

    ctx.fillStyle = "#606060";
    ctx.strokeStyle = "#606060";
    ctx.font = `12px ${FONT_STRING_CAVAS}`;

    let xTickCount = calculateNumberTicks,
      xTicks = this.xScale.ticks(xTickCount);

    xTicks.forEach((d) => {
      ctx.textAlign = "center";
      ctx.fillText(d, this.xScale(d), this.yScale(70));
    })

    //Drawing the horizontal line at the bottom:
    ctx.beginPath();
    ctx.moveTo(0, this.yScale(20));
    ctx.lineTo(this.width, this.yScale(20));
    ctx.stroke();

    //Drawing vertical ticks on ruler:
    let verticalTicks = this.xScale.ticks(xTickCount*8)
    ctx.beginPath();
    verticalTicks.forEach((d) => {
      if (d % 10 === 0) {
        ctx.moveTo(this.xScale(d), this.yScale(20));
        ctx.lineTo(this.xScale(d), this.yScale(55));
      } else {
        ctx.moveTo(this.xScale(d), this.yScale(20));
        ctx.lineTo(this.xScale(d), this.yScale(40));
      }
    })
    ctx.stroke();
  }

  componentDidMount() {
    this.drawCanvas();
    this.getInitialAxis();
    this.drawRuler();
  }

  render() {
    return(
      <div className="split-monitor-ruler" ref={this.chartRef}></div>
    )
  }
}

export default SplitMonitorRuler;