import ChartDetail from "components/ChartDetail";
const EVENT_DATA_MAPPER = {
  gap_out: 4,
  max_out: 5,
  force_off: 6,
  phase_skip: 20,
};

const programmedSplittKey = "programmed_split"; //@TODO:hardcode here

class PhaseTerminationChart extends ChartDetail {
  constructor(props) {
    super(props);
    this.value_field = "datetime";
    this.data_field = "phase_terminations";
    this.highlightList = props.data?.actual_phase_terminations;
    this.CHART_AXIS_SETTING = [
      {
        position: "left",
        key: "phase_time",
        label: "Phase Duration",
        data: this.props.data.phase_terminations,
        tick: true,
      },
    ];
    this.TooltipStyle.width = 240;
  }

  renderTooltipText = (label, unit, value) => label + ": " + value + unit;

  getDataObject = (chartData, item, planData) => {
    const { from_time, to_time } = this.props;
    let result = [];
    if (item.key !== programmedSplittKey) {
      let availableData = chartData.filter(
        (e) =>
          EVENT_DATA_MAPPER[item.key] === e.event &&
          from_time.getTime() <= new Date(e[this.value_field]).getTime() &&
          new Date(e[this.value_field]).getTime() <= to_time.getTime()
      );
      if (item.key === "gap_out") {
        result = availableData.map((e) => {
          let findHightLight = this.highlightList.findIndex(
            (element) =>
              element.datetime === e.datetime &&
              e.phase_time === element.phase_time
          );
          return {
            time: e.datetime,
            isHighLight: findHightLight !== -1,
            value: e.phase_time,
          };
        });
      } else
        result = availableData.map((e) => {
          let findHightLight = this.highlightList.findIndex(
            (element) =>
              element.datetime === e.datetime &&
              e.phase_time === element.phase_time
          );
          return {
            time: e.datetime,
            isHighLight: findHightLight !== -1,
            value: e.phase_time,
          };
        });
    } else {
      planData.map((plan) => {
        result.push({
          time: plan.from_time,
          value: plan.programmed_split,
        });
        result.push({
          time: plan.to_time,
          value: plan.programmed_split,
        });
      });
    }
    return result;
  };

  /**
   * render
   *
   * @return  {Component}
   */
  render() {
    return super.render();
  }
}

export default PhaseTerminationChart;
