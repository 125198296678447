import queryString from "query-string";
import Api from "./Api";
import BaseApi from "./BaseApi";
/**
 * Service for intersections
 *
 * @class
 */
class OptimizeService extends BaseApi {
  constructor(parentStore) {
    super("optimization/histories", parentStore);
    this.dataKey = "optimization_histories";
  }
  delete = (params, cb, fb) => {
    const api = new Api(
      this.moduleName +
        "?" +
        queryString.stringify(params, { arrayFormat: "brackets" }),
      this.parentStore
    );
    api.deleteRequest(
      () => {
        cb && cb();
      },
      () => {
        fb && fb();
      }
    );
  };
  /**
   * getAllMetaData
   * get all meta data
   * @return  {null} -  call the callback function
   */
  getAllMetaData = () => {
    return new Promise((resolve, reject) => {
      const api = new Api("optimization/metadata", this.parentStore);
      api.getRequest(
        {},
        (response) => {
          resolve(response.data);
        },
        (error) => {
          reject(error);
        }
      );
    });
  };
  getOneItem = (id, filterObject) => {
    return new Promise((resolve, reject) => {
      const api = new Api("optimization/" + id, this.parentStore);
      api.getRequest(
        filterObject,
        (response) => {
          resolve(response.data);
        },
        (error) => {
          reject(error);
        }
      );
    });
  };
  beginOptimize = (body) => {
    return new Promise((resolve, reject) => {
      const api = new Api("optimization", this.parentStore);
      api.postRequest(
        body,
        (response) => {
          resolve(response.data);
        },
        (error) => {
          reject(error?.errors);
        }
      );
    });
  };
}

export default OptimizeService;
