import React from "react";
import BaseHistoricTimeSpaceChart from "../BaseHistoricTimeSpaceChart";
import RightContent from "./RightContent";
class PrgrammedTimespaceChart extends BaseHistoricTimeSpaceChart {
  constructor(props) {
    super(props);
  }

  renderRightContent = () => {
    const { start_time, end_time, showTimeSpace } = this.props;
    return (
      <RightContent
        handleBrushZoom={this.handleBrushZoom}
        showTimeSpace={showTimeSpace}
        ref={this.rightRef}
        start_time={start_time}
        end_time={end_time}
        dataSourceIntersection={this.dataSource?.intersections}
        dataSource={this.dataSource}
        height={this.listHeight}
        yScale={this.yScale}
        xScale={this.xScale}
        rangeX={this.rangeX}
        {...this.props}
      />
    );
  };
}

export default PrgrammedTimespaceChart;
