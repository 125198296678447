import { Layout, Spin } from "antd";
import LocalPopupComponent from "components/LocalPopupComponent";
import { inject, observer } from "mobx-react";
import React from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import localStorageHelper from "utils/localStorageHelper";
import AllJobProgress from "../AllJobProgress";
import Footer from "./Footer";
import Header from "./Header";
import MiniLoading from "./MiniLoading";

/**
 * Component for Application layout
 *
 * @component
 */
@inject("rootStore")
@observer
class PrivateLayout extends React.Component {
  /**
   * contructor of component
   * @param   {object} props  props of component
   */
  constructor(props) {
    super(props);
    this.state = {
      isChecking: true,
    };
  }

  /**
   * componentDidMount
   *
   * auto redirect to login page for unauthenticated request
   * clean the token when close the browser
   * run script auto refresh token
   * re-update lastest information of current user
   *
   */
  componentDidMount() {
    const {
      rootStore: { authStore, socketStore, commonStore },
    } = this.props;
    authStore?.checkCurrentCookieAuthentication().then((isOk) => {
      if (isOk) {
        //run script check token
        authStore?.startCheckToken();
        //listen push notification from server
        socketStore.connect();

        //set current style from localstorage
        let currentTheme = localStorageHelper.getLocalStorage("theme", "light");
        if (!currentTheme) {
          currentTheme = "light";
        }
        commonStore.setThemeStyle(currentTheme);
        //done checking auth -please show page
        this.setState({ isChecking: false });
      } else {
        this.props.history.push("/login");
      }
    });
  }

  /**
   * handleWindowClose
   * check if remember me not checked => remove all localstorage data
   *
   * @return  {null}
   */
  // handleWindowClose = () => {
  //   const {
  //     rootStore: { authStore }
  //   } = this.props;
  //   if (!authStore?.getRememberMe()) {
  //     authStore?.clearToken();
  //   }
  // };

  /**
   * render
   *
   * @return  {Component}
   */
  render() {
    const {
      rootStore: { commonStore, unreadNotificationStore },
      t,
    } = this.props;
    return this.state.isChecking ? (
      <Spin className={"fullscreen centralize"} size="large" />
    ) : (
      <div className={"App"}>
        {unreadNotificationStore?.unReadReleaseNote &&
          !commonStore.isFromStatic && (
            <LocalPopupComponent currentStore={unreadNotificationStore} t={t} />
          )}
        <div className="wrap-mini-loading-outside">
          <MiniLoading show={commonStore?.miniLoading} />
        </div>
        <Layout>
          <Header />
          {/* <BubbleButton/> */}
          <AllJobProgress />
          {this.props?.children}
          <Footer />
        </Layout>
      </div>
    );
  }
}

export default withRouter(withTranslation()(PrivateLayout));
