import { Menu } from "antd";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { matchPath, withRouter } from "react-router";
import { Link } from "react-router-dom";
import { privateRoutes } from "../../../Routes";
import "./style.scss";
const { SubMenu } = Menu;

/**
 * Component for Menu on the header bar
 *
 * @component
 */

class MenuBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true,
    };
  }
  /**
   * findTheMatchedRoute
   *
   * @return  {String} Matched path
   */
  findTheMatchedRoute = (currentPath) => {
    const { myStore } = this.props;
    let path = currentPath;
    privateRoutes
      ?.filter((item) => {
        return (
          !item.hiddenFromMenu &&
          (item.permission == null ||
            myStore.hasPermission(item.action, item.permission))
        );
      })
      .find((item) => {
        let matchedPath = null;
        if (item.childs && Array.isArray(item.childs)) {
          item.childs.find((subItem) => {
            if (subItem?.childs)
              matchedPath = matchPath(currentPath, {
                path: item.path + subItem.path,
                exact: false,
                strict: true,
              });
            else
              matchedPath = matchPath(currentPath, {
                path: item.path,
                exact: false,
                strict: true,
              });
            return true;
          });
        } else {
          matchedPath = matchPath(currentPath, {
            path: item.path,
            exact: true,
            strict: true,
          });
        }
        if (matchedPath) {
          path = matchedPath.path;
          return true;
        }
      });

    return path;
  };

  /**
   * render
   *
   * @return  {Component}
   */
  render() {
    const { location, t, myStore } = this.props;
    let selectedPath = this.findTheMatchedRoute(location.pathname);
    const licenses = myStore.getCurrentAgency().licenses;
    return (
      <Menu
        className="will-hide"
        selectedKeys={[selectedPath]}
        defaultSelectedKeys={["/"]}
        id="menu"
        mode="horizontal">
        {privateRoutes
          ?.filter((item) => {
            return (
              !item.hiddenFromMenu &&
              (item.permission == null ||
                myStore.hasPermission(item.action, item.permission, true))
            ) && (!item.license || licenses.includes(item.license))
              && (!item.license_child || licenses.some(el => item.license_child.includes(el)));
          })
          .map((item) => {
            return !item.childs || item.render_route ? (
              <Menu.Item key={item.path}>
                <Link to={item.path} className="items-center inline-flex">
                  {item.icon}
                  <span className="nav-text">{t(item.text)}</span>
                </Link>
              </Menu.Item>
            ) : (
              <SubMenu
                key={item.path}
                title={
                  <span className="items-center inline-flex">
                    {item.icon}
                    <span className="nav-text">{t(item.text)}</span>
                  </span>
                }>
                {item.childs.map((subItem) => {
                  return !subItem.hiddenFromMenu &&
                    myStore.hasPermission(
                      subItem.action,
                      subItem.permission,
                      true
                    ) && (!subItem.license || licenses.includes(subItem.license)) ? (
                    <Menu.Item key={item.path + subItem.path}>
                      <Link
                        to={item.path + subItem.path}
                        style={{
                          display: "inline-flex",
                          alignItems: "center",
                        }}>
                        {subItem.icon}
                        <span className="nav-text">{t(subItem.text)}</span>
                      </Link>
                    </Menu.Item>
                  ) : null;
                })}
              </SubMenu>
            );
          })}
      </Menu>
    );
  }
}
export default withRouter(withTranslation()(MenuBar));
