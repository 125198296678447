import {
  Checkbox,
  DatePicker,
  Form,
  Input,
  InputNumber,
  TimePicker,
} from "antd";
import { inject, observer } from "mobx-react";
import moment from "moment";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import { DATE_FORMAT, TIME_PICKER_FORMAT } from "utils/constants";
import SiwaveLazySelectBox from "./SiwaveLazySelectBox";

@inject("rootStore")
@observer
class HistoricTimeSpaceFilter extends Component {
  constructor(props) {
    super(props);
  }

  /**
   * forcus on target marker
   * @return  {Component}
   */
  render() {
    const {
      t,
      rootStore,
      rootStore: {
        optimizerStore: { formData, setFormField },
      },
      form: { getFieldDecorator, validateFields, resetFields },
    } = this.props;

    return (
      <>
        <Form.Item label={t("Corridor")}>
          {getFieldDecorator("corridor_id", {
            rules: [
              {
                required: true,
                message: t("field_required", {
                  field: t("Corridor"),
                }),
              },
            ],
            initialValue: formData.corridor_id,
            getValueFromEvent: (value) => {
              setFormField("corridor_id", value);
              return value;
            },
          })(
            <SiwaveLazySelectBox
              path={`corridors`}
              firstParams={{
                agency_id: formData.agency_id,
              }}
              rootStore={rootStore}
              moduleName="corridors"
              placeholder={t("select_item", {
                item: t("corridor"),
              })}
            />
          )}
        </Form.Item>
        <Input.Group className="flex justify-space-between items-end">
          <Form.Item label={t("optimization.plan_number")}>
            {getFieldDecorator("plan_number", {
              rules: [
                {
                  required: true,
                  message: t("optimization.plan_number_required"),
                },
              ],
              initialValue: formData.plan_number,
            })(
              <InputNumber
                onChange={(value) => {
                  setFormField("plan_number", value);
                }}
                min={1}
                max={255}
              />
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator("show_programmed", {
              initialValue: formData.show_programmed,
              valuePropName: "checked",
              getValueFromEvent: (e) => {
                setFormField("show_programmed", e.target.checked);
                return e.target.checked;
              },
            })(<Checkbox> {t("optimization.show_programmed")}</Checkbox>)}
          </Form.Item>
        </Input.Group>

        <Input.Group className="flex w-full justify-space-beetwen">
          <Form.Item label={t("report_result.time_range")}>
            {getFieldDecorator("start_time", {
              rules: [
                {
                  required: true,
                  message: t("analysis.from_time_required"),
                },
              ],

              initialValue: moment(formData.start_time, DATE_FORMAT.hour),
            })(
              <TimePicker
                placeholder={t("analysis.from_time_placeholder")}
                onChange={(time, timeString) => {
                  setFormField(
                    "start_time",
                    moment(timeString, TIME_PICKER_FORMAT).format(
                      TIME_PICKER_FORMAT
                    )
                  );
                }}
                format={TIME_PICKER_FORMAT}
              />
            )}
          </Form.Item>

          <Form.Item label={" "} colon={false}>
            {getFieldDecorator("end_time", {
              rules: [
                {
                  required: true,
                  message: t("optimization.to_time_required"),
                },
              ],
              initialValue: moment(formData.end_time, DATE_FORMAT.hour),
            })(
              <TimePicker
                placeholder={t("analysis.to_time_placeholder")}
                onChange={(time, timeString) => {
                  setFormField(
                    "end_time",
                    moment(timeString, TIME_PICKER_FORMAT).format(
                      TIME_PICKER_FORMAT
                    )
                  );
                  resetFields(["from_time"]);
                  validateFields(["from_time"]);
                }}
                format={TIME_PICKER_FORMAT}
              />
            )}
          </Form.Item>
        </Input.Group>

        <Form.Item label={t("date")}>
          {getFieldDecorator("start_date", {
            rules: [
              {
                required: true,
                message: t("field_required", { field: t("date") }),
              },
            ],
            initialValue: formData.start_date
              ? moment(formData.start_date, DATE_FORMAT.day_only_backend)
              : null,
            getValueFromEvent: (value) => {
              setFormField(
                "start_date",
                value.format(DATE_FORMAT.day_only_backend)
              );
              setFormField(
                "end_date",
                value.format(DATE_FORMAT.day_only_backend)
              );
              return value;
            },
          })(<DatePicker format={DATE_FORMAT.day_only_backend} />)}
        </Form.Item>
      </>
    );
  }
}

export default withRouter(withTranslation()(HistoricTimeSpaceFilter));
