import * as d3 from "d3";
import { CHART_COLOR_SCHEMA } from "utils/constants";
import ChartDetail from "components/ChartDetail";
class CoordinationHealth extends ChartDetail {
  constructor(props) {
    super(props);
    this.value_field = "to_time";
    this.data_field = "cha_bins";
    this.CHART_AXIS_SETTING = [
      {
        position: "left",
        key: "cycle",
        label: "Cycles",
        data: this.props.data?.cha_bins,
        tick: true,
      },
    ];
  }
  renderChartData = () => {
    const self = this;
    let defaultBarWidth =
      this.newX(new Date(1610438540000)) - this.newX(new Date(1610438400000));
    if (defaultBarWidth > 16) defaultBarWidth = 16;
    if (defaultBarWidth < 8) defaultBarWidth = 8;
    let lineCharts = this.chartObject
      .append("g")
      .attr("class", "lines-chart-group");
    lineCharts
      .append("defs")
      .append("SVG:clipPath")
      .attr("id", "clip")
      .append("SVG:rect")
      .attr("width", this.fullWidth)
      .attr("height", this.fullHeight)
      .attr("x", 0)
      .attr("y", 0);
    this.lineChart = lineCharts;
    this.availableLineData.forEach((item) => {
      let pointX =
        this.newX(new Date(item.to_time)) +
        this.ChartStyle.marginLeftRight -
        defaultBarWidth / 2;
      let miniumYValueBar = this.newYObject["left"].domain()[0] / 50;
      let pointY = this.newYObject["left"](
        item.cycle > 0 ? item.cycle : miniumYValueBar
      );
      let barHeight = this.fullHeight - pointY;
      let transitionBarHeight = (barHeight * item?.cha_instep) / 100;
      let instepHeight = barHeight;
      //add instep bar
      let barGroup = lineCharts.append("g");
      barGroup.on("mouseout", this.handleMouseOut);
      barGroup.on("mousemove", function () {
        let mouse = d3.mouse(this);
        self.handleMouseMove(
          [
            mouse[0] - self.ChartStyle.marginLeftRight,
            mouse[1] - self.ChartStyle.marginTopBottom,
          ],
          item
        );
      });
      barGroup
        .append("rect")
        .style("fill", CHART_COLOR_SCHEMA.PINK)
        .attr("clip-path", "url(#clip)")
        .attr(
          "transform",
          "translate(" +
            this.ChartStyle.marginLeftRight +
            ", " +
            this.ChartStyle.marginTopBottom +
            ")"
        )
        .attr("x", pointX - this.ChartStyle.marginLeftRight)
        .attr("y", pointY)
        .attr("width", defaultBarWidth)
        .attr("class", "bar")
        .attr("height", instepHeight + this.ChartStyle.marginTopBottom);

      //add transition bar
      barGroup
        .append("rect")
        .style("fill", CHART_COLOR_SCHEMA.GREEN_SIEMENS)
        .attr("clip-path", "url(#clip)")
        .attr(
          "transform",
          "translate(" +
            this.ChartStyle.marginLeftRight +
            ", " +
            this.ChartStyle.marginTopBottom +
            ")"
        )
        .attr("x", pointX - this.ChartStyle.marginLeftRight)
        .attr("y", pointY)
        .attr("width", defaultBarWidth)
        .attr("class", "bar")
        .attr("height", transitionBarHeight);
    });
  };

  handleMouseMove = (mousePosition, item) => {
    let xPosition = mousePosition[0];
    let yPosition = mousePosition[1];
    if (this.hightLineObject) this.hightLineObject.remove();
    if (item) {
      let targetObject = [
        {
          label: "Time: ",
          value: new Date(item.to_time)?.toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            // second: "numeric",
            hour12: true,
          }),
          color: CHART_COLOR_SCHEMA.DEFAULT,
        },
        {
          label: "Num. of Cycles: ",
          value: item.cycle,
          color: CHART_COLOR_SCHEMA.DEFAULT,
        },
        {
          label: "In-step: ",
          value:
            item.cha_instep % 1 === 0
              ? item.cha_instep
              : Number(item.cha_instep).toFixed(2),
          color: CHART_COLOR_SCHEMA.GREEN_SIEMENS,
          vehical: "%",
        },
        {
          label: "Transition: ",
          value:
            item.cha_transition % 1 === 0
              ? item.cha_transition
              : Number(item.cha_transition).toFixed(2),
          color: CHART_COLOR_SCHEMA.PINK,
          vehical: "%",
        },
      ];
      this.renderHightLinePoint(targetObject, xPosition, yPosition);
    }
    this.crossHairObject?.crosshair_x
      ?.attr("display", "")
      .attr("x1", xPosition + this.ChartStyle.marginLeftRight)
      .attr("x2", xPosition + this.ChartStyle.marginLeftRight);
    this.crossHairObject?.crosshair_y
      ?.attr("display", "")
      .attr("y1", yPosition + this.ChartStyle.marginTopBottom)
      .attr("y2", yPosition + this.ChartStyle.marginTopBottom);
  };
  renderHightLinePoint = (targetObject, x, y) => {
    const tootTipHeight =
      (targetObject.length + 2) * this.TooltipStyle.defaultChartHeight; //2 is space top/bottom
    let chartObject = d3.select(this.chartRef?.current);
    if (this.hightLineObject) {
      this.hightLineObject?.remove();
    }

    if (chartObject) {
      this.hightLineObject = chartObject
        .append("g")
        .attr("class", "hightline-group");
      const xTooltipPosition =
        x < this.fullWidth / 2
          ? this.ChartStyle.marginLeftRight + x + this.TooltipStyle.marginLeft
          : this.ChartStyle.marginLeftRight +
            x -
            this.TooltipStyle.marginLeft -
            this.TooltipStyle.width;
      const yTooltipPosition =
        y < this.fullHeight / 2
          ? y + tootTipHeight / 2 + this.TooltipStyle.marginTop
          : y - tootTipHeight + this.TooltipStyle.marginTop;
      this.hightLineObject
        .append("rect")
        .attr("width", this.TooltipStyle.width) //hard code the width of box
        .attr("height", tootTipHeight)
        .attr("class", "tooltip-box")
        .attr("stroke", "var(--text)")
        .attr("fill", "var(--background)")
        .attr(
          "transform",
          "translate(" + xTooltipPosition + "," + yTooltipPosition + ")"
        );
      let labelIndex = 1.5;
      targetObject.forEach((item) => {
        this.hightLineObject
          .append("text")
          .text(
            item.label + item.value + " " + (item.vehical ? item.vehical : "")
          ) //the way we show tooltip defined on ANALYSIS_PLAN_INFO in constant file
          .attr("text-anchor", "middle")
          .attr("class", "tooltip-info-text")
          .attr(
            "transform",
            "translate(" +
              (xTooltipPosition + this.TooltipStyle.width / 2) +
              "," +
              (yTooltipPosition +
                this.TooltipStyle.defaultChartHeight * labelIndex) +
              ")"
          )
          .attr("fill", item.color);
        labelIndex++;
      });

      //render hight-line infor box
    }
  };
  tranformChartDataToFronEndDataFormat = (chartData) => {
    this.availableLineData = chartData;
    return this.availableLineData;
  };

  componentDidMount = () => {
    super.componentDidMount();
  };

  /**
   * render
   *
   * @return  {Component}
   */
  render() {
    console.log(this.CHART_AXIS_SETTING[0].data);
    return super.render();
  }
}

export default CoordinationHealth;
