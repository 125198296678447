import { action, observable, runInAction } from "mobx";
import moment from "moment";
import {
  DATE_FORMAT,
  DEFAULT_MAP_CENTER,
  DEFAULT_ZOOM_LEVEL,
  DELAY_SEARCH_STRING,
  INTERSECTION_TAB_INDEX,
  MAX_NUMBER_START_SEARCH,
  QUERY_DELAY_LOAD_DATA,
  RESOURCE_LIST,
  SEARCH_TEXT_FIELD,
  SUPPER_ADMIN_GROUP_AGENCY_ID,
} from "utils/constants";
import AgencyService from "../services/AgencyService";
import IntersectionService from "../services/IntersectionService";
import BaseStore from "./BaseStore";
const AGENCY_KEY_FIELD = "agency_id";
const DEFAULT_DOWNLOAD_INFO = {
  from_time: moment().day(-6),
  to_time: moment(),
  agency_uuid: "",
  int_uuid: "",
  name: "",
  selectedCategoryEvent: null,
  selectedEvent: null,
  formDownload: [],
};
/**
 * Store for intersections
 *
 * @class
 */
class IntersectionStore extends BaseStore {
  constructor(parentStore) {
    super(new IntersectionService(parentStore), parentStore);
    this.agencyService = new AgencyService(parentStore); //agency service to get agency data
    //filter data for list intersection
    this.filterData = {
      ...this.filterData,
      ...{
        text: "",
        sort: "",
        status: "null",
        agency_id: parentStore?.myStore?.currentAgency?.agency_id,
      },
    };
    this.resourceName = RESOURCE_LIST.INTERSECTION;
    //for loading loop intersections
    this.continuousLoadingThreadId = null;
  }
  DOWNLOAD_TYPE = {
    PROGRESS: "progress",
    DETAIL: "detail",
  };
  PROGRESS_ACTION_BTN_TYPE = {
    CANCEL: "cancel",
    CLEAR: "clear",
  };
  PROGRESS_STATUS_TYPE = {
    CANCELLED: "CANCELLED",
    PROCESSING: "PROCESSING",
  };
  //data for filtering
  @observable listAllAgency = []; //list all agency of current user
  @observable currentTab = INTERSECTION_TAB_INDEX.INTERSECTION_LIST_TAB; //current tab
  //data for map
  @observable initalAgencyLocation = {
    centerPoint: DEFAULT_MAP_CENTER,
    zoomLevel: DEFAULT_ZOOM_LEVEL,
  };
  @observable selectedIntersectionId = null;
  //data for download features
  @observable visibleDownloadModal = false;
  @observable curentDownloadInfo = { ...DEFAULT_DOWNLOAD_INFO };
  @observable exportProgressData = [];
  @observable listAllEvent = [];

  /**
   * toggleDownloadModalVisibility
   *
   * show and hide download raw data modal box
   * set and unset value of current intersection need tobe download
   * update new time range for download raw data
   * @param {String} value value
   * @param {String} int_uuid int_uuid
   * @param {String} name name
   * @return  {null}
   */
  @action toggleDownloadModalVisibility = (value, int_uuid = "", name = "") => {
    if (value) {
      this.curentDownloadInfo = {
        ...this.curentDownloadInfo,
        int_uuid: int_uuid,
        name: name,
        agency_uuid: this.filterData.agency_id,
      };
    }
    this.visibleDownloadModal = value;
  };
  @action setDownloadFormData = (value) => {
    this.curentDownloadInfo = {
      ...this.curentDownloadInfo,
      ...value,
    };
  };
  /**
   * handleDownloadDataChange
   *
   * update new time range for download raw data
   * @param {moment} fromTime fromTime
   * @param {moment} toTime toTime
   * @return  {null}
   */
  @action setInItalAngencyLocation(topLeft, bottomRight) {
    let lat = (topLeft?.lat + bottomRight?.lat) / 2;
    let lon = (topLeft?.lon + bottomRight?.lon) / 2;
    let currentAcreage = Math.abs(
      (topLeft?.lat - bottomRight?.lat) * (topLeft?.lon - bottomRight?.lon)
    );

    let baseZoomLevel = currentAcreage ? (180 * 360) / currentAcreage : 11;
    //Acreage will be decrease 4 ^ n times with n is zoom-level
    let zoomLevel = Math.floor(Math.log(baseZoomLevel) / Math.log(4));
    if (lat && lon)
      this.initalAgencyLocation = {
        centerPoint: [lat, lon],
        zoomLevel: zoomLevel,
      };
    else
      this.initalAgencyLocation = {
        centerPoint: DEFAULT_MAP_CENTER,
        zoomLevel: DEFAULT_ZOOM_LEVEL,
      };
  }
  @action handleDownloadDataChange = (fromTime, toTime) => {
    this.curentDownloadInfo.from_time = fromTime;
    this.curentDownloadInfo.to_time = toTime;
  };
  /**
   * handleDownloadDataChange
   *
   * update new time range for download raw data
   * @param {moment} fromTime fromTime
   * @param {moment} toTime toTime
   * @return  {null}
   */
  @action setDrawerVisible = (val) => {
    this.drawerVisible = val;
  };
  /**
   *
   * @param {*} data
   */
  @action setExportProgressData = (data) => {
    this.exportProgressData = data;
  };
  @action
  getAllPerlogEvent = () => {
    this.loading = true;
    this.moduleService
      .getAllPerlogEvent()
      .then((data) => {
        runInAction(() => {
          this.listAllEvent = data;
        });
      })
      .catch(() => {})
      .finally(() => {
        runInAction(() => {
          this.loading = false;
        });
      });
  };
  /**
   * downloadRawData
   *
   * @param {fucntion} cb call back after export file done
   * @return  {null}
   */
  @action downloadRawData = (cb, export_type) => {
    this.loading = true;
    let downloadInfo = {
      agency_uuid: this.curentDownloadInfo.agency_uuid,
      int_uuid: this.curentDownloadInfo.int_uuid,
      from_time: this.curentDownloadInfo.from_time.format(
        DATE_FORMAT.date_and_hour_second
      ),
      to_time: this.curentDownloadInfo.to_time.format(
        DATE_FORMAT.date_and_hour_second
      ),
      event_list: this.curentDownloadInfo.formDownload,
      export_type: export_type,
    };
    this.moduleService
      ?.downloadIntersectionRawData(downloadInfo)
      .then(() => {
        cb && cb();
      })
      .catch(() => {
        this.setLoading(false);
      })
      .finally(() => {
        this.setLoading(false);
      });
  };

  /**
   * updateCurrentIndexTab
   *
   * @param {int} indexTab new tab index
   *
   * @return  {null}
   */
  @action updateCurrentIndexTab = (indexTab) => {
    this.currentTab = indexTab;
  };
  /**
   * setSelectedIntersection
   *
   * @param {String} intersectionId intersection Id
   *
   * @return  {null}
   */
  @action setSelectedIntersection = (intersectionId) => {
    this.selectedIntersectionId = intersectionId;
  };
  /**
   * updateAgencyFilterValue
   * this function update filter to get all value of intersections that belong to agency
   * @return  {null}
   */
  @action updateAgencyFilterValue(value) {
    this.filterData.agency_id = value;
  }

  /**
   * getAllAgencies
   * get all agencies belong to current users
   *
   * @return {null}
   */
  @action getAllAgencies = () => {
    this.loading = true;
    let gency_id = this.parentStore?.myStore?.currentAgency?.agency_id;
    if (gency_id == SUPPER_ADMIN_GROUP_AGENCY_ID) {
      this.agencyService?.fetchDataWithoutParams(
        (items) => {
          runInAction(() => {
            this.loading = false;
            this.listAllAgency = items;
            if (Array.isArray(this.listAllAgency) && this.listAllAgency[0]) {
              //set default agency
              let defaultAgencies = this.listAllAgency.find(
                (item) => item.id === this.filterData.agency_id
              );
              if (!defaultAgencies) {
                defaultAgencies = this.listAllAgency[0];
                this.filterData.agency_id = defaultAgencies.id;
              }
              this.setInItalAngencyLocation(
                defaultAgencies?.top_left,
                defaultAgencies?.bottom_right
              );
              this.getData();
            }
          });
        },
        () => {
          runInAction(() => {
            this.loading = false;
          });
        }
      );
    } else {
      this.filterData.agency_id = gency_id;
      this.agencyService?.fetchAgencySetting(gency_id, (response) => {
        runInAction(() => {
          this.setInItalAngencyLocation(
            response.top_left,
            response.bottom_right
          );
        });
        this.getData();
      });
    }
  };
  /**
   * getData
   * this function get all data from server with filter value
   * setpage on loading beforload and clean it after loading done
   * after get the data done: update the total item for paging compoment
   *
   * @return  {null} -
   */
  @action getData = (page = 1, continuousLoadingThreadId = null) => {
    if (page === 1) {
      this.continuousLoadingThreadId = Math.floor(Math.random() * 100);
    }
    this.loading = true;
    let filterData = this.filterData;

    if (this.currentTab == INTERSECTION_TAB_INDEX.INTERSECTION_MAP_TAB) {
      filterData.pagination.current = page;
    } else {
      filterData.top_left = filterData.bottom_right = null;
    }
    this.moduleService?.fetchDataWithParams(
      filterData,
      (items, totalCount) => {
        if (
          continuousLoadingThreadId &&
          this.continuousLoadingThreadId !== continuousLoadingThreadId
        ) {
          return;
        } else {
          runInAction(() => {
            this.loading = false;
            if (
              this.currentTab == INTERSECTION_TAB_INDEX.INTERSECTION_LIST_TAB
            ) {
              this.listData = items;
            } else {
              if (page === 1) {
                this.listData = items;
              } else {
                this.listData = this.listData.concat(items);
              }
              if (totalCount > this.listData.length) {
                continuousLoadingThreadId = this.continuousLoadingThreadId;
                let context = this;
                setTimeout(function () {
                  context.getData(page + 1, continuousLoadingThreadId);
                }, QUERY_DELAY_LOAD_DATA);
              }
            }
            this.filterData.pagination.total = totalCount;
            // this.filterData.pagination.total = 100;
          });
        }
      },
      () => {
        runInAction(() => {
          this.loading = false;
        });
      }
    );
  };

  /**
   * handleFilterDataChange
   * this function will trigger when user input filtering or searching data
   * if filter data is search text and search length >=3 or length == 0 automatic start search
   * @param   {String} target  Field name wanna filter
   * @param   {String} value   Filtering data
   * @param   {Boolean} forceLoad   need to refetch data
   * @return  {null} -  setState of current filter data
   */
  @action handleFilterDataChange = (target, value, forceLoad = true) => {
    if (this.filterData) {
      this.filterData[target] = value;
    }
    if (target === SEARCH_TEXT_FIELD) {
      if (value?.length !== 0 && value?.length <= MAX_NUMBER_START_SEARCH) {
        return;
      }
      if (this.timeoutSearchString) clearTimeout(this.timeoutSearchString);
      if (forceLoad)
        this.timeoutSearchString = setTimeout(() => {
          this.handleFilerAction();
        }, DELAY_SEARCH_STRING);
    } else {
      if (target === AGENCY_KEY_FIELD) {
        let currentAgency = this.listAllAgency.find(
          (item) => item.id === value
        );

        //we will not load the data when agency change on map
        //cuz at this time the new centerpoint not REALLY set on map => current topleft/buttonright is not correct
        //we have to call map change on view to get lastest map position
        if (this.currentTab === INTERSECTION_TAB_INDEX.INTERSECTION_MAP_TAB) {
          forceLoad = false;
          this.setInItalAngencyLocation(
            currentAgency?.top_left,
            currentAgency?.bottom_right
          );
        }
      }
      this.listData = [];
      if (forceLoad) {
        this.handleFilerAction();
      }
    }
  };
}

export default IntersectionStore;
