import { inject, observer } from "mobx-react";
import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";
import ChartComponent from "../ChartComponent";
import "./style.scss";

@inject("rootStore")
@observer
class ChartItem extends PureComponent {
  render() {
    const {
      index,
      style,
      rootStore: {
        chartStore: {
          currentChartTypeFrontEnd,
          chartInfo,
          filterData,
          gapData,

          getCompareChartByIndex,
          getChartByIndex,
        },
      },
      isPrintMode,
    } = this.props;
    let chart = getChartByIndex(index);
    let compareChart = getCompareChartByIndex(index);
    const isCompareMode = chartInfo?.isCompareMode;
    return (
      chart && (
        <div className="ListItem" style={style}>
          <div className="wrap-chart-item">
            <div className="wrap-chart-detail">
              <ChartComponent
                data={chart}
                parentHeight={style.height}
                filterData={filterData}
                chartInfo={chartInfo}
                gapData={gapData}
                isCompareMode={false}
                chartType={currentChartTypeFrontEnd}
                isPrintMode={isPrintMode}
                height={isCompareMode ? "50%" : "100%"}
              />
              {isCompareMode && (
                <ChartComponent
                  data={compareChart}
                  parentHeight={style.height}
                  filterData={filterData}
                  chartInfo={chartInfo}
                  gapData={gapData}
                  isCompareMode={true}
                  chartType={currentChartTypeFrontEnd}
                  isPrintMode={isPrintMode}
                  height="50%"
                />
              )}
            </div>
          </div>
        </div>
      )
    );
  }
}

export default withTranslation()(ChartItem);
