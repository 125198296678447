import ChartDetail from "components/ChartDetail";
import * as d3 from "d3";
import moment from "moment";
import { LEVEL_COLOR } from "utils/constants";
import React, { createRef } from "react";
import PlanData from "./PlanData";
class DetailTrafficProfileChart extends ChartDetail {
  constructor(props) {
    super(props);
    this.value_field = "offset";
    this.data_field = "days";
    this.chartLegend = props.chartLegend;
    this.xAxisLabel = null;
    this.planRef = createRef();
    this.CHART_AXIS_SETTING = [
      {
        position: "left",
        key: "group1",
        label: "Green Demand (Unit %)",
        data: this.props.data.days,
        tick: true,
      },
    ];
    this.TooltipStyle = {
      defaultChartHeight: 18,
      width: 220,
      marginLeft: 15,
      marginTop: 20,
    };
  }
  renderHightLinePoint = (targetObject, x, y) => {
    const tootTipHeight =
      (targetObject.length + 2) * this.TooltipStyle.defaultChartHeight; //2 is space top/bottom
    let chartObject = d3.select(this.chartRef?.current);
    if (this.hightLineObject) {
      this.hightLineObject?.remove();
    }

    if (chartObject) {
      this.hightLineObject = chartObject
        .append("g")
        .attr("class", "hightline-group");
      const xTooltipPosition =
        x < this.fullWidth / 2
          ? this.ChartStyle.marginLeftRight + x + this.TooltipStyle.marginLeft
          : this.ChartStyle.marginLeftRight +
            x -
            this.TooltipStyle.marginLeft -
            this.TooltipStyle.width;
      const yTooltipPosition =
        y < this.fullHeight / 2
          ? y + tootTipHeight / 2 + this.TooltipStyle.marginTop
          : y - tootTipHeight + this.TooltipStyle.marginTop;
      this.hightLineObject
        .append("rect")
        .attr("width", this.TooltipStyle.width) //hard code the width of box
        .attr("height", tootTipHeight)
        .attr("class", "tooltip-box")
        .attr("stroke", "var(--text)")
        .attr("fill", "var(--background)")
        .attr(
          "transform",
          "translate(" + xTooltipPosition + "," + yTooltipPosition + ")"
        );
      //render hightline point

      // let labelHeight = yTooltipPosition;
      let labelIndex = 1.5;
      //add default text of time
      this.hightLineObject
        .append("text")
        .text(
          "Time: " +
            new Date(targetObject[0]?.data?.time)?.toLocaleString("en-US", {
              hour: "numeric",
              minute: "numeric",
              second: "numeric",
              hour12: true,
            })
        ) //@TODO:hard code here
        .attr("text-anchor", "start")
        .attr("class", "tooltip-info-text")
        .attr(
          "transform",
          "translate(" +
            (xTooltipPosition + this.TooltipStyle.marginLeft) +
            "," +
            (yTooltipPosition +
              this.TooltipStyle.defaultChartHeight * labelIndex) +
            ")"
        )
        .attr("fill", "var(--text)");
      labelIndex += 1;
      targetObject.forEach((item) => {
        //find the target point setting
        const setting = this.chartLegend.find((e) => e.key === item.key);
        //find y posistion of point
        //get point value
        const pointValue = item.data.value;
        //get point position
        const yPosistion = this.newYObject[setting.yAxis](pointValue);
        this.hightLineObject
          .append("circle")
          .attr("r", this.HIGHT_LINE_POINT)
          .attr("fill", setting.color)
          .attr("class", "hight-line-point")
          .attr(
            "transform",
            "translate(" +
              (this.ChartStyle.marginLeftRight + x) +
              "," +
              (yPosistion + this.ChartStyle.marginTopBottom) +
              ")"
          )
          .on("mouseout", () => {
            this.handleMouseOut();
          });

        this.hightLineObject
          .append("text")
          .text(
            this.renderTooltipText(
              setting.label,
              setting.unit,
              setting.toolTip(pointValue)
            )
          ) //the way we show tooltip defined on ANALYSIS_PLAN_INFO in constant file
          .attr("text-anchor", "start")
          .attr("class", "tooltip-info-text")
          .attr(
            "transform",
            "translate(" +
              (xTooltipPosition + this.TooltipStyle.marginLeft) +
              "," +
              (yTooltipPosition +
                this.TooltipStyle.defaultChartHeight * labelIndex) +
              ")"
          )
          .attr("fill", setting.color);
        labelIndex += 1;
      });

      //render hight-line infor box
    }
  };
  renderChartBackgroundColor = (planData) => {
    let backgroundGroup = this.chartObject
      .append("g")
      .attr("class", "backgournd-group")
      .style("pointer-events", "none");
    const minX = moment(this.newX.domain()[0]);
    const maxX = moment(this.newX.domain()[1]);
    planData?.forEach((plan) => {
      let fromTime = moment(plan?.from_time);
      let toTime = moment(plan?.to_time);
      if (
        fromTime.isBetween(minX, maxX) ||
        toTime.isBetween(minX, maxX) ||
        (maxX.isBetween(fromTime, toTime) && minX.isBetween(fromTime, toTime))
      ) {
        if (fromTime < minX) {
          fromTime = this.newX.domain()[0];
        }
        if (toTime > maxX) {
          toTime = this.newX.domain()[1];
        }
        let xStart = this.newX(new Date(fromTime)); //get position of from time plan
        let xEnd = this.newX(new Date(toTime)); //get position of to time plan
        const widthPlan = xEnd - xStart; //calculate the plan width

        backgroundGroup
          .append("rect")
          .attr("x", xStart)
          .attr("y", 0)
          .attr("width", widthPlan)
          .attr("height", this.fullHeight)
          .attr("class", "plan-background")
          .style("pointer-events", "none")
          .attr(
            "transform",
            "translate(" +
              this.ChartStyle.marginLeftRight +
              "," +
              this.ChartStyle.marginTopBottom +
              ")"
          )
          .attr("clip-path", "url(#clip)")
          .attr("fill", LEVEL_COLOR[plan.cluster]);
      }
    });

    this.chartObject.selectAll(".backgournd-group").lower();
  };
  findMaxValueInObjectArray = (array) => {
    let maxValue = 0;
    if (Array.isArray(array)) {
      array.forEach((item) => {
        this.chartLegend.forEach((legend) => {
          maxValue = maxValue > item[legend.key] ? maxValue : item[legend.key];
        });
      });
    }
    return maxValue;
  };

  getDataObject = (chartData, item) => {
    let result = [];
    chartData.forEach((e) => {
      result.push({
        time: e.from_time,
        value: e[item.key],
      });
    });
    return result;
  };
  updateChart = (xObj, yObj) => {
    this.newX = xObj;
    this.newYObject = yObj;
    for (var id in yObj) {
      if (id === "left") {
        this.yAxis[id].call(d3.axisLeft(yObj[id]).tickSize(-this.fullWidth));
      } else {
        this.yAxis[id].call(d3.axisRight(yObj[id]));
      }
    }
    for (var id in this.availableLineData) {
      this.availableLineData[id].x = this.newX;
      this.availableLineData[id].y =
        this.newYObject[this.availableLineData[id].yAxis];
    }
    this.xAxis.call(d3.axisBottom(this.newX).tickSize(-this.fullHeight));
    this.planRef.current && this.planRef.current.updateChart(this.newX);
    this.chartObject.selectAll(".backgournd-group").remove();
    this.chartObject.selectAll(".lines-chart-group").remove();
    this.chartObject.selectAll(".gapdata-group").remove();
    this.renderChartBackgroundColor(this.planData);
    this.renderGapDataForChart(this.props.gapdata);
    this.handleMouseOut();
    this.renderChartData();
  };
  componentDidMount() {
    const { data, isPrintMode, gapdata, plan_statistics } = this.props;
    if (!this.data_field && !this.value_field) {
      return;
    }
    //general Info
    const chartData = data[this.data_field];
    this.planData = plan_statistics;
    //Draw Chart Plan
    //draw xAxisObject
    //get plan object from dom
    this.chartObject = d3.select(this.chartRef?.current);
    if (
      Array.isArray(this.planData) &&
      Array.isArray(chartData) &&
      this.chartObject
    ) {
      //calculate the witdh of xAxist bar base on the scren size
      let parentWidth = parseFloat(
        window.getComputedStyle(this.chartObject.node())?.width
      );
      this.fullWidth = parentWidth * 0.9;
      this.ChartStyle.marginLeftRight = parentWidth * 0.05;
      //calculate the height of xAxist bar base on the scren size
      let parentHeight = parseFloat(
        window.getComputedStyle(this.chartObject.node())?.height
      );
      this.ChartStyle.marginTopBottom = parentHeight * 0.05;
      this.fullHeight =
        parentHeight * 0.86 -
        this.ChartStyle.extraFocusHeight -
        this.ChartStyle.marginTopBottom;

      this.ChartStyle.extraFocusPosition = this.fullHeight;
      /// create y scale Object
      const fromTime = chartData[0].from_time;
      const toTime = chartData[[chartData.length - 1]].to_time;
      if (fromTime === toTime)
        this.renderXaxisLineForChart(
          new Date(chartData[0].from_time),
          new Date(chartData[[chartData.length - 1]].from_time)
        );
      else this.renderXaxisLineForChart(new Date(fromTime), new Date(toTime));
      this.renderChartBackgroundColor(this.planData);
      /// create y scale object
      this.renderYaxisLineForChart();

      //generate chart data

      //convert chartData to front-end format
      //note have to run behin renderXaxisLineForChart for xScale Object
      this.tranformChartDataToFronEndDataFormat(chartData, this.planData);

      //note have to run this function first tranformChartDataToFronEndDataFormat
      // this.renderZoomHandle();
      //generate chart crosshair
      if (!isPrintMode) {
        this.renderChartCrossHair(chartData);
      }
      this.renderChartData();
      /// render gap data
      if (gapdata) {
        this.renderGapDataForChart(gapdata);
      } //render zoom handler
    }
  }

  render() {
    const { from_time, to_time, plan_statistics, isPrintMode } = this.props;
    return (
      <div className="traffic-profile-chart-container">
        <div className="chart-plan-container">
          <PlanData
            isPrintMode={isPrintMode}
            ref={this.planRef}
            from_time={from_time}
            to_time={to_time}
            planData={plan_statistics}
          />
        </div>
        <div className="chart-detail-container">{super.render()}</div>
      </div>
    );
  }
}

export default DetailTrafficProfileChart;
