export const NOTIFICATION_TYPE = {
  SUCCESS: "success",
  ERROR: "error",
  WARNING: "warning",
  INFO: "info",
};
export const DYNAMIC_CONTENT_TYPE = {
  TEXT: "TEXT",
  HYPERLINKS: "HYPERLINKS",
};
export const NOTIFICATION_ACTION_TYPE = {
  REDIRECT: "REDIRECT",
  OPEN_POPUP: "OPEN_POPUP",
  OPEN_SIDE_PANEL: "OPEN_SIDE_PANEL",
  OPEN_LINK: "OPEN_LINK",
  REDIRECT_BY_PATH: "REDIRECT_BY_PATH",
};
export const NOTIFICATION_SIDE_PANEL_ACTION = {
  USER_PANEL: "myStore.handleRedirectFromNotification",
  ALARM_RULE_DETAIL: "alarmRuleStore.handleRedirectFromNotification",
  PM_TEMPLATE_DETAIL: "analysisScheduleStore.handleRedirectFromNotification",
  REPORT_TEMPLATE_DETAIL: "reportTemplateStore.handleRedirectFromNotification",
  NOTI_DETAIL: "notificationStore.handleRedirectFromNotification",
};
export const NOTIFICATION_REDIREC_ACTION = {
  REPORT_RESULTS: "/report/result/summary-report",
  PM_RESULTS: "/report/result/performance-metric",
  PM_TEMPLATE_DETAIL: "/report/template/performance-metric",
  ANALYSIS: "/analysis/perfomance-metric",
  ALARM_RECORDS: "/alarms/records",
  NOTIFICATIONS: "/notifications",
  OPTIMIZATION: "/analysis/optimizer",
};
export const SYSTEM_ROUTES_PATH = {
  AlarmRule: "/alarms/rules",
  ReportTemplate: "/report/template/summary-report",
  AnalysisSchedule: "/report/template/performance-metric",
  Notitication: "/notifications",
};
export const NOTIF_STATUS = {
  UNFLAG: "UNFLAG",
  FLAG: "FLAG",
  READ: "READ",
  UNREAD: "UNREAD",
};
