import React, { Component, createRef } from "react";
import DatePicker from "@thuypm/react-multi-date-picker";
import "./style.scss";
import "@thuypm/react-multi-date-picker/styles/colors/green.css";
import DatePanel from "@thuypm/react-multi-date-picker/plugins/date_panel";
import Holidays from "date-holidays";
import { Badge } from "antd";
import { DATE_FORMAT } from "utils/constants";

const holidays = new Holidays("US");

class SiwaveMultipleDatePicker extends Component {
  constructor(props) {
    super(props);
    this.ref = createRef();
    this.toolTipRef = createRef();
  }
  render() {
    return (
      <DatePicker
        format={DATE_FORMAT.day_only_backend}
        ref={this.ref}
        className="green"
        calendarPosition="top-right"
        inputClass={
          this.props["data-__field"]?.errors
            ? "rmdp-input rmdp-input-error"
            : "rmdp-input"
        }
        mapDays={({ date }) => {
          const findHoliday = holidays.isHoliday(date.toDate());
          let title = null;
          if (findHoliday) {
            title = findHoliday.map((e) => e.name).join(", ");
          }
          return {
            children: findHoliday ? (
              <Badge dot>{date.format("D")}</Badge>
            ) : null,
            onMouseOver: (e) => {
              let spanRect = e.target.getBoundingClientRect();
              let calendarRect = this.ref.current
                .querySelector(".rmdp-wrapper")
                .getBoundingClientRect();
              if (title) {
                this.toolTipRef.current.innerHTML = title;
                this.toolTipRef.current.style.transform = `translate(${
                  spanRect.left - calendarRect.left
                }px, ${spanRect.top - calendarRect.top + 30}px)`;
                this.toolTipRef.current.style.visibility = "visible";
              }
            },
            onMouseLeave: () => {
              this.toolTipRef.current.style.visibility = "hidden";
            },
          };
        }}
        value={this.props.value ? this.props.value : []}
        rangeWithExclude
        plugins={[<DatePanel key="plugin" />]}
        {...this.props}
      >
        <span
          ref={this.toolTipRef}
          style={{
            position: "absolute",
            visibility: "hidden",
            zIndex: 105,
            left: 0,
            top: 0,
            backgroundColor: "rgba(0,0,0,.75)",
            border: "1px solid #ccc",
            boxShadow: "0 0 5px #ccc",
            borderRadius: "5px",
            padding: "4px 8px",
            fontSize: "14px",
            color: "white",
          }}
        ></span>
      </DatePicker>
    );
  }
}

export default SiwaveMultipleDatePicker;
