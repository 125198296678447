import React, { Component } from "react";
import { Button, Icon, Modal, Pagination, Select, Spin } from "antd";
// import HorizontalCollapseTable from "../../../../components/HorizontalCollapseTable";
import { TIMEOUT_RESIZE } from "utils/constants";
import "./style.scss";
import CoordCollapseTable from "./CoordCollapseTable";
import { ReactComponent as Logo } from "../../../../svgs/logo.svg";

const PAGE_SIZE = 10;
// @inject("rootStore")
// @observer
class BaseCollapseData extends Component {
  /**
   * contructor of component
   * @param   {object} props  props of component
   */
  timeoutResize = null;

  constructor(props) {
    super(props);
    this.tooltipKeyDescription = "";
    this.timeoutFilter = null;
    this.filterInput = [];
    this.state = {
      resizing: false,
      maxHeight: 500,
      currentPage: 1,
      currentPatternFilter: [],
      helpModalOpen: false,
    };
    this.collapseField = {
      key: "pattern_number",
      label: "Pattern Number",
    };
    this.desRef = React.createRef();
  }

  handleOpenModal = () => {
    this.setState({ ...this.state, helpModalOpen: true });
  };

  handleCloseModal = () => {
    this.setState({ ...this.state, helpModalOpen: false });
  };

  /**
   * componentDidMount
   * clear all error list
   */
  componentDidMount() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  getPartenFilter = () => {
    return [];
  };
  getTableColumn = () => {
    return [];
  };

  handleResize = () => {
    this.setState({ resizing: true });
    let tabsHeight = document.getElementsByClassName(
      "intersection-config-tabs"
    )[0]?.offsetHeight;
    let headerStickyHeight =
      document.getElementsByClassName("ant-tabs-top-bar")[0]?.offsetHeight +
      this.desRef.current?.offsetHeight;
    if (this.timeoutResize) clearTimeout(this.timeoutResize);
    this.timeoutResize = setTimeout(() => {
      this.setState({
        resizing: false,
        maxHeight: tabsHeight - headerStickyHeight - 90,
      });
    }, TIMEOUT_RESIZE);
  };
  handlePaginate = (paginate) => {
    this.setState({
      currentPage: paginate,
    });
  };

  handleFilterData(array) {
    let convertArray = array?.map((e) => parseInt(e));
    this.setState({
      currentPatternFilter: convertArray,
      currentPage: 1,
    });
  }

  transformTableData = () => {
    return [];
  };

  filterData = () => {
    let data = this.transformTableData();
    if (this.state.currentPatternFilter && this.state.currentPatternFilter.length > 0) {
      data = data.filter((e) => this.state.currentPatternFilter.includes(e.pattern_number));
    }

    this.total = data.length;
    return data.slice(
      (this.state.currentPage - 1) * PAGE_SIZE,
      this.state.currentPage * PAGE_SIZE > data.length
        ? data.length
        : this.state.currentPage * PAGE_SIZE
    );
  }

  render() {
    const { t, } = this.props;

    return (
      <div>
        <Modal
          visible={this.state.helpModalOpen}
          onCancel={this.handleCloseModal}
          closable={false}
          width="800px"
          title={<Logo className="logo-img" style={{ width: 120 }}/>}
          footer={[
            <Button
              key="close"
              onClick={this.handleCloseModal}
            >
              OK
            </Button>
          ]}
        >
          <div>
            <p><b>Coord Data Details</b></p>
            <p><b>Is coord phase: </b> {t('intersection_config.coord_data_description')}</p>
            <p><b>Cycle length: </b> {t('intersection_config.cycle_length_description')}</p>
            <p><b>Offset time: </b> {t('intersection_config.offset_time_description')}</p>
            <p><b>Sequence number: </b> {t('intersection_config.sequence_number_description')}</p>
            <p><b>Split time: </b> {t('intersection_config.split_mode_description')}</p>
          </div>
        </Modal>
        <div className="flex justify-space-beetwen " ref={this.desRef}>
          <p style={{ maxWidth: "70%" }}>
            <Icon type="info-circle" style={{ marginRight: 5 }}/>
            {t(this.tooltipKeyDescription)}
            <Button
              type="link"
              onClick={this.handleOpenModal}
              size="small"
            >
              More details on coord data
            </Button>
          </p>
          <div>
            <Select
              mode="multiple"
              style={{ width: "350px" }}
              onChange={(e) => {
                this.filterInput = e;
                if (this.timeoutFilter) clearTimeout(this.timeoutFilter);
                this.timeoutFilter = setTimeout(() => {
                  this.handleFilterData(this.filterInput);
                }, 1000);
              }}
              placeholder={t("intersection_config.filter_pattern")}
            >
              {this.getPartenFilter()}
            </Select>
          </div>
        </div>
        <div className="flex justify-center">
          {this.state.resizing ? (
            <Spin spinning size="large"/>
          ) : (
            <div className="w-full">
              <CoordCollapseTable
                tableColumn={this.getTableColumn()}
                data={this.filterData()}
                collapseField={this.collapseField}
                maxHeight={this.state.maxHeight}
                highlightRowKey={this.collapseField.key}
              />
              <Pagination
                style={{ marginTop: "8px" }}
                onChange={this.handlePaginate}
                defaultCurrent={this.state.currentPage}
                pageSize={PAGE_SIZE}
                total={this.total}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default BaseCollapseData;
