import React, { Component } from "react";
import {
  Col,
  DatePicker,
  Form,
  Icon,
  InputNumber,
  Radio,
  Row,
  TimePicker,
  Tooltip,
  Typography,
} from "antd";
import { inject, observer } from "mobx-react";
import moment from "moment";
import { withTranslation } from "react-i18next";
import {
  DATE_FORMAT,
  DATE_RAGNGE_SELECT,
  TIME_PICKER_FORMAT,
} from "utils/constants";

@inject("rootStore")
@observer
class AbnormalSubForm extends Component {
  constructor(props) {
    super(props)
  }

  renderDates = () => {
    const {
      form: { getFieldValue },
      rootStore: {
        analysisScheduleStore: {
          formData: { general_data },
        },
      },
    } = this.props

    const offsetDays = getFieldValue('general_data.date_range.offset');
    if (!offsetDays) return;

    const chosenDate = general_data.date_range.target_date;
    const datesComponent = [];

    for (let i=0; i<offsetDays; i+=1) {
      datesComponent.push(
        <div key={i}>
          <Typography.Text style={{fontSize: "12px", color: "#009999"}}>
            {`${i+1}` + ": " + "" + `${moment(chosenDate).subtract(i+1, 'week').format('dddd, LL')}`}
          </Typography.Text>
        </div>
      )
    }

    return(datesComponent)
  }

  componentDidMount() {
    const {
      rootStore: {
        analysisScheduleStore: { setFormField },
      },
    } = this.props

    setFormField("general_data.week_days", []);
    setFormField("general_data.date_range.unit", 'WEEK');
    setFormField("general_data.date_range.start_date", null);
    setFormField("general_data.date_range.end_date", null);
  }

  render() {
    const {
      t,
      form: { getFieldDecorator, validateFields, resetFields, getFieldValue },
      rootStore: {
        analysisScheduleStore: {
          formData: { general_data },
        },
      },
      onChangeDateScope,
      checkVaildTime,
      checkToTime,
      formItemLayout,
      onChangeField,
    } = this.props;
    const moduleName = "report_template";

    return(
      <>
        <Col lg={24}>
          <Form.Item
            className="label"
            label={t(moduleName + ".select_date_range")}
            {...formItemLayout}
            style={{ marginBottom: 0 }}
          >
            {getFieldDecorator("general_data.date_range.scope", {
              rules: [],
              initialValue: (general_data.date_range.scope === "FIXED") ? (DATE_RAGNGE_SELECT.FIXED.value) : (DATE_RAGNGE_SELECT.RELATIVE.value),
              getValueFromEvent: (e) => {
                return onChangeDateScope(
                  "general_data.date_range.scope",
                  e.target.value
                );
              },
            })(
              <Radio.Group name="radiogroup">
                <Radio value={DATE_RAGNGE_SELECT.RELATIVE.value}>
                  {DATE_RAGNGE_SELECT.RELATIVE.label}
                </Radio>
                <Radio value={DATE_RAGNGE_SELECT.FIXED.value}>
                  {DATE_RAGNGE_SELECT.FIXED.label}
                </Radio>
              </Radio.Group>
            )}
          </Form.Item>
        </Col>
        <Col lg={24} md={24}>
          {getFieldValue('general_data.date_range.scope') === DATE_RAGNGE_SELECT.FIXED.value ? (
            <Form.Item>
              <DatePicker
                format={DATE_FORMAT.day_only_backend}
                allowClear={false}
                onChange={(value) =>
                  onChangeField("general_data.date_range.target_date", value.format(DATE_FORMAT.day_only_backend))
                }
                value={(general_data?.date_range?.target_date) ? moment(general_data?.date_range?.target_date) : null}
                showToday={false}
              />
            </Form.Item>
          ) : (
            <Row className="mt-16 mb-16">
              <Icon
                className="info-tooltip-icon"
                type="info-circle"
              />
              <Typography.Text className="ml-8">
                {t(moduleName + ".relative_description")}
              </Typography.Text>
            </Row>
          )}
        </Col>
        <Col span={24}>
          <Row>
            <Col span={6}>
              <Form.Item label={t("analysis.from_time")}>
                {getFieldDecorator(
                  "general_data.metadata.from_time",
                  {
                    rules: [
                      {
                        required: true,
                        message: t("analysis.from_time_required"),
                      },
                      { validator: checkVaildTime },
                    ],
                    getValueFromEvent: (value) => {
                      onChangeField(
                        "general_data.metadata.from_time",
                        value.format(DATE_FORMAT.hour)
                      );
                      resetFields(["general_data.metadata.to_time"]);
                      validateFields([
                        "general_data.metadata.to_time",
                      ]);
                      return value;
                    },
                    initialValue: general_data.metadata.from_time
                      ? moment(
                          general_data.metadata.from_time,
                          DATE_FORMAT.hour
                        )
                      : null,
                  }
                )(
                  <TimePicker
                    className="w-full"
                    placeholder={t("analysis.from_time_placeholder")}
                    format={TIME_PICKER_FORMAT}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label={t("analysis.to_time")}>
                {getFieldDecorator("general_data.metadata.to_time", {
                  rules: [
                    {
                      required: true,
                      message: t("optimization.to_time_required"),
                    },
                    { validator: checkToTime },
                  ],
                  initialValue: general_data.metadata.to_time
                    ? moment(
                        general_data.metadata.to_time,
                        DATE_FORMAT.hour
                      )
                    : null,
                  getValueFromEvent: (value) => {
                    onChangeField(
                      "general_data.metadata.to_time",
                      value.format(DATE_FORMAT.hour)
                    );
                    resetFields(["general_data.metadata.from_time"]);
                    validateFields([
                      "general_data.metadata.from_time",
                    ]);
                    return value;
                  },
                })(
                  <TimePicker
                    className="w-full"
                    placeholder={t("analysis.to_time_placeholder")}
                    format={TIME_PICKER_FORMAT}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={20}>
          <Form.Item
            className="label"
            label={t(moduleName + ".compare_previous")}
            {...formItemLayout}
            style={{marginBottom: "5px"}}
          >
            {getFieldDecorator(
              "general_data.date_range.offset",
              {
                rules: [
                  {
                    required: true,
                    message: t(
                      moduleName + ".date_range_offset_required"
                    ),
                  },
                ],
                initialValue: general_data?.date_range.offset,
                getValueFromEvent: (e) => {
                  return onChangeField(
                    "general_data.date_range.offset",
                    e
                  );
                },
              }
            )(
              <InputNumber
                min={1}
                max={5}
              />
            )}
            &nbsp;
            <Tooltip
              placement="right"
              title={
                t(moduleName + ".tooltip.compare_previous_example")
              }
            >
              <Icon
                className="info-tooltip-icon"
                type="info-circle"
              />
            </Tooltip>
          </Form.Item>
        </Col>
        <Col lg={24} md={24}>
          {getFieldValue('general_data.date_range.scope') === DATE_RAGNGE_SELECT.FIXED.value ? (
            this.renderDates()
          ) : (
            null
          )}
        </Col>
      </>
    )
  }
}

export default withTranslation()(AbnormalSubForm);