import React, { Component, createRef } from "react";
// import RingChart from "./RingChart/index";
import { withTranslation } from "react-i18next";
import SplitMonitorEvents from "./SplitMonitorEvents";
import moment from "moment";
import RingChartActual from "./RingChart/RingChartActual";
import RingChartTimeline from "./RingChart/RingChartTimeline";
import SplitMonitorRuler from "./SplitMonitorRuler";

class SplitMonitorActual extends Component {
  constructor(props) {
    super(props)
    this.chartRef = createRef();
    this.state = {
      height: null,
    }
  }

  calculateDataHeight = () => {
    const {data} = this.props;
    const dataLength = data.cycles.length;
    const chartHeight = 125;

    this.setState({height: dataLength * chartHeight})
  }

  calculateChartWidth = (cycle) => {
    const chartScale = 445/120000
    const cycleDuration = moment(cycle.end_time) - moment(cycle.start_time);
    const width = 180 + 300 + cycleDuration * chartScale

    return width;
  }

  componentDidMount() {
    this.calculateDataHeight();
  }

  render() {
    const {t, data} = this.props;
    // const dataLength = data.cycles.length;

    return(
      <div style={{height: "100%"}}>
        <div style={{display: "flex", justifyContent: "space-between"}}>
          <div style={{display: "flex", gap: "25px", padding: "20px 0px"}}>
            <div className="font-bold">
              {t("analysis.actual_cycle")}
            </div>
            <div> 
              {t("analysis.number_of_cycles")} 
              <span className="font-bold">{data.no_cycle}</span> 
            </div>
            <div>
              {t("analysis.min_cycle_length")} 
              <span className="font-bold">{data.min_cycle_length}s</span> 
            </div>
            <div>
              {t("analysis.max_cycle_length")}
              <span className="font-bold">{data.max_cycle_length}s</span>
            </div>
            <div>
              {t("analysis.number_skipped")}
              <span className="font-bold">{data.no_skipped_phase}</span>
            </div>
            <div>
              {t("analysis.total_time_skipped")}
              <span className="font-bold">{data.skipped_phase_duration}s</span>
            </div>
          </div>

          <SplitMonitorEvents />
        </div>

        <div style={{width: "100%", height: "100%", display:"flex", flexDirection:"column"}}>
          <div style={{paddingBottom: "5px", fontWeight: 700}}>{t("analysis.by_time_range")}</div>
          <div style={{overflowX: "auto", paddingBottom: "5px", position:"relative", flexShrink:0, marginBottom: "10px"}}>
            <RingChartTimeline width={this.calculateChartWidth({start_time: data.from_time, end_time: data.to_time})} cycleNumber={0} data={data.timeline} from_time={data.from_time} to_time={data.to_time} />
          </div>

          <div style={{height: "35px", paddingBottom: "5px", position: "relative", display:"flex", overflowX:"hidden", flexShrink:0, fontWeight: 700}}>
            {data?.cycles?.length ? t("analysis.by_cycle") : null}
            <SplitMonitorRuler />
          </div>
          <div ref={this.chartRef} style={{height: "100%", overflowY:"auto", display:"flex", flexDirection:"column", gap: "20px"}}>
            {
              data.cycles.map((cycle, index) => {
                
                const cycleWidth = this.calculateChartWidth(cycle);

                {/* if (index !== dataLength-1) {
                  return(
                    <div style={{overflowX: "auto", paddingBottom: "5px", position:"relative", flexShrink:0}}>
                      <RingChartActual width={cycleWidth} isLastCycle={false} lastCycleEnds="" key={index} cycleNumber={index+1} data={cycle} />
                    </div>
                  ) 
                } else {
                  console.log(cycle.end_time)
                  return(
                    <div style={{overflowX: "auto", paddingBottom: "5px", position:"relative", flexShrink:0}}>
                      <RingChartActual width={cycleWidth} isLastCycle={true} lastCycleEnds={cycle.end_time} key={index} cycleNumber={index+1} data={cycle} />
                    </div>
                  )  
                } */}

                return(
                  <div key={index} style={{overflowX: "auto", paddingBottom: "5px", position:"relative", flexShrink:0}}>
                    <RingChartActual width={cycleWidth} isLastCycle={false} lastCycleEnds="" key={index} cycleNumber={index+1} data={cycle} />
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation()(SplitMonitorActual);