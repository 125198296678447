import React, { Component } from "react";
import { Form, Radio, Checkbox, Spin } from 'antd'
import { inject } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import "./style.scss";

@inject('rootStore')
class AlarmFrequency extends Component {
  constructor(props) {
    super(props)
    
    this.state = {
      loading: true
    }

    this.formFields = ["alarm_enabled", "alarm_frequency"]
  }

  /**
  * componentDidMount
  * 
  * This function does 2 things:
  * 1. Binding this component to the childRef function that was passed down
  * 2. Get the current alarm summary settings
  *  
  * @return  {null} -
  */
  componentDidMount() {
    const {
      rootStore: { 
        myStore,
      },
      childRef
    } = this.props;
    
    //Using the childRef function passed down from UserSidePane:
    childRef(this);
    
    //Getting the current alarm summary settings:
    myStore.getAlarmSummaryFrequency(this.setLoadingFalse);
  }

  isFormChanged = () => {
    return this.props.form?.isFieldsTouched(this.formFields);
  };

  setLoadingFalse = () => {
    this.setState({loading: false});
  }

  handleSubmit = () => {
    const {
      rootStore: { myStore },
    } = this.props;
    this.props.form.validateFields((err, values) => {
      if (!err) {
        
        let submitValue = {
          as_enabled: values.alarm_enabled,
          as_frequency: values.alarm_frequency
        };
        
        myStore?.changeAlarmSummaryFrequency(
          submitValue,
          () => {
            myStore?.setVisibleUserSidePanel(false);
          },
          (errors) => {
            errors?.map((error) => {
              let message = error?.message;
              let err = {};
              err[error?.field] = {
                value: values[error?.field],
                errors: [new Error(message)],
              };
              this.props.form?.setFields(err);
            });
          }
        );
      }
    });
  };

  render() {
    const { loading } = this.state;
    const {
      form: {
        getFieldDecorator,
        getFieldValue
      },
      rootStore: { myStore },
      t,
    } = this.props;
    
    return(
      <div>
        {loading ? (
          <Spin className="alarm-frequency-spinner"/>
        ) : (
          <Form>
            <Form.Item label={t("profile.alarm_enabled")}>
              {getFieldDecorator("alarm_enabled", {
                valuePropName: "checked",
                initialValue: myStore?.alarmSummaryFrequency?.as_enabled,
              })(
                <Checkbox>
                  {t("profile.alarm_enabled_opt_in")}
                </Checkbox>
              )}
            </Form.Item>
            {
              getFieldValue("alarm_enabled") && (
                <Form.Item label={t("profile.alarm_frequency")}>
                  {getFieldDecorator("alarm_frequency", {
                    initialValue: myStore?.alarmSummaryFrequency?.as_frequency,
                    rules: [{ required: true, message: t("profile.alarm_frequency_required") }],
                  })(
                    <Radio.Group>
                      <Radio value={"DAILY"}>
                        {t("report_template.daily")}
                      </Radio>
                      <Radio value={"WEEKLY"}>
                        {t("report_template.weekly")}
                      </Radio>
                      <Radio value={"MONTHLY"}>
                        {t("report_template.monthly")}
                      </Radio>
                    </Radio.Group>
                  )}
                </Form.Item>
              )
            }
          </Form>
        )}
      </div>
    )
  }
};

const AlarmFrequencyTabForm = Form.create({name: "alarm-frequency-form"})(AlarmFrequency)

export default withTranslation()(AlarmFrequencyTabForm);