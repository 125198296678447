import React, { Component } from "react";

class CollapseComponent extends Component {
  toggleCollapse = (event) => {
    event.target.parentElement?.classList?.toggle("collapsed");
  };

  render() {
    const { children, className, width, height } = this.props;
    return (
      <div className={(width < 999 || height < 884 ? "collapsable collapsed" : "collapsed")}>
        <div className={(className || "") + " collapsable-content"}>
          {children}
        </div>
        <div className="expander" onClick={this.toggleCollapse} />
      </div>
    );
  }
}

export default CollapseComponent;
