import { FONT_STRING_CAVAS } from "utils/constants/optimizerConstant";
import BaseLeftContent from "../BaseHistoricTimeSpaceChart/BaseLeftContent";
class LeftContent extends BaseLeftContent {
  constructor(props) {
    super(props);
  }

  drawIntersectionInfo = () => {
    const { width, dataSourceIntersection } = this.props;
    dataSourceIntersection?.forEach((int) => {
      this.ctx.beginPath();
      this.ctx.textAlign = "left";
      this.ctx.font = `20px ${FONT_STRING_CAVAS}`;
      this.ctx.textBaseline = "top";
      const startY = 32;
      this.ctx.fillText(int.int_name, 20, this.yScale(int.coordY) + startY);
      this.ctx.font = `14px ${FONT_STRING_CAVAS}`;
      this.ctx.fillText(
        "Distance: " + int.distance + "(feet)",
        20,
        this.yScale(int.coordY) + startY + 44
      );
      this.ctx.closePath();
    });
    dataSourceIntersection?.forEach((int) => {
      this.ctx.save();
      this.ctx.translate(width - 60, this.yScale(int.coordY) + 42);
      this.ctx.beginPath();
      this.ctx.fillText("1", 0, 0);
      this.ctx.fillText("2", 0, 26);
      this.ctx.rotate(-0.5 * Math.PI);
      this.ctx.fillText("Ring", -32, -20);
      this.ctx.closePath();
      this.ctx.restore();
    });
  };
}

export default LeftContent;
