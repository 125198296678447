import { Icon, Spin } from "antd";
import HorizontalTable from "components/HorizontalTable";
import React, { Component } from "react";
import { TIMEOUT_RESIZE } from "utils/constants";
import "./style.scss";

class BasePatternData extends Component {
  /**
   * contructor of component
   * @param   {object} props  props of component
   */
  subDescription;
  timeoutResize = null;

  constructor(props) {
    super(props);
    this.state = {
      resizing: false,
      maxHeight: 500,
    };
    this.highlightRowKey = "pattern_number";
    this.desRef = React.createRef();
  }

  componentDidMount() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  getTableColumn = () => {
    return [
      {
        key: "pattern_number",
        label: "Pattern Number",
      },
      {
        key: "seconds",
        label: "Seconds",
      },
    ];
  };
  handleResize = () => {
    this.setState({ resizing: true });
    let tabsHeight = document.getElementsByClassName(
      "intersection-config-tabs"
    )[0]?.offsetHeight;
    let headerStickyHeight =
      document.getElementsByClassName("ant-tabs-top-bar")[0]?.offsetHeight +
      this.desRef.current?.offsetHeight;
    if (this.timeoutResize) clearTimeout(this.timeoutResize);
    this.timeoutResize = setTimeout(() => {
      this.setState({
        resizing: false,
        maxHeight: tabsHeight - headerStickyHeight - 70,
      });
    }, TIMEOUT_RESIZE);
  };
  transformTableData = () => {
    return [];
  };

  render() {
    const { t } = this.props;
    return (
      <div>
        <div ref={this.desRef}>
          {this.subDescription && (
            <div>
              <Icon type="info-circle" style={{ marginRight: 16 }}/>
              {this.subDescription}
            </div>
          )}
          <p>
            <Icon type="info-circle" style={{ marginRight: 16 }}/>
            {t(this.tooltipKeyDescription)}
          </p>
        </div>
        <div className="flex items-center justify-center h-full">
          {this.state.resizing ? (
            <Spin spinning size="large"/>
          ) : (
            <HorizontalTable
              tableColumn={this.getTableColumn()}
              data={this.transformTableData()}
              maxHeight={this.state.maxHeight}
              highlightRowKey={this.highlightRowKey}
            />
          )}
        </div>
      </div>
    );
  }
}

export default BasePatternData;
