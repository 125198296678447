import React, { Component } from "react";
import { WidthProvider, Responsive } from "react-grid-layout";
import { Button, Col, Icon, Menu, Row, Typography } from "antd";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import "./style.scss";
import {
  DASHBOARD_DEFAULT_LAYOUT,
  DASHBOARD_WIDGETS,
  WIDGET_MENU_ITEMS,
} from "../../utils/constants/dashboardConstant";
import HeatMap from "../../containers/Home/HeatMap";
import TopTen from "../../containers/Home/TopTen";
import InfoAlarmMessage from "../../containers/Home/InfoAlarmMessage";
import { withTranslation } from "react-i18next";
import RightSideBar from "../RightSideBar";
import SummaryReport from "../../containers/Home/SummaryReport";
import VolumeCounts from "../../containers/Home/VolumeCounts";
import Notification from "../../containers/Home/Notification";
import SpinLoading from "../PrivateLayout/SpinLoading";

const ResponsiveReactGridLayout = WidthProvider(Responsive);

class WidgetSample extends Component {
  static defaultProps = {
    name: "",
    image: "",
    description: "",
  };

  constructor(props) {
    super(props);
    this.state = {
      showMore: false,
    };
  }

  componentDidMount() {
    const element = document.getElementsByClassName("text-description");
    for (let i = 0; i < element.length; i++) {
      if (element[i].offsetHeight > 63) {
        element[i].classList.add("show-all");
        document
          .getElementsByClassName("btn-show-all")
          [i].classList.remove("d-none");
      }
    }
    this.setState({ showMore: true });
  }

  onShowAll = (e) => {
    e.stopPropagation();
    const { showMore } = this.state;
    this.setState({
      showMore: !showMore,
    });
  };

  render() {
    const { image, name, description, onClick, disabled } = this.props;
    const { showMore } = this.state;
    return (
      <div
        type="default"
        className={`grid-widget-sample ${
          disabled ? "grid-widget-disabled" : ""
        }`}
        onClick={onClick}
        disabled={disabled}
      >
        <Row style={{ height: "100%" }}>
          <Col span={6} style={{ height: "100%", overflow: "hidden" }}>
            <img src={image} alt={name} />
          </Col>
          <Col span={18} style={{ padding: "0 12px" }}>
            <Typography.Title level={4}>{name}</Typography.Title>
            <Typography.Paragraph className="description">
              <p
                className={
                  showMore ? "text-description show-all" : "text-description"
                }
              >
                {description}
              </p>
              <span className="btn-show-all d-none" onClick={this.onShowAll}>
                {showMore ? "See more" : "See less"}
              </span>
            </Typography.Paragraph>
          </Col>
        </Row>
      </div>
    );
  }
}

class GridWidgetLayout extends Component {
  static defaultProps = {
    className: "layout",
    editing: false,
    rowHeight: 50,
    refItem: {},
    toggleEditing: () => {},
    toggleAdding: () => {},
    toggleSetting: () => {},
    onChangeLayout: () => {},
    onSaveLayout: () => {},
    onCancelEdit: () => {},
    availableWidgets: [],
    widgetConfigurations: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedItem: null,
    };
  }

  onClickMenu = (index, action) => {
    if (action === WIDGET_MENU_ITEMS.SETTING) {
      this.selectItem(index);
    }

    if (action === WIDGET_MENU_ITEMS.DELETE_WIDGET) {
      this.onRemoveItem(index);
      this.props.onSaveLayout();
    }
  };

  createElement = (el, component) => {
    const { t, editing } = this.props;
    const { selectedItem } = this.state;

    return (
      <div
        key={el.i}
        data-grid={el}
        className={
          selectedItem && selectedItem.i === el.i ? "grid-selected-item" : ""
        }
      >
        {component}
        {editing ? (
          <Button
            className="item-menu"
            type="link"
            onClick={() => this.onRemoveItem(el.i)}
          >
            <Icon type="close" />
          </Button>
        ) : (
          <Menu
            className="item-menu"
            mode="horizontal"
            onClick={({ key }) => this.onClickMenu(el.i, key)}
          >
            <Menu.SubMenu title={<Icon type="ellipsis" />}>
              <Menu.Item
                className="menu-item-grid"
                key={WIDGET_MENU_ITEMS.DELETE_WIDGET}
              >
                <Icon type="delete" />
                {t("dashboard.remove_widget")}
              </Menu.Item>
              <Menu.Item
                className="menu-item-grid"
                key={WIDGET_MENU_ITEMS.SETTING}
              >
                <Icon type="setting" />
                {t("dashboard.widget_setting")}
              </Menu.Item>
            </Menu.SubMenu>
          </Menu>
        )}
      </div>
    );
  };

  renderWidgetSample = (widget) => {
    const { t, layouts } = this.props;
    const disabled = layouts.findIndex((el) => el.i === widget.key) >= 0;

    return (
      <WidgetSample
        key={widget.key}
        name={t(widget.name)}
        image={widget.image}
        description={t(widget.description)}
        onClick={() => this.onAddItem(widget)}
        disabled={disabled}
      />
    );
  };

  renderGridLayout = (layout) => {
    const { editing, refItem } = this.props;

    switch (layout.i) {
      case DASHBOARD_WIDGETS.HEATMAP:
        return this.createElement(
          layout,
          <HeatMap
            ref={refItem[DASHBOARD_WIDGETS.HEATMAP]}
            skeleton={editing}
          />
        );
      case DASHBOARD_WIDGETS.TOP_TEN:
        return this.createElement(layout, <TopTen skeleton={editing} />);
      case DASHBOARD_WIDGETS.INFO_ALARM_MESSAGE:
        return this.createElement(
          layout,
          <InfoAlarmMessage skeleton={editing} />
        );
      case DASHBOARD_WIDGETS.SUMMARY_REPORT:
        return this.createElement(layout, <SummaryReport skeleton={editing} />);
      case DASHBOARD_WIDGETS.VOLUME_COUNT:
        return this.createElement(layout, <VolumeCounts skeleton={editing} />);
      case DASHBOARD_WIDGETS.NOTIFICATION:
        return this.createElement(layout, <Notification skeleton={editing} />);
      default:
        return null;
    }
  };

  selectItem = (i) => {
    const { layouts, toggleSetting } = this.props;
    this.setState({ selectedItem: layouts.find((el) => el.i === i) });
    toggleSetting(true);
  };

  onAddItem = (widget) => {
    const { layouts, onChangeLayout } = this.props;
    const newWidget = DASHBOARD_DEFAULT_LAYOUT.find(
      (el) => el.i === widget.key
    );
    const isExisted = layouts.find((el) => el.i === widget.key);

    if (!isExisted && newWidget) {
      onChangeLayout(layouts.concat([newWidget]));
    }
  };

  onRemoveItem = (i) => {
    const { layouts, onChangeLayout } = this.props;
    onChangeLayout(layouts.filter((el) => i !== el.i));
  };

  onChange = (layouts) => {
    const { onChangeLayout } = this.props;
    onChangeLayout(layouts);
  };

  onCloseSetting = () => {
    this.setState({ selectedItem: null });
  };

  renderWidgetSetting = () => {
    const { selectedItem } = this.state;
    const { widgetConfigurations } = this.props;

    const SettingComponent = widgetConfigurations[selectedItem?.i];

    if (SettingComponent)
      return <SettingComponent onClose={this.onCloseSetting} />;

    return null;
  };

  render() {
    const {
      t,
      loading,
      editing,
      adding,
      availableWidgets,
      layouts,
      rowHeight,
      toggleAdding,
    } = this.props;

    if (loading) {
      return (
        <div style={{ width: "100%", height: "90vh" }}>
          <SpinLoading visible={true} />
        </div>
      );
    }
    return (
      <>
        <div style={{ flex: 1 }}>
          <ResponsiveReactGridLayout
            className="layout"
            layout={this.state.items}
            onLayoutChange={this.onChange}
            verticalCompact={true}
            breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
            preventCollision={false}
            cols={{ lg: 24, md: 24, sm: 24, xs: 24, xxs: 24 }}
            autoSize={true}
            margin={{
              lg: [12, 12],
              md: [12, 12],
              sm: [12, 12],
              xs: [12, 12],
              xxs: [12, 12],
            }}
            rowHeight={rowHeight}
            isDraggable={editing}
            isResizable={editing}
          >
            {layouts.map(this.renderGridLayout)}
          </ResponsiveReactGridLayout>
        </div>
        <RightSideBar
          visible={adding}
          title={t("dashboard.add_widgets")}
          onClose={() => toggleAdding(false)}
        >
          {availableWidgets.map(this.renderWidgetSample)}
        </RightSideBar>
        {this.renderWidgetSetting()}
      </>
    );
  }
}

export default withTranslation()(GridWidgetLayout);
