import AgencyService from "../services/AgencyService";
import BaseStore from "./BaseStore";
import { action, runInAction, observable } from "mobx";
import {
  RESOURCE_LIST,
  DEFAULT_MAP_CENTER,
  DEFAULT_COLOR_MATRIX,
  ACTIONS_LIST,
} from "utils/constants";
import i18n from "../../src/i18n";
import helper, {showNotification} from "../utils/helper";
/**
 * Store for user management.
 *
 * @class
 */

class AgencySettingStore extends BaseStore {
  MAX_ITEM_PER_MATRIX = 5; // max number of level per matrix
  @observable currentCenterPoint = DEFAULT_MAP_CENTER; //agency center point
  @observable agencyColorMatrix = []; //agency center point
  @observable selectedMatrix = {}; //current agency matrix
  @observable timezone = "";
  defaultColorMatrix = DEFAULT_COLOR_MATRIX; //default color matrix
  @observable currentAgencySetting = {
    location: {
      lat: null,
      lon: null,
    },
    display: {},
    timezone: null,
    top_left: {
      lat: null,
      lon: null,
    },
    bottom_right: {
      lat: null,
      lon: null,
    },
    alarm_categories: [],
    saturation: {
      straight: null,
      left: null,
      right: null,
      straight_left: null,
      straight_right: null,
    },
    show_unavailable_map_data: false,
  }; //current agency setting ( for saving back to server)

  constructor(agencyId, parentStore) {
    super(new AgencyService(parentStore), parentStore);
    this.agencyId = agencyId; //set current agency
    this.resourceName = RESOURCE_LIST.AGENCY;
  }
  /**
   * getAgencySettings
   * this function get current agency setting
   * @param   {Array} data  list id of agencies, default null
   * @return  {null} -
   */
  @action getAgencySettings = () => {
    this.loading = true;
    return new Promise((resolve, reject) => {
      this.moduleService?.fetchAgencySetting(
        this.agencyId,
        (response) => {
          runInAction(() => {
            this.loading = false;
            this.errorList = null;
            this.timezone = response.timezone;
            if (response.location) {
              //get agency location from setting response - default using local const
              this.currentCenterPoint = [
                response.location.lat
                  ? response.location.lat
                  : DEFAULT_MAP_CENTER[0],
                response.location.lon
                  ? response.location.lon
                  : DEFAULT_MAP_CENTER[1],
                ,
              ];
            }
            //transform display(color matrix) to front-end format
            if (response.display) {
              let colorDisplayList = Object.keys(response.display);

              if (Array.isArray(colorDisplayList)) {
                //add key of each matrix to item.key( for binding and checking)
                this.agencyColorMatrix = colorDisplayList.map((item, index) => {
                  let colorMatrix = null;
                  if ((colorMatrix = response.display[item])) {
                    colorMatrix.key = item;
                  }
                  //set default selected color matrix for the first item
                  if (index === 0) {
                    this.selectedMatrix = colorMatrix;
                  }
                  return colorMatrix;
                });
              }
            }
            this.currentAgencySetting = response;
            resolve(response);
          });
        },
        (errors) => {
          runInAction(() => {
            this.loading = false;
            this.errorList = null;
          });
          reject(errors);
        }
      );
    });
  };
  @action handleChangeAlarmCategory(value, action) {
    if (action === ACTIONS_LIST.ADD) {
      let pushValue = {
        id: null,
        name: value,
        is_default: false,
      };
      if (
        !this.currentAgencySetting?.alarm_categories?.find(
          (e) => e.name === value
        )
      )
        this.currentAgencySetting?.alarm_categories?.push(pushValue);
    } else {
      let filterArray = this.currentAgencySetting?.alarm_categories?.filter(
        (e) => e.name !== value
      );
      this.currentAgencySetting.alarm_categories = filterArray;
    }
  }
  /**
   * changeCurrentColorMatrix
   * this function change current color matrix setting
   * @param   {String} colorMatrixKey  color matrix key
   * @return  {null} -
   */
  @action changeCurrentColorMatrix = (colorMatrixKey) => {
    this.selectedMatrix = this.currentAgencySetting?.display[colorMatrixKey];
  };
  /**
   * colorMatrixValidation
   * validate color matrix
   * no duplicate level - no duplicate color
   * @return  {null} -
   */
  @action colorMatrixValidation = () => {
    let message = "";
    let colorDisplayList = Object.keys(this.currentAgencySetting.display);
    if (Array.isArray(colorDisplayList)) {
      colorDisplayList.map((item) => {
        let colorMatrix = null;
        let validationMessage = "";
        if ((colorMatrix = this.currentAgencySetting.display[item])) {
          let matixValue = colorMatrix.color.map((item) => item.from_value);
          let matixColor = colorMatrix.color.map((item) =>
            item.color.toLowerCase()
          );
          if (
            helper.isDuplicateExists(matixValue) ||
            helper.isDuplicateExists(matixColor)
          ) {
            validationMessage =
              "[" +
              colorMatrix.label +
              "] " +
              i18n.t("agency.duplicate-matrix-value");
          }
        }
        if (validationMessage.length > 0) {
          message += validationMessage + "\n";
        }
      });
    }
    if (message.length > 0) {
      this.errorList = { message };
    } else {
      this.errorList = null;
    }
  };
  /**
   * handlerAddNewColorMatrix
   * add new level for current color matrix
   * default added item is defaultColorMatrix
   * set new data to currentAgencySetting(save later)
   * @return  {null} -
   */
  @action handlerAddNewColorMatrix = () => {
    this.selectedMatrix.color.push({ ...this.defaultColorMatrix });
    this.currentAgencySetting.display[this.selectedMatrix.key].color.push({
      ...this.defaultColorMatrix,
    });
    //re-validate color setting after add
    this.colorMatrixValidation();
  };

  /**
   * handlerRemoveColorMatrix
   * remove level setting for current color matrix
   * set new data to currentAgencySetting(save later)
   * @param   {Int} index  index of level setting
   * @return  {null} -
   */
  @action handlerRemoveColorMatrix = (index) => {
    this.selectedMatrix.color.splice(index, 1);
    this.currentAgencySetting.display[this.selectedMatrix.key].color.splice(
      index,
      1
    );
    //re-validate color setting after remove
    this.colorMatrixValidation();
  };
  /**
   * handlerColorMatrixAttributeChange
   *  level setting of current setting
   * set new data to currentAgencySetting(save later)
   * @param   {Int} index  index of level setting
   * @param   {String} field  the field name
   * @param   {Int/String} newValue  the field value
   * @return  {null} -
   */
  @action handlerColorMatrixAttributeChange = (index, field, newValue) => {
    this.selectedMatrix.color[index][field] = newValue;
    this.currentAgencySetting.display[this.selectedMatrix.key].color[index][
      field
    ] = newValue;
    //re-validate color setting after change data
    this.colorMatrixValidation();
  };
  /**
   * handlerAgencyLocationChange
   * set new Agency location center point
   * set new data to currentAgencySetting(save later)
   * @param   {Double} lat  latitude
   * @param   {Double} lng  longitude
   * @return  {null} -
   */
  @action handlerAgencyLocationChange = (lat, lng) => {
    this.currentCenterPoint = [lat, lng];
    this.currentAgencySetting.location.lat = lat;
    this.currentAgencySetting.location.lon = lng;
  };

  /**
   * setFormField
   * set form field
   * set new data to currentAgencySetting(save later)
   * @param   {Array} target
   * @param   {any} value
   * @return  {null} -
   */
  @action setFormField = (target, value) => {
    const trySetField = (target, value, currentNode) => {
      if (target.length === 1) {
        currentNode[target[0]] = value;
      } else trySetField(target.slice(1), value, currentNode[target[0]]);
    };
    trySetField(target, value, this.currentAgencySetting);
  };
  /**
   * handlerColorMatrixUnitChange
   * set new Color matrix change
   * set new data to currentAgencySetting(save later)
   * @param   {String} newValue
   * @return  {null} -
   */
  @action handlerColorMatrixUnitChange = (newValue) => {
    this.selectedMatrix.unit = newValue;
    this.currentAgencySetting.display[this.selectedMatrix.key].unit = newValue;
  };
  /**
   * handleSubmit
   * save current currentAgencySetting to backend
   * @return  {null} -
   */
  @action handleSubmit = () => {
    //if still have error => not allow to save
    if (this.errorList) {
      return;
    }
    this.loading = true;
    return new Promise((resolve, reject) => {
      this.moduleService?.saveAgencySetting(
        this.agencyId,
        this.currentAgencySetting,
        (response) => {
          runInAction(() => {
            this.loading = false;
            showNotification("success", i18n.t("success"), i18n.t("update_success"));
            resolve(response);
          });
        },
        (errors) => {
          runInAction(() => {
            this.loading = false;
            errors?.errors?.map((error) => {
              showNotification("error", i18n.t("error"), error.message);
            })
          });
          reject(errors);
        }
      );
    });
  };
}

export default AgencySettingStore;
