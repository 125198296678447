import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { List, Row, Col, Badge } from "antd";

import { toJS } from "mobx";
import { withTranslation } from "react-i18next";
import "./style.scss";

@inject("rootStore")
@observer
class TopTen extends Component {
  render() {
    const {
      t,
      rootStore: { dashboardStore },
      skeleton,
    } = this.props;
    let dataSource = toJS(dashboardStore.listTopTenIntersections);

    if (skeleton) {
      return (
        <div className="wrap-top-ten ">
          <div className="widget-header-title">
            {t("dashboard.top-ten-header")}
          </div>
        </div>
      );
    }

    return (
      <div className="wrap-top-ten ">
        <div className="widget-header">
          <div className="widget-header-title">
            {t("dashboard.top-ten-header")}
          </div>
          <div className="widget-header-subtitle">&nbsp;</div>
        </div>
        <div className="styled-scroll">
          <List
            itemLayout="horizontal"
            dataSource={dataSource}
            renderItem={(item, index) =>
              item && (
                <List.Item>
                  <Row className="intersection-item">
                    <Col span={4}>
                      <Badge
                        count={index + 1}
                        style={{ backgroundColor: "#1890ff" }}
                      />
                    </Col>
                    <Col span={14} className="alarm-name">
                      {item?.name}
                    </Col>
                    <Col span={6} className="open-number-alarm">
                      {item?.open_alarm_count}
                    </Col>
                  </Row>
                </List.Item>
              )
            }
          />
        </div>
      </div>
    );
  }
}

export default withTranslation()(TopTen);
