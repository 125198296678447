import React, { Component } from "react";
import Lottie from "react-lottie";
import animationData from "./animation_data.json";
import "./style.scss";

class SpinLoading extends Component {
  render() {
    const { visible } = this.props;
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    return (
      <div
        className={visible ? "wrap-spin-loading" : "wrap-spin-loading-unload"}
      >
        <div className="loading-icon">
          <Lottie options={defaultOptions} height={100} width={100} />
        </div>
      </div>
    );
  }
}

export default SpinLoading;
