import BaseApi from '../../BaseApi';
import Api from '../../Api';
/**
 * Service for user management.
 * 
 * @class
 */
class AlarmRuleSubscriberService extends BaseApi {

  constructor(id, parentStore) {
    super("alarm/rules/" + id + "/subscribers", parentStore);
    this.moduleName = "alarm/rules/" + id + "/subscribers";
    this.dataKey = "subscribers";
    this.keyField = 'id';
  }

  /**
   * getRuleCategories
   * this function return all categories type 
   * @param   {function} cb  callback function
   * @param   {function} fb  falseback function
   * @return  {null} -  call the callback function
   */
  add = (ids, cb, fb) => {
    const api = new Api(this.moduleName, this.parentStore);
    api.patchRequest({
      action: "ADD",
      subscriber_ids: ids
    }, (response) => {
      cb && cb(response?.data)
    }, () => {
      fb && fb()
    });
  }
  /**
   * getRuleCategories
   * this function return all categories type 
   * @param   {function} cb  callback function
   * @param   {function} fb  falseback function
   * @return  {null} -  call the callback function
   */
  delete = (ids, cb, fb) => {
    const api = new Api(this.moduleName, this.parentStore);
    api.patchRequest({
      action: "REMOVE",
      subscriber_ids: ids
    }, (response) => {
      cb && cb(response?.data)
    }, () => {
      fb && fb()
    });
  }

}

export default AlarmRuleSubscriberService;