import ChartDetail from "components/ChartDetail";

class PedestrianDelayChart extends ChartDetail {
  constructor(props) {
    super(props);
    this.value_field = "datetime";
    this.data_field = "ped_delays";
    this.CHART_AXIS_SETTING = [
      {
        position: "left",
        key: "duration",
        label: "Pedestrian Delay (second)",
        data: this.props.data.ped_delays,
        tick: true,
      },
    ];
  }
  componentDidMount = () => {
    super.componentDidMount();
  };
  /**
   * render
   *
   * @return  {Component}
   */

  render() {
    return super.render();
  }
}

export default PedestrianDelayChart;
