import ChartDetail from "components/ChartDetail";
class QueueLengthChart extends ChartDetail {
  constructor(props) {
    super(props);
    this.value_field = "to_time";
    this.data_field = "cycles";

    this.CHART_AXIS_SETTING = [
      {
        position: "left",
        key: "queue_length",
        label: "Vehicle",
        data: this.props.data.cycles,
        tick: true,
      },
    ];
  }

  render() {
    return super.render();
  }
}

export default QueueLengthChart;
