import { Button, Empty, Modal, Popover, Skeleton, Spin, Table } from "antd";
import { inject, observer } from "mobx-react";
import moment from "moment";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import {
  DATE_FORMAT,
  SELECT_TREND,
  TIMEOUT_RESIZE,
  TRENDING,
  VALUE_RESULT_TABLE,
} from "utils/constants";
import { exportCSVFile } from "utils/renderExportFile";
import PrintPreview from "./PrintPreview";

@inject("rootStore")
@observer
class ReportResultPane extends Component {
  timeoutResize = null;
  constructor(props) {
    super(props);
    this.moduleName = "result_table";
    this.printRef = React.createRef();
    this.state = {
      tableHeight: 700,
      resizing: false,
      printPreview: false,
    };
  }
  getItemWithTrending = (data, keyName) => {
    const {
      t,
      rootStore: {
        reportResultStore: { prev_result_time },
      },
    } = this.props;
    let trendingObject = TRENDING[data.trending];
    return data.trending === SELECT_TREND.none ? (
      <div key={keyName} className="flex items-center">
        <div className="mr-8">
          <div className="none-trending"></div>
        </div>
        <p>
          {t("result_table." + keyName)} {data?.value.toFixed(2)}
        </p>
      </div>
    ) : (
      <Popover
        key={keyName}
        placement={"left"}
        content={
          <div className="flex items-center">
            <div className="mr-8">
              <div className={trendingObject.icon}></div>
            </div>
            <div>
              {data.fluctuation >= 0 ? "+" : ""}
              {parseFloat(data.fluctuation).toFixed(2)}
              &nbsp;
              {t("report_result.prev_result_time")}-
              {moment(prev_result_time).format(DATE_FORMAT.full)}
            </div>
          </div>
        }
      >
        <div key={keyName} className="flex items-center">
          <div className="mr-8">
            <div className={trendingObject.icon}></div>
          </div>
          <p>
            {t("result_table." + keyName)} {data?.value.toFixed(2)}
          </p>
        </div>
      </Popover>
    );
  };
  getCellItem = (item) => {
    return (
      <div className="value-report-table-cell">
        <div>
          {VALUE_RESULT_TABLE.map((keyName) => {
            return this.getItemWithTrending(item[keyName], keyName);
          })}
        </div>
      </div>
    );
  };
  getTableColumn = () => {
    const {
      t,
      rootStore: {
        reportResultStore: { resultData },
      },
    } = this.props;

    const columKeys = resultData.length ? Object.keys(resultData[0]) : [];

    return [
      {
        title: t(this.moduleName + ".intersection_name"),
        dataIndex: "intersection_name",
        key: "intersection_name",
        className: "intersection-name",
      },
      {
        title: t(this.moduleName + ".aor"),
        dataIndex: "aor",
        key: "aor",
        className: "number-result",
        render: (item) => this.getCellItem(item),
      },
      {
        title: t(this.moduleName + ".aog"),
        dataIndex: "aog",
        key: "aog",
        className: "number-result",
        render: (item) => this.getCellItem(item),
      },
      {
        title: t(this.moduleName + ".veh_act"),
        dataIndex: "veh_act",
        key: "veh_act",
        className: "number-result",
        render: (item) => this.getCellItem(item),
      },
      {
        title: t(this.moduleName + ".max_out"),
        dataIndex: "max_out",
        key: "max_out",
        className: "number-result",
        render: (item) => this.getCellItem(item),
      },
      {
        title: t(this.moduleName + ".gap_out"),
        dataIndex: "gap_out",
        key: "gap_out",
        className: "number-result",
        render: (item) => this.getCellItem(item),
      },
      {
        title: t(this.moduleName + ".force_off"),
        dataIndex: "force_off",
        key: "force_off",
        className: "number-result",
        render: (item) => this.getCellItem(item),
      },
      {
        title: t(this.moduleName + ".avg_ped_delay"),
        dataIndex: "ped_delay",
        key: "ped_delay",
        className: "number-result",
        render: (item) => this.getCellItem(item),
      },
      {
        title: t(this.moduleName + ".ped_delay_act"),
        dataIndex: "ped_delay_act",
        key: "ped_delay_act",
        className: "number-result",
        render: (item) => this.getCellItem(item),
      },
      {
        title: t(this.moduleName + ".transition"),
        dataIndex: "transition",
        key: "transition",
        className: "number-result",
        render: (item) => this.getCellItem(item),
      },
      {
        title: t(this.moduleName + ".gor"),
        dataIndex: "gor",
        key: "gor",
        className: "number-result",
        render: (item) => this.getCellItem(item),
      },
      {
        title: t(this.moduleName + ".ror5"),
        dataIndex: "ror5",
        key: "ror5",
        className: "number-result",
        render: (item) => this.getCellItem(item),
      },
      {
        title: t(this.moduleName + ".approach_delay"),
        dataIndex: "approach_delay",
        key: "approach_delay",
        className: "number-result",
        render: (item) => this.getCellItem(item),
      },
      {
        title: t(this.moduleName + ".queue_length"),
        dataIndex: "queue_length",
        key: "queue_length",
        className: "number-result",
        render: (item) => this.getCellItem(item),
      },
      {
        title: t(this.moduleName + ".sf_indicator"),
        dataIndex: "sf_indicator",
        key: "sf_indicator",
        className: "number-result",
        render: (item) => this.getCellItem(item),
      },
      {
        title: t(this.moduleName + ".pc_indicator"),
        dataIndex: "pc_indicator",
        key: "pc_indicator",
        className: "number-result",
        render: (item) => this.getCellItem(item),
      },
      {
        title: t(this.moduleName + ".ec_indicator"),
        dataIndex: "ec_indicator",
        key: "ec_indicator",
        className: "number-result",
        render: (item) => this.getCellItem(item),
      },
      {
        title: t(this.moduleName + ".rlv_count"),
        dataIndex: "rlv_count",
        key: "rlv_count",
        className: "number-result",
        render: (item) => this.getCellItem(item),
      },
    ].filter((el) => columKeys.find((key) => el.key === key));
  };
  convertCoverPageToExportJsonFormat = (
    reportDetail,
    numberOfIntersection,
    prev_result_time
  ) => {
    const { t } = this.props;
    let coverTranform = [];
    Object.keys(reportDetail).forEach((e) => {
      switch (e) {
        case "owner": {
          coverTranform.push({
            title: t("report_result." + e),
            data: reportDetail[e].name,
          });
          break;
        }
        case "week_days": {
          coverTranform.push({
            title: t("report_result." + e),
            data: reportDetail[e].join(","),
          });
          break;
        }
        case "owner_id": {
          break;
        }
        case "id": {
          break;
        }
        case "agency_id": {
          break;
        }
        default: {
          coverTranform.push({
            title: t("report_result." + e),
            data: reportDetail[e],
          });
        }
      }
    });
    coverTranform.push({
      title: t("report_result.selected_intersection"),
      data: numberOfIntersection,
    });
    if (prev_result_time)
      coverTranform.push({
        title: t("report_result.prev_result_time"),
        data: moment(prev_result_time).format(DATE_FORMAT.full),
      });
    return coverTranform;
  };
  convertDataToExportJsonFormat = (resultData) => {
    const { t } = this.props;
    let titleRow = {};
    let dataTranform = [];
    if (resultData.length) {
      let someElement = resultData[0];

      //render header row
      Object.keys(someElement).forEach((e) => {
        if (e !== "key") {
          if (e === "intersection_name") titleRow[e] = t("result_table." + e);
          else
            Object.keys(someElement[e]).forEach((val) => {
              titleRow[e + "_" + val] = t("result_table." + e + "_" + val);
              titleRow[e + "_" + val + "trend"] = " ";
            });
        }
      });
      dataTranform.push(titleRow);
      let titleValue = {};
      //render value/trending row
      Object.keys(someElement).forEach((e) => {
        if (e !== "key") {
          if (e === "intersection_name") titleValue[e] = " ";
          else
            Object.keys(someElement[e]).forEach((val) => {
              titleValue[e + "_" + val] = t("value");
              titleValue[e + "_" + val + "trend"] = t("trending");
            });
        }
      });
      dataTranform.push(titleValue);
    }
    // render data
    resultData.forEach((row) => {
      let tranformRow = {};
      Object.keys(row).forEach((e) => {
        if (e !== "key") {
          if (e === "intersection_name") tranformRow[e] = row[e];
          else
            Object.keys(row[e]).forEach((c) => {
              tranformRow[e + "_" + c] = row[e][c].value;
              tranformRow[e + "_" + c + "trending"] =
                row[e][c].trending === SELECT_TREND.none
                  ? t(row[e][c].trending)
                  : t(row[e][c].trending) +
                    "(" +
                    (row[e][c].fluctuation >= 0
                      ? "+" + row[e][c].fluctuation
                      : row[e][c].fluctuation) +
                    ")";
            });
        }
      });
      dataTranform.push(tranformRow);
    });
    return dataTranform;
  };
  handleSetTableHeight = () => {
    let functionHeader = this.printRef.current;
    this.setState({ resizing: true });
    let table = document.getElementsByClassName("ant-table-thead")[0];
    if (this.timeoutResize) clearTimeout(this.timeoutResize);
    this.timeoutResize = setTimeout(() => {
      if (functionHeader && table) {
        let vh =
          window.innerHeight -
          96 -
          functionHeader.offsetHeight -
          table.offsetHeight;
        this.setState({
          resizing: false,
          tableHeight: vh,
        });
      }
    }, TIMEOUT_RESIZE);
  };
  componentDidMount() {
    window.addEventListener("resize", this.handleSetTableHeight);
    this.handleSetTableHeight();
  }
  componentWillUnmount() {
    const {
      rootStore: { reportResultStore },
    } = this.props;
    reportResultStore.resetData();
    window.removeEventListener("resize", this.handleSetTableHeight);
  }
  render() {
    const {
      t,
      history,
      rootStore: {
        reportResultStore: {
          resultData,
          reportDetail,
          prev_result_time,
          loading,
        },
      },
    } = this.props;

    return (
      <div className="report-result-pane">
        <Modal
          className="alarm-records-summary-modal"
          width={"60%"}
          title={t("print.modal-title")}
          visible={this.state.printPreview}
          onCancel={() => this.setState({ printPreview: false })}
          footer={null}
          destroyOnClose={true}
        >
          <PrintPreview
            prevDay={
              prev_result_time
                ? moment(prev_result_time).format(DATE_FORMAT.full)
                : null
            }
            printInfo={reportDetail}
            tableColumn={this.getTableColumn()}
            tableData={resultData}
          />
        </Modal>
        <div
          className="result-loading-pane"
          style={{ display: loading ? "" : "none" }}
        >
          <Skeleton active />
          <Skeleton active />
        </div>
        {!resultData.length && (
          <div className="result-nodata">
            <Empty />
          </div>
        )}
        <div className="result-table-pane">
          <div className="report-result-function" ref={this.printRef}>
            <div>
              <Button
                icon="arrow-left"
                onClick={() => history.push("/report/result/summary-report")}
              >
                {t("detail.back")}
              </Button>
            </div>
            <div>
              <Button
                onClick={() => {
                  this.setState({ printPreview: true });
                }}
                icon="printer"
              >
                {t("report_result.print_table")}
              </Button>
              <Button
                icon="file-excel"
                onClick={() => {
                  const fileTitle = reportDetail?.name;
                  exportCSVFile(
                    this.convertCoverPageToExportJsonFormat(
                      reportDetail,
                      resultData.length,
                      prev_result_time
                    ),
                    [
                      {
                        title: "",
                        data: this.convertDataToExportJsonFormat(resultData),
                      },
                    ],
                    t("report_result.print_title"),
                    fileTitle
                  );
                }}
              >
                {t("report_result.export_csv")}
              </Button>
            </div>
          </div>
          <Table
            className="report-result-table"
            rowKey="intersection_name"
            loading={
              this.state.resizing && {
                indicator: <Spin spinning size="large" />,
              }
            }
            rowClassName={(record, index) => {
              if (index % 2 !== 0) return "odd-row";
              else return "even-row";
            }}
            scroll={{ y: this.state.tableHeight }}
            pagination={false}
            dataSource={resultData}
            columns={this.getTableColumn()}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(withTranslation()(ReportResultPane));
