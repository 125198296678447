import { action, observable, runInAction } from "mobx";
import { NOTIFICATION_ACTION_TYPE, NOTIFICATION_TYPE } from "utils/constants";
import i18n from "../i18n";
import UnreadNotificationService from "../services/UnreadNotificationService";
import helper from "../utils/helper";
import BaseStore from "./BaseStore";

const fadeOutTime = 1000;

/**
 * Store for unread notification.
 *
 * @class
 */
class UnreadNotificationStore extends BaseStore {
  constructor(parentStore) {
    super(new UnreadNotificationService(parentStore), parentStore);
  }

  @observable unReadReleaseNote = [];

  @action setUnReadReleaseNote = (value) => {
    this.unReadReleaseNote = value;
  };
  @action getData = (cb) => {
    this.loading = true;
    this.moduleService?.fetchDataWithParams(
      this.filterData,
      (items, totalCount) => {
        runInAction(() => {
          this.loading = false;
          this.listData = items;
          this.unReadReleaseNote = items.filter(
            (e) => e?.action?.type == NOTIFICATION_ACTION_TYPE.OPEN_POPUP
          ); // check popup notice to show
          this.filterData.pagination.total = totalCount;
          // this.filterData.pagination.total = 100;
        });
        cb && cb();
      },
      () => {
        runInAction(() => {
          this.loading = false;
        });
      }
    );
  };

  /**
   * addNewNotification
   * @param   {Object} notification notification object include message
   */
  @action addNewNotification = (notification) => {
    if (!notification.name) {
      const itemTranslate = notification.notificationMessage?.find(
        (e) =>
          e.id.language === this.parentStore.myStore.currentProfile.language
      );
      notification.name = itemTranslate?.name;
      notification.description = itemTranslate?.description;
    }
    this.listData = [notification].concat(this.listData);
    this.filterData.pagination.total++;
    // check popup to show notice
    if (notification?.action?.type === NOTIFICATION_ACTION_TYPE.OPEN_POPUP)
      this.unReadReleaseNote = [notification, ...this.unReadReleaseNote];
    helper.showNotification(
      NOTIFICATION_TYPE.INFO,
      i18n.t("alm-noti.info"),
      i18n.t("alm-noti.new_message")
    );
  };

  /**
   *  mark all notification as read
   */
  @action readAll = () => {
    this.loading = true;
    return new Promise((resolve) => {
      this.moduleService
        ?.readAll()
        .then(() => {
          runInAction(() => {
            this.unReadReleaseNote = [];
            this.listData = [];
            this.filterData.pagination.total = 0;
            resolve();
            //synchronizeNotifReadData with notification store
            this.parentStore.notificationStore.synchronizeNotifData();
          });
        })
        .finally(() => {
          runInAction(() => {
            this.loading = false;
          });
        });
    });
  };

  /**
   * update read status of a notification
   * @param {Array<number>} ids notification id list
   * @param {String} statusRead read/unread status
   */
  @action updateNotifRead = (ids, statusRead, cb) => {
    this.loading = true;
    this.moduleService?.updateReadStatus(ids, statusRead).then(() => {
      runInAction(() => {
        this.loading = false;
        this.unReadReleaseNote = this.unReadReleaseNote.filter(
          (e) => !ids.includes(e?.id)
        );
        this.listData = this.listData.map((e) => {
          if (ids.includes(e.id)) e.read_status = statusRead;
          return e;
        });
        setTimeout(() => {
          runInAction(() => {
            this.listData = this.listData.filter((e) => {
              return !ids.includes(e.id);
            });
            this.filterData.pagination.total -= 1;
            //synchronizeNotifReadData with notification store
            this.parentStore.notificationStore.synchronizeNotifData();
            cb && cb();
          });
        }, fadeOutTime);
      });
    });
  };

  /**
   * update read status of a notification
   * @param {Array<number>} ids notification id list
   * @param {String} statusFlag flag/unflag status
   */
  @action updateNotifFlag = (ids, statusFlag) => {
    this.moduleService?.updateFlagStatus(ids, statusFlag).then(() => {
      runInAction(() => {
        //do not reload the list - we only update client side only
        this.listData = this.listData.map((e) => {
          if (ids.includes(e.id)) e.flag_status = statusFlag;
          return e;
        });
        //synchronizeNotifFlagData
        this.parentStore.notificationStore.synchronizeNotifData();
      });
    });
  };

  /**
   * loadMoreData
   * this function get more data from server with filter value
   *
   * @return  {null} -
   */
  @action loadMoreData = (cb) => {
    this.loading = true;
    this.filterData.pagination.current++;
    this.moduleService?.fetchDataWithParams(
      this.filterData,
      (items, totalCount) => {
        runInAction(() => {
          this.loading = false;
          this.listData = this.listData.concat(items);
          this.filterData.pagination.total = totalCount;
        });
        cb && cb();
      },
      () => {
        runInAction(() => {
          this.loading = false;
        });
      }
    );
  };
  /**
   * synchronizeNotifFlagData
   * update read status of a notification
   * this function nomarly call by notification store to synch flag data of notifcation
   * @param {Array<number>} ids notification id list
   * @param {String} statusFlag flag/unflag status
   */
  @action synchronizeNotifFlagData = (ids, statusFlag) => {
    this.listData = this.listData.map((e) => {
      if (ids.includes(e.id)) e.flag_status = statusFlag;
      return e;
    });
  };

  /**
   * synchronizeNotifReadData
   * update read status of a notification
   * this function nomarly call by notification store to synch unread data of notifcation
   * @param {Array<number>} ids notification id list
   * @param {String} statusRead read/unread status
   */
  @action synchronizeNotifReadData = () => {
    this.filterData.pagination.current = 1;
    this.getData();
  };
}

export default UnreadNotificationStore;
