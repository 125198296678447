import { Button, Empty, Icon, Skeleton, Spin, Table, Typography } from "antd";
import SimpleYellowTrapChart from "components/Charts/SimpleYellowTrapChart";
import { inject, observer } from "mobx-react";
import moment from "moment";
import React, { Component, createRef } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import { TIMEOUT_RESIZE } from "utils/constants";
import { DATE_FORMAT } from "utils/constants/index";
import { exportCSVFile } from "utils/renderExportFile";

@inject("rootStore")
@observer
class YellowTrapAnalysisData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      resizing: false,
    };
    this.listRef = createRef();
    this.scrollHeight = 0;
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleWindowResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowResize);
  }

  handleWindowResize = () => {
    const {
      rootStore: {
        analysisScheduleResultStore: { printPReview },
      },
    } = this.props;
    //do not resize when in print preview cuz it will re render the PDF file => this is burnning RAM and TIME
    if (!printPReview) {
      this.setState({ resizing: true });
      setTimeout(() => {
        this.setState({ resizing: false });
      }, TIMEOUT_RESIZE);
    }
  };

  getColumns = () => {
    const { t } = this.props;
    return [
      {
        title: t("yellow_trap.occupied"),
        key: "yellowTrap",
        children: [
          {
            title: t("yellow_trap.date"),
            width: 160,
            dataIndex: "yellow_trap_event.datetime",
            key: "date",
            render: (val) => new Date(val).toLocaleDateString(),
          },
          {
            title: t("yellow_trap.time"),
            width: 160,
            dataIndex: "yellow_trap_event.datetime",
            key: "time",
            render: (val) => new Date(val).toLocaleTimeString(),
          },
          {
            title: t("yellow_trap.left_turn_phase"),
            width: 160,
            dataIndex: "left_turn_phase_num",
            key: "leftTurnPhase",
            render: (val) => val?.toString().padStart(2, "0") || "",
          },
        ],
      },
      {
        title: t("yellow_trap.opposing_through_phase"),
        width: 200,
        dataIndex: "opposing_through_phase_num",
        key: "opposingThroughPhase",
        render: (val) => val?.toString().padStart(2, "0") || "",
      },
      {
        title: t("yellow_trap.split_visualization"),
        width: 600,
        dataIndex: "yellow_trap_event.datetime",
        key: "timeSpaceVisualization",
        render: (val, row) => (
          <SimpleYellowTrapChart
            leftTurnPhase={row.left_turn_phase_num}
            opposingThroughPhase={row.opposing_through_phase_num}
            leftTurnPhaseEvents={row.left_turn_phase_events}
            opposingThroughPhaseEvents={row.opposing_through_phase_events}
            yellowTrapEvent={row.yellow_trap_event}
          />
        ),
      },
    ];
  };
  handleExportCSV = () => {
    const {
      t,
      rootStore: {
        analysisScheduleResultStore: { reportData, getYellowTrapData },
      },
    } = this.props;
    // tranform header
    const dataTranformHeader = [];
    Object.keys(reportData).map((key) => {
      switch (key) {
        case "chart_data":
          break;
        case "owner_id":
          break;
        case "agency_id":
          break;
        case "metadata":
          dataTranformHeader.push({
            title: t("start_time"),
            data: reportData.metadata.from_time,
          });
          dataTranformHeader.push({
            title: t("end_time"),
            data: reportData.metadata.to_time,
          });
          break;
        case "owner":
          dataTranformHeader.push({
            title: t("created_by"),
            data: reportData.owner.name,
          });
          break;
        default:
          dataTranformHeader.push({
            title: t(key),
            data: reportData[key],
          });
          break;
      }
    });
    //tranform data
    //render header row
    let dataTranform = [];
    let titleRow = {
      date: t("date"),
      time: t("time"),
      left_turn_phase_num: t("analysis_schedule.left_turn_phase_num"),
      opposing_through_phase_num: t(
        "analysis_schedule.opposing_through_phase_num"
      ),
      left_turn_phase_events: t("analysis_schedule.left_turn_phase_events"),
      opposing_through_phase_event: t(
        "analysis_schedule.opposing_through_phase_event"
      ),
    };
    dataTranform.push(titleRow);
    getYellowTrapData.forEach((row) => {
      let tranformRow = {
        date: moment(row.yellow_trap_event.datetime).format(
          DATE_FORMAT.time_only_full
        ),
        time: moment(row.yellow_trap_event.datetime).format(
          DATE_FORMAT.time_only_full
        ),
        left_turn_phase_num: row.left_turn_phase_num,
        opposing_through_phase_num: row.opposing_through_phase_num,
        left_turn_phase_events: JSON.stringify(row.left_turn_phase_events),
        opposing_through_phase_events: `${JSON.stringify(
          row.opposing_through_phase_events
        ).replaceAll(",", `, '\r'`)}`,
      };
      dataTranform.push(tranformRow);
    });
    exportCSVFile(
      dataTranformHeader,
      [
        {
          title: "",
          data: dataTranform,
        },
      ],
      "Yellow Trap Occurrences Result",
      reportData.name
    );
  };
  render() {
    const {
      t,
      rootStore: {
        analysisScheduleResultStore: {
          loading,
          loadingChart,

          getYellowTrapData,
        },
      },
      history,
    } = this.props;

    return loading || loadingChart ? (
      <div className="bg-background px-8 py-8 h-full">
        <Skeleton />
        <Skeleton />
      </div>
    ) : getYellowTrapData?.length && !this.state.resizing ? (
      <div className="wrap-analysis-result">
        <div className="wrap-analysis-header">
          <div className="analysis-header flex items-center py-8 px-8 bg-background">
            <div>
              <Button
                size={"large"}
                icon="arrow-left"
                onClick={() =>
                  history.push("/report/result/performance-metric")
                }
              >
                {t("detail.back")}
              </Button>
            </div>
            <div className="flex-grow flex align-items-start">
              <Icon className="analysis-warning-icon" type="info-circle" />
              <Typography.Text className="flex-grow font-16">
                {
                  "If Intersection Topology is not configured, we'll use a default pair of phases. Where odd numbered phases are the left turns and [left turn phase + 1] is the opposing through phase."
                }
              </Typography.Text>
            </div>
            <div className="flex ">
              <div>
                <Button
                  icon="file-excel"
                  size={"large"}
                  onClick={this.handleExportCSV}
                >
                  {t("report_result.export_csv")}
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="analysis-body body-normal styled-scroll bg-background"
          style={{
            height: "calc(100% - 60px)",
          }}
        >
          {this.state.loading ? (
            <Spin size="large" className={"centralize"} />
          ) : (
            <Table
              style={{ fontSize: 16 }}
              className="spm-table yellow-trap-table"
              dataSource={getYellowTrapData}
              columns={this.getColumns()}
              rowKey="index"
              pagination={{
                showSizeChanger: true,
                defaultPageSize: 5,
                pageSizeOptions: ["5", "10", "20"],
              }}
              rowClassName={(record, index) => {
                if ((index + 1) % 2) return "even-row";
                else return "odd-row";
              }}
            />
          )}
        </div>
      </div>
    ) : (
      <>
        <div className="wrap-analysis-result">
          <div className="wrap-analysis-header">
            <div className="analysis-header flex items-center py-8 px-8 bg-background">
              <div>
                <Button
                  size={"large"}
                  icon="arrow-left"
                  onClick={() =>
                    history.push("/report/result/performance-metric")
                  }
                >
                  {t("detail.back")}
                </Button>
              </div>
            </div>
          </div>
          <div className="analysis-body body-normal styled-scroll flex items-center justify-center">
            <Empty></Empty>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(withTranslation()(YellowTrapAnalysisData));
