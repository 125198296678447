import React from "react";
import { withRouter } from "react-router";
import { Form, Alert, Spin } from "antd";
import SpinLoading from "components/PrivateLayout/SpinLoading";
import { observer, inject } from "mobx-react";
import { withTranslation } from "react-i18next";
import BaseDetailPopupComponent from "components/BaseDetailPopupComponent";
import { Link } from "react-router-dom";
import {
  FORM_ITEM_LAYOUT,
  DATE_FORMAT,
  DYNAMIC_CONTENT_TYPE,
  NOTIFICATION_REDIREC_ACTION,
} from "utils/constants";
import moment from "moment";
import Helper from "utils/helper";
import "./style.scss";
/**
 * Component for showing details of the user.
 *
 * @component
 */
@inject("rootStore")
@observer
class AlarmNotificationDetail extends BaseDetailPopupComponent {
  /**
   * contructor of component
   * @param   {object} props  props of component
   */
  constructor(props) {
    super(props.rootStore?.notificationStore, props);
  }

  /**
   * render.
   *
   */
  getDynamicComponent(item) {
    switch (item.type) {
      case DYNAMIC_CONTENT_TYPE.TEXT:
        return item.values;
      case DYNAMIC_CONTENT_TYPE.HYPERLINKS:
        return item.values.map((link, id) => {
          return (
            <div key={id}>
              <Link
                to={NOTIFICATION_REDIREC_ACTION[link.target] + "/" + link.id}
              >
                {link.text}
              </Link>
            </div>
          );
        });
      default:
        break;
    }
  }
  renderDetailData(formData) {
    const {
      t,
      rootStore: { myStore },
    } = this.props;
    const currentStore = this.props.currentStore;
    const currentLanguage = myStore?.currentProfile?.language;
    return (
      <div>
        <Spin
          size="large"
          indicator={<SpinLoading visible={true}></SpinLoading>}
          spinning={currentStore?.loading}
        >
          {currentStore?.errorList && (
            <Alert
              message={currentStore?.errorList?.message}
              type="error"
              closable
              showIcon
              style={{ margin: "0" }}
              onClose={currentStore?.clearErrorList}
              className="spm-error-comp"
            />
          )}
          <Form.Item
            className="alm-noti-key-label"
            label={t("alm-noti.id")}
            {...FORM_ITEM_LAYOUT}
          >
            {formData?.id}
          </Form.Item>
          <Form.Item label={t("alm-noti.name")} {...FORM_ITEM_LAYOUT}>
            {Helper.getTranslateText(formData?.name, currentLanguage)}
          </Form.Item>
          <Form.Item label={t("alm-noti.description")} {...FORM_ITEM_LAYOUT} className="noti-desc">
            {Helper.getTranslateText(formData?.description, currentLanguage)}
          </Form.Item>
          <Form.Item label={t("alm-noti.type")} {...FORM_ITEM_LAYOUT}>
            {formData?.type}
          </Form.Item>
          <Form.Item label={t("alm-noti.time")} {...FORM_ITEM_LAYOUT}>
            {moment(formData?.created_at).format(DATE_FORMAT.full)}
          </Form.Item>
          {Array.isArray(formData?.dynamic_content) &&
            formData?.dynamic_content?.map((content, id) => (
              <Form.Item key={id} label={content.label}>
                {this.getDynamicComponent(content)}
              </Form.Item>
            ))}
        </Spin>
        <div className="detail-btn-button-group"></div>
      </div>
    );
  }
}

const WrapForm = Form.create({ name: "user_detail" })(AlarmNotificationDetail);

export default withRouter(withTranslation()(WrapForm));
