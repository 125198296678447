import {
  Alert,
  Button,
  Col,
  Form,
  Icon,
  InputNumber,
  Row,
  Select,
  Table,
  Tag,
  Tooltip,
} from "antd";
import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import "./style.scss";
/**
 * Component for base managment module.
 * it's comon behavior of all management componenent
 *
 * @component
 */
const MAX_LENGTH_TAG = 4;
const { Option } = Select;
const MAX_DAY_RANGE = 30;
const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};
@inject("rootStore")
@observer
class AbnormalFilterSubForm extends Component {
  state = {
    inputVisible: false,
    inputValue: null,
    allTags: [],
    selectedCategoryEvent: Object.keys(
      this.props.rootStore.analysisScheduleStore.eventList
    )[0],
    selectedEvent: "all",
    tableData: [],
  };
  constructor(props) {
    super(props);
  }
  /**
   * checkOutRange
   * check time range validation
   * rule: start time and end time not over 30 days
   * @params {object} rule
   * @params {object} data
   * @params {object} callback
   * @return  {null}
   */
  checkOutRange = (rule, data, callback) => {
    const { t, disableExportBtn } = this.props;
    if (Array.isArray(data) && data.length === 2) {
      if (data[1].diff(data[0], "days") > MAX_DAY_RANGE) {
        callback(t("intersection.time_range_over"));
        disableExportBtn(true);
      } else {
        callback();
        disableExportBtn(false);
      }
    }
  };
  handleClose = async (removedTag) => {
    this.setState({
      allTags: this.state.allTags.filter((e) => e !== removedTag),
    });
  };
  handleAdd = (value) => {
    if (!this.state.allTags?.find((e) => e === value))
      this.setState({
        allTags: [...this.state.allTags, value],
        inputVisible: false,
        inputValue: "",
      });
    else
      this.setState({
        inputVisible: false,
      });
  };
  showInput = () => {
    this.setState({ inputVisible: true }, () => this.input.focus());
  };

  handleInputChange = (e) => {
    if (Number(e) < 0) return;
    this.setState({ inputValue: Number(e) });
  };

  handleInputConfirm = () => {
    const { inputValue } = this.state;
    if (inputValue || inputValue === 0) this.handleAdd(inputValue);
    else
      this.setState({
        inputVisible: false,
      });
  };

  saveInputRef = (input) => (this.input = input);

  addAllEvents = () => {
    const {
      rootStore: {
        analysisScheduleStore: { eventList },
      },
      onChangeField,
    } = this.props;
    const allEventList = eventList[
      this.state.selectedCategoryEvent
    ].event_list.map((event) => {
      return {
        event_code: event.event_code,
        param_type: event.param_type,
        params: this.state.allTags,
        event_name: event.event_label,
        deviation: 10,
      };
    });

    const filteredTableData = this.state.tableData.filter((selectedEvent) => {
      for (let i = 0; i < allEventList.length; i += 1) {
        if (selectedEvent.event_code === allEventList[i].event_code)
          return false;
      }
      return true;
    });

    const newTableData = [...filteredTableData, ...allEventList];

    const storeTableData = newTableData.map((event) => {
      return {
        event: event.event_code,
        params: event.params,
        deviation: event.deviation,
      };
    });

    this.setState({
      tableData: newTableData,
    });

    onChangeField("general_data.metadata.abnormal_data_filter", storeTableData);
  };

  componentDidMount() {
    const {
      rootStore: {
        analysisScheduleStore: {
          formData: { general_data },
          eventList,
        },
      },
    } = this.props;

    if (!general_data?.metadata?.abnormal_data_filter?.length) {
      this.setState({
        selectedCategoryEvent: Object.keys(eventList)[0],
        selectedEvent: "all",
      });
      return;
    }

    // let categoryEvent;
    const initTable = general_data.metadata.abnormal_data_filter.map(
      (filter) => {
        for (let key of Object.keys(eventList)) {
          for (let i = 0; i < eventList[key].event_list.length; i += 1) {
            if (eventList[key].event_list[i].event_code === filter.event) {
              // categoryEvent = key;
              return {
                ...eventList[key].event_list[i],
                params: filter.params,
                event_name: eventList[key].event_list[i].event_label,
                deviation: filter.deviation || 10,
              };
            }
          }
        }
      }
    );

    this.setState({
      tableData: initTable,
      selectedCategoryEvent: Object.keys(eventList)[0],
      selectedEvent: "all",
      allTags: [],
    });
  }

  /**
   * render
   *
   * @return  {Component}
   */
  render() {
    const {
      rootStore: {
        analysisScheduleStore: { eventList, formData },
      },
      t,
      form: { getFieldDecorator },
      onChangeField,
    } = this.props;

    const {
      inputVisible,
      inputValue,
      allTags,
      selectedCategoryEvent,
      selectedEvent,
      tableData,
    } = this.state;

    return (
      <>
        <Row>
          <Col span={12}>
            <Form.Item
              label={t("intersection.select_category_event")}
              {...formItemLayout}
              className="mb-8">
              {getFieldDecorator("select_category_event", {
                rules: [
                  {
                    required: true,
                    message:
                      t("intersection.select_category_event") +
                      t("is_required"),
                  },
                ],
                initialValue: selectedCategoryEvent,
              })(
                <Select
                  onChange={(value) => {
                    this.setState({
                      selectedCategoryEvent: value,
                      selectedEvent: "all",
                      allTags: [],
                    });
                  }}>
                  {Object.keys(eventList).map((key) => (
                    <Option value={key} key={key}>
                      {eventList[key]?.group_name}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={12} className="flex justify-end">
            {
              <Form.Item
                label={t("intersection.select_event")}
                className="w-320  mb-8"
                {...formItemLayout}>
                {getFieldDecorator("event_code", {
                  rules: [
                    {
                      required: true,
                      message:
                        t("intersection.select_event") + t("is_required"),
                    },
                  ],
                  initialValue: selectedEvent,
                })(
                  <Select
                    showSearch
                    filterOption={(input, option) => {
                      return (
                        option?.props?.children
                          ?.toLowerCase()
                          ?.indexOf(input?.toLowerCase()) >= 0
                      );
                    }}
                    onChange={(value) => {
                      const existingItem = tableData.find(
                        (e) => e.event_code === value
                      );
                      this.setState({
                        selectedEvent: value,
                      });
                      if (existingItem)
                        this.setState({
                          allTags: existingItem.params,
                        });
                      else
                        this.setState({
                          allTags: [],
                        });
                    }}
                    placeholder={t("intersection.select_event")}>
                    <Option value={"all"}>
                      {t("intersection.all_event_category")}
                    </Option>
                    {eventList[selectedCategoryEvent]?.event_list.map((e) => (
                      <Option value={e.event_code} key={e.event_code}>
                        {e.event_label}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            }
          </Col>
          {
            <Col span={24}>
              <Form.Item
                {...formItemLayout}
                label={t("intersection.enter_params_type", {
                  value: eventList[selectedCategoryEvent]?.event_list.find(
                    (e) => e.event_code === selectedEvent
                  )?.param_type,
                })}
                className="mb-8">
                <div className="input-tag">
                  {this.state.allTags?.map((item) => {
                    const tag = item;
                    const tagString = tag?.toString();
                    const isLongTag = tagString.length > MAX_LENGTH_TAG;
                    const tagElem = (
                      <Tag
                        className="default-tag"
                        key={tag}
                        closable={!item.is_default}
                        onClose={() => this.handleClose(tag)}>
                        {isLongTag
                          ? `${tagString.slice(0, MAX_LENGTH_TAG)}...`
                          : tagString}
                      </Tag>
                    );
                    if (!item.is_default)
                      return isLongTag ? (
                        <Tooltip title={tag} key={tag}>
                          {tagElem}
                        </Tooltip>
                      ) : (
                        tagElem
                      );
                  })}
                  {inputVisible && (
                    <InputNumber
                      ref={this.saveInputRef}
                      type="text"
                      size="default"
                      value={inputValue}
                      onChange={this.handleInputChange}
                      onBlur={this.handleInputConfirm}
                      onPressEnter={this.handleInputConfirm}
                    />
                  )}
                  {!inputVisible && (
                    <Tag
                      className="p-4 px-8"
                      onClick={this.showInput}
                      style={{ background: "#fff", borderStyle: "dashed" }}>
                      <Icon type="plus" /> {t("intersection.new_param")}
                    </Tag>
                  )}
                </div>
              </Form.Item>
            </Col>
          }
        </Row>
        {
          <Row>
            {tableData.length === 0 && (
              <div className="mt-8 mb-8">
                <Alert
                  message={t("report_template.perflog_filter_empty")}
                  type="warning"
                  showIcon
                />
              </div>
            )}
            <div className="w-full flex justify-space-beetwen mb-8">
              <div>{t("intersection.filter_label")}</div>
              <div>
                <Button
                  icon="plus"
                  type="primary"
                  onClick={() => {
                    if (!selectedCategoryEvent) return;

                    if (selectedEvent === "all") {
                      this.addAllEvents();
                      return;
                    }

                    const selectedEventFind = eventList[
                      selectedCategoryEvent
                    ]?.event_list.find((e) => e.event_code === selectedEvent);
                    const newAbnormalFilter =
                      formData.general_data.metadata.abnormal_data_filter.filter(
                        (e) => e.event !== selectedEvent
                      );
                    const filterOldata = tableData.filter(
                      (e) => e.event_code !== selectedEvent
                    );
                    onChangeField(
                      "general_data.metadata.abnormal_data_filter",
                      [
                        {
                          event: selectedEvent,
                          params: allTags,
                          deviation: 10,
                        },
                        ...newAbnormalFilter,
                      ]
                    );
                    this.setState({
                      tableData: [
                        {
                          event_code: selectedEvent,
                          param_type: selectedEventFind.param_type,
                          params: allTags,
                          event_name: selectedEventFind.event_label,
                          deviation: 10,
                        },
                        ...filterOldata,
                      ],
                    });
                  }}>
                  {t("intersection.add_filter")}
                </Button>
              </div>
            </div>
            <Table
              pagination={{ pageSize: 5 }}
              className="spm-table"
              columns={[
                {
                  title: t("intersection.select_event"),
                  dataIndex: "event_name",
                  key: "event_name",
                },
                {
                  title: t("intersection.param_type"),
                  key: "param_type",
                  dataIndex: "param_type",
                },
                {
                  title: t("intersection.params_value"),
                  dataIndex: "params",
                  key: "params",
                  render: (data) => {
                    return data?.length ? data.join(", ") : t("all");
                  },
                },
                {
                  title: `${t("intersection.deviation")} (%)`,
                  key: "deviation",
                  render: (data) => {
                    return (
                      <InputNumber
                        defaultValue={data.deviation}
                        min={0}
                        max={100}
                        formatter={(value) => `${value ? value : 0}%`}
                        onChange={(value) => {
                          if (
                            value === null ||
                            value === "" ||
                            value < 0 ||
                            value > 100
                          )
                            return;

                          const tableIndex = tableData.findIndex(
                            (item) => item.event_code === data.event_code
                          );

                          const newTableData = [...tableData];
                          newTableData[tableIndex] = {
                            ...data,
                            deviation: value,
                          };

                          const newAbnormalFilter = [
                            ...formData.general_data.metadata
                              .abnormal_data_filter,
                          ];
                          newAbnormalFilter[tableIndex] = {
                            event: data.event_code,
                            params: data.params,
                            deviation: value,
                          };

                          this.setState({ tableData: newTableData });
                          onChangeField(
                            "general_data.metadata.abnormal_data_filter",
                            newAbnormalFilter
                          );
                        }}
                      />
                    );
                  },
                },
                {
                  title: t("table.actions"),
                  key: "action",
                  render: (item) => {
                    return (
                      <Button
                        icon="delete"
                        type="link"
                        onClick={() => {
                          const newList = tableData.filter(
                            (e) => e.event_code !== item.event_code
                          );
                          const newAbnormalFilter =
                            formData.general_data.metadata.abnormal_data_filter.filter(
                              (e) => e.event !== item.event_code
                            );
                          onChangeField(
                            "general_data.metadata.abnormal_data_filter",
                            newAbnormalFilter
                          );
                          this.setState({ tableData: newList });
                        }}
                      />
                    );
                  },
                },
              ]}
              rowKey="event_code"
              dataSource={tableData}
            />
          </Row>
        }
      </>
    );
  }
}

export default withTranslation()(AbnormalFilterSubForm);
