import { action, observable, runInAction } from "mobx";
import moment from "moment";
import {
  DEFAULT_MAP_CENTER,
  DEFAULT_ZOOM_LEVEL,
  INTERSECTION_TAB_INDEX,
  RESOURCE_LIST,
} from "utils/constants";
import { PROGRESS_STATUS_TYPE } from "utils/constants/index";
import AgencyService from "../services/AgencyService";
import JobProgressService from "../services/JobProgressService";
import BaseStore from "./BaseStore";

const DEFAULT_DOWNLOAD_INFO = {
  from_time: moment().day(-6),
  to_time: moment(),
  int_uuid: "",
  name: "",
};
/**
 * Store for intersections
 *
 * @class
 */
class JobProgressStore extends BaseStore {
  constructor(parentStore) {
    super(new JobProgressService(parentStore), parentStore);
    this.agencyService = new AgencyService(parentStore); //agency service to get agency data
    //filter data for list intersection
    this.filterData = {
      ...this.filterData,
      ...{
        text: "",
        sort: "",
        status: "null",
        agency_id: parentStore?.myStore?.currentAgency?.agency_id,
      },
    };
    this.resourceName = RESOURCE_LIST.INTERSECTION;
    //for loading loop intersections
    this.continuousLoadingThreadId = null;
  }
  DOWNLOAD_TYPE = {
    PROGRESS: "progress",
    DETAIL: "detail",
  };
  PROGRESS_ACTION_BTN_TYPE = {
    CANCEL: "cancel",
    CLEAR: "clear",
  };

  //data for filtering
  @observable listAllAgency = []; //list all agency of current user
  @observable currentTab = INTERSECTION_TAB_INDEX.INTERSECTION_LIST_TAB; //current tab
  //data for map
  @observable initalAgencyLocation = {
    centerPoint: DEFAULT_MAP_CENTER,
    zoomLevel: DEFAULT_ZOOM_LEVEL,
  };
  @observable selectedIntersectionId = null;
  //data for download features
  @observable visibleDownloadModal = false;
  @observable curentDownloadInfo = { ...DEFAULT_DOWNLOAD_INFO };
  @observable exportProgressData = [];
  //type for details

  typeDetail = this.DOWNLOAD_TYPE.DETAIL;
  /**
   * toggleDownloadModalVisibility
   *
   * show and hide download raw data modal box
   * set and unset value of current intersection need tobe download
   * update new time range for download raw data
   * @param {String} value value
   * @param {String} int_uuid int_uuid
   * @param {String} name name
   * @return  {null}
   */
  @action toggleDownloadModalVisibility = (value, int_uuid = "", name = "") => {
    this.visibleDownloadModal = value;
    if (value) {
      this.curentDownloadInfo.int_uuid = int_uuid;
      this.curentDownloadInfo.name = name;
      this.curentDownloadInfo.agency_uuid = this.filterData.agency_id;
    } else {
      this.curentDownloadInfo = { ...DEFAULT_DOWNLOAD_INFO };
    }
  };

  /**
   * handleDownloadDataChange
   *
   * update new time range for download raw data
   * @param {moment} fromTime fromTime
   * @param {moment} toTime toTime
   * @return  {null}
   */
  @action handleDownloadDataChange = (fromTime, toTime) => {
    this.curentDownloadInfo.from_time = fromTime;
    this.curentDownloadInfo.to_time = toTime;
  };
  /**
   * handleDownloadDataChange
   *
   * update new time range for download raw data
   * @param {moment} fromTime fromTime
   * @param {moment} toTime toTime
   * @return  {null}
   */
  @action setDrawerVisible = (val) => {
    this.drawerVisible = val;
  };
  /**
   *
   * @param {*} data
   */
  @action setExportProgressData = (data) => {
    this.exportProgressData = data;
  };
  /**
   * handleActionWithProgress: get all progress
   *
   * @param {fucntion} title : set title for header detail ?
   * @return  {null}
   */
  @action handleShowAllJob = (title) => {
    this.createEditFromTile = title;
    this.setDrawerVisible(true);
    this.clearErrorList();
    this.setLoading(true);
    this.moduleService
      .getAllProgressExport()
      .then((data) => {
        this.setExportProgressData(data);
        this.setLoading(false);
      })
      .catch((errors) => {
        runInAction(() => {
          this.setErrorList(errors);
          this.setLoading(false);
        });
      })
      .finally(() => {});
  };

  /**
   * handleActionWithProgress
   *
   * @param {fucntion} action : do what ?
   * @param {fucntion} id : do with ?
   * @return  {null}
   */

  @action handleActionWithProgress = (action, id) => {
    this.setLoading(true);
    if (action === this.PROGRESS_ACTION_BTN_TYPE.CANCEL)
      this.moduleService
        .cancelProgress(id)
        .then(() => {
          let obj = this.exportProgressData.find((e) => e.id === id);
          obj.status = PROGRESS_STATUS_TYPE.CANCELLED;
        })
        .catch((errors) => {
          this.setErrorList(errors);
        })
        .finally(() => {
          this.handleShowAllJob();
        });
    if (action === this.PROGRESS_ACTION_BTN_TYPE.CLEAR)
      this.moduleService
        .clearProgress(id)
        .then(() => {
          let newExportProgressData = this.exportProgressData.filter(
            (e) => e.id != id
          );
          this.setExportProgressData(newExportProgressData);
        })
        .catch((errors) => {
          this.setErrorList(errors);
        })
        .finally(() => {
          this.handleShowAllJob();
        });
  };

  /**
   * updateAgencyFilterValue
   * this function update filter to get all value of intersections that belong to agency
   * @return  {null}
   */
  @action updateAgencyFilterValue(value) {
    this.filterData.agency_id = value;
  }

  /**
   * closeCreateOrEditForm
   * this function will trigger when user wanna close item detail form
   * @return  {null} -  setState of current typeDetail drawerVisible, createEditFromTile, formEditable
   */
  @action closeCreateOrEditForm = (defaultObject = null) => {
    this.drawerVisible = false;
    if (defaultObject) {
      this.itemData = defaultObject;
    } else {
      this.itemData = this.defaultItemData;
    }
  };
}

export default JobProgressStore;
