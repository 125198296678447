import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Button, Typography, Select, Radio } from "antd";
import "../../style.scss";
import { inject, observer } from "mobx-react";
import {
  DASHBOARD_INTERSECTION_SELECTION_TYPE,
  DASHBOARD_WIDGETS,
} from "../../../../utils/constants/dashboardConstant";
import RightSideBar from "../../../../components/RightSideBar";

const { Option } = Select;

/**
 * Component for showing user info pannel
 *
 * @component
 */
@inject("rootStore")
@observer
class OpenAlarmSetting extends Component {
  constructor(props) {
    super(props);
  }

  cancelOpenAlarmSetting = () => {
    const {
      rootStore: {
        dashboardStore: { resetOpenAlarmSetting, toggleWidgetSetting },
      },
      onClose,
    } = this.props;

    resetOpenAlarmSetting && resetOpenAlarmSetting();
    toggleWidgetSetting(false);
    onClose && onClose();
  };

  saveOpenAlarmSetting = () => {
    const {
      rootStore: {
        dashboardStore: { patchDashboardSetting },
      },
      onClose,
    } = this.props;

    patchDashboardSetting &&
      patchDashboardSetting(DASHBOARD_WIDGETS.INFO_ALARM_MESSAGE);
    onClose && onClose();
  };

  /**
   * render
   *
   * @return  {Component}
   */
  render() {
    const {
      t,
      rootStore: {
        dashboardStore: {
          widgetSetting,
          intersectionOptions,
          openAlarmWidgetSetting,
          changeOpenAlarmSetting,
          savingSetting,
        },
      },
    } = this.props;
    return (
      <RightSideBar
        visible={widgetSetting}
        title={t("dashboard.widget_settings")}
        onClose={this.cancelOpenAlarmSetting}>
        <div className="setting-info-panel">
          <Typography.Paragraph>
            {t("dashboard.chart-header") +
              " - " +
              t("dashboard.widget_settings_subtitle")}
          </Typography.Paragraph>
          <div className="setting-section">
            <p className="setting-label">
              {t("dashboard.selected_intersection_list")}
              <br />
              {t("dashboard.selected_intersection_description")}
            </p>
            <Radio.Group
              className="w-full"
              onChange={(e) =>
                changeOpenAlarmSetting({
                  select_type: e.target.value,
                  selected_intersections: [],
                })
              }
              value={openAlarmWidgetSetting.select_type}>
              <Radio
                value={DASHBOARD_INTERSECTION_SELECTION_TYPE.INCLUDE_ALL}
                className="w-full">
                {t("dashboard.include_all_intersections")}
              </Radio>
              <Radio
                value={DASHBOARD_INTERSECTION_SELECTION_TYPE.INCLUDE_SPECIFIC}
                className="w-full setting-select">
                {t("dashboard.include_specific_intersections")}
              </Radio>
              <Radio
                value={DASHBOARD_INTERSECTION_SELECTION_TYPE.EXCLUDE_SPECIFIC}
                className="w-full setting-select">
                {t("dashboard.exclude_specific_intersections")}
              </Radio>
            </Radio.Group>
            <Select
              mode="multiple"
              className="setting-select"
              placeholder={t("dashboard.select_intersections")}
              value={openAlarmWidgetSetting.selected_intersections}
              onChange={(val) => {
                changeOpenAlarmSetting({ selected_intersections: val });
              }}
              disabled={
                openAlarmWidgetSetting.select_type ===
                DASHBOARD_INTERSECTION_SELECTION_TYPE.INCLUDE_ALL
              }
              optionFilterProp="label">
              {intersectionOptions.map((el) => (
                <Option key={el.id} value={el.id} label={el.name}>
                  {el.name}
                </Option>
              ))}
            </Select>
          </div>
          <div className="flex justify-end w-full mt-8 setting-footer">
            <Button
              type="default"
              onClick={this.cancelOpenAlarmSetting}
              disabled={savingSetting}>
              {t("cancel")}
            </Button>
            <Button
              type="primary"
              style={{ marginLeft: 5 }}
              onClick={this.saveOpenAlarmSetting}
              loading={savingSetting}
              disabled={!openAlarmWidgetSetting.changed}>
              {t("save")}
            </Button>
          </div>
        </div>
      </RightSideBar>
    );
  }
}

export default withTranslation()(OpenAlarmSetting);
