import React, { Component } from "react";
import { withRouter } from "react-router";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Spin,
  Switch,
  TimePicker,
  Typography,
} from "antd";
import { inject, observer } from "mobx-react";
import {
  DATE_FORMAT,
  SAMPLING_PERIOD,
  TIME_PICKER_FORMAT,
  WEEK_DAY_OPTION,
} from "utils/constants";
import { withTranslation } from "react-i18next";
import { TIMEZONES } from "../../../../utils/timezone";
import AlarmRuleBuider from "./AlarmRuleBuider";
import moment from "moment";
import "./style.scss";
import { ReactComponent as Logo } from "../../../../svgs/logo.svg";
import HelperPopup from "./HelperPopup";

const { Option } = Select;
const HORIZONTAL_LAYOUT = "horizontal";
const { TextArea } = Input;
const createUrl = "/alarms/rules/create";

// eslint-disable-next-line react/display-name
const RefBuilder = React.forwardRef(
  ({ metricList, handleChangeCondition, setVisible, ...props }, ref) => {
    return (
      <div ref={ref}>
        <AlarmRuleBuider
          fields={metricList}
          setVisible={setVisible}
          handleChangeCondition={handleChangeCondition}
          {...props}
        ></AlarmRuleBuider>
      </div>
    );
  }
);

@inject("rootStore")
@observer
class AlarmRuleDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formLayout: HORIZONTAL_LAYOUT,
      selectDuration: false,
      visible: false,
      loading: false,
      isFirstLoad: true,
    };
    this.formItemLayout = {
      labelCol: { span: 24 },
      wrapperCol: { span: 24 },
    };
    this.formItemLayoutNoLabel = {
      wrapperCol: { span: 24 },
    };
  }

  ruleContidion = "";

  componentWillUnmount() {
    const {
      rootStore: {
        alarmRuleStore: { resetRuleDetailState, resetCreateRuleForm },
      },
    } = this.props;
    resetCreateRuleForm();
    resetRuleDetailState();
  }

  componentDidMount() {
    const {
      id,
      rootStore: { alarmRuleStore, commonStore, myStore },

      childRef,
      t,
    } = this.props;
    this.setState({ loading: true });
    document.title = t("alarm_rules.page-title");

    const currentAgency = myStore.currentAgency;
    const superAdmin = commonStore.isSuperAdmin();
    if (id) {
      alarmRuleStore.getRuleDetail(id);
    }
    alarmRuleStore.getAllRuleAnalysis();
    // alarmRuleStore.getAllRuleCategories();
    if (!superAdmin) {
      alarmRuleStore.setRuleCreateField("agency_id", currentAgency.agency_id);
    } else {
      alarmRuleStore.getAllAgencies();
    }
    alarmRuleStore.getMetric();
    childRef && childRef(this);

    this.setState({ loading: false, isFirstLoad: false });
  }

  /**
   * handleNext
   *
   * @return  {Object}
   */
  handleNext = () => {
    const {
      rootStore: {
        alarmRuleStore: { changeCreateRuleTab },
      },
    } = this.props;
    this.validateDetailRule();
    this.props.form.validateFields((err) => {
      if (!err) {
        changeCreateRuleTab("2");
      }
    });
  };

  validateDetailRule = () => {
    const { t } = this.props;
    const moduleName = "alarm_rules";
    const fieldObject = {
      condition: {
        errors: null,
      },
    };
    let conditionObject = null;
    if (this.ruleContidion) conditionObject = JSON.parse(this.ruleContidion);
    if (!conditionObject || conditionObject?.conditions?.length == 0) {
      fieldObject.condition.errors = [
        {
          message: t(moduleName + ".condition_required"),
          field: "condition",
        },
      ];
    }
    this.props.form.setFields(fieldObject);
  };

  setFormError = (errors) => {
    errors?.map((e) => {
      const fieldObject = {};
      let fieldName = e.field;
      //specific for time_range case
      if (e.field == "time_range") {
        fieldName = "time_range.start";
      }
      fieldObject[fieldName] = {
        errors: errors.filter((ei) => ei.field == e.field),
      };
      this.props.form.setFields(fieldObject);
    });
  };
  /**
   * handleDuplicate
   *
   * @return  {Object}
   */
  handleDuplicate = () => {
    const { id, history } = this.props;
    this.props.form.validateFields(() => {
      history.push(createUrl + "?cloneid=" + id);
    });
  };

  /**
   * handleDuplicate
   *
   * @return  {Object}
   */
  handleUpdate = () => {
    const {
      id,
      rootStore: {
        alarmRuleStore,
        alarmRuleStore: { getRuleDetail },
      },
    } = this.props;
    this.validateDetailRule();
    this.props.form.validateFields((err) => {
      if (!err) {
        alarmRuleStore.submitUpdateRule(
          () => {
            // history.push("/alarms/rules");
            if (id) getRuleDetail(id);
          },
          (error) => {
            this.setFormError(error);
          }
        );
      }
    });
  };

  handleChangeCondition = (query) => {
    const {
      rootStore: {
        alarmRuleStore: { onChangeCondition },
      },
    } = this.props;
    this.ruleContidion = query;
    // The line below is needed to update the form value for condition field. Otherwise, it will cause
    // a weird behavior when editing the rule condition (Query Builder). Update this if you know the root cause
    this.props.form.setFieldsValue({ condition: query });
    onChangeCondition(query);
  };

  onChangeTimeDuration = (startTime, duration, endTime, field) => {
    const {
      rootStore: { alarmRuleStore },
    } = this.props;
    let dur;
    let endT = endTime;

    if (startTime && duration) {
      let miliSeCondStart = moment(startTime, DATE_FORMAT.hour).valueOf();
      endT = moment(miliSeCondStart + duration * 3600000).format(
        DATE_FORMAT.hour
      );
      this.onChangeField("time_range.end", endT);
    }
    if (startTime && endTime) {
      let miliSeCondStart = moment(startTime, DATE_FORMAT.hour).valueOf();
      let miliSecondEnd = moment(endTime, DATE_FORMAT.hour).valueOf();
      dur = Math.round((miliSecondEnd - miliSeCondStart) / 60000);
      if (dur <= 0) {
        dur += 1440;
      }
      let durMinutes = dur % 60;
      let durHours = (dur - durMinutes) / 60;
      let durationString =
        (durHours < 10 ? "0" + durHours : durHours) +
        ":" +
        (durMinutes < 10 ? "0" + durMinutes : durMinutes);
      this.onChangeField("time_range.duration", durationString);
    }

    if (
      endT &&
      moment(endT, DATE_FORMAT.hour).valueOf() <=
        moment(startTime, DATE_FORMAT.hour).valueOf()
    ) {
      alarmRuleStore.setOverNight(true);
    } else alarmRuleStore.setOverNight(false);

    if (field == "time_range.start") {
      return this.onChangeField(field, startTime);
    }
    if (field == "time_range.duration") {
      return this.onChangeField(field, duration);
    }
    if (field == "time_range.end") {
      return this.onChangeField(field, endTime);
    }
  };
  onChangeField = (field, value) => {
    const {
      id,
      rootStore: { alarmRuleStore },
    } = this.props;
    if (id) {
      alarmRuleStore.setRuleDetailField(field, value);
    } else {
      alarmRuleStore.setRuleCreateField(field, value);
    }
    return value;
  };
  setVisible = (value) => {
    this.setState({
      visible: value,
    });
  };

  render() {
    const {
      id,
      t,
      form: { getFieldDecorator },
      rootStore: {
        alarmRuleStore,
        alarmRuleStore: {
          metricList,
          createRuleDetail,
          ruleDetail,
          allRuleAnalysis,
          allRuleCategories,
        },
        commonStore,
      },
    } = this.props;
    const allAgencies = alarmRuleStore.allAgencies;
    const superAdmin = commonStore.isSuperAdmin();
    let formData = undefined;
    if (id) {
      formData = ruleDetail;
    } else {
      formData = createRuleDetail;
    }

    const moduleName = "alarm_rules";

    return (
      <div className="wrap-alarm-rule-detail-page">
        <Spin
          spinning={
            !id
              ? false
              : this.state.isFirstLoad
              ? !Boolean(formData.condition)
              : false
          }
        >
          <Modal
            closable={false}
            title={<Logo className="logo-img" style={{ width: 120 }} />}
            visible={this.state.visible}
            width="800px"
            onCancel={() => this.setVisible(false)}
            footer={[
              <Button key="back" onClick={() => this.setVisible(false)}>
                OK
              </Button>,
            ]}
          >
            {" "}
            <HelperPopup />
          </Modal>

          <Form layout={this.state.formLayout} onSubmit={this.handleSubmit}>
            <div>
              <Row gutter={32}>
                <Col lg={12} xs={24}>
                  <Row gutter={16}>
                    <Col lg={16} xs={24}>
                      <Form.Item
                        className="label"
                        label={t(moduleName + ".name")}
                        {...this.formItemLayout}
                      >
                        {getFieldDecorator("name", {
                          rules: [
                            {
                              required: true,
                              message: t(moduleName + ".name_required"),
                            },
                          ],
                          initialValue: formData?.name,
                          getValueFromEvent: (e) => {
                            return this.onChangeField("name", e.target.value);
                          },
                        })(
                          <Input
                            placeholder={t(moduleName + ".name_placeholder")}
                            maxLength={128}
                          />
                        )}
                      </Form.Item>
                    </Col>
                    <Col lg={24} xs={24}>
                      <Form.Item
                        className="label"
                        label={t(moduleName + ".description")}
                        {...this.formItemLayout}
                      >
                        {getFieldDecorator("description", {
                          rules: [
                            {
                              required: true,
                              message: t(moduleName + ".description_required"),
                            },
                          ],
                          initialValue: formData?.description,
                          getValueFromEvent: (e) => {
                            return this.onChangeField(
                              "description",
                              e.target.value
                            );
                          },
                        })(
                          <TextArea
                            maxLength={500}
                            style={{ resize: "none" }}
                            rows={5}
                            placeholder={t(moduleName + ".description")}
                          />
                        )}
                      </Form.Item>
                    </Col>
                    {id && (
                      <Col lg={8} xs={24}>
                        <Form.Item
                          className="label"
                          label={t(moduleName + ".status")}
                          {...this.formItemLayout}
                        >
                          {getFieldDecorator("status", {
                            rules: [
                              {
                                required: true,
                                message: t(moduleName + ".status_required"),
                              },
                            ],
                            initialValue: formData?.status,
                            getValueFromEvent: (e) => {
                              return this.onChangeField("status", e);
                            },
                          })(
                            <Select
                              placeholder={t(
                                moduleName + ".status_placeholder"
                              )}
                            >
                              <Option value={"ACTIVE"}>ACTIVE</Option>
                              <Option value={"INACTIVE"}>INACTIVE</Option>
                            </Select>
                          )}
                        </Form.Item>
                      </Col>
                    )}
                    {superAdmin && (
                      <Col lg={12} xs={24}>
                        <Form.Item
                          className="label"
                          label={t(moduleName + ".agency_id")}
                          {...this.formItemLayout}
                        >
                          {getFieldDecorator("agency_id", {
                            rules: [
                              {
                                required: true,
                                message: t(moduleName + ".agency_required"),
                              },
                            ],
                            initialValue: formData?.agency_id,
                            getValueFromEvent: (e) => {
                              return this.onChangeField("agency_id", e);
                            },
                          })(
                            <Select
                              disabled={id != null}
                              placeholder={t(
                                moduleName + ".agency_placeholder"
                              )}
                            >
                              {allAgencies.map((e) => (
                                <Option key={e.id} value={e.id}>
                                  {e.name}
                                </Option>
                              ))}
                            </Select>
                          )}
                        </Form.Item>
                      </Col>
                    )}
                    <Col lg={12} xs={24}>
                      <Form.Item
                        className="label"
                        label={t(moduleName + ".category")}
                        {...this.formItemLayout}
                      >
                        {getFieldDecorator("category", {
                          rules: [
                            {
                              required: true,
                              message: t(moduleName + ".category_required"),
                            },
                          ],
                          initialValue: id
                            ? formData?.category?.id
                            : formData?.category_id,
                          getValueFromEvent: (e) => {
                            return this.onChangeField("category_id", e);
                          },
                        })(
                          <Select
                            placeholder={t(
                              moduleName + ".category_placeholder"
                            )}
                          >
                            {allRuleCategories.map((e) => (
                              <Option key={e.id} value={e.id}>
                                {t(e.name)}
                              </Option>
                            ))}
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                    <Col lg={12} xs={24}>
                      <Form.Item
                        className="label"
                        label={t(moduleName + ".analysis_id")}
                        {...this.formItemLayout}
                      >
                        {getFieldDecorator("analysis", {
                          rules: [
                            {
                              required: true,
                              message: t(moduleName + ".analysis_required"),
                            },
                          ],
                          initialValue: id
                            ? formData?.analysis?.id
                            : formData?.analysis_id,
                          getValueFromEvent: (e) => {
                            return this.onChangeField("analysis_id", e);
                          },
                        })(
                          <Select
                            placeholder={t(
                              moduleName + ".analysis_placeholder"
                            )}
                          >
                            {allRuleAnalysis.map((e) => (
                              <Option key={e.id} value={e.id}>
                                {e.name}
                              </Option>
                            ))}
                          </Select>
                        )}
                      </Form.Item>
                    </Col>

                    <Col lg={24} xs={24}>
                      <Form.Item
                        className="label"
                        label={t(moduleName + ".select_day")}
                        {...this.formItemLayout}
                      >
                        {getFieldDecorator("time_range.week_days", {
                          rules: [
                            {
                              required: true,
                              message: t(moduleName + ".week_days_required"),
                            },
                          ],
                          initialValue: formData?.time_range?.week_days,
                          getValueFromEvent: (e) => {
                            return this.onChangeField(
                              "time_range.week_days",
                              e
                            );
                          },
                        })(<Checkbox.Group options={WEEK_DAY_OPTION} />)}
                      </Form.Item>
                    </Col>
                    <Col lg={8} xs={24}>
                      <div>
                        <Form.Item
                          className="label"
                          label={t(moduleName + ".start")}
                          {...this.formItemLayout}
                        >
                          <div>
                            {getFieldDecorator("time_range.start", {
                              rules: [
                                {
                                  required: true,
                                  message: t(moduleName + ".start_required"),
                                },
                              ],
                              initialValue: formData?.time_range?.start
                                ? moment(
                                    formData?.time_range?.start,
                                    DATE_FORMAT.hour
                                  )
                                : null,
                              getValueFromEvent: (e) => {
                                return this.onChangeTimeDuration(
                                  e,
                                  this.state.selectDuration
                                    ? formData.time_range.duration
                                    : null,
                                  this.state.selectDuration
                                    ? null
                                    : formData.time_range.end,
                                  "time_range.start"
                                );
                              },
                            })(<TimePicker format={TIME_PICKER_FORMAT} />)}
                            {formData?.time_range?.start && (
                              <Typography.Text disabled>
                                (
                                {moment(
                                  formData?.time_range?.start,
                                  DATE_FORMAT.hour
                                ).format(DATE_FORMAT.short_hour)}
                                )
                              </Typography.Text>
                            )}
                          </div>
                        </Form.Item>
                      </div>
                    </Col>

                    <Col lg={8} xs={24}>
                      <Form.Item
                        className="label"
                        label={t(moduleName + ".end")}
                        {...this.formItemLayout}
                        style={{ width: "100%" }}
                        required={!this.state.selectDuration}
                      >
                        <div>
                          {this.state.selectDuration ? (
                            <div>
                              {formData?.time_range?.end && (
                                <Typography className="value-time-des">
                                  <Typography.Text className="value-time">
                                    {moment(
                                      formData?.time_range?.end,
                                      DATE_FORMAT.hour
                                    ).format(DATE_FORMAT.hour)}
                                  </Typography.Text>
                                  <Typography.Text disabled>
                                    &nbsp; (
                                    {moment(
                                      formData?.time_range?.end,
                                      DATE_FORMAT.hour
                                    ).format(DATE_FORMAT.short_hour)}
                                    )
                                  </Typography.Text>
                                  {alarmRuleStore?.overNight && (
                                    <Typography.Text type="warning">
                                      &nbsp;
                                      {t("alarm_rules.over_night")}
                                    </Typography.Text>
                                  )}
                                </Typography>
                              )}
                            </div>
                          ) : (
                            getFieldDecorator("time_range.end", {
                              rules: [
                                {
                                  required: true,
                                  message: t(moduleName + ".end_required"),
                                },
                              ],
                              initialValue: formData?.time_range?.end
                                ? moment(
                                    formData?.time_range?.end,
                                    DATE_FORMAT.hour
                                  )
                                : null,
                              getValueFromEvent: (e) => {
                                return this.onChangeTimeDuration(
                                  formData?.time_range?.start,
                                  null,
                                  e,
                                  "time_range.end"
                                );
                              },
                            })(<TimePicker format={TIME_PICKER_FORMAT} />)
                          )}
                          {!this.state.selectDuration &&
                            formData?.time_range?.end && (
                              <Typography.Text disabled>
                                (
                                {moment(
                                  formData?.time_range?.end,
                                  DATE_FORMAT.hour
                                ).format(DATE_FORMAT.short_hour)}
                                )
                              </Typography.Text>
                            )}
                        </div>
                      </Form.Item>
                    </Col>

                    <Col lg={8} xs={24}>
                      <Form.Item
                        className="label"
                        required={this.state.selectDuration}
                        label={
                          <span>
                            <Switch
                              style={{ marginRight: "5px" }}
                              size="small"
                              onChange={() => {
                                this.onChangeTimeDuration(
                                  formData.time_range.start,
                                  null,
                                  0,
                                  "time_range.duration"
                                );
                                this.onChangeTimeDuration(
                                  formData.time_range.start,
                                  0,
                                  null,
                                  "time_range.end"
                                );
                                this.setState({
                                  selectDuration: !this.state.selectDuration,
                                });
                              }}
                            ></Switch>
                            {t(moduleName + ".duration")}
                          </span>
                        }
                        {...this.formItemLayout}
                      >
                        {/* show form select if select is not duration
                        show text only if select is duration
                      */}
                        {this.state.selectDuration ? (
                          getFieldDecorator("time_range.duration", {
                            rules: [
                              {
                                required: true,
                                message: t(moduleName + ".duration_required"),
                              },
                            ],
                            initialValue: formData?.time_range?.duration,
                            getValueFromEvent: (e) => {
                              return this.onChangeTimeDuration(
                                formData.time_range.start,
                                e,
                                null,
                                "time_range.duration"
                              );
                            },
                          })(
                            <InputNumber
                              style={{ minWidth: "105px" }}
                              placeholder={t("alarm_rules.duration")}
                              min={1}
                              max={24}
                            ></InputNumber>
                          )
                        ) : (
                          <Typography className="value-time-des">
                            <Typography.Text className="value-time">
                              {formData?.time_range?.duration}
                              &nbsp;
                            </Typography.Text>
                            {alarmRuleStore?.overNight && (
                              <Typography.Text type="warning">
                                {t("alarm_rules.over_night")}
                              </Typography.Text>
                            )}
                          </Typography>
                        )}
                      </Form.Item>
                    </Col>
                    <Col lg={24} xs={24}>
                      <Form.Item
                        className="label"
                        label={t(moduleName + ".timezone")}
                        {...this.formItemLayout}
                      >
                        {getFieldDecorator("time_range.timezone", {
                          rules: [
                            {
                              required: true,
                              message: t(moduleName + ".timezone_required"),
                            },
                          ],
                          initialValue: formData?.time_range?.timezone,
                          getValueFromEvent: (e) => {
                            return this.onChangeField("time_range.timezone", e);
                          },
                        })(
                          <Select className="timezone">
                            {TIMEZONES.map((e) => (
                              <Option value={e?.value} key={e?.value}>
                                {e?.label}
                              </Option>
                            ))}
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col lg={12} xs={24} className="wrap-condition-area">
                  <Row>
                    <Col lg={8} xs={24}>
                      <Form.Item
                        className="label"
                        label={t(moduleName + ".sampling_period")}
                        {...this.formItemLayout}
                      >
                        {getFieldDecorator("sampling_period", {
                          rules: [
                            {
                              required: true,
                              message: t(
                                moduleName + ".sampling_period_required"
                              ),
                            },
                          ],
                          initialValue: formData?.sampling_period,
                          getValueFromEvent: (e) => {
                            return this.onChangeField("sampling_period", e);
                          },
                        })(
                          <Select>
                            {SAMPLING_PERIOD.map((e) => (
                              <Option key={e.value} value={e.value}>
                                {e.label + " " + t("alarm_rules.hours")}
                              </Option>
                            ))}
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                    <Col lg={24} xs={24}>
                      <Form.Item
                        className="label condition-title"
                        label={
                          <span>
                            <span>{t(moduleName + ".condition")}</span>
                          </span>
                        }
                        {...this.formItemLayout}
                      >
                        {/* {getFieldDecorator('condition', {

                    })(<RefBuilder metricList={metricList?.traffic_metrics} condition={formData?.condition} handleChangeCondition={this.handleChangeCondition}></RefBuilder>)} */}
                        {getFieldDecorator("condition", {
                          rules: [
                            {
                              required: true,
                              message: t(moduleName + ".condition_required"),
                            },
                          ],
                          initialValue: formData?.condition || "",
                        })(
                          <RefBuilder
                            metricList={metricList}
                            setVisible={this.setVisible}
                            handleChangeCondition={this.handleChangeCondition}
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Form>
        </Spin>
      </div>
    );
  }
}

const WrapForm = Form.create({ name: "alarm_rule" })(AlarmRuleDetail);

export default withRouter(withTranslation()(WrapForm));
