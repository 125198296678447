import { Col, Row } from "antd";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { OFFSET_OPTIMIZER_COLOR } from "utils/constants";
import AOGPieChart from "../AOGPieChart";
import "./style.scss";

class OffsetOptimization extends Component {
  /**
   * componentDidMount
   *
   */
  constructor(props) {
    super(props);
  }

  render() {
    const { t, data } = this.props;
    const legendColor = [
      OFFSET_OPTIMIZER_COLOR.EXISTING,
      OFFSET_OPTIMIZER_COLOR.PREDICT_GAIN,
      OFFSET_OPTIMIZER_COLOR.PREDICT_LOSS,
      OFFSET_OPTIMIZER_COLOR.GAP,
    ];

    const dataPie = data?.map((item) => {
      const predictPercent =
        item.predicted_aog_percent % 1 === 0
          ? item.predicted_aog_percent
          : Number(item.predicted_aog_percent).toFixed(2);
      const existingPercent =
        item.existing_aog_percent % 1 === 0
          ? item.existing_aog_percent
          : Number(item.existing_aog_percent).toFixed(2);
      const gapPercent = 100 - existingPercent - predictPercent;
      const roundGapPercent =
        gapPercent % 1 === 0 ? gapPercent : Number(gapPercent).toFixed(2);
      const realPredictShow = Math.abs(predictPercent - existingPercent);
      return {
        phase: item.phases,
        is_coord_phase: item.is_coord_phase,
        existing_aog: item.existing_aog,
        predicted_aog: item.predicted_aog,
        predicted_aog_percent: predictPercent,
        existing_aog_percent: existingPercent,
        charts: [
          {
            key: "existing",
            name: t("optimization.existing"),
            value: existingPercent,
            tooltip: true,
            vehical: " %",
          },
          {
            key: "predicted_gain",
            name: t("optimization.predicted_gain"),
            value: item.bias > 0 ? realPredictShow : 0,
            tooltip: true,
            vehical: " %",
          },
          {
            key: "predicted_loss",
            name: t("optimization.predicted_loss"),
            value: item.bias < 0 ? realPredictShow : 0,
            tooltip: true,
            vehical: " %",
          },
          {
            key: "gap",
            name: "GAP",
            value: roundGapPercent,
            tooltip: false,
          },
        ],
      };
    });

    return (
      <div className={this.props.printMode ? "text-print" : ""}>
        <Row justify="center" type="flex" align="middle">
          {dataPie?.map((e) => (
            <Col md={8} xl={6} xxl={6} key={e.phase} className="my-8">
              <div
                style={{
                  background:
                    this.props.printMode && this.props.currentPhase === e.phase
                      ? "#E5E8E8"
                      : null,
                }}
                className={
                  "py-8 " +
                  (this.props.currentPhase === e.phase ? "active" : "")
                }
              >
                <div
                  className={
                    "flex justify-center " +
                    (e.is_coord_phase ? "text-bold" : "")
                  }
                >
                  {t("analysis.phase_name").toUpperCase()}: {e.phase}
                </div>
                <AOGPieChart legendColor={legendColor} data={e.charts} />
                <div className="flex justify-center">
                  <table style={{ width: 200 }}>
                    <tbody className="table-bordered">
                      <tr>
                        <th>{t("optimization.predicted")}</th>
                        <td>{e.predicted_aog}</td>
                        <td>{e.predicted_aog_percent} %</td>
                      </tr>
                      <tr>
                        <th>{t("optimization.existing")}</th>
                        <td>{e.existing_aog}</td>
                        <td>{e.existing_aog_percent} %</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    );
  }
}

export default withTranslation()(OffsetOptimization);
