import BaseApi from "./BaseApi";
import Api from "./Api";
/**
 * Service for user management.
 *
 * @class
 */
class SelectIntersectionService extends BaseApi {
  constructor(id, pathApi, parentStore, intIdsFields) {
    super(pathApi + "/" + id + "/intersections", parentStore);
    this.moduleName = pathApi + "/" + id + "/intersections";
    this.dataKey = "intersections";
    this.keyField = "id";
    this.intIdsFields = intIdsFields ? intIdsFields : "uuids";
  }

  /**
   * getRuleCategories
   * this function return all categories type
   * @param   {function} cb  callback function
   * @param   {function} fb  falseback function
   * @return  {null} -  call the callback function
   */
  add = (scope, ids, cb, fb) => {
    const api = new Api(this.moduleName, this.parentStore);
    api.patchRequest(
      {
        action: "ADD",
        scope: scope,
        [this.intIdsFields]: ids,
      },
      (response) => {
        cb && cb(response?.data);
      },
      (err) => {
        console.log(err);
        fb && fb();
      }
    );
  };

  /**
   * getRuleCategories
   * this function return all categories type
   * @param   {function} cb  callback function
   * @param   {function} fb  falseback function
   * @return  {null} -  call the callback function
   */
  delete = (scope, ids, cb, fb) => {
    const api = new Api(this.moduleName, this.parentStore);
    api.patchRequest(
      {
        action: "REMOVE",
        scope: scope,
        [this.intIdsFields]: ids,
      },
      (response) => {
        cb && cb(response?.data);
      },
      () => {
        fb && fb();
      }
    );
  };
}

export default SelectIntersectionService;
