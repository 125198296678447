import { Checkbox } from "antd";
import React from "react";
import AdjustmentResultChart from "./AdjustmentResultChart";
import {
  CHART_COLOR_SCHEMA,
  GRAPHIC_CHART_TYPE,
  OFFSET_OPTIMIZER_RANDOM_COLOR,
} from "utils/constants";

class AdjustmentResult extends React.Component {
  chartLegend = [];
  constructor(props) {
    super(props);
    this.chartRef = React.createRef();
    this.hiddenLegend = [];
    this.getChartAllLegend();
  }

  /**
   * render
   *
   * @return  {Component}
   */
  handleFilterLengend(item) {
    const findIndex = this.hiddenLegend.findIndex((e) => e === item.key);
    if (findIndex === -1) this.hiddenLegend = [...this.hiddenLegend, item.key];
    else this.hiddenLegend = this.hiddenLegend.filter((e) => e !== item.key);
    this.chartRef.current.handleFilterChartData(this.hiddenLegend);
  }
  rerenderAllChart() {
    this.chartRef.current.rerenderAllChart();
  }
  getChartAllLegend = () => {
    const { rootData, t } = this.props;
    let listLegend = [];
    rootData.offset_results[0]?.predicted_list.forEach((item, id) => {
      listLegend.push({
        key: "predicted_phase_" + item.phases,
        label: t("optimization.phase") + " " + item.phases,
        color:
          item.phases === rootData.phase
            ? CHART_COLOR_SCHEMA.YELLOW
            : OFFSET_OPTIMIZER_RANDOM_COLOR[id],
        unit: "",
        yAxis: "left",
        toolTip: (value) => Math.round(value),
        type: GRAPHIC_CHART_TYPE.line,
        legendTooltip: "optimizer.legend_aog_predict",
      });
    });
    listLegend.push({
      key: "total_aog",
      label: "Total AoG",
      color: CHART_COLOR_SCHEMA.BLUE,
      unit: "",
      yAxis: "left",
      toolTip: (value) => Math.round(value),
      type: GRAPHIC_CHART_TYPE.line,
      legendTooltip: "optimizer.legend_aog_predict",
    });
    this.chartLegend = listLegend;
  };
  render() {
    return (
      <div className="w-full">
        <div className="mx-16 flex justify-end chart-info-legend wrap-analysis-legend">
          {this.chartLegend.map((legend) => (
            <Checkbox
              key={legend.key}
              defaultChecked
              style={{ "--checkbox-chart-color": legend.color }}
              onChange={() => this.handleFilterLengend(legend)}>
              {legend.label}
            </Checkbox>
          ))}
        </div>
        <div className="flex justify-center w-full">
          <div
            className="w-full"
            style={{
              height: this.props.height ? this.props.height : 480,
            }}>
            <AdjustmentResultChart
              onChartZoom={() => {}}
              ref={this.chartRef}
              {...this.props}
              chartLegend={this.chartLegend}
              hiddenLegend={this.hiddenLegend}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default AdjustmentResult;
