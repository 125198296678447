import { Checkbox, Select } from "antd";
import ChartItem from "components/ChartItem";
import { inject, observer } from "mobx-react";
import moment from "moment";
import queryString from "query-string";
import React, { Component, createRef } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import { FixedSizeList as List } from "react-window";
import {
  ANALYSIS_EXTRA_TYPE,
  ANALYSIS_TYPE,
  DATE_FORMAT,
  SYSTEM_ROUTES_PATH,
  TIMEOUT_RESIZE,
} from "utils/constants";
import AnalysisDefault from "./Analyses/AnalysisDefault";
import AnalysisMOE from "./Analyses/AnalysisMOE";
import AnalysisSplitMonitor from "./Analyses/AnalysisSplitMonitor";
import "./style.scss";

@inject("rootStore")
@observer
class AnalysisResult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      resizing: false,
    };
    this.listRef = createRef();
    this.scrollHeight = 0;
  }
  componentDidMount() {
    const {
      rootStore: { analysisStore, commonStore },
    } = this.props;
    // set default filter data in tatic mode
    let defaultParams = queryString.parse(
      location.href.slice(location.href.indexOf("?") + 1)
    );

    if (commonStore?.isFromStatic) analysisStore.setFilterData(defaultParams);
    window.addEventListener("resize", this.handleWindowResize);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowResize);
  }
  handleWindowResize = () => {
    const {
      rootStore: {
        analysisStore: { printPReview },
      },
    } = this.props;
    //do not resize when in print preview cuz it will re render the PDF file => this is burnning RAM and TIME
    if (!printPReview) {
      this.setState({ resizing: true });
      setTimeout(() => {
        this.setState({ resizing: false });
      }, TIMEOUT_RESIZE);
    }
  };
  convertTimeFormat = (value) => {
    return moment(value, DATE_FORMAT.backend).format(
      DATE_FORMAT.analysis_range
    );
  };

  print = () => {
    // window.print();
    const {
      rootStore: {
        analysisStore: { togglePrintPReviewVisibility },
      },
    } = this.props;

    togglePrintPReviewVisibility();
  };
  renderExtraOptions = () => {
    const {
      t,
      rootStore: {
        chartStore: {
          chartInfo,
          currentChartTypeFrontEnd,

          setCurrentChartTypeFrontEnd,
        },
      },
    } = this.props;
    switch (chartInfo.analysis_type) {
      case ANALYSIS_TYPE.PED_TYPE:
        return (
          <Select
            size="large"
            value={currentChartTypeFrontEnd}
            onChange={(value) => {
              setCurrentChartTypeFrontEnd(ANALYSIS_TYPE.PED_TYPE, value);
            }}>
            {ANALYSIS_EXTRA_TYPE[chartInfo.analysis_type]?.map((subType) => (
              <Select.Option key={subType.key} value={subType.value}>
                {t(subType.label)}
              </Select.Option>
            ))}
          </Select>
        );
      case ANALYSIS_TYPE.PT_TYPE:
        return (
          <Checkbox
            checked={
              currentChartTypeFrontEnd === ANALYSIS_TYPE.PT_HIGHLIGHT_TYPE
                ? true
                : false
            }
            onChange={(e) => {
              if (e.target.checked)
                setCurrentChartTypeFrontEnd(
                  ANALYSIS_TYPE.PT_TYPE,
                  ANALYSIS_TYPE.PT_HIGHLIGHT_TYPE
                );
              else
                setCurrentChartTypeFrontEnd(
                  ANALYSIS_TYPE.PT_TYPE,
                  ANALYSIS_TYPE.DEFAULT_PT
                );
            }}
            style={{ lineHeight: "32px" }}>
            {" "}
            {t("analysis.hightline_actual")}
          </Checkbox>
        );
      case ANALYSIS_TYPE.APPOARCH_DELAY:
        return (
          <Select
            size="large"
            value={currentChartTypeFrontEnd}
            onChange={(value) => {
              setCurrentChartTypeFrontEnd(ANALYSIS_TYPE.APPOARCH_DELAY, value);
            }}>
            {ANALYSIS_EXTRA_TYPE[chartInfo.analysis_type]?.map((subType) => (
              <Select.Option key={subType.key} value={subType.value}>
                {t(subType.label)}
              </Select.Option>
            ))}
          </Select>
        );
      case ANALYSIS_TYPE.QUEUE_LENGTH:
        return (
          <Select
            size="large"
            value={currentChartTypeFrontEnd}
            onChange={(value) => {
              setCurrentChartTypeFrontEnd(ANALYSIS_TYPE.QUEUE_LENGTH, value);
            }}>
            {ANALYSIS_EXTRA_TYPE[chartInfo.analysis_type]?.map((subType) => (
              <Select.Option key={subType.key} value={subType.value}>
                {t(subType.label)}
              </Select.Option>
            ))}
          </Select>
        );
      case ANALYSIS_TYPE.PREEMPTION_PRIORITY:
        return (
          <Select
            size="large"
            value={currentChartTypeFrontEnd}
            onChange={(value) => {
              setCurrentChartTypeFrontEnd(
                ANALYSIS_TYPE.PREEMPTION_PRIORITY,
                value
              );
            }}>
            {ANALYSIS_EXTRA_TYPE[chartInfo.analysis_type]?.map((subType) => (
              <Select.Option key={subType.key} value={subType.value}>
                {t(subType.label)}
              </Select.Option>
            ))}
          </Select>
        );

      default:
        return <></>;
    }
  };

  renderListItem = ({ height, width }) => {
    const {
      rootStore: {
        chartStore: { chartInfo, chartData },
      },
    } = this.props;

    return (
      <List
        ref={this.listRef}
        className="list"
        height={height}
        itemCount={chartData?.length}
        itemSize={chartInfo?.isCompareMode ? width * 0.8 : width * 0.4}
        width={width}>
        {ChartItem.bind(this)}
      </List>
    );
  };

  goToScheduleLink = () => {
    const {
      rootStore: {
        analysisScheduleStore: { setPresetItemFromAnalysis },
        analysisStore: {
          filterData,
          listIntersections,
          transferAnalysisFilterBeforeSend,
        },
      },
      history,
    } = this.props;
    const presetObject = {
      generalData: {
        ...filterData,
        ...transferAnalysisFilterBeforeSend(),
      },
      intersection: listIntersections.find(
        (e) => e.id === filterData.intersection_id
      ),
    };
    setPresetItemFromAnalysis(presetObject);
    history.push(SYSTEM_ROUTES_PATH.AnalysisSchedule + "/create");
  };

  renderResult = () => {
    const {
      rootStore: {
        chartStore: { chartInfo },
      },
    } = this.props;

    switch (chartInfo?.analysis_type) {
      case ANALYSIS_TYPE.MOE_ANALYSIS:
        return (
          <AnalysisMOE
            loading={this.state.loading}
            resizing={this.state.resizing}
            convertTimeFormat={this.convertTimeFormat}
            print={this.print}
            renderExtraOptions={this.renderExtraOptions}
            renderListItem={this.renderListItem}
            goToScheduleLink={this.goToScheduleLink}
          />
        );

      case ANALYSIS_TYPE.SPLIT_MONITOR:
        return (
          <AnalysisSplitMonitor
            loading={this.state.loading}
            resizing={this.state.resizing}
            convertTimeFormat={this.convertTimeFormat}
            print={this.print}
            goToScheduleLink={this.goToScheduleLink}
          />
        );
      default:
        return (
          <AnalysisDefault
            loading={this.state.loading}
            resizing={this.state.resizing}
            convertTimeFormat={this.convertTimeFormat}
            print={this.print}
            renderExtraOptions={this.renderExtraOptions}
            renderListItem={this.renderListItem}
            goToScheduleLink={this.goToScheduleLink}
          />
        );
    }
  };
  // componentDidMount() {
  //   getExcelFile();
  // }
  render() {
    return this.renderResult();
  }
}

export default withRouter(withTranslation()(AnalysisResult));
