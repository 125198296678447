export const CHART_COLOR_SCHEMA = {
  RT_PERCENT: "#922b21",
  AOR_PERCENT: "#e74c3c",
  AOR_VOLUME: "#1f23f0",

  GAP_OUT_PERCENT: "#2ecc71",
  MAX_OUT_PERCENT: "#e74c3c",
  FORCE_OFF_PERCENT: "#3498db",
  AVERAGE_SPLIT: "#6b757d",

  AOG_PERCENT: "#119956",
  AOG_VOLUME: "#009999",
  GT_PERCENT: "#b8c142",
  PR: "#636d75",
  VOLUME: "#616a6b",

  PROGRAM_SPLIT: "#616a6b",
  PHASE_SKIP: "#f1c40f",

  DETECTOR: "#1f618d",
  GREEN: "#2ecc71",
  LIGHT_GREEN: "#cefad0",
  BLUE: "#1f23f0",
  YELLOW: "#f1c40f",
  RED: "#e74c3c",
  GREY: "#606060",
  LIGHT_GREY: "#BFCAE6",
  GRAY: "#ccc",
  GREEN_SIEMENS: "#009999",
  PINK: "#e28f93",
  DEFAULT: "var(--text)",
  BLACK: "#000",
  DURATION: "steelblue",
  DARK_GREEN: "#003300",
  LOWER_GREEN: "#80cccc",
  PURPIL: "#BF40BF",

  YELLOW_TRAP: "#f8e186",
};
export const CHART_LINE_COLOR = [
  "#f16707",
  "#0375e1",
  "#3b945e",
  "#7b7c56",
  "#de0a65",
];
export const GRAPHIC_CHART_TYPE = {
  line: "line",
  ziczac: "ziczac",
  point: "point",
  bar: "bar",
};
