import React, { Component } from "react";
import { observer, inject } from "mobx-react";

import { Switch, Select, Tooltip, Form } from "antd";
import Control from "react-leaflet-control";
import { withTranslation } from "react-i18next";

import { MAP_SETTING } from "utils/constants";
import "./style.scss";
import CollapseComponent from "../../../../components/CollapseComponent";

const formItemLayout = {
  labelCol: {
    span: 16,
  },
  wrapperCol: {
    span: 8,
  },
};

@inject("rootStore")
@observer
class MapFilter extends Component {
  render() {
    const {
      t,
      rootStore: { dashboardStore },
      widthMap,
    } = this.props;
    return (
      <Control
        position="topright"
        className="heatmap-switch-icon container-collapsable"
      >
        <Form.Item
          label={t("heatmap.swith")}
          className="switch-title"
          {...formItemLayout}
        >
          <Switch
            checked={!dashboardStore?.mapSetting?.layer_hidden}
            onChange={(value) => {
              dashboardStore.handleMapSettingChange(
                MAP_SETTING.HEATMAP,
                !value
              );
              dashboardStore.resetIsSave();
              dashboardStore.clearTimeoutSucces();
            }}
          />
        </Form.Item>
        <Tooltip>
          <Form.Item label={t("heatmap.metric_filter")}>
            <Select
              className="heatmap-header-matrix"
              value={dashboardStore.selectedColorMatrix}
              onChange={(e) => {
                dashboardStore.handleColorMatrixChange(e);
              }}
            >
              {dashboardStore.agencyColorMatrix.map((matrix) => (
                <Select.Option key={matrix.id} value={matrix.id}>
                  {matrix.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Tooltip>
        <CollapseComponent
            className="heatmap-header-intersection"
            width={widthMap.width}
            height={widthMap.height}>
          <Tooltip
            placement="rightBottom"
            title={t("heatmap.intersection_filter_tooltip")}
          >
            <Form.Item label={t("heatmap.intersection_filter")}>
              <Select
                showSearch
                showArrow={false}
                allowClear
                placeholder={t("heatmap.intersection_placeholder")}
                filterOption={false}
                value={dashboardStore.searchString}
                autoClearSearchValue={false}
                defaultActiveFirstOption={false}
                onSearch={(value) => {
                  dashboardStore.updateSerachString(value);
                }}
                onChange={(value, obj) => {
                  dashboardStore.updateSerachString(obj?.props?.children);
                }}
              >
                {dashboardStore.listFilterIntersections.map((d) => (
                  <Select.Option key={d.id}>{d.name}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Tooltip>
        </CollapseComponent>
      </Control>
    );
  }
}

export default withTranslation()(MapFilter);
