import React from "react";
import { Col, Tooltip, DatePicker } from "antd";
import { observer, inject } from "mobx-react";
import "./style.scss";
import { ACTIONS_LIST, RESOURCE_LIST, DATE_FORMAT } from "utils/constants";
import BaseManagementComponents from "../../../../components/BaseManagementComponents";
import { withTranslation } from "react-i18next";
import moment from "moment";

@inject("rootStore")
@observer
class ListConfigModalContent extends BaseManagementComponents {
  constructor(props) {
    const { t } = props;
    super(props.rootStore.intersectionConfigStore, props);
    this.state = {
      displayModal: true,
    };
    this.rowKey = "_id";
    this.enableRowSelection = false;
    this.enableCreateButton = false;
    this.columns = this.getTableColumns();
    this.tableInlineActions = [
      {
        key: "show",
        tooltip: t("intersection_config.show"),
        action: this.handleChangeConfig,
        resource: RESOURCE_LIST.REPORT_TEMPLATE,
        permission: ACTIONS_LIST.CREATE,
        icon: "login",
        passingData: (item) => {
          return [item];
        },
      },
    ];
  }
  handleChangeConfig = (item) => {
    this.currentStore?.changeCurrentConfig(item);
    this.props.closeModal();
  };
  getTableColumns = () => {
    const { t } = this.props;
    return [
      {
        title: t("intersection_config.uuid"),
        key: "uuid",
        render: (item) => item?.uuid,
      },
      {
        title: t("intersection_config.valid_from_time"),
        key: "valid_from_time",
        render: (item) =>
          moment(item?.valid_from_time, DATE_FORMAT.backend).format(
            DATE_FORMAT.full
          ),
      },
      {
        title: t("intersection_config.valid_to_time"),
        key: "valid_to_time",
        render: (item) => {
          return moment(item?.valid_to_time, DATE_FORMAT.backend).format(
            DATE_FORMAT.full
          );
        },
      },
    ];
  };
  renderFilterComponent = () => {
    const { t } = this.props;

    return (
      <div className="wrap-filter">
        <Col lg={12} xl={12}>
          <Tooltip title={t("intersection_config.filter_range_valid_text")}>
            <DatePicker.RangePicker
              format={DATE_FORMAT.day_only}
              defaultValue={[
                this.currentStore?.filterData?.from_date
                  ? moment.unix(this.currentStore?.filterData?.from_date / 1000)
                  : null,
                this.currentStore?.filterData?.to_date
                  ? moment.unix(this.currentStore?.filterData?.to_date / 1000)
                  : null,
              ]}
              onChange={(e) => {
                this.currentStore.handleFilterDataChange(
                  "from_time",
                  e?.[0]?.startOf("day").format("x"),
                  false
                );
                this.currentStore.handleFilterDataChange(
                  "to_time",
                  e?.[1]?.endOf("day").format("x")
                );
              }}
            />
            {/* <Input
                            value={this.currentStore?.filterData?.text}
                         
                            placeholder={t("alarm_rules.search_placholer")}
                        /> */}
          </Tooltip>
        </Col>
      </div>
    );
  };
  componentDidMount() {
    // super.componentDidMount();
  }
  render() {
    /**this code cause when click to open handle action, will only receive actionObject!
     *
     *  */
    return <div>{super.render()}</div>;
  }
}

export default withTranslation()(ListConfigModalContent);
