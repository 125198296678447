import { Button } from "antd";
import * as d3 from "d3";
import React, { Component, createRef } from "react";
import { FONT_STRING_CAVAS } from "utils/constants";
import { HISTORIC_SEEK_TYPE } from "utils/constants/optimizerConstant";
class BaseLeftContent extends Component {
  constructor(props) {
    super(props);
    this.chartRef = createRef();
    this.areaRef = createRef();
    this.ctx = null;
  }

  drawYaxis = () => {
    const { width, height } = this.props;
    const [startY, endY] = [64, height];
    const tickPadding = 3,
      tickSize = 3,
      yTicks = this.yScale.ticks(),
      yTickFormat = this.yScale.tickFormat();
    this.ctx.lineWidth = 0.5;
    this.ctx.strokeStyle = "black";
    this.ctx.beginPath();
    yTicks.forEach((d) => {
      this.ctx.moveTo(width - 2, this.yScale(d) + startY);
      this.ctx.lineTo(width - 2 - tickSize, this.yScale(d) + startY);
    });
    this.ctx.stroke();

    this.ctx.beginPath();
    this.ctx.moveTo(width - tickSize, 0);
    this.ctx.lineTo(width - 2, startY);
    this.ctx.lineTo(width - 2, endY);
    this.ctx.lineTo(width - tickSize, endY);
    this.ctx.stroke();

    this.ctx.textAlign = "right";
    this.ctx.textBaseline = "middle";
    this.ctx.fillStyle = "black";

    yTicks.forEach((d) => {
      this.ctx.beginPath();
      if (d)
        this.ctx.fillText(
          yTickFormat(d),
          width - tickSize - tickPadding,
          this.yScale(d) + startY
        );
      else
        this.ctx.fillText(
          yTickFormat(d),
          width - tickSize - tickPadding,
          this.yScale(d + 1) + startY
        );
    });
  };

  drawIntersectionInfo = () => {
    const { width, dataSourceIntersection } = this.props;
    dataSourceIntersection?.forEach((int) => {
      this.ctx.beginPath();
      this.ctx.textAlign = "left";
      this.ctx.font = `20px ${FONT_STRING_CAVAS}`;
      this.ctx.textBaseline = "top";
      const startY = 24;
      this.ctx.fillText(int.int_name, 20, this.yScale(int.coordY) + startY);
      this.ctx.font = `14px ${FONT_STRING_CAVAS}`;
      this.ctx.fillText(
        "Distance: " + int.distance + "(feet)",
        20,
        this.yScale(int.coordY) + startY + 24
      );
      this.ctx.fillText(
        "Offset: " + int.offset + "(s)",
        20,
        this.yScale(int.coordY) + startY + 40
      );

      this.ctx.fillText(
        "Cycle: " + int.cycle + "(s)",
        20,
        this.yScale(int.coordY) + startY + 56
      );
      this.ctx.closePath();
    });
    dataSourceIntersection?.forEach((int) => {
      this.ctx.save();
      this.ctx.translate(width - 60, this.yScale(int.coordY) + 42);
      this.ctx.beginPath();
      this.ctx.fillText("1", 0, 0);
      this.ctx.fillText("2", 0, 26);
      this.ctx.rotate(-0.5 * Math.PI);
      this.ctx.fillText("Ring", -32, -20);
      this.ctx.closePath();
      this.ctx.restore();
    });
  };

  drawContent = (yScale) => {
    const { width, height } = this.props;
    this.yScale = yScale;
    this.ctx?.clearRect(0, 0, width, height);
    this.drawYaxis();
    this.drawIntersectionInfo();
  };
  componentDidMount() {
    const { width, height } = this.props;
    var base = d3.select(this.chartRef.current);
    var chart = base
      .append("canvas")
      .attr("width", width)
      .attr("height", height);
    this.ctx = chart.node().getContext("2d");
    this.ctx.imageSmoothingQuality = "high";
  }

  render() {
    const { width, seekTo, height } = this.props;
    return (
      <div>
        <div
          style={{
            position: "sticky",
            height: "min-content",
            top: 0,
            left: 0,
            zIndex: 25,
            background: "transparent",
            // width: "calc(100% - 300px)",
          }}>
          <div
            ref={this.axisAreaRef}
            style={{ height: 24, width: width - 8, display: "flex" }}>
            <Button
              size="small"
              onClick={() => seekTo(HISTORIC_SEEK_TYPE.LAST_PREV)}
              style={{ borderRadius: "0", width: "100%" }}
              icon="step-backward"></Button>
            <Button
              size="small"
              onClick={() => seekTo(HISTORIC_SEEK_TYPE.PREV_2_MIN)}
              style={{ borderRadius: "0", width: "100%" }}
              icon="backward"></Button>
            <Button
              size="small"
              onClick={() => seekTo(HISTORIC_SEEK_TYPE.PREV_1_MIN)}
              style={{ borderRadius: "0", width: "100%" }}
              icon="caret-left"></Button>
            <Button
              size="small"
              onClick={() => seekTo(HISTORIC_SEEK_TYPE.NEXT_1_MIN)}
              style={{ borderRadius: "0", width: "100%" }}
              icon="caret-right"></Button>
            <Button
              size="small"
              onClick={() => seekTo(HISTORIC_SEEK_TYPE.NEXT_2_MIN)}
              style={{ borderRadius: "0", width: "100%" }}
              icon="forward"></Button>
            <Button
              size="small"
              onClick={() => seekTo(HISTORIC_SEEK_TYPE.LAST_NEXT)}
              style={{ borderRadius: "0", width: "100%" }}
              icon="step-forward"></Button>
          </div>
        </div>
        <div
          ref={this.chartRef}
          style={{
            width: width,
            height: height,
          }}></div>
      </div>
    );
  }
}

export default BaseLeftContent;
