import { Form, Tooltip } from "antd";

import React, { Component } from "react";
import { Field } from "formik";
/**
 * Component for showing details of the user.
 *
 * @component
 */

class AntFieldmik extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const {
      label,
      required,
      className,
      children,
      hideExplainError,
      ...otherProps
    } = this.props;
    return (
      <Field {...otherProps}>
        {(formProps) => {
          const {
            field: { name },
            form: { errors },
          } = formProps;
          const fieldList = name.split(".");
          let err = errors;

          fieldList.forEach((key) => {
            if (err) err = err[key];
          });
          return (
            <Form.Item
              className={className}
              required={required}
              label={label}
              validateStatus={err ? "error" : null}>
              {<Tooltip title={err}>{children(formProps)}</Tooltip>}
              {hideExplainError ? (
                <></>
              ) : (
                <div className="ant-form-explain absolute">{err}</div>
              )}
            </Form.Item>
          );
        }}
      </Field>
    );
  }
}

export default AntFieldmik;
