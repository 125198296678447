import AppoarchDelayChart from "components/Charts/AppoarchDelayChart";
import ArrivalOnGreen from "components/Charts/ArrivalOnGreen";
import ArrivalOnRed from "components/Charts/ArrivalOnRed";
import CoordinationChart from "components/Charts/CoordinationChart";
import CoordinationHealth from "components/Charts/CoordinationHealth";
import PedestrianDelayChart from "components/Charts/PedestrianDelayChart";
import PedestrianActivitiesChart from "components/Charts/PerdestrianActivitiesChart";
import PhaseTerminationChart from "components/Charts/PhaseTerminationChart";
import PhaseTerminationHighlight from "components/Charts/PhaseTerminationHighlight";
import PreemptDetail from "components/Charts/PreemptPriorityGroup/PreemptDetail";
import PreemptRequest from "components/Charts/PreemptPriorityGroup/PreemptRequest";
import PreemptTSPDetail from "components/Charts/PreemptPriorityGroup/PreemptTSPDetail";
import QueueLengthChart from "components/Charts/QueueLengthChart";
import SplitFailure from "components/Charts/SplitFailure";
import SplitMonitor from "components/Charts/SplitMonitor/index";
import TurningMovement from "components/Charts/TurningMovement";
import Volume from "components/Charts/VolumeChart";
import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { ANALYSIS_TYPE } from "utils/constants";
import "./style.scss";
import WrapChartComponent from "./WrapChartComponent";
import WrapPreemptDetailChart from "./WrapChartPreempt/WrapPreemptDetailChart";
import WrapPreemptRequestChart from "./WrapChartPreempt/WrapPreemptRequestChart";
import WrapPreemptTSPDetailChart from "./WrapChartPreempt/WrapPreemptTSPDetailChart";
import WrapChartSplitMonitor from "./WrapChartSplitMonitor";
import WrapChartTurningMovement from "./WrapChartTurningMovement";
import WrapChartChartVolume from "./WrapChartVolume";
import WrapChartRLV from "./WrapChartRLV";
import RLVCount from "components/Charts/RLVCount";

@inject("rootStore")
@observer
class ChartComponent extends Component {
  constructor(props) {
    super(props);
    // create a ref to store the textInput DOM element
    this.dataProps = this.transformPropsToChartFormat();
  }

  transformPropsToChartFormat = () => {
    const {
      rootStore: { chartStore },
      data,
      isCompareMode,
      isPrintMode,
      chartType,
    } = this.props;
    let dataProps = {
      isChartPerformanceMetric: true,
      data: data,
      hiddenLegend: chartStore.filterData?.hiddenLegend,
      chartType: chartType,
      from_time: isCompareMode
        ? chartStore.filterData?.comapre_from_time
        : chartStore.filterData?.from_time,
      to_time: isCompareMode
        ? chartStore.filterData?.comapre_to_time
        : chartStore.filterData?.to_time,
      height: isCompareMode ? (isPrintMode ? "40%" : "50%") : "100%",
      compareMode: isCompareMode,
      isPrintMode: isPrintMode,
      gapdata: chartStore.chartInfo?.isGapData ? chartStore.gapData : null,
    };
    return dataProps;
  };
  renderChartByChartType = (chartType) => {
    switch (chartType) {
      case ANALYSIS_TYPE.AOR_TYPE:
        return <ArrivalOnRed {...this.dataProps} />;
      case ANALYSIS_TYPE.AOG_TYPE:
        return <ArrivalOnGreen {...this.dataProps} />;
      case ANALYSIS_TYPE.DEFAULT_PT:
        return <PhaseTerminationChart {...this.dataProps} />;
      case ANALYSIS_TYPE.PT_HIGHLIGHT_TYPE:
        return <PhaseTerminationHighlight {...this.dataProps} />;
      case ANALYSIS_TYPE.CD_TYPE:
        return <CoordinationChart {...this.dataProps} />;
      case ANALYSIS_TYPE.CH_TYPE:
        return <CoordinationHealth {...this.dataProps} />;
      case ANALYSIS_TYPE.SPLIT_FAILURE:
        return <SplitFailure {...this.dataProps} />;
      case ANALYSIS_TYPE.APPOARCH_DELAY_LANE_VIEW:
        return <AppoarchDelayChart {...this.dataProps} />;
      case ANALYSIS_TYPE.APPOARCH_DELAY_PHASE_VIEW:
        return <AppoarchDelayChart {...this.dataProps} />;
      case ANALYSIS_TYPE.QUEUE_LENGTH_PHASE_VIEW:
        return <QueueLengthChart {...this.dataProps} />;
      case ANALYSIS_TYPE.QUEUE_LENGTH_LANE_VIEW:
        return <QueueLengthChart {...this.dataProps} />;

      case ANALYSIS_TYPE.PED_DELAY:
        return <PedestrianDelayChart {...this.dataProps} />;
      case ANALYSIS_TYPE.PED_ACTIVITIES:
        return <PedestrianActivitiesChart {...this.dataProps} />;
      case ANALYSIS_TYPE.VOLUMNE:
        return <Volume {...this.dataProps} />;
      case ANALYSIS_TYPE.TURNING_MOVEMENT:
        return <TurningMovement {...this.dataProps} />;
      case ANALYSIS_TYPE.RLV_COUNT:
        return <RLVCount {...this.dataProps} />;
      case ANALYSIS_TYPE.SPLIT_MONITOR:
        return <SplitMonitor {...this.dataProps} />;

      // pp group
      case ANALYSIS_TYPE.PREEMPTION_PRIORITY_REQUEST:
        return <PreemptRequest {...this.dataProps} />;

      case ANALYSIS_TYPE.PREEMPTION_PRIORITY_DETAIL:
        return <PreemptDetail {...this.dataProps} />;
      case ANALYSIS_TYPE.PREEMPTION_PRIORITY_TSP_DETAIL:
        return <PreemptTSPDetail {...this.dataProps} />;
      default:
        return <></>;
    }
  };

  render() {
    const {
      chartType,
      rootStore: {
        chartStore: { updateFilterData },
      },
    } = this.props;
    let component = this.renderChartByChartType(chartType);

    switch (chartType) {
      case ANALYSIS_TYPE.VOLUMNE:
        return (
          <WrapChartChartVolume
            updateFilterData={updateFilterData}
            {...this.props}
            dataProps={this.dataProps}>
            {component}
          </WrapChartChartVolume>
        );
      case ANALYSIS_TYPE.TURNING_MOVEMENT:
        return (
          <WrapChartTurningMovement
            updateFilterData={updateFilterData}
            {...this.props}
            dataProps={this.dataProps}>
            {component}
          </WrapChartTurningMovement>
        );
      case ANALYSIS_TYPE.RLV_COUNT:
        return (
          <WrapChartRLV
            updateFilterData={updateFilterData}
            {...this.props}
            dataProps={this.dataProps}>
            {component}
          </WrapChartRLV>
        );
      case ANALYSIS_TYPE.SPLIT_MONITOR:
        return (
          <WrapChartSplitMonitor {...this.props} dataProps={this.dataProps}>
            {component}
          </WrapChartSplitMonitor>
        );

      // PP group
      case ANALYSIS_TYPE.PREEMPTION_PRIORITY_REQUEST:
        return (
          <WrapPreemptRequestChart
            updateFilterData={updateFilterData}
            {...this.props}
            dataProps={this.dataProps}>
            {component}
          </WrapPreemptRequestChart>
        );

      case ANALYSIS_TYPE.PREEMPTION_PRIORITY_DETAIL:
        return (
          <WrapPreemptDetailChart
            updateFilterData={updateFilterData}
            {...this.props}
            dataProps={this.dataProps}>
            {component}
          </WrapPreemptDetailChart>
        );
      case ANALYSIS_TYPE.PREEMPTION_PRIORITY_TSP_DETAIL:
        return (
          <WrapPreemptTSPDetailChart
            updateFilterData={updateFilterData}
            {...this.props}
            dataProps={this.dataProps}>
            {component}
          </WrapPreemptTSPDetailChart>
        );

      default:
        return (
          <WrapChartComponent {...this.props} dataProps={this.dataProps}>
            {component}
          </WrapChartComponent>
        );
    }
  }
}

export default withTranslation()(ChartComponent);
