import React, { Component } from "react";
import {
  Table,
  Drawer,
  Button,
  Row,
  Modal,
  PageHeader,
  Divider,
  Tooltip,
} from "antd";
import { Link } from "react-router-dom";
import SpinLoading from "../PrivateLayout/SpinLoading";
import { DETAIL_POPUP_WIDTH, ACTIONS_LIST } from "utils/constants";
import queryString from "query-string";
import Unauthorized from "../../containers/StaticPages/403";
import "./style.scss";

const { confirm } = Modal;

/**
 * Component for base managment module.
 * it's comon behavior of all management componenent
 *
 * @component
 */
class BaseManagementComponents extends Component {
  constructor(currentStore, props) {
    super(props);
    this.currentStore = currentStore; //current using data store
  }
  resourceName = "";
  columns = []; //data format of table(should overide by child component)
  rowKey = "id"; //key field of data list(should overide by child component)
  moduleName = "table"; //module name(should overide by child component)
  drawerWithPercent = DETAIL_POPUP_WIDTH; //drawerWithPercent(should overide by child component)

  forceEnableRowSelection = false;
  enableRowSelection = true;
  enableCreateButton = true;
  enableActionBox = true;
  enableFilterBox = true;
  enableRedirectPage = false; //redirect to create page not open a drawer
  pageTitle = "";
  headerButtonActions = []; //action button list on header of the page
  defailFromRef = null; //refrence of drawer to call drawer function
  pageCustomClass = "";

  buttonActions = []; //action button list when checked item on list
  tableInlineActions = [];
  /**
   * componentDidMount
   * init page it's will get list all item with default filterdata object
   *
   */
  componentDidMount() {
    if (this.props.location?.search?.length > 0) {
      let filterObject = queryString?.parse(this.props.location.search);
      this.currentStore?.setFilterData(filterObject);
    }
    this.currentStore?.getData();
  }

  //-------- render common component ----------
  /**
   * handleTableAction
   * this function will trigger when user select an action on combobox
   * @param   {String} value  Action name
   * @param   {Object} data  Custom data
   * @return  {null} -  call the confirm box
   */
  handleTableAction = (value, data = null) => {
    let selectedItem = null;
    if (this.buttonActions.length > 0) {
      selectedItem = this.buttonActions.find((item) => {
        return item.key == value;
      });
    }
    if (selectedItem) {
      if (selectedItem.confirm) {
        this.showConfirmBox(
          selectedItem.confirmTitle,
          selectedItem.confirmContent,
          selectedItem.action,
          data
        );
      } else {
        selectedItem.action(data);
        this.currentStore?.clearSelectedItems();
      }
    }
  };
  /**
   * handleDrawerOnClose
   * this function will check is current data changed or not and then close the form
   * @return  {null} -  call the confirm box
   */
  handleDrawerOnClose = (forceClose = false) => {
    if (
      this.defailFromRef?.checkFormDirty != undefined &&
      this.defailFromRef?.checkFormDirty() &&
      !forceClose
    ) {
      const { t } = this.props;
      let currentStore = this.currentStore;
      confirm({
        title: t("common.confirm_redirect_title"),
        content: t("common.confirm_redirect_content"),
        okText: t("common.leave"),
        okType: "danger",
        cancelText: t("common.stay"),
        onOk() {
          currentStore?.closeCreateOrEditForm();
        },
      });
    } else {
      this.currentStore?.closeCreateOrEditForm();
    }
  };

  /**
   * showConfirmBox
   * this function will trigger when need a confirm infomation
   * @param   {String} title  title of confirm box
   * @param   {String} content  content of confirm box
   * @param   {Function} action  Action trigger when click ok
   * @param   {Object} data  Custom data
   * @return  {null} -  call deactive of user store and handleActiveDeactiveUser function
   */
  showConfirmBox = (title, content, action, data) => {
    const { t } = this.props;
    const context = this;
    confirm({
      title: title,
      content: content,
      okText: t("user.yes"),
      okType: "danger",
      cancelText: t("user.cancel"),
      onOk() {
        action(data);
        context.currentStore?.clearSelectedItems();
      },
      onClose() {
        context.currentStore?.clearSelectedItems();
      },
      style: { whiteSpace: "pre-line" },
    });
  };

  /**
   * renderKeyColumn
   * this function return the default key colum format of table
   * @return  {Component}
   */
  renderKeyColumn = (value, row, resource = null) => {
    const {
      t,
      rootStore: { myStore },
    } = this.props;

    return myStore.hasPermission(
      ACTIONS_LIST.VIEW,
      resource ? resource : this.resourceName,
      false,
      row.owner_id
    ) ? (
      <div>
        <a
          title={value}
          onClick={() => {
            this.currentStore?.showCreateOrEditForm(
              t(this.moduleName + ".show_form"),
              row[this.rowKey],
              false
            );
          }}>
          {value}
        </a>
      </div>
    ) : (
      { value }
    );
  };

  /**
   * renderTableButtonActions
   * render action button on top of table(after click on selection box)
   * @return  {Component}
   */
  renderTableButtonActions = () => {
    if (this.buttonActions.length > 0) {
      const {
        rootStore: { myStore },
      } = this.props;
      return this.buttonActions.map((e) => {
        if (
          myStore.hasPermission(
            e.permission,
            e.resource ? e.resource : this.resourceName,
            true
          )
        ) {
          return (
            <Button
              className={e.className}
              disabled={
                e.notEnable
                  ? e.notEnable()
                  : this.currentStore?.selectedItems?.length <= 0
              }
              icon={!e.child ? e.type : ""}
              key={e.key}
              onClick={() => this.handleTableAction(e.key)}>
              {e.child && e.child}
              {e.title}
            </Button>
          );
        }
      });
    }
  };

  /**
   * renderHeaderButtonActions
   * render action button on headers
   * @return  {Component}
   */
  renderHeaderButtonActions = () => {
    const {
      rootStore: { myStore },
    } = this.props;
    let defaultHeaderActions = [
      {
        key: "create",
        className: "create-button",
        title: this.props.t(this.moduleName + ".create"),
        icon: "plus",
        onClick: () => {
          this.currentStore?.showCreateOrEditForm(
            this.props.t(this.moduleName + ".create_form_title")
          );
        },
        isALink: this.enableRedirectPage,
        permission: ACTIONS_LIST.CREATE,
        linkTo: this.moduleName + "/create",
      },
    ];
    let allButton = [...this.headerButtonActions];
    if (this.enableCreateButton) {
      allButton = [...this.headerButtonActions, ...defaultHeaderActions];
    }
    let headerButtons = [];
    allButton.map((e) => {
      if (
        myStore.hasPermission(
          e.permission,
          e.resource ? e.resource : this.resourceName,
          true
        )
      ) {
        if (e.isALink) {
          headerButtons.push(
            <Link key={e.key} to={e.linkTo}>
              <Button className={e.className} icon={e.icon}>
                {e.title}
              </Button>
            </Link>
          );
        } else {
          headerButtons.push(
            <Button
              key={e.key}
              className={e.className}
              icon={e.icon}
              onClick={e.onClick}>
              {e.title}
            </Button>
          );
        }
      }
    });
    if (this.currentStore?.selectedItems?.length > 0) {
      headerButtons = [];
    }
    return headerButtons;
  };
  /**
   * renderTableRows
   * this function return the default key colum format of table
   * @return  {Component}
   */
  renderTableRows = () => {
    const {
      t,
      rootStore: { myStore },
    } = this.props;
    let columns = [...this.columns];
    this.hasRowSelection = false;
    if (this.tableInlineActions.length > 0) {
      this.hasRowSelection = true;
      columns.push({
        title: t("table.actions"),
        key: "action",
        render: (item) => (
          <span>
            {this.tableInlineActions.map((e, index) => {
              if (
                (!e.visible || e.visible(item)) &&
                myStore.hasPermission(
                  e.permission,
                  e.resource ? e.resource : this.resourceName,
                  false,
                  item.owner_id
                )
              ) {
                if (index != 0) {
                  return (
                    <Divider type="vertical" /> && (
                      <Tooltip
                        key={"tooltip_" + index}
                        placement="top"
                        title={e.tooltip}>
                        <Button
                          className={e.className}
                          key={e.key + "_" + index}
                          type="link"
                          disabled={e.notEnable && e.notEnable(item)}
                          icon={e.icon}
                          onClick={() =>
                            e.action?.apply(null, e.passingData(item))
                          }>
                          {e.child}
                        </Button>
                      </Tooltip>
                    )
                  );
                } else {
                  return (
                    <Tooltip
                      key={"tooltip_" + index}
                      placement="top"
                      title={e.tooltip}>
                      <Button
                        key={e.key + "_" + index}
                        type="link"
                        icon={e.icon}
                        disabled={
                          e.disabled
                            ? typeof e.disabled === "function"
                              ? e.disabled(item)
                              : e.disabled
                            : false
                        }
                        onClick={() =>
                          e.action?.apply(null, e.passingData(item))
                        }>
                        {e.child}
                      </Button>
                    </Tooltip>
                  );
                }
              }
            })}
          </span>
        ),
      });
    }
    return columns;
  };
  /**
   * render
   *
   * @return  {Component}
   */
  render() {
    const {
      t,
      permission = "*",
      rootStore: { myStore },
    } = this.props;

    if (!myStore.hasPermission(ACTIONS_LIST.VIEW, permission, true)) {
      return <Unauthorized />;
    }
    //render header buttons actions
    let headerButtons = this.renderHeaderButtonActions();
    //render table action button
    let tableActionButtons = this.renderTableButtonActions();
    //render inline table action button and row format

    let columns = this.renderTableRows();
    //render checked column in each row -  this one HAVE TO run under renderTableRows functions
    let rowSelection = null;

    if (
      this.forceEnableRowSelection ||
      (this.enableRowSelection && this.hasRowSelection)
    ) {
      rowSelection = {
        selectedRowKeys: this.currentStore?.selectedItems,
        onChange: this.currentStore?.onSelectRowChange,
        getCheckboxProps: (record) => ({
          disabled: this.forceEnableRowSelection
            ? false
            : record.disable ||
              !this.currentStore.myStore.hasPermission(
                ACTIONS_LIST.EDIT,
                this.resourceName,
                false,
                record.owner_id
              ),
        }),
      };
    }
    //passing data of table
    const data = this.currentStore?.listData;
    //binding paging info of table
    const pagination = this.currentStore?.filterData?.pagination;

    return (
      <PageHeader
        title={this.pageTitle}
        extra={headerButtons}
        className={this.pageCustomClass + " wrap-base-management"}>
        <div className="wrap-table">
          {this.currentStore?.selectedItems?.length <= 0 &&
            this.enableFilterBox && (
              <div className="filter">
                <Row gutter={[8, 8]}>
                  <div className="filter-content">
                    {this.renderFilterComponent()}
                  </div>
                  {/* <Button className="btn-search" icon="search" onClick={this.currentStore?.handleFilerAction}>{t('table.search')}</Button> */}
                </Row>
              </div>
            )}
          {this.currentStore?.selectedItems?.length > 0 &&
            this.enableActionBox && (
              <div className="actions" style={{ height: "35px" }}>
                <div className="action-content">{tableActionButtons}</div>
                <div className="selected-content">
                  {(this.currentStore?.selectedItems?.length > 0
                    ? t("table.selected") +
                      this.currentStore?.selectedItems?.length +
                      t("table.items_in")
                    : "") +
                    t("table.total") +
                    this.currentStore?.filterData?.pagination?.total +
                    t("table.item")}
                </div>
              </div>
            )}

          {this.renderCustomContent ? (
            <div>
              {this.renderCustomContent()}
              <Drawer
                title={
                  <div>
                    {this.currentStore?.createEditFromTile} &nbsp;
                    {this.detailHeader && this.detailHeader()}
                  </div>
                }
                width={this.drawerWithPercent}
                className="spm-detail-drawer"
                placement="right"
                destroyOnClose
                onClose={this.handleDrawerOnClose}
                visible={this.currentStore?.drawerVisible}>
                <div className="wrap-form-detail">
                  {this.renderDetailData && this.renderDetailData()}
                </div>
                <div className="wrap-footer-detail">
                  {this.renderFooterDetail && this.renderFooterDetail()}
                </div>
              </Drawer>
            </div>
          ) : (
            <div>
              <Table
                // scroll={{ x: true }}
                rowClassName={(record, index) => {
                  if (index % 2 == 0) return "odd-row";
                  else return "even-row";
                }}
                className="spm-table"
                rowSelection={rowSelection}
                columns={columns}
                rowKey={this.rowKey}
                dataSource={data}
                pagination={pagination}
                loading={
                  this.currentStore?.loading && {
                    indicator: <SpinLoading visible={true}></SpinLoading>,
                  }
                }
                onRow={this?.onRow}
                onChange={this.currentStore?.handleTableChange}
              />
              <Drawer
                title={
                  <div>
                    {this.currentStore?.createEditFromTile} &nbsp;
                    {this.detailHeader && this.detailHeader()}
                  </div>
                }
                width={this.drawerWithPercent}
                className="spm-detail-drawer"
                placement="right"
                destroyOnClose
                onClose={this.handleDrawerOnClose}
                visible={this.currentStore?.drawerVisible}>
                <div className="wrap-form-detail">
                  {this.renderDetailData && this.renderDetailData()}
                </div>
                <div className="wrap-footer-detail">
                  {this.renderFooterDetail && this.renderFooterDetail()}
                </div>
              </Drawer>
              {this.renderAdditionalInfo && this.renderAdditionalInfo()}
            </div>
          )}
        </div>
      </PageHeader>
    );
  }
}

export default BaseManagementComponents;
