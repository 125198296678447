import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import ReportDetail from "./ReportDetail";
import ReportResultPane from "./ReportResultPane";

@inject("rootStore")
@observer
class ReportResultPage extends Component {
  /**
   * componentDidMount
   *
   */
  componentDidMount() {
    const {
      t,
      match: { params },
      rootStore: { reportResultStore },
    } = this.props;
    document.title = t("report_result.page-title");
    reportResultStore.getResultData(params.id);
  }

  render() {
    return (
      <div className="wrap-report-result">
        <div className="wrap-report-detail">
          <ReportDetail />
        </div>
        <div className="wrap-report-content">
          <ReportResultPane />
        </div>
      </div>
    );
  }
}

export default withRouter(withTranslation()(ReportResultPage));
