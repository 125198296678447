import { inject, observer } from "mobx-react";
import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";
import ChartComponent from "../ChartComponent";
import "./style.scss";

@inject("rootStore")
@observer
class ChartItemSplitMonitor extends PureComponent {
  render() {
    const {
      index,
      style,
      rootStore: {
        chartStore: {
          currentChartTypeFrontEnd,
          chartInfo,
          filterData,
          gapData,

          getChartByIndex,
        },
      },
      isPrintMode,
    } = this.props;
    let chart = getChartByIndex(index);
    return (
      chart && (
        <div className={(index !== 0) ? "actual-time-space ListItem" : "ListItem"} style={style}>
          <div className={(index !== 0) ? "wrap-chart-item" : "wrap-chart-item"} >
            <div className="wrap-chart-detail" height={"100%"}>
              <ChartComponent
                data={chart}
                parentHeight={style.height}
                filterData={filterData}
                chartInfo={chartInfo}
                gapData={gapData}
                isCompareMode={false}
                chartType={currentChartTypeFrontEnd}
                isPrintMode={isPrintMode}
                height="100%"
              />
            </div>
          </div>
        </div>
      )
    );
  }
}

export default withTranslation()(ChartItemSplitMonitor);
