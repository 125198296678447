import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import OptimizerFilter from "./OptimizerFilter";
import OptimizerHistory from "./OptimizerHistory";
import "./style.scss";

@inject("rootStore")
@observer
class Optimizer extends Component {
  /**
   * componentDidMount
   *
   */
  componentDidMount() {
    const { t } = this.props;
    document.title = t("optimization.page-title");
  }
  render() {
    return (
      <div className="wrap-optimizer">
        <div className="wrap-filter flex justify-center">
          <OptimizerFilter />
        </div>
        <div className="wrap-history-optimizer bg-background relative">
          <OptimizerHistory />
        </div>
      </div>
    );
  }
}

export default withTranslation()(Optimizer);
