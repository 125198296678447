import { Alert, Button, Checkbox, Form, Icon, Input } from "antd";
import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import { ReactComponent as Logo } from "../../svgs/logo.svg";
import "./style.scss";

/**
 * Component for showing login page.
 *
 * @component
 */
@inject("rootStore")
@observer
class Login extends Component {
  recaptchaRef = {};

  constructor(props) {
    super(props);
    this.state = {
      reCAPTCHA: false,
    };
    this.emailInput = null;
  }
  /**
   * handleForgotPassClick
   * redirect page into forgot password page
   *
   */
  handleForgotPassClick = () => {
    this.props?.history?.push("/forgot-password");
  };
  /**
   * componentDidMount
   * check whenever logined user enter the login page -redirect to home page
   * clear all error list
   */
  componentDidMount = () => {
    const {
      t,
      rootStore: {
        authStore: { clearErrorList },
      },
    } = this.props;
    document.title = t("login.page-title");
    clearErrorList();
    this.emailInput.focus();
  };
  /**
   * componentDidMount
   *  @params  {e}  event submit form
   * handle when user click on login button
   *
   */
  handleSubmit = (e) => {
    e.preventDefault();
    const {
      rootStore: {
        authStore: { login },
      },
    } = this.props;
    //validate username and password
    this.props?.form?.validateFields((err, values) => {
      if (!err) {
        //call the login from store
        login(values);
        this.setState({
          reCAPTCHA: false,
        });
        this.recaptchaRef?.reset();
      }
    });
  };
  /**
   * onCapchaChange
   * when captcha verifyed enable login button
   *
   */
  onCapchaChange = () => {
    this.setState({
      reCAPTCHA: true,
    });
  };
  /**
   * render
   *
   * @return  {Component}
   */
  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      rootStore: {
        authStore: { loading, showCapcha, errorList, clearErrorList },
      },
      t,
    } = this.props;
    let disableLogin = false;
    if (showCapcha) {
      disableLogin = !this.state.reCAPTCHA;
    }
    return (
      <div className="wrap-login-comp-bg">
        <div className="wrap-login-error-comp">
          {errorList && (
            <Alert
              message={errorList.message}
              type="error"
              closable
              showIcon
              onClose={clearErrorList}
              className="spm-error-comp"
            />
          )}
        </div>
        <div className="wrap-login-comp">
          <Form onSubmit={this.handleSubmit} className="login-form">
            <div className="logo-login">
              <Logo className="logo-img"></Logo>
            </div>
            <div className="title-login">{t("login.title_login")}</div>
            <Form.Item required={false} label={t("login.email")}>
              {getFieldDecorator("username", {
                rules: [{ required: true, message: t("login.email_required") }],
              })(
                <Input
                  ref={(input) => {
                    this.emailInput = input;
                  }}
                  maxLength={255}
                  prefix={<Icon type="user" />}
                  placeholder={t("login.email_placeholder")}
                />
              )}
            </Form.Item>
            <Form.Item
              required={false}
              label={
                <span>
                  {t("login.password")}
                  <a
                    className="login-form-forgot"
                    onClick={this.handleForgotPassClick}
                  >
                    {t("login.forgotpass")}
                  </a>
                </span>
              }
            >
              {getFieldDecorator("password", {
                rules: [
                  { required: true, message: t("login.password_required") },
                ],
              })(
                <Input.Password
                  maxLength={128}
                  autoComplete="off"
                  prefix={<Icon type="lock" />}
                  type="password"
                  placeholder={t("login.password_placeholder")}
                />
              )}
            </Form.Item>
            {showCapcha && (
              <Form.Item>
                <ReCAPTCHA
                  className="captcha"
                  ref={(e) => (this.recaptchaRef = e)}
                  sitekey={process.env.REACT_APP_GOOGLE_CAPCHA_SITE_KEY}
                  onChange={this.onCapchaChange}
                />
              </Form.Item>
            )}
            <Form.Item style={{ textAlign: "center" }}>
              <Button
                icon="login"
                id="login"
                type="primary"
                htmlType="submit"
                loading={loading}
                className="login-form-button"
                disabled={disableLogin}
              >
                {t("login.login")}
              </Button>
              {getFieldDecorator("keep_me_sign_in", {
                valuePropName: "checked",
                initialValue: false,
              })(<Checkbox>{t("login.keepMe")}</Checkbox>)}
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
}

const LoginForm = Form.create({ name: "login-form" })(Login);

export default withRouter(withTranslation()(LoginForm));
