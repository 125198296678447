import { action, observable, runInAction } from "mobx";
import {
  AGENCY_FILTER_FIELD,
  AVAILABLE,
  DEFAULT_CENTER_POINT_DASHBOARD,
  DEFAULT_CONDITION_SUMMARY_REPORT,
  DEFAULT_LOAD_INTERSECTION_ON_MAP,
  DEFAULT_ZOOM_LEVEL,
  DELAY_SEARCH_STRING,
  MAP_SETTING,
  MAX_NUMBER_START_SEARCH,
  QUERY_DELAY_LOAD_DATA,
  RESOURCE_LIST,
  SEARCH_TEXT_FIELD,
  SUMMARY_REPORT_TAB_INDEX,
  SUPPER_ADMIN_GROUP_AGENCY_ID,
  WHOLE_MAP,
} from "utils/constants";
import AgencyService from "../services/AgencyService";
import DashboardService from "../services/DashboardService";
import BaseStore from "./BaseStore";
import {
  DASHBOARD_DEFAULT_AVAILABLE_WIDGETS,
  DASHBOARD_DEFAULT_LAYOUT,
  DASHBOARD_INTERSECTION_SELECTION_TYPE,
  DASHBOARD_WIDGET_IDS,
  DASHBOARD_WIDGETS,
  DEFAULT_SUMMARY_REPORT_METRICS,
  MAP_WIDGET_DEFAULT_SETTING,
  SELECT_INTERSECTION_DEFAULT_SETTING,
  SELECT_PERIODS_DEFAULT_SETTING,
  TIME_PERIODS,
} from "../utils/constants/dashboardConstant";
import moment from "moment";
import { getLastPeriodTime } from "../utils/helper";
import i18n from "../i18n";

/**
 * Store for Dashboard
 *
 * @class
 */
class DashboardStore extends BaseStore {
  //header data
  @observable filterData = {};
  @observable lastUpldatedTime = 0; //last time update intersection - display on header
  @observable listAllAgency = []; //list all agency of current user - list agenies for simemens admin
  @observable currentAgency = "";
  //map data
  @observable selectedColorMatrixExtraText = "";
  @observable listAllIntersections = []; //list all intersection
  @observable listFilterIntersections = []; //list filtered intersection display on map
  @observable mapSetting = {
    layer_hidden: true, //default hide heatmap mode
    location: DEFAULT_CENTER_POINT_DASHBOARD,
  };
  @observable agencyColorMatrix = [];
  @observable selectedColorMatrix = null;
  @observable selectedColorList = {};
  @observable selectedColorGradient = {};
  @observable maxColorGradient = 0;
  @observable minColorGradient = 0;
  @observable isSave = false;
  @observable TimeoutSucces = null;
  @observable clientSideFilterData = {
    minValue: 0,
    maxValue: 100,
  };

  //top 10 data
  @observable listTopTenIntersections = []; //top 10 intersection by open alarm on left side
  //chart data
  @observable listCharts = []; //data chart of open alarm on left side
  @observable totalOpenAlarms = 0; //all open data display on chart
  //ThreadId loading loop for lazy loading intersection on map
  continuousLoadingThreadId = null;
  //refernce of map object - to get topleft-buttonright position when change agency
  heatMapRef = {};
  @observable searchString = ""; //all open data display on chart
  @observable availableWidgets = DASHBOARD_DEFAULT_AVAILABLE_WIDGETS;
  @observable originalDashboardSetting = null;
  @observable loadingLayout = false;
  @observable layouts = [];
  @observable layoutEditing = false;
  @observable layoutAdding = false;
  @observable widgetSetting = false;
  @observable mapWidgetSetting = { ...MAP_WIDGET_DEFAULT_SETTING };
  @observable topTenWidgetSetting = {
    ...SELECT_INTERSECTION_DEFAULT_SETTING,
  };
  @observable openAlarmWidgetSetting = {
    ...SELECT_INTERSECTION_DEFAULT_SETTING,
  };
  @observable intersectionOptions = [];
  @observable savingSetting = false;
  @observable summaryReport = {
    loading: false,
    pagination: {
      current: 1,
      pageSize: parseInt(process.env.REACT_APP_PAGE_SIZE) || null,
      showSizeChanger: true,
      pageSizeOptions: ["5", "10", "20", "40"],
      showTotal: this.renterTotalItems,
    },
    sort: "",
    text: "",
    data: [],
  };
  @observable volumeCounts = {
    loading: false,
    data: [],
  };
  @observable notification = {
    loading: false,
    data: {},
  };
  @observable volumeCountsSetting = {
    ...SELECT_PERIODS_DEFAULT_SETTING,
    ...SELECT_INTERSECTION_DEFAULT_SETTING,
  };
  @observable summaryReportSetting = {
    ...SELECT_PERIODS_DEFAULT_SETTING,
    metrics: [],
  };
  @observable notificationSetting = {
    ...SELECT_PERIODS_DEFAULT_SETTING,
    notificationTypes: [],
  };
  @observable typeSummaryReport = "";
  @observable metadataDashboardSetting = null;
  @observable currentAgencySetting = null;

  constructor(parentStore) {
    super(new DashboardService(parentStore), parentStore);
    this.agencyService = new AgencyService(parentStore); //agency service to get agency data
    //filter data for list intersection
    this.filterData = {
      ...this.filterData,
      ...{
        agency_id: parentStore?.myStore?.currentAgency?.agency_id,
      },
    };
    this.resourceName = RESOURCE_LIST.DASHBOARD;
    //current agency name is wokring on -  for binding on UI
    this.currentAgency = parentStore?.myStore?.currentAgency?.agency_name;
    this.mapFilterData = {
      top_left: WHOLE_MAP.TOP_LEFT,
      bottom_right: WHOLE_MAP.BOTTOM_RIGHT,
      size: DEFAULT_LOAD_INTERSECTION_ON_MAP,
    };
  }

  @action resetIsSave = () => {
    this.isSave = false;
  };
  @action setTimeoutSucces = () => {
    this.TimeoutSucces = setTimeout(() => {
      runInAction(() => {
        this.isSave = false;
      });
    }, 5000);
  };
  @action clearTimeoutSucces = () => {
    clearTimeout(this.TimeoutSucces);
  };

  @action saveMapSettingByAgency = () => {
    this.moduleService
      .saveMapSettingByAgency(
        this.mapSetting.layer_hidden
          ? {
            target: "default",
            agency_id: this.filterData.agency_id,
            default_map: {
              ...this.mapSetting.default_map,
            },
          }
          : {
            target: "heat",
            agency_id: this.filterData.agency_id,
            heat_map: {
              ...this.mapSetting.heat_map,
            },
          }
      )
      .then(() => {
        runInAction(() => {
          this.isSave = true;
          this.setTimeoutSucces();
        });
      })
      .catch(() => {
      });
  };

  @action setMapSetting(value) {
    this.mapSetting = value;
  }

  @action updateSerachString = (searchStr = "") => {
    this.searchString = searchStr;
    this.updateListAllIntersections();
  };
  @action clearClientSideFiltering = (maxValue = 100) => {
    this.clientSideFilterData = {
      minValue: 0,
      maxValue: maxValue,
    };
    this.updateClientSideFilterData();
  };
  /**
   * handleMapSettingChange
   * this function triggered whenever map setting change
   * this function will update radius and blur of intersection on map
   * @params {Enum} type only 2 type support now : radius/ blur
   * @params {int} value new value
   *
   * @return {null} intensity
   */
  @action handleMapSettingChange = (type, value, saveSetting) => {
    if (value !== null) {
      if (type === "layer_hidden") {
        this.mapSetting[type] = value;
      } else if (type === MAP_SETTING.CENTER_POINT) {
        this.mapWidgetSetting.centerPointer = value;
        if (this.mapWidgetSetting.saveZoomLevel) {
          this.patchDashboardSetting(DASHBOARD_WIDGETS.HEATMAP, saveSetting);
        }
      } else if (type === MAP_SETTING.ZOOM_LEVEL) {
        this.mapWidgetSetting.zoomLevel = value;
        if (this.mapWidgetSetting.saveZoomLevel) {
          this.patchDashboardSetting(DASHBOARD_WIDGETS.HEATMAP, saveSetting);
        }
      }
    }
  };
  /**
   * setHeatMapRef
   *
   * @return {null}
   */
  setHeatMapRef = (heatMap) => {
    this.heatMapRef = heatMap;
  };
  /**
   * refreshDashboardData
   * refresh all data of dashboard
   *
   * 1.get all agency => if admin => cancel
   * 2.get top 10 open alarms
   * 3.get chart open alarm
   * 4.get current Agency setting
   * => after get location of agency =
   * > we will trigger get intersection later
   *
   * @return {null}
   */
  refreshDashboardData = () => {
    this.getAllAgencies();
  };
  /**
   * getChartInfo
   * get all chart info data
   *
   * @return {null}
   */
  @action getChartInfo = () => {
    this.loading = true;
    const filters = { agency_id: this.filterData.agency_id };
    if (
      this.openAlarmWidgetSetting.select_type ===
      DASHBOARD_INTERSECTION_SELECTION_TYPE.INCLUDE_SPECIFIC
    ) {
      filters["int_ids"] = this.openAlarmWidgetSetting.selected_intersections;
    } else if (
      this.openAlarmWidgetSetting.select_type ===
      DASHBOARD_INTERSECTION_SELECTION_TYPE.EXCLUDE_SPECIFIC
    ) {
      filters["exclude_int_ids"] =
        this.openAlarmWidgetSetting.selected_intersections;
    }
    this.moduleService
      ?.getChart(filters)
      .then((items) => {
        runInAction(() => {
          //find the total count
          this.totalOpenAlarms = items.elements.reduce(function (total, item) {
            return total + item.count;
          }, 0);
          let index = 1;
          //find the available item( cuz backend return data with count = 0)
          //we clean it up at client side
          let availableItem = items.elements.filter((item) => item.count > 0);
          //set listChart data. we automatic convert count to value with % to build pie chart
          this.listCharts = availableItem.map((item) => {
            item.value = item.count;
            item.date = index++;
            return item;
          });
        });
      })
      .catch(console.error)
      .finally(() => {
        runInAction(() => {
          this.loading = false;
        });
      });
  };
  /**
   * getTopTenOpenAlarms
   * get top 10 intersection by open alarms
   *
   * @return {null}
   */
  @action getTopTenOpenAlarms = () => {
    this.loading = true;
    const filters = { agency_id: this.filterData.agency_id };
    if (
      this.topTenWidgetSetting.select_type ===
      DASHBOARD_INTERSECTION_SELECTION_TYPE.INCLUDE_SPECIFIC
    ) {
      filters["int_ids"] = this.topTenWidgetSetting.selected_intersections;
    } else if (
      this.topTenWidgetSetting.select_type ===
      DASHBOARD_INTERSECTION_SELECTION_TYPE.EXCLUDE_SPECIFIC
    ) {
      filters["exclude_int_ids"] =
        this.topTenWidgetSetting.selected_intersections;
    }
    this.moduleService
      ?.getTopTenOpenAlarms(filters)
      .then((items) => {
        runInAction(() => {
          this.listTopTenIntersections = items.intersections;
        });
      })
      .catch(() => {
      })
      .finally(() => {
        runInAction(() => {
          this.loading = false;
        });
      });
  };
  /**
   * getAllAgencies
   * get all agencies belong to current users
   * if current user is not simemen admin using current agency of user
   *
   * @return {Promise}
   */
  @action getAllAgencies = () => {
    return new Promise((resolve, reject) => {
      let agency_id = this.parentStore?.myStore?.currentAgency?.agency_id;
      if (agency_id === SUPPER_ADMIN_GROUP_AGENCY_ID) {
        this.loading = true;
        this.agencyService?.fetchDataWithoutParams(
          (items) => {
            runInAction(() => {
              this.listAllAgency = items;
              //auto binding seleted agency - or set default a gency
              if (Array.isArray(this.listAllAgency) && this.listAllAgency[0]) {
                //binding selected agency
                let defaultAgencies = this.listAllAgency.find(
                  (item) => item.id === this.filterData.agency_id
                );
                //if no selected agency =>  get default of the frist one
                if (!defaultAgencies) {
                  defaultAgencies = this.listAllAgency[0];
                }
                //set default agency data
                this.handleFilterDataChange("agency_id", defaultAgencies.id);
              }
              //auto load agency setting by current agency
            });
            resolve(items);
          },
          () => {
            runInAction(() => {
              this.loading = false;
            });
            reject(false);
          }
        );
      } else {
        this.handleFilterDataChange("agency_id", agency_id);
        resolve(true);
      }
    });
  };

  /**
   * handleFilterDataChange
   * this function will trigger when user input filtering or searching data
   * if filter data is search text and search length >=3 or length == 0 automatic start search
   * @param   {String} target  Field name wanna filter
   * @param   {String} value   Filtering data
   * @return  {null} -  setState of current filter data
   */
  @action handleFilterDataChange = (target, value) => {
    if (this.filterData) {
      this.filterData[target] = value;
    }
    if (target === AGENCY_FILTER_FIELD) {
      let selectedAgency = this.listAllAgency?.find(
        (item) => item.id === value
      );
      this.listAllIntersections = [];
      this.currentAgency = selectedAgency?.name;

      //refresh all data execpt agenies
      this.getDashboardSettingByAgency();
      this.getIntersectionOptions();
      this.updateSerachString(); //clear searchString
    }
  };

  @action updateClientSideFilterData = (key, newValue) => {
    this.clientSideFilterData[key] = newValue;
    this.updateListAllIntersections();
  };
  /**
   * setMapBounder
   * this function will trigger when heat map viewport change
   * to update mapfilter data (topleft / buttonright)
   * then we make a request to get intersection base on that data filter
   * @return  {null}
   */
  setMapBounder = (topLeft, bottomRight, isReload = true) => {
    this.mapFilterData.top_left =
      (topLeft?.lat || 0) + "," + (topLeft?.lng || topLeft?.lon || 0);
    this.mapFilterData.bottom_right =
      (bottomRight?.lat || 0) +
      "," +
      (bottomRight?.lng || bottomRight?.lon || 0);
    if (isReload) {
      this.getAllIntersectionsLocation();
    }
  };
  /**
   * updateListAllIntersections
   * update List All Intersections with filter data
   * @param {Array} newIntersection
   */
  @action updateListAllIntersections = (newIntersection = null) => {
    if (Array.isArray(newIntersection)) {
      this.listAllIntersections = newIntersection;
    }
    let result = this.listAllIntersections;
    this.listFilterIntersections = result.filter((intersection) => {
      let value = intersection[this.selectedColorMatrix];
      if (!value) {
        value = 0;
      }
      let result;
      if (this.selectedColorMatrix && this.maxColorGradient > 0) {
        if (this.clientSideFilterData.maxValue === this.maxColorGradient)
          result =
            value >= this.clientSideFilterData.minValue &&
            intersection.name
              ?.toLowerCase()
              .indexOf(this.searchString?.toLowerCase()) >= 0 &&
            (this.clientSideFilterData.id
              ? this.clientSideFilterData.id === intersection.id
              : true);
        else
          result =
            value >= this.clientSideFilterData.minValue &&
            value <= this.clientSideFilterData.maxValue &&
            intersection.name
              ?.toLowerCase()
              .indexOf(this.searchString?.toLowerCase()) >= 0 &&
            (this.clientSideFilterData.id
              ? this.clientSideFilterData.id === intersection.id
              : true);
      } else
        result =
          intersection.name
            ?.toLowerCase()
            .indexOf(this.searchString?.toLowerCase()) >= 0 &&
          (this.clientSideFilterData.id
            ? this.clientSideFilterData.id === intersection.id
            : true);
      return result;
    });
  };
  /**
   * getAllIntersectionsLocation
   * get all intersection for map
   *
   * @return {null}
   */
  @action getAllIntersectionsLocation = (
    page = 0,
    continuousLoadingThreadId = null
  ) => {
    if (page === 0) {
      //random the loading threadId
      this.continuousLoadingThreadId = Math.floor(Math.random() * 100);
    }
    const filters = {
      ...this.mapFilterData,
      ...{ agency_id: this.filterData.agency_id, page },
    };
    if (this.mapWidgetSetting.period === TIME_PERIODS.CUSTOM) {
      filters.from_time = this.mapWidgetSetting.timeRange[0]
        .utc()
        .toISOString();
      filters.to_time = this.mapWidgetSetting.timeRange[1].utc().toISOString();
    } else if (this.mapWidgetSetting.period === TIME_PERIODS.LAST_12_HOURS) {
      const period = getLastPeriodTime(12, "hour");
      filters.from_time = period[0].toISOString();
      filters.to_time = period[1].toISOString();
    } else if (this.mapWidgetSetting.period === TIME_PERIODS.LAST_24_HOURS) {
      const period = getLastPeriodTime(24, "hour");
      filters.from_time = period[0].toISOString();
      filters.to_time = period[1].toISOString();
    }

    this.loading = true;
    // return new Promise((resolve, reject) => {
    this.moduleService
      ?.getAllIntersectionsWithBounds({ ...filters, status: AVAILABLE })
      .then((response) => {
        //check if return data belong to old thread just ignore that
        if (
          continuousLoadingThreadId &&
          this.continuousLoadingThreadId !== continuousLoadingThreadId
        ) {
          // resolve();
        } else {
          runInAction(() => {
            this.lastUpldatedTime = response.updated_time;
            if (page === 0) {
              this.updateListAllIntersections(response?.intersections);
            } else {
              this.updateListAllIntersections(
                this.listAllIntersections.concat(response?.intersections)
              );
            }
            // resolve();
            if (response.total_count > this.listAllIntersections.length) {
              continuousLoadingThreadId = this.continuousLoadingThreadId;
              let context = this;
              setTimeout(function () {
                context.getAllIntersectionsLocation(
                  page + 1,
                  continuousLoadingThreadId
                );
              }, QUERY_DELAY_LOAD_DATA);
            }
          });
        }
      })
      .catch((response) => {
        runInAction(() => {
          this.setErrorList(response);
        });
        // reject(response);
      })
      .finally(() => {
        runInAction(() => {
          this.loading = false;
        });
      });
    // });
  };
  /**
   * getAgencySettingByAgencyId
   * get setting of agency include
   * color setting matrix
   * location of agency
   * get all intersection for map
   *
   * @return {null}
   */
  @action getAgencySettingByAgencyId = (saveSetting) => {
    this.loading = true;
    return new Promise((resolve, reject) => {
      this.moduleService?.fetchAgencySettingForDashboardMap(
        this.filterData.agency_id,
        (response) => {
          runInAction(() => {
            this.currentAgencySetting = response;
            //init locor matrix and color gradient
            if (response.display) {
              let colorDisplayList = Object.keys(response.display);
              if (Array.isArray(colorDisplayList)) {
                //set default selected Item

                // let defaultItem = null;
                this.agencyColorMatrix = colorDisplayList.map((item) => {
                  // if (index === 0 || item === this.selectedColorMatrix) {
                  //   defaultItem = item;
                  // }
                  let colorMatrix;
                  if ((colorMatrix = response.display[item])) {
                    colorMatrix.id = item;
                  }
                  return colorMatrix;
                });
                // if (defaultItem) {
                //   this.handleColorMatrixChange(defaultItem);
                // }
                if (!saveSetting) {
                  this.handleColorMatrixChange(
                    this.mapWidgetSetting.defaultMetric
                  );
                }
              }
            }
            if (response.top_left && response.bottom_right) {
              this.setMapBounder(response.top_left, response.bottom_right);
            }

            this.loading = false;
            resolve(response);
          });
        },
        (errors) => {
          runInAction(() => {
            this.loading = false;
          });
          reject(errors);
        }
      );
    });
  };
  /**
   * handleColorMatrixChange
   *
   * @return {null}
   */
  @action handleColorMatrixChange = (selectedColorMatrix) => {
    this.selectedColorMatrix = selectedColorMatrix;
    this.selectedColorList = this.agencyColorMatrix?.find(
      (item) => item?.id === selectedColorMatrix
    );
    this.selectedColorMatrixExtraText = "";
    if (selectedColorMatrix === "alarm_record_count") {
      this.selectedColorMatrixExtraText = "7_day";
    }
    if (
      selectedColorMatrix === "aor_percent" ||
      selectedColorMatrix === "avg_ped_delay" ||
      selectedColorMatrix === "detector_actuation_volume" ||
      selectedColorMatrix === "aog_percent"
    ) {
      this.selectedColorMatrixExtraText = "24_hours";
    }
    let maxColorMatrix = 1;
    this.selectedColorList?.color.map((item) => {
      if (maxColorMatrix < item?.from_value) {
        maxColorMatrix = item?.from_value;
      }
    });
    if (maxColorMatrix) {
      this.maxColorGradient = maxColorMatrix;
    }
    this.clearClientSideFiltering(maxColorMatrix);
    if (Array.isArray(this.selectedColorList?.color)) {
      let color = {};
      //make value as a percentage => required by heat map
      this.selectedColorList.color.map((item) => {
        if (item.from_value !== null) {
          color[item.from_value / this.maxColorGradient] = item.color;
        }
      });
      this.selectedColorGradient = color;
    }
  };

  @action toggleEditingLayout = () => {
    runInAction(() => {
      this.layoutEditing = !this.layoutEditing;
    });

    if (!this.layoutEditing) {
      this.saveDashboardSetting();
    }
  };

  @action toggleAddingLayout = (adding) => {
    runInAction(() => {
      this.layoutAdding = adding;
    });
  };

  @action toggleWidgetSetting = (setting) => {
    runInAction(() => {
      this.widgetSetting = setting;
    });
  };

  @action changeLayout = (layouts) => {
    runInAction(() => {
      this.layouts = layouts;
    });
  };

  @action changeMapSetting = (setting) => {
    runInAction(() => {
      this.mapWidgetSetting = Object.assign(
        {},
        this.mapWidgetSetting,
        setting,
        { changed: true }
      );
    });
  };

  composeMapSettingWithLayout = (layout) => {
    const setting = {
      widget_id: "map_view",
      position: JSON.stringify(layout),
      period_type: this.mapWidgetSetting.period,
      normal_map_radius: this.mapWidgetSetting.dotSize,
      heat_map_radius: this.mapWidgetSetting.radius,
      heat_map_blur: this.mapWidgetSetting.blur,
      save_current: this.mapWidgetSetting.saveZoomLevel,
      default_metric: this.mapWidgetSetting.defaultMetric,
    };
    setting.zoom_level = this.mapWidgetSetting.zoomLevel || DEFAULT_ZOOM_LEVEL;
    setting.center_pointer =
      this.mapWidgetSetting.centerPointer || this.mapSetting.location;

    if (this.mapWidgetSetting.period === TIME_PERIODS.CUSTOM) {
      /* Get UTC time without change moment object */
      setting.from_time = moment
        .unix(this.mapWidgetSetting.timeRange[0].unix())
        .utc();
      setting.to_time = moment
        .unix(this.mapWidgetSetting.timeRange[1].unix())
        .utc();
    }

    return setting;
  };

  @action resetMapCenterPointZoomLevel = () => {
    this.mapWidgetSetting = {
      ...this.mapWidgetSetting,
      zoomLevel: DEFAULT_ZOOM_LEVEL,
      centerPointer: this.currentAgencySetting.center_point,
    };
    this.saveDashboardSetting();
  };

  @action resetMapSetting = () => {
    runInAction(() => {
      this.mapWidgetSetting = this.initMapSetting(
        this.originalDashboardSetting
      );
    });
  };

  @action changeTopTenSetting = (setting) => {
    runInAction(() => {
      this.topTenWidgetSetting = Object.assign(
        {},
        this.topTenWidgetSetting,
        setting,
        { changed: true }
      );
    });
  };

  composeTopTenSettingWithLayout = (layout) => {
    return {
      widget_id: "top_int_open_alarm",
      position: JSON.stringify(layout),
      intersection_option: this.topTenWidgetSetting.select_type,
      intersection_ids: this.topTenWidgetSetting.selected_intersections,
    };
  };

  @action resetTopTenSetting = () => {
    runInAction(() => {
      this.topTenWidgetSetting = this.initTopTenSetting(
        this.originalDashboardSetting
      );
    });
  };

  @action changeOpenAlarmSetting = (setting) => {
    runInAction(() => {
      this.openAlarmWidgetSetting = Object.assign(
        {},
        this.openAlarmWidgetSetting,
        setting,
        { changed: true }
      );
    });
  };

  composeOpenAlarmSetting = (layout) => {
    return {
      widget_id: "open_alarm",
      position: JSON.stringify(layout),
      intersection_option: this.openAlarmWidgetSetting.select_type,
      intersection_ids: this.openAlarmWidgetSetting.selected_intersections,
    };
  };

  @action resetOpenAlarmSetting = () => {
    runInAction(() => {
      this.openAlarmWidgetSetting = this.initOpenAlarmSetting(
        this.originalDashboardSetting
      );
    });
  };

  composeSummaryReportSetting = (layout) => {
    const setting = {
      widget_id: "summary_report",
      position: JSON.stringify(layout),
      period_type: this.summaryReportSetting.period,
      metrics: this.summaryReportSetting.metrics
        .filter((el) => el.enabled)
        .map((el) => ({
          metric_id: el.id,
          warning_condition:
            el.warningCondition.operator !== DEFAULT_CONDITION_SUMMARY_REPORT
              ? el.warningCondition
              : "",
        })),
    };

    if (this.summaryReportSetting.period === TIME_PERIODS.CUSTOM) {
      setting.from_time = moment
        .unix(this.summaryReportSetting.timeRange[0]?.unix())
        .utc();
      setting.to_time = moment
        .unix(this.summaryReportSetting.timeRange[1]?.unix())
        .utc();
    }

    return setting;
  };

  @action resetSummaryReportSetting = () => {
    runInAction(() => {
      this.summaryReportSetting = this.initSummaryReportSetting(
        this.originalDashboardSetting,
        this.metadataDashboardSetting
      );
    });
  };

  @action changeSummaryReportSetting = (setting) => {
    runInAction(() => {
      this.summaryReportSetting = Object.assign(
        {},
        this.summaryReportSetting,
        setting,
        { changed: true }
      );
    });
  };

  composeVolumeCountsSetting = (layout) => {
    const setting = {
      widget_id: "volume_report",
      position: JSON.stringify(layout),
      period_type: this.volumeCountsSetting.period,
      intersection_option: this.volumeCountsSetting.select_type,
      intersection_ids: this.volumeCountsSetting.selected_intersections,
    };

    if (this.volumeCountsSetting.period === TIME_PERIODS.CUSTOM) {
      setting.from_time = moment
        .unix(this.volumeCountsSetting.timeRange[0]?.unix())
        .utc();
      setting.to_time = moment
        .unix(this.volumeCountsSetting.timeRange[1]?.unix())
        .utc();
    }

    return setting;
  };

  @action changeVolumeCountsSetting = (setting) => {
    runInAction(() => {
      this.volumeCountsSetting = Object.assign(
        {},
        this.volumeCountsSetting,
        setting,
        { changed: true }
      );
    });
  };

  @action resetVolumeCountsSetting = () => {
    runInAction(() => {
      this.volumeCountsSetting = this.initVolumeCountsSetting(
        this.originalDashboardSetting
      );
    });
  };

  composeNotificationSetting = (layout) => {
    const setting = {
      widget_id: "notification",
      position: JSON.stringify(layout),
      period_type: this.notificationSetting.period,
      notification_types: this.notificationSetting.notificationTypes
        .filter((el) => el.enabled)
        .map((el) => el.notification_type?.id),
    };

    if (this.notificationSetting.period === TIME_PERIODS.CUSTOM) {
      setting.from_time = moment
        .unix(this.notificationSetting.timeRange[0]?.unix())
        .utc();
      setting.to_time = moment
        .unix(this.notificationSetting.timeRange[1]?.unix())
        .utc();
    }

    return setting;
  };
  @action resetNotificationSetting = () => {
    runInAction(() => {
      this.notificationSetting = this.initNotificationSetting(
        this.originalDashboardSetting,
        this.metadataDashboardSetting
      );
    });
  };

  @action changeNotificationSetting = (setting) => {
    runInAction(() => {
      this.notificationSetting = Object.assign(
        {},
        this.notificationSetting,
        setting,
        { changed: true }
      );
    });
  };

  /**
   * Get dashboard setting and layout for specific agency
   * then get data for each widget
   */
  @action getDashboardSettingByAgency = () => {
    runInAction(() => {
      this.loadingLayout = true;
    });
    this.moduleService
      .getDashboardSettingByAgency({
        agency_id: this.filterData.agency_id,
      })
      .then((data) => {
        const dashboardSetting = data.dashboard_setting;
        if (!dashboardSetting) return;
        runInAction(() => {
          this.metadataDashboardSetting = data.dashboard_metadata;
          this.initDashboardSetting(dashboardSetting);
        });
      })
      .catch(() => {
      })
      .finally(() =>
        runInAction(() => {
          this.loadingLayout = false;
        })
      );
  };

  initDashboardSetting = (dashboardSetting) => {
    this.originalDashboardSetting = dashboardSetting;
    this.mapWidgetSetting = this.initMapSetting(dashboardSetting);
    this.topTenWidgetSetting = this.initTopTenSetting(dashboardSetting);
    this.openAlarmWidgetSetting = this.initOpenAlarmSetting(dashboardSetting);
    this.volumeCountsSetting = this.initVolumeCountsSetting(dashboardSetting);
    this.summaryReportSetting = this.initSummaryReportSetting(dashboardSetting, this.metadataDashboardSetting);
    this.notificationSetting = this.initNotificationSetting(dashboardSetting, this.metadataDashboardSetting);

    this.layouts = this.initLayouts(dashboardSetting);
    this.fetchDashboardData();
  }

  @action patchDashboardSetting = (widget, saveSetting) => {
    const layout = this.layouts.find((el) => el.i === widget);
    if (!layout) return;

    const dashboardSetting = {
      agency_id: this.filterData.agency_id,
    };
    switch (layout.i) {
      case DASHBOARD_WIDGETS.HEATMAP:
        dashboardSetting.widget = this.composeMapSettingWithLayout(layout);
        this.getAgencySettingByAgencyId(saveSetting);
        if (!saveSetting) {
          this.handleColorMatrixChange(this.mapWidgetSetting.defaultMetric);
        }
        break;
      case DASHBOARD_WIDGETS.TOP_TEN:
        dashboardSetting.widget = this.composeTopTenSettingWithLayout(layout);
        this.getTopTenOpenAlarms();
        break;
      case DASHBOARD_WIDGETS.INFO_ALARM_MESSAGE:
        dashboardSetting.widget = this.composeOpenAlarmSetting(layout);
        this.getChartInfo();
        break;
      case DASHBOARD_WIDGETS.SUMMARY_REPORT:
        dashboardSetting.widget = this.composeSummaryReportSetting(layout);
        this.getSummaryReport();
        break;
      case DASHBOARD_WIDGETS.VOLUME_COUNT:
        dashboardSetting.widget = this.composeVolumeCountsSetting(layout);
        this.getVolumeCounts();
        break;
      case DASHBOARD_WIDGETS.NOTIFICATION:
        dashboardSetting.widget = this.composeNotificationSetting(layout);
        this.getNotification();
        break;
      default:
        break;
    }
    runInAction(() => {
      this.savingSetting = true;
    });
    this.moduleService
      .patchDashboardSetting(dashboardSetting)
      .then((res) => {
        runInAction(() => {
          this.originalDashboardSetting = res;
        });
      })
      .catch(console.error)
      .finally(() => {
        runInAction(() => {
          this.savingSetting = false;
        });
      });
  };

  @action saveDashboardSetting = () => {
    const dashboardSetting = {
      agency_id: this.filterData.agency_id,
    };

    runInAction(() => {
      this.savingSetting = true;
    });

    this.layouts.forEach((layout) => {
      switch (layout.i) {
        case DASHBOARD_WIDGETS.HEATMAP:
          dashboardSetting[DASHBOARD_WIDGET_IDS.HEATMAP] = this.composeMapSettingWithLayout(layout);
          break;
        case DASHBOARD_WIDGETS.TOP_TEN:
          dashboardSetting[DASHBOARD_WIDGET_IDS.TOP_TEN] = this.composeTopTenSettingWithLayout(layout);
          break;
        case DASHBOARD_WIDGETS.INFO_ALARM_MESSAGE:
          dashboardSetting[DASHBOARD_WIDGET_IDS.INFO_ALARM_MESSAGE] = this.composeOpenAlarmSetting(layout);
          break;
        case DASHBOARD_WIDGETS.SUMMARY_REPORT:
          dashboardSetting[DASHBOARD_WIDGET_IDS.SUMMARY_REPORT] = this.composeSummaryReportSetting(layout);
          break;
        case DASHBOARD_WIDGETS.VOLUME_COUNT:
          dashboardSetting[DASHBOARD_WIDGET_IDS.VOLUME_COUNT] = this.composeVolumeCountsSetting(layout);
          break;
        case DASHBOARD_WIDGETS.NOTIFICATION:
          dashboardSetting[DASHBOARD_WIDGET_IDS.NOTIFICATION] = this.composeNotificationSetting(layout);
          break;
        default:
          break;
      }
    });

    this.moduleService
      .saveDashboardSetting(dashboardSetting)
      .then((res) => {
        runInAction(() => {
          this.initDashboardSetting(res);
        });
      })
      .catch(console.error)
      .finally(() => {
        runInAction(() => {
          this.savingSetting = false;
        });
      });
  };

  initMapSetting = (dashboardSetting) => {
    if (dashboardSetting && dashboardSetting[DASHBOARD_WIDGETS.HEATMAP]) {
      const centerPointer = dashboardSetting[DASHBOARD_WIDGETS.HEATMAP].center_pointer || {};
      if (!centerPointer.lat || !centerPointer.lon) {
        centerPointer.lat = this.currentAgencySetting?.center_point?.lat || 0;
        centerPointer.lon = this.currentAgencySetting?.center_point?.lon || 0;
      }

      return {
        changed: false,
        period: dashboardSetting[DASHBOARD_WIDGETS.HEATMAP].period_type,
        timeRange: this.initMapTimeRange(
          dashboardSetting[DASHBOARD_WIDGETS.HEATMAP].from_time,
          dashboardSetting[DASHBOARD_WIDGETS.HEATMAP].to_time
        ),
        saveZoomLevel: dashboardSetting[DASHBOARD_WIDGETS.HEATMAP].save_current,
        zoomLevel: dashboardSetting[DASHBOARD_WIDGETS.HEATMAP].zoom_level,
        centerPointer: centerPointer,
        dotSize: dashboardSetting[DASHBOARD_WIDGETS.HEATMAP].normal_map_radius,
        radius: dashboardSetting[DASHBOARD_WIDGETS.HEATMAP].heat_map_radius,
        blur: dashboardSetting[DASHBOARD_WIDGETS.HEATMAP].heat_map_blur,
        defaultMetric: dashboardSetting[DASHBOARD_WIDGETS.HEATMAP].default_metric,
      };
    } else {
      return {
        ...MAP_WIDGET_DEFAULT_SETTING,
        timeRange: this.initMapTimeRange(),
      };
    }
  };

  initTopTenSetting = (dashboardSetting) => {
    if (dashboardSetting && dashboardSetting[DASHBOARD_WIDGETS.TOP_TEN]) {
      return {
        changed: false,
        select_type: dashboardSetting[DASHBOARD_WIDGETS.TOP_TEN].intersection_option,
        selected_intersections: dashboardSetting[DASHBOARD_WIDGETS.TOP_TEN].intersection_ids,
      };
    } else {
      return { ...SELECT_INTERSECTION_DEFAULT_SETTING };
    }
  };

  initOpenAlarmSetting = (dashboardSetting) => {
    if (
      dashboardSetting &&
      dashboardSetting[DASHBOARD_WIDGETS.INFO_ALARM_MESSAGE]
    ) {
      return {
        changed: false,
        select_type:
        dashboardSetting[DASHBOARD_WIDGETS.INFO_ALARM_MESSAGE].intersection_option,
        selected_intersections:
        dashboardSetting[DASHBOARD_WIDGETS.INFO_ALARM_MESSAGE].intersection_ids,
      };
    } else {
      return { ...SELECT_INTERSECTION_DEFAULT_SETTING };
    }
  };

  initVolumeCountsSetting = (dashboardSetting) => {
    if (dashboardSetting && dashboardSetting[DASHBOARD_WIDGETS.VOLUME_COUNT]) {
      return {
        changed: false,
        period: dashboardSetting[DASHBOARD_WIDGETS.VOLUME_COUNT].period_type,
        timeRange: this.initMapTimeRange(
          dashboardSetting[DASHBOARD_WIDGETS.VOLUME_COUNT].from_time,
          dashboardSetting[DASHBOARD_WIDGETS.VOLUME_COUNT].to_time
        ),
        select_type: dashboardSetting[DASHBOARD_WIDGETS.VOLUME_COUNT].intersection_option,
        selected_intersections: dashboardSetting[DASHBOARD_WIDGETS.VOLUME_COUNT].intersection_ids,
      };
    } else {
      return {
        ...SELECT_PERIODS_DEFAULT_SETTING,
        ...SELECT_INTERSECTION_DEFAULT_SETTING,
        timeRange: this.initMapTimeRange(),
      };
    }
  };

  initSummaryReportSetting = (dashboardSetting, dashboard_metadata) => {
    if (
      dashboardSetting &&
      dashboardSetting[DASHBOARD_WIDGETS.SUMMARY_REPORT]
    ) {
      const setting = {
        changed: false,
        period: dashboardSetting[DASHBOARD_WIDGETS.SUMMARY_REPORT].period_type,
        timeRange: this.initMapTimeRange(
          dashboardSetting[DASHBOARD_WIDGETS.SUMMARY_REPORT].from_time,
          dashboardSetting[DASHBOARD_WIDGETS.SUMMARY_REPORT].to_time
        ),
        metrics: [],
      };

      if (dashboard_metadata?.summary_report_widget_metadata?.metrics) {
        const metricSettings =
          dashboardSetting[DASHBOARD_WIDGETS.SUMMARY_REPORT].metrics;
        setting.metrics =
          dashboard_metadata.summary_report_widget_metadata.metrics.map(
            (el) => {
              const metric = metricSettings.find(
                (item) => item.metric_id === el.id
              );
              el.operators.push(DEFAULT_CONDITION_SUMMARY_REPORT);
              return {
                ...el,
                warningCondition: metric?.warning_condition || {
                  operator: null,
                  value: null,
                },
                enabled: !!metric,
              };
            }
          );
      }

      return setting;
    } else {
      if (dashboard_metadata?.summary_report_widget_metadata?.metrics) {
        return {
          ...SELECT_PERIODS_DEFAULT_SETTING,
          metrics:
            dashboard_metadata.summary_report_widget_metadata.metrics.map(
              (el) => {
                el.operators.push(DEFAULT_CONDITION_SUMMARY_REPORT);
                return {
                  ...el,
                  warningCondition: {
                    operator: null,
                    value: null,
                  },
                  enabled:
                    DEFAULT_SUMMARY_REPORT_METRICS.findIndex(
                      (item) => el.id === item
                    ) >= 0,
                };
              }
            ),
          timeRange: this.initMapTimeRange(),
        };
      }
      return {
        ...SELECT_PERIODS_DEFAULT_SETTING,
        metrics: [],
        timeRange: this.initMapTimeRange(),
      };
    }
  };

  initNotificationSetting = (dashboardSetting, dashboard_metadata) => {
    if (dashboardSetting && dashboardSetting[DASHBOARD_WIDGETS.NOTIFICATION]) {
      const setting = {
        changed: false,
        period: dashboardSetting[DASHBOARD_WIDGETS.NOTIFICATION].period_type,
        timeRange: this.initMapTimeRange(
          dashboardSetting[DASHBOARD_WIDGETS.NOTIFICATION].from_time,
          dashboardSetting[DASHBOARD_WIDGETS.NOTIFICATION].to_time
        ),
        notificationTypes: [],
      };

      if (
        dashboard_metadata?.notification_widget_metadata?.notification_types
      ) {
        const notificationTypes =
          dashboardSetting[DASHBOARD_WIDGETS.NOTIFICATION].notification_types;
        setting.notificationTypes =
          dashboard_metadata.notification_widget_metadata.notification_types.map(
            (el) => {
              const type = notificationTypes.find(
                (item) => item === el.notification_type?.id
              );
              return { ...el, enabled: !!type };
            }
          );
      }

      return setting;
    } else {
      if (
        dashboard_metadata?.notification_widget_metadata?.notification_types
      ) {
        return {
          ...SELECT_PERIODS_DEFAULT_SETTING,
          notificationTypes:
            dashboard_metadata.notification_widget_metadata.notification_types.map(
              (el) => ({ ...el })
            ),
          timeRange: this.initMapTimeRange(),
        };
      }
      return {
        ...SELECT_PERIODS_DEFAULT_SETTING,
        notificationTypes: [],
        timeRange: this.initMapTimeRange(),
      };
    }
  };

  initLayouts = (dashboard_setting) => {
    return Object.values(dashboard_setting)
      .map((el) => {
        try {
          return JSON.parse(el.position);
        } catch (e) {
          switch (el.widget_id) {
            case DASHBOARD_WIDGET_IDS.HEATMAP:
              return DASHBOARD_DEFAULT_LAYOUT[0];
            case DASHBOARD_WIDGET_IDS.TOP_TEN:
              return DASHBOARD_DEFAULT_LAYOUT[1];
            case DASHBOARD_WIDGET_IDS.INFO_ALARM_MESSAGE:
              return DASHBOARD_DEFAULT_LAYOUT[2];
            case DASHBOARD_WIDGET_IDS.SUMMARY_REPORT:
              return DASHBOARD_DEFAULT_LAYOUT[3];
            case DASHBOARD_WIDGET_IDS.VOLUME_COUNT:
              return DASHBOARD_DEFAULT_LAYOUT[4];
            case DASHBOARD_WIDGET_IDS.NOTIFICATION:
              return DASHBOARD_DEFAULT_LAYOUT[5];
            default:
              return null;
          }
        }
      })
      .filter((el) => !!el);
  };

  /**
   * Get data for only shown widgets
   */
  fetchDashboardData = () => {
    this.layouts.forEach((el) => {
      switch (el.i) {
        case DASHBOARD_WIDGETS.HEATMAP:
          this.getAgencySettingByAgencyId();
          break;
        case DASHBOARD_WIDGETS.TOP_TEN:
          this.getTopTenOpenAlarms();
          break;
        case DASHBOARD_WIDGETS.INFO_ALARM_MESSAGE:
          this.getChartInfo();
          break;
        case DASHBOARD_WIDGETS.SUMMARY_REPORT:
          this.getSummaryReport();
          break;
        case DASHBOARD_WIDGETS.VOLUME_COUNT:
          this.getVolumeCounts();
          break;
        case DASHBOARD_WIDGETS.NOTIFICATION:
          this.getNotification();
          break;
        default:
          break;
      }
    });
  };

  initMapTimeRange = (from, to) => {
    const fromTime = from ? moment.utc(from).local() : moment().startOf("day");
    const toTime = to ? moment.utc(to).local() : moment();
    return [fromTime, toTime];
  };

  @action getIntersectionOptions = () => {
    this.moduleService
      .getAllIntersectionByAgencyId({
        agency_id: this.filterData.agency_id,
        status: AVAILABLE,
      })
      .then((result) => {
        runInAction(() => {
          this.intersectionOptions = result || [];
        });
      })
      .catch(() => {
        runInAction(() => {
          this.intersectionOptions = [];
        });
      });
  };

  @action renterTotalItems = (total, range) => {
    return (
      range[0] +
      "-" +
      range[1] +
      " " +
      i18n.t("of") +
      " " +
      total +
      " " +
      i18n.t("items")
    );
  };

  @action getVolumeCounts = () => {
    runInAction(() => {
      this.volumeCounts.loading = true;
    });
    const params = {
      agency_id: this.filterData.agency_id,
      intersection_option: this.volumeCountsSetting.select_type,
      intersection_ids: this.volumeCountsSetting.selected_intersections,
    };
    if (this.volumeCountsSetting.period === TIME_PERIODS.CUSTOM) {
      params.from_time = this.volumeCountsSetting.timeRange[0]
        .utc()
        .toISOString();
      params.to_time = this.volumeCountsSetting.timeRange[1]
        .utc()
        .toISOString();
    } else if (this.volumeCountsSetting.period === TIME_PERIODS.LAST_12_HOURS) {
      const period = getLastPeriodTime(12, "hour");
      params.from_time = period[0].toISOString();
      params.to_time = period[1].toISOString();
    } else if (this.volumeCountsSetting.period === TIME_PERIODS.LAST_24_HOURS) {
      const period = getLastPeriodTime(24, "hour");
      params.from_time = period[0].toISOString();
      params.to_time = period[1].toISOString();
    }
    this.moduleService
      .getVolumeCounts(params)
      .then((res) => {
        runInAction(() => {
          this.volumeCounts.data = res?.intersections || [];
        });
      })
      .catch(() => {
        runInAction(() => {
          this.volumeCounts.data = [];
        });
      })
      .finally(() => {
        runInAction(() => {
          this.volumeCounts.loading = false;
        });
      });
  };

  @action getSummaryReport = () => {
    runInAction(() => {
      this.summaryReport.loading = true;
    });
    const params = {
      agency_id: this.filterData.agency_id,
      metrics: this.summaryReportSetting.metrics
        .filter((el) => el.enabled)
        .map((el) => {
          if (
            (el.warningCondition.operator && el.warningCondition.value) ||
            el.warningCondition.value === 0
          ) {
            return `${el.id}:${el.warningCondition.operator}:${el.warningCondition.value}`;
          }
          return el.id;
        })
        .join(", "),
      page: this.summaryReport.pagination.current - 1,
      size: this.summaryReport.pagination.pageSize,
      sort: this.summaryReport.sort,
      filter: this.summaryReport.text
        ? "intName:" + this.summaryReport.text
        : "",
    };
    if (this.summaryReportSetting.period === TIME_PERIODS.CUSTOM) {
      params.from_time = this.summaryReportSetting.timeRange[0]
        .utc()
        .toISOString();
      params.to_time = this.summaryReportSetting.timeRange[1]
        .utc()
        .toISOString();
    } else if (
      this.summaryReportSetting.period === TIME_PERIODS.LAST_12_HOURS
    ) {
      const period = getLastPeriodTime(12, "hour");
      params.from_time = period[0].toISOString();
      params.to_time = period[1].toISOString();
    } else if (
      this.summaryReportSetting.period === TIME_PERIODS.LAST_24_HOURS
    ) {
      const period = getLastPeriodTime(24, "hour");
      params.from_time = period[0].toISOString();
      params.to_time = period[1].toISOString();
    }
    this.moduleService
      .getSummaryReport(params)
      .then((res) => {
        runInAction(() => {
          this.summaryReport.data = res?.int_statistics || [];
          this.summaryReport.pagination.total =
            res?.total_count || this.summaryReport.data.length;
        });
      })
      .catch(() => {
        runInAction(() => {
          this.summaryReport.data = [];
          this.summaryReport.pagination.total = 0;
        });
      })
      .finally(() => {
        runInAction(() => {
          this.summaryReport.loading = false;
        });
      });
  };

  @action getNotification = () => {
    runInAction(() => {
      this.notification.loading = true;
    });
    const params = {
      agency_id: this.filterData.agency_id,
      type_ids: this.notificationSetting.notificationTypes
        .filter((el) => el.enabled)
        .map((el) => el.notification_type.id)
        .join(","),
    };
    if (this.notificationSetting.period === TIME_PERIODS.CUSTOM) {
      params.from_time = this.notificationSetting.timeRange[0]
        .utc()
        .toISOString();
      params.to_time = this.notificationSetting.timeRange[1]
        .utc()
        .toISOString();
    } else if (this.notificationSetting.period === TIME_PERIODS.LAST_12_HOURS) {
      const period = getLastPeriodTime(12, "hour");
      params.from_time = period[0].toISOString();
      params.to_time = period[1].toISOString();
    } else if (this.notificationSetting.period === TIME_PERIODS.LAST_24_HOURS) {
      const period = getLastPeriodTime(24, "hour");
      params.from_time = period[0].toISOString();
      params.to_time = period[1].toISOString();
    }
    this.moduleService
      .getNotification(params)
      .then((res) => {
        runInAction(() => {
          this.notification.data = res || {};
        });
      })
      .catch(() => {
        runInAction(() => {
          this.notification.data = {};
        });
      })
      .finally(() => {
        runInAction(() => {
          this.notification.loading = false;
        });
      });
  };

  @action updateSummaryReportIndexTab = (indexTab) => {
    this.typeSummaryReport = indexTab;
  };

  @action changeSummaryReportPagination = (pagination, filters, sorter) => {
    let sortString = "";
    const sortTypeSummaryReport = this.typeSummaryReport
      ? this.typeSummaryReport
      : SUMMARY_REPORT_TAB_INDEX.AVERAGE;
    if (sorter.order) {
      sortString =
        sorter.columnKey + "." + sortTypeSummaryReport + ":" + sorter.order;
    }
    runInAction(() => {
      this.summaryReport.pagination = Object.assign(
        {},
        this.summaryReport.pagination,
        pagination
      );
      this.summaryReport.sort = sortString;
    });
    this.getSummaryReport();
  };

  @action handleFilterSummaryReportChange = (
    target,
    value,
    forceLoad = true
  ) => {
    if (this.summaryReport) {
      this.summaryReport[target] = value;
    }
    if (target === SEARCH_TEXT_FIELD) {
      if (value?.length !== 0 && value?.length <= MAX_NUMBER_START_SEARCH) {
        return;
      }

      if (this.timeoutSearchString) clearTimeout(this.timeoutSearchString);
      if (forceLoad)
        this.timeoutSearchString = setTimeout(() => {
          this.handleFilerAction();
        }, DELAY_SEARCH_STRING);
    } else if (forceLoad) {
      this.handleFilerAction();
    }
  };

  @action handleFilerAction = () => {
    this.summaryReport.pagination = {
      ...this.summaryReport.pagination,
      current: 1,
      pageSize: parseInt(process.env.REACT_APP_PAGE_SIZE) || null,
      total: 0,
    };
    this.getSummaryReport();
  };
}

export default DashboardStore;
