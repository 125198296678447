import { Table, Button, Tooltip, InputNumber } from "antd";
import { DndProvider, DragSource, DropTarget } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import React from "react";
import { withTranslation } from "react-i18next";
import AntFieldmik from "components/AntFieldmik";
import StatusTag from "components/StatusTag";

let dragingIndex = -1;

class BodyRow extends React.Component {
  render() {
    const {
      isOver,
      connectDragSource,
      connectDropTarget,
      // eslint-disable-next-line unused-imports/no-unused-vars
      moveRow,
      ...restProps
    } = this.props;
    const style = { ...restProps.style, cursor: "move" };

    let { className } = restProps;
    if (isOver) {
      if (restProps.index > dragingIndex) {
        className += " drop-over-downward";
      }
      if (restProps.index < dragingIndex) {
        className += " drop-over-upward";
      }
    }

    return connectDragSource(
      connectDropTarget(
        <tr {...restProps} className={className} style={style} />
      )
    );
  }
}

const rowSource = {
  beginDrag(props) {
    dragingIndex = props.index;
    return {
      index: props.index,
    };
  },
};

const rowTarget = {
  drop(props, monitor) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }

    // Time to actually perform the action
    props.moveRow(dragIndex, hoverIndex);

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    monitor.getItem().index = hoverIndex;
  },
};

const DragableBodyRow = DropTarget("row", rowTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
}))(
  DragSource("row", rowSource, (connect) => ({
    connectDragSource: connect.dragSource(),
  }))(BodyRow)
);

class TableCorridorList extends React.Component {
  constructor(props) {
    super(props);
  }
  getTableColumns = () => {
    const { t, remove, form } = this.props;
    return [
      {
        title: t("alarm_helper.no"),
        key: "no",
        render: (_dataFields, _item, id) => id + 1,
      },
      // {
      //   title: "UUID",
      //   dataIndex: "id",
      //   key: "id",
      // },
      {
        title: t("module_name", { module: t("intersection") }),
        dataIndex: "name",
        key: "name",
      },
      {
        title: t("corridor.coordphase_downstream"),
        dataIndex: "downstream",
        key: "downstream",
        render: (_dataFields, _item, id) => {
          return (
            <AntFieldmik
              name={`corridor_intersections.${id}.downstream`}
              validate={(value) => {
                if (!value && value !== 0)
                  return t("field_required", {
                    field: t("corridor.coordphase_downstream"),
                  });
                if (value < 1 || value > 16)
                  return t("range_beetwen", {
                    field: t("corridor.coordphase_downstream"),
                    min: 1,
                    max: 16,
                  });
              }}
              hideExplainError
              className="mb-0">
              {({
                field: { value },
                form: { setFieldValue, validateField },
              }) => (
                <InputNumber
                  //  onFocus={(e) => this.fixSelectable(e, true)}
                  //  onBlur={(e) => this.fixSelectable(e, false)}
                  parser={(value) => value.replace(/[^0-9]/g, "")}
                  value={value}
                  onChange={async (value) => {
                    setFieldValue(
                      `corridor_intersections.${id}.downstream`,
                      value
                    );
                    await Promise.resolve();
                    validateField(`corridor_intersections.${id}.downstream`);
                  }}
                />
              )}
            </AntFieldmik>
          );
        },
      },
      {
        title: t("corridor.coordphase_upstream"),
        dataIndex: "upstream",
        key: "upstream",
        render: (_dataFields, _item, id) => {
          return (
            <AntFieldmik
              name={`corridor_intersections.${id}.upstream`}
              validate={(value) => {
                if (!value && value !== 0)
                  return t("field_required", {
                    field: t("corridor.coordphase_upstream"),
                  });
                if (value < 1 || value > 16)
                  return t("range_beetwen", {
                    field: t("corridor.coordphase_upstream"),
                    min: 1,
                    max: 16,
                  });
              }}
              hideExplainError
              className="mb-0">
              {({
                field: { value },
                form: { setFieldValue, validateField },
              }) => (
                <InputNumber
                  //  onFocus={(e) => this.fixSelectable(e, true)}
                  //  onBlur={(e) => this.fixSelectable(e, false)}
                  parser={(value) => {
                    return value?.replace(/[^0-9]/g, "");
                  }}
                  value={value}
                  onChange={async (value) => {
                    setFieldValue(
                      `corridor_intersections.${id}.upstream`,
                      value
                    );
                    await Promise.resolve();
                    validateField(`corridor_intersections.${id}.upstream`);
                  }}
                />
              )}
            </AntFieldmik>
          );
        },
      },

      {
        title: t("corridor.distance"),
        dataIndex: "distance",
        key: "distance",
        render: (_dataFields, _item, id) => {
          return (
            <AntFieldmik
              name={`corridor_intersections.${id}.distance`}
              validate={(value) => {
                if (!value && value !== 0)
                  return t("field_required", {
                    field: t("corridor.distance"),
                  });

                if (
                  id === form.values.corridor_intersections?.length - 1 &&
                  value !== 0
                )
                  return t("corridor.last_distance_list_int_err");
              }}
              hideExplainError
              className="mb-0">
              {({
                field: { value },
                form: { setFieldValue, validateField },
              }) => (
                <InputNumber
                  //  onFocus={(e) => this.fixSelectable(e, true)}
                  //  onBlur={(e) => this.fixSelectable(e, false)}
                  parser={(value) => value.replace(/[^0-9]/g, "")}
                  value={value}
                  onChange={async (value) => {
                    setFieldValue(
                      `corridor_intersections.${id}.distance`,
                      value
                    );
                    await Promise.resolve();
                    validateField(`corridor_intersections.${id}.distance`);
                  }}
                />
              )}
            </AntFieldmik>
          );
        },
      },
      {
        title: t("corridor.speed"),
        dataIndex: "speed",
        key: "speed",
      },
      {
        title: t("status"),
        dataIndex: "status",
        key: "status",
        render: (status) => <StatusTag status={status} />,
      },
      {
        title: t("actions"),
        key: "action",
        render: (_item, _record, id) => {
          return (
            <Tooltip title={t("delete")}>
              <Button
                type="link"
                icon="delete"
                onClick={() => remove(id)}></Button>
            </Tooltip>
          );
        },
      },
    ];
  };

  components = {
    body: {
      row: DragableBodyRow,
    },
  };

  moveRow = (dragIndex, hoverIndex) => {
    const { swap } = this.props;
    swap(dragIndex, hoverIndex);
  };
  // fixSelectable(oElement, bGotFocus) {
  //   var oParent = oElement.target;

  //   while (
  //     oParent &&
  //     (oParent.draggable === null || oParent.draggable === undefined)
  //   )
  //     oParent = oParent.parentNode;
  //   if (oParent) oParent.draggable = !bGotFocus;
  //   console.log(oParent);
  // }
  render() {
    const {
      form: {
        values: { corridor_intersections },
      },
    } = this.props;

    return (
      <DndProvider backend={HTML5Backend}>
        <Table
          rowKey={"id"}
          rowClassName={(record, index) => {
            if (index % 2 !== 0) return "odd-row";
            else return "even-row";
          }}
          pagination={false}
          className="spm-table wrap-table mt-16"
          columns={this.getTableColumns()}
          dataSource={corridor_intersections}
          components={this.components}
          onRow={(_record, index) => ({
            index,
            moveRow: this.moveRow,
          })}
        />
      </DndProvider>
    );
  }
}

export default withTranslation()(TableCorridorList);
