import { action, observable, runInAction, computed } from "mobx";
import AgencyService from "services/AgencyService";
import AnalysisService from "services/AnalysisService";
import AnanlysisScheduleResultService from "services/TemplateGroup/AnanlysisScheduleResultService";
import UserService from "services/UserService";
import BaseStore from "stores/BaseStore";
import { RESOURCE_LIST, TIMEOUT_RESIZE, ANALYSIS_TYPE } from "utils/constants";
import { v4 as uuid } from "uuid";
class AnalysisScheduleResult extends BaseStore {
  @observable allAgencies = [];
  @observable allUsers = [];
  constructor(parentStore) {
    super(new AnanlysisScheduleResultService(parentStore), parentStore);
    this.agencyService = new AgencyService(parentStore);
    this.userService = new UserService(parentStore);
    this.analysisService = new AnalysisService(parentStore);
    this.resourceName = RESOURCE_LIST.ALARM_RECORD;
    this.filterData = {
      ...this.filterData,
      ...{
        text: "",
        owner_id: null,
        metric_type: null,
        agency_id: "",
        from_time: null,
        to_time: null,
        from_date: null,
        to_date: null,
        sort: "",
      },
    };
    this.resourceName = RESOURCE_LIST.REPORT_RESULT;
  }
  @observable loadingChart = false;
  @observable reportData = {};
  @observable prev_result_time = null;
  @observable allAnalysisTypes = [];
  @observable printPReview = false;
  @observable selectedDate = null;
  /**
   * getCategoryName
   *
   * @return  {string} -
   */
  @action togglePrintPReviewVisibility = () => {
    this.printPReview = !this.printPReview;
  };
  @action resetData() {
    this.reportData = {};
  }
  @action getAllUsers = (agency_id) => {
    const userService = new UserService(this.parentStore);
    if (this.allUsers?.length == 0) {
      this.loading = true;
      userService?.getAllUserByAgency(
        agency_id,
        (response) => {
          runInAction(() => {
            this.allUsers = [
              ...response?.data,
              {
                id: -1,
                name: "System",
                email: "SYSTEM",
              },
            ];
          });
        },
        () => {
          runInAction(() => {
            this.loading = false;
          });
        }
      );
    }
  };
  @action getAllAnalysisTypes = (cb) => {
    this.analysisService.getAllAnalysisTypes().then((data) => {
      runInAction(() => {
        this.allAnalysisTypes = data.analyses;
      });
      cb && cb();
    });
  };
  @action setDetaultAgencyFilter = (isSuperAdmin, cb) => {
    if (isSuperAdmin)
      this.agencyService.getAll((data) => {
        runInAction(() => {
          this.allAgencies = data;
          //set default agency
          if (Array.isArray(this.allAgencies) && this.allAgencies[0]) {
            let defaultAgency = this.allAgencies.find(
              (item) => item.id === this.filterData.agency_id
            );
            if (!defaultAgency) {
              this.filterData.agency_id = this.allAgencies[0].id;
            }
          }
          cb && cb();
        });
      });
    else {
      const currentAgency = this.parentStore.myStore.currentAgency;
      this.filterData = {
        ...this.filterData,
        ...{ agency_id: currentAgency.agency_id },
      };
      cb && cb();
    }
  };

  @action delete = (id = null) => {
    this.loading = true;
    if (!id) {
      id = this.selectedItems;
    }
    this.moduleService?.delete(
      { result_ids: id },
      () => {
        //redirect into page 1 avoid load wrong data
        this.filterData.pagination.current = 1;
        this.getData();
      },
      () => {
        runInAction(() => {
          this.loading = false;
        });
      }
    );
  };
  @action changeCurrentDateResult = (selectedDate) => {
    this.selectedDate = selectedDate;
    this.loadingChart = true;
    const currentChart = this.reportData.chart_data.days?.find(
      (chart) => chart.date === selectedDate
    );
    this.parentStore.chartStore.clearChartData();
    this.parentStore.chartStore.preTranformChartData(currentChart, null);
    //force component rerender the chart Data when switch mode
    setTimeout(() => {
      runInAction(() => {
        this.loadingChart = false;
      });
    }, TIMEOUT_RESIZE);
  };
  @action resetData = () => {
    this.reportData = {};
    this.parentStore.chartStore.clearChartData();
  };
  @action getResultData = (id) => {
    this.loading = true;

    this.moduleService?.getDetail(
      id,
      (response) => {
        if (response.metric_type === ANALYSIS_TYPE.YTO) {
          const chartData = response.chart_data
            ? JSON.parse(response.chart_data)
            : null;
          runInAction(() => {
            this.reportData = { ...response, chart_data: chartData?.charts };
            this.loading = false;
          });
          return;
        }

        runInAction(() => {
          const chartListJson = response.chart_data
            ? JSON.parse(response.chart_data)
            : null;

          const chartListTranfrom = {
            ...chartListJson,
            days: chartListJson.days?.map((day) => {
              let dateFilterData = null;
              let dateFilterChart = null;
              switch (response.metric_type) {
                case ANALYSIS_TYPE.VOLUMNE:
                  dateFilterData = `${response.from_date} - ${response.to_date}`;
                  dateFilterChart = response.from_date;
                  break;
                default:
                  dateFilterData = day.date;
                  dateFilterChart = day.date;
                  break;
              }

              return {
                ...day,
                date: dateFilterData,
                int_uuid: chartListJson.int_uuid,
                int_name: chartListJson.int_name,
                from_time: `${dateFilterChart}T${chartListJson.from_time}`,
                to_time: `${dateFilterChart}T${chartListJson.to_time}`,
                analysis_type: response.metric_type,
              };
            }),
          };
          this.reportData = {
            ...response,
            chart_data: chartListTranfrom,
          };
          if (chartListTranfrom.days)
            this.changeCurrentDateResult(chartListTranfrom.days[0]?.date);
          this.loading = false;
        });
      },
      () => {
        runInAction(() => {
          this.reportData = null;
          this.loading = false;
        });
      }
    );
  };

  @computed get getYellowTrapData() {
    const arrayData = [];
    this.reportData.chart_data?.forEach((day) => {
      day.events?.forEach((item) =>
        arrayData.push({
          ...item,
          index: uuid(),
        })
      );
    });
    return arrayData;
  }
}

export default AnalysisScheduleResult;
