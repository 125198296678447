import React, { Component } from "react";
import { Form, Icon, Input, Button, Alert } from "antd";
import { ReactComponent as Logo } from "../../svgs/logo.svg";
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import "./style.scss";

/**
 * Component for showing recovery password page.
 *
 * @component
 */
@inject("rootStore")
@observer
class ForgotPassword extends Component {
  /**
   * handleLoginClick
   * redirect page into login page
   *
   */
  handleLoginClick = () => {
    this.props?.history?.push("/login");
  };

  /**
   * componentDidMount
   * check whenever logined user enter the forgot page -redirect to home page
   * clear all error list
   */
  componentDidMount = () => {
    const {
      t,
      rootStore: {
        authStore: { clearErrorList },
      },
    } = this.props;

    document.title = t("forgot_pass.page-title");
    clearErrorList();
  };

  /**
   * handleSubmit
   * validation input then send back to store
   *
   */
  handleSubmit = (e) => {
    e.preventDefault();
    const {
      rootStore: {
        authStore: { resetpassword },
      },
    } = this.props;
    this.props.form.validateFields((err, values) => {
      if (!err) {
        resetpassword(values, this.props?.history);
      }
    });
  };
  /**
   * render
   *
   * @return  {Component}
   */
  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      rootStore: {
        authStore: { loading, errorList, clearErrorList },
      },
      t,
    } = this.props;
    return (
      <div className="wrap-passwordreset-comp">
        <div className="wrap-login-error-comp">
          {errorList && (
            <Alert
              message={errorList.message}
              type="error"
              closable
              showIcon
              onClose={clearErrorList}
              className="spm-error-comp"
            />
          )}
        </div>
        <div className="wrap-login-comp">
          <Form onSubmit={this.handleSubmit} className="passwordreset-form">
            <div className="logo-login">
              <Logo className="logo-img"> </Logo>
            </div>
            <div className="title-login">
              {t("forgot_pass.recovery_password_title")}
            </div>
            <Form.Item required={false} label={t("forgot_pass.email")}>
              {getFieldDecorator("email", {
                rules: [
                  {
                    type: "email",
                    message: t("forgot_pass.email_format"),
                  },
                  { required: true, message: t("forgot_pass.email_required") },
                ],
              })(
                <Input
                  maxLength={255}
                  prefix={<Icon type="mail" />}
                  placeholder={t("forgot_pass.email")}
                />
              )}
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="reset-form-button"
                loading={loading}
              >
                {" "}
                {t("forgot_pass.send")}{" "}
              </Button>
              <a
                className="login-form login-form-forgot"
                onClick={this.handleLoginClick}
              >
                {t("forgot_pass.login")}
              </a>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
}

const ForgotPasswordForm = Form.create({ name: "forgot_ResetPassword" })(
  ForgotPassword
);

export default withRouter(withTranslation()(ForgotPasswordForm));
