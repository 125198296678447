import { Badge, Button, Checkbox, Descriptions, Radio } from "antd";
import React from "react";
import { withTranslation } from "react-i18next";
import {
  CHART_COLOR_SCHEMA,
  HISTORIC_TIMSPACE_MODE,
  DATE_FORMAT,
} from "utils/constants";
import moment from "moment";
import { withRouter } from "react-router";

class HeaderTimeSpaceInfo extends React.Component {
  render() {
    const {
      t,
      chartType,
      handleChangeMode,
      hasProgrammed,
      showGreenwave,
      showUpstreamBand,
      showDownstreamBand,
      handleChangeViewFilter,
      showTimePhase,
      showAogPhase,
      data,
    } = this.props;

    return (
      <>
        {" "}
        <Descriptions
          className="bg-background py-8 px-8"
          title={
            <div className="flex justify-space-beetwen">
              <div>
                <h1>{t("optimization.historic_timespace")} </h1>
              </div>
              <div>
                <Button
                  icon="arrow-left"
                  className="mx-8"
                  onClick={() => {
                    this.props.history.push("/analysis/optimizer");
                  }}>
                  {t("detail.back")}
                </Button>
              </div>
            </div>
          }
          column={{ md: 4 }}>
          <Descriptions.Item label={t("name", { module: t("Corridor") })}>
            {data?.name}
          </Descriptions.Item>
          <Descriptions.Item label={t("report_result.time_range")}>
            {data?.start_time} - {data?.end_time}
          </Descriptions.Item>

          <Descriptions.Item label={t("print.date")}>
            {data?.start_date}
          </Descriptions.Item>
          <Descriptions.Item label={t("optimization.plan_number")}>
            {data?.plan_number}
          </Descriptions.Item>
          <Descriptions.Item label={t("optimization.created_by")}>
            {data?.owner?.name}
          </Descriptions.Item>

          <Descriptions.Item label={t("report_template.created_at")}>
            {moment(data?.created_at).format(DATE_FORMAT.full_with_tz)}
          </Descriptions.Item>
        </Descriptions>
        <div className="flex justify-space-beetwen items-center mt-8 mb-8">
          <div className="flex">
            <Badge
              className="border-square mx-8"
              color={CHART_COLOR_SCHEMA.LOWER_GREEN}
              text={"Green Time"}
            />
            <Badge
              className="border-square mx-8"
              color={CHART_COLOR_SCHEMA.YELLOW}
              text={"Yellow Time"}
            />
            <Badge
              className="border-square mx-8"
              color={CHART_COLOR_SCHEMA.RED}
              text={"Red Time"}
            />
            <Badge
              className="border-square ml-8"
              color={CHART_COLOR_SCHEMA.GREEN_SIEMENS}
              text={"Green Time of Coord Phase"}
            />
            <span className="px-16">|</span>

            <Checkbox
              style={{ "--checkbox-chart-color": "#c0c0c0" }}
              onChange={(e) =>
                handleChangeViewFilter({ showUpstreamBand: e.target.checked })
              }
              checked={showUpstreamBand}>
              {t("show", {
                item: t("optimization.upstream_band"),
              })}
            </Checkbox>
            <Checkbox
              style={{ "--checkbox-chart-color": "#acd6ff" }}
              onChange={(e) =>
                handleChangeViewFilter({ showDownstreamBand: e.target.checked })
              }
              checked={showDownstreamBand}>
              {t("show", {
                item: t("optimization.downstream_band"),
              })}
            </Checkbox>

            {chartType === HISTORIC_TIMSPACE_MODE.ACTUAL ? (
              <>
                <Checkbox
                  onChange={(e) =>
                    handleChangeViewFilter({ showGreenwave: e.target.checked })
                  }
                  style={{
                    "--checkbox-chart-color": CHART_COLOR_SCHEMA.GREEN,
                  }}
                  checked={showGreenwave}>
                  {t("show", {
                    item: t("optimization.greenwave"),
                  })}
                </Checkbox>
                <span className="px-16">|</span>

                <Checkbox
                  style={{ "--checkbox-chart-color": "#bbffff" }}
                  onChange={(e) =>
                    handleChangeViewFilter({
                      showTimePhase: e.target.checked,
                      showAogPhase: e.target.checked ? false : showAogPhase,
                    })
                  }
                  checked={showTimePhase}>
                  {t("show", {
                    item: t("optimization.the_time"),
                  })}
                </Checkbox>
                <Checkbox
                  style={{ "--checkbox-chart-color": CHART_COLOR_SCHEMA.GREEN }}
                  onChange={(e) =>
                    handleChangeViewFilter({
                      showTimePhase: e.target.checked ? false : showTimePhase,
                      showAogPhase: e.target.checked,
                    })
                  }
                  checked={showAogPhase}>
                  {t("show", {
                    item: "AoG",
                  })}
                </Checkbox>
              </>
            ) : (
              <></>
            )}
          </div>
          <Radio.Group
            buttonStyle="solid"
            value={chartType}
            onChange={(e) => handleChangeMode(e.target.value)}>
            {hasProgrammed ? (
              <Radio.Button value={HISTORIC_TIMSPACE_MODE.PROGRAMMED}>
                {t(
                  `optimization.timespace.${HISTORIC_TIMSPACE_MODE.PROGRAMMED}`
                )}
              </Radio.Button>
            ) : (
              <></>
            )}{" "}
            <Radio.Button value={HISTORIC_TIMSPACE_MODE.ACTUAL}>
              {t(`optimization.timespace.${HISTORIC_TIMSPACE_MODE.ACTUAL}`)}
            </Radio.Button>
          </Radio.Group>
        </div>
      </>
    );
  }
}
export default withRouter(withTranslation()(HeaderTimeSpaceInfo));
