export default  {
    translation: {
      "Welcome to React": "german",
      "Dashboard":"Trang chủ",
      "Configuration":"Điều Chỉnh",
      "User":"Người dùng",
      "Report":"Báo cáo",
      "login.username.required" : "Bitte geben Sie Ihren Benutzernamen oder Ihre E-Mail-Adresse ein",
      "login.password.required" : "Bitte geben Sie Ihr Passwort ein",
      "login.username.placeholder" : "Benutzername / E-Mail",
      "login.password.placeholder" : "Passwort",
      "login.keepMe" : "Angemeldet bleiben",
      "login.login" : "Einloggen",
      "login.forgotpass" : "Passwort vergessen"
    }
  }