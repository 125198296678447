import { Button, Row, Typography } from "antd";
import React, { Component } from "react";
import { NOTIF_STATUS } from "utils/constants";
import { ReactComponent as Logo } from "../../svgs/logo.svg";
import "./style.scss";

class LocalPopUpComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayModal: true,
    };
  }

  render() {
    const currentStore = this.props.currentStore;
    let data;
    /**this code cause when click to open handle action, will only receive actionObject!
     *
     *  */
    let unReadReleaseNote =
      currentStore.unReadReleaseNote[currentStore.unReadReleaseNote.length - 1];
    if (!unReadReleaseNote?.action) data = unReadReleaseNote?.data;
    else data = unReadReleaseNote?.action?.data;
    const id = unReadReleaseNote?.id;
    const { t } = this.props;
    return (
      <div
        className="local-popup-modal"
        style={{ display: data ? "block" : "none" }}
      >
        <div className="modal-content">
          <div className="modal-header">
            <div className="modal-header-left">
              <div className="modal-header-logo">
                <Logo className="logo-img" />
              </div>
            </div>
            <div className="modal-header-main">
              <Typography>
                <Typography.Title level={3}>{data?.title}</Typography.Title>

                <Typography.Text type="warning">
                  <b>{data?.description}</b>
                </Typography.Text>
              </Typography>
            </div>
            <div className="modal-header-right">
              <Typography.Title level={3}>
                {t("localpopup.version")}{" "}
                <span style={{ color: "#009999" }}>{data?.version}</span>
              </Typography.Title>
            </div>
          </div>
          <div className="modal-body">
            <div className="list-description">
              {data?.content?.map((note, id) => {
                return (
                  <div key={id}>
                    <Typography.Text style={{ fontSize: "16px" }}>
                      <b> {note?.title}</b>
                    </Typography.Text>
                    <ul>
                      {note?.description?.map((e, i) => (
                        <li key={i}>
                          <Typography.Text level={4}>{e}</Typography.Text>
                        </li>
                      ))}
                    </ul>
                  </div>
                );
              })}
            </div>
          </div>
          <Row className="modal-footer">
            <Button
              className="modal-confirm-btn"
              type="primary"
              htmlType="button"
              loading={currentStore.loading}
              onClick={() => {
                if (id) currentStore.updateNotifRead([id], NOTIF_STATUS.READ);
                else currentStore.setUnReadReleaseNote([]);
              }}
            >
              {t("localpopup.got-it")}
            </Button>
          </Row>
        </div>
      </div>
    );
  }
}

export default LocalPopUpComponent;
