import BaseApi from './BaseApi';

class RoleService extends BaseApi {

    constructor(parentStore) {
        super("roles", parentStore);
    }

}

export default RoleService;