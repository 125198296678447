import { inject, observer } from "mobx-react";
import React, { Component, createRef } from "react";
import { withTranslation } from "react-i18next";
import * as d3 from "d3";

import "./style.scss";
import { CHART_COLOR_SCHEMA } from "utils/constants";
import { toFixed2Offset } from "utils/helper";
import { Badge } from "antd";
const LABEL_WIDTH = 120;
const LINE_HEIGHT = 30;
const BLOCK_SPACE = 20;
const LEGEND_COLOR = {
  avg_green: CHART_COLOR_SCHEMA.GREEN_SIEMENS,
  avg_yellow: CHART_COLOR_SCHEMA.YELLOW,
  avg_red: CHART_COLOR_SCHEMA.RED,
  avg_ped_walk: CHART_COLOR_SCHEMA.GREEN,
  avg_ped_clear: CHART_COLOR_SCHEMA.LIGHT_GREEN,
};
const LEGEND_LABEL = {
  avg_green: "Avg Green",
  avg_yellow: "Avg Yellow",
  avg_red: "Avg Red",
  avg_ped_walk: "Prog Ped Walk",
  avg_ped_clear: "Prog Ped Clear",
};
@inject("rootStore")
@observer
class SummaryChart extends Component {
  chartObject;
  axisRefObject;
  xAxis;
  xScaleObject;
  crossHairObject = {};

  constructor(props) {
    super(props);
    this.chartRef = createRef();
    this.axisRef = createRef();
  }
  renderXAxis = () => {
    const { data } = this.props;

    let maxData = 0;

    for (let phaseName in data) {
      const phase = data[phaseName];
      for (let paramName in phase) {
        const params = phase[paramName];
        let total = 0;
        for (let itemName in params) {
          total += isNaN(params[itemName]) ? 0 : Number(params[itemName]);
        }
        maxData = Math.max(maxData, total);
      }
    }

    if (maxData === 0) {
      // Fake data to make sure that the domain is not empty.
      // This is a workaround for the issue that the domain of the xScaleObject is empty.
      maxData = 100;
    }

    this.xScaleObject = d3
      .scaleLinear()
      .domain([0, Math.ceil(maxData)])
      .range([0, this.chartRef?.current.clientWidth - 140]);
    // console.log();

    //create axisObject
    let axisObject = d3.axisBottom(this.xScaleObject);
    this.xAxis = this.axisRefObject
      .append("g")
      .attr("class", "x-axis-botttom")
      .attr("transform", "translate(" + LABEL_WIDTH + "," + 10 + ")")
      .call(axisObject);
  };

  renderData() {
    const currentContext = this;
    const { data } = this.props;
    let currentY = 20;
    this.chartData = this.chartObject
      .append("g")
      .attr("class", "lines-chart-group");
    for (let phaseName in data) {
      const phase = data[phaseName];
      for (let paramName in phase) {
        const params = phase[paramName];
        this.chartData
          .append("text")
          .attr("x", 20)
          .attr("y", currentY)
          .attr("class", "y-axis-label")
          .style("fill", "var(--text)")
          .style("text-anchor", "left")
          .style("font-size", "14px")
          .text(
            phaseName.replace("hase ", "") + " - " + paramName.toUpperCase()
          );
        let currentX = LABEL_WIDTH;
        for (let itemName in params) {
          const barWidth = this.xScaleObject(params[itemName] ?? 0);
          this.chartData
            .append("rect")
            .style("fill", LEGEND_COLOR[itemName])
            .attr("clip-path", "url(#clip)")
            .attr("transform", "translate(" + 0 + ", " + 0 + ")")
            .attr("x", currentX)
            .attr("y", currentY - 14)
            .attr("width", barWidth)
            .attr("class", "bar")
            .attr("height", 20)
            .on("mousemove", function () {
              let mouse = d3.mouse(this);
              currentContext.handleMouseMove(mouse, params);
              // console.log(itemName);
            })
            .on("mouseout", this.handleMouseOut);

          // FIXME: Calculate the width of the text to make sure that it is not overlapped with the bar instead of
          //  using a hard-coded value.
          if (barWidth > 15)
            this.chartData
              .append("text")
              .attr("x", currentX + barWidth / 2)
              .attr("y", currentY)
              .attr("class", "y-axis-label")
              .style("fill", "black")
              .style("text-anchor", "middle")
              .style("font-size", "12px")
              .text(toFixed2Offset(params[itemName]) ?? "")
              .on("mousemove", function () {
                let mouse = d3.mouse(this);
                currentContext.handleMouseMove(mouse, params);
                // console.log(itemName);
              })
              .on("mouseout", this.handleMouseOut);
          currentX += barWidth;
        }
        currentY += LINE_HEIGHT;

        // maxData = Math.max(maxData, total);
      }
      currentY += BLOCK_SPACE;
    }
  }
  renderChartAxisLine = () => {
    this.axisLineGroup = this.chartObject
      .append("g")
      .attr("class", "overlay-group");
    this.axisLineGroup
      .append("line")
      .attr("class", "cross-hair-line")
      .attr("stroke", "var(--text")
      .attr("stroke-width", 0.5)
      .attr("x1", 0)
      .attr("y1", 0)
      .attr("x2", 0)
      .attr("y2", this.chartRef.current.clientHeight);
    this.axisLineGroup
      .append("line")
      .attr("class", "cross-hair-line")
      .attr("stroke", "var(--text")
      .attr("stroke-width", 0.5)
      .attr("x1", this.chartRef.current.clientWidth)
      .attr("y1", 0)
      .attr("x2", this.chartRef.current.clientWidth)
      .attr("y2", this.chartRef.current.clientHeight);

    this.axisLineGroup
      .append("line")
      .attr("class", "cross-hair-line")
      .attr("stroke", "var(--text")
      .attr("stroke-width", 0.5)
      .attr("x1", 0)
      .attr("y1", 0)
      .attr("x2", this.chartRef.current.clientHeight)
      .attr("y2", 0);

    this.axisLineGroup
      .append("line")
      .attr("class", "cross-hair-line")
      .attr("stroke", "var(--text")
      .attr("stroke-width", 0.5)
      .attr("x1", 0)
      .attr("y1", this.chartRef.current.clientHeight)
      .attr("x2", this.chartRef.current.clientWidth)
      .attr("y2", this.chartRef.current.clientHeight);

    this.xScaleObject.ticks().forEach((tick) => {
      this.axisLineGroup
        .append("line")
        .attr("class", "cross-hair-line")
        .attr("stroke", "var(--text")
        .style("stroke-dasharray", "3, 3")
        .attr("stroke-width", 0.5)
        .attr("x1", this.xScaleObject(tick) + LABEL_WIDTH)
        .attr("y1", 0)
        .attr("x2", this.xScaleObject(tick) + LABEL_WIDTH)
        .attr("y2", this.chartRef.current.clientHeight);
    });
    let currentY = 20;
    const { data } = this.props;

    for (let phaseName in data) {
      const phase = data[phaseName];
      this.axisLineGroup
        .append("line")
        .attr("class", "cross-hair-line")
        .attr("stroke", "var(--text")
        .style("stroke-dasharray", "3, 3")
        .attr("stroke-width", 0.5)
        .attr("x1", 0)
        .attr("y1", currentY - 30)
        .attr("x2", this.chartRef.current.clientWidth)
        .attr("y2", currentY - 30);

      currentY += LINE_HEIGHT * Object.keys(phase).length;

      currentY += BLOCK_SPACE;
    }
  };
  renderChartCrossHair = () => {
    const currentContext = this;
    this.overLayGroup = this.chartObject
      .append("g")
      .attr("class", "overlay-group");
    let crossHairGroup = this.overLayGroup
      .append("g")
      .attr("class", "cross-hair-group");
    //add crosshair line
    this.crossHairObject.crosshair_x = crossHairGroup
      .append("line")
      .attr("class", "cross-hair-line")
      .attr("stroke", "var(--text)")
      .attr("display", "none")
      .attr("x1", 0)
      .attr("y1", 0)
      .attr("x2", 0)
      .attr("y2", this.chartRef.current.clientHeight);
    this.crossHairObject.crosshair_y = crossHairGroup
      .append("line")
      .attr("class", "cross-hair-line")
      .attr("stroke", "var(--text)")
      .attr("display", "none")
      .attr("x1", 0)
      .attr("y1", 0)
      .attr("x2", this.chartRef.current.clientWidth)
      .attr("y2", 0);
    //add overlay rect
    this.overLayGroup
      .append("rect")
      .attr("class", "overlay-area")
      .attr("width", this.chartRef.current.clientWidth)
      .attr("height", this.chartRef.current.clientHeight)
      .attr("fill-opacity", "0")
      .attr("transform", "translate(" + 0 + "," + 0 + ")")
      .on("mousemove", function () {
        let mouse = d3.mouse(this);

        currentContext.handleMouseMove(mouse);
      })
      .on("mouseout", this.handleMouseOut);
  };
  renderHightLinePoint = (targetItem, x, y) => {
    if (this.hightLineObject) {
      this.hightLineObject?.remove();
    }
    this.hightLineObject = this.chartObject
      .append("g")
      .attr("class", "hightline-group");

    this.hightLineObject
      .append("rect")
      .attr("width", 200) //hard code the width of box
      .attr("height", 70)
      .attr("class", "tooltip-box")
      .attr("stroke", "var(--text)")
      .attr("fill", "var(--background)")
      .attr("transform", "translate(" + (x + 10) + "," + (y + 10) + ")");
    let currentY = y;

    for (let item in targetItem) {
      this.hightLineObject
        .append("text")
        .text(LEGEND_LABEL[[item]] + ": " + toFixed2Offset(targetItem[item]))
        .attr("text-anchor", "left")

        .attr(
          "transform",
          "translate(" + (x + 24) + " ," + (currentY + 30) + ")"
        )
        .attr("fill", LEGEND_COLOR[item]);
      currentY += 20;
    }
  };
  handleMouseMove = (mousePosition, targetItem = null) => {
    if (Array.isArray(mousePosition) && mousePosition.length === 2) {
      let xPosition = mousePosition[0];
      let yPosition = mousePosition[1];

      if (targetItem) {
        this.renderHightLinePoint(targetItem, xPosition, yPosition);
      }
      //move crossHair
      this.crossHairObject?.crosshair_x
        ?.attr("display", "block")
        .attr("x1", xPosition)
        .attr("x2", xPosition);
      this.crossHairObject?.crosshair_y
        ?.attr("display", "block")
        .attr("y1", yPosition)
        .attr("y2", yPosition);
    }
  };
  handleMouseOut = () => {
    //hide crossHair
    this.crossHairObject?.crosshair_x?.attr("display", "none");
    this.crossHairObject?.crosshair_y?.attr("display", "none");
    //hide hightline data point
    this.hightLineObject?.remove();
  };
  drawChart() {
    this.renderXAxis();
    this.renderChartAxisLine();
    this.renderChartCrossHair();

    this.renderData();
  }
  componentDidMount() {
    this.chartObject = d3.select(this.chartRef?.current);
    this.axisRefObject = d3.select(this.axisRef?.current);
    this.drawChart();
  }
  caculatorChartHeight = () => {
    const { data } = this.props;
    let totalHeight = 0;
    for (let phaseName in data) {
      const phase = data[phaseName];

      totalHeight += LINE_HEIGHT * Object.keys(phase).length;

      totalHeight += BLOCK_SPACE;
    }
    return totalHeight;
  };
  componentDidUpdate() {
    this.chartObject.selectAll("*").remove();
    this.axisRefObject.selectAll("*").remove();
    this.drawChart();
  }
  render() {
    return (
      <div className="flex flex-col w-full h-full">
        <div className="flex gap-4 mb-4">
          {Object.keys(LEGEND_LABEL).map((item) => (
            <Badge
              key={item}
              className="border-square mr-4"
              color={LEGEND_COLOR[item]}
              text={LEGEND_LABEL[item]}
            />
          ))}
        </div>
        <div className="flex-1-1-auto overflow-auto">
          <svg
            className=""
            style={{ height: this.caculatorChartHeight() }}
            ref={this.chartRef}
          />
        </div>
        <svg
          className="chart-detail"
          style={{
            flex: "0 0 40px",
          }}
          ref={this.axisRef}
        />
      </div>
    );
  }
}

export default withTranslation()(SummaryChart);
