import { inject, observer } from "mobx-react";
import React, { createRef } from "react";
import { withTranslation } from "react-i18next";
import { HISTORIC_TIMSPACE_MODE } from "utils/constants";
import { Spin } from "antd";
import ActualTimespaceChart from "./ActualTimespaceChart/index";
import HeaderTimeSpaceInfo from "./HeaderTimeSpaceInfo";
import PrgrammedTimespaceChart from "./PrgrammedTimespaceChart";
import "./style.scss";
import { DATE_FORMAT } from "utils/constants/index";
import moment from "moment";
@inject("rootStore")
@observer
class HistoricTimeSpace extends React.Component {
  constructor(props) {
    super(props);
    this.offsetHistoricTimeSpace = createRef();
    this.headerTimeSpace = createRef();
    this.offsetHistoricTimeSpace = createRef();
    this.state = {
      chartHeight: 500,
      showUpstreamBand: true,
      showDownstreamBand: true,
      showGreenwave: true,

      showActivation: true,
      showTimePhase: true,
      showAogPhase: false,
      chartType: HISTORIC_TIMSPACE_MODE.ACTUAL,
    };
  }
  handleChangeMode = (mode) => {
    this.setState({
      chartType: mode,
    });
  };
  async componentDidMount() {
    await Promise.resolve();
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }
  handleResize = () => {
    if (this.offsetHistoricTimeSpace.current && this.headerTimeSpace.current) {
      this.setState({
        chartHeight:
          this.offsetHistoricTimeSpace.current?.offsetHeight -
          this.headerTimeSpace.current?.offsetHeight -
          8,
      });
    }
  };

  handleChangeViewFilter = (filterCheckObject) => {
    this.setState({
      ...this.state,
      ...filterCheckObject,
    });
  };
  render() {
    const {
      rootStore: {
        optimizerStore: {
          historicTimespaceStore: {
            tranformDataHistoricTimeSpace,
            getDataActual,
            loading,
            loadingActual,
          },
        },
      },
    } = this.props;

    return (
      <div
        className="bg-wrap-background h-full wrap-historic-detail"
        ref={this.offsetHistoricTimeSpace}>
        <div className="" ref={this.headerTimeSpace}>
          <HeaderTimeSpaceInfo
            handleChangeViewFilter={this.handleChangeViewFilter}
            data={tranformDataHistoricTimeSpace?.general_data}
            hasProgrammed={!!tranformDataHistoricTimeSpace?.programmed}
            handleChangeMode={this.handleChangeMode}
            {...this.state}
          />
        </div>
        {loading ? (
          <div className="w-full  flex items-center justify-center">
            <Spin spinning />
          </div>
        ) : this.state.chartType === HISTORIC_TIMSPACE_MODE.ACTUAL ? (
          <ActualTimespaceChart
            {...this.state}
            loadingActual={loadingActual}
            start_time={moment(
              tranformDataHistoricTimeSpace.general_data.start_time,
              DATE_FORMAT.time_only_full
            ).toDate()}
            end_time={moment(
              tranformDataHistoricTimeSpace.general_data.end_time,
              DATE_FORMAT.time_only_full
            ).toDate()}
            from_time={moment(
              tranformDataHistoricTimeSpace.actual.from_time,
              DATE_FORMAT.time_only_full
            ).toDate()}
            to_time={moment(
              tranformDataHistoricTimeSpace.actual.to_time,
              DATE_FORMAT.time_only
            ).toDate()}
            getDataActual={getDataActual}
            data={tranformDataHistoricTimeSpace.actual}
          />
        ) : (
          <PrgrammedTimespaceChart
            {...this.state}
            start_time={moment(
              tranformDataHistoricTimeSpace.programmed.from_time,
              DATE_FORMAT.time_only_full
            ).toDate()}
            end_time={moment(
              tranformDataHistoricTimeSpace.programmed.to_time,
              DATE_FORMAT.time_only
            ).toDate()}
            data={tranformDataHistoricTimeSpace.programmed}
          />
        )}
      </div>
    );
  }
}
export default withTranslation()(HistoricTimeSpace);
