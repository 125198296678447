import React, { Component } from "react";
import {
  DATE_FORMAT,
  TRAFFIC_PROFILE_CHART_MODE,
  LEVEL_COLOR,
} from "utils/constants";
import moment from "moment";
import { withTranslation } from "react-i18next";
import {
  PDFViewer,
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import * as htmlToImage from "html-to-image";
import SiemenFont from "../../../../fonts/Siemens_Sans_Roman.ttf";
import SiemenBold from "../../../../fonts/Siemens_Sans_Bold.ttf";
import SiemenItalicBold from "../../../../fonts/Siemens_Sans_Bold_Italic.ttf";
import { Spin } from "antd";

const MAX_LEGEND_LENGTH = 20;
Font.register({
  family: "siemenfont",
  src: SiemenFont,
  fonts: [
    { src: SiemenFont }, // font-style: normal, font-weight: normal
    { src: SiemenBold, fontWeight: "bold", fontWeight: 700 },
    { src: SiemenItalicBold, fontStyle: "italic", fontWeight: 900 },
  ],
});
const styles = StyleSheet.create({
  cover: {
    fontFamily: "siemenfont",
    backgroundColor: "#becdd7",
    height: "100%",
    width: "100%",
  },
  logo: {
    width: "150px",
    margin: "30px",
    marginLeft: "70%",
  },
  title: {
    backgroundColor: "#009999",
    width: "350px",
    height: "256px",
    padding: "16px",
    marginTop: "60%",
    marginLeft: "30px",
    color: "#fff",
  },
  line: {
    width: "280px",
    marginLeft: "10px",
    marginTop: "10px",
    height: "2px",
    backgroundColor: "#fff",
  },
  site: {
    backgroundColor: "#fff",
    width: "350px",
    height: "30x",
    marginTop: "20px",
    padding: "0 16px",
    marginLeft: "30px",
  },
  siteText: {
    color: "black",
    fontWeight: "900",
    fontSize: "10px",
    textAlign: "justify",
    marginLeft: "10px",
    marginTop: "10px",
    fontWeight: "bold",
  },
  companyText: {
    fontWeight: "bold",
    marginLeft: "10px",
    marginTop: "10px",
    fontSize: "22px",
  },
  titleText: {
    backgroundColor: "#fff",
    marginTop: "10px",
    width: "auto",
    width: "160px",
    color: "#000",
    marginLeft: "8px",
    padding: "4px",
    fontWeight: "bold",
    fontSize: "16px",
  },
  titleTypeText: {
    marginLeft: "10px",
    marginTop: "10px",
    fontWeight: "900",
    fontSize: "12px",
  },
  desText: {
    marginLeft: "10px",
    marginTop: "6px",
    fontSize: "12px",
  },
  contentDetail: {
    fontFamily: "siemenfont",
    height: "100%",
    padding: "36px 16px 32px 16px",
  },
  contentDetailCenter: {
    fontFamily: "siemenfont",
    padding: "16px",
    paddingTop: "32px",
    paddingBottom: "64px",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  headerPane: {
    position: "absolute",
    top: "0",
    right: "16px",
  },
  chart: {
    position: "relative",
    padding: "16px",
    top: "0",
  },
  pageTitle: {
    position: "absolute",
    top: "32px",
    display: "flex",
    justifyContent: "center",
    fontFamily: "siemenfont",
    fontSize: "12px",
    fontWeight: "bold",
  },
  header: {
    fontFamily: "siemenfont",
    fontSize: "8px",
    marginTop: "10px",
    fontWeight: "bold",
  },
  headerInfo: {
    fontFamily: "siemenfont",
    color: "#becdd7",
    marginTop: "10px",
    fontSize: "8px",
  },
  footerPane: {
    position: "absolute",
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    left: "0px",
    padding: "0 16px",
    bottom: "16px",
  },
  footer: {
    fontFamily: "siemenfont",
    textAlign: "left",
    fontSize: "8px",
    fontWeight: "bold",
  },
  footerPage: {
    fontFamily: "siemenfont",
    fontSize: "8px",
  },
  row: {
    fontFamily: "siemenfont",
    flexGrow: 1,
    flexDirection: "row",
    height: "10px",
  },
  flex: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  defaultText: {
    fontFamily: "siemenfont",
    fontSize: "10px",
    fontWeight: "bold",
    color: "#909090",
  },
  legendText: {
    marginRight: "12px",
    marginLeft: "4px",
  },
});

class PrintPreview extends Component {
  page = 0;
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      dataDetail: [],
      dataMinMax: [],
    };
    this.page = 0;
    this.minmaxChartList = React.createRef();
    this.detailChartList = React.createRef();
  }
  renderCoverPage = (coverData) => {
    const { t } = this.props;
    return (
      <View style={styles.cover}>
        <Image
          style={styles.logo}
          src={process.env.PUBLIC_URL + "/img/logo.png"}
        />
        <View style={styles.title}>
          <Text style={styles.companyText}>YUTRAFFIC Insights</Text>
          <Text style={styles.titleText}>
            {t("optimization.traffic_profile")}
          </Text>
          <View style={styles.line}></View>
          <Text style={styles.titleTypeText}>
            {t("optimization.bin_size")}: &nbsp;
            {t("optimization.bin_size." + coverData?.bin_size)} |{" "}
            {t("optimization.num_of_profiles")}: &nbsp;
            {coverData?.num_of_profiles}
          </Text>

          <Text style={styles.desText}>
            {t("report_result.intersection")}:&nbsp;{" "}
            {coverData?.intersection_name}
          </Text>
          <Text style={styles.desText}>
            {t("report_result.time_range")}: &nbsp;
            {coverData?.start_time}
            {" - "}
            {coverData?.end_time}
          </Text>
          <Text style={styles.desText}>
            {t("report_result.date_range")}: &nbsp;
            {moment(coverData?.start_date, DATE_FORMAT.day_only_backend).format(
              DATE_FORMAT.day_only
            )}
            {" - "}
            {moment(coverData?.end_date, DATE_FORMAT.day_only_backend).format(
              DATE_FORMAT.day_only
            )}
          </Text>

          <Text style={styles.desText}>
            {t("optimization.cluster_types")}:&nbsp;
            {t(coverData?.cluster_type)}
          </Text>
          <Text style={styles.desText}>
            {t("report_result.created_at")}: &nbsp;
            {moment(coverData?.created_at).format(DATE_FORMAT.full_with_tz)}
          </Text>
        </View>
        <View style={styles.site}>
          <Text style={styles.siteText}>{t("print.site")}</Text>
        </View>
      </View>
    );
  };

  async componentDidMount() {
    const childrenDetailChart = this.detailChartList.current?.childNodes;
    const childrenMinmaxChart = this.minmaxChartList.current?.childNodes;
    let dataDetail = [];
    let dataMinMax = [];
    for (let i = 0; i < childrenDetailChart?.length; i++) {
      const dataUrl = await htmlToImage.toPng(childrenDetailChart[i]);
      dataDetail.push(dataUrl);
    }
    for (let i = 0; i < childrenMinmaxChart?.length; i++) {
      const dataUrl = await htmlToImage.toPng(childrenMinmaxChart[i]);
      dataMinMax.push(dataUrl);
    }

    this.setState({
      dataMinMax: dataMinMax,
      dataDetail: dataDetail,
    });
  }
  getProfileLegend = () => {
    const { printInfo, t } = this.props;
    let arrayComponent = [];
    for (let id = 1; id <= printInfo.num_of_profiles; id++) {
      arrayComponent.push(
        <View key={id} style={styles.flex}>
          <View
            style={StyleSheet.create({
              width: "12px",
              height: "12px",
              borderRadius: "1px",
              backgroundColor: LEVEL_COLOR[id],
            })}
          ></View>
          <View>
            <Text
              style={{
                ...styles.defaultText,
                ...styles.legendText,
              }}
            >
              {t("optimization.profile") + " " + id}
            </Text>
          </View>
        </View>
      );
    }
    return arrayComponent;
  };
  renderChartPerPage(page, id, chartMode) {
    const { getLegend, t, reporter } = this.props;
    const legends = getLegend(id, chartMode);
    const splitLegend = legends.slice(0, MAX_LEGEND_LENGTH);
    const nextPageLegend = legends.slice(MAX_LEGEND_LENGTH);
    let arrayComponent = [];

    arrayComponent.push(
      <Page
        key={id}
        style={{ position: "relative", height: "100%" }}
        size="A4"
        orientation="landscape"
      >
        <View
          style={{
            ...styles.row,
            ...styles.headerPane,
          }}
        >
          <Text style={styles.header}>{t("print.header")} | </Text>
          <Text style={styles.headerInfo}>
            {t("print.header-reporter")} : {reporter} |{" "}
            {moment().format(DATE_FORMAT.full)}
          </Text>
        </View>
        <View style={styles.contentDetailCenter}>
          <View
            style={StyleSheet.create({
              fontFamily: "siemenfont",
              fontSize: "16px",
              paddingBottom: "16px",
              fontWeight: "bold",
              color: "#000",
            })}
          >
            <Text>{t(chartMode)}</Text>
          </View>

          <View
            style={StyleSheet.create({
              display: "flex",
              flexDirection: "row",
              width: "100%",
              paddingLeft: "20px",
              paddingBottom: "8px",
              justifyContent: "left",
            })}
          >
            {this.getProfileLegend()}
          </View>
          <View>
            <Image src={page} />
          </View>
          <View
            style={StyleSheet.create({
              display: "flex",
              width: "100%",
              paddingLeft: "24px",
              justifyContent: "left",
              flexDirection: "row",
              flexWrap: "wrap",
            })}
          >
            {splitLegend.map((legend) => (
              <View
                key={legend.key}
                style={{
                  ...styles.flex,
                  ...StyleSheet.create({
                    width: "17%",
                    padding: "4px 0",
                  }),
                }}
              >
                <View
                  style={StyleSheet.create({
                    width: "12px",
                    height: "12px",
                    borderRadius: "1px",
                    backgroundColor: legend.color,
                  })}
                ></View>
                <View>
                  <Text
                    style={{
                      ...styles.defaultText,
                      ...styles.legendText,
                    }}
                  >
                    {legend.label}
                  </Text>
                </View>
              </View>
            ))}
          </View>
        </View>

        <View
          style={{
            ...styles.row,
            ...styles.footerPane,
          }}
        >
          <Text style={styles.footer}>
            {"© " + moment().year() + "  " + t("print.footer")}
          </Text>
          <Text style={styles.footerPage}>
            {t("print.footer-page")} {++this.page}
          </Text>
        </View>
      </Page>
    );
    if (nextPageLegend?.length)
      arrayComponent.push(
        <Page
          key={`${id}${this.page}`}
          style={{ position: "relative", height: "100%" }}
          size="A4"
          orientation="landscape"
        >
          <View
            style={{
              ...styles.row,
              ...styles.headerPane,
            }}
          >
            <Text style={styles.header}>{t("print.header")} | </Text>
            <Text style={styles.headerInfo}>
              {t("print.header-reporter")} : {reporter} |{" "}
              {moment().format(DATE_FORMAT.full)}
            </Text>
          </View>
          <View
            style={StyleSheet.create({
              display: "flex",
              width: "100%",
              paddingLeft: "40px",
              paddingTop: "32px",
              justifyContent: "left",
              flexDirection: "row",
              flexWrap: "wrap",
            })}
          >
            {nextPageLegend.map((legend) => (
              <View
                key={legend.key}
                style={{
                  ...styles.flex,
                  ...StyleSheet.create({
                    width: "17%",
                    padding: "4px 0",
                  }),
                }}
              >
                <View
                  style={StyleSheet.create({
                    width: "12px",
                    height: "12px",
                    borderRadius: "1px",
                    backgroundColor: legend.color,
                  })}
                ></View>
                <View>
                  <Text
                    style={{
                      ...styles.defaultText,
                      ...styles.legendText,
                    }}
                  >
                    {legend.label}
                  </Text>
                </View>
              </View>
            ))}
          </View>
          <View
            style={{
              ...styles.row,
              ...styles.footerPane,
            }}
          >
            <Text style={styles.footer}>
              {"© " + moment().year() + "  " + t("print.footer")}
            </Text>
            <Text style={styles.footerPage}>
              {t("print.footer-page")} {++this.page}
            </Text>
          </View>
        </Page>
      );
    return arrayComponent;
  }
  renderPDFFile = () => {
    const { printInfo } = this.props;

    return (
      <PDFViewer className="chart-print-preview">
        <Document>
          <Page size="A4" orientation="portrait">
            {this.renderCoverPage(printInfo)}
          </Page>
          {this.state.dataMinMax.map((page, id) =>
            this.renderChartPerPage(
              page,
              id,
              TRAFFIC_PROFILE_CHART_MODE.MAX_MIN
            )
          )}
          {this.state.dataDetail.map((page, id) =>
            this.renderChartPerPage(page, id, TRAFFIC_PROFILE_CHART_MODE.DETAIL)
          )}
        </Document>
      </PDFViewer>
    );
  };
  render() {
    const { data, Chart } = this.props;

    return this.state.dataMinMax.length ? (
      this.renderPDFFile()
    ) : (
      <div className="loading-screen">
        <Spin size="large" className={"centralize"}>
          <div ref={this.minmaxChartList} className="chart-data">
            {data.map((e, id) => {
              return Chart({
                style: {
                  height: "60vh",
                },
                index: id,
                chartModePrint: TRAFFIC_PROFILE_CHART_MODE.MAX_MIN,
                isPrintMode: true,
              });
            })}
          </div>
          <div ref={this.detailChartList} className="chart-data">
            {data.map((e, id) => {
              return Chart({
                style: {
                  height: "60vh",
                },
                index: id,
                chartModePrint: TRAFFIC_PROFILE_CHART_MODE.DETAIL,
                isPrintMode: true,
              });
            })}
          </div>
        </Spin>
      </div>
    );
  }
}

export default withTranslation()(PrintPreview);
