import React, { Component } from "react";
import { DATE_FORMAT } from "utils/constants";
import { Spin } from "antd";
import Charts from "components/ChartItem";
import moment from "moment";
import { withTranslation } from "react-i18next";
import {
  PDFViewer,
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import "./style.scss";
import * as htmlToImage from "html-to-image";

import SiemenFont from "fonts/Siemens_Sans_Roman.ttf";
import SiemenBold from "fonts/Siemens_Sans_Bold.ttf";
import SiemenItalicBold from "fonts/Siemens_Sans_Bold_Italic.ttf";
import gapdataImage from "./gapdata.png";

Font.register({
  family: "siemenfont",
  src: SiemenFont,
  fonts: [
    { src: SiemenFont }, // font-style: normal, font-weight: normal
    { src: SiemenBold, fontWeight: "bold", fontWeight: 700 },
    { src: SiemenItalicBold, fontStyle: "italic", fontWeight: 900 },
  ],
});
const styles = StyleSheet.create({
  chart: {
    fontFamily: "siemenfont",
    paddingVertical: 30,
  },
  cover: {
    fontFamily: "siemenfont",
    backgroundColor: "#becdd7",
    height: "100%",
    width: "100%",
  },
  logo: {
    width: "150px",
    margin: "30px",
    marginLeft: "70%",
  },
  title: {
    backgroundColor: "#009999",
    width: "350px",
    height: "220px",
    padding: "16px",
    marginTop: "60%",
    marginLeft: "30px",
    color: "#fff",
  },
  line: {
    width: "280px",
    marginLeft: "10px",
    marginTop: "10px",
    height: "2px",
    backgroundColor: "#fff",
  },
  site: {
    backgroundColor: "#fff",
    width: "350px",
    height: "30x",
    marginTop: "20px",
    padding: "0 16px",
    marginLeft: "30px",
  },
  siteText: {
    color: "black",
    fontWeight: "900",
    fontSize: "10px",
    textAlign: "justify",
    marginLeft: "10px",
    marginTop: "10px",
    fontWeight: "bold",
  },
  titleText: {
    backgroundColor: "#fff",
    marginTop: "10px",
    width: "auto",
    width: "86px",
    color: "#000",
    marginLeft: "8px",
    padding: "4px",
    fontWeight: "bold",
    fontSize: "16px",
  },
  titleTypeText: {
    marginLeft: "10px",
    marginTop: "10px",
    fontWeight: "900",
    fontSize: "12px",
  },
  descriptionChart: {
    marginLeft: "10px",
    marginTop: "10px",
    fontStyle: "italic",
    fontSize: "10px",
  },
  desText: {
    marginLeft: "10px",
    marginTop: "10px",
    fontSize: "12px",
  },
  header: {
    fontFamily: "siemenfont",
    fontSize: "8px",
    marginLeft: "70%",
    marginTop: "20px",
    fontWeight: "bold",
  },
  headerInfo: {
    fontFamily: "siemenfont",
    color: "#becdd7",
    marginTop: "20px",
    fontSize: "8px",
  },
  footer: {
    fontFamily: "siemenfont",
    textAlign: "left",
    fontSize: "8px",
    fontWeight: "bold",
  },
  footerPage: {
    fontFamily: "siemenfont",
    fontSize: "8px",
  },
  row: {
    fontFamily: "siemenfont",
    flexGrow: 1,
    flexDirection: "row",
    height: "10px",
    width: "100%",
  },
  rowFooter: {
    position: "absolute",
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    left: "0px",
    padding: "0 16px",
    bottom: "16px",
  },
  companyText: {
    fontWeight: "bold",
    marginLeft: "10px",
    marginTop: "10px",
    fontSize: "22px",
  },
  flex: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  justifyRight: {
    position: "absolute",
    top: "64px",
    padding: "0 16px",
    justifyContent: "flex-end",
  },
  wFull: {
    width: "100vw",
  },
  defaultText: {
    fontFamily: "siemenfont",
    fontSize: "10px",
    fontWeight: "bold",
    color: "#909090",
  },
  legendText: {
    marginRight: "12px",
    marginLeft: "4px",
  },
});

class PrintPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pageSize: "A4",
    };
  }
  async componentDidMount() {
    let allChartItem = document.getElementsByClassName("chart-item");
    let data = [];
    for (let i = 0; i < allChartItem.length; i++) {
      const dataUrl = await htmlToImage.toPng(allChartItem[i]);
      data.push({ pharse: i + 1, data: dataUrl });
    }
    this.setState({ data });
  }

  renderPDFFile = () => {
    const { printInfo, t, reporter, printFilter } = this.props;

    return (
      <PDFViewer className="chart-print-preview">
        <Document>
          <Page size="A4" orientation="portrait">
            <View style={styles.cover}>
              <Image
                style={styles.logo}
                src={process.env.PUBLIC_URL + "/img/logo.png"}
              />
              <View style={styles.title}>
                <Text style={styles.companyText}>YUTRAFFIC Insights</Text>
                <Text style={styles.titleText}> {t("print.title")}</Text>
                <View style={styles.line}></View>
                <Text style={styles.titleTypeText}>
                  {t("print.analysis_name")}: &nbsp;
                  {t("analysis." + printInfo?.chartType) + " "}
                </Text>
                <Text style={styles.desText}>
                  {t("print.name")}: {printInfo.title}
                </Text>

                <Text style={styles.desText}>
                  {t("print.time")}:
                  {moment(printFilter.from_time).format(DATE_FORMAT.time_only)}{" "}
                  - {moment(printFilter.to_time).format(DATE_FORMAT.time_only)}
                </Text>

                <Text style={styles.desText}>
                  {t("print.date")}:
                  {moment(printInfo.from_time).format(DATE_FORMAT.day_only)}
                  {printInfo.isCompareMode &&
                    " - " +
                      moment(printInfo.comapre_from_time).format(
                        DATE_FORMAT.day_only
                      )}
                </Text>
              </View>
              <View style={styles.site}>
                <Text style={styles.siteText}>{t("print.site")}</Text>
              </View>
            </View>
          </Page>
          {this.state.data.map((item) => {
            return (
              <Page
                key={item.pharse}
                size="A4"
                orientation="landscape"
                wrap={true}>
                <View style={styles.row}>
                  <Text style={styles.header}>{t("print.header")} | </Text>
                  <Text style={styles.headerInfo}>
                    {t("print.header-reporter")} : {reporter} |{" "}
                    {moment().format(DATE_FORMAT.full)}
                  </Text>
                </View>
                <View
                  style={{
                    ...styles.flex,
                    ...styles.wFull,
                    ...styles.justifyRight,
                  }}>
                  <View
                    style={StyleSheet.create({
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      paddingRight: "16px",
                    })}>
                    {printInfo?.totalLegend.map(
                      (legend) =>
                        !printFilter.hiddenLegend.includes(legend.key) &&
                        !legend.hidden && (
                          <View key={legend.key} style={styles.flex}>
                            <View
                              style={StyleSheet.create({
                                width: "12px",
                                height: "12px",
                                borderRadius: "1px",
                                backgroundColor: legend.color,
                              })}></View>
                            <View>
                              <Text
                                style={{
                                  ...styles.defaultText,
                                  ...styles.legendText,
                                }}>
                                {legend.label}
                              </Text>
                            </View>
                          </View>
                        )
                    )}
                    {printInfo.isGapData && (
                      <View style={styles.flex}>
                        <View
                          style={StyleSheet.create({
                            width: "32px",
                            height: "14px",
                          })}>
                          <Image src={gapdataImage} />
                        </View>
                        <Text
                          style={{
                            ...styles.defaultText,
                            ...styles.legendText,
                          }}>
                          {t("analysis.gap-data")}
                        </Text>
                      </View>
                    )}
                  </View>
                </View>
                <View style={styles.chart}>
                  <Image src={item.data} />
                </View>
                <View style={{ ...styles.row, ...styles.rowFooter }}>
                  <Text style={styles.footer}>
                    {"© " + moment().year() + t("print.footer")}
                  </Text>
                  <Text style={styles.footerPage}>
                    {t("print.footer-page")} {item.pharse}
                  </Text>
                </View>
              </Page>
            );
          })}
        </Document>
      </PDFViewer>
    );
  };

  render() {
    const { totalPage, printFilter } = this.props;
    let pages = totalPage.filter(
      (page) => !printFilter.hiddenPhase.includes(page)
    );
    return this.state.data.length !== 0 ? (
      this.renderPDFFile()
    ) : (
      <div className={"loading-screen"}>
        <Spin
          style={{ fontFamily: "siemenfont" }}
          size="large"
          className={"centralize"}>
          <div className="chart-data">
            {pages.map((item, index) => {
              return (
                <div
                  key={"chart_" + index}
                  className="chart-item chart-item-print"
                  style={{ height: "720px", width: "1280px" }}>
                  <Charts
                    style={{ fontFamily: SiemenFont }}
                    index={index}
                    isPrintMode={true}
                  />
                </div>
              );
            })}
          </div>
        </Spin>
      </div>
    );
  }
}

export default withTranslation()(PrintPreview);
