import BaseApi from "services/BaseApi";
import Api from "services/Api";
import queryString from "query-string";

class ReportResultService extends BaseApi {
  constructor(parentStore) {
    super("summary-report/results", parentStore);
    this.dataKey = "summary_reports";
  }
  /**
   * getRuleDetail
   * this function return detail of rule
   * @param   {int} id  rule id
   * @param   {function} cb  callback function
   * @param   {function} fb  falseback function
   * @return  {null} -  call the callback function
   */
  getDetail = (id, cb, fb) => {
    const api = new Api(this.moduleName + "/" + id, this.parentStore);
    api.getRequest(
      {},
      (response) => {
        cb && cb(response?.data);
      },
      () => {
        fb && fb();
      }
    );
  };
  delete = (params, cb, fb) => {
    const api = new Api(
      this.moduleName +
        "?" +
        queryString.stringify(params, { arrayFormat: "brackets" }),
      this.parentStore
    );
    api.deleteRequest(
      () => {
        cb && cb();
      },
      () => {
        fb && fb();
      }
    );
  };
}

export default ReportResultService;
