import i18n from "../i18n";
import helper from "../utils/helper";
import Api from "./Api";
/**
 * Base class for API services.
 *
 * @class
 */
class BaseApi {
  constructor(moduleName, parentStore) {
    this.parentStore = parentStore;
    this.moduleName = moduleName;
    this.dataKey = moduleName;
    this.keyField = "id";
  }

  /**
   * fetchDataWithParams
   * this function get all data from API with paging, searching and filtering params
   * @param   {Object} params  searching, filtering, paging object
   * @param   {Function} cb  callback function after success
   * @param   {Function} fb  failback function after fail
   * @return  {null} -  call the callback function
   */
  fetchDataWithParams = (params, cb, fb) => {
    const api = new Api(this.moduleName, this.parentStore);
    let paramData = this.addPagingToFilterData(params);
    api.getRequest(
      paramData,
      (response) => {
        let data = response?.data;
        if (
          data &&
          data.total_count != undefined &&
          data[this.dataKey] != undefined &&
          Array.isArray(data[this.dataKey])
        ) {
          cb && cb(data[this.dataKey], data.total_count, data);
        } else {
          helper.showNotification(
            "error",
            i18n.t("response_wrong_format"),
            i18n.t("response_wrong_format_message")
          );
        }
      },
      (erros) => {
        fb && fb(erros);
      }
    );
  };

  /**
   * fetchDataWithParams
   * this function get all data from API with paging, searching and filtering params
   * @param   {Object} params  searching, filtering, paging object
   * @param   {Function} cb  callback function after success
   * @param   {Function} fb  failback function after fail
   * @return  {null} -  call the callback function
   */
  getAll = (cb, fb) => {
    const api = new Api(this.moduleName + "/all", this.parentStore);
    api.getRequest(
      {},
      (response) => {
        cb && cb(response?.data);
      },
      (erros) => {
        fb && fb(erros);
      }
    );
  };

  /**
   * addPagingToFilterData
   * this function will restructure params object with paging
   * this function will remove item with null, empty value
   * @param   {Object} params  searching, filtering, paging object
   * @return  {Object} -  the new params object with paging structure
   */
  addPagingToFilterData(params) {
    let filterData = {
      ...params,
      page: params.pagination ? params?.pagination?.current - 1 : "null",
      size: params?.pagination?.pageSize,
      pagination: null,
    };
    Object.keys(filterData).forEach(
      (key) =>
        (filterData[key] == "" || filterData[key] == "null") &&
        delete filterData[key]
    );
    return filterData;
  }

  /**
   * fetchDataWithoutParams
   * this function get all data from API with paging, searching and filtering params
   * @param   {String} url  URL of the request
   * @param   {String} dataKey  the data key from response structure
   * @param   {Function} cb  callback function after success
   * @param   {Function} fb  failback function after fail
   * @return  {null} -  call the callback function
   */
  fetchDataWithoutParams = (cb, fb) => {
    const api = new Api(this.moduleName + "/all", this.parentStore);
    api.getRequest(
      {},
      (response) => {
        let data = response?.data;
        if (data != undefined && Array.isArray(data)) {
          cb && cb(data);
        } else {
          helper.showNotification(
            "error",
            i18n.t("response_wrong_format"),
            i18n.t("response_wrong_format_message")
          );
        }
      },
      (erros) => {
        fb && fb(erros);
      }
    );
  };

  /**
   * fetchItemById
   * this function get data by ID
   * @param   {Interger/String} item_id  Id of the data
   * @param   {Function} cb  callback function after success
   * @param   {Function} fb  failback function after fail
   * @return  {null} -  call the callback function
   */
  fetchItemById = (item_id, cb, fb) => {
    const api = new Api(this.moduleName + "/" + item_id, this.parentStore);
    api.getRequest(
      {},
      (response) => {
        let data = response?.data;
        if (response?.data) {
          cb && cb(data);
        } else {
          helper.showNotification(
            "error",
            i18n.t("response_wrong_format"),
            i18n.t("response_wrong_format_message")
          );
        }
      },
      (erros) => {
        fb && fb(erros);
      }
    );
  };

  /**
   * deleteItemById
   * this function delete data by ID
   * @param   {Interger/String} item_id  Id of the data
   * @param   {Function} cb  callback function after success
   * @param   {Function} fb  failback function after fail
   * @return  {null} -  call the callback function
   */
  deleteItemById = (item_id, cb, fb) => {
    const api = new Api(this.moduleName + "/" + item_id, this.parentStore);
    api.deleteRequest(
      () => {
        cb && cb();
      },
      (erros) => {
        fb && fb(erros);
      }
    );
  };

  /**
   * saveData - use for insert and update data
   * this function save data to DB
   * @param   {Object} data  the body data to push to server
   * @param   {Function} cb  callback function after success
   * @param   {Function} fb  failback function after fail
   * @return  {null} -  call the callback function
   */
  saveData = (data, cb, fb) => {
    let api;
    if (data[this.keyField] && data[this.keyField] != 0) {
      api = new Api(
        this.moduleName + "/" + data[this.keyField],
        this.parentStore
      );
      api.putRequest(
        data,
        () => {
          cb && cb();
        },
        (erros) => {
          fb && fb(erros);
        }
      );
    } else {
      api = new Api(this.moduleName, this.parentStore);
      api.postRequest(
        data,
        (response) => {
          if (response?.data) {
            cb && cb(data);
          } else {
            helper.showNotification(
              "error",
              i18n.t("response_wrong_format"),
              i18n.t("response_wrong_format_message")
            );
          }
        },
        (erros) => {
          fb && fb(erros);
        }
      );
    }
  };

  /**
   * updateData - use for update the part of data
   * @param   {Object} data  the body data to push to server
   * @param   {Function} cb  callback function after success
   * @param   {Function} fb  failback function after fail
   * @return  {null} -  call the callback function
   */
  updateData = (data, cb, fb) => {
    const api = new Api(this.moduleName + "/" + item_id, this.parentStore);
    api.patchRequest(
      data,
      () => {
        cb && cb();
      },
      (erros) => {
        fb && fb(erros);
      }
    );
  };
}

export default BaseApi;
