import helper from "../utils/helper";

const getCookie = (cname) => {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

const setCookie = (cname, cvalue, exdays) => {
  var d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
};

const deleteCookie = (name) => {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
};

const setLocalStorage = (name, value) => {
  if (typeof localStorage !== "undefined") {
    localStorage.setItem(name, JSON.stringify(value));
  } else {
    helper.showNotification(
      "error",
      "ERROR",
      "The Local Storage is disabled.",
      () => {}
    );
  }
};

const clearLocalStorage = () => {
  localStorage.clear();
};

const getLocalStorage = (name, defaultValue) => {
  if (typeof localStorage !== "undefined") {
    if (localStorage.getItem(name)) {
      try {
        return JSON.parse(localStorage.getItem(name));
      } catch (e) {
        if (defaultValue) return defaultValue;
        return localStorage.getItem(name);
      }
    }
  } else {
    helper.showNotification(
      "error",
      "ERROR",
      "The Local Storage is disabled.",
      () => {}
    );
    return defaultValue;
  }
};

const getSessionStorage = (name, defaultValue) => {
  if (typeof sessionStorage !== "undefined") {
    return sessionStorage.getItem(name)
      ? sessionStorage.getItem(name)
      : defaultValue;
  } else {
    helper.showNotification(
      "error",
      "ERROR",
      "The session Storage is disabled.",
      () => {}
    );
    return defaultValue;
  }
};
const setSessionStorage = (name, value) => {
  if (typeof sessionStorage !== "undefined") {
    sessionStorage.setItem(name, value);
  } else {
    helper.showNotification(
      "error",
      "ERROR",
      "The session Storage is disabled.",
      () => {}
    );
  }
};

export default {
  setCookie,
  getCookie,
  deleteCookie,
  setLocalStorage,
  getLocalStorage,
  getSessionStorage,
  setSessionStorage,
  clearLocalStorage,
};
