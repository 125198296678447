import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import "./style.scss";
import {
  PageHeader,
} from "antd";
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router";
import CustomAnalysisResult from "./CustomAnalysisResult";
import CustomAnalysisFilter from "./CustomAnalysisFilter";


@inject("rootStore")
@observer

class CustomAnalysis extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      idSelectedItem: null,
      isEdit: false,
      tableData: []
    };
  }

  closeModal = () => {
    const {
      rootStore: { customAnalysisStore: {
        resetForm
      }},
      form: { resetFields }
    } = this.props;
    this.setState({
      openModal: false,
      isEdit: false,
      tableData: []
    })
    resetFields();
    resetForm();
  }

  handleAdd = (value) => {
    if (!this.state.allTags?.find((e) => e === value))
      this.setState({
        allTags: [...this.state.allTags, value],
        inputVisible: false,
        inputValue: "",
      });
    else
      this.setState({
        inputVisible: false,
      });
  };

  handleEditCustomAnalysis = () => {
    const {
      rootStore: { customAnalysisStore: {
        listAllEvent,
        selectedAnalysis,
        setDownloadFormData,
      },
      }

    } = this.props;
    if (selectedAnalysis) {
      const initTable = selectedAnalysis.events.map(filter => {
        for (let key of Object.keys(listAllEvent)) {
          for (let i = 0; i < listAllEvent[key].event_list.length; i += 1) {
            if (listAllEvent[key].event_list[i].event_code === filter.event_code) {
              return { ...listAllEvent[key].event_list[i], params: filter.params, event_name: listAllEvent[key].event_list[i].event_label };
            }
          }
        }
      })
      setDownloadFormData({
        formDownload: initTable,
      });
      this.setState({
        tableData: initTable,
        isEdit: true,
        openModal: true
      })
    }


  }
  componentDidMount() {
    const { t } = this.props;
    document.title = t("intersection.page-title");
  }
  render() {
    return (
      <div className="custom-analysis">
        <div className="header-custom-analysis">
          <PageHeader className="site-page-header" title="Custom Analysis" />
          <div className="filter-custom-analysis">
            <CustomAnalysisFilter />
          </div>
        </div>
        <div className="result-custom-analysis w-full" style={{ height: 550 }}>
          <CustomAnalysisResult />
        </div>
      </div>
    );
  }
}

export default withRouter(withTranslation()(CustomAnalysis));
