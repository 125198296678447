import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import "./style.scss";
import { Icon, Spin } from "antd";
import { withTranslation } from "react-i18next";
import HorizontalCollapseTable from "../../../../components/HorizontalCollapseTable";
import { TIMEOUT_RESIZE } from "utils/constants";
const mapInitialState = [
  "0 - None (not enabled)",
  "1 - Inactive & enabled",
  "2 - ",
  "3 - Yel Chg & enabled",
];
const heaederInitial = [
  {
    key: "phase",
    label: "Phase",
  },
  {
    key: "initial_state",
    label: "Initial State",
  },
];
const headerSequenceData = [
  {
    key: "seq",
    label: "Seq",
  },
  {
    key: "row_1",
    label: "R1",
  },
  {
    key: "row_2",
    label: "R2",
  },
  {
    key: "row_3",
    label: "R3",
  },
  {
    key: "row_4",
    label: "R4",
  },
];
@inject("rootStore")
@observer
class RingStructure extends Component {
  /**
   * contructor of component
   * @param   {object} props  props of component
   */
  constructor(props) {
    super(props);
    this.state = {
      resizing: false,
      maxHeight: 300,
    };
    this.desRef = React.createRef();
  }
  componentDidMount() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }
  handleResize = () => {
    this.setState({ resizing: true });
    let tabsHeight = document.getElementsByClassName(
      "intersection-config-tabs"
    )[0]?.offsetHeight;
    let headerStickyHeight =
      document.getElementsByClassName("ant-tabs-top-bar")[0]?.offsetHeight +
      this.desRef.current?.offsetHeight;
    if (this.timeoutResize) clearTimeout(this.timeoutResize);
    this.timeoutResize = setTimeout(() => {
      this.setState({
        resizing: false,
        maxHeight: tabsHeight - headerStickyHeight - 64,
      });
    }, TIMEOUT_RESIZE);
  };

  renderInitialStateTable = () => {
    const {
      rootStore: {
        intersectionConfigStore: { currentConfig },
      },
    } = this.props;
    const ringStructureInfo = currentConfig?.RingStructureInfo;
    let tranformData = ringStructureInfo?.phaseInitialState?.map(
      (row, idRow) => {
        return {
          phase: idRow + 1,
          initial_state: mapInitialState[row],
        };
      }
    );

    return (
      <table className="initial_state_table">
        <tbody>
          {heaederInitial?.map((column, keyRow) => (
            <tr key={keyRow}>
              <th>{column.label}</th>
              {tranformData?.map((cell, keyCell) => (
                <td key={keyCell}>{cell[column.key]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };
  renderListSequenseTable = () => {
    const {
      rootStore: {
        intersectionConfigStore: { currentConfig },
      },
    } = this.props;
    const ringStructureInfo = currentConfig?.RingStructureInfo;
    let mapArray = [0, 1, 2, 3];
    let tranformData = ringStructureInfo?.sequenceData?.map((row, rowId) => {
      return mapArray.map((e) => {
        return {
          seq: rowId + 1,
          row_1: row[0].length > e ? row[0][e] : "",
          row_2: row[1].length > e ? row[1][e] : "",
          row_3: row[2].length > e ? row[2][e] : "",
          row_4: row[3].length > e ? row[3][e] : "",
        };
      });
    });
    let cutArray = [];
    if (tranformData) {
      let total = Math.ceil(tranformData.length / 4);
      for (let i = 0; i < total; i++) {
        cutArray.push(tranformData.slice(i * 4, (i + 1) * 4));
      }
    }
    return this.state.resizing ? (
      <div className="flex justify-space-around w-full">
        <Spin spinning size="large" />{" "}
      </div>
    ) : (
      <div
        className="flex justify-space-around w-full"
        style={{
          overflowX: "auto",
          maxHeight: this.state.maxHeight,
        }}
      >
        {cutArray.map((array, id) => (
          <div key={id}>
            <HorizontalCollapseTable
              tableColumn={headerSequenceData}
              data={array}
              collapseField={headerSequenceData[0]}
              highlightRowKey={headerSequenceData[0].key}
            />
          </div>
        ))}
      </div>
    );
  };
  render() {
    const { t } = this.props;
    return (
      <div>
        <div ref={this.desRef}>
          <p>
            <Icon type="info-circle" style={{ marginRight: 16 }} />
            {t("intersection_config.initial_state_description")}
          </p>
          {this.renderInitialStateTable()}
          <p>
            <Icon
              type="info-circle"
              style={{ marginRight: 16, marginTop: 16 }}
            />
            {t("intersection_config.sequence_config_description")}
          </p>
        </div>
        <div style={{ marginTop: 16 }}>
          <div>{this.renderListSequenseTable()}</div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(RingStructure);
