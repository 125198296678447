import React, { Component } from 'react'
import { Form, Input } from 'antd'
import { inject } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import "./style.scss"
/**
 * Component for showing change password tab.
 * 
 * @component
 */
@inject('rootStore')
class ChangePasswordProfileComp extends Component {

    constructor(props) {
        super(props);
        this.state = {
            confirmDirty: false,
        };
        this.formFields = ["current_password", "new_password", "confirm"]
    }
    /**
     * isFormChanged
     * check current form is changed data with current setting formFields
     * @return  {boolean} true/false            
     */
    isFormChanged = () => {
        return this.props.form?.isFieldsTouched(this.formFields)
    }
    /**
     * componentDidMount
     * binding this component to parent childRef
     * in this case click on save button calling handlesubmit
     * incase leaving page call check form data changed 
     * @return  {null} -
     */
    componentDidMount() {
        const { childRef } = this.props;
        childRef(this);
    }

    /**
     * handleSubmit
     * 
     * @return  {Object}            
     */
    handleSubmit = () => {
        const { rootStore: { myStore } } = this.props;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let submitValue = {
                    ...values,
                }
                myStore?.changePassword(submitValue).catch(errors => {
                    errors?.map(error => {
                        let message = error?.message
                        let err = {};
                        err[error?.field] = {
                            value: values[error?.field],
                            errors: [new Error(message)],
                        }
                        this.props.form?.setFields(err);
                    })
                });
            }
        });
    };

    /**
     * compareToFirstPassword
     * check 2 password match consistent rule or not
     */
    compareToFirstPassword = (rule, value, callback) => {
        const { form, t } = this.props;
        if (value && value !== form?.getFieldValue('new_password')) {
            callback(t('reset_pass.pass_inconsistent'));
        } else {
            callback();
        }
    };

    /**
     * validateToNextPassword
     * check whenever pasword typing - check match validation rule or not
     */
    validateToNextPassword = (rule, value, callback) => {
        const { form } = this.props;
        if (value && this.state?.confirmDirty) {
            form.validateFields(['confirm'], { force: true });
        }
        callback();
    };

    /**
     * handleConfirmBlur
     * check whenever confirm pasword typing - check match validation rule or not
     */
    handleConfirmBlur = e => {
        const { value } = e.target;
        this.setState({ confirmDirty: this.state.confirmDirty || !!value });
    };


    /**
    * render
    *
    * @return  {Component}
    */
    render() {
        const { getFieldDecorator } = this.props.form;
        const { t } = this.props;
        return <div className="wrap-change-password-comp">
            <Form className="passwordreset-form" autoComplete='off' >
                <Form.Item label={t('profile.current_password')}>
                    {getFieldDecorator('current_password', {
                        rules: [
                            {
                                required: true,
                                message: t('profile.current_password_required'),
                            }
                        ],
                    })(<Input.Password autoComplete="off" placeholder={t('profile.current_password_placeholder')} maxLength={128} />)}
                </Form.Item>
                <Form.Item label={t('profile.new_password')}>
                    {getFieldDecorator('new_password', {
                        rules: [
                            {
                                required: true,
                                message: t('profile.new_password_requried'),
                            },
                            {
                                validator: this.validateToNextPassword,
                            },
                        ],
                    })(<Input.Password autoComplete="off" placeholder={t('profile.new_password_placeholder')} maxLength={128} />)}
                </Form.Item>
                <Form.Item label={t('profile.confirm_password')}>
                    {getFieldDecorator('confirm', {
                        rules: [
                            {
                                required: true,
                                message: t('profile.confirm_password_requried'),
                            },
                            {
                                validator: this.compareToFirstPassword,
                            },
                        ],
                    })(<Input.Password autoComplete="off" maxLength={128} placeholder={t('profile.confirm_password_placeholder')} onBlur={this.handleConfirmBlur} />)}
                </Form.Item>
                <Form.Item>
                    <div className="password-rule">
                        {t("common.password_rule")}
                    </div>
                </Form.Item>
            </Form>
        </div>
    }
}

const ChangePasswordTabForm = Form.create({ name: 'normal_change_password_profile' })(ChangePasswordProfileComp);

export default withTranslation()(ChangePasswordTabForm);