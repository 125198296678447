import { action, runInAction, observable } from "mobx";
import NotificationService from "../services/NotificationService";
import BaseStore from "./BaseStore";
import {
  NOTIF_STATUS,
  RESOURCE_LIST,
  SYSTEM_ROUTES_PATH,
} from "utils/constants";

/**
 * Store for notification.
 *
 * @class
 */
class NotificationStore extends BaseStore {
  constructor(parentStore) {
    super(new NotificationService(parentStore), parentStore);
    //filter data for list intersection
    this.resourceName = RESOURCE_LIST.NOTIFICATIONS;
    this.currentModuleRoute = SYSTEM_ROUTES_PATH.Notitication;
    this.filterData = {
      ...this.filterData,
      ...{
        text: "",
        sort: "last_modified_at:descend",
        type_id: "null",
        flag_status: "null",
        read_status: "null",
      },
    };
  }

  //list of all roles
  @observable listAllTypes = [];

  /**
   * getAllNotificationTypes
   * update read status of a notification
   * @param {Array<number>} ids notification id list
   * @param {String} statusRead read/unread status
   */
  @action getAllNotificationTypes = () => {
    this.loading = true;
    this.moduleService
      ?.getAllTypes()
      .then((response) => {
        runInAction(() => {
          if (response && Array.isArray(response.types)) {
            //sort by name at client side
            this.listAllTypes = response.types;
            // .sort((first, second) =>
            //   first.name.localeCompare(second.name)
            // );
            //getting default value
            if (Array.isArray(this.listAllTypes)) {
              let selectedType = this.listAllTypes.find(
                (item) => item.id === this.filterData.type_id
              );
              if (!selectedType) {
                this.filterData.type_id = "null";
              }
            }
          }
        });
      })
      .finally(() => {
        runInAction(() => {
          this.loading = false;
        });
      });
  };
  /**
   * mapping current user to connection id
   * @param   {Object} id connection id
   */
  mapping = (id, cb) => {
    this.moduleService?.mapping(id).then(() => {
      cb && cb();
    });
  };

  /**
   * update read status of a notification
   * @param {Array<number>} ids notification id list
   * @param {String} statusRead read/unread status
   */
  @action updateNotifRead = (statusRead, ids = null) => {
    if (!ids) {
      ids = this.selectedItems;
    }
    this.moduleService?.updateReadStatus(ids, statusRead).then(() => {
      runInAction(() => {
        //reload all data
        this.getData();
        //synchronizeNotifReadData
        this.parentStore.unreadNotificationStore.synchronizeNotifReadData();
      });
    });
  };

  /**
   * update read status of a notification
   * @param {Array<number>} ids notification id list
   * @param {String} statusFlag flag/unflag status
   */
  @action updateNotifFlag = (statusFlag, ids) => {
    if (!ids) {
      ids = this.selectedItems;
    }
    this.moduleService?.updateFlagStatus(ids, statusFlag).then(() => {
      runInAction(() => {
        //reload the list data to get lastest info
        this.getData();
        //synchronizeNotifFlagData
        this.parentStore.unreadNotificationStore.synchronizeNotifFlagData(
          ids,
          statusFlag
        );
      });
    });
  };

  /**
   * synchronizeNotifData
   * update read status of a notification
   * this function nomarly call by notification store to synch flag data of notifcation
   */
  @action synchronizeNotifData = () => {
    //only refresh if current page is notification
    if (window.location.pathname === "/notifications") {
      this.getData();
    }
  };
  /**
   * delete a notification
   * @param {Array<number>} ids notification id list
   */
  @action deleteNotif = (ids = null) => {
    this.loading = true;
    if (!ids) {
      ids = this.selectedItems;
    }
    this.moduleService?.deleteNotif(
      ids,
      () => {
        this.getData();
        runInAction(() => {
          this.loading = false;
          //redirect into page 1 avoid load wrong data
          this.filterData.pagination.current = 1;
          //reload the list data
          this.getData();
          //synchronizeNotifReadData
          this.parentStore.unreadNotificationStore.synchronizeNotifReadData();
        });
      },
      () => {
        runInAction(() => {
          this.loading = false;
        });
      }
    );
  };

  @action getDataById = (item_id, cb) => {
    this.loading = true;
    this.moduleService?.fetchItemById(
      item_id,
      (item) => {
        runInAction(() => {
          this.loading = false;
          this.itemData = this.tranformDataToFormData(item);
          if (item.read_status !== NOTIF_STATUS.READ) {
            this.updateNotifRead(NOTIF_STATUS.READ, [item_id]);
          }
        });
        cb && cb();
      },
      () => {
        runInAction(() => {
          this.loading = false;
        });
      }
    );
  };
}

export default NotificationStore;
