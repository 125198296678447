import * as d3 from "d3";
import React, { Component, createRef } from "react";
import "./style.scss";

const tooltipDimension = { width: 120, height: 30 };

class DoughnutChart extends Component {
  constructor(props) {
    super(props);
    this.ref = createRef();
    this.createPie = d3
      .pie()
      .value((d) => d.value)
      .sort(null);
    this.createArc = d3
      .arc()
      .innerRadius(props.innerRadius)
      .outerRadius(props.outerRadius);
    this.colors = d3.scaleOrdinal(d3.schemeCategory10);
    this.format = d3.format(".2f");
  }

  componentDidMount() {
    this.svg = d3.select(this.ref.current);

    const data = this.createPie(this.props.data);
    const { width, height } = this.props;

    this.svg.attr("class", "chart").attr("width", width).attr("height", height);

    const group = this.svg
      .append("g")
      .attr("transform", `translate(${width / 2} ${height / 2})`);
    this.tooltip = this.svg
      .append("g")
      .style("opacity", 0)
      .style("pointer-events", "none");
    this.tooltip
      .append("rect")
      .attr("x", 0)
      .attr("y", 0)
      .attr("rx", 4)
      .attr("ry", 4)
      .attr("width", tooltipDimension.width)
      .attr("height", tooltipDimension.height)
      .style("fill", "white")
      .style("stroke-width", 1)
      .style("stroke", "#3d3d3d");
    this.tooltip
      .append("circle")
      .attr("id", "legendIcon")
      .attr("cx", 8)
      .attr("cy", tooltipDimension.height / 2)
      .attr("r", 4)
      .attr("fill", "white");
    this.tooltip
      .append("text")
      .attr("id", "legendText")
      .attr("x", 16)
      .attr("y", 20)
      .text("");

    const groupWithEnter = group.selectAll("g.arc").data(data).enter();

    const path = groupWithEnter.append("g").attr("class", "arc");

    path
      .append("path")
      .attr("class", "arc")
      .attr("d", this.createArc)
      .attr("fill", (d) => this.colors(d.index))
      .on("mouseover", this.mouseover)
      .on("mousemove", this.mousemove)
      .on("mouseleave", this.mouseleave);

    path
      .append("text")
      .attr("class", "pie-value")
      .attr("text-anchor", "middle")
      .attr("fill", "var(--text)")
      .attr("alignment-baseline", "middle")
      .attr("transform", () => `translate(0,-10)`)
      .text(this.props.totalData);

    path
      .append("text")
      .attr("class", "pie-text")
      .attr("fill", "var(--text)")
      .attr("text-anchor", "middle")
      .attr("alignment-baseline", "middle")
      .attr("transform", () => `translate(0,10)`)
      .text(this.props.title);
  }

  UNSAFE_componentWillUpdate(nextProps) {
    const data = this.createPie(nextProps.data);

    const group = this.svg.select("g").selectAll("g.arc").data(data);

    group.exit().remove();

    const groupWithUpdate = group.enter().append("g").attr("class", "arc");

    const path = groupWithUpdate.append("path").merge(group.select("path.arc"));

    path
      .attr("class", "arc")
      .attr("d", this.createArc)
      .attr("fill", (d, i) => this.colors(i))
      .on("mouseover", this.mouseover)
      .on("mousemove", this.mousemove)
      .on("mouseleave", this.mouseleave);

    const text = groupWithUpdate.append("text").merge(group.select("text"));
    text
      .attr("class", "pie-value")
      .attr("text-anchor", "middle")
      .attr("fill", "var(--text)")
      .attr("alignment-baseline", "middle")
      .attr("transform", () => `translate(0,-10)`)
      .text(nextProps.totalData);
  }

  // Three function that change the tooltip when user hover / move / leave a cell
  mouseover = ({ data, index }) => {
    this.tooltip.select("#legendIcon").attr("fill", this.colors(index));
    this.tooltip.select("#legendText").text(`${data.name}: ${data.value}`);
    const { width } = this.tooltip
      .select("#legendText")
      .node()
      .getBoundingClientRect();
    this.tooltip.select("rect").attr("width", width + 20);
    this.tooltip.style("opacity", 1);
  };

  mouseleave = () => {
    this.tooltip.style("opacity", 0);
  };

  mousemove = () => {
    const { x, y, width, height } = this.svg.node().getBoundingClientRect();
    const { width: tooltipWidth, height: tooltipHeight } = this.tooltip
      .select("rect")
      .node()
      .getBoundingClientRect();
    const translateX = Math.min(d3.event.pageX - x, width - tooltipWidth);
    const translateY = Math.min(d3.event.pageY - y, height - tooltipHeight);
    this.tooltip?.attr("transform", `translate(${translateX}, ${translateY})`);
  };

  render() {
    return <svg ref={this.ref} />;
  }
}

export default DoughnutChart;
