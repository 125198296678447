import React, { Component } from "react";
import DataTable from "./DataTable";
import { List } from "antd";

class DataTableTrafficProfile extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { dataSource } = this.props;

    return (
      <List
        className="data-table-list"
        dataSource={dataSource}
        renderItem={(item) => (
          <List.Item>
            <DataTable data={item.cluster_info} title={item.timeline} />
          </List.Item>
        )}
      />
    );
  }
}

export default DataTableTrafficProfile;
