import { Button, Col, DatePicker, Icon, Input, Select, Tooltip } from "antd";
import { inject, observer } from "mobx-react";
import moment from "moment";
import React from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import {
  ACTIONS_LIST,
  DATE_FORMAT,
  NOTIFICATION_REDIREC_ACTION,
  NOTIF_STATUS,
  RESOURCE_LIST,
} from "utils/constants";
import BaseManagementComponents from "../../components/BaseManagementComponents";
import Helper from "../../utils/helper";
import NotificationDetail from "./NotificationDetail";
import "./style.scss";

/**
 * Component for showing alarm notification.
 *
 * @component
 */

const { RangePicker } = DatePicker;
const { Option } = Select;
// Default constant
// for category, read, flag

const DEFAULT_READ_LIST = [
  { key: "READ", value: "Read" },
  { key: "UNREAD", value: "Unread" },
];
const DEFAULT_FLAG_LIST = [
  { key: "FLAG", value: "Flag" },
  { key: "UNFLAG", value: "Unflag" },
];
const { UNFLAG, FLAG, UNREAD, READ } = NOTIF_STATUS;
const DELETE = "DELETE";
@inject("rootStore")
@observer
class Notifications extends BaseManagementComponents {
  constructor(props) {
    super(props.rootStore?.notificationStore, props); //
    this.columns = this.getTableColumns();
    this.enableActionBox = true;
    this.enableCreateButton = false;
    this.moduleName = "alm-noti";
    this.pageTitle = (
      <span>
        {props.t("alm-noti.alm_noti_management")}&nbsp;
        <Tooltip
          className="page-title-tooltip"
          placement="right"
          title={props.t("alm-noti.title_explane")}
        >
          <Icon type="exclamation-circle" />
        </Tooltip>
      </span>
    );
    this.resourceName = RESOURCE_LIST.NOTIFICATIONS;

    this.buttonActions = [
      {
        key: "FLAG",
        title: props.t("alm-noti.flag_all"),
        action: (data) => this.currentStore?.updateNotifFlag(FLAG, data),
        confirm: true,
        resource: RESOURCE_LIST.NOTIFICATIONS,
        child: <Icon type="flag" theme="filled" />,
        permission: ACTIONS_LIST.EDIT,
        confirmTitle: props.t("alm-noti.flag_all_confirm_title"),
        confirmContent: props.t("alm-noti.flag_all_confirm_content"),
      },
      {
        key: "UNFLAG",
        title: props.t("alm-noti.un_flag_all"),
        action: (data) => this.currentStore?.updateNotifFlag(UNFLAG, data),
        confirm: true,
        type: "flag",
        permission: ACTIONS_LIST.EDIT,
        confirmTitle: props.t("alm-noti.un_flag_all_confirm_title"),
        confirmContent: props.t("alm-noti.un_flag_all_confirm_content"),
      },
      {
        key: "READ",
        title: props.t("alm-noti.read_all"),
        action: (data) => this.currentStore?.updateNotifRead(READ, data),
        confirm: true,
        child: <Icon type="read" theme="filled" />,
        permission: ACTIONS_LIST.EDIT,
        confirmTitle: props.t("alm-noti.read_all_confirm_title"),
        confirmContent: props.t("alm-noti.read_all_confirm_content"),
      },
      {
        key: "UNREAD",
        title: props.t("alm-noti.un_read_all"),
        action: (data) => this.currentStore?.updateNotifRead(UNREAD, data),
        confirm: true,
        type: "read",
        permission: ACTIONS_LIST.EDIT,
        confirmTitle: props.t("alm-noti.un_read_all_confirm_title"),
        confirmContent: props.t("alm-noti.un_read_all_confirm_content"),
      },
      {
        key: "DELETE",
        title: props.t("alm-noti.delete_all"),
        action: (data) => this.currentStore?.deleteNotif(data),
        confirm: true,
        permission: ACTIONS_LIST.DELETE,
        type: "delete",
        confirmTitle: props.t("alm-noti.delete_all_confirm_title"),
        confirmContent: props.t("alm-noti.delete_all_confirm_content"),
      },
    ];
    this.tableInlineActions = [
      {
        key: "FLAG",
        tooltip: props.t("alm-noti.flag"),
        action: this.handleTableAction,
        permission: ACTIONS_LIST.EDIT,
        icon: "flag",
        passingData: (item) => {
          return [FLAG, [item?.id]];
        },
        visible: (item) => {
          return item?.flag_status == UNFLAG;
        },
      },
      {
        key: "UNFLAG",
        tooltip: props.t("alm-noti.unflag"),
        action: this.handleTableAction,
        permission: ACTIONS_LIST.EDIT,
        child: <Icon type="flag" theme="filled" />,
        passingData: (item) => {
          return [UNFLAG, [item?.id]];
        },
        visible: (item) => {
          return item?.flag_status == FLAG;
        },
      },
      {
        key: "DELETE",
        tooltip: props.t("alm-noti.delete"),
        action: this.handleTableAction,
        permission: ACTIONS_LIST.DELETE,
        icon: "delete",
        passingData: (item) => {
          return [DELETE, [item?.id]];
        },
      },
      {
        key: "READ",
        tooltip: props.t("alm-noti.read"),
        action: this.handleTableAction,
        permission: ACTIONS_LIST.EDIT,
        icon: "read",
        passingData: (item) => {
          return [READ, [item?.id]];
        },
        visible: (item) => {
          return item?.read_status === UNREAD;
        },
      },
      {
        key: "UNREAD",
        tooltip: props.t("alm-noti.unread"),
        action: this.handleTableAction,
        permission: ACTIONS_LIST.EDIT,
        child: <Icon type="read" theme="filled" />,
        passingData: (item) => {
          return [UNREAD, [item?.id]];
        },
        visible: (item) => {
          return item?.read_status === READ;
        },
      },
    ];
  }
  /**
   * onRow
   * hight line row of tables
   *
   */
  onRow = (record) => {
    const rowProps = {};
    if (record?.read_status == UNREAD) rowProps.className = "bold-row";
    return rowProps;
  };
  /**
   * componentDidMount
   *
   */
  componentDidMount = () => {
    const { t } = this.props;
    document.title = t("alm-noti.page-title");
    super.componentDidMount();
    this.currentStore.getAllNotificationTypes();
  };

  /**
   * return column set of table
   */
  getTableColumns = () => {
    const {
      t,
      rootStore: { myStore },
    } = this.props;
    const currentLanguage = myStore?.currentProfile?.language;
    return [
      {
        title: t("alm-noti.name"),
        dataIndex: "name",
        key: "name",
        width: "20%",
        render: (value, key) => {
          //because name of notification can be an object( multi lang)
          //have to trans to current language
          return this.renderKeyColumn(
            Helper.getTranslateText(value, currentLanguage),
            key,
            ACTIONS_LIST.NOTIFICATIONS
          );
        },
      },
      {
        title: t("alm-noti.type"),
        dataIndex: "type",
        key: "type",
      },
      {
        title: t("alm-noti.description"),
        key: "description",
        width: "30%",
        className: "noti-desc",
        render: (item) => {
          //because description of notification can be an object( multi lang)
          //have to trans to current language
          let description = Helper.getTranslateText(
            item.description,
            currentLanguage
          );

          return description;
        },
      },
      {
        title: t("alm-noti.intersection_name"),
        dataIndex: "intersection_name",
        key: "intersection_name",
      },
      {
        title: t("alm-noti.created_time"),
        dataIndex: "created_at",
        key: "created_at",
        width: "10%",
        render: (created_at) => moment(created_at).format(DATE_FORMAT.full),
        sorter: true,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: t("alm-noti.last_modified_at"),
        dataIndex: "last_modified_at",
        key: "last_modified_at",
        width: "10%",
        render: (last_modified_at) =>
          moment(last_modified_at).format(DATE_FORMAT.full),
        sorter: true,
        defaultSortOrder: "descend",
        sortDirections: ["descend", "ascend"],
      },
    ];
  };

  //-------- render common component ----------
  /**
   * renderFilterComponent
   * this function return the filter box
   * @return  {Component}
   */
  renderFilterComponent = () => {
    const { t } = this.props;
    return (
      <div>
        <Col span={3}>
          <Tooltip placement="top" title={t("alm-noti.category_tooltip")}>
            <Select
              labelInValue
              onChange={(value) => {
                this.currentStore?.handleFilterDataChange("type_id", value.key);
              }}
              defaultValue={{ key: this.currentStore?.filterData.type_id }}
            >
              <Option value="null">{t("alm-noti.category-all")}</Option>
              {this.currentStore.listAllTypes.map((e) => (
                <Option key={e.id} value={e.id}>
                  {e.name}
                </Option>
              ))}
            </Select>
          </Tooltip>
        </Col>
        <Col span={4}>
          <Tooltip placement="top" title={t("alm-noti.search_tooltip")}>
            <Input
              maxLength={255}
              defaultValue={this.currentStore?.filterData?.text}
              onChange={(e) =>
                this.currentStore?.handleFilterDataChange(
                  "text",
                  e.target?.value
                )
              }
              placeholder={t("alm-noti.search_placholer")}
              onPressEnter={this.currentStore?.handleFilerAction}
            />
          </Tooltip>
        </Col>
        <Col span={5}>
          <Tooltip title={t("alm-noti.filter_date")}>
            <RangePicker
              format={DATE_FORMAT.day_only}
              defaultValue={[
                this.currentStore?.filterData?.from_date
                  ? moment.unix(this.currentStore?.filterData?.from_date / 1000)
                  : null,
                this.currentStore?.filterData?.to_date
                  ? moment.unix(this.currentStore?.filterData?.to_date / 1000)
                  : null,
              ]}
              onChange={(e) => {
                this.currentStore.handleFilterDataChange(
                  "from_date",
                  e?.[0]?.startOf("day").format("x"),
                  false
                );
                this.currentStore.handleFilterDataChange(
                  "to_date",
                  e?.[1]?.endOf("day").format("x")
                );
              }}
            />
          </Tooltip>
        </Col>
        <Col span={4}>
          <Tooltip placement="top" title={t("alm-noti.read_status_tooltip")}>
            <Select
              onChange={(value) =>
                this.currentStore?.handleFilterDataChange("read_status", value)
              }
              value={this.currentStore?.filterData?.read_status}
            >
              <Option value="null">{t("alm-noti.read_status_all")}</Option>
              {DEFAULT_READ_LIST?.map((e) => (
                <Option key={e.key} value={e.key}>
                  {t(e.value)}
                </Option>
              ))}
            </Select>
          </Tooltip>
        </Col>
        <Col span={4}>
          <Tooltip placement="top" title={t("alm-noti.flag_status_tooltip")}>
            <Select
              onChange={(value) =>
                this.currentStore?.handleFilterDataChange("flag_status", value)
              }
              defaultValue={this.currentStore?.filterData?.flag_status}
            >
              <Option value="null">{t("alm-noti.flag_status_all")}</Option>
              {DEFAULT_FLAG_LIST?.map((e) => (
                <Option key={e.key} value={e.key}>
                  {t(e.value)}
                </Option>
              ))}
            </Select>
          </Tooltip>
        </Col>
        <Col span={4}>
          <Tooltip
            placement="top"
            title={t("alarm_records.intersection_search_tooltip")}
          >
            <Input
              maxLength={255}
              value={this.currentStore?.filterData?.intersection}
              onChange={(e) =>
                this.currentStore?.handleFilterDataChange(
                  "intersection",
                  e.target?.value
                )
              }
              placeholder={t("alarm_records.intersection_search_plachoder")}
              onPressEnter={this.currentStore?.handleFilerAction}
            />
          </Tooltip>
        </Col>
      </div>
    );
  };

  /**
   * renderDetailData
   * this function return the user detail box
   * @return  {Component}
   */
  renderDetailData = () => {
    return (
      <NotificationDetail
        onClose={this.handleDrawerOnClose}
        currentStore={this.currentStore}
      />
    );
  };

  /**
   * renderFooterDetail
   * this function return the action for every notification detail
   * @return  {Component}
   */
  renderFooterDetail = () => {
    const {
      t,
      history,
      rootStore: { reportResultStore },
    } = this.props;
    this.currentStore?.itemData;
    if (Array.isArray(this.currentStore?.itemData?.action))
      return this.currentStore?.itemData?.action?.map((e, index) => {
        return (
          <Button
            key={index}
            onClick={() =>
              Helper.handleLinkAction(e, history, this.props.rootStore)
            }
          >
            {e.btn_title ? e.btn_title : t("alm-noti.show_more")}
          </Button>
        );
      });
    else
      return (
        this.currentStore?.itemData?.action && (
          <div>
            <Button
              onClick={() => {
                if (
                  this.currentStore?.itemData?.action?.target ===
                  "REPORT_RESULTS"
                ) {
                  history.push(
                    NOTIFICATION_REDIREC_ACTION.REPORT_RESULTS +
                      "/" +
                      this.currentStore?.itemData?.action?.data
                        ?.report_result_id
                  );
                  if (
                    this.props?.location?.pathname.indexOf(
                      NOTIFICATION_REDIREC_ACTION.REPORT_RESULTS
                    ) !== -1
                  )
                    reportResultStore.getResultData(
                      this.currentStore?.itemData?.action?.data
                        ?.report_result_id
                    );
                } else
                  Helper.handleLinkAction(
                    this.currentStore?.itemData?.action,
                    history,
                    this.props.rootStore,
                    this.currentStore,
                    t
                  );
              }}
            >
              {this.currentStore?.itemData?.action?.btn_title
                ? this.currentStore.itemData.action.btn_title
                : t("alm-noti.show_more")}
            </Button>
          </div>
        )
      );
  };
  /**
   * render
   *
   * @return  {Component}
   */
  render() {
    return <div className="notification-page-wrap">{super.render()}</div>;
  }
}

export default withRouter(withTranslation()(Notifications));
