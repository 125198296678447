import React, { Component } from "react";
import { Icon, Table } from "antd";
import "./style.scss";
// import { deviation } from "d3-array";

class AbnormalDataTable extends Component {
  constructor(props) {
    super(props);
    this.formattedData = this.formatData();
  }

  formatData = () => {
    const { data } = this.props;
    const formattedData = data.data.map((event) => {
      const children = [];
      if (event.details.length) {
        for (let i = 0; i < event.details.length; i += 1) {
          const count = event.details[i].count.find(date => date.data === data.target_date).raw_count;

          children.push({
            event_name: `${event.event_name} (${event.event_code})`,
            event_params: event.params[i],
            event_count: count,
            avg: event.details[i].avg,
            raw_counts: event.details[i].count,
            deviation: event.deviation,
          })
        }
      }

      const count = event.raw_counts.length ? event.raw_counts.find(date => date.data === data.target_date).raw_count : 0;

      if (children.length) {
        return {
          event_name: `${event.event_name} (${event.event_code})`,
          event_params: event.params.join(', '),
          event_count: count,
          avg: event.avg,
          raw_counts: event.raw_counts,
          deviation: event.deviation,
          children: children, 
        }
      } else {
        return {
          event_name: `${event.event_name} (${event.event_code})`,
          event_params: event.params.join(', '),
          event_count: count,
          avg: event.avg,
          raw_counts: event.raw_counts,
          deviation: event.deviation,
        }
      }
    })

    return formattedData;
  }

  renderColumns = () => {
    const {data, setChartVisible} = this.props;

    const getSubColumns = () => {
      const subColumns = [];
            
      //Average sub column
      subColumns.push({
        title: "Average",
        key: "avg",
        render: item => Math.round(item?.avg*100)/100,
      })

      if (!data?.data[0]?.raw_counts) return []

      data.data[0].raw_counts.forEach((element) => {
        if (element.data !== data.target_date) {
          subColumns.push({
            title: element.data,
            key: element.data,
            render: (item) => {
              if (!item.raw_counts.length) return 0;

              const exactDate = item.raw_counts.find(date => date.data === element.data);

              return exactDate ? exactDate.raw_count : 0;
            },
          })
        }
      })

      return subColumns
    }

    return(
      [
        {
          title: "Event Name - Event Code", 
          key: "event_name",
          render: (item) => (
            <>
              {(item.event_count > (item.avg*(1+ item.deviation/100))) && <Icon type="warning" theme="filled" style={{color: "orange", height: "18px"}} className="mr-8"/>}
              {item.event_name}
            </>
          )  
        },
        {
          title: "Event Params", 
          key: "event_params",
          dataIndex: "event_params",
        },
        {
          title: `Target Date: ${data.target_date}`, 
          key: "event_count",
          dataIndex: "event_count",
        },
        {
          title: `Compare previous (${data.period}) days to target date`,
          children: getSubColumns(),
        },
        {
          render: (item, record, index) => {
            if (item?.children) return <a onClick={() => {setChartVisible(index || 0)}}>View Chart</a>
          }
        },
      ]
    )
  }

  render() {
    return(
      <div style={{width: "100%"}}>
        <Table
          pagination={false}
          rowClassName={(record, index) => {
            if (index % 2 == 0) return "odd-row";
            else return "even-row";
          }}
          className="spm-table"
          columns={this.renderColumns()}
          dataSource={this.formattedData}
        />
      </div>
    )
  }
}

export default AbnormalDataTable;