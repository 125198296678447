import { Form, Input, InputNumber, Select, TimePicker, Button } from "antd";
import SiwaveMultipleDatePicker from "components/SiwaveMultipleDatePicker/index";
import { inject, observer } from "mobx-react";
import moment from "moment";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import { Map, Marker, TileLayer } from "react-leaflet";

import Control from "react-leaflet-control";
import {
  DATE_FORMAT,
  SELECTED_PIN,
  DEFAULT_PIN,
  TIME_PICKER_FORMAT,
  WEEK_DAY_OPTION,
} from "utils/constants";
const COMBOBOX = "combobox";
const Option = Select.Option;
@inject("rootStore")
@observer
class OffsetOptimizationFilter extends Component {
  constructor(props) {
    super(props);
  }

  checkVaildTime = (time, value, callback) => {
    const {
      t,
      rootStore: {
        optimizerStore: { formData },
      },
    } = this.props;
    const fromTimeObject = moment(formData.start_time, DATE_FORMAT.hour);
    const toTimeObject = moment(formData.end_time, DATE_FORMAT.hour);
    if (fromTimeObject.isBefore(toTimeObject)) {
      return callback();
    } else {
      callback(t("analysis.from_time_greater_to_time"));
    }
  };

  /**
   * forcus on target marker
   * @return  {Component}
   */
  render() {
    const {
      t,
      handleIntersectionChange,
      showFullMap,
      rootStore: {
        optimizerStore: {
          formData,
          allPhaseNumber,
          setFormField,
          listIntersections,
          currentMapZoom,
          currentMapCenter,
        },
      },
      form: { getFieldDecorator, validateFields, resetFields },
    } = this.props;
    return (
      <>
        <Form.Item label={t("analysis.intersection")}>
          {getFieldDecorator("intersection_id", {
            rules: [
              {
                required: true,
                message: t("analysis.intersection_required"),
              },
            ],
            initialValue: formData.intersection_id,
          })(
            <Select
              showSearch
              onChange={(value) => handleIntersectionChange(value, COMBOBOX)}
              placeholder={t("analysis.intersection_placholder")}
              optionFilterProp="value"
              filterOption={(input, option) =>
                option?.props?.children
                  ?.toLowerCase()
                  ?.indexOf(input?.toLowerCase()) >= 0
              }>
              {listIntersections?.map((e) => (
                <Select.Option key={e.id} value={e.id}>
                  {e.name}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item>
          <Map
            style={{ height: "200px" }}
            className="intersection-map"
            zoom={currentMapZoom}
            center={currentMapCenter}
            onViewportChanged={this.handleMapChangeView}>
            <TileLayer
              url={process.env.REACT_APP_OPEN_STREET_MAP}
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            />
            <Control position="bottomright">
              <Button
                icon="fullscreen"
                className="map-fullscreen"
                onClick={showFullMap}
              />
            </Control>
            {listIntersections?.map((intersection) => (
              <Marker
                icon={
                  formData?.intersection_id === intersection?.id
                    ? SELECTED_PIN
                    : DEFAULT_PIN
                }
                position={[intersection.latitude, intersection.longitude]}
                key={intersection.id}
                onClick={(e) =>
                  handleIntersectionChange(intersection.id, MAP, e)
                }></Marker>
            ))}
          </Map>
        </Form.Item>
        <Input.Group className="flex w-full justify-space-beetwen">
          <Form.Item label={t("optimization.plan_number")} className="w-128">
            {getFieldDecorator("plan_number", {
              rules: [
                {
                  required: true,
                  message: t("optimization.plan_number_required"),
                },
              ],
              initialValue: formData.plan_number,
            })(
              <InputNumber
                onChange={(value) => {
                  setFormField("plan_number", value);
                }}
                min={1}
                max={254}
              />
            )}
          </Form.Item>

          <Form.Item label={t("analysis.phase_name")} className="w-128">
            {getFieldDecorator("phase", {
              rules: [
                {
                  required: true,
                  message: t("analysis.analysis_required"),
                },
              ],
              initialValue: formData.phase,
            })(
              <Select
                style={{ width: "100%" }}
                onChange={(value) => {
                  setFormField("phase", value);
                }}>
                {allPhaseNumber?.map((e) => (
                  <Option value={e?.phase_number} key={e?.phase_number}>
                    {e?.name}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Input.Group>

        <Input.Group className="flex w-full justify-space-beetwen">
          <Form.Item label={t("report_result.time_range")}>
            {getFieldDecorator("start_time", {
              rules: [
                {
                  required: true,
                  message: t("analysis.from_time_required"),
                },
                { validator: this.checkVaildTime },
              ],

              initialValue: moment(formData.start_time, DATE_FORMAT.hour),
            })(
              <TimePicker
                placeholder={t("analysis.from_time_placeholder")}
                onChange={(time, timeString) => {
                  setFormField(
                    "start_time",
                    moment(timeString, TIME_PICKER_FORMAT).format(
                      TIME_PICKER_FORMAT
                    )
                  );
                }}
                format={TIME_PICKER_FORMAT}
              />
            )}
          </Form.Item>

          <Form.Item label={" "} colon={false}>
            {getFieldDecorator("end_time", {
              rules: [
                {
                  required: true,
                  message: t("optimization.to_time_required"),
                },
              ],
              initialValue: moment(formData.end_time, DATE_FORMAT.hour),
            })(
              <TimePicker
                placeholder={t("analysis.to_time_placeholder")}
                onChange={(time, timeString) => {
                  setFormField(
                    "end_time",
                    moment(timeString, TIME_PICKER_FORMAT).format(
                      TIME_PICKER_FORMAT
                    )
                  );
                  resetFields(["from_time"]);
                  validateFields(["from_time"]);
                }}
                format={TIME_PICKER_FORMAT}
              />
            )}
          </Form.Item>
        </Input.Group>
        <Form.Item
          className="form-disable-position"
          label={t("report_result.date_range")}>
          {getFieldDecorator("dates", {
            rules: [
              {
                required: true,
                message: t("report_template.select_date_range_required"),
              },
            ],
            initialValue: formData.dates,
            getValueFromEvent: (value) => {
              setFormField(
                "dates",
                value.map((e) => e.format(DATE_FORMAT.day_only_backend))
              );
              return value;
            },
          })(
            <SiwaveMultipleDatePicker
              placeholder={t("report_result.date_range")}
              format={DATE_FORMAT.day_only_backend}
            />
          )}
        </Form.Item>

        <Form.Item label={t("report_template.day_of_week")}>
          {getFieldDecorator("weekdays", {
            rules: [
              {
                required: true,
                message: t("report_template.week_days_required"),
              },
            ],
            getValueFromEvent: (value) => {
              setFormField("week_days", value);
              return value;
            },
            initialValue: formData.week_days,
          })(
            <Select
              mode="multiple"
              allowClear={true}
              style={{ width: "100%" }}
              placeholder={t("alarm_rules.weekdays")}
              optionLabelProp="label">
              {WEEK_DAY_OPTION?.map((e, id) => (
                <Option key={id} value={e.value} label={e.label}>
                  {e.label}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>
      </>
    );
  }
}

export default withRouter(withTranslation()(OffsetOptimizationFilter));
