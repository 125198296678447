import { Button, Col, Row, Select, Tooltip } from "antd";
import { inject, observer } from "mobx-react";
import moment from "moment";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import {
  ACTIONS_LIST,
  DASHBOARD_AUTO_LOAD_DATA_TIME,
  DATE_FORMAT,
  UNKOWN_DATA_TEXT,
} from "utils/constants";
import Unauthorized from "../../containers/StaticPages/403";
import "./style.scss";
import GridWidgetLayout from "../../components/GridWidgetLayout";
import { DASHBOARD_WIDGETS } from "../../utils/constants/dashboardConstant";
import MapSetting from "./HeatMap/MapSetting";
import OpenAlarmSetting from "./InfoAlarmMessage/OpenAlarmSetting";
import TopTenSetting from "./TopTen/TopTenSetting";
import NotificationSetting from "./Notification/NotificationSetting";
import VolumeCountsSetting from "./VolumeCounts/VolumeCountsSetting";
import SummaryReportSetting from "./SummaryReport/SummaryReportSetting";

const { Option } = Select;
@inject("rootStore")
@observer
class Home extends Component {
  // refernce of map object - to get topleft-buttonright position when change agency
  heatMapRef = {};
  //interval autorefresh dashboard data in every hours
  autoRefreshDataInterval = null;
  /**
   * componentDidMount
   * get all Data for home page
   *
   */
  componentDidMount = () => {
    const {
      t,
      rootStore: { dashboardStore },
    } = this.props;
    document.title = t("dashboard.page-title");
    dashboardStore?.refreshDashboardData();
    dashboardStore?.setHeatMapRef(this.heatMapRef);
    if (!this.autoRefreshDataInterval) {
      this.autoRefreshDataInterval = setInterval(
        dashboardStore?.refreshDashboardData,
        DASHBOARD_AUTO_LOAD_DATA_TIME
      );
    }
  };

  /**
   * componentWillUnmount
   * clean interval loading avoid leak mem
   * @return  {null}
   */
  componentWillUnmount() {
    clearInterval(this.autoRefreshDataInterval);
    this.autoRefreshDataInterval = null;
  }

  triggerResizeEvent = () => {
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 20);
  };

  toggleEditingLayout = (editing) => {
    const {
      rootStore: { dashboardStore },
    } = this.props;
    dashboardStore?.toggleEditingLayout(editing);
    if (!editing) this.triggerResizeEvent();
  };

  toggleAddingLayout = (adding) => {
    const {
      rootStore: { dashboardStore },
    } = this.props;
    dashboardStore?.toggleAddingLayout(adding);
  };

  toggleSettingLayout = (setting) => {
    const {
      rootStore: { dashboardStore },
    } = this.props;
    dashboardStore?.toggleWidgetSetting(setting);
  };

  onChangeLayout = (layouts) => {
    const {
      rootStore: { dashboardStore },
    } = this.props;
    dashboardStore.changeLayout(layouts);
    this.triggerResizeEvent();
  };

  /**
   * render
   * @return  {Compoennt}
   */
  render() {
    const {
      t,
      permission,
      rootStore: { commonStore, dashboardStore, myStore },
    } = this.props;
    if (!myStore.hasPermission(ACTIONS_LIST.VIEW, permission, true)) {
      return <Unauthorized />;
    }
    return (
      <div className="wrap-home">
        <Row style={{ height: "5%", width: "100%", padding: "0 12px" }}>
          <Col span={10}>
            {/* only admin can see the list of agencies */}
            {commonStore.isSuperAdmin() &&
              dashboardStore.listAllAgency.length > 0 && (
                <Tooltip
                  placement="top"
                  title={t("intersection.agency_tooltip")}
                >
                  <span className="agency-header">
                    {t("dashboard.agency")} :{" "}
                  </span>
                  <Select
                    className="agency"
                    onChange={(value) => {
                      dashboardStore?.handleFilterDataChange(
                        "agency_id",
                        value
                      );
                    }}
                    value={dashboardStore?.currentAgency}
                  >
                    {dashboardStore?.listAllAgency?.map((e) => (
                      <Option key={e.id} value={e.id}>
                        {e.name}
                      </Option>
                    ))}
                  </Select>
                </Tooltip>
              )}
            {dashboardStore.layoutEditing && (
              <Button
                type="primary"
                onClick={() => this.toggleAddingLayout(true)}
                style={{
                  marginLeft: 12,
                }}
              >
                {t("dashboard.add_widgets")}
              </Button>
            )}
            <Button
              type="primary"
              onClick={() =>
                this.toggleEditingLayout(!dashboardStore.layoutEditing)
              }
              style={{ marginLeft: 12 }}
            >
              {dashboardStore.layoutEditing
                ? t("dashboard.done_editing")
                : t("dashboard.edit_widgets")}
            </Button>
          </Col>
          <Col span={14} className="last-updated-col">
            <span className="last-updated-txt">
              {t("dashboard.updated-time")}
              {dashboardStore?.lastUpldatedTime
                ? moment
                    .utc(dashboardStore.lastUpldatedTime)
                    .format(DATE_FORMAT.withTimeZone)
                : UNKOWN_DATA_TEXT}
            </span>
            <Tooltip placement="top" title={t("dashboard.refresh_tooltip")}>
              <Button
                type="primary"
                icon="reload"
                loading={dashboardStore.loading}
                className="auto-reload-btn"
                onClick={dashboardStore?.refreshDashboardData}
              />
            </Tooltip>
          </Col>
        </Row>
        <Row style={{ display: "flex", width: "100%" }}>
          <GridWidgetLayout
            loading={dashboardStore.loadingLayout}
            availableWidgets={dashboardStore.availableWidgets}
            layouts={dashboardStore.layouts}
            editing={dashboardStore.layoutEditing}
            adding={dashboardStore.layoutAdding}
            setting={dashboardStore.widgetSetting}
            toggleEditing={this.toggleEditingLayout}
            toggleAdding={this.toggleAddingLayout}
            toggleSetting={this.toggleSettingLayout}
            onChangeLayout={this.onChangeLayout}
            onSaveLayout={dashboardStore?.saveDashboardSetting}
            itemRef={{
              [DASHBOARD_WIDGETS.HEATMAP]: this.heatMapRef,
            }}
            widgetConfigurations={{
              [DASHBOARD_WIDGETS.HEATMAP]: MapSetting,
              [DASHBOARD_WIDGETS.TOP_TEN]: TopTenSetting,
              [DASHBOARD_WIDGETS.INFO_ALARM_MESSAGE]: OpenAlarmSetting,
              [DASHBOARD_WIDGETS.SUMMARY_REPORT]: SummaryReportSetting,
              [DASHBOARD_WIDGETS.VOLUME_COUNT]: VolumeCountsSetting,
              [DASHBOARD_WIDGETS.NOTIFICATION]: NotificationSetting,
            }}
          />
        </Row>
      </div>
    );
  }
}

export default withTranslation()(Home);
