import React, { Component } from "react";
import { Form, Input, Button, Alert } from "antd";
import { ReactComponent as Logo } from "../../svgs/logo.svg";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import "./style.scss";

/**
 * Component for showing reset password page.
 *
 * @component
 */
@inject("rootStore")
@observer
class ForceChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmDirty: false,
    };
  }
  /**
   * componentDidMount
   * check whenever logined user enter the forgot page -redirect to home page
   * clear all error list
   */
  componentDidMount = () => {
    const {
      t,
    } = this.props;    
    document.title = t("profile.page-title");
  };
  /**
   * handleSubmit
   * validation input then send back to store
   *
   */
  handleSubmit = (e) => {
    e.preventDefault();
    const {
      rootStore: { myStore },
    } = this.props;
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let submitValue = {
          ...values,
        };
        myStore
          ?.changePassword(submitValue)
          .then(() => {
            window.location.href = "/login";
          })
          .catch((errors) => {
            errors?.map((error) => {
              let message = error?.message;
              let err = {};
              err[error?.field] = {
                value: values[error?.field],
                errors: [new Error(message)],
              };
              this.props.form?.setFields(err);
            });
          });
      }
    });
  };

  /**
   * handleConfirmBlur
   * check whenever confirm pasword typing - check match validation rule or not
   */
  handleConfirmBlur = (e) => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  /**
   * compareToFirstPassword
   * check 2 password match consistent rule or not
   */
  compareToFirstPassword = (rule, value, callback) => {
    const { form, t } = this.props;
    if (value && value !== form.getFieldValue("new_password")) {
      callback(t("reset_pass.pass_inconsistent"));
    } else {
      callback();
    }
  };
  /**
   * validateToNextPassword
   * check whenever pasword typing - check match validation rule or not
   */
  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(["confirm"], { force: true });
    }
    callback();
  };

  /**
   * render
   *
   * @return  {Component}
   */
  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      rootStore: {
        myStore: { loading, errorList, clearErrorList },
      },
      t,
    } = this.props;
    return (
      <div className="wrap-change-password">
        <div className="wrap-error-comp">
          {errorList && (
            <Alert
              message={errorList.message}
              type="error"
              closable
              showIcon
              onClose={clearErrorList}
              className="spm-error-comp"
            />
          )}
        </div>
        <div className="wrap-login-comp">
          <Form
            onSubmit={this.handleSubmit}
            className="passwordreset-form"
            autoComplete="off"
          >
            <div className="logo-login">
              <Logo className="logo-img"> </Logo>
            </div>
            <div className="title-login">{t("profile.title_expire")}</div>
            <Form.Item label={t("profile.current_password")} hasFeedback>
              {getFieldDecorator("current_password", {
                rules: [
                  {
                    required: true,
                    message: t("profile.current_password_required"),
                  },
                ],
              })(
                <Input.Password
                  autoComplete="off"
                  placeholder={t("profile.current_password_placeholder")}
                  maxLength={128}
                />
              )}
            </Form.Item>
            <Form.Item label={t("profile.new_password")} hasFeedback>
              {getFieldDecorator("new_password", {
                rules: [
                  {
                    required: true,
                    message: t("profile.new_password_requried"),
                  },
                  {
                    validator: this.validateToNextPassword,
                  },
                ],
              })(
                <Input.Password
                  autoComplete="off"
                  placeholder={t("profile.new_password_placeholder")}
                  maxLength={128}
                />
              )}
            </Form.Item>
            <Form.Item label={t("profile.confirm_password")} hasFeedback>
              {getFieldDecorator("confirm", {
                rules: [
                  {
                    required: true,
                    message: t("profile.confirm_password_requried"),
                  },
                  {
                    validator: this.compareToFirstPassword,
                  },
                ],
              })(
                <Input.Password
                  autoComplete="off"
                  placeholder={t("profile.confirm_password_placeholder")}
                  maxLength={128}
                  onBlur={this.handleConfirmBlur}
                />
              )}
            </Form.Item>
            <Form.Item>
              <div className="password-rule">{t("common.password_rule")}</div>
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="reset-form-button"
                loading={loading}
              >
                {t("reset_pass.change")}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
}

const ForceChangePasswordForm = Form.create({ name: "reset_password_form" })(
  ForceChangePassword
);

export default withRouter(withTranslation()(ForceChangePasswordForm));
