import ChartDetail from "components/ChartDetail";
import * as d3 from "d3";
import { CHART_COLOR_SCHEMA } from "utils/constants";
const MAP_COLOR_SPLIT = [
  CHART_COLOR_SCHEMA.YELLOW,
  CHART_COLOR_SCHEMA.GREEN_SIEMENS,
  CHART_COLOR_SCHEMA.BLUE,
  CHART_COLOR_SCHEMA.GREY,
];

class SplitFailure extends ChartDetail {
  constructor(props) {
    super(props);
    this.value_field = "to_time";
    this.data_field = "cycles";
    this.ChartStyle = {
      marginLeftRight: 0,
      marginTopBottom: 0,
      extraFocusPosition: 0,
      extraFocusHeight: 40,
      extraFocusWidth: 4,
    };
    this.CHART_AXIS_SETTING = [
      {
        position: "left",
        key: "ror5",
        label: "Percentage",
        data: this.props.data.cycles,
        tick: true,
      },
    ];
  }
  findMaxValueInObjectArray = () => {
    return 90.91;
  };
  getDataObject = (chartData, item) => {
    const { from_time, to_time } = this.props;
    let result = [];
    chartData.map((e) => {
      if (
        from_time.getTime() <= new Date(e[this.value_field]).getTime() &&
        new Date(e[this.value_field]).getTime() <= to_time.getTime()
      ) {
        result.push({
          time: e[this.value_field],
          value: e[item.key],
        });
      }
    });
    return result;
  };

  setExtraData = () => {
    const { data } = this.props;
    this.extraData = [];

    data[this.data_field]?.forEach((item) => {
      let color = MAP_COLOR_SPLIT[item?.indicator - 1];
      if (color)
        this.extraData.push({
          time: item.to_time,
          color: color,
        });
    });
  };

  updateChart = (xObj, yObj) => {
    const { onChartZoom } = this.props;
    this.newX = xObj;
    this.newYObject = yObj;
    for (var id in yObj) {
      if (id == "left") {
        this.yAxis[id].call(d3.axisLeft(yObj[id]).tickSize(-this.fullWidth));
      } else {
        this.yAxis[id].call(d3.axisRight(yObj[id]));
      }
    }
    for (var id in this.availableLineData) {
      this.availableLineData[id].x = this.newX;
      this.availableLineData[id].y =
        this.newYObject[this.availableLineData[id].yAxis];
    }
    this.xAxis.call(d3.axisBottom(this.newX).tickSize(-this.fullHeight));
    onChartZoom(this.newX);
    this.chartObject.selectAll(".backgournd-group").remove();
    this.chartObject.selectAll(".lines-chart-group").remove();
    this.chartObject.selectAll(".gapdata-group").remove();
    this.chartObject.selectAll(".focus").remove();
    this.renderChartBackgroundColor(this.planData);
    this.renderGapDataForChart(this.props.gapdata);
    this.handleMouseOut();
    this.renderChartData();
  };

  renderChartData  = () => {
    let lineCharts = this.chartObject
      .append("g")
      .attr("class", "lines-chart-group");
    lineCharts
      .append("defs")
      .append("SVG:clipPath")
      .attr("id", "clip")
      .append("SVG:rect")
      .attr("width", this.fullWidth)
      .attr("height", this.fullHeight)
      .attr("x", 0)
      .attr("y", 0);
    this.lineChart = lineCharts;

    this.extraData?.forEach((item) => {
      if (item.color === CHART_COLOR_SCHEMA.GREY) return;
      const pointX = this.newX(new Date(item.time));
      lineCharts
        .append("rect")
        .style("fill", item.color)
        .attr("clip-path", "url(#clip)")
        .attr("x", pointX)
        .attr("width", 4)
        .attr("class", "bar")
        .attr("height", this.fullHeight)
        .attr(
          "transform",
          "translate(" +
            this.ChartStyle.marginLeftRight +
            "," +
            this.ChartStyle.marginTopBottom +
            ")"
        );
    });

    // this area to override the lineChart overflow x;yAxis
    if (Array.isArray(this.availableLineData)) {
      this.availableLineData.map((line) => {
        let minX = line.x(new Date(line.x.domain()[0]));
        let maxX = line.x(new Date(line.x.domain()[1]));
        let minY = line.y(line.y.domain()[0]);
        let maxY = line.y(line.y.domain()[1]);

        const zizacData = [];
        line.data?.forEach((item, index) => {
          zizacData.push(item);
          if (index < line.data.length - 1)
            zizacData.push({
              time: line.data[index + 1].time,
              value: item.value,
            });
        });

        const lineChartObj = d3
          .line()
          .x(function (d) {
            return line.x(new Date(d?.time));
          })
          .y(function (d) {
            return line.y(d?.value);
          })
          .curve(d3.curveMonotoneX);
        

        lineCharts
          .append("path")
          .attr("clip-path", "url(#clip)")
          .attr(
            "transform",
            "translate(" +
              this.ChartStyle.marginLeftRight +
              ", " +
              this.ChartStyle.marginTopBottom +
              ")"
          )
          .attr("class", "lines-chart")
          .attr("stroke", line.color)
          .style("fill", "none")
          .style("stroke-width", "2")
          .datum(zizacData)
          .attr("d", lineChartObj);
        let renderData = line.data.filter((item) => {
          let PointXlocation = line.x(new Date(item?.time));
          let PointYlocation = line.y(item?.value);
          if (
            PointXlocation >= minX &&
            PointXlocation <= maxX &&
            PointYlocation >= minY &&
            PointYlocation <= maxY
          ) {
            return true;
          }
          return false;
        });
        renderData?.forEach((item) => {
          if (item) {
            let pointx =
              line.x(new Date(item?.time)) +
              this.ChartStyle.marginLeftRight;
            let pointy =
              line.y(item?.value) + this.ChartStyle.marginTopBottom;
            lineCharts
              .append("circle")
              .attr("r", this.LINE_POINT)
              .attr("fill", line.color)
              .style("opacity", "0")
              .attr("class", "hight-line-point")
              .attr("transform", "translate(" + pointx + "," + pointy + ")")
              .on("mousemove", () => {
                this.handleMouseMove([pointx, pointy], {
                  data: item,
                  key: line.key,
                });
              });
          }
        });
      })
    }
  }

  componentDidMount = () => {
    this.setExtraData();
    super.componentDidMount();
  };
  /**
   * render
   *
   * @return  {Component}
   */
  render() {
    return super.render();
  }
}

export default SplitFailure;
