import { Button, Modal, PageHeader, Spin, Tabs } from "antd";
import SelectIntersectionTab from "components/SelectIntersectionTab";
import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import AvailableIntersectionStore from "stores/AvailableIntersectionStore";
import SelectIntersectionStore from "stores/SelectIntersectionStore";
import { ACTIONS_LIST, RESOURCE_LIST } from "utils/constants";
import helper from "utils/helper";
import ReportSchedule from "./ReportSchedule";
import ReportTemplateDetail from "./ReportTemplateDetail";

const { confirm } = Modal;
const { TabPane } = Tabs;
const tabIndex = {
  firstTab: "1",
  secondTab: "2",
  thirdTab: "3",
};
const pathApi = "summary-report/templates";
const moduleNameConstant = "REPORT_TEMPLATE";

/**
 * Component for showing details of the user.
 *
 * @component
 */
@inject("rootStore")
@observer
class ReportTemplateDetailPage extends Component {
  state = {
    loading: false,
  };

  constructor(props) {
    super(props);
    this.selectIntersectionStore = new SelectIntersectionStore(
      props.match?.params?.id,
      "summary-report/templates",
      props.rootStore
    );
    this.addMoreIntersectionStore = new AvailableIntersectionStore(
      props.rootStore,
      pathApi,
      moduleNameConstant
    );
  }

  reportDetail = null;
  reportSchedule = null;
  reportIntersection = null;

  componentDidMount() {
    const {
      match: { params },
      rootStore: {
        reportTemplateStore: {
          changeReportTemplateTab,
          getTemplateDetail,
          setTemplateId,
          getTemplatePopupTrend,
        },
      },
    } = this.props;
    const cloneId = helper.getQueryString(location.search, "cloneid");
    this.setState({
      loading: true,
    });
    getTemplatePopupTrend(() => {
      if (cloneId) {
        getTemplateDetail(cloneId, () => {
          this.setState({ loading: false });
        });
      } else {
        if (params?.id) {
          setTemplateId(params.id);
          getTemplateDetail(params.id, () => {
            this.setState({ loading: false });
          });
        } else {
          setTemplateId(null);
          this.setState({ loading: false });
        }
      }
      changeReportTemplateTab(tabIndex.firstTab);
    });
  }

  renderHeaderAction = () => {
    const {
      t,
      history,
      rootStore: {
        reportTemplateStore: { currentTab, context_id },
        myStore,
      },
    } = this.props;
    if (context_id)
      return (
        currentTab !== tabIndex.secondTab && (
          <div className="header-function">
            <Button
              onClick={() => {
                history.push("/report/template/summary-report");
                // history.goBack();
              }}>
              {t("alarm_rules.cancel")}
            </Button>
            {myStore.hasPermission(
              ACTIONS_LIST.EDIT,
              RESOURCE_LIST.REPORT_TEMPLATE_DETAIL,
              true
            ) && (
              <Button
                onClick={() => {
                  if (currentTab === tabIndex.firstTab)
                    this.reportDetail?.handleUpdate();
                  else {
                    this.reportSchedule.handleUpdate();
                  }
                }}>
                {t("alarm_rules.save")}
              </Button>
            )}
          </div>
        )
      );
    else
      return (
        <div className="header-function">
          <Button onClick={this.goToPrevStep}>{t("alarm_rules.back")}</Button>
          <Button onClick={this.goToNextStep}>
            {currentTab === tabIndex.thirdTab
              ? t("alarm_rules.save")
              : t("alarm_rules.next_step")}
          </Button>
        </div>
      );
  };
  goToNextStep = () => {
    const {
      history,
      rootStore: {
        reportTemplateStore: {
          currentTab,
          changeReportTemplateTab,
          setIntersectionList,
          submit,
          formData,
        },
      },
    } = this.props;
    if (currentTab === tabIndex.firstTab) this.reportDetail?.handleNext();
    else if (currentTab === tabIndex.secondTab) {
      setIntersectionList(
        this.selectIntersectionStore?.scope,
        this.selectIntersectionStore?.listData
      );
      changeReportTemplateTab(tabIndex.thirdTab);
    } else {
      this.reportSchedule?.handleNext(() => {
        submit(
          () => {
            history.push("/report/template/summary-report");
          },
          (error) => {
            changeReportTemplateTab(tabIndex.firstTab);
            this.reportDetail?.setFormError(error);
          }
        );
      });
    }
    if (currentTab === tabIndex.firstTab) {
      this.addMoreIntersectionStore.handleFilterDataReset({
        agency_id: formData?.agency_id,
      });
    }
  };

  componentWillUnmount() {
    const {
      rootStore: { reportTemplateStore },
    } = this.props;
    reportTemplateStore.resetForm();
  }

  goToPrevStep = () => {
    const {
      history,
      rootStore: {
        reportTemplateStore: { currentTab, changeReportTemplateTab },
      },
    } = this.props;

    if (currentTab === tabIndex.firstTab) {
      history.push("/report/template/summary-report");
    } else if (currentTab === tabIndex.secondTab) {
      changeReportTemplateTab(tabIndex.firstTab);
    } else {
      changeReportTemplateTab(tabIndex.secondTab);
    }
  };
  handleChangeTab = (key) => {
    const reportDetail = this.reportDetail;
    const {
      t,
      rootStore: {
        reportTemplateStore: {
          context_id,
          currentTab,
          changeReportTemplateTab,
          isEditFormDirty,
          getTemplateDetail,
        },
      },
    } = this.props;
    if (key !== currentTab) {
      if (currentTab !== tabIndex.secondTab && isEditFormDirty) {
        confirm({
          title: t("common.confirm_redirect_title"),
          content: t("common.confirm_redirect_content"),
          okText: t("common.leave"),
          okType: "danger",
          cancelText: t("common.stay"),
          onOk() {
            reportDetail?.props?.form?.resetFields();
            getTemplateDetail(context_id, changeReportTemplateTab(key));
          },
        });
      } else {
        changeReportTemplateTab(key);
      }
    }
  };

  render() {
    const {
      t,
      match: { params },
      rootStore: {
        reportTemplateStore: { currentTab, context_id, formData },
      },
    } = this.props;

    return this.state.loading ? (
      <div className="w-full h-full flex items-center justify-center">
        <Spin size="large" spinning/>
      </div>
    ) : (
      <div className="wrap-alarm-rule-detail-page">
        <PageHeader
          title={
            context_id
              ? t("report_template.edit_template_title")
              : t("report_template.create_template_title")
          }
          extra={this.renderHeaderAction()}>
          <div className={!context_id ? "template-report-create" : ""}>
            <Tabs
              className="edit-rule-tab"
              defaultActiveKey={tabIndex.secondTab}
              type="card"
              activeKey={currentTab}
              onTabClick={context_id && this.handleChangeTab}>
              <TabPane
                forceRender={false}
                tab="General"
                key={tabIndex.firstTab}
                disabled={
                  currentTab === tabIndex.firstTab || context_id ? false : true
                }
                style={{ color: "red" }}>
                <ReportTemplateDetail
                  childRef={(ref) => (this.reportDetail = ref)}
                  selectedIntersectionStore={this.selectIntersectionStore}
                  id={params?.id}></ReportTemplateDetail>
              </TabPane>
              <TabPane
                tab="Intersections"
                key={tabIndex.secondTab}
                disabled={
                  currentTab === tabIndex.secondTab || context_id ? false : true
                }>
                <SelectIntersectionTab
                  addMoreIntersectionStore={this.addMoreIntersectionStore}
                  context_id={context_id}
                  idFieldName={"template_id"}
                  agency_id={formData.agency_id}
                  moduleName={"report_template"}
                  resourceList={{
                    main: "REPORT_TEMPLATE",
                    detail: "REPORT_TEMPLATE_DETAIL",
                    detailsIntersection: "REPORT_TEMPLATE_DETAIL_INTERSECTION",
                  }}
                  childRef={(ref) => (this.reportIntersection = ref)}
                  currentStore={this.selectIntersectionStore}
                  pathApi="summary-report/templates"></SelectIntersectionTab>
              </TabPane>
              <TabPane
                forceRender={false}
                tab="Schedule"
                key={tabIndex.thirdTab}
                disabled={
                  currentTab === tabIndex.thirdTab || context_id ? false : true
                }>
                <ReportSchedule
                  childRef={(ref) =>
                    (this.reportSchedule = ref)
                  }></ReportSchedule>
              </TabPane>
            </Tabs>
          </div>
        </PageHeader>
      </div>
    );
  }
}

export default withRouter(withTranslation()(ReportTemplateDetailPage));
