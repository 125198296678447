import React from "react";
import { inject } from "mobx-react";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import { Spin } from "antd";
import "./style.scss";
/**
 * Component for Application layout
 *
 * @component
 */
@inject("rootStore")
class PublicLayout extends React.Component {
  /**
   * contructor of component
   * @param   {object} props  props of component
   */
  constructor(props) {
    super(props);
    this.state = {
      isChecking: true,
    };
  }
  /**
   * componentDidMount
   *
   * auto redirect to home page for authenticated request
   *
   */
  componentDidMount() {
    const {
      rootStore: { authStore },
      history,
    } = this.props;
    authStore?.checkCurrentCookieAuthentication().then((isOk) => {
      if (isOk) {
        history.push("/");
      } else {
        this.setState({ isChecking: false });
      }
    });
  }

  /**
   * render
   *
   * @return  {Component}
   */
  render() {
    return this.state.isChecking ? (
      <Spin className={"fullscreen centralize"} size="large" />
    ) : (
      <div className="pubic-form-layout fullscreen">{this.props?.children}</div>
    );
  }
}

export default withRouter(withTranslation()(PublicLayout));
