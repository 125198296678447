import React, { Component } from "react";
import { Result, Button } from "antd";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import { inject } from "mobx-react";
@inject("rootStore")
class Unauthorized extends Component {
  render() {
    const {
      t,
      history,
      rootStore: { commonStore },
    } = this.props;
    return (
      <Result
        status="403"
        title="403"
        subTitle={t("message.403")}
        extra={
          commonStore.isFromStatic ? (
            <Button type="primary" onClick={() => history.push("/analysis")}>
              {t("common.analysis")}
            </Button>
          ) : (
            <Button type="primary" onClick={() => history.push("/")}>
              {t("common.back_home")}
            </Button>
          )
        }
      />
    );
  }
}

export default withRouter(withTranslation()(Unauthorized));
