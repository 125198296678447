import { uniq } from "lodash";
import moment from "moment";
import { ANALYSIS_TYPE, GRAPHIC_CHART_TYPE, OFFSET_OPTIMIZER_RANDOM_COLOR, PATTERNS, } from "utils/constants";

export const tranferAppoarchDelayData = (rootChartData, currentType) => {
  let allChartData = [];
  rootChartData?.charts?.forEach((e) => {
    if (e.chart_type === currentType)
      allChartData.push({
        ...e,
        phase:
          currentType === ANALYSIS_TYPE.APPOARCH_DELAY_LANE_VIEW
            ? `P${e.phase}-L${e.lane_no}-${e.movement}`
            : e.phase,
        rootPhase: e.phase,
        rootLane: e.lane_no,
      });
  });
  return allChartData;
};

export const tranferQueueLengthData = (rootChartData, currentType) => {
  let allChartData = [];
  rootChartData?.charts?.forEach((e) => {
    if (e.chart_type === currentType)
      allChartData.push({
        ...e,
        phase:
          currentType === ANALYSIS_TYPE.QUEUE_LENGTH_LANE_VIEW
            ? `P${e.phase}-L${e.lane_no}-${e.movement}`
            : e.phase,
        rootPhase: e.phase,
        rootLane: e.lane_no,
      });
  });
  return allChartData;
};
export const tranferVolumeData = (rootChartData, compareChart = false) => {
  let allChartData = [];
  rootChartData?.charts?.forEach((phase) => {
    let pushObject = {
      from_time: phase.from_time,
      to_time: phase.to_time,
      phase:
        phase.chart_type === ANALYSIS_TYPE.VOLUME_ALL_PHASE
          ? "All"
          : phase.phase,
      avg_per_hour: phase.avg_per_hour,
      avg_per_day: phase.avg_per_day,
      plan_statistics: [],
      all_legend: [],
      charts_data: [],
    };

    phase.days.forEach((dataDay, indexDay) => {
      let legendKey = compareChart
        ? `${phase.phase}_compare_${dataDay.date}`
        : `${phase.phase}_${dataDay.date}`;

      pushObject.all_legend.push({
        key: legendKey,
        label: "Volume - " + dataDay.date,
        color: OFFSET_OPTIMIZER_RANDOM_COLOR[indexDay],
        unit: "",
        yAxis: "left",
        toolTip: (value) => parseFloat(value).toFixed(2) + " VPH",
        type: GRAPHIC_CHART_TYPE.line,
      });

      dataDay.charts_data.forEach((dataPoint, indexData) => {
        if (!pushObject.charts_data[indexData])
          pushObject.charts_data[indexData] = {
            from_time: dataPoint.from_time,
            to_time: dataPoint.to_time,
          };
        pushObject.charts_data[indexData][legendKey] = dataPoint.volume;
      });
    });
    allChartData.push(pushObject);
  });
  return allChartData;
};

export const tranferTurningMovementData = (rootChartData) => {
  let allChartData = [];
  rootChartData?.charts?.forEach((e) => {
    const listAllMovement = Object.keys(e.movements);
    allChartData.push({
      ...e,
      phase: `${e.direction} - Phase ${e.phase_numbers?.join(", ")}`,
      bins: e.bins.map((bin) => {
        let tranferBinData = {
          from_time: bin.from_time,
          to_time: bin.to_time,
          total: bin.total,
        };
        listAllMovement.forEach((key) => {
          tranferBinData[key] = 0;
        });

        if (bin.turning_per_movement) {
          tranferBinData = {
            ...tranferBinData,
            ...bin.turning_per_movement,
          };
        }

        return tranferBinData;
      }),
    });
  });
  return allChartData;
};

export const tranferRLVCountData = (rootChartData) => {
  let allChartData = [];
  rootChartData?.charts?.forEach((e) => {
    allChartData.push({
      ...e,
      phase: `${e.direction}`,
      bins: e.bins.map((bin) => {
        return {
          from_time: bin.from_time,
          to_time: bin.to_time,
          violation_count: bin.violation_count,
        };
      }),
    });
  });
  return allChartData;
};

export const tranferPreemptPriorityRequestData = (rootChartData, FEtype) => {
  let allChartData = [];

  switch (FEtype) {
    case ANALYSIS_TYPE.PREEMPTION_PRIORITY_REQUEST:
      rootChartData?.charts?.forEach((chart) => {
        if (chart.chart_type === ANALYSIS_TYPE.PREEMPTION_PRIORITY_REQUEST) {
          allChartData.push({
            from_time: chart.from_time,
            to_time: chart.to_time,
            preempt_requests: chart.preempt_requests.map((e) => ({
              time: e.event_time,
              number: e.number,
            })),
            tsp_check_in_list: [],
            phase: "Preempt Request",
            plan_statistics: chart.plan_statistics.map((plan) => {
              const startTime = moment(plan.from_time).valueOf();
              const endTime = moment(plan.to_time).valueOf();

              const numOfPreemptRequest = chart.preempt_requests.filter(
                (item) => {
                  const itemTime = moment(item.event_time).valueOf();
                  return startTime <= itemTime && itemTime <= endTime;
                }
              )?.length;

              return {
                ...plan,
                num_of_tsp_checklist: null,
                num_of_preempt_request: numOfPreemptRequest,
              };
            }),
          });

          allChartData.push({
            from_time: chart.from_time,
            to_time: chart.to_time,
            preempt_requests: [],
            tsp_check_in_list: chart.tsp_check_in_list.map((e) => ({
              time: e.event_time,
              number: e.number,
            })),
            phase: "TSP Request",
            plan_statistics: chart.plan_statistics.map((plan) => {
              const startTime = moment(plan.from_time).valueOf();
              const endTime = moment(plan.to_time).valueOf();

              const numOfTSPCheckList = chart.tsp_check_in_list.filter(
                (item) => {
                  const itemTime = moment(item.event_time).valueOf();
                  return startTime <= itemTime && itemTime <= endTime;
                }
              )?.length;

              return {
                ...plan,
                num_of_tsp_checklist: numOfTSPCheckList,
                num_of_preempt_request: null,
              };
            }),
          });
        }
      });
      break;
    case ANALYSIS_TYPE.PREEMPTION_PRIORITY_DETAIL:
      const listPriorityDetailChart = rootChartData?.charts?.filter(
        (chart) => chart.chart_type === ANALYSIS_TYPE.PREEMPTION_PRIORITY_DETAIL
      );

      listPriorityDetailChart?.forEach((chart) => {
        const pushChart = {
          ...chart,
          from_time: rootChartData.from_time,
          to_time: rootChartData.to_time,
          phase: chart.number,
          plan_statistics: [],

          d_well_time: [],
          track_clear_interval: [],
          entry_delay: [],

          track_clear_time: [],
          max_out_time: [],
          entry_start_time: [],
          exit_time: [],
          exit_time_point: [],
          input_off_times: [],
        };

        chart.cycles.forEach((cycle) => {
          const inputOnTime = moment(cycle.input_on_times?.[0]).valueOf();

          pushChart.track_clear_interval.push({
            time: inputOnTime,
            number: cycle.track_clear_interval,
            value: cycle.track_clear_interval + cycle.entry_delay_duration,
          });
          // pushChart.d_well_time.push({
          //   time: cycle.entry_start_time,
          //   number: cycle.d_well_duration,
          //   value:
          //     cycle.d_well_duration +
          //     cycle.entry_delay_duration +
          //     cycle.track_clear_interval,
          // });
          //105
          pushChart.entry_delay.push({
            time: inputOnTime,
            timeValue: cycle.entry_start_time,
            isMissingSomeEvent: false,
            value:
              (moment(cycle.entry_start_time).valueOf() - inputOnTime) / 1000,
            number:
              (moment(cycle.entry_start_time).valueOf() - inputOnTime) / 1000,
          });

          //106
          if (cycle.track_clear_time)
            pushChart.track_clear_time.push({
              time: inputOnTime,
              timeValue: cycle.track_clear_time,

              isMissingSomeEvent: !cycle.entry_start_time,
              value:
                (moment(cycle.track_clear_time).valueOf() - inputOnTime) / 1000,
              number:
                (moment(cycle.track_clear_time).valueOf() -
                  moment(cycle.entry_start_time).valueOf()) /
                1000,
            });

          //107
          if (cycle.d_well_time)
            pushChart.d_well_time.push({
              time: inputOnTime,
              timeValue: cycle.d_well_time,

              isMissingSomeEvent: !cycle.track_clear_time,
              value: (moment(cycle.d_well_time).valueOf() - inputOnTime) / 1000,
              number:
                (moment(cycle.d_well_time).valueOf() -
                  moment(
                    cycle.track_clear_time
                      ? cycle.track_clear_time
                      : cycle.d_well_time
                  ).valueOf()) /
                1000,
            });

          //111
          if (cycle.exit_time)
            pushChart.exit_time.push({
              isMissingSomeEvent: false,
              time: inputOnTime,
              timeValue: cycle.exit_time,

              value: (moment(cycle.exit_time).valueOf() - inputOnTime) / 1000,
              number:
                (moment(cycle.exit_time).valueOf() -
                  moment(cycle.d_well_time).valueOf()) /
                1000,
            });
          //111
          if (cycle.exit_time_point)
            pushChart.exit_time.push({
              isMissingSomeEvent: !cycle.max_out_time || !cycle.d_well_time,
              time: inputOnTime,
              timeValue: cycle.exit_time,

              value: (moment(cycle.exit_time).valueOf() - inputOnTime) / 1000,
              number:
                (moment(cycle.exit_time).valueOf() -
                  moment(cycle.d_well_time).valueOf()) /
                1000,
            });
          //110
          if (cycle.max_out_time)
            pushChart.max_out_time.push({
              time: cycle.max_out_time,
              value:
                (moment(cycle.max_out_time).valueOf() - entryStartTime) / 1000,
              number:
                (moment(cycle.max_out_time).valueOf() - entryStartTime) / 1000,
            });

          //104
          if (cycle.input_off_times)
            cycle.input_off_times.forEach((input_off) => {
              pushChart.input_off_times.push({
                isMissingSomeEvent: false,
                time: inputOnTime,
                timeValue: input_off,

                value: (moment(input_off).valueOf() - inputOnTime) / 1000,
                number: (moment(input_off).valueOf() - inputOnTime) / 1000,
              });
            });
        });

        allChartData.push(pushChart);
      });
      break;

    case ANALYSIS_TYPE.PREEMPTION_PRIORITY_TSP_DETAIL:
      const listTSPDetailChart = rootChartData?.charts?.filter(
        (chart) =>
          chart.chart_type === ANALYSIS_TYPE.PREEMPTION_PRIORITY_TSP_DETAIL
      );

      listTSPDetailChart?.forEach((chart, index) => {
        const pushChart = {
          ...chart,
          from_time: rootChartData.from_time,
          to_time: rootChartData.to_time,
          phase: index + 1,
          plan_statistics: [],

          tsp_check_in: [],
          adj_early_green: [],
          adj_extend_green: [],
          tsp_check_out: [],
        };

        chart.cycles.forEach((cycle) => {
          const tsp_check_in = moment(cycle.tsp_check_in).valueOf();

          pushChart.tsp_check_in.push({
            time: cycle.tsp_check_in,
            number: 0,
          });
          if (cycle.adj_early_green)
            pushChart.adj_early_green.push({
              time: cycle.adj_early_green,
              number:
                (moment(cycle.adj_early_green).valueOf() - tsp_check_in) / 1000,
            });
          if (cycle.adj_extend_green)
            pushChart.adj_extend_green.push({
              time: cycle.adj_extend_green,
              number:
                (moment(cycle.adj_extend_green).valueOf() - tsp_check_in) /
                1000,
            });
          if (cycle.tsp_check_out)
            pushChart.tsp_check_out.push({
              time: cycle.tsp_check_out,
              number:
                (moment(cycle.tsp_check_out).valueOf() - tsp_check_in) / 1000,
            });
        });

        allChartData.push(pushChart);
      });
      break;
    default:
      break;
  }
  return allChartData;
};

export const transferMOEData = (rootChartData, currentType) => {
  let allChartData = [];
  let allPattern = [];

  switch (currentType) {
    case ANALYSIS_TYPE.MOE_DETAIL_VIEW: {
      const detailView = rootChartData?.charts?.[0]?.detail_view;

      const phases = uniq(detailView
        .map((cycle) => cycle.phase_cycle)
        .flatMap((phase_cycle) => phase_cycle)
        .map((phase_cycle) => phase_cycle.phase)
      );

      const allPhaseApproach = rootChartData?.charts?.[0]?.approach;

      detailView?.forEach((data) => {
        const allPhase = {};
        phases.forEach((phase) => {
          const phaseData = data.phase_cycle.find((p) => p.phase === phase);
          if (phaseData) allPhase[`phase_${phase}`] = phaseData;
          else
            allPhase[`phase_${phase}`] = {
              status: [],
              phase: phase,
              actual_length: null,
              programmed_length: null,
            };
        });

        allChartData.push({
          ...data,
          ...allPhase,
          phase_cycle: phases.map((phaseNumber) => {
            const phaseData = data.phase_cycle.find(
              (p) => p.phase === phaseNumber
            );
            if (phaseData)
              return {
                ...phaseData,
                approach: allPhaseApproach[`Phase ${phaseNumber}`],
              };
            else
              return {
                status: [],
                phase: phaseNumber,
                actual_length: null,
                programmed_length: null,
                approach: allPhaseApproach[`Phase ${phaseNumber}`],
              };
          }),
        });
      });

      allPattern = uniq(allChartData.map((item) => item.pattern));

      break;
    }

    case ANALYSIS_TYPE.MOE_SUMMARY: {
      const summaryView = rootChartData?.charts?.[0]?.summary_view;
      const allPhaseAppoarch = rootChartData?.charts?.[0]?.approach;

      allChartData = { ...summaryView };
      allPattern = Object.keys(allChartData);
      allPattern.forEach((pattern) => {
        const listPhase = allChartData[pattern].phase_summary;
        Object.keys(listPhase).forEach((p) => {
          allChartData[pattern].phase_summary[p].approach = allPhaseAppoarch[p];
        });
      });

      // Remove the -1 in the pattern list after the data is prepared for "ALL PATTERNs(-1)"
      allPattern = allPattern.filter((item) => item !== String(PATTERNS.CODE_FOR_ALL));

      break;
    }

    default:
      break;
  }

  return {
    allChartData,
    allPattern,
  };
};
