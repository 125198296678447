import { Radio, Table } from "antd";
import moment from "moment";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { DATE_FORMAT } from "utils/constants/index";
import { toFixed2Offset } from "utils/helper";

class TableRLV extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPercentage: true,
    };
  }

  getTableColumns = () => {
    const { dataSource } = this.props;
    return [
      {
        title: "Time",
        key: "time",
        width: 120,
        render: (_data, record) =>
          `${moment(record.from_time).format(DATE_FORMAT.hour)}-${moment(
            record.to_time
          ).format(DATE_FORMAT.hour)}`,
        dataIndex: "total",
        className: "text-left",
      },

      this.state.showPercentage
        ? {
            title: "Total Violation Count",
            className: "text-left",
            width: 96,
            key: "violation_count",
            render: (data) => (
              <b>
                {data
                  ? `${toFixed2Offset(
                      (100 * data) / dataSource.total_violation_count
                    )} `
                  : 0}
                {" %"}
              </b>
            ),
            dataIndex: "violation_count",
          }
        : {
            title: "Total Violation Count",
            className: "text-left",
            width: 96,
            key: "violation_count",
            render: (data) => <b>{data}</b>,
            dataIndex: "violation_count",
          },
    ];
  };
  render() {
    const { dataSource, pageSize, t } = this.props;

    return (
      <>
        <div className=" table-percentage-mode">
          <Radio.Group
            value={this.state.showPercentage}
            onChange={(e) => {
              // console.log(value);
              this.setState({
                showPercentage: e.target.value,
              });
            }}>
            <Radio value={true}>{t("analysis.percentage")}</Radio>
            <Radio value={false}>
              {t("alarm_helper.table_unit_raw_count")}
            </Radio>
          </Radio.Group>
        </div>
        <Table
          className="spm-table"
          columns={this.getTableColumns()}
          dataSource={dataSource?.bins}
          rowKey={"from_time"}
          pagination={{
            pageSize: pageSize,
          }}
        />
      </>
    );
  }
}

export default withTranslation()(TableRLV);
