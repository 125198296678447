import React, { createRef, Component } from "react";
import * as d3 from "d3";
import { ANALYSIS_PLAN_INFO } from "utils/constants";
import moment from "moment";
import "./style.scss";

const LABEL_HEIGHT = 12;
const PlanStyle = {
  minWith: 10, //minwith for small mode
  maxWith: 100, //maxwith for medium mode
}; // the with style of plan for check mode
const PlanInfoStyle = {
  dotRadius: 5, //radius of the dot
  labelMarginTop: 12, //space bettween text info inside box
}; // the style for plan info boxf
const PLAN_MODE = {
  SMALL: 0,
  MEDIUM: 1,
  LARGE: 2,
}; //plant display mode

class ChartPlan extends Component {
  constructor(props) {
    super(props);
    this.planRef = createRef();
    this.axisObject = {};
    this.scaleObject = {};
    this.newX = {};
    this.planObject = {};
    this.planData = {};
    this.fullHeight = 0;
    this.fullWidth = 0;
  }
  ChartStyle = {
    marginLeftRight: 40, //plan name mả
    marginTopBottom: 0,
  }; //style for axis object
  /**
   * getPlanMode
   * We support 3 mode of display plan info base on the with of plan
   *
   * @param {float} widthPlan width of target Plan
   * @return  {PLAN_MODE}
   */
  getPlanMode = (widthPlan) => {
    if (widthPlan < PlanStyle.minWith) {
      return PLAN_MODE.SMALL;
    }
    if (widthPlan > PlanStyle.maxWith) {
      return PLAN_MODE.LARGE;
    }
    return PLAN_MODE.MEDIUM;
  };

  /**
   * drawPlanInfo
   * Render plan info box base on location and data of current plan
   *
   * @param {object} target The object wanna add plan info box in
   * @param {float} x The x posision wanna draw box
   * @param {float} y The y posision wanna draw box
   * @param {float} height The height of plan info box
   * @param {object} planData data wanna show up on info box
   * @param {String} customClassName custom class name of plan info box
   * @return  {PLAN_MODE}
   */
  drawPlanInfo = (target, x, y, height, planData, customClassName = null) => {
    const { chartType, isPrintMode } = this.props;
    //get chart type to defined which field of planData will be show up
    //check more at ANALYSIS_PLAN_INFO in constant file

    //wrap box in g tag
    let planInfoBox = target
      .append("g")
      .attr("class", customClassName ? customClassName : "plan-info-box");
    //draw the box
    planInfoBox
      .append("rect")
      .attr("width", PlanStyle.maxWith) //hard code the width of box
      .attr("height", height)
      .attr(
        "transform",
        "translate(" + (x - PlanStyle.maxWith / 2) + "," + y + ")"
      ) //move box to center of x/y
      .attr("stroke", isPrintMode ? "#606060" : "var(--text)")
      .attr("fill", isPrintMode ? "#fff" : "var(--background)");

    //addChar Info
    //only genrate vaild data to chart
    let availablePlanInfo = ANALYSIS_PLAN_INFO[chartType]?.filter((item) => {
      let value = planData[item.key];
      return value !== undefined && value != null;
    });
    if (Array.isArray(availablePlanInfo)) {
      //get total availbale info to centerlize all text in box
      const totalAvailabelInfo = availablePlanInfo.length;
      let labelHeight = height / totalAvailabelInfo; //hieght of each line
      //calculate the y position of first text in box
      if (totalAvailabelInfo === 1) {
        labelHeight = labelHeight / 2;
      }
      let marginTop = labelHeight + y;
      //start rennder tex and move inside box
      availablePlanInfo.map((item) => {
        planInfoBox
          .append("text")
          .text(item.label(planData)) //the way we show label defined on ANALYSIS_PLAN_INFO in constant file
          .attr("text-anchor", "middle")
          .attr("class", "plan-info-text")
          .attr("transform", "translate(" + x + "," + marginTop + ")")
          .attr("fill", item.color);
        //add more space for next item
        marginTop = marginTop + PlanInfoStyle.labelMarginTop;
      });
    }
  };
  /**
   * handlePlanInfoDotOut
   * When remove out of the info dot - we clear current plan info box
   * Note: this happend only for plan with mode MEDIUM
   * @return  {Null}
   */
  handlePlanInfoDotOut = () => {
    if (this.planObject) {
      //remove current active plan info box
      this.planObject.select(".active-plan-info-box")?.remove();
    }
  };

  /**
   * handlePlanInfoDotHover
   * When remove into the info dot - we show info box
   * Note:
   * this happend only for plan with mode MEDIUM
   * we using className as a key to find the planInfo box show by this way
   *
   * @param {object} plan data wanna show up on info box
   * @param {float} x The x posision wanna draw box
   * @param {float} y The y posision wanna draw box
   * @param {float} height The height of plan info box
   * @param {String} customClassName custom class name of plan info box
   * @return  {Null}
   */
  handlePlanInfoDotHover = (plan, x, y, height) => {
    const { compareMode } = this.props;

    //find the root object too add plan info box
    if (this.planObject) {
      //first remove current active plan info box
      //incase the moveout function do not fired
      this.planObject.select(".active-plan-info-box")?.remove();
      //start draw plan info box
      this.drawPlanInfo(
        this.planObject,
        x,
        compareMode
          ? y - height - PlanInfoStyle.labelMarginTop
          : y + PlanInfoStyle.labelMarginTop,
        height,
        plan,
        "active-plan-info-box"
      );
    }
  };
  renderAxis = () => {
    const { from_time, to_time, data, compareMode } = this.props;
    //general Info
    const fromTime = new Date(from_time)?.getTime();
    const toTime = new Date(to_time)?.getTime();
    //Draw Chart Plan
    //draw xAxisObject
    //get plan object from dom
    const planData = data?.plan_statistics;
    this.planData = planData;
    if (Array.isArray(planData) && this.planObject) {
      //calculate the witdh of xAxist bar base on the scren size
      this.fullWidth = parseFloat(
        window.getComputedStyle(this.planObject.node())?.width
      );
      this.ChartStyle.marginLeftRight = (this.fullWidth * 0.1) / 2;
      const witdh = this.fullWidth * 0.9;
      //calculate the height of xAxist bar base on the scren size
      this.fullHeight = parseFloat(
        window.getComputedStyle(this.planObject.node())?.height
      );

      this.ChartStyle.marginTopBottom = (this.fullHeight * 0.1) / 2;
      const height = this.fullHeight * 0.9;
      //create the scaleObject - this one is important cuz we use this to calculate position base on time(draw plan info)
      this.scaleObject = d3
        .scaleTime()
        .domain([fromTime, toTime])
        .range([0, witdh]);
      this.newX = this.scaleObject;
      //create axisObject
      let axisObject = d3.axisBottom(this.scaleObject);
      if (compareMode) {
        axisObject = d3.axisTop(this.scaleObject);
      }
      axisObject
        .tickValues(planData?.map((e) => new Date(e.to_time))) //tick axis bar by our end time
        .tickFormat(""); //remove label of axis
      //draw axis line
      this.axisObject = this.planObject
        .append("g")
        .attr("class", "axis axis--x-plan")
        .attr("clip-path", "url(#clip-plan)")
        .attr(
          "transform",
          "translate(" +
            this.ChartStyle.marginLeftRight +
            "," +
            (height / 2 + this.ChartStyle.marginTopBottom) +
            ")"
        )
        .call(axisObject);
    }
  };

  renderPlanData = () => {
    const { data, compareMode } = this.props;
    //general Info
    //Draw Chart Plan
    //draw xAxisObject
    //get plan object from dom
    const planData = data?.plan_statistics;
    if (Array.isArray(planData) && this.planObject) {
      //calculate the witdh of xAxist bar base on the scren size;
      //calculate the height of xAxist bar base on the scren size
      const height = this.fullHeight * 0.9;
      //create the scaleObject - this one is important cuz we use this to calculate position base on time(draw plan info)
      //add label point
      let planInfoGroup = this.planObject
        .append("g")
        .attr("class", "plan-info-group");
      const heightPlan = height * 0.2;
      const heightInfoPlan = height * 0.8;
      const minX = moment(this.newX.domain()[0]);
      const maxX = moment(this.newX.domain()[1]);
      planData?.map((plan) => {
        let fromTime = moment(plan?.from_time);
        let toTime = moment(plan?.to_time);
        if (fromTime.isBetween(minX, maxX) || toTime.isBetween(minX, maxX)) {
          if (fromTime < minX) {
            fromTime = this.newX.domain()[0];
          } else {
            fromTime = plan?.from_time;
          }
          if (toTime > maxX) {
            toTime = this.newX.domain()[1];
          } else {
            toTime = plan?.to_time;
          }
          let xStart = this.newX(new Date(fromTime)); //get position of from time plan
          let xEnd = this.newX(new Date(toTime)); //get position of to time plan
          const widthPlan = xEnd - xStart; //calculate the plan width
          const planMode = this.getPlanMode(widthPlan); //check the display plan mode with that witdh
          const planText = planMode != PLAN_MODE.SMALL ? plan.plan : "..."; //change the text base on the plan display mode
          const centerX =
            this.ChartStyle.marginLeftRight + xStart + widthPlan / 2; //calulate position of the center of plan
          let planInfo = planInfoGroup.append("g").attr("class", "plan-info");
          //add text
          planInfo
            .append("text")
            .text(planText)
            .attr("text-anchor", "middle")
            .attr(
              "transform",
              "translate(" +
                centerX +
                "," +
                (compareMode ? height : LABEL_HEIGHT) +
                ")"
            )
            .attr("fill", "var(--text)")
            .attr("class", "plan-text");
          //add plan info base on the plan mode
          if (planMode === PLAN_MODE.MEDIUM) {
            //MEDIUM - draw the cirle point and add onhover event to show plan info
            planInfo
              .append("circle")
              .attr("r", PlanInfoStyle.dotRadius)
              .attr("class", "point-plan-tooltip")
              .attr(
                "transform",
                "translate(" +
                  centerX +
                  "," +
                  (this.ChartStyle.marginTopBottom + height / 2) +
                  ")"
              )
              .attr("fill", "var(--text)")
              .on("mouseover", () =>
                this.handlePlanInfoDotHover(
                  plan,
                  centerX,
                  height / 2,
                  heightInfoPlan
                )
              )
              .on("mouseout", this.handlePlanInfoDotOut);
          } else if (planMode === PLAN_MODE.LARGE) {
            //LARGE - draw the plan info rect
            this.drawPlanInfo(
              planInfo,
              centerX,
              compareMode
                ? heightPlan - PlanInfoStyle.labelMarginTop
                : heightPlan,
              heightInfoPlan,
              plan
            );
          }
        }
      });
    }
  };

  updateChart = (newX) => {
    const { compareMode } = this.props;
    this.newX = newX;
    const minX = moment(this.newX.domain()[0]);
    const maxX = moment(this.newX.domain()[1]);
    //create axisObject
    this.axisObject.call(
      d3
        .axisBottom(this.newX)
        .tickValues(
          this.planData
            ?.filter((e) => moment(e?.to_time).isBetween(minX, maxX))
            .map((e) => {
              return new Date(e.to_time);
            })
        )
        .tickFormat("")
    );
    if (compareMode) {
      this.axisObject.call(
        d3
          .axisTop(this.newX)
          .tickValues(
            this.planData
              ?.filter((e) => moment(e?.to_time).isBetween(minX, maxX))
              .map((e) => {
                return new Date(e.to_time);
              })
          )
          .tickFormat("")
      );
    }

    this.planObject.selectAll(".plan-info-group").remove();
    this.renderPlanData();
  };
  componentDidMount() {
    this.planObject = d3.select(this.planRef?.current);
    this.renderAxis();
    // this.renderZoomHandle();
    this.renderPlanData();
  }

  render() {
    return <svg className="chart-plan" ref={this.planRef} />;
  }
}

export default ChartPlan;
