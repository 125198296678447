import React, { Component } from "react";
import { Modal, Badge } from "antd";
import { withTranslation } from "react-i18next";
import { observer, inject } from "mobx-react";
import { Calendar, CalendarControls } from "react-yearly-calendar";
import Helper from "../../../../utils/helper";
import "./style.scss";
import moment from "moment";
@inject("rootStore")
@observer
class FullCalendarPopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      year: moment().year(),
      selectedDate: moment(),
    };
  }
  componentDidMount = () => {
    const { defaultValue } = this.props;
    this.setState({
      year: defaultValue.year(),
      selectedDate: defaultValue,
    });
  };
  onChangeYear = (year) => {
    const {
      rootStore: { analysisStore },
    } = this.props;

    analysisStore
      .getAllDateByIntersection(
        moment(String(year)).startOf("year"),
        moment(String(year)).endOf("year")
      )
      .finally(() => {
        this.setState(() => ({
          year,
        }));
      });
  };

  render() {
    const {
      t,
      onClose,
      visible,
      onCalendarSelect,
      defaultValue,
      customCSSclasses,
      selectedColorMatrix,
      renderDisableDateInCalendar,
      rootStore: { analysisStore },
    } = this.props;
    return (
      <Modal
        visible={visible}
        width={"80%"}
        title={t("calendar_popup.title")}
        onCancel={() => {
          this.setState({ selectedDate: defaultValue });
          onClose();
        }}
        cancelText={t("detail.cancel")}
        okText={t("Ok")}
        onOk={() => {
          onCalendarSelect(this.state.selectedDate);
          onClose();
        }}
        destroyOnClose={true}
      >
        <div className="heatmap-color-group">
          <span className="color-name">
            {t("calendar_popup.matrix_name")}&nbsp;:&nbsp;
            {selectedColorMatrix?.label}
          </span>
          <span className="color-items">
            {selectedColorMatrix?.color &&
              selectedColorMatrix.color.map((item) => {
                return (
                  <div key={item.color} className="color-item">
                    <Badge
                      color={item.color}
                      text={Helper.getDisplayTextColor(
                        item,
                        selectedColorMatrix.unit,
                        t
                      )}
                    />
                  </div>
                );
              })}
          </span>
        </div>
        <div className="calendar">
          <CalendarControls
            year={this.state.year}
            showTodayButton={false}
            onPrevYear={() => this.onChangeYear(this.state.year - 1)}
            onNextYear={() => {
              if (this.state.year === moment().year()) return;
              this.onChangeYear(this.state.year + 1);
            }}
          />

          <Calendar
            year={this.state.year}
            selectedDay={this.state.selectedDate}
            titles={(value) => {
              return analysisStore.getDisplayDayTitle(value);
            }}
            onPickDate={(selectedDate) => {
              if (selectedDate.isAfter(moment())) {
                return
              }
              this.setState({ selectedDate });
            }}
            customClasses={{...customCSSclasses, 'disabled-dates': (day) => (day.isAfter(moment().add(1,'days')))}}
            renderDisableDateInCalendar={renderDisableDateInCalendar}
          />
        </div>
      </Modal>
    );
  }
}

export default withTranslation()(FullCalendarPopup);
