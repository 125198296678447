import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import { Row, Col, Form, Input, Button, Modal } from "antd";
import { SYSTEM_ROLE } from "utils/constants";

import "./style.scss";
import {showNotification} from "../../../../utils/helper";

const { confirm } = Modal;
/**
 * Component for showing agency detail (or edit form)
 *
 * @component
 */
@inject("rootStore")
@observer
class GeneralTab extends Component {
  /**
   * contructor of component
   * @param   {object} props  props of component
   */
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      currentAction: props.t("agency.select_action"),
    };
    this.currentStore = props.rootStore.agencyStore;
    this.formFields = [
      "name",
      "organization",
      "address",
      "phone",
      "email",
      "status",
    ];
  }

  /**
   * checkFormDirty
   * check current form is changed data with current setting formFields
   * @return  {null}
   */

  checkFormDirty = () => {
    return this.props?.form?.isFieldsTouched(this.formFields);
  };

  /**
   * handleDrawerOnClose
   * this function will check is current data changed or not and then close the form
   * @param   {function} leave  leave function
   * @return  {null} -  call the confirm box
   */
  checkFormClearToLeave = (leave) => {
    if (this.checkFormDirty()) {
      const { t } = this.props;
      confirm({
        title: t("common.confirm_redirect_title"),
        content: t("common.confirm_redirect_content"),
        okText: t("common.leave"),
        okType: "danger",
        cancelText: t("common.stay"),
        onOk() {
          leave && leave();
        },
      });
    } else {
      leave && leave();
    }
  };

  /**
   * componentDidMount
   * assign this compenent to props - for parent comment access child function
   * in this case EditAgency component will call save function of General info tab ( cuz save button stay in EditAgency component)
   *
   * this function get detail data of agency
   * @param   {object} props  props of component
   */
  componentDidMount() {
    const { agency_id, childRef } = this.props;
    this.currentStore?.getDataById(agency_id, () => {
      this.currentStore?.getAgencyApiKey(agency_id);
    });
    childRef && childRef(this);
  }

  /**
   * handleSubmit
   * this function submit value to update data
   * @return  {Component}
   */
  handleSubmit = (e) => {
    const {t} = this.props;
    e.preventDefault();
    this.props?.form?.validateFields((err, values) => {
      if (!err) {
        this.currentStore?.saveData(
          values,
          () => {
            // this.currentStore?.getData();
            showNotification(
                "success",
                t("success"),
                t("update_success")
            );
          },
          (errors) => {
            errors?.map((error) => {
              console.log(error)
              let message = error?.message;
              let err = {};
              err[error?.field] = {
                value: values[error?.field],
                errors: [new Error(message)],
              };
              this.props?.form?.setFields(err);
              showNotification("error", t("error"), error?.message);
            });
          }
        );
      }
    });
  };

  /**
   * render
   * @return  {Component}
   */
  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      agency_id,
      t,
      rootStore: { commonStore, myStore },
    } = this.props;

    let formData = this.currentStore?.itemData;
    let formSpanStyle = 5;
    return (
      <div className="wrap-agency-detail">
        <Form onSubmit={this.handleSubmit}>
          <Row gutter={16} className="agencydetail-row">
            <Col span={formSpanStyle}>
              <Form.Item label={t("agency.id")} {...this.formItemLayout}>
                {formData?.id}
              </Form.Item>
            </Col>
            <Col span={formSpanStyle}>
              <Form.Item label={t("agency.name")} {...this.formItemLayout}>
                {commonStore?.isSuperAdmin()
                  ? getFieldDecorator("name", {
                      rules: [
                        { required: true, message: t("agency.name_required") },
                      ],
                      initialValue: formData?.name,
                    })(<Input maxLength={255} placeholder={t("agency.name")} />)
                  : formData?.name}
              </Form.Item>
            </Col>
            <Col span={formSpanStyle}>
              <Form.Item
                label={t("agency.organization")}
                {...this.formItemLayout}
              >
                {commonStore?.isSuperAdmin()
                  ? getFieldDecorator("organization", {
                      initialValue: formData?.organization,
                    })(
                      <Input
                        maxLength={255}
                        placeholder={t("agency.organization")}
                      />
                    )
                  : formData?.organization}
              </Form.Item>
            </Col>
            <Col span={formSpanStyle}>
              <Form.Item label={t("agency.address")} {...this.formItemLayout}>
                {getFieldDecorator("address", {
                  initialValue: formData?.address,
                })(<Input maxLength={255} placeholder={t("agency.address")} />)}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} className="agencydetail-row">
            <Col span={formSpanStyle}>
              <Form.Item label={t("agency.phone")} {...this.formItemLayout}>
                {getFieldDecorator("phone", {
                  initialValue: formData?.phone,
                })(
                  <Input
                    maxLength={255}
                    placeholder={t("agency.phone")}
                    autoComplete="new-phone"
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={formSpanStyle}>
              <Form.Item label={t("agency.email")} {...this.formItemLayout}>
                {getFieldDecorator("email", {
                  rules: [
                    {
                      type: "email",
                      message: t("agency.email_format"),
                    },
                    { required: false, message: t("agency.email_required") },
                  ],
                  initialValue: formData?.email,
                })(
                  <Input
                    autoComplete="new-email"
                    maxLength={255}
                    placeholder={t("agency.email_placeholder")}
                  />
                )}
              </Form.Item>
            </Col>
            {myStore?.getCurrentRoleName() !== SYSTEM_ROLE.OPERATOR && (
              <Col span={formSpanStyle}>
                <Form.Item label={t("agency.api-key")} {...this.formItemLayout}>
                  <Input.Password
                    value={this.currentStore?.currentDHApiKey}
                    type="password"
                    className="api-key-field"
                    autoComplete="new-password"
                  />
                  <Button
                    type="primary"
                    icon="reload"
                    loading={this.currentStore?.loading}
                    className="auto-reload-btn"
                    onClick={() =>
                      this.currentStore?.getAgencyApiKey(agency_id)
                    }
                  />
                </Form.Item>
              </Col>
            )}
          </Row>
        </Form>
      </div>
    );
  }
}

const GeneralTabForm = Form.create({ name: "agency" })(GeneralTab);

export default withRouter(withTranslation()(GeneralTabForm));
