import React, { Component } from "react";
import { Tag } from "antd";
import "./style.scss";
import { PHASE_ALL_KEY } from "utils/constants";

class ChartPhase extends Component {
  /**
   * handleShowHidePhase
   *
   * update the minimap range for showing chart data
   *
   */
  handleShowHidePhase = (phase) => {
    const { hiddenPhase, updateHiddenPhase } = this.props;
    let newHiddenPhase = [...hiddenPhase];

    if (newHiddenPhase?.includes(phase)) {
      newHiddenPhase = newHiddenPhase.filter((item) => item != phase);
    } else {
      newHiddenPhase.push(phase);
    }
    return updateHiddenPhase(newHiddenPhase);
  };

  render() {
    const { totalPhase, hiddenPhase } = this.props;
    return (
      <div className="wrap-chart-phase">
        {Array.isArray(totalPhase) && totalPhase[0] !== PHASE_ALL_KEY
          ? totalPhase.map((phase) => (
              <Tag
                className="mb-8"
                key={phase}
                onClick={() => this.handleShowHidePhase(phase)}
                color={hiddenPhase?.includes(phase) ? "gray" : "#009999"}
              >
                {phase}
              </Tag>
            ))
          : null}
      </div>
    );
  }
}

export default ChartPhase;
