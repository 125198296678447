import { observable, action } from "mobx";
import ThemeStyle from "../styles/Theme";
import helper from "../utils/helper";
import localStorageHelper from "../utils/localStorageHelper";
import {
  SUPPER_ADMIN_GROUP,
  DEFAULT_THEME,
  THEME_LIGHT,
  THEME_DARK,
} from "utils/constants";

class CommonStore {
  constructor(parentStore) {
    this.parentStore = parentStore;
    const theme = localStorageHelper.getLocalStorage("theme", DEFAULT_THEME);
    helper.loadTheme(
      theme === THEME_DARK ? ThemeStyle.darkTheme : ThemeStyle.lightTheme
    );
  }
  isFromStatic = false; //user access from STACTIC system
  @observable miniLoading = false; //show and hide mini loading from header
  @observable theme = DEFAULT_THEME; //theme style of applications
  @observable showUserPanel = false; //show and hide user panel on header
  @observable showNotificaionPanel = false; //show and hine notification on header

  setIsFromStatic = (value) => {
    this.isFromStatic = value;
  };
  /**
   * setVisibleUserPanel
   * this function show or hide user pannel
   * @param   {boolean} status  true/false
   * @return  {null} -
   */
  @action setVisibleUserPanel = (status) => {
    this.showUserPanel = status;
  };

  /**
   * setVisibleNotificaionPanel
   * this function show or hide notification pannel
   * @param   {boolean} status  true/false
   * @return  {null} -
   */
  @action setVisibleNotificaionPanel = (status) => {
    this.showNotificaionPanel = status;
    //if currrent page is dashboard refresh data to get updated unread noti
    if (!status && window.location.pathname === "/") {
      this.parentStore?.dashboardStore?.refreshDashboardData();
    }
  };

  /**
   * showMiniLoading
   * this function show mini loadling
   * @return  {null} -
   */
  @action showMiniLoading = () => {
    this.miniLoading = true;
  };

  /**
   * hideMiniLoading
   * this function hide mini loadling
   * @return  {null} -
   */
  @action hideMiniLoading = () => {
    this.miniLoading = false;
  };

  /**
   * getThemeStyle
   * this function return current theme style
   * @return  {string} -
   */
  getThemeStyle = () => {
    return this.theme;
  };

  /**
   * setThemeStyle
   * this function save theme to local storage- save stage of theme for next time login
   * change theme of current application
   * @param   {sting} theme  dark/light
   * @return  {null} -
   */
  @action setThemeStyle = (theme) => {
    this.theme = theme;
    const currentTheme =
      theme !== THEME_LIGHT ? ThemeStyle.darkTheme : ThemeStyle.lightTheme;
    helper.loadTheme(currentTheme);
    localStorageHelper.setLocalStorage("theme", theme);
  };

  /**
   * isSuperAdmin
   * check for current login user is super admin group or not
   * Or check given role is belong to super admin group or not
   * @param   {string} role
   * @return  {boolean} -
   */
  isSuperAdmin = (role = null) => {
    if (!role) {
      let roleName = this.parentStore.myStore?.getCurrentRoleName();
      if (roleName) {
        role = roleName;
      } else {
        return false;
      }
    }
    return SUPPER_ADMIN_GROUP.includes(role);
  };
}

export default CommonStore;
