import React, { Component } from "react";
import { Progress } from "antd";
import "./style.scss";

/**
 * Component for Mini loading bar form the top
 *
 * @component
 */
class MiniLoading extends Component {
  constructor(props) {
    super(props);
    this.state = {
      progressValue: 0
    };
    this.loadingInterval = null;
  }

  /**
   * timer
   * this function auto increse loading bar progess by time
   * every time this function trigger the progress will increse 30% of left space
   * this function trigger every 0.3 s
   * @return  {null}
   */
  timer = () => {
    let newValue = this.state.progressValue;
    let spaceLeft = 95 - newValue;
    newValue = newValue + (spaceLeft * 0.2);
    this.setState({ progressValue: newValue });
  };
  /**
   * componentWillUnmount
   * clean interval loading avoid leak mem
   * @return  {null}
   */
  componentWillUnmount() {
    clearInterval(this.loadingInterval)
    this.loadingInterval = null
  }


  /**
   * render
   *
   * @return  {Component}
   */
  render() {
    const { show } = this.props;
    if (show) {
      if (!this.loadingInterval) {
        this.loadingInterval = setInterval(this.timer, 500);
      }
    } else {
      clearInterval(this.loadingInterval)
      this.loadingInterval = null
    }
    return (
      <div className={show ? "wrap-mini-loading" : "wrap-mini-loading-unload"}>
        <div className="loading-icon">
          <Progress
            percent={this.state.progressValue}
            status="active"
            showInfo={false}
          />
        </div>
      </div>
    );
  }
}

export default MiniLoading;
