import React from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import { Route, Switch } from "react-router-dom";
import PrivateLayout from "./components/PrivateLayout";
import PublicLayout from "./components/PublicLayout";
//import Unauthorized from "./containers/StaticPages/403";
import UnAuthenticate from "./containers/StaticPages/401";
import Notfound from "./containers/StaticPages/404";
import { generalRoutes, privateRoutes, publicRoutes } from "./Routes";
import "styles/themes/spm.scss";
import { Layout } from "antd";

const { Content } = Layout;

/**
 * Component for Application layout
 *
 * @component
 */

class App extends React.Component {
  /**
   * renderRouteItem
   * this function return component of route
   * the real path should combine with parent path
   *
   * have to return all container and check permission later
   * @return  {Component}
   */
  renderRouteItem(item, parentPath = null) {
    return (
      <Route
        key={item.name}
        path={parentPath ? parentPath + item.path : item.path}
        exact={item.exact}
      >
        <Content style={{ margin: item.name === "dashboard" ? 0 : "0 12px" }}>
          <div
            style={{ height: "100%" }}
            className="wrap-page-content styled-scroll"
          >
            {/* {myStore.hasPermission(item.action, item.permission, true) ? item.container : <Unauthorized />} */}
            {item.container}
          </div>
        </Content>
      </Route>
    );
  }

  /**
   * renderListRootItems
   * this function return recusive route item base on item data
   * @return  {Component}
   */
  renderListRouteItems(item, currentPath = "") {
    //check item have child?
    if (item.childs) {
      //check flag need to render item route or not
      if (item.render_route) {
        //get component of child first
        let childItems = item.childs.map((subItem) => {
          let path = currentPath + item.path;
          if (subItem.childs) {
            return this.renderListRouteItems(subItem, path);
          } else {
            return this.renderRouteItem(subItem, path);
          }
        });
        //add compoenent of current item
        childItems.push(this.renderRouteItem(item, currentPath));
        return childItems;
      } else {
        return item.childs.map((subItem) => {
          let path = currentPath + item.path;
          if (subItem.childs) {
            return this.renderListRouteItems(subItem, path);
          } else {
            return this.renderRouteItem(subItem, path);
          }
        });
      }
    } else {
      return this.renderRouteItem(item, currentPath);
    }
  }

  /**
   * render
   * 2 type of route
   * public route: for unauthenticate page
   * private route : for authenticate required page
   * private route will go with SIWAVE layout
   *
   * @return  {Component}
   */
  render() {
    return (
      <Switch>
        <Route key="401" path="/401" exact={true}>
          <div className={"fullscreen"}>
            <UnAuthenticate />
          </div>
        </Route>
        {/* public path */}
        <Route exact path={generalRoutes.map((route) => route.path)}>
          <Switch>
            {generalRoutes.map((item) => {
              return (
                <Route key={item.name} path={item.path} exact={item.exact}>
                  {item.container}
                </Route>
              );
            })}
          </Switch>
        </Route>

        <Route path={publicRoutes.map((route) => route.path)}>
          <PublicLayout>
            <Switch>
              {publicRoutes.map((item) => {
                return (
                  <Route key={item.name} path={item.path} exact={item.exact}>
                    {item.container}
                  </Route>
                );
              })}
            </Switch>
          </PublicLayout>
        </Route>

        {/* private path */}
        <Route path={privateRoutes.map((route) => route.path)}>
          <PrivateLayout>
            <Switch>
              {privateRoutes.map((item) => {
                return this.renderListRouteItems(item);
              })}
              <Route>
                <div className={"fullscreen"}>
                  <Notfound />
                </div>
              </Route>
            </Switch>
          </PrivateLayout>
        </Route>

        <Route>
          <div className={"fullscreen"}>
            <Notfound />
          </div>
        </Route>
      </Switch>
    );
  }
}

export default withRouter(withTranslation()(App));
