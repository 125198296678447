import React, { Component } from "react";
import { ACTIVE, INACTIVE, AVAILABLE, UNAVAILABLE, UNACTIVATED} from "utils/constants";
import { Badge, Tag } from "antd";
import { withTranslation } from "react-i18next";

class StatusTag extends Component {
  render() {
    const { status, children, t } = this.props;
    let color = "green";
    let text = t("active");
    switch (status) {
      case ACTIVE:
        color = "green";
        text = t("active");
        break;
      case AVAILABLE:
        color = "green";
        text = t("available");
        break;

      case UNAVAILABLE:
        color = "volcano";
        text = t("inactive");
        break;
      case INACTIVE:
        color = "volcano";
        text = t("inactive");
        break;
      case UNACTIVATED: 
        color = "volcano";
        text = t("unactivated");
        break;

      default:
        break;
    }
    return (
      <Tag color={color}>
        <Badge color={color} />
        {children ? children : text}
      </Tag>
    );
  }
}

export default withTranslation()(StatusTag);
