import BaseApi from './BaseApi';
import Api from './Api';

class UnreadNotificationService extends BaseApi {

  constructor(parentStore) {
    super("notifications/lastest-unread", parentStore);
    this.dataKey = "notifications";
  }

  /**
   * mark all notifications as read
   */
  readAll = () => {
    return new Promise((resolve, reject) => {
      const api = new Api("notifications/as-read", this.parentStore);
      api.patchRequest({}, () => {
        resolve()
      }, (err) => {
        reject(err)
      });
    })
  }

  /**
   * update read status of a notification
   * @param   {Array<number>} ids notification id list
   * @param   {String} readStatus read status
   */
  updateReadStatus = (ids, readStatus) => {
    return new Promise((resolve, reject) => {
      const api = new Api("notifications", this.parentStore);
      api.patchRequest({ noti_ids: ids, read_status: readStatus }, () => {
        resolve()
      }, (err) => {
        reject(err)
      });
    })
  }
  /**
   * update flag status of a notification
   * @param   {Array<number>} ids notification id list
   * @param   {String} flagStatus flag status
   */
  updateFlagStatus = (ids, flagStatus) => {
    return new Promise((resolve, reject) => {
      const api = new Api("notifications", this.parentStore);
      api.patchRequest({ noti_ids: ids, flag_status: flagStatus }, () => {
        resolve()
      }, (err) => {
        reject(err)
      });
    })
  }

}

export default UnreadNotificationService;