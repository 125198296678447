import { Badge, Button, Descriptions, Radio, Tooltip } from "antd";
import { inject, observer } from "mobx-react";
import moment from "moment";
import React, { Component } from "react";
import {
  DATE_FORMAT,
  LEVEL_COLOR,
  TRAFFIC_PROFILE_CHART_MODE,
} from "utils/constants";
import { exportCSVFile } from "utils/renderExportFile";
import {
  getShortDateRangeString,
  getFullDateRangeString,
} from "../../../../utils/helper";

@inject("rootStore")
@observer
class HeaderTrafficProfile extends Component {
  constructor(props) {
    super(props);
    this._dom = React.createRef();
  }
  getProfileLegend = (num_of_profiles) => {
    const { t } = this.props;
    let arrayComponent = [];
    for (let id = 1; id <= num_of_profiles; id++) {
      arrayComponent.push(
        <Badge
          key={id}
          className="border-square mr-16"
          color={LEVEL_COLOR[id - 1]}
          text={t("optimization.profile") + " " + id}
        />
      );
    }
    return arrayComponent;
  };
  convertCoverPageToExportJsonFormat = (coverData) => {
    const { t } = this.props;
    let coverTranform = [];
    Object.keys(coverData).forEach((e) => {
      switch (e) {
        case "optimization_type": {
          coverTranform.push({
            title: t("optimization." + e),
            data: coverData[e].name,
          });
          break;
        }
        case "owner_id": {
          break;
        }
        case "optimization_type": {
          coverTranform.push({
            title: t("optimization." + e),
            data: coverData[e].name,
          });
          break;
        }
        case "owner": {
          coverTranform.push({
            title: t("optimization." + e),
            data: coverData[e].name,
          });
          break;
        }
        case "week_days": {
          coverTranform.push({
            title: t("optimization." + e),
            data: coverData[e].join(", "),
          });
          break;
        }
        default: {
          coverTranform.push({
            title: t("optimization." + e),
            data: coverData[e],
          });
        }
      }
    });
    return coverTranform;
  };
  convertDataToExportJsonFormat = (resultData) => {
    const { t } = this.props;
    let cluster_data = [];
    let days = [];
    for (let index = 0; index < resultData.length; index++) {
      cluster_data = [
        ...cluster_data,
        {
          day: resultData[index].timeline,
          data: resultData[index].cluster_data.map((e) => {
            return {
              day: "",
              cluster: e.cluster,
              from_time: e.from_time,
              to_time: e.to_time,
              ...e,
              cluster: e.cluster + 1,
            };
          }),
        },
      ];
      Object.values(resultData[index].days).forEach((day) => {
        day.forEach((k) => {
          let data = k.data_points.map((t) => ({
            Date: k.date,
            ...t,
          }));
          days = [...days, ...data];
        });
      });
    }
    return [
      {
        title: t("optimization.title_cluster_details"),
        data: cluster_data.map((block) => [
          { day: block.day },
          this.getTitleCSV(block.data[0]),
          ...block.data,
        ]),
      },
      {
        title: t("optimization.title_green-demand"),
        data: [this.getTitleCSV(days[0]), ...days],
      },
    ];
  };

  getTitleCSV = (dataRow) => {
    const { t } = this.props;
    let titleRow = {};
    if (dataRow) {
      let someElement = dataRow;
      Object.keys(someElement).forEach((e) => {
        titleRow[e] = t("optimization.title_" + e);
      });
    }
    return titleRow;
  };

  render() {
    const {
      t,
      rootStore: {
        optimizerStore: { selectedItem },
      },
      chartMode,
      setChartMode,
      setPrintModeVisible,
      history,
    } = this.props;

    return (
      <div className="offset-table" ref={this._dom}>
        <div className="bg-background px-8 py-8">
          <Descriptions
            title={
              <div className="flex justify-space-beetwen">
                <div className="flex self-end">
                  <h1 className="mr-8">{t("optimization.traffic_profile")} </h1>
                  <h4 className="mb-8">{selectedItem?.general_data.name}</h4>
                </div>
                <div>
                  <Button
                    icon="arrow-left"
                    className="mx-8"
                    onClick={() => {
                      history.push("/analysis/optimizer");
                    }}>
                    {t("detail.back")}
                  </Button>
                  <Button
                    icon="file-excel"
                    onClick={() =>
                      exportCSVFile(
                        this.convertCoverPageToExportJsonFormat(
                          selectedItem?.general_data
                        ),
                        this.convertDataToExportJsonFormat(
                          selectedItem?.charts
                        ),
                        t("optimization.title_traffic_profile"),
                        t("optimization.title_nameCSV")
                      )
                    }>
                    {t("report_result.export_csv")}
                  </Button>
                  <Button
                    type="primary"
                    icon="printer"
                    className="mx-8"
                    onClick={() => setPrintModeVisible(true)}>
                    {t("report_result.print_table")}
                  </Button>
                </div>
              </div>
            }
            column={{ md: 4 }}>
            <Descriptions.Item label={t("optimization.min_profile_duration")}>
              {selectedItem?.general_data?.min_profile_duration / 60}
            </Descriptions.Item>
            <Descriptions.Item label={t("optimization.bin_size")}>
              {t(
                "optimization.bin_size." + selectedItem?.general_data?.bin_size
              )}
            </Descriptions.Item>
            <Descriptions.Item label={t("report_result.time_range")}>
              {selectedItem?.general_data.start_time} -{" "}
              {selectedItem?.general_data.end_time}
            </Descriptions.Item>

            <Descriptions.Item label={t("optimization.cluster_types")}>
              {t(selectedItem?.general_data.cluster_type)}
            </Descriptions.Item>
            <Descriptions.Item label={t("optimization.created_by")}>
              {selectedItem?.general_data.owner?.name}
            </Descriptions.Item>
            <Descriptions.Item label={t("optimization.num_of_profiles")}>
              {selectedItem?.general_data?.num_of_profiles}
            </Descriptions.Item>
            <Descriptions.Item label={t("report_result.date_range")}>
              <Tooltip
                title={
                  <span className="ant-tooltip-pre-wrap">
                    {getFullDateRangeString(selectedItem?.general_data, t)}
                  </span>
                }>
                {getShortDateRangeString(selectedItem?.general_data, t)}
              </Tooltip>
            </Descriptions.Item>
            <Descriptions.Item label={t("optimization.created_at")}>
              {moment(selectedItem?.general_data.created_at).format(
                DATE_FORMAT.full_with_tz
              )}
            </Descriptions.Item>
          </Descriptions>
        </div>

        <div className="flex justify-space-beetwen w-full my-8 items-center px-8">
          <div className="flex">
            {this.getProfileLegend(selectedItem?.general_data?.num_of_profiles)}
          </div>
          <div>
            <Radio.Group
              buttonStyle="solid"
              value={chartMode}
              onChange={(e) => setChartMode(e.target.value)}>
              <Radio.Button value={TRAFFIC_PROFILE_CHART_MODE.MAX_MIN}>
                {t(TRAFFIC_PROFILE_CHART_MODE.MAX_MIN)}
              </Radio.Button>
              <Radio.Button value={TRAFFIC_PROFILE_CHART_MODE.DATA_TABLE}>
                {t(TRAFFIC_PROFILE_CHART_MODE.DATA_TABLE)}
              </Radio.Button>
              <Radio.Button value={TRAFFIC_PROFILE_CHART_MODE.DETAIL}>
                {t(TRAFFIC_PROFILE_CHART_MODE.DETAIL)}
              </Radio.Button>
            </Radio.Group>
          </div>
        </div>
      </div>
    );
  }
}

export default HeaderTrafficProfile;
