export default  {
    translation: {
      "Welcome to React": "german",
      "Dashboard":"Armaturenbrett",
      "Configuration":"Konfiguration",
      "User":"Benutzer",
      "Report":"Bericht",
      "login.username.required" : "Bitte geben Sie Ihren Benutzernamen oder Ihre E-Mail-Adresse ein",
      "login.password.required" : "Bitte geben Sie Ihr Passwort ein",
      "login.username.placeholder" : "Benutzername / E-Mail",
      "login.password.placeholder" : "Passwort",
      "login.keepMe" : "Angemeldet bleiben",
      "login.login" : "Einloggen",
      "login.forgotpass" : "Passwort vergessen",
      "intersection":"Überschneidung",
      "report":"Bericht",
      "compare.interval":"Vergleichsintervall",
      "filter.signal":"Filter signal",
      "create":"Erstellen",
      "arrival_on_red":"Ankunft auf Rot",
      "phase_chart":"Phasendiagramm",
      "coordinate":"Koordinate",
      "other.paramater":"Anderer parameter",
      "volumn.bin.size":"Volumenbehältergröße",
      "please.select.intersection":"Bitte Kreuzung auswählen",
      "please.select.analysis":"Bitte wählen Sie einen Analysten aus",
      "please.select.time":"Bitte wählen Sie eine Uhrzeit aus"
    }
  }