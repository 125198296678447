import ChartDetail from "components/ChartDetail";
import * as d3 from "d3";

class VolumeChart extends ChartDetail {
  constructor(props) {
    super(props);
    this.value_field = "to_time";
    this.data_field = "charts_data";
    this.TooltipStyle = {
      defaultChartHeight: 18,
      width: 240,
      marginLeft: 20,
      marginTop: 20,
    };
    this.CHART_AXIS_SETTING = [
      {
        position: "left",
        key: "volume",
        label: "VPH",
        data: this.props.data.charts_data,
        tick: true,
      },
    ];
  }
  findMaxValueInObjectArray = (array) => {
    let maxValue = 0;
    if (Array.isArray(array)) {
      array.forEach((item) => {
        this.chartLegend.forEach((legend) => {
          maxValue = maxValue > item[legend.key] ? maxValue : item[legend.key];
        });
      });
    }
    return maxValue;
  };
  componentDidMount() {
    const { from_time, to_time, data, isPrintMode, gapdata } = this.props;
    if (!this.data_field && !this.value_field) {
      return;
    }
    this.chartLegend = data.all_legend;
    //general Info
    const fromTime = new Date(from_time)?.getTime();
    const toTime = new Date(to_time)?.getTime();
    const chartData = data[this.data_field];
    this.planData = data?.plan_statistics;
    //Draw Chart Plan
    //draw xAxisObject
    //get plan object from dom
    this.chartObject = d3.select(this.chartRef?.current);
    if (
      Array.isArray(this.planData) &&
      Array.isArray(chartData) &&
      this.chartObject
    ) {
      //calculate the witdh of xAxist bar base on the scren size
      let parentWidth = parseFloat(
        window.getComputedStyle(this.chartObject.node())?.width
      );
      this.fullWidth = parentWidth * 0.9;
      this.ChartStyle.marginLeftRight = parentWidth * 0.05;
      //calculate the height of xAxist bar base on the scren size
      let parentHeight = parseFloat(
        window.getComputedStyle(this.chartObject.node())?.height
      );
      this.ChartStyle.marginTopBottom = parentHeight * 0.05;
      this.fullHeight =
        parentHeight -
        this.ChartStyle.extraFocusHeight -
        this.ChartStyle.marginTopBottom;

      this.ChartStyle.extraFocusPosition = this.fullHeight;
      /// create y scale Object
      //draw background color base on plan data

      this.renderXaxisLineForChart(fromTime, toTime, this.planData);
      this.renderChartBackgroundColor(this.planData);
      /// create y scale object
      this.renderYaxisLineForChart();

      //generate chart data

      //convert chartData to front-end format
      //note have to run behin renderXaxisLineForChart for xScale Object
      this.tranformChartDataToFronEndDataFormat(chartData, this.planData);

      //note have to run this function first tranformChartDataToFronEndDataFormat
      // this.renderZoomHandle();
      //generate chart crosshair
      if (!isPrintMode) {
        this.renderChartCrossHair(chartData);
      }
      this.renderChartData();
      /// render gap data
      if (gapdata) {
        this.renderGapDataForChart(gapdata);
      } //render zoom handler
    }
  }
  /**
   * render
   *
   * @return  {Component}
   */
  render() {
    return super.render();
  }
}

export default VolumeChart;
