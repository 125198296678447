import IntersectionService from "../services/IntersectionService";
import { RESOURCE_LIST } from "utils/constants";
import BaseStore from "./BaseStore";
/**
 * Store for intersections of agency
 *
 * @class
 */
class AgencyIntersectionStore extends BaseStore {
  constructor(agencyId, parentStore) {
    super(new IntersectionService(parentStore), parentStore);
    this.agencyId = agencyId;
    //filter data for list intersection
    this.filterData = {
      ...this.filterData,
      ...{
        text: "",
        agency_id: agencyId,
      },
    };
    this.resourceName = RESOURCE_LIST.AGENCY_DETAIL;
  }
}

export default AgencyIntersectionStore;
