import React, { Component } from "react";
import {
  Icon,
  Avatar,
  Popover,
  Switch as AntSwitch,
  Tooltip,
  Badge,
  Button,
} from "antd";
import { Link } from "react-router-dom";
import { THEME_DARK, THEME_LIGHT } from "utils/constants";
import { observer, inject } from "mobx-react";
import helper from "../../../utils/helper";
import UserInfoPanel from "./UserInfoPanel";
import NotificationListPanel from "./NotificationListPanel";
import { ReactComponent as Logo } from "../../../svgs/logo.svg";
import MenuBar from "../MenuBar";
import ToggleMenuBar from "../ToggleMenuBar";
import { withTranslation } from "react-i18next";
import { ReactComponent as LogoMoon } from "../../../svgs/moon.svg";
import "./style.scss";

@inject("rootStore")
@observer
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
    };
  }
  /**
   * handle change theme of all app
   * @param   {Boolean} isDark  true is dark theme false is light theme
   * @return  {null}
   */
  onSwitchTheme = (isDark) => {
    const {
      rootStore: { commonStore },
    } = this.props;
    commonStore.setThemeStyle(isDark ? THEME_DARK : THEME_LIGHT);
  };
  handleShowAllJob = () => {
    const {
      rootStore: { jobProgressStore },
    } = this.props;
    jobProgressStore.handleShowAllJob();
  };
  /**
   * componentDidMount
   *
   */
  componentDidMount() {
    const {
      rootStore: { unreadNotificationStore },
    } = this.props;

    //get unread message
    unreadNotificationStore.getData();
  }
  render() {
    const {
      rootStore: { unreadNotificationStore, commonStore, myStore, authStore },
      t,
    } = this.props;
    let user = myStore.getCurrentProfile();
    let agency = myStore.getCurrentAgency();
    const currentTheme = commonStore.getThemeStyle();
    const badgeNumner = unreadNotificationStore.filterData.pagination.total;
    return (
      <div className="wrap-header">
        <div className="logo">
          <Logo className="logo-img"></Logo>
        </div>

        {!commonStore.isFromStatic && <MenuBar myStore={myStore}></MenuBar>}
        {!commonStore.isFromStatic && (
          <div className="right-function">
            <div className="theme-switcher will-hide">
              <Tooltip placement="bottom" title={t("header.theme")}>
                <AntSwitch
                  checkedChildren={<LogoMoon></LogoMoon>}
                  unCheckedChildren={<LogoMoon></LogoMoon>}
                  checked={currentTheme === THEME_DARK}
                  onChange={(e) => this.onSwitchTheme(e)}
                />
              </Tooltip>
            </div>
            <Tooltip
              className="will-hide"
              placement="bottom"
              title={t("header.help")}
            >
              <a href="/assets/User_Guide.pdf" target="_blank">
                <Icon type="question-circle" />
              </a>
            </Tooltip>
            <Tooltip
              placement="bottom"
              title={t("job_progress.buble_btn_tooltip")}
            >
              <div className="job-progress-btn" onClick={this.handleShowAllJob}>
                <Icon type="cloud-sync" />
              </div>
            </Tooltip>
            <Tooltip placement="bottom" title={t("header.notification")}>
              <Popover
                content={<NotificationListPanel />}
                title={t("alm-noti.title")}
                placement="bottomRight"
                trigger="click"
                visible={commonStore.showNotificaionPanel}
                getPopupContainer={() =>
                  document.getElementsByClassName("wrap-header")[0]
                }
                onVisibleChange={commonStore.setVisibleNotificaionPanel}
              >
                <Badge count={badgeNumner}>
                  <Icon type="bell" />
                </Badge>
              </Popover>
            </Tooltip>
            <div className="profile  will-hide">
              <Popover
                onVisibleChange={commonStore.setVisibleUserPanel}
                trigger="click"
                visible={commonStore.showUserPanel && !this.state.collapsed}
                placement="bottomRight"
                getPopupContainer={() =>
                  document.getElementsByClassName("wrap-header")[0]
                }
                content={
                  <UserInfoPanel currentUser={myStore?.currentProfile} />
                }
              >
                <Avatar src={user?.avatar_url}>
                  {!user?.avarta_url &&
                    helper
                      .getFirstsLetterFromWords(user?.name)
                      ?.substring(0, 2)}
                </Avatar>
              </Popover>
            </div>
            <div className=" will-hide agency-info">
              {!commonStore.isSuperAdmin() ? (
                <Link to={"/agencies/" + agency?.agency_id} className="">
                  <div className="agency-info-text">{agency?.agency_name}</div>
                  <div className="agency-info-sub">{agency?.role_name}</div>
                </Link>
              ) : (
                <div className="">
                  <div className="agency-info-text">{"YUNEX"}</div>
                  <div className="agency-info-sub">{agency?.role_name}</div>
                </div>
              )}
            </div>
            <div style={{ marginRight: 10 }} className="toggle-btn-menu">
              <Popover
                content={
                  <ToggleMenuBar
                    onSwitchTheme={this.onSwitchTheme}
                    myStore={myStore}
                  />
                }
                trigger="click"
                visible={this.state.collapsed}
                placement={"bottomLeft"}
                onVisibleChange={() =>
                  this.setState({ collapsed: !this.state.collapsed })
                }
              >
                <Button
                  type="primary"
                  // onClick={() => { this.setState({ collapsed: !this.state.collapsed }) }}
                >
                  <Icon
                    type={this.state.collapsed ? "menu-unfold" : "menu-fold"}
                  />
                </Button>
              </Popover>
            </div>
          </div>
        )}
        {commonStore.isFromStatic && (
          <Button
            type="primary"
            className="go-spm"
            onClick={() => authStore.logout()}
          >
            {t("header.go_spm")}
          </Button>
        )}
      </div>
    );
  }
}

export default withTranslation()(Header);
