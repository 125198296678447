import { Avatar, Form, Menu, Button } from "antd";
import { inject, observer } from "mobx-react";
import moment from "moment";
import React from "react";
import { withTranslation } from "react-i18next";
import { DATE_FORMAT, UNKOWN_DATA_TEXT } from "utils/constants";
import BaseDetailPopupComponent from "../../../components/BaseDetailPopupComponent";
import helper from "../../../utils/helper";
import "./style.scss";
/**
 * Component for showing details of the user.
 *
 * @component
 */
@inject("rootStore")
@observer
class IntersectionDetail extends BaseDetailPopupComponent {
  /**
   * contructor of component
   * @param   {object} props  props of component
   */
  constructor(props) {
    super(props.rootStore?.intersectionStore, props);
  }
  /**
   * render.
   *
   */
  renderDetailData(intersection) {
    const {
      t,
      rootStore: {
        intersectionStore: {
          toggleDownloadModalVisibility,
          // setSelectedIntersection,
        },
      },
      handleRedirectToConfigPage,
    } = this.props;
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "90vh",
          justifyContent: "space-between",
        }}
      >
        <div className="wrap-intersection-detail">
          <Form.Item label={t("intersection.id")}>{intersection?.id}</Form.Item>
          <Form.Item label={t("intersection.number")}>
            {intersection?.number}
          </Form.Item>
          <Form.Item label={t("intersection.name")}>
            {intersection?.name}
          </Form.Item>
          <Form.Item label={t("intersection.address")}>
            {intersection?.address}
          </Form.Item>
          <Form.Item label={t("intersection.model")}>
            {intersection?.model}
          </Form.Item>
          <Form.Item label={t("intersection.version")}>
            {intersection?.version}
          </Form.Item>
          <Form.Item label={t("intersection.status")}>
            {intersection?.status}
          </Form.Item>
          <Form.Item label={t("intersection.perflog_updated_time")}>
            {intersection?.perflog_recent_time
              ? moment(intersection?.perflog_recent_time).format(
                  DATE_FORMAT.full
                )
              : UNKOWN_DATA_TEXT}
          </Form.Item>
          <Form.Item label={t("intersection.agencies")}>
            <Menu>
              {intersection?.agencies?.map((e) => (
                <Menu.Item key={e.id}>
                  <div className="agency-item">
                    <div>
                      <Avatar
                        style={{ backgroundColor: helper.getRandomColor() }}
                      >
                        {helper
                          .getFirstsLetterFromWords(e.name)
                          ?.substring(0, 2)}
                      </Avatar>
                      <span className="agency-name">{e.name}</span>
                    </div>
                  </div>
                </Menu.Item>
              ))}
            </Menu>
          </Form.Item>
        </div>
        <div
          style={{
            display: "flex",
            gap: "5px",
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          <Button
            onClick={() => {
              toggleDownloadModalVisibility(
                true,
                intersection?.id,
                intersection?.name
              );
            }}
          >
            {t("intersection.download_raw")}
          </Button>
          <Button
            type="primary"
            onClick={() => {
              handleRedirectToConfigPage(intersection);
            }}
          >
            {t("intersection.config_edit")}
          </Button>
        </div>
      </div>
    );
  }
}

const IntersectionDetialForm = Form.create({ name: "intersection_detail" })(
  IntersectionDetail
);

export default withTranslation()(IntersectionDetialForm);
