import ChartDetail from "components/ChartDetail";
import * as d3 from "d3";
const EXTENDS_KEY = ["oversaturated", "detector_occupied_during_green"];
class AppoarchDelayChart extends ChartDetail {
  constructor(props) {
    super(props);
    this.value_field = "to_time";
    this.data_field = "delay_vehicle";
    this.CHART_AXIS_SETTING = [
      {
        position: "left",
        key: "delay_per_vehicle",
        label: "Approach Delay Per Vehicle (seconds)",
        data: this.props.data.delay_vehicle,
        tick: true,
      },
      {
        position: "right",
        key: "bin_volume",
        label: "Volume (Raw count)",
        data: this.props.data.delay_vehicle,
        tick: false,
      },
    ];
    this.TooltipStyle = {
      defaultChartHeight: 18,
      width: 280,
      marginLeft: 20,
      marginTop: 20,
    };
  }
  /**
   * handleMouseMove
   * handle mouse move action
   * if targetItem is not defined => find the suitable item in the chart data
   * if targetItem is vaild => render hight line that target item
   * @param {Object} mousePosition
   * @param {Object} targetItem the target item// format :{ data, key: line.key }
   */
  handleMouseMove = (mousePosition, targetItem = null) => {
    if (
      Array.isArray(mousePosition) &&
      mousePosition.length === 2 &&
      this.bisectDate
    ) {
      let xPosition = mousePosition[0];
      let yPosition = mousePosition[1];
      //find the closest data object on chart

      //find target object by target date
      let targetObject = [];
      if (!targetItem) {
        let currentTime = null;

        //convert current mouse postion to date
        const indexDate = new Date(this.newX?.invert(xPosition));
        const indexValue = indexDate?.getTime();
        this.availableLineData.forEach((line) => {
          // if (line.type === GRAPHIC_CHART_TYPE.line) {
          let minX = line.x(new Date(line.x.domain()[0]));
          let maxX = line.x(new Date(line.x.domain()[1]));
          let minY = line.y(line.y.domain()[0]);
          let maxY = line.y(line.y.domain()[1]);

          const index = this.bisectDate(line.data, indexValue);
          const data = line.data[index];
          if (data) {
            let dataPositonX = line.x(new Date(data.time));
            let dataPositonY = line.y(data.value);
            //checkdata  in current zoom data or not
            if (
              dataPositonY > minY &&
              dataPositonY < maxY &&
              dataPositonX > minX &&
              dataPositonX < maxX
            ) {
              if (data.time && !EXTENDS_KEY.includes(line.key))
                if (currentTime === null) {
                  targetObject.push({ data, key: line.key });
                  currentTime = data.time;
                } else {
                  if (data.time < currentTime) {
                    targetObject = [];
                    targetObject.push({ data, key: line.key });
                  } else if (data.time === currentTime) {
                    targetObject.push({ data, key: line.key });
                  }
                }
            }
          }
          // }
        });
      } else {
        targetObject.push(targetItem);
        yPosition -= this.ChartStyle.marginTopBottom;
      }

      let newXPosition = xPosition;
      if (Array.isArray(targetObject) && targetObject.length > 0) {
        //get all info of target datapoint to draw the hightline and box detail
        //draw box detail
        //update position of crosshair to data point
        newXPosition = this.newX(new Date(targetObject[0].data.time));
        this.renderHightLinePoint(targetObject, newXPosition, yPosition);
        //update the crosshair x to exact position of data point
      }

      //move crossHair
      this.crossHairObject?.crosshair_x
        ?.attr("display", "block")
        .attr("x1", newXPosition + this.ChartStyle.marginLeftRight)
        .attr("x2", newXPosition + this.ChartStyle.marginLeftRight);
      this.crossHairObject?.crosshair_y
        ?.attr("display", "block")
        .attr("y1", yPosition + this.ChartStyle.marginTopBottom)
        .attr("y2", yPosition + this.ChartStyle.marginTopBottom);
    }
  };
  renderChartData = () => {
    let lineCharts = this.chartObject
      .append("g")
      .attr("class", "lines-chart-group");
    lineCharts
      .append("defs")
      .append("SVG:clipPath")
      .attr("id", "clip")
      .append("SVG:rect")
      .attr("width", this.fullWidth)
      .attr("height", this.fullHeight)
      .attr("x", 0)
      .attr("y", 0);
    this.lineChart = lineCharts;
    // this area to override the lineChart overflow x;yAxis
    if (Array.isArray(this.availableLineData)) {
      this.availableLineData.map((line) => {
        let minX = line.x(new Date(line.x.domain()[0]));
        let maxX = line.x(new Date(line.x.domain()[1]));
        let minY = line.y(line.y.domain()[0]);
        let maxY = line.y(line.y.domain()[1]);

        // let renderData = line.data;
        const lineChartObj = d3
          .line()
          .x(function (d) {
            return line.x(new Date(d?.time));
          })
          .y(function (d) {
            return line.y(d?.value);
          });
        lineCharts
          .append("path")
          .attr("clip-path", "url(#clip)")
          .attr(
            "transform",
            "translate(" +
              this.ChartStyle.marginLeftRight +
              ", " +
              this.ChartStyle.marginTopBottom +
              ")"
          )
          .attr("class", "lines-chart")
          .attr("stroke", line.color)
          .style("fill", "none")
          .style("stroke-width", line.strokeWidth)
          .datum(line.data)
          .attr("d", lineChartObj);
        if (EXTENDS_KEY.includes(line.key)) {
          line.data?.forEach((subData) => {
            lineCharts
              .append("path")
              .attr("clip-path", "url(#clip)")
              .attr(
                "transform",
                "translate(" +
                  this.ChartStyle.marginLeftRight +
                  ", " +
                  this.ChartStyle.marginTopBottom +
                  ")"
              )
              .attr("class", "lines-chart")
              .attr("stroke", line.color)
              .style("fill", "none")
              .style("stroke-width", line.strokeWidth)
              .datum(subData)
              .attr("d", lineChartObj);
          });
        } else {
          let renderData = line.data.filter((item) => {
            let PointXlocation = line.x(new Date(item?.time));
            let PointYlocation = line.y(item?.value);
            if (
              PointXlocation >= minX &&
              PointXlocation <= maxX &&
              PointYlocation >= minY &&
              PointYlocation <= maxY
            )
              return true;
            return false;
          });
          renderData.forEach((item) => {
            if (item) {
              let pointx =
                line.x(new Date(item?.time)) + this.ChartStyle.marginLeftRight;
              let pointy =
                line.y(item?.value) + this.ChartStyle.marginTopBottom;
              lineCharts
                .append("circle")
                .attr("r", this.LINE_POINT)
                .attr("fill", line.color)
                .style("opacity", "0")
                .attr("class", "hight-line-point")
                .attr("transform", "translate(" + pointx + "," + pointy + ")")
                .on("mousemove", () => {
                  this.handleMouseMove([pointx, pointy], {
                    data: item,
                    key: line.key,
                  });
                });
            }
          });
        }
      });
    }
  };

  /**
   * getDataObject
   * convert the chart data to format of D3 Chart
   * this format is inside of LineData
   * @param {Array} chartData
   * @param {Object} item item key wanna convert
   * @param {Object} planData
   *
   * @returns {Array} data with 3D format
   */
  getDataObject = (chartData, item) => {
    const { from_time, to_time } = this.props;
    let result = [];
    let currentTime = null;
    chartData.forEach((e) => {
      if (
        from_time.getTime() <= new Date(e[this.value_field]).getTime() &&
        new Date(e[this.value_field]).getTime() <= to_time.getTime()
      ) {
        if (EXTENDS_KEY.includes(item.key)) {
          if (e[item.key]) {
            if (e.from_time !== currentTime) {
              result.push([]);
              result[result.length - 1]?.push({
                time: e[this.value_field],
                value: e.delay_per_vehicle,
              });
            } else
              result[result.length - 1]?.push({
                time: e[this.value_field],
                value: e.delay_per_vehicle,
              });
            currentTime = e.to_time;
          }
        } else
          result.push({
            time: e[this.value_field],
            value: e[item.key],
          });
      }
    });
    return result;
  };
  componentDidMount = () => {
    super.componentDidMount();
  };
  /**
   * render
   *
   * @return  {Component}
   */
  render() {
    return super.render();
  }
}

export default AppoarchDelayChart;
