import * as d3 from "d3";
import React, { Component, createRef } from "react";
import "./style.scss";

class PieChart extends Component {
  svg;
  hightLineObject;
  g;
  pieHeight;
  pieWidth;
  constructor(props) {
    super(props);
    this.color = d3.scaleOrdinal(this.props.legendColor);
    this.ref = createRef();
  }

  componentDidMount() {
    const { data } = this.props;
    this.svg = d3.select(this.ref.current);
    this.pieWidth = parseFloat(window.getComputedStyle(this.svg.node())?.width);
    this.pieHeight = parseFloat(
      window.getComputedStyle(this.svg.node())?.height
    );
    this.svg
      .attr("class", "chart")
      .attr("width", this.pieWidth)
      .attr("height", this.pieHeight);
    const self = this;
    const width = +this.svg.attr("width");
    const height = +this.svg.attr("height");
    const radius = Math.min(width, height) / 2;
    this.g = this.svg
      .append("g")
      .attr("width", this.pieWidth)
      .attr("height", this.pieHeight)
      .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");
    const pie = d3
      .pie()
      .sort(null)
      .value(function (d) {
        return d.value;
      });
    const path = d3
      .arc()
      .outerRadius(radius - 10)
      .innerRadius(0);
    const arc = this.g
      .selectAll(".arc")
      .data(pie(data))
      .enter()
      .append("g")
      .attr("class", "arc");
    arc
      .append("path")
      .attr("d", path)
      .style("stroke-width", "2px")
      .attr("fill", function (d) {
        return self.color(d.data.key);
      })
      .on("mousemove", function (d) {
        const position = d3.mouse(this);
        self.handleMouseMove(position[0], position[1], d);
      })
      .on("mouseout", function () {
        self.handleMouseOut();
      });
  }
  handleMouseMove(xPosition, yPosistion, d) {
    if (this.hightLineObject) {
      this.hightLineObject?.remove();
    }
    const toolTipHeight = 60;
    const toolTipWidth = 185;
    const tranformTooltipX = xPosition + this.pieWidth / 2 - 60;
    const tranformTooltipY = this.pieHeight / 2 + yPosistion + 20;
    if (this.svg && d?.data.tooltip) {
      this.hightLineObject = this.svg
        .append("g")
        .attr("class", "hightline-group");
      this.hightLineObject
        .append("rect")
        .attr("width", toolTipWidth) //hard code the width of bo
        .attr("height", toolTipHeight)
        .style("background", "#000")
        .attr("class", "tooltip-box")
        .attr("stroke", "var(--text)")
        .attr("fill", "var(--background)")
        .attr(
          "transform",
          "translate(" + tranformTooltipX + "," + tranformTooltipY + ")"
        );

      this.hightLineObject
        .append("rect")
        .attr("width", 10)
        .attr("height", 10)
        .attr("stroke", "black")
        .attr("fill", this.color(d?.data?.key))
        .attr(
          "transform",
          "translate(" +
            (tranformTooltipX + 6) +
            "," +
            (tranformTooltipY + toolTipHeight / 2 - 6) +
            ")"
        );
      this.hightLineObject
        .append("text")
        .text(d?.data.name + ": " + (Number(d?.data?.value)).toFixed(2).replace(".00", '') + d.data?.vehical)
        .attr("text-anchor", "middle")
        .attr("class", "tooltip-info-text")
        .attr("fill", "var(--text)")
        .attr(
          "transform",
          "translate(" +
            (tranformTooltipX + toolTipWidth / 2 + 4) +
            "," +
            (tranformTooltipY + toolTipHeight / 2 + 4) +
            ")"
        );
    }
  }
  handleMouseOut() {
    this.hightLineObject?.remove();
  }
  render() {
    return <svg className="chart-detail" ref={this.ref} />;
  }
}

export default PieChart;
