import React from "react";
import { Select } from "antd";
import { inject, observer } from "mobx-react";
import BaseCollapseData from "../BaseCollapseData";
import { withTranslation } from "react-i18next";

const { OptGroup } = Select;
const MAP_SPLIT_MODE = {
  2: " 2 = none",
  3: " 3 = Min Vehicle Recall",
  4: " 4= Max Vehicle Recall",
  5: " 5 = Ped Recall",
  6: " 6 = Max Vehicle & Ped Recall",
  7: " 7 = Phase Omitted",
};

@inject("rootStore")
@observer
class CoordData extends BaseCollapseData {
  /**
   * contructor of component
   * @param   {object} props  props of component
   */
  constructor(props) {
    super(props);
    this.tooltipKeyDescription = "";
  }

  getTableColumn = () => {
    return [
      {
        key: "pattern_number",
        label: "Pattern Number",
      },
      {
        key: "phase",
        label: "Phase",
      },
      {
        key: "is_coord_phase",
        label: "Is Coord Phase",
      },
      {
        key: "split_mode",
        label: "Split Mode",
      },
      {
        key: "split_time",
        label: "Split Time (sec)",
      },
    ];
  };
  transformTableData = () => {
    const {
      rootStore: {
        intersectionConfigStore: { currentConfig },
      },
    } = this.props;
    const patternInfo = currentConfig?.PatternInfo;

    const patternMap = new Map();

    patternInfo?.splitCoordPhase?.forEach((splitCoordPhaseData, index) => {
      const pattern = index + 1;
      patternMap.set(pattern, { splitCoordPhaseData: splitCoordPhaseData });
    });

    patternInfo?.splitTime?.forEach((splitTimeData, index) => {
      const pattern = index + 1;
      if (patternMap.has(pattern)) {
        patternMap.get(pattern).splitTime = splitTimeData;
      } else {
        patternMap.set(pattern, { splitTime: splitTimeData });
      }
    });

    patternInfo?.splitMode?.forEach((splitModeData, index) => {
      const pattern = index + 1;
      if (patternMap.has(pattern)) {
        patternMap.get(pattern).splitMode = splitModeData;
      } else {
        patternMap.set(pattern, { splitMode: splitModeData });
      }
    });

    patternInfo?.cycleLength?.forEach((cycleLengthData, index) => {
      const pattern = index + 1;
      if (patternMap.has(pattern)) {
        patternMap.get(pattern).cycleLength = cycleLengthData;
      } else {
        patternMap.set(pattern, { cycleLength: cycleLengthData });
      }
    });

    patternInfo?.offsetTime?.forEach((offsetTimeData, index) => {
      const pattern = index + 1;
      if (patternMap.has(pattern)) {
        patternMap.get(pattern).offsetTime = offsetTimeData;
      } else {
        patternMap.set(pattern, { offsetTime: offsetTimeData });
      }
    });

    patternInfo?.sequenceNumber?.forEach((sequenceNumberData, index) => {
      const pattern = index + 1;
      if (patternMap.has(pattern)) {
        patternMap.get(pattern).sequenceNumber = sequenceNumberData;
      } else {
        patternMap.set(pattern, { sequenceNumber: sequenceNumberData });
      }
    });

    // Convert map to array
    const patternArray = [];
    patternMap.forEach((value, key) => {
      // For each pattern, aggregate data according to phases
      const phaseMap = new Map();
      value.splitCoordPhaseData.forEach((coordPhase, index) => {
        const phase = index + 1;
        phaseMap.set(phase, { is_coord_phase: coordPhase });
      });
      value.splitTime.forEach((splitTime, index) => {
        const phase = index + 1;
        if (phaseMap.has(phase)) {
          phaseMap.get(phase).split_time = splitTime;
        } else {
          phaseMap.set(phase, { split_time: splitTime });
        }
      });

      value.splitMode.forEach((splitMode, index) => {
        const phase = index + 1;
        if (phaseMap.has(phase)) {
          phaseMap.get(phase).split_mode = MAP_SPLIT_MODE[splitMode];
        } else {
          phaseMap.set(phase, { split_mode: MAP_SPLIT_MODE[splitMode] });
        }
      });

      const phaseArray = [];
      phaseMap.forEach((phaseValue, phaseKey) => {
        phaseArray.push({
          pattern_number: key,
          phase: phaseKey,
          ...phaseValue,
        });
      });

      patternArray.push({
        pattern_number: key,
        cycle_length: value.cycleLength,
        offset_time: value.offsetTime,
        sequence_number: value.sequenceNumber,
        phases: phaseArray,
      });
    });

    return patternArray;
  };

  getPartenFilter = () => {
    const {
      rootStore: {
        intersectionConfigStore: { currentConfig },
      },
    } = this.props;
    const patternInfo = currentConfig?.PatternInfo;
    let arrayComponent = [];
    let arrRow = [1, 2, 3, 4];
    for (let i = 0; i < patternInfo?.splitCoordPhase?.length / 4; i++) {
      arrayComponent.push(
        <OptGroup key={i}>
          {arrRow.map((e) => {
            let keyId = i * 4 + e;
            if (keyId <= patternInfo?.splitCoordPhase?.length)
              return (
                <Select.Option key={keyId} style={{ minWidth: 80 }}>
                  {keyId < 10 ? "0" + keyId : keyId}
                </Select.Option>
              );
            else return null;
          })}
        </OptGroup>
      );
    }
    return arrayComponent;
  };

  render() {
    return super.render();
  }
}

export default withTranslation()(CoordData);
