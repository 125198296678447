import ChartDetail from "components/ChartDetail";
import * as d3 from "d3";
import { ANALYSIS_LEGEND } from "utils/constants";
import { GRAPHIC_CHART_TYPE } from "utils/constants";
class PreemptDetail extends ChartDetail {
  constructor(props) {
    super(props);
    this.value_field = "time";
    this.data_field = "d_well_time";
    this.CHART_AXIS_SETTING = [
      {
        position: "left",
        key: "number",
        label: "Seconds (s)",
        tick: true,
        data: [
          ...this.props.data.d_well_time,
          ...this.props.data.track_clear_interval,
          ...this.props.data.entry_delay,
          ...this.props.data.track_clear_time,
          ...this.props.data.max_out_time,
          ...this.props.data.exit_time,
        ],
      },
    ];
    this.TooltipStyle = {
      defaultChartHeight: 18,
      width: 240,
      marginLeft: 20,
      marginTop: 20,
    };
    this.bisectDate = (data, time) => {
      let minDistnaceIndex = -1;
      let maxDistance = Number.MAX_SAFE_INTEGER;
      data.forEach((item, id) => {
        const itemDistance = Math.abs(
          new Date(item[this.value_field]).getTime() - time
        );
        maxDistance = itemDistance <= maxDistance ? itemDistance : maxDistance;
        minDistnaceIndex = itemDistance <= maxDistance ? id : minDistnaceIndex;
      });
      if (minDistnaceIndex === -1)
        return d3
          .bisector(function (d) {
            return new Date(d.time).getTime();
          })
          .left(data, time);
      else {
        return minDistnaceIndex;
      }
    };
  }
  /**
   * findMaxValueInObjectArray
   *
   * @param {Array} array  data to find the max value
   * @param {String} key key of compare field
   * @param {Object} defaultValue defaultValue
   */
  findMaxValueInObjectArray = (array) => {
    let maxValue = 0;
    if (Array.isArray(array)) {
      array.forEach((item) => {
        maxValue = maxValue > item.value ? maxValue : item.value;
      });
    }
    return maxValue;
  };

  renderChartData = () => {
    let lineCharts = this.chartObject
      .append("g")
      .attr("class", "lines-chart-group");
    lineCharts
      .append("defs")
      .append("SVG:clipPath")
      .attr("id", "clip")
      .append("SVG:rect")
      .attr("width", this.fullWidth)
      .attr("height", this.fullHeight)
      .attr("x", 0)
      .attr("y", 0);
    this.lineChart = lineCharts;
    // this area to override the lineChart overflow x;yAxis
    if (Array.isArray(this.availableLineData)) {
      this.availableLineData.map((line) => {
        let minX = line.x(new Date(line.x.domain()[0]));
        let maxX = line.x(new Date(line.x.domain()[1]));
        let minY = line.y(line.y.domain()[0]);
        let maxY = line.y(line.y.domain()[1]);

        // let renderData = line.data;
        switch (line.type) {
          case GRAPHIC_CHART_TYPE.point: {
            let previousPointXlocation = null;
            let previousPointYlocation = null;
            let renderData = line.data.filter((item) => {
              let PointXlocation = line.x(new Date(item?.time));
              let PointYlocation = line.y(item?.value);
              if (
                PointXlocation >= minX &&
                PointXlocation <= maxX &&
                PointYlocation >= minY &&
                PointYlocation <= maxY
              )
                return true;
              return false;
            });
            //if element overflow xyAxis we will not render
            renderData.forEach((item) => {
              if (item) {
                let currentPointXlocation = line.x(new Date(item?.time));
                let currentPointYlocation = line.y(item?.value);
                let shouldShow = true;
                if (previousPointXlocation && previousPointYlocation) {
                  shouldShow =
                    Math.abs(currentPointXlocation - previousPointXlocation) >
                      this.LINE_POINT * 2 ||
                    Math.abs(currentPointYlocation - previousPointYlocation) >
                      this.LINE_POINT * 2;
                }
                if (shouldShow && item?.number) {
                  let pointx =
                    line.x(new Date(item?.time)) +
                    this.ChartStyle.marginLeftRight;
                  let pointy =
                    line.y(item?.value) + this.ChartStyle.marginTopBottom;
                  lineCharts
                    .append("circle")
                    .attr("r", this.LINE_POINT)
                    .attr("fill", line.color)
                    .attr("class", "hight-line-point")
                    .attr("class", "foo")
                    .attr(
                      "transform",
                      "translate(" + pointx + "," + pointy + ")"
                    )
                    .on("mousemove", () => {
                      this.handleMouseMove([pointx, pointy], {
                        data: item,
                        key: line.key,
                      });
                    });

                  previousPointXlocation = currentPointXlocation;
                  previousPointYlocation = currentPointYlocation;
                }
              }
            });
            break;
          }
          case GRAPHIC_CHART_TYPE.bar: {
            let defaultBarWidth =
              line.x(new Date(1610439640000)) - line.x(new Date(1610438400000));
            if (defaultBarWidth > 32) defaultBarWidth = 32;
            if (defaultBarWidth < 16) defaultBarWidth = 16;
            line.data.forEach((item) => {
              let pointX =
                line.x(new Date(item.time)) +
                this.ChartStyle.marginLeftRight -
                defaultBarWidth / 2;

              if (item?.offsetSpace > 0) pointX += defaultBarWidth;
              else if (item?.offsetSpace < 0) pointX -= defaultBarWidth;

              let miniumYValueBar = line.y.domain()[0] / 50;
              let pointY = line.y(
                item.value > 0 ? item.value : miniumYValueBar
              );
              let bar = lineCharts
                .append("rect")
                .style("fill", item.isMissingSomeEvent ? "#ccc" : line.color)
                .attr("clip-path", "url(#clip)")
                .attr(
                  "transform",
                  "translate(" +
                    this.ChartStyle.marginLeftRight +
                    ", " +
                    this.ChartStyle.marginTopBottom +
                    ")"
                )
                .attr("x", pointX - this.ChartStyle.marginLeftRight)
                .attr(
                  "y",
                  pointY > this.fullHeight - 6 ? this.fullHeight - 6 : pointY
                )
                .attr("width", defaultBarWidth)
                .attr("class", "bar")
                .attr(
                  "height",
                  this.fullHeight - pointY + this.ChartStyle.marginTopBottom
                );
              if (
                pointX >= minX &&
                pointX <= maxX &&
                pointY >= minY &&
                pointY <= maxY
              )
                bar.on("mousemove", () => {
                  this.handleMouseMove([pointX, pointY]);
                });
            });
            break;
          }
        }
      });
    }
  };
  handleMouseMove = (mousePosition, targetItem = null) => {
    if (
      Array.isArray(mousePosition) &&
      mousePosition.length === 2 &&
      this.bisectDate
    ) {
      let xPosition = mousePosition[0];
      let yPosition = mousePosition[1];
      //find the closest data object on chart

      //find target object by target date
      let targetObject = [];
      if (!targetItem) {
        let currentTime = null;

        //convert current mouse postion to date
        const indexDate = new Date(this.newX?.invert(xPosition));
        const indexValue = indexDate?.getTime();
        this.availableLineData.forEach((line) => {
          // if (line.type === GRAPHIC_CHART_TYPE.line) {
          let minX = line.x(new Date(line.x.domain()[0]));
          let maxX = line.x(new Date(line.x.domain()[1]));
          let minY = line.y(line.y.domain()[0]);
          let maxY = line.y(line.y.domain()[1]);

          const index = this.bisectDate(line.data, indexValue);
          const data = line.data[index];

          if (data && line.type !== "point") {
            let dataPositonX = line.x(new Date(data.time));
            let dataPositonY = line.y(data.value);
            //checkdata  in current zoom data or not
            if (
              dataPositonY > minY &&
              dataPositonY < maxY &&
              dataPositonX > minX &&
              dataPositonX < maxX
            ) {
              if (data.time)
                if (currentTime === null) {
                  targetObject.push({ data, key: line.key });
                  currentTime = data.time;
                } else {
                  if (data.time < currentTime) {
                    targetObject = [];
                    targetObject.push({ data, key: line.key });
                  } else if (data.time === currentTime) {
                    targetObject.push({ data, key: line.key });
                  }
                }
            }
          }
          // }
        });
      } else {
        targetObject.push(targetItem);
        yPosition -= this.ChartStyle.marginTopBottom;
      }

      let newXPosition = xPosition;
      if (Array.isArray(targetObject) && targetObject.length > 0) {
        //get all info of target datapoint to draw the hightline and box detail
        //draw box detail
        //update position of crosshair to data point
        newXPosition = this.newX(new Date(targetObject[0].data.time));
        this.renderHightLinePoint(targetObject, newXPosition, yPosition);
        //update the crosshair x to exact position of data point
      }

      //move crossHair
      this.crossHairObject?.crosshair_x
        ?.attr("display", "block")
        .attr("x1", newXPosition + this.ChartStyle.marginLeftRight)
        .attr("x2", newXPosition + this.ChartStyle.marginLeftRight);
      this.crossHairObject?.crosshair_y
        ?.attr("display", "block")
        .attr("y1", yPosition + this.ChartStyle.marginTopBottom)
        .attr("y2", yPosition + this.ChartStyle.marginTopBottom);
    }
  };
  renderHightLinePoint = (targetObject, x, y) => {
    const { data } = this.props;

    const tootTipHeight =
      (targetObject.length + 2) * this.TooltipStyle.defaultChartHeight +
      (targetObject.length > 1 ? 56 : 0); //2 is space top/bottom
    let chartObject = d3.select(this.chartRef?.current);
    if (this.hightLineObject) {
      this.hightLineObject?.remove();
    }

    let defaultBarWidth =
      this.xScaleObject(new Date(1610439640000)) -
      this.xScaleObject(new Date(1610438400000));

    if (defaultBarWidth > 32) defaultBarWidth = 32;
    if (defaultBarWidth < 16) defaultBarWidth = 16;

    if (chartObject) {
      this.hightLineObject = chartObject
        .append("g")
        .attr("class", "hightline-group");
      const xTooltipPosition =
        x < this.fullWidth / 2
          ? this.ChartStyle.marginLeftRight + x + this.TooltipStyle.marginLeft
          : this.ChartStyle.marginLeftRight +
            x -
            this.TooltipStyle.marginLeft -
            this.TooltipStyle.width;
      const yTooltipPosition =
        y < this.fullHeight / 2
          ? y + tootTipHeight / 2 + this.TooltipStyle.marginTop
          : y - tootTipHeight + this.TooltipStyle.marginTop;
      this.hightLineObject
        .append("rect")
        .attr("width", this.TooltipStyle.width) //hard code the width of box
        .attr("height", tootTipHeight)
        .attr("class", "tooltip-box")
        .attr("stroke", "var(--text)")
        .attr("fill", "var(--background)")
        .attr(
          "transform",
          "translate(" + xTooltipPosition + "," + yTooltipPosition + ")"
        );
      //render hightline point

      // let labelHeight = yTooltipPosition;
      let labelIndex = 1.5;
      //add default text of time
      if (targetObject.length > 1) {
        this.hightLineObject
          .append("text")
          .text(
            "Input on (102): " +
              new Date(targetObject[0]?.data?.time)?.toLocaleString("en-US", {
                hour: "numeric",
                minute: "numeric",
                second: "numeric",
                hour12: false,
              })
          ) //@TODO:hard code here
          .attr("text-anchor", "middle")
          .attr("class", "tooltip-info-text")
          .attr(
            "transform",
            "translate(" +
              (xTooltipPosition + this.TooltipStyle.width / 2) +
              "," +
              (yTooltipPosition +
                this.TooltipStyle.defaultChartHeight * labelIndex) +
              ")"
          )
          .attr("fill", "var(--text)");
        labelIndex += 1;
      }

      if (targetObject.length > 1) {
        //105
        const entryDelayTooltipItem = data.entry_delay?.find(
          (item) => item.time === targetObject[0]?.data?.time
        );
        if (entryDelayTooltipItem) {
          this.hightLineObject
            .append("text")
            .attr("fill", "var(--text)")

            .text(
              this.renderTooltipText(
                "Entry Started (105)",
                "",
                new Date(entryDelayTooltipItem?.timeValue)?.toLocaleString(
                  "en-US",
                  {
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric",
                    hour12: false,
                  }
                )
              )
            ) //the way we show tooltip defined on ANALYSIS_PLAN_INFO in constant file
            .attr("text-anchor", "middle")
            .attr("class", "tooltip-info-text")
            .attr(
              "transform",
              "translate(" +
                (xTooltipPosition + this.TooltipStyle.width / 2) +
                "," +
                (yTooltipPosition +
                  this.TooltipStyle.defaultChartHeight * labelIndex) +
                ")"
            );
          labelIndex += 1;
        }

        //106
        const trackClearTooltipItem = data.track_clear_time?.find(
          (item) => item.time === targetObject[0]?.data?.time
        );

        if (trackClearTooltipItem) {
          this.hightLineObject
            .append("text")
            .attr("fill", "var(--text)")

            .text(
              this.renderTooltipText(
                "Begin Track Clearance (106)",
                "",
                new Date(trackClearTooltipItem?.timeValue)?.toLocaleString(
                  "en-US",
                  {
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric",
                    hour12: false,
                  }
                )
              )
            ) //the way we show tooltip defined on ANALYSIS_PLAN_INFO in constant file
            .attr("text-anchor", "middle")
            .attr("class", "tooltip-info-text")
            .attr(
              "transform",
              "translate(" +
                (xTooltipPosition + this.TooltipStyle.width / 2) +
                "," +
                (yTooltipPosition +
                  this.TooltipStyle.defaultChartHeight * labelIndex) +
                ")"
            );
          labelIndex += 1;
        }

        //107
        const DWellTimeTooltipItem = data.d_well_time?.find(
          (item) => item.time === targetObject[0]?.data?.time
        );
        if (DWellTimeTooltipItem) {
          this.hightLineObject
            .append("text")
            .attr("fill", "var(--text)")

            .text(
              this.renderTooltipText(
                "Begin Dwell Service (107)",
                "",
                new Date(DWellTimeTooltipItem.timeValue)?.toLocaleString(
                  "en-US",
                  {
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric",
                    hour12: false,
                  }
                )
              )
            ) //the way we show tooltip defined on ANALYSIS_PLAN_INFO in constant file
            .attr("text-anchor", "middle")
            .attr("class", "tooltip-info-text")
            .attr(
              "transform",
              "translate(" +
                (xTooltipPosition + this.TooltipStyle.width / 2) +
                "," +
                (yTooltipPosition +
                  this.TooltipStyle.defaultChartHeight * labelIndex) +
                ")"
            );
          labelIndex += 1;
        }

        //111
        const exitTimeTooltipItem = data.exit_time?.find(
          (item) => item.time === targetObject[0]?.data?.time
        );
        if (exitTimeTooltipItem) {
          this.hightLineObject
            .append("text")
            .attr("fill", "var(--text)")

            .text(
              this.renderTooltipText(
                "Begin Exit Interval (111)",
                "",
                new Date(exitTimeTooltipItem.timeValue)?.toLocaleString(
                  "en-US",
                  {
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric",
                    hour12: false,
                  }
                )
              )
            ) //the way we show tooltip defined on ANALYSIS_PLAN_INFO in constant file
            .attr("text-anchor", "middle")
            .attr("class", "tooltip-info-text")
            .attr(
              "transform",
              "translate(" +
                (xTooltipPosition + this.TooltipStyle.width / 2) +
                "," +
                (yTooltipPosition +
                  this.TooltipStyle.defaultChartHeight * labelIndex) +
                ")"
            );
          labelIndex += 1;
        }
      }

      targetObject.forEach((item) => {
        //find the target point setting
        const setting = this.chartLegend.find(
          (e) => e.key === item.key && !e.hidden
        );
        //find y posistion of point
        //get point value
        const pointValue = item.data.value;
        //get point position
        if (setting && item.data.number) {
          const yPosistion = this.newYObject[setting.yAxis](pointValue);
          if (setting.type === "point")
            this.hightLineObject
              .append("circle")
              .attr("r", this.HIGHT_LINE_POINT)
              .attr("fill", setting.color)
              .attr("class", "hight-line-point")
              .attr(
                "transform",
                "translate(" +
                  (this.ChartStyle.marginLeftRight +
                    x +
                    (item.data.offsetSpace > 0
                      ? defaultBarWidth
                      : item.data.offsetSpace < 0
                      ? -defaultBarWidth
                      : 0)) +
                  "," +
                  (yPosistion + this.ChartStyle.marginTopBottom) +
                  ")"
              )
              .on("mouseout", () => {
                this.handleMouseOut();
              });
          if (setting.type === "point")
            this.hightLineObject
              .append("circle")
              .attr("r", this.HIGHT_LINE_POINT)
              .attr("fill", setting.color)
              .attr("class", "hight-line-point")
              .attr(
                "transform",
                "translate(" +
                  (this.ChartStyle.marginLeftRight +
                    x +
                    (item.data.offsetSpace > 0
                      ? defaultBarWidth
                      : item.data.offsetSpace < 0
                      ? -defaultBarWidth
                      : 0)) +
                  "," +
                  (yPosistion + this.ChartStyle.marginTopBottom) +
                  ")"
              )
              .on("mouseout", () => {
                this.handleMouseOut();
              });
          this.hightLineObject
            .append("text")
            .text(
              this.renderTooltipText(
                setting.label,
                setting.unit,
                setting.toolTip(item.data.number)
              )
            ) //the way we show tooltip defined on ANALYSIS_PLAN_INFO in constant file
            .attr("text-anchor", "middle")
            .attr("class", "tooltip-info-text")
            .attr(
              "transform",
              "translate(" +
                (xTooltipPosition + this.TooltipStyle.width / 2) +
                "," +
                (yTooltipPosition +
                  this.TooltipStyle.defaultChartHeight * labelIndex) +
                ")"
            )
            .attr("fill", setting.color);

          labelIndex += 1;
        }
      });
      //render hight-line infor box
    }
  };
  componentDidMount() {
    const { from_time, to_time, data, isPrintMode, chartType, gapdata } =
      this.props;
    if (!this.data_field && !this.value_field) {
      return;
    }
    this.chartLegend = [
      ...ANALYSIS_LEGEND[chartType].filter((lg) => lg.type === "bar").reverse(),
      ...ANALYSIS_LEGEND[chartType].filter((lg) => lg.type === "point"),
    ];
    //general Info
    const fromTime = new Date(from_time)?.getTime();
    const toTime = new Date(to_time)?.getTime();
    const chartData = data[this.data_field];
    this.planData = data?.plan_statistics;
    //Draw Chart Plan
    //draw xAxisObject
    //get plan object from dom
    this.chartObject = d3.select(this.chartRef?.current);
    if (
      Array.isArray(this.planData) &&
      Array.isArray(chartData) &&
      this.chartObject
    ) {
      //calculate the witdh of xAxist bar base on the scren size
      let parentWidth = parseFloat(
        window.getComputedStyle(this.chartObject.node())?.width
      );
      this.fullWidth = parentWidth * 0.9;
      this.ChartStyle.marginLeftRight = parentWidth * 0.05;
      //calculate the height of xAxist bar base on the scren size
      let parentHeight = parseFloat(
        window.getComputedStyle(this.chartObject.node())?.height
      );
      this.ChartStyle.marginTopBottom = parentHeight * 0.05;
      this.fullHeight =
        parentHeight * 0.86 -
        this.ChartStyle.extraFocusHeight -
        this.ChartStyle.marginTopBottom;

      this.ChartStyle.extraFocusPosition = this.fullHeight;
      /// create y scale Object
      //draw background color base on plan data

      this.renderXaxisLineForChart(fromTime, toTime, this.planData);
      this.renderChartBackgroundColor(this.planData);
      /// create y scale object
      this.renderYaxisLineForChart();

      //generate chart data

      //convert chartData to front-end format
      //note have to run behin renderXaxisLineForChart for xScale Object
      this.tranformChartDataToFronEndDataFormat(chartData, this.planData);

      //note have to run this function first tranformChartDataToFronEndDataFormat
      // this.renderZoomHandle();
      //generate chart crosshair
      if (!isPrintMode) {
        this.renderChartCrossHair(chartData);
      }
      this.renderChartData();
      /// render gap data
      if (gapdata) {
        this.renderGapDataForChart(gapdata);
      } //render zoom handler
    }
  }
  getDataObject = (_chartData, legend) => {
    const { from_time, to_time, data } = this.props;
    const allData = data[legend.key];
    let lineData = [];
    allData?.forEach((item) => {
      if (
        from_time.getTime() <= new Date(item[this.value_field]).getTime() &&
        new Date(item[this.value_field]).getTime() <= to_time.getTime()
      )
        lineData.push({
          time: item.time,
          value: item.value,
          timeValue: item.timeValue,
          number: item.number,
          isMissingSomeEvent: item.isMissingSomeEvent,
          offsetSpace: 0,
        });
    });

    return lineData;
  };

  /**
   * render
   *
   * @return  {Component}
   */
  render() {
    return super.render();
  }
}

export default PreemptDetail;
