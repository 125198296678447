import { Empty, Spin } from "antd";
import { inject, observer } from "mobx-react";
import queryString from "query-string";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import { OPTIMIZATION_TYPE } from "utils/constants";
import CorridorOffsetOptimization from "./CorridorOffsetOptimization";
import HistoricTimeSpace from "./HistoricTimeSpace";
import OffsetOptimization from "./OffsetOptimization";
import "./style.scss";
import TrafficProfile from "./TrafficProfile";
@inject("rootStore")
@observer
class OptimizerDetail extends Component {
  constructor(props) {
    super(props);
    this.currentStore = props.rootStore.optimizerStore;
  }
  componentDidMount() {
    const {
      match: { params },
      t,
    } = this.props;
    let filterObject = queryString?.parse(this.props.location.search);
    this.currentStore.getOptimizeDetail(params.id, filterObject);
    document.title = t("optimization.page-title");
  }
  componentWillUnmount() {
    this.currentStore.setSelectedItem(null);
  }
  renderDetailType = (type) => {
    switch (type) {
      case OPTIMIZATION_TYPE.OFFSET_OPTIMIZATION:
        return <OffsetOptimization />;
      case OPTIMIZATION_TYPE.TRAFFIC_PROFILE:
        return <TrafficProfile />;
      case OPTIMIZATION_TYPE.HISTORIC_TIME_SPACE:
        return <HistoricTimeSpace />;
      case OPTIMIZATION_TYPE.CORRIDOR_OFFSET_OPTIMIZATION:
        return <CorridorOffsetOptimization />;
      default:
        return <></>;
    }
  };
  render() {
    const {
      rootStore: {
        optimizerStore: { loading, selectedItem },
      },
    } = this.props;
    return (
      <div className="bg-wrap-background relative wrap-body-optimizer">
        {loading ? (
          <div className="w-full h-full flex justify-center items-center bg-background">
            <Spin size="large" />
          </div>
        ) : selectedItem ? (
          this.renderDetailType(
            selectedItem?.general_data.optimization_type.key
          )
        ) : (
          <div className="w-full h-full flex justify-center items-center bg-background">
            <Empty />
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(withTranslation()(OptimizerDetail));
