import { unionBy } from "lodash";
import { action } from "mobx";
import { RESOURCE_LIST } from "utils/constants";
import IntersectionAvailbleService from "../services/IntersectionAvailbleService";
import BaseStore from "./BaseStore";

class AvailableIntersectionStore extends BaseStore {
  constructor(parentStore, pathApi, mainResource) {
    super(new IntersectionAvailbleService(parentStore, pathApi), parentStore);
    this.resourceName = RESOURCE_LIST[mainResource];
  }
  //list of full object selected items in table
  selectedFullItems = [];

  /**
   * onSelectRowChange
   * this function will trigger when user select or unselect item row on table
   * @param   {Array} selectedItems  List of selected ITem
   * @return  {null} -  setState of current selectedItems
   */
  @action onSelectRowChange = (selectedItems) => {
    this.selectedItems = selectedItems;
    const selectedFullItems = this.selectedFullItems.filter((e) =>
      selectedItems?.includes(e?.id)
    );
    const newSubs = this.listData?.filter((e) => {
      return selectedItems?.includes(e?.id);
    });
    this.selectedFullItems = unionBy(selectedFullItems, newSubs, "id");
  };

  /**
   * get full object of selected items
   * @return  {null}   return selected item list
   */
  getSelectedItems = () => {
    return this.selectedFullItems;
  };

  @action setSelectedItem = (ids, cb) => {
    this.selectedItems = ids.map((e) => e.id);
    this.selectedFullItems = ids;
    cb && cb();
  };

  @action handleFilterDataReset = (filterData) => {
    this.filterData = {
      ...this.filterData,
      ...filterData,
    };
    this.handleFilerAction();
  };

  @action removeExcludeIdsFilter = () => {
    this.filterData.exclude_ids = null;
    this.handleFilerAction();
  };
}

export default AvailableIntersectionStore;
