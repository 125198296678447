import React from "react";
import { Button, Col, Input, Select, Tooltip } from "antd";
import StatusTag from "components/StatusTag";
import { inject, observer } from "mobx-react";
import { withTranslation } from "react-i18next";
import { ACTIONS_LIST, RESOURCE_LIST, USER_STATUS_LIST, } from "utils/constants";
import BaseManagementComponents from "../../../../../components/BaseManagementComponents";
import AlarmAvailableUserStore from "../../../../../stores/AlarmAvailableUserStore";
import AlarmRuleSubscriberStore from "../../../../../stores/AlarmRuleSubscriberStore";
import AddMoreSub from "./AddMoreSub";
import "./style.scss";

const Option = Select.Option;

/**
 * Component for showing details of the user.
 *
 * @component
 */
@inject("rootStore")
@observer
class AlarmRuleSubscriber extends BaseManagementComponents {
  constructor(props) {
    const { rootStore, ruleId, t } = props;
    super(new AlarmRuleSubscriberStore(ruleId, rootStore), props);
    this.addMoreSubStore = new AlarmAvailableUserStore(rootStore);
    this.columns = this.getTableColumns();
    this.resourceName =
      props.editingMode === ACTIONS_LIST.CREATE
        ? RESOURCE_LIST.ALARM_RULE
        : RESOURCE_LIST.ALARM_RULE_DETAIL_SUBCRIBER;
    this.moduleName = "alarm_rules_sub";
    this.enableCreateButton = false;
    this.headerButtonActions = [
      {
        key: "create",
        className: "create-button",
        title: this.props.t(this.moduleName + ".create"),
        icon: "plus",
        onClick: () => {
          this.currentStore?.showCreateOrEditForm(
            this.props.t(this.moduleName + ".create_form_title")
          );
        },
        isALink: this.enableRedirectPage,
        resource:
          this.props.editingMode === ACTIONS_LIST.CREATE
            ? RESOURCE_LIST.ALARM_RULE
            : RESOURCE_LIST.ALARM_RULE_DETAIL_SUBCRIBER,
        permission:
          this.props.editingMode === ACTIONS_LIST.CREATE
            ? ACTIONS_LIST.CREATE
            : ACTIONS_LIST.ADD,
        linkTo: this.moduleName + "/create",
      },
    ];
    this.buttonActions = [
      {
        key: "delete_all",
        title: t("alarm_rules.remove_all"),
        action: this.submitDeleteUser,
        confirm: true,
        resource:
          this.props.editingMode === ACTIONS_LIST.CREATE
            ? RESOURCE_LIST.ALARM_RULE
            : RESOURCE_LIST.ALARM_RULE_DETAIL_SUBCRIBER,
        permission:
          this.props.editingMode === ACTIONS_LIST.CREATE
            ? ACTIONS_LIST.CREATE
            : ACTIONS_LIST.REMOVE,
        confirmTitle: t("alarm_rules.delete_confirm_title"),
        confirmContent: t("alarm_rules.delete_confirm_content"),
      },
    ];
    this.tableInlineActions = [
      {
        key: "delete_all",
        tooltip: t("alarm_rules.remove"),
        resource:
          this.props.editingMode === ACTIONS_LIST.CREATE
            ? RESOURCE_LIST.ALARM_RULE
            : RESOURCE_LIST.ALARM_RULE_DETAIL_SUBCRIBER,
        permission:
          this.props.editingMode === ACTIONS_LIST.CREATE
            ? ACTIONS_LIST.CREATE
            : ACTIONS_LIST.REMOVE,
        action: this.handleTableAction,
        icon: "delete",
        passingData: (item) => {
          return ["delete_all", item?.id];
        },
      },
    ];
  }

  addMoreSubChild = null;

  getTableColumns = () => {
    const { ruleId, t } = this.props;
    let returnColumns = [
      {
        title: t("user.name"),
        key: "name",
        ellipsis: true,
        render: (item) => (
          <Button
            type="link"
            // onClick={() => this.goToDetailPage(item.id)}
          >
            {item.name}
          </Button>
        ),
        sorter: ruleId ? true : false,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: t("user.email"),
        key: "email",
        dataIndex: "email",
        ellipsis: true,
        sorter: ruleId ? true : false,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: t("user.phone"),
        key: "phone",
        dataIndex: "phone",
        ellipsis: true,
        sorter: ruleId ? true : false,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: t("user.status"),
        dataIndex: "status",
        key: "status",
        render: (status) => <StatusTag status={status}/>,
        // sorter: ruleId ? true : false,
        // sortDirections: ["descend", "ascend"],
        ellipsis: true,
      },
    ];
    return returnColumns;
  };

  componentDidMount() {
    const {
      ruleId,
      rootStore: { commonStore, alarmRuleStore },
      childRef,
    } = this.props;
    if (ruleId) {
      this.currentStore?.getData();
    }
    const superAdmin = commonStore.isSuperAdmin();
    if (superAdmin) {
      alarmRuleStore.getAllUsers(alarmRuleStore?.createRuleDetail?.agency_id);
    } else {
      alarmRuleStore.getAllUsers();
    }
    childRef(this);
  }

  renderFilterComponent = () => {
    const { t, ruleId } = this.props;

    return (
      ruleId && (
        <div className="wrap-filter">
          <Col span={8}>
            <Tooltip
              placement="top"
              title={t("alarm_rules.search_sub_tooltip")}
            >
              <Input
                defaultValue={this.currentStore?.filterData?.text}
                onChange={(e) =>
                  this.currentStore?.handleFilterDataChange(
                    "text",
                    e.target.value
                  )
                }
                placeholder={t("alarm_rules.search_placholer")}
                maxLength={128}
              />
            </Tooltip>
          </Col>
          <Col span={4}>
            <Tooltip placement="top" title={t("users.status_tooltip")}>
              <Select
                onChange={(value) =>
                  this.currentStore?.handleFilterDataChange("status", value)
                }
                defaultValue={this.currentStore?.filterData?.status}
              >
                <Option value="null">{t("user.all_status")}</Option>
                {USER_STATUS_LIST?.map((e) => (
                  <Option key={e.key} value={e.key}>
                    {t(e.value)}
                  </Option>
                ))}
              </Select>
            </Tooltip>
          </Col>
        </div>
      )
    );
  };

  renderDetailData = () => {
    const {
      ruleId,
      rootStore: {
        alarmRuleStore: { createRuleDetail, ruleDetail, myStore },
        commonStore: { isSuperAdmin },
      },
    } = this.props;
    const currentAgency = myStore.currentAgency;
    if (isSuperAdmin()) {
      if (ruleId) {
        this.addMoreSubStore.filterData.agency_id = ruleDetail?.agency_id;
      } else {
        this.addMoreSubStore.filterData.agency_id = createRuleDetail?.agency_id;
      }
    } else {
      this.addMoreSubStore.filterData.agency_id = currentAgency?.agency_id;
    }
    if (ruleId) {
      this.addMoreSubStore.filterData.rule_id = ruleId;
    }
    return (
      <AddMoreSub
        selectedItems={
          ruleId ? [] : this.currentStore.listData.map((e) => e.id)
        }
        addMoreSubStore={this.addMoreSubStore}
        childRef={(ref) => {
          this.defailFromRef = ref;
          this.addMoreSubChild = ref;
        }}
        id={ruleId}
      ></AddMoreSub>
    );
  };

  renderFooterDetail = () => {
    const { t } = this.props;
    return (
      <div>
        <Button onClick={this.addSubscriberToRule}>
          {t("alarm_rules.add_subscribers")}
        </Button>
      </div>
    );
  };

  addSubscriberToRule = () => {
    const { ruleId } = this.props;
    const newSubscriberList = this.addMoreSubStore?.getSelectedItems();
    if (ruleId) {
      this.currentStore?.updateSubscriberAdd(newSubscriberList);
      this.handleDrawerOnClose(true);
    } else {
      this.currentStore?.addItem(newSubscriberList, () => {
        this.handleDrawerOnClose(true);
      });
    }
  };

  submitDeleteUser = (user) => {
    const {
      ruleId,
      rootStore: { alarmRuleStore },
    } = this.props;
    if (ruleId) {
      this.currentStore?.updateSubscriberDelete([user]);
    } else {
      this.currentStore?.removeItem([user]);
      alarmRuleStore.createSubscriberList = this.currentStore?.listData;
    }
  };

  render() {
    return <div className="wrap-alarm-rule-subscriber">{super.render()}</div>;
  }
}

export default withTranslation()(AlarmRuleSubscriber);
