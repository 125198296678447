import { Icon, Tooltip, Checkbox } from "antd";

import React from "react";
import WrapChartComponent from "./WrapChartComponent";

class WrapChartVolume extends WrapChartComponent {
  handleShowHideLegend = (legend) => {
    const {
      dataProps: { hiddenLegend },
      updateFilterData,
    } = this.props;
    let newHiddenLegend = [...hiddenLegend];

    if (newHiddenLegend?.includes(legend)) {
      newHiddenLegend = newHiddenLegend.filter((item) => item !== legend);
    } else {
      newHiddenLegend.push(legend);
    }
    updateFilterData("hiddenLegend", newHiddenLegend);
  };

  render() {
    const { t, isCompareMode, isPrintMode, children, dataProps } = this.props;
    return (
      <div
        style={{ height: this.props.height, position: "relative" }}
        className="wrap-chart-volume"
      >
        <div className="chart-info flex">
          <div className="font-bold mr-16">
            {t("analysis.phase_name") + " " + dataProps.data.phase}
          </div>
          <div className="mr-16">
            {t("analysis.avg_count_per_hour") + " "}
            <strong>{dataProps.data.avg_per_hour} </strong>
            (Raw count)
          </div>
          <div>
            {t("analysis.avg_count_per_day") + " "}{" "}
            <strong>{dataProps.data.avg_per_day} </strong>
            (Raw count)
          </div>
        </div>
        <div className="flex h-full w-full">
          <div className="relative w-full">
            {this.state.isZomming && (
              <Tooltip placement="top" title={t("analysis.zoom_explaination")}>
                <Icon
                  onClick={this.clearZoomingChart}
                  className="chart-zoom-volume"
                  type="reload"
                />
              </Tooltip>
            )}
            {!isCompareMode ? (
              <div className="chart-container w-full">
                <div className=" h-full chart-detail-container w-full">
                  {React.cloneElement(children, {
                    onChartZoom: this.handleChartZoom,
                    ref: this.chartData,
                  })}
                </div>
              </div>
            ) : (
              <div
                className={
                  isPrintMode
                    ? "chart-container compare-chart"
                    : "chart-container"
                }
              >
                <div className="h-full chart-detail-container ">
                  {React.cloneElement(children, {
                    onChartZoom: this.handleChartZoom,
                    ref: this.chartData,
                  })}
                </div>
              </div>
            )}
          </div>
          <div className="w-240 px-8 chart-info-legend styled-scroll">
            {dataProps.data.all_legend?.map((legend) => (
              <div className="w-full py-8" key={legend.key}>
                <Checkbox
                  className="w-full"
                  checked={!dataProps.hiddenLegend?.includes(legend.key)}
                  style={{ "--checkbox-chart-color": legend.color }}
                  onChange={() => {
                    this.handleShowHideLegend(legend.key);
                  }}
                >
                  {legend.label}
                </Checkbox>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default WrapChartVolume;
