import { Button, PageHeader, Skeleton } from "antd";
import { FieldArray, Form, Formik } from "formik";
import { inject, observer } from "mobx-react";
import { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import CorridorSelectIntersection from "./CorridorSelectIntersection";
import FormCorridorDetail from "./FormCorridorDetail";
import TableCorridorList from "./TableCorridorList";
import React from "react";
import { showNotification } from "utils/helper";
import { Prompt } from "react-router";

/**
 * Component for showing details of the user.
 *
 * @component
 */
@inject("rootStore")
@observer
class CorridorDetailPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visibleModalIntersection: false,
    };
    this.formikProps = null;
  }

  toggleModal = () => {
    this.setState({
      visibleModalIntersection: !this.state.visibleModalIntersection,
    });
  };
  componentDidMount() {
    const {
      history,
      match: { params },
      rootStore: {
        corridorStore: {
          getAllAgencies,
          formData,
          saveFormData,
          getCorrridorDetails,
        },
        commonStore,
        myStore,
      },
    } = this.props;

    const currentAgency = myStore.currentAgency;
    const superAdmin = commonStore.isSuperAdmin();

    if (!superAdmin) {
      saveFormData({
        ...formData,
        agency_id: currentAgency.agency_id,
      });

      if (params?.id) {
        getCorrridorDetails(params?.id, () => {
          history.push("/404");
        });
      }
    } else {
      getAllAgencies(() => {
        if (params?.id) {
          getCorrridorDetails(params?.id, () => {
            history.push("/404");
          });
        }
      });
    }
    window.addEventListener("beforeunload", this.handleAlertUnsave);
  }
  handleAlertUnsave = (e) => {
    if (!this.formikProps.dirty) return undefined;
    var confirmationMessage =
      "It looks like you have been editing something. " +
      "If you leave before saving, your changes will be lost.";

    (e || window.event).returnValue = confirmationMessage; //Gecko + IE
    return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
  };

  componentWillUnmount() {
    const {
      rootStore: {
        corridorStore: { resetFormData },
      },
    } = this.props;
    window.removeEventListener("beforeunload", this.handleAlertUnsave);
    resetFormData();
  }
  validate = (values) => {
    const { t } = this.props;
    const errors = {};

    if (values.corridor_intersections?.length < 2) {
      errors.corridor_intersections = t("corridor.min_num_int_error");
    }

    return errors;
  };
  render() {
    const {
      t,
      match: { params },
      history,
      rootStore: {
        corridorStore: {
          saveEditCorridor,
          resetFormData,
          loadingSubmitDetailPage,
          formData,
          loadingCorridorDetail,
          loading,
          submitCreateCorridor,
        },
      },
    } = this.props;
    return (
      <div className="wrap-alarm-rule-detail-page">
        {loadingCorridorDetail || loading ? (
          <div className="p-16">
            <Skeleton />
            <Skeleton />
          </div>
        ) : (
          <Formik
            initialValues={{
              ...formData,
              corridor_intersections: [...formData.corridor_intersections],
            }}
            validate={this.validate}
            enableReinitialize
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={(values, actions) => {
              if (params?.id) {
                saveEditCorridor(
                  params?.id,
                  {
                    ...values,
                    corridor_intersections: values.corridor_intersections.map(
                      (el, id) => {
                        return {
                          ...el,
                          intersection_id: el.id,
                          number: id,
                        };
                      }
                    ),
                  },
                  () => {
                    showNotification(
                      "success",
                      t("success"),
                      t("update_success")
                    );
                  },
                  (errMessage) => {
                    errMessage?.errors?.map((fieldData) => {
                      showNotification("error", t("error"), fieldData.message);
                    });
                  }
                );
              } else
                submitCreateCorridor(
                  {
                    ...values,
                    corridor_intersections: values.corridor_intersections.map(
                      (el, id) => {
                        return {
                          ...el,
                          intersection_id: el.id,
                          number: id,
                        };
                      }
                    ),
                  },
                  async () => {
                    showNotification(
                      "success",
                      t("success"),
                      t("create_success")
                    );
                    resetFormData();
                    actions.resetForm();
                    await Promise.resolve();
                    history.push("/corridor");
                  },
                  (errMessage) => {
                    errMessage?.errors?.map((fieldData) => {
                      showNotification("error", t("error"), fieldData.message);
                    });
                  }
                );
            }}>
            {(form) => {
              this.formikProps = form;
              return (
                <PageHeader
                  title={
                    params?.id
                      ? t("edit_item", { item: t("corridor") })
                      : t("create_item", { item: t("corridor") })
                  }
                  extra={
                    <div className="header-function">
                      <Button
                        onClick={async () => {
                          resetFormData();
                          form.resetForm();
                          await Promise.resolve();
                          history.push("/corridor");
                        }}>
                        {t("cancel")}
                      </Button>

                      <Button
                        loading={loadingSubmitDetailPage}
                        name="submit"
                        type="primary"
                        htmlType="submit"
                        onClick={() => {
                          form.submitForm();
                        }}>
                        {t("save")}
                      </Button>
                    </div>
                  }>
                  <Prompt
                    when={form.dirty}
                    message="You have unsaved changes, are you sure you want to leave?"
                  />
                  <Form>
                    {/* form info global */}
                    <FormCorridorDetail
                      id={params?.id}
                      form={form}
                      toggleModal={this.toggleModal}
                    />

                    {/* table corridor */}
                    <FieldArray name="corridor_intersections">
                      {(fieldArrayHelper) => {
                        return (
                          <>
                            <TableCorridorList {...fieldArrayHelper} />
                            <CorridorSelectIntersection
                              context_id={params?.id}
                              visible={this.state.visibleModalIntersection}
                              toggleModal={this.toggleModal}
                              {...fieldArrayHelper}
                            />
                          </>
                        );
                      }}
                    </FieldArray>
                  </Form>
                </PageHeader>
              );
            }}
          </Formik>
        )}
      </div>
    );
  }
}

export default withRouter(withTranslation()(CorridorDetailPage));
