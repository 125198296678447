import * as d3 from "d3";
import React, { Component, createRef } from "react";
import "./style.scss";
import { CHART_COLOR_SCHEMA, TLC_EVENTS } from "utils/constants";
import { withTranslation } from "react-i18next";

const colorAfterEvent = (event) => {
  switch (event) {
    case TLC_EVENTS.GREEN:
      return CHART_COLOR_SCHEMA.GREEN_SIEMENS;
    case TLC_EVENTS.YELLOW:
      return CHART_COLOR_SCHEMA.YELLOW;
    case TLC_EVENTS.RED:
      return CHART_COLOR_SCHEMA.RED;
    default:
      return CHART_COLOR_SCHEMA.GREEN_SIEMENS;
  }
};

const colorBeforeEvent = (event) => {
  switch (event) {
    case TLC_EVENTS.GREEN:
      return CHART_COLOR_SCHEMA.RED;
    case TLC_EVENTS.YELLOW:
      return CHART_COLOR_SCHEMA.GREEN_SIEMENS;
    case TLC_EVENTS.RED:
      return CHART_COLOR_SCHEMA.YELLOW;
    default:
      return CHART_COLOR_SCHEMA.GREEN_SIEMENS;
  }
};

const tlcEventSliceTime = (from, to, periods) => {
  const slice = [];
  let data;
  if (periods[0].datetime > from) {
    data = [from, periods[0].datetime];
    data.value = periods[0].datetime - from;
    data.color = colorBeforeEvent(periods[0].event);
    data.parameter = periods[0].parameter;
    slice.push(data);
  }
  periods.forEach((el, i) => {
    if (i < periods.length - 1) {
      data = [el.datetime, periods[i + 1].datetime];
      data.value = periods[i + 1].datetime - el.datetime;
      data.color = colorAfterEvent(el.event);
      data.parameter = el.parameter;
    } else if (el.datetime < to) {
      data = [el.datetime, to];
      data.value = to - el.datetime;
      data.color = colorAfterEvent(el.event);
      data.parameter = el.parameter;
    }
    slice.push(data);
  });
  return slice;
};

const yellowTrapEndDetect = (
  from,
  to,
  leftTurnPhases,
  opposingThroughPhases
) => {
  const left =
    leftTurnPhases.find(
      (el) => el.datetime > from && el.event === TLC_EVENTS.RED
    )?.datetime || to;
  const opposing =
    opposingThroughPhases.find(
      (el) => el.datetime > from && el.event === TLC_EVENTS.YELLOW
    )?.datetime || to;
  return left > opposing ? opposing : left;
};

class SimpleYellowTrapChart extends Component {
  constructor(props) {
    super(props);
    //current SVG object
    this.ref = createRef();
    this.ctx = null;
  }
  /**
   * componentDidMount
   * inital mini map control
   * we draw brush and axisX on here
   */
  componentDidMount() {
    const [width, height] = this.getContext();
    this.drawChart(width, height);
  }

  getContext = () => {
    const svg = d3.select(this.ref?.current);

    if (svg) {
      return [
        parseFloat(window.getComputedStyle(svg.node().parentNode)?.width),
        parseFloat(window.getComputedStyle(svg.node())?.height),
      ];
    }

    return [];
  };

  drawChart = (width, height) => {
    const {
      t,
      leftTurnPhaseEvents,
      leftTurnPhase,
      opposingThroughPhase,
      opposingThroughPhaseEvents,
      yellowTrapEvent,
    } = this.props;

    const leftTurnPhases = leftTurnPhaseEvents.map((phase) => ({
      ...phase,
      datetime: d3.isoParse(phase.datetime),
    }));
    const opposingThroughPhases = opposingThroughPhaseEvents.map((phase) => ({
      ...phase,
      datetime: d3.isoParse(phase.datetime),
    }));
    const fromTime =
      leftTurnPhases[0].datetime > opposingThroughPhases[0].datetime
        ? opposingThroughPhases[0].datetime
        : leftTurnPhases[0].datetime;
    const toTime =
      leftTurnPhases[leftTurnPhases.length - 1].datetime >
      opposingThroughPhases[opposingThroughPhases.length - 1].datetime
        ? leftTurnPhases[leftTurnPhases.length - 1].datetime
        : opposingThroughPhases[opposingThroughPhases.length - 1].datetime;
    const yellowTrapStart = d3.isoParse(yellowTrapEvent.datetime);
    const yellowTrapEnd = yellowTrapEndDetect(
      yellowTrapStart,
      toTime,
      leftTurnPhases,
      opposingThroughPhases
    );

    const svg = d3.select(this.ref?.current);
    const leftTurnPhaseData = tlcEventSliceTime(
      fromTime,
      toTime,
      leftTurnPhases
    );
    leftTurnPhaseData.index = 0;
    leftTurnPhaseData.key = "leftTurnPhaseEvents";
    const opposingThroughPhaseData = tlcEventSliceTime(
      fromTime,
      toTime,
      opposingThroughPhases
    );
    opposingThroughPhaseData.index = 1;
    opposingThroughPhaseData.key = "opposingThroughPhaseEvents";
    const data = [leftTurnPhaseData, opposingThroughPhaseData];

    const x = d3.scaleTime().domain([fromTime, toTime]).range([0, width]);

    const y = d3
      .scaleBand()
      .domain(data.map((el) => el.key))
      .rangeRound([height - 30, 30]);

    const xAxis = (g) =>
      g
        .attr("transform", `translate(0,${height - 42})`)
        .call(
          d3
            .axisBottom(x)
            .ticks(4)
            .tickFormat(d3.timeFormat("%H:%M:%S"))
            .tickSize(4)
            .tickSizeOuter(4)
        )
        .call((g) => g.selectAll(".tick line").attr("visibility", "hidden"));

    svg.attr("viewBox", [0, 0, width, height]);

    svg.append("g").call(xAxis);

    // Draw yellow trap text
    svg
      .append("g")
      .append("text")
      .text(
        `P${opposingThroughPhase}: ${t("yellow_trap.opposing_through_phase")} `
      ) // must replace with  i18n
      .attr("x", 0)
      .attr("y", height - y("leftTurnPhaseEvents") - 52)
      .attr("fill", "var(--text)");

    svg
      .append("g")
      .append("text")
      .text(`P${leftTurnPhase}: ${t("yellow_trap.left_turn_phase")} `) // must replace with  i18n
      .attr("x", 0)
      .attr("y", height + y.bandwidth() - 52 - y("leftTurnPhaseEvents"))
      .attr("fill", "var(--text)");

    // Draw yellow trap period

    svg
      .append("g")
      .append("rect")
      .attr("x", function () {
        return x(yellowTrapStart);
      })
      .attr("y", y("leftTurnPhaseEvents") - y.bandwidth() - 12)
      .attr("height", height + y.bandwidth() - 32 - y("leftTurnPhaseEvents"))
      .attr("width", function () {
        return x(yellowTrapEnd) - x(yellowTrapStart);
      })
      .attr("fill", CHART_COLOR_SCHEMA.YELLOW_TRAP);
    // const handleMouseMove = (mouseX, mouseY, itemPos) => {
    //   this.hightLineObject = chartObject
    //   .append("g")
    //   .attr("class", "hightline-group");
    // };
    // Draw time space
    svg
      .append("g")
      .selectAll("g")
      // Enter in the stack data = loop key per key = group per group
      .data(data)
      .enter()
      .append("g")
      .selectAll("rect")
      // enter a second time = loop subgroup per subgroup to add all rectangles
      .data(function (d) {
        return d.map((el) => ({ ...el, key: d.key }));
      })
      .enter()
      .append("rect")
      .attr("x", function (d) {
        return x(d[0]);
      })
      .attr("y", function (d) {
        return y(d.key) + y.bandwidth() / 4 - 12;
      })
      .attr("height", y.bandwidth() / 2)
      .attr("width", function (d) {
        return x(d[1]) - x(d[0]);
      })
      // .on("mousemove", (itemPos) => {
      //   var mouseX = d3.event.layerX || d3.event.offsetX - this.ref.current.getBoundingClientRect().x ;
      //   var mouseY = d3.event.layerY || d3.event.offsety - this.ref.current.getBoundingClientRect().y;
      //   this.handleMouseMove(mouseX, mouseY, itemPos);
      // })
      .attr("fill", function (d) {
        return d.color;
      });

    // Draw bar text
    svg
      .append("g")
      .selectAll("g")
      .data(data)
      .enter()
      .append("g")
      .selectAll("Text")
      .data(function (d) {
        return d.map((el) => ({ ...el, key: d.key }));
      })
      .enter()
      .append("text")
      .attr("text-anchor", "middle")
      .attr("dominant-baseline", "middle")
      .attr("x", function (d) {
        return (x(d[0]) + x(d[1])) / 2;
      })
      .attr("y", function (d) {
        return y(d.key) + y.bandwidth() / 2 - 12;
      })
      .attr("font-family", "sans-serif")
      .attr("font-size", "11px")
      .attr("fill", "#333")
      .text((d) => {
        return d.color !== CHART_COLOR_SCHEMA.RED
          ? `P${d.parameter}(${Math.round(d.value / 1000)}s)`
          : "";
      })
      .each(function wrap(d) {
        const containerWidth = x(d[1]) - x(d[0]);
        const self = d3.select(this);
        let textLength = self.node().getComputedTextLength();
        let text = self.text();

        while (textLength > containerWidth - 2 * 4 && text.length > 0) {
          text = text.slice(0, -1);
          self.text(text + "...");
          textLength = self.node().getComputedTextLength();
        }

        if (textLength > containerWidth - 2 * 4) {
          self.text("");
        }
      });

    const line = d3.line();

    // draw vertical line
    const padding = 5;
    const tooltipWidth = 180;
    const tooltipHeight = 24;
    const highlight = svg
      .append("g")
      .attr("class", "vertical-highlight")
      .attr("opacity", 0);

    highlight
      .append("path")
      .attr("class", "vertical-line")
      .attr("fill", "none")
      .attr("stroke", "#000")
      .attr("stroke-width", 1.5)
      // .attr("stroke-opacity", 0)
      .attr(
        "d",
        line([
          [0, 0],
          [0, height - 42],
        ])
      );

    highlight
      .append("rect")
      .attr("width", tooltipWidth) //hard code the width of bo
      .attr("height", 24)
      .style("background", "#000")
      .attr("class", "tooltip-box")
      .attr("stroke", "var(--text)")
      .attr("fill", "var(--background)");

    highlight
      .append("text")
      .text("")
      .attr("text-anchor", "middle")
      .attr("class", "tooltip-info-text")
      .attr("width", tooltipWidth)
      .attr("height", tooltipHeight)
      .attr("dominant-baseline", "middle")
      .attr("x", tooltipWidth / 2)
      .attr("y", tooltipHeight / 2)
      .attr("fill", "red");

    svg.on("mouseover", function () {
      svg.selectAll(".vertical-highlight").attr("opacity", 1);
    });

    svg.on("mousemove", function () {
      const point = d3.mouse(this);
      const time = x.invert(point[0]);
      let toolTipTransformX = point[0] + padding;
      toolTipTransformX = Math.min(
        toolTipTransformX,
        width - tooltipWidth - padding
      );
      let toolTipTransformY = point[1];
      toolTipTransformY = Math.min(toolTipTransformY, height - 24 - tooltipHeight);

      svg
        .selectAll(".vertical-line")
        .attr("transform", `translate(${point[0]},0)`);
      svg
        .selectAll(".tooltip-box")
        .attr(
          "transform",
          `translate(${toolTipTransformX},${toolTipTransformY})`
        );
      svg
        .selectAll(".tooltip-info-text")
        .attr(
          "transform",
          `translate(${toolTipTransformX},${toolTipTransformY})`
        )
        .text(time.toLocaleString());
    });

    svg.on("mouseleave", function () {
      svg.selectAll(".vertical-highlight").attr("opacity", 0);
    });
  };

  render() {
    return (
      <div className="yellow-trap-chart-wrap">
        <div className="yellow-trap-chart-container">
          <svg ref={this.ref} />
        </div>
      </div>
    );
  }
}

export default withTranslation()(SimpleYellowTrapChart);
