import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { withRouter } from 'react-router'
import { withTranslation } from 'react-i18next'
import helper from '../../../../utils/helper'
import { Avatar, Menu, Radio } from 'antd'
import './style.scss'

@inject('rootStore')
@observer
class AgencySwitcher extends Component {

  /**
   * componentDidMount
   *
   * get all available agencies belong to user
   *
   */
  componentDidMount() {
    const { rootStore: { myStore } } = this.props;
    myStore?.getAllAvaiableAgencies();
  }

  /**
   * handleChaneAgency
   *
   * switch agency of current user
   *
   */
  handleChaneAgency = (id) => {
    const { rootStore: { myStore } } = this.props;
    myStore?.switchAgency({ new_agency_id: id })
  }

  /**
   * render
   * 
   * @return  {Component} 
   */
  render() {
    const { rootStore: { myStore } } = this.props;
    let agency = myStore?.getCurrentAgency();
    return <div className="wrap-agency-switcher">
      <Menu>
        {myStore?.availableAgencies?.map(e =>
          <Menu.Item key={e.id} onClick={() => this.handleChaneAgency(e.id)} disabled={agency?.agency_name === e.name}>
            <div className="agency-item">
              <div>
                <Avatar style={{ backgroundColor: helper.getRandomColor() }}>{helper.getFirstsLetterFromWords(e.name)?.substring(0, 2)}</Avatar>
                <span className="agency-name">{e.name}</span>
              </div>
              <div><Radio checked={agency?.agency_name == e.name}></Radio></div>
            </div>
          </Menu.Item>
        )}
      </Menu>
    </div>
  }
}

export default withRouter(withTranslation()(AgencySwitcher));