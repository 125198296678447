import React, { Component } from "react";
import { Modal, Button } from "antd";
import Control from "react-leaflet-control";
import { withTranslation } from "react-i18next";
import { observer, inject } from "mobx-react";
import { Map, Marker, TileLayer } from "react-leaflet";
import { SELECTED_PIN, DEFAULT_PIN } from "utils/constants";

import "./style.scss";

@inject("rootStore")
@observer
class MapPopup extends Component {
  constructor(props) {
    super(props);
    this.currentStore = props.rootStore.optimizerStore;
  }

  render() {
    const { t, onClose, visible, onMapSelect } = this.props;
    return (
      <Modal
        visible={visible}
        width={"80%"}
        title={t("map_popup.title")}
        footer={null}
        onCancel={onClose}
        destroyOnClose={true}
      >
        <Map
          className="map-full-screen"
          zoom={this.currentStore?.currentMapZoom}
          center={this.currentStore?.currentMapCenter}
        >
          <TileLayer
            url={process.env.REACT_APP_OPEN_STREET_MAP}
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />
          <Control position="bottomright">
            <Button
              icon="fullscreen-exit"
              className="map-fullscreen"
              onClick={onClose}
            />
          </Control>
          {this.currentStore?.listIntersections?.map((intersection) => (
            <Marker
              icon={
                this.currentStore?.formData?.intersection_id ===
                intersection?.id
                  ? SELECTED_PIN
                  : DEFAULT_PIN
              }
              position={[intersection.latitude, intersection.longitude]}
              key={intersection.id}
              onClick={() => onMapSelect(intersection.id)}
            ></Marker>
          ))}
        </Map>
      </Modal>
    );
  }
}

export default withTranslation()(MapPopup);
