import React from "react";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import { Col, Form, Input, Tag, Tooltip } from "antd";
import moment from "moment";
import { DATE_FORMAT, AVAILABLE } from "utils/constants";
import BaseManagementComponents from "../../../../components/BaseManagementComponents";
import { RESOURCE_LIST } from "utils/constants";

/**
 * Component for showing lists of intersection belong to agency.
 *
 * @component
 */
@inject("rootStore")
@observer
class IntersectionTab extends BaseManagementComponents {
  /**
   * contructor of component
   * @param   {object} props  props of component
   */
  constructor(props) {
    super(props.store, props);
    this.enableActionBox = false;
    this.enableCreateButton = false;
    this.resourceName = RESOURCE_LIST.AGENCY_DETAIL;
    const { t } = props;
    this.columns = [
      {
        title: t("intersection_tab.name"),
        dataIndex: "name",
        key: "name",
        sorter: true,
        sortDirections: ["descend", "ascend"],
        ellipsis: true,
      },
      {
        title: t("intersection_tab.status"),
        dataIndex: "status",
        key: "status",
        render: (status) => (
          <Tag color={status === AVAILABLE ? "green" : "volcano"} key={status}>
            {status?.toUpperCase()}
          </Tag>
        ),
        ellipsis: true,
      },
      // {
      //   title: t('intersection_tab.address'),
      //   dataIndex: 'address',
      //   key: 'address',
      //   ellipsis: true,
      // },
      {
        title: t("intersection_tab.updated_time"),
        dataIndex: "last_modified_at",
        key: "last_modified_at",
        render: (last_modified_at) =>
          moment(last_modified_at).format(DATE_FORMAT.full),
        ellipsis: true,
      },
    ];
  }

  /**
   * renderFilterComponent
   * this function return the filter box
   * @return  {Component}
   */
  renderFilterComponent = () => {
    const { t } = this.props;
    return (
      <div>
        <Col span={4}>
          <Tooltip placement="top" title={t("intersection.search_tooltip")}>
            <Input
              defaultValue={this.currentStore?.filterData?.text}
              onChange={(e) =>
                this.currentStore?.handleFilterDataChange(
                  "text",
                  e.target?.value
                )
              }
              placeholder={t("intersection_tab.search_placholer")}
              onPressEnter={this.currentStore?.handleFilerAction}
            />
          </Tooltip>
        </Col>
      </div>
    );
  };

  /**
   * render
   * @return  {Component}
   */
  render() {
    return super.render();
  }
}

const IntersectionTabForm = Form.create({ name: "agency_intersection_tab" })(
  IntersectionTab
);

export default withRouter(withTranslation()(IntersectionTabForm));
