import BaseApi from "./BaseApi";
/**
 * Service for user management.
 *
 * @class
 */
class IntersectionAvailbleService extends BaseApi {
  constructor(parentStore, pathApi) {
    super(pathApi + "/intersections/available", parentStore);
    this.moduleName = pathApi + "/intersections/available";
    this.dataKey = "intersections";
    this.keyField = "id";
  }
}

export default IntersectionAvailbleService;
