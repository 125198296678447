import React, { Component } from "react";
import { DatePicker, Modal, Select, Typography } from "antd";
import { withTranslation } from "react-i18next";
import { DATE_FORMAT } from "../../utils/constants";
import moment from "moment";
import "./style.scss";
import { TIMEZONES } from "../../utils/timezone";
const { Option } = Select;

class TriggerAlarmRulePopupComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      date: moment().startOf('day'),
    };
  }

  onChangeDate = (val) => {
    this.setState({ date: val });
  };

  render() {
    const { t, alarm, visible, onSave, onCancel } = this.props;
    const { date } = this.state;
    return (
      <Modal
        visible={visible}
        title={t("alarm_rules.run_alarm_rule_manually")}
        onOk={() => onSave(alarm, date.format(DATE_FORMAT.day_only_backend))}
        onCancel={onCancel}
        okText={t("save")}
        okButtonProps={{
          className: "save-btn",
        }}
        destroyOnClose
      >
        <Typography className="popup-content">
          {t("alarm_rules.time_zone", {
            name: alarm?.name,
          })}
        </Typography>
        <Select
          className="timezone"
          value={alarm?.time_range?.timezone}
          disabled
        >
          {TIMEZONES.map((e) => (
            <Option value={e?.value} key={e?.value}>
              {e?.label}
            </Option>
          ))}
        </Select>
        <Typography className="popup-content">
          {t("alarm_rules.select_date_trigger_alarm_rule", {
            name: alarm?.name,
          })}
        </Typography>
        <DatePicker
          mode={"date"}
          allowClear={false}
          format={DATE_FORMAT.day_only_backend}
          value={date}
          onChange={this.onChangeDate}
          showToday={true}
        />
      </Modal>
    );
  }
}

export default withTranslation()(TriggerAlarmRulePopupComponent);
