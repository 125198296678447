import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { withTranslation } from "react-i18next";
import { Icon, Button, Modal } from "antd";
import { NOTIFICATION_TYPE, TIMEOUT_SHOW_NOTI } from "utils/constants";
import "./style.scss";
import TableInputTopology from "./TableInputTopology";
import { Prompt } from "react-router";
import ChoosingAvalableConfigs from "./ChoosingAvalableConfigs";

@inject("rootStore")
@observer
class IntersectionTopology extends Component {
  constructor(props) {
    super(props);
    this.state = {
      actionStatus: null,
      visibleModal: false,
      idError: [],
    };
  }
  closeModal = () => {
    this.setState({
      visibleModal: false,
    });
  };
  validateFormData = () => {
    const {
      rootStore: {
        intersectionConfigStore: { topologyConfig },
      },
    } = this.props;
    let idError = [];
    if (topologyConfig.length)
      topologyConfig.forEach((direction) => {
        let isValidDirection =
          direction.Direction &&
          topologyConfig.filter((e) => e.Direction === direction.Direction)
            .length === 1;
        if (!isValidDirection) idError.push(direction.id);
        if (direction.Phases.length) {
          direction.Phases.forEach((phase) => {
            let isValidPhaseNumber =
              phase.PhaseNumber &&
              direction.Phases.filter(
                (e) => e.PhaseNumber === phase.PhaseNumber
              ).length === 1;
            if (!isValidPhaseNumber) idError.push(phase.id);
            if (phase.Lanes.length) {
              phase.Lanes.forEach((lane) => {
                let isValidMovement =
                  lane.Movement &&
                  phase.Lanes.filter((e) => e.Movement === lane.Movement)
                    .length === 1;
                if (!isValidMovement) idError.push(lane.id);
                if (lane.Detectors.length) {
                  lane.Detectors.forEach((detector) => {
                    let isValidDetectorNumber =
                      detector.DetectorNumber &&
                      lane.Detectors.filter(
                        (e) => e.DetectorNumber === detector.DetectorNumber
                      ).length === 1;
                    if (!isValidDetectorNumber) idError.push(detector.id);
                  });
                }
              });
            }
          });
        }
      });
    this.setState({ idError: idError });
    return idError.length > 0 ? false : true;
  };
  handleAlertUnsave = (e) => {
    const {
      rootStore: {
        intersectionConfigStore: { dirtyTopologyForm },
      },
    } = this.props;
    if (!dirtyTopologyForm) return undefined;
    var confirmationMessage =
      "It looks like you have been editing something. " +
      "If you leave before saving, your changes will be lost.";

    (e || window.event).returnValue = confirmationMessage; //Gecko + IE
    return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
  };
  componentDidMount() {
    window.addEventListener("beforeunload", this.handleAlertUnsave);
  }
  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.handleAlertUnsave);
  }
  render() {
    const {
      t,
      rootStore: {
        intersectionConfigStore: { saveTopologyConfig, dirtyTopologyForm },
        topologyConfigStore: { getData },
      },
    } = this.props;

    return (
      <>
        <div className="flex justify-space-beetwen px-8">
          <Prompt
            when={dirtyTopologyForm}
            message="You have unsaved changes, are you sure you want to leave?"
          />
          <div className="flex items-center">
            <div className="flex items-center">
              <Icon type="info-circle" className="mr-8" />
              <span>{t("intersection_config.topology_info")}</span>
            </div>
          </div>
          <div>
            <Modal
              title={t("intersection_config.config_history_topology")}
              style={{ top: 40 }}
              visible={this.state.visibleModal}
              onCancel={() => this.setState({ visibleModal: false })}
              width={"70%"}
              className="list-config-modal"
              footer={[
                <Button
                  key="back"
                  onClick={() => this.setState({ visibleModal: false })}>
                  {t("intersection.cancel_btn")}
                </Button>,
              ]}>
              <ChoosingAvalableConfigs closeModal={this.closeModal} />
            </Modal>
            <Button
              type="primary"
              className="mr-8"
              onClick={() => {
                getData();
                this.setState({ visibleModal: true });
              }}>
              {t("intersection.coppy_btn")}
            </Button>
            <Button
              type="primary"
              onClick={() => {
                if (this.validateFormData()) {
                  this.setState({
                    actionStatus: null,
                  });
                  saveTopologyConfig(
                    () => {
                      this.setState({
                        actionStatus: {
                          type: NOTIFICATION_TYPE.SUCCESS,
                          message: t("update_success"),
                        },
                      });
                      setTimeout(() => {
                        this.setState({
                          actionStatus: null,
                        });
                      }, TIMEOUT_SHOW_NOTI);
                    },
                    () => {
                      this.setState({
                        actionStatus: {
                          type: NOTIFICATION_TYPE.ERROR,
                          message: t("message.500"),
                        },
                      });
                    }
                  );
                } else {
                  this.setState({
                    actionStatus: {
                      type: NOTIFICATION_TYPE.ERROR,
                      message: t("intersection_config.invalid_form"),
                    },
                  });
                }
              }}>
              {t("save")}
            </Button>
          </div>
        </div>

        <TableInputTopology
          actionStatus={this.state.actionStatus}
          t={t}
          x
          idError={this.state.idError}
        />
      </>
    );
  }
}

export default withTranslation()(IntersectionTopology);
