import {
  Button,
  Descriptions,
  Empty,
  Modal,
  PageHeader,
  Skeleton,
  Tabs,
} from "antd";
import { inject, observer } from "mobx-react";
import moment from "moment";
import queryString from "query-string";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import { DATE_FORMAT } from "utils/constants";
import CoordData from "./CoordData";
// import CycleLength from "./CycleLength";
import Detector from "./Detector";
import GeneralInfo from "./GeneralInfo";
import IntersectionTopology from "./IntersectionTopology";
import ListConfigModalContent from "./ListConfigModalContent";
// import OffsetTime from "./OffsetTime";
import RingStructure from "./RingStructure";
// import Sequence from "./Sequence";
// import SplitMode from "./SplitMode";
import "./style.scss";
const { TabPane } = Tabs;
/**
 * Component for showing agency detail information or intersection belong to agency or user tab.
 *
 * @component
 */
const tabList = [
  {
    key: "general_tab",
    number: 1,
    label: "intersection_config.general_info",
    component: <GeneralInfo />,
  },
  {
    key: "coord_data",
    number: 2,
    label: "intersection_config.coord_data",
    component: <CoordData />,
  },
  {
    key: "detector",
    number: 3,
    label: "intersection_config.detector",
    component: <Detector />,
  },
  {
    key: "ring_structure",
    number: 4,
    label: "intersection_config.ring_structure",
    component: <RingStructure />,
  },
  {
    key: "intersection_topology",
    number: 5,
    label: "intersection_config.topology",
    component: <IntersectionTopology />,
  },
];
@inject("rootStore")
@observer
class IntersectionConfig extends Component {
  /**
   * contructor of component
   * @param   {object} props  props of component
   */
  constructor(props) {
    super(props);
    this.state = {
      visibleModal: false,
    };
    // this.listConfigRef = React.createRef();
  }

  /**
   * componentDidMount
   * clear all error list
   */
  componentDidMount = () => {
    const {
      t,
      rootStore: {
        intersectionConfigStore: {
          setCurrentIntersectionAgencyQuery,
          getLastedConfig,
          getMetaData,
        },
        topologyConfigStore,
      },
    } = this.props;
    document.title = t("intersection.page-title");
    let queryData = queryString.parse(this.props.location.search);
    setCurrentIntersectionAgencyQuery(queryData);
    topologyConfigStore?.setCurrentIntersectionAgencyQuery({
      agency_id: queryData?.agency_id,
    });
    getLastedConfig();
    getMetaData();
  };

  closeModal = () => {
    this.setState({
      visibleModal: false,
    });
  };
  handleExportJson = () => {
    const {
      rootStore: { intersectionConfigStore },
    } = this.props;
    let jsonObject = {
      configuration: intersectionConfigStore?.currentConfig,
      intersection: intersectionConfigStore?.intersectionInfo,
    };
    let blob = new Blob([JSON.stringify(jsonObject)], {
      type: "text/json;charset=utf-8;",
    });
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(
        blob,
        intersectionConfigStore?.intersectionInfo?.name + ".json"
      );
    } else {
      let link = document.createElement("a");
      if (link.download !== undefined) {
        // feature detection
        // Browsers that support HTML5 download attribute
        let url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute(
          "download",
          intersectionConfigStore?.intersectionInfo?.name + ".json"
        );
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };
  /**
   * render
   *
   * @return  {Component}
   */
  render() {
    const {
      t,
      rootStore: {
        intersectionConfigStore: {
          latest,
          getData,
          currentConfig,
          intersectionInfo,
          spinning,
        },
      },
    } = this.props;
    return (
      <div className="wrap-intersection-config">
        <Modal
          title={t("intersection_config.config_history")}
          style={{ top: 40 }}
          visible={this.state.visibleModal}
          onCancel={() => this.setState({ visibleModal: false })}
          width={"70%"}
          className="list-config-modal"
          footer={[
            <Button
              key="back"
              onClick={() => this.setState({ visibleModal: false })}
            >
              {t("intersection.cancel_btn")}
            </Button>,
          ]}
        >
          <ListConfigModalContent closeModal={this.closeModal} />
        </Modal>
        <PageHeader
          style={{
            border: "1px solid rgb(235, 237, 240)",
            marginBottom: 16,
          }}
          className="intersection-config-header"
          title={intersectionInfo?.name}
          extra={[
            <a
              key="2"
              className="mr-16"
              onClick={() => {
                // this.listConfigRef.current.componentDidMount();
                getData();
                this.setState({ visibleModal: true });
              }}
            >
              {t("intersection_config.see_another_config")}{" "}
            </a>,
            <Button
              key="3"
              onClick={() => this.props.history.push("/intersection")}
            >
              {t("detail.back")}
            </Button>,
            <Button key="1" type="primary" onClick={this.handleExportJson}>
              {t("export_json")}
            </Button>,
          ]}
        >
          {spinning ? (
            <Skeleton spinning />
          ) : intersectionInfo ? (
            <div className="flex" style={{ width: "80%" }}>
              <div>
                <Descriptions size="large" column={3}>
                  <Descriptions.Item
                    label={t("intersection.perflog_updated_time")}
                  >
                    {moment(
                      intersectionInfo?.updated_time,
                      DATE_FORMAT.backend_with_tz
                    ).format(DATE_FORMAT.full)}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("intersection_config.uuid")}>
                    {currentConfig?.config_id}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={t("intersection_config.valid_period")}
                  >
                    {
                      moment(
                        currentConfig?.valid_from_time,
                        DATE_FORMAT.backend
                      ).format(DATE_FORMAT.full) +
                        " - " +
                      moment(
                        currentConfig?.valid_to_time,
                        DATE_FORMAT.backend
                      ).format(DATE_FORMAT.full) +
                        `${latest ? ' (latest)' : ' (historical)'}`
                    }
                  </Descriptions.Item>
                </Descriptions>
              </div>
            </div>
          ) : (
            <Empty />
          )}
        </PageHeader>
        {spinning ? (
          <Skeleton />
        ) : currentConfig ? (
          <Tabs
            className="intersection-config-tabs"
            defaultActiveKey="general_tab"
          >
            {tabList.map((tab) => (
              <TabPane
                className="intersection-config-tab-pane"
                tab={t(tab.label)}
                key={tab.key}
              >
                {tab.component}
              </TabPane>
            ))}
          </Tabs>
        ) : (
          <Empty />
        )}
      </div>
    );
  }
}

export default withRouter(withTranslation()(IntersectionConfig));
