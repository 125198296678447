import { Col, Form, Input, Select, Tooltip } from "antd";
import StatusTag from "components/StatusTag";
import { inject, observer } from "mobx-react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import {
  ACTION_STATUS_VISIBILITY_MAP,
  ACTIONS_LIST,
  RESEND_EMAIL,
  RESOURCE_LIST,
  UNACTIVATED,
  USER_STATUS_LIST
} from "utils/constants";
import BaseManagementComponents from "../../../../components/BaseManagementComponents";
import NewUserForm from "./NewUser";
import "./style.scss";
import React from "react";
import { showNotification } from "../../../../utils/helper";

/**
 * Component for showing lists of the user.
 *
 * @component
 */
const { Option } = Select;

@inject("rootStore")
@observer
class UsersTab extends BaseManagementComponents {
  constructor(props) {
    super(props.store, props);
    const { t } = props;
    this.rowKey = "user_id";
    this.moduleName = "user";
    this.resourceName = RESOURCE_LIST.AGENCY_DETAIL;
    this.columns = this.getTableColumns();
    this.enableActionBox = props.editable;
    this.enableCreateButton = false;
    this.headerButtonActions = [
      {
        key: "add_user",
        className: "create-button",
        title: this.props.t(this.moduleName + ".create"),
        icon: "plus",
        resource: RESOURCE_LIST.AGENCY_DETAIL_ASSIGN_USER,
        onClick: () => {
          this.currentStore?.showCreateOrEditForm(
            t(this.moduleName + ".create_form_title")
          );
        },
        permission: ACTIONS_LIST.CREATE,
      },
    ];
    this.buttonActions = [
      {
        key: "resend_email",
        title: 'Send Activation Email',
        resource: RESOURCE_LIST.USER,
        action: this.currentStore?.sendActivationEmail,
        permission: ACTIONS_LIST.CREATE,
        confirm: true,
        type: "mail",
        confirmTitle: 'resend email',
        confirmContent: 'resend email',
        notEnable: this.disableTableButton(RESEND_EMAIL),
      },
      {
        key: "remove_all",
        title: t("user.remove_all"),
        type: "delete",
        className: "",
        resource: RESOURCE_LIST.AGENCY_DETAIL_REMOVE_USER,
        action: this.currentStore?.removeUser,
        permission: ACTIONS_LIST.EDIT,
        confirm: true,
        confirmTitle: t("user.remove_user_confirm_title"),
        confirmContent: t("user.remove_user_confirm_content"),
      },
    ];

    this.tableInlineActions = [
      {
        key: "resend_email",
        tooltip: t("user.active"),
        resource: RESOURCE_LIST.USER,
        permission: ACTIONS_LIST.CREATE,
        action: this.handleTableAction,
        icon: "mail",
        passingData: (item) => {
          return ["resend_email", [item?.user_id]];
        },
        visible: (item) => {
          return !item?.disable && item?.status === UNACTIVATED;
        },
      },
      {
        key: "remove_all",
        tooltip: t("user.remove"),
        resource: RESOURCE_LIST.AGENCY_DETAIL_REMOVE_USER,
        permission: ACTIONS_LIST.EDIT,
        action: this.handleTableAction,
        icon: "delete",
        passingData: (item) => {
          return ["remove_all", [item?.user_id]];
        },
      },
    ];
    this.pageCustomClass = "agency-user-tab";
  }

  /**
   *
   * comparesSelectedListStatus
   * returns true if the status argument is the same as all selected user arguments and false otherwise
   * @returns {boolean}
   */
  compareSelectedListStatus = (status) => {

    const selectedItemsID = this.currentStore?.selectedItems;
    const selectedItems = this.currentStore?.listData.filter(item => selectedItemsID.includes(item?.user_id))

    return selectedItems.every(item => item?.status === status);
  }

  /**
   *
   * disableSelectedListStatus
   * this function first takes the input action and gets the status the user needs to be in in order to enable the action.
   * returns a function which compares the status of selected users with the status determined from the map
   * @returns {function}
   */
  disableTableButton = (action) => {
    const status = ACTION_STATUS_VISIBILITY_MAP[action]

    return () => {
      return !this.compareSelectedListStatus(status);
    }
  }

  /**
   * return column set of table
   */
  getTableColumns = () => {
    const { t, agency_id } = this.props;
    return [
      {
        title: t("user.name"),
        dataIndex: "name",
        sorter: true,
        sortDirections: ["descend", "ascend"],
        key: "name",
        ellipsis: true,
      },
      {
        title: t("user.email"),
        dataIndex: "email",
        key: "email",
        ellipsis: true,
      },
      {
        title: t("user.role"),
        dataIndex: "roles",
        key: "roles",
        render: (roles) => (
          <span>
            {
              roles?.find((elem) => {
                return elem?.agency_id == agency_id;
              })?.role_name
            }
          </span>
        ),
        ellipsis: true,
      },
      {
        title: t("user.phone"),
        dataIndex: "phone",
        key: "phone",
        ellipsis: true,
      },
      {
        title: t("user.status"),
        dataIndex: "status",
        key: "status",
        render: (status) => <StatusTag status={status}/>,
        // sorter: true,
        // sortDirections: ['descend', 'ascend'],
        ellipsis: true,
      },
    ];
  };

  //-------- render common component ----------
  /**
   * renderFilterComponent
   * this function return the filter box
   * @return  {Component}
   */
  renderFilterComponent = () => {
    const { t } = this.props;
    return (
      <div>
        <Col span={4}>
          <Tooltip placement="top" title={t("users.search_tooltip")}>
            <Input
              maxLength={255}
              defaultValue={this.currentStore?.filterData?.text}
              onChange={(e) =>
                this.currentStore?.handleFilterDataChange(
                  "text",
                  e.target?.value
                )
              }
              placeholder={t("user.search_placholer")}
              onPressEnter={this.currentStore?.handleFilerAction}
            />
          </Tooltip>
        </Col>
        <Col span={4}>
          <Tooltip placement="top" title={t("users.status_tooltip")}>
            <Select
              onChange={(value) =>
                this.currentStore?.handleFilterDataChange("status", value)
              }
              defaultValue={this.currentStore?.filterData?.status}>
              <Option value="null">{t("user.all_status")}</Option>
              {USER_STATUS_LIST?.map((e) => (
                <Option key={e.key} value={e.key}>
                  {t(e.value)}
                </Option>
              ))}
            </Select>
          </Tooltip>
        </Col>
      </div>
    );
  };

  /**
   * render
   *
   * @return  {Component}
   */
  render() {
    return super.render();
  }

  /**
   * handleSubmit
   *
   * @param   {Object} e  event of action submit form
   * @return  {Object}
   */
  handleSubmit = (e) => {
    e.preventDefault();
    const { t } = this.props;
    this.props.form?.validateFields((err, values) => {
      if (!err) {
        this.currentStore?.saveData(
          values,
          () => {
            this.currentStore?.getData();
            this.props.form.resetFields();
            this.currentStore?.hideAddUserPopup();
            showNotification(
              "success",
              t("success"),
              t("update_success")
            );
          },
          (errors) => {
            errors.map((error) => {
              let message = error.message;
              let err = {};
              err[error.field] = {
                value: values[error.field],
                errors: [new Error(message)],
              };
              this.props.form.setFields(err);
              showNotification("error", t("error"), error?.message);
            });
          }
        );
      }
    });
  };

  /**
   * renderDetailData
   * this function return the add new user box
   * @return  {Component}
   */
  renderDetailData = () => {
    return (
      <NewUserForm
        editable={true}
        store={this.currentStore}
        onClose={this.handleDrawerOnClose}
        childRef={(ref) => (this.defailFromRef = ref)}
      />
    );
  };
}

const UsersTabForm = Form.create({ name: "agency_subcriblers" })(UsersTab);
export default withRouter(withTranslation()(UsersTabForm));
