import { Select, Spin, Tabs } from "antd";
import { inject, observer } from "mobx-react";
import React from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import { SOCKET_TOPIC, TSP_KEY_TAB } from "utils/constants";
import IntersectionEventHistory from "../containter/IntersectionEventHistory";
import SummaryTable from "../containter/SummaryTable";
import TSPEventHistory from "../containter/TSPEventHistory";
import TSPMapViewSVG from "../containter/TSPMapViewSVG";
import "./style.scss";

/**
 * Component for Application layout
 *
 * @component
 */
@inject("rootStore")
@observer
class TSPLayout extends React.Component {
  /**
   * contructor of component
   * @param   {object} props  props of component
   */
  ws;
  constructor(props) {
    super(props);
    this.mapViewRef = React.createRef();
    this.intEventRef = React.createRef();
    this.summaryTableRef = React.createRef();
    this.tspEventRef = React.createRef();
    this.state = {
      isChecking: false,
    };
    this.intervelReloadListIntersection = null;
  }

  /**
   * componentDidMount
   *
   * auto redirect to login page for unauthenticated request
   * clean the token when close the browser
   * run script auto refresh token
   * re-update lastest information of current user
   *
   */
  async componentDidMount() {
    const {
      rootStore: { TSPStore, socketStore },
    } = this.props;
    TSPStore.getAllEventTypes();
    this.changeTab(TSP_KEY_TAB.MAP_VIEW);
    TSPStore?.setHeatMapRef(this.mapViewRef);
    // if (this.intervelReloadListIntersection);
    // clearInterval(this.intervelReloadListIntersection);
    if (socketStore.connectionId) {
      try {
        await socketStore.subcribeTopic([
          SOCKET_TOPIC.TSP_EVENT_TOPIC.key,
          SOCKET_TOPIC.NOTI_TOPIC.key,
        ]);
      } catch (error) {}
    }
    try {
      await TSPStore?.getAllAgencies();
      // this.intervelReloadListIntersection = setInterval(() => {
      //   TSPStore.getAllIntersectionsLocation();
      // }, RELOAD_TSP_INTESECTION);
    } catch (error) {}
  }
  async componentWillUnmount() {
    // clearInterval(this.intervelReloadListIntersection);
    const {
      rootStore: { socketStore },
    } = this.props;

    if (socketStore.connectionId) {
      try {
        await socketStore.unsubcribeTopic([SOCKET_TOPIC.NOTI_TOPIC.key]);
      } catch (error) {}
    }
  }
  changeTab = (tab) => {
    const {
      t,
      rootStore,
      rootStore: { TSPStore },
    } = this.props;
    TSPStore.setActiveTab(tab);
    switch (tab) {
      case TSP_KEY_TAB.MAP_VIEW: {
        document.title = t("tsp_dashboard.mapview_page_title");
        break;
      }
      case TSP_KEY_TAB.SUMMARY_TABLE: {
        document.title = t("tsp_dashboard.summary_table_page_title");
        this.summaryTableRef.current?.handleQuerytimeChange(
          rootStore.TSPSummaryTableStore.filterData.range
        );
        break;
      }
      case TSP_KEY_TAB.INT_EVENT_HISTORY: {
        document.title = t("tsp_dashboard.int_event_history_page_title");
        this.intEventRef.current?.handleQuerytimeChange(
          rootStore.intersectionEventHistoryStore.filterData.range
        );

        break;
      }

      case TSP_KEY_TAB.TSP_EVENT_HISTORY: {
        document.title = t("tsp_dashboard.tsp_event_history_page_title");
        this.tspEventRef.current?.handleQuerytimeChange(
          rootStore.TSPEventHistoryStore.filterData.range
        );
        break;
      }

      default: {
        break;
      }
    }
  };
  handleChangeAgency = (value) => {
    const {
      rootStore: { TSPStore },
    } = this.props;
    TSPStore.handleFilterDataChange("agency_id", value);
  };
  /**
   * render
   *
   * @return  {Component}
   */
  render() {
    const {
      t,
      rootStore: { TSPStore, commonStore },
    } = this.props;
    return this?.state?.isChecking ? (
      <Spin className={"fullscreen centralize"} size="large" />
    ) : (
      <div className="wrap-home">
        {commonStore.isSuperAdmin() && (
          <div className="flex tsp-filter-agency" style={{ minWidth: 300 }}>
            <div className="agency-header" style={{ marginRight: 16 }}>
              {t("dashboard.agency")} :
            </div>
            <Select
              className="agency"
              value={TSPStore?.currentAgency?.id}
              onChange={(value) => {
                TSPStore?.handleFilterDataChange("agency_id", value);
              }}
            >
              {TSPStore.listAllAgency.map((agency) => (
                <Select.Option key={agency.id} value={agency.id}>
                  {agency.name}
                </Select.Option>
              ))}
            </Select>
          </div>
        )}
        <div className="tsp-card-container">
          <Tabs
            onChange={this.changeTab}
            type="card"
            activeKey={TSPStore.activeTab}
          >
            <Tabs.TabPane
              disabled={TSPStore.loading}
              forceRender={true}
              tab={t("tsp_dashboard.map_view")}
              key={TSP_KEY_TAB.MAP_VIEW}
            >
              <TSPMapViewSVG ref={this.mapViewRef} t={t} />
            </Tabs.TabPane>
            <Tabs.TabPane
              disabled={TSPStore.loading}
              forceRender={true}
              tab={t("tsp_dashboard.summary_table")}
              key={TSP_KEY_TAB.SUMMARY_TABLE}
            >
              <SummaryTable t={t} ref={this.summaryTableRef} />
            </Tabs.TabPane>
            <Tabs.TabPane
              disabled={TSPStore.loading}
              forceRender={true}
              tab={t("tsp_dashboard.tsp_event_history")}
              key={TSP_KEY_TAB.TSP_EVENT_HISTORY}
            >
              <TSPEventHistory t={t} ref={this.tspEventRef} />
            </Tabs.TabPane>
            <Tabs.TabPane
              disabled={TSPStore.loading}
              forceRender={true}
              tab={t("tsp_dashboard.intersection_event_history")}
              key={TSP_KEY_TAB.INT_EVENT_HISTORY}
            >
              <IntersectionEventHistory t={t} ref={this.intEventRef} />
            </Tabs.TabPane>
          </Tabs>
        </div>
      </div>
    );
  }
}

export default withRouter(withTranslation()(TSPLayout));
