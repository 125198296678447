import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import { TRENDING } from "utils/constants";
import { toFixed2Offset } from "utils/helper";
import ModalAogChart from "./ModalAogChart";
@inject("rootStore")
@observer
class CorridorOffsetTableView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentIntersection: null,
    };
  }
  getBiasTrend = (bias) => {
    if (bias < 0)
      return (
        <div className="flex items-center ml-8">
          <div className={TRENDING.BAD.icon}> </div>
          <span style={{ color: TRENDING.BAD.color }} className="ml-4">
            {bias.toFixed(2)} %
          </span>
        </div>
      );
    if (bias > 0)
      return (
        <div className="flex items-center ml-8">
          <div className={TRENDING.GOOD.icon}> </div>
          <span style={{ color: TRENDING.GOOD.color }} className="ml-4">
            {bias.toFixed(2)} %
          </span>
        </div>
      );
    else
      return (
        <div className="flex items-center ml-8">
          <div className={TRENDING.NORMAL.icon}> </div>
          <span style={{ color: TRENDING.NORMAL.color }} className="ml-4">
            {bias.toFixed(2)} %
          </span>
        </div>
      );
  };
  render() {
    const {
      t,
      rootStore: {
        optimizerStore: {
          corridorOffsetStore: {
            tranformCorridorOffsetData: {
              result: { intersections },
            },
          },
        },
      },
    } = this.props;

    return (
      <div className="flex-1-1-auto bg-background flex wrap-table-corridor-offset-optimization">
        <ModalAogChart
          aogData={this.state.currentIntersection}
          onClose={() => {
            this.setState({ currentIntersection: null });
          }}
        />
        <table>
          <thead>
            <tr>
              {intersections?.map((int) => {
                return (
                  <th key={int.order + 1}>
                    ({int.order + 1}): {t("intersection")} {int.int_name}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            <tr>
              {intersections?.map((int) => {
                return (
                  <td key={int.order}>{`${t("optimization.existing_offset")}: ${
                    int.current_offset
                  } - ${t("optimization.new_offset")}: ${int.best_offset}`}</td>
                );
              })}
            </tr>
            <tr>
              {intersections?.map((int, id) => {
                return (
                  <td key={int.order}>
                    <div className="mb-16">
                      <b>{t("optimization.upstream_aog")}</b>
                    </div>

                    <p>{`${t("intersection")}: ${int.int_name} - ${t(
                      "analysis.phase_name"
                    )} ${int.upstream_phase}`}</p>
                    <p>
                      {`${t("intersection_config.Direction")}: ${
                        int.int_name
                      } - ${
                        intersections[id + 1]
                          ? intersections[id + 1].int_name
                          : "-"
                      }`}
                    </p>
                    <p>{`${t("optimization.existing_aog")}: ${
                      int.up_stream_object.existing_aog
                    } (${toFixed2Offset(
                      int.up_stream_object.existing_aog_percent
                    )}%)`}</p>
                    <div className="flex items-center">
                      {`${t("optimization.predict_aog")}: ${toFixed2Offset(
                        int.up_stream_object.predicted_aog
                      )} (${toFixed2Offset(
                        int.up_stream_object.predicted_aog_percent
                      )}%)`}
                      {this.getBiasTrend(int.up_stream_object.bias)}
                    </div>
                  </td>
                );
              })}
            </tr>

            <tr>
              {intersections?.map((int, id) => {
                return (
                  <td key={int.order}>
                    <div className="mb-16">
                      <b>{t("optimization.downstream_aog")}</b>
                    </div>

                    <p>{`${t("intersection")}: ${int.int_name} - ${t(
                      "analysis.phase_name"
                    )} ${int.downstream_phase}`}</p>
                    <p>
                      {`${t("intersection_config.Direction")}:  ${
                        int.int_name
                      } - ${
                        intersections[id - 1]
                          ? intersections[id - 1].int_name
                          : "-"
                      }`}
                    </p>

                    <p>{`${t("optimization.existing_aog")}: ${toFixed2Offset(
                      int.down_stream_object.existing_aog
                    )} (${toFixed2Offset(
                      int.down_stream_object.existing_aog_percent
                    )}%)`}</p>
                    <div className="flex items-center">
                      {`${t("optimization.predict_aog")}: ${
                        int.down_stream_object.predicted_aog
                      } (${toFixed2Offset(
                        int.down_stream_object.predicted_aog_percent
                      )}%)`}
                      {this.getBiasTrend(int.down_stream_object.bias)}
                    </div>
                  </td>
                );
              })}
            </tr>

            <tr>
              {intersections?.map((int) => {
                return (
                  <td key={int.order}>
                    <div className="mb-16">
                      <b>{t("optimization.total_aog")}</b>
                    </div>

                    <p>{`${t("optimization.existing_aog")}: ${toFixed2Offset(
                      int.total_object.existing_aog
                    )} (${toFixed2Offset(
                      int.total_object.existing_aog_percent
                    )}%)`}</p>
                    <div className="flex items-center">
                      {`${t("optimization.predict_aog")}: ${
                        int.total_object.predicted_aog
                      } (${toFixed2Offset(
                        int.total_object.predicted_aog_percent
                      )}%)`}
                      {this.getBiasTrend(int.total_object.bias)}
                    </div>
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({
                          currentIntersection: int,
                        });
                      }}>
                      {t("optimization.view_adjustment_table")}
                    </a>
                  </td>
                );
              })}
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default withRouter(withTranslation()(CorridorOffsetTableView));
