import TopologyConfigService from "../../services/IntersectionConfig/TopologyConfigService";
import AgencyService from "../../services/AgencyService";
import { RESOURCE_LIST } from "utils/constants";
import BaseStore from "../BaseStore";
import { action, runInAction } from "mobx";

/**
 * Store for intersections
 *
 * @class
 */
class TopologyConfigStore extends BaseStore {
  constructor(parentStore) {
    super(new TopologyConfigService(parentStore), parentStore);
    this.agencyService = new AgencyService(parentStore); //agency service to get agency data
    //filter data for list intersection
    this.filterData = {
      ...this.filterData,
    };
    this.resourceName = RESOURCE_LIST.INTERSECTION;
    //for loading loop intersections
    this.continuousLoadingThreadId = null;
  }

  //get Topology Detail
  @action getTopologyDetail = (item, cb) => {
    this.loading = true;
    this.moduleService?.getTopologyDetail(
      {
        int_uuid: item.int_uuid,
        agency_id: this.filterData.agency_id,
        from_time_utc: this.parentStore.intersectionConfigStore.from_time_utc,
        config_id:
          this.parentStore.intersectionConfigStore.currentConfig.config_id,
      },
      (data) => {
        runInAction(() => {
          this.loading = false;
          this.parentStore.intersectionConfigStore.setTopologyConfig(data);
        });
        cb && cb();
      },
      () => {
        runInAction(() => {
          this.topologyConfig = [];
          this.loading = false;
        });
      }
    );
  };
  //params when component did mount will be used to load lasted config
  @action setCurrentIntersectionAgencyQuery = (value) => {
    this.filterData = {
      ...this.filterData,
      ...value,
    };
    this.currentQueryIntersectionAgency = value;
  };
  @action getData = (cb) => {
    this.loading = true;
    this.moduleService?.fetchDataWithParams(
      this.filterData,
      (items, totalCount) => {
        runInAction(() => {
          this.loading = false;

          this.listData = items.map((e, id) => {
            return {
              ...e,
              _id: id,
            };
          });
          this.filterData.pagination.total = totalCount;
          // this.filterData.pagination.total = 100;
        });
        cb && cb();
      },
      () => {
        runInAction(() => {
          this.listData = [];
          this.loading = false;
        });
      }
    );
  };
}

export default TopologyConfigStore;
