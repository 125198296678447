import {action, observable, runInAction} from "mobx";
import moment from "moment";
import {DATE_FORMAT, MAX_NUMBER_START_SEARCH, RESOURCE_LIST, SEARCH_TEXT_FIELD,} from "utils/constants";
import AgencyService from "../services/AgencyService";
import AlarmRecordService from "../services/AlarmRecordService";
import IntersectionService from "../services/IntersectionService";
import UserService from "../services/UserService";
import BaseStore from "./BaseStore";

const BASE_EXPORT_LINK =
  process.env.REACT_APP_API_HOST +
  ":" +
  process.env.REACT_APP_API_PORT +
  process.env.REACT_APP_API_PREFIX +
  "alarm/records/summaries/export";

class AlarmRecordStore extends BaseStore {
  @observable allCategories = [];
  @observable allAnalysisType = [];
  @observable allAgencies = [];
  @observable allForwardUsers = [];

  @observable allSummaryRecords = [];
  @observable visibleSummartModal = false;
  @observable exportLink = BASE_EXPORT_LINK;
  @observable defaultAgency = "";

  constructor(parentStore) {
    super(new AlarmRecordService(parentStore), parentStore);
    this.agencyService = new AgencyService(parentStore);
    this.userService = new UserService(parentStore);
    this.intersectionService = new IntersectionService(parentStore);
    this.resourceName = RESOURCE_LIST.ALARM_RECORD;
    this.filterData = {
      ...this.filterData,
      ...{
        text: "",
        agency_id: this.parentStore.myStore?.currentAgency?.agency_id,
        analysis_id: "null",
        category_id: "null",
        from_time: moment().day(-6).startOf("day").format(DATE_FORMAT.backend),
        to_time: moment().endOf("day").format(DATE_FORMAT.backend),
        sort: "",
      },
    };
    this.defaultAgency = this.parentStore.myStore?.currentAgency?.agency_name;
    this.exportLink = BASE_EXPORT_LINK + this.exportLinkParamsBuilder();
  }

  /**
   * getCategoryName
   *
   * @return  {string} -
   */
  getCategoryName = (category_id) => {
    return this.allCategories?.find((e) => e.id == category_id)?.name;
  };
  /**
   * getAnalysisName
   *
   * @return  {string} -
   */
  getAnalysisName = (analysis_id) => {
    return this.allAnalysisType?.find((e) => e.id == analysis_id)?.name;
  };
  /**
   * getAgencyName
   *
   * @return  {string} -
   */
  getAgencyName = (agency_id) => {
    return this.allAgencies?.find((e) => e.id == agency_id)?.name;
  };
  /**
   * toggleSummatyModalVisibility
   * toggle the SummatyModal visibility
   *
   * @return  {Promise} -
   */
  @action toggleSummatyModalVisibility = () => {
    this.visibleSummartModal = !this.visibleSummartModal;
    if (this.visibleSummartModal) {
      this.summaryAlarmRercords();
    }
  };

  exportLinkParamsBuilder = () => {
    let params = {...this.filterData, pagination: null};
    let searchParams = new URLSearchParams();
    Object.keys(params).forEach((key) => {
      if (params[key] && params[key] !== "null" && params[key] !== "") {
        searchParams.append(key, this.filterData[key]);
      }
    });
    return "?" + searchParams;
  };
  /**
   * summaryAlarmRercords
   * this function get all record with selected date data
   *
   * @return  {Promise} -
   */
  @action summaryAlarmRercords = () => {
    this.loading = true;
    return new Promise((resolve, reject) => {
      this.moduleService
        ?.fetchAllRecordByDate({...this.filterData, pagination: null})
        .then((data) => {
          runInAction(() => {
            this.allSummaryRecords = data.alarm_record_summaries;
            this.loading = false;
          });
          resolve(data.alarm_record_summaries);
        })
        .catch((error) => {
          reject(error?.errors);
        })
        .finally(() => {
          runInAction(() => {
            this.loading = false;
          });
        });
    });
  };

  /**
   * forwardMessages
   * this function forward message to target users
   * @param   {Object} users  target user
   * @param   {int} record_id  record id
   * @return  {null} -  setState of current filter data
   */
  @action forwardMessages = (users, record_id) => {
    this.loading = true;
    return new Promise((resolve, reject) => {
      this.moduleService
        ?.forwardMessages(users, record_id)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error?.errors);
        })
        .finally(() => {
          runInAction(() => {
            this.loading = false;
          });
        });
    });
  };
  /**
   * getAllForwardUser
   * this function will get all of user of current agency
   * @return  {Promise} -  setState of current filter data
   */
  @action getAllForwardUser = () => {
    this.loading = true;
    return new Promise((resolve, reject) => {
      this.userService?.getAllUserByAgency(
        this.filterData.agency_id,
        (users) => {
          resolve(users);
          runInAction(() => {
            this.allForwardUsers = users.data;
            this.loading = false;
          });
        },
        (error) => {
          reject(error?.errors);
          runInAction(() => {
            this.loading = false;
          });
        }
      );
    });
  };

  /**
   * getAllCategories
   * get all agencies
   *
   * @return {Promise}
   */
  @action getAllCategories = () => {
    this.loading = true;
    return new Promise((resolve, reject) => {
      this.moduleService
        ?.fetchAllCategories()
        .then((data) => {
          resolve(data);
          runInAction(() => {
            this.allCategories = data.categories;
          });
        })
        .catch((error) => {
          reject(error?.errors);
        })
        .finally(() => {
          runInAction(() => {
            this.loading = false;
          });
        });
    });
  };

  /**
   * getAllAnalysisType
   * get all analysis type
   *
   * @return {Promise}
   */
  @action getAllAnalysisType = () => {
    this.loading = true;
    return new Promise((resolve, reject) => {
      this.moduleService
        ?.fetchAllAnalysisType()
        .then((data) => {
          resolve(data);
          runInAction(() => {
            this.allAnalysisType = data.analyses;
          });
        })
        .catch((error) => {
          reject(error?.errors);
        })
        .finally(() => {
          runInAction(() => {
            this.loading = false;
          });
        });
    });
  };

  /**
   * getAllAgencies
   * get all agencies belong to current users
   *
   * @return {Promise}
   */
  @action getAllAgencies = () => {
    this.loading = true;
    return new Promise((resolve, reject) => {
      if (this.parentStore.commonStore.isSuperAdmin()) {
        this.agencyService?.fetchDataWithoutParams(
          (agencies) => {
            runInAction(() => {
              this.loading = false;
              this.allAgencies = agencies;
              if (Array.isArray(this.allAgencies) && this.allAgencies[0]) {
                let selectedAgency = this.allAgencies.find(
                  (item) => item.id === this.filterData.agency_id
                );
                if (!selectedAgency) {
                  selectedAgency = this.allAgencies[0];
                }
                this.filterData.agency_id = selectedAgency.id;
                this.defaultAgency = selectedAgency.name;
                //re-generate link after set default agency
                this.exportLink = BASE_EXPORT_LINK + this.exportLinkParamsBuilder();
                resolve(agencies);
              } else {
                reject();
              }
            });
          },
          (error) => {
            reject(error?.errors);
            runInAction(() => {
              this.loading = false;
            });
          }
        );
      } else {
        this.filterData.agency_id = this.parentStore.myStore?.currentAgency?.agency_id;
        this.defaultAgency = this.parentStore.myStore?.currentAgency?.agency_name;
        // NOTE: Make sure to re-generate link after set default agency
        this.exportLink = BASE_EXPORT_LINK + this.exportLinkParamsBuilder();
        resolve();
      }
    });
  };
  /**
   * handleFilterDataChange
   * this function will trigger when user input filtering or searching data
   * if filter data is search text and search length >=3 or length == 0 automatic start search
   * @param   {String} target  Field name wanna filter
   * @param   {String} value   Filtering data
   * @param   {Boolean} forceLoad   need to refetch data
   * @return  {null} -  setState of current filter data
   */
  @action handleFilterDataChange = (target, value, forceLoad = true) => {
    if (this.filterData) {
      this.filterData[target] = value;
    }
    if (target === SEARCH_TEXT_FIELD) {
      if (value?.length !== 0 && value?.length <= MAX_NUMBER_START_SEARCH) {
        return;
      }
    }
    if (forceLoad) {
      this.handleFilerAction();
    }
    this.exportLink = BASE_EXPORT_LINK + this.exportLinkParamsBuilder();
  };
}

export default AlarmRecordStore;
