import { Icon } from "antd";
import React, { Component } from "react";
import "./style.scss";
class HorizontalCollapseTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseRow: [],
    };
    this.tablePaneRef = React.createRef();
    this.minWidthTable = 0;
  }

  collapseRow = (rowId) => {
    let sliceArray = [];
    if (this.state.collapseRow.findIndex((e) => e === rowId) !== -1)
      sliceArray = this.state.collapseRow.filter((e) => e !== rowId);
    else sliceArray = [...this.state.collapseRow, rowId];
    this.setState({
      collapseRow: sliceArray,
    });
  };
  componentDidMount() {
    let minWidth = this.tablePaneRef?.current?.getElementsByClassName(
      "child-horizontal-table"
    )[0]?.offsetWidth;
    if (minWidth && !this.minWidthTable) this.minWidthTable = minWidth;
  }
  render() {
    return (
      <div
        className="horizontal-collapse-table-pane"
        style={{ height: this.props.maxHeight ? this.props.maxHeight : null }}
      >
        <div
          className={"horizontal-collapse-table"}
          style={{ minWidth: this.minWidthTable }}
        >
          {this.props.data?.map((breakRow, breakRowId) => {
            return (
              <div key={breakRowId} ref={this.tablePaneRef}>
                <div className="horizontal-row-collapse-btn">
                  <a
                    onClick={() => {
                      this.collapseRow(breakRowId);
                    }}
                  >
                    {!this.state.collapseRow.includes(breakRowId) ? (
                      <Icon type="down" />
                    ) : (
                      <Icon type="right" />
                    )}
                    &nbsp;
                    {this.props.collapseField?.label}{" "}
                    {breakRow[0]
                      ? breakRow[0][this.props.collapseField?.key]
                      : ""}
                  </a>
                </div>
                {!this.state.collapseRow.includes(breakRowId) &&
                  this.props.tableColumn?.map((keyObject) => (
                    <table
                      key={keyObject.key}
                      className="child-horizontal-table"
                    >
                      <tbody>
                        <tr
                          className={
                            keyObject.key === this.props.highlightRowKey
                              ? "highlight-row"
                              : ""
                          }
                        >
                          <th>{keyObject.label}</th>
                          {breakRow?.map((cell, cellId) => (
                            <td key={cellId}>{cell[keyObject?.key]}</td>
                          ))}
                        </tr>
                      </tbody>
                    </table>
                  ))}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default HorizontalCollapseTable;
