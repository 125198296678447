import { Icon } from "antd";
import * as d3 from "d3";
import React, { Component, createRef } from "react";
import { FONT_STRING_CAVAS } from "utils/constants";
import { CHART_COLOR_SCHEMA } from "utils/constants/colorSchema";

class RightExtendAogContent extends Component {
  constructor(props) {
    super(props);
    this.chartRef = createRef();
  }
  drawBorderLine = () => {
    const upperLineCtx = d3
      .line()
      .x(() => 4)
      .y((d) => this.yScale(d.y))
      .context(this.ctx);

    this.ctx.beginPath();
    upperLineCtx([
      { x: 0, y: this.yScale.domain()[0] },
      { x: 0, y: this.yScale.domain()[1] },
    ]);

    this.ctx.lineWidth = 1;
    this.ctx.strokeStyle = CHART_COLOR_SCHEMA.GREY;
    this.ctx.stroke();
    this.ctx.closePath();
  };
  drawIntersectionInfo = () => {
    const { dataSource, width } = this.props;
    dataSource?.intersections?.forEach((int) => {
      const lineCtx = d3
        .line()
        .x((d) => d.x)
        .y((d) => d.y)
        .context(this.ctx);
      this.ctx.beginPath();
      this.ctx.textAlign = "left";
      this.ctx.font = `20px ${FONT_STRING_CAVAS}`;
      this.ctx.textBaseline = "top";
      const startY = 20;
      this.ctx.fillText(int.int_name, 12, this.yScale(int.coordY));
      this.ctx.font = `14px ${FONT_STRING_CAVAS}`;

      this.ctx.fillStyle = "#939393";

      this.ctx.fillText(
        `Phase     %Aog     Volume`,
        132,
        this.yScale(int.coordY) + 24
      );

      lineCtx([
        {
          x: 10,
          y: this.yScale(int.coordY) + 40,
        },
        {
          x: width - 10,
          y: this.yScale(int.coordY) + 40,
        },
      ]);
      this.ctx.strokeStyle = "#939393";

      this.ctx.stroke();
      this.ctx.stroke();
      // upstreasm
      this.ctx.fillText("Upstream", 12, this.yScale(int.coordY) + startY + 24);
      this.ctx.fillStyle = "black";
      this.ctx.fillText(
        `P2          ${int.ring1.aog_percent?.toFixed(2) || ""}       ${
          int.ring1.det_hit
        }  `,
        132,
        this.yScale(int.coordY) + startY + 24
      );
      lineCtx([
        {
          x: 10,
          y: this.yScale(int.coordY) + startY + 40,
        },
        {
          x: width - 10,
          y: this.yScale(int.coordY) + startY + 40,
        },
      ]);
      this.ctx.strokeStyle = "#939393";

      this.ctx.stroke();

      //downstream
      this.ctx.fillStyle = "#939393";

      this.ctx.fillText(
        "Downstream",
        12,
        this.yScale(int.coordY) + startY + 44
      );
      this.ctx.fillStyle = "black";

      this.ctx.fillText(
        `P6          ${int.ring2.aog_percent?.toFixed(2) || ""}       ${
          int.ring2.det_hit
        }  `,
        132,
        this.yScale(int.coordY) + startY + 44
      );
      this.ctx.fillStyle = "#939393";

      lineCtx([
        {
          x: 10,
          y: this.yScale(int.coordY) + startY + 60,
        },
        {
          x: width - 10,
          y: this.yScale(int.coordY) + startY + 60,
        },
      ]);
      this.ctx.strokeStyle = "#939393";

      this.ctx.stroke();
      this.ctx.stroke();
      //total
      this.ctx.fillStyle = "#939393";

      this.ctx.fillText(
        "Total(intersection): ",
        12,
        this.yScale(int.coordY) + startY + 64
      );
      this.ctx.fillStyle = "black";

      this.ctx.fillText(
        `P2.6       ${int.aog_percent?.toFixed(2) || ""}        ${
          int.det_hit
        }  `,
        132,
        this.yScale(int.coordY) + startY + 64
      );
      this.ctx.closePath();
    });
  };

  drawContent = (yScale) => {
    const { width, height } = this.props;
    this.yScale = yScale;
    this.ctx?.clearRect(0, 0, width, height);
    this.drawBorderLine();
    this.drawIntersectionInfo();
  };
  componentDidMount() {
    const { width, height } = this.props;
    var base = d3.select(this.chartRef.current);
    var chart = base
      .append("canvas")
      .attr("width", width)
      .attr("height", height);
    this.ctx = chart.node().getContext("2d");
    this.ctx.imageSmoothingQuality = "high";
  }

  render() {
    const { onCollapsePanel, dataSource } = this.props;
    return (
      <>
        <div
          className="sticky clickable flex justify-space-beetwen items-center px-8 aog-statistic-header"
          style={{ top: 0 }}
          onClick={onCollapsePanel}>
          <span>
            <Icon type="caret-right" />
            AOG Static
          </span>
          <div className="aog-statistic-right-header">
            Total(cordidor)
            <div>
              %Aog: {dataSource?.aog_percent?.toFixed(2)}% | Volume:{" "}
              {dataSource?.det_hit}
            </div>
          </div>
        </div>
        {/* <hr /> */}
        <div ref={this.chartRef} className="aog-statistic-content"></div>
      </>
    );
  }
}

export default RightExtendAogContent;
