import {
  MAX_NUMBER_START_SEARCH,
  MAP_DELAY_LOAD_DATA,
} from "utils/constants";
import { observable, action } from "mobx";
import moment from "moment";
import EventHistoryService from "../../services/TSPServices/EventHistoryService";
import BaseStore from "../BaseStore";

/**
 * Store for intersections
 *
 * @class
 */
class EventHistoryStore extends BaseStore {
  constructor(parentStore) {
    super(new EventHistoryService(parentStore), parentStore);
    this.filterData = {
      ...this.filterData,
      ...{
        text: "",
        agency_id: parentStore?.myStore?.currentAgency?.agency_id,
        event_type: null,
        from_time: moment().valueOf() - 86400000,
        to_time: moment().valueOf(),
        range: 86400000,
        sort: "",
      },
    };
    // this.listData =
  }
  @observable selectedItem = null;
  /**
   * handleFilterDataChange
   * this function will trigger when user input filtering or searching data
   * if filter data is search text and search length >=3 or length == 0 automatic start search
   * @param   {String} target  Field name wanna filter
   * @param   {String} value   Filtering data
   * @param   {Boolean} forceLoad   need to refetch data
   * @return  {null} -  setState of current filter data
   */
  @action handleFilterDataChange = (target, value, forceLoad = true) => {
    if (this.filterData) {
      this.filterData[target] = value;
    }
    if (target === "text" || target === "route_number") {
      if (value?.length !== 0 && value?.length <= MAX_NUMBER_START_SEARCH) {
        return;
      }
      if (this.timeoutSearchString) clearTimeout(this.timeoutSearchString);
      if (forceLoad)
        this.timeoutSearchString = setTimeout(() => {
          this.handleFilerAction();
        }, MAP_DELAY_LOAD_DATA);
    } else if (forceLoad) {
      this.handleFilerAction();
    }
  };

  //data for filtering
  //data for map
  @action getDataById = (item_id, cb) => {
    this.itemData = this.listData.find((e) => e.id === item_id);
    cb && cb();
  };
}

export default EventHistoryStore;
