import BaseApi from "../BaseApi";
import Api from "../Api";

/**
 * Service for intersections
 *
 * @class
 */
class IntersectionConfigService extends BaseApi {
  constructor(parentStore) {
    super("intersection-configs", parentStore);
    this.dataKey = "int_configs";
  }
  /**
   * getTopology config
   * this function return topology config
   * @param   {function} cb  callback function
   * @param   {function} fb  falseback function
   * @return  {null} -  call the callback function
   */
  getTopologyConfig = (queryObject, cb, fb) => {
    const api = new Api(
      "intersection-configs/topologies/details",
      this.parentStore
    );
    api.getRequest(
      queryObject,
      (response) => {
        cb && cb(response.data);
      },
      () => {
        fb && fb();
      }
    );
  };
  getAllConfig = (queryObject, cb, fb) => {
    const api = new Api("intersection-configs/topologies", this.parentStore);
    api.getRequest(
      queryObject,
      (response) => {
        cb && cb(response.data);
      },
      () => {
        fb && fb();
      }
    );
  };
  getTopologyDetail = (queryObject, cb, fb) => {
    const api = new Api(
      "intersection-configs/topologies/topology-detail",
      this.parentStore
    );
    api.getRequest(
      queryObject,
      (response) => {
        cb && cb(response.data);
      },
      () => {
        fb && fb();
      }
    );
  };
  getMetaData = (cb, fb) => {
    const api = new Api(
      "intersection-configs/topologies/metadata",
      this.parentStore
    );
    api.getRequest(
      null,
      (response) => {
        cb && cb(response.data);
      },
      () => {
        fb && fb();
      }
    );
  };
  getLastedConfig = (queryObject, cb, fb) => {
    const api = new Api("intersection-configs/latest", this.parentStore);
    api.getRequest(
      queryObject,
      (response) => {
        cb && cb(response.data);
      },
      () => {
        fb && fb();
      }
    );
  };

  changeCurrentConfig = (query, cb, fb) => {
    const api = new Api("intersection-configs/" + query.uuid, this.parentStore);
    api.getRequest(
      query,
      (response) => {
        cb && cb(response.data);
      },
      () => {
        fb && fb();
      }
    );
  };

  saveTopologyConfig = (body, cb, fb) => {
    if (body?.Id) {
      const apiPut = new Api(
        "intersection-configs/topologies/" + body.Id,
        this.parentStore
      );
      apiPut.putRequest(
        body,
        (response) => {
          cb && cb(response);
        },
        () => {
          fb && fb();
        }
      );
    } else {
      const apiPost = new Api(
        "intersection-configs/topologies",
        this.parentStore
      );
      apiPost.postRequest(
        body,
        (response) => {
          cb && cb(response);
        },
        () => {
          fb && fb();
        }
      );
    }
  };
}

export default IntersectionConfigService;
