import React, { Component, createRef } from "react";
import { observer, inject } from "mobx-react";
import moment from "moment";
import { Button, Empty, Spin, Modal, Skeleton } from "antd";
import { withTranslation } from "react-i18next";
import {
  DATE_FORMAT,
  TIMEOUT_RESIZE,
  ANALYSIS_LEGEND,
} from "utils/constants";
import ChartItemSplitMonitor from "components/ChartItem/ChartItemSplitMonitor";
import AnalysisLegend from "containers/Analysis/AnalysisLegend";
import { VariableSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import PrintSplitMonitor from "containers/Analysis/AnalysisResult/PrintPreview/PrintSplitMonitor";
import { withRouter } from "react-router";

@inject("rootStore")
@observer
class SplitMonitorAnalysisData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      resizing: false,
    };
    this.listRef = createRef();
    this.scrollHeight = 0;
  }
  componentDidMount() {
    window.addEventListener("resize", this.handleWindowResize);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowResize);
  }
  handleWindowResize = () => {
    const {
      rootStore: {
        analysisScheduleResultStore: { printPReview },
      },
    } = this.props;
    //do not resize when in print preview cuz it will re render the PDF file => this is burnning RAM and TIME
    if (!printPReview) {
      this.setState({ resizing: true });
      setTimeout(() => {
        this.setState({ resizing: false });
      }, TIMEOUT_RESIZE);
    }
  };
  convertTimeFormat = (value) => {
    return moment(value, DATE_FORMAT.backend).format(
      DATE_FORMAT.analysis_range
    );
  };

  print = () => {
    // window.print();
    const {
      rootStore: {
        analysisScheduleResultStore: { togglePrintPReviewVisibility },
      },
    } = this.props;

    togglePrintPReviewVisibility();
  };

  renderListItem = ({ height, width }) => {
    const {
      rootStore: {
        chartStore: { chartData },
      },
    } = this.props;

    return (
      <VariableSizeList
        ref={this.listRef}
        className="list"
        height={height}
        itemCount={chartData?.length}
        itemSize={(index) => [130, height-155][index]}
        width={width}
      >
        {ChartItemSplitMonitor.bind(this)}
      </VariableSizeList>
    );
  };
  render() {
    const {
      t,
      rootStore: {
        myStore: { currentProfile },
        chartStore: {
          chartInfo,
          allChartData,
          filterData,
          updateFilterData,
          getChartByIndex,
          currentChartTypeFrontEnd,
        },
        analysisScheduleResultStore: {
          loading,
          loadingChart,
          printPReview,
          togglePrintPReviewVisibility,
        },
      },
      history,
    } = this.props;

    const totalPhase = allChartData?.map((e) => e.phase);
    return loading || loadingChart ? (
      <div className="bg-background px-8 py-8 h-full">
        <Skeleton />
        <Skeleton />
      </div>
    ) : chartInfo && allChartData.length && !this.state.resizing ? (
      <div className="wrap-analysis-result">
        <div className="wrap-analysis-header">
          <div className="analysis-header flex items-center py-8 px-8 bg-background">
            <div>
              <Button
                size={"large"}
                icon="arrow-left"
                onClick={() =>
                  history.push("/report/result/performance-metric")
                }>
                {t("detail.back")}
              </Button>
            </div>
            <div className="flex ">
              <div>
                {/* <Button icon="printer" size={"large"} onClick={this.print}>
                  {t("analysis.print-title")}
                </Button> */}
              </div>
            </div>
          </div>
          <div></div>
          <div className="filter-function">
            <div></div>
            <AnalysisLegend
              totalLegend={ANALYSIS_LEGEND[currentChartTypeFrontEnd]}
              hiddenLegend={filterData?.hiddenLegend}
              updateHiddenLegend={(hiddenLegend) => {
                updateFilterData("hiddenLegend", hiddenLegend);
              }}
            />
          </div>
        </div>
        <div className="analysis-body body-normal styled-scroll">
          {this.state.loading ? (
            <Spin size="large" className={"centralize"} />
          ) : (
            <AutoSizer>{this.renderListItem.bind(this)}</AutoSizer>
          )}
        </div>
        <Modal
          className="alarm-records-summary-modal"
          centered
          width={"80%"}
          title={t("print.modal-title")}
          visible={printPReview}
          onCancel={togglePrintPReviewVisibility}
          footer={null}
          destroyOnClose={true}>
          <PrintSplitMonitor
            totalPage={totalPhase}
            getChartByIndex={getChartByIndex}
            printInfo={{
              ...chartInfo,
              chartType: currentChartTypeFrontEnd,
              totalLegend: ANALYSIS_LEGEND[currentChartTypeFrontEnd],
            }}
            printFilter={filterData}
            reporter={currentProfile?.name}
          />
        </Modal>
      </div>
    ) : (
      <>
        <div className="wrap-analysis-result">
          <div className="wrap-analysis-header">
            <div className="analysis-header flex items-center py-8 px-8 bg-background">
              <div>
                <Button
                  size={"large"}
                  icon="arrow-left"
                  onClick={() =>
                    history.push("/report/result/performance-metric")
                  }>
                  {t("detail.back")}
                </Button>
              </div>
            </div>
          </div>
          <div className="analysis-body body-normal styled-scroll flex items-center justify-center">
            <Empty></Empty>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(withTranslation()(SplitMonitorAnalysisData));
