import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import "./style.scss";
import ReportDetail from "./AnalysisReportDetail";
import WrapAnalysisData from "./WrapAnalysisData";
import YellowTrapAnalysisData from "./YellowTrapAnalysisData";
import { ANALYSIS_TYPE } from "../../../../utils/constants";
import SplitMonitorAnalysisData from "./SplitMonitorAnalysisData";
import AbnormalAnalysisData from "./AbnormalAnalysisData";
import AbnormalReportDetail from "./AbnormalReportDetail";
import { Skeleton } from "antd";
import MOEAnalysisData from "./MOEAnalysisData";
@inject("rootStore")
@observer
class AnalysisScheduleResultDetail extends Component {
  /**
   * componentDidMount
   *
   */
  componentDidMount() {
    const {
      t,
      match: { params },
      rootStore: {
        analysisScheduleResultStore: { getResultData },
      },
    } = this.props;

    document.title = t("report_result.page-title");
    getResultData(params.id);
  }

  componentWillUnmount() {
    const {
      rootStore: {
        analysisScheduleResultStore: { resetData },
      },
    } = this.props;
    resetData();
  }

  renderReportDetail = () => {
    const {
      rootStore: {
        analysisScheduleResultStore: { reportData },
      },
    } = this.props;

    switch (reportData?.metric_type) {
      case ANALYSIS_TYPE.ABNORMAL:
        return <AbnormalReportDetail />;
      default:
        return <ReportDetail />;
    }
  };

  renderAnalysisData = () => {
    const {
      rootStore: {
        analysisScheduleResultStore: { reportData },
      },
    } = this.props;

    if (!reportData?.metric_type) return <Skeleton />;

    switch (reportData?.metric_type) {
      case ANALYSIS_TYPE.YTO:
        return <YellowTrapAnalysisData />;
      case ANALYSIS_TYPE.SPLIT_MONITOR:
        return <SplitMonitorAnalysisData />;
      case ANALYSIS_TYPE.ABNORMAL:
        return <AbnormalAnalysisData />;
      case ANALYSIS_TYPE.MOE_ANALYSIS:
        return <MOEAnalysisData />;
      default:
        return <WrapAnalysisData />;
    }
  };

  render() {
    return (
      <div className="wrap-analysis-report-result">
        <div className="wrap-report-detail">{this.renderReportDetail()}</div>
        <div className="wrap-report-content">{this.renderAnalysisData()}</div>
      </div>
    );
  }
}

export default withRouter(withTranslation()(AnalysisScheduleResultDetail));
