import { action, runInAction, observable } from "mobx";
import CorridorService from "services/CorridorService";
import AgencyService from "services/AgencyService";
import IntersectionService from "services/IntersectionService";
import BaseStore from "./BaseStore";

// Store for analysis
class CorridorStore extends BaseStore {
  constructor(parentStore) {
    super(new CorridorService(parentStore), parentStore);
    //init relation services
    this.agencyService = new AgencyService(parentStore); //agency service
    this.intersectionService = new IntersectionService(parentStore); //intersection service
    this.filterData = {
      agency_id: null,
      ...this.filterData,
      type: null,
      status: null,
    };
  }
  @observable loadingCorridorDetail = false;
  @observable loadingSubmitDetailPage = false;
  @observable formData = {
    agency_id: null,
    corridor_intersections: [],
    global_downstream_phase: 1,
    global_upstream_phase: 1,
    name: "",
    speed: 0,
    status: "",
  };

  @action getAllAgencies = (cb) => {
    this.loading = true;
    this.agencyService.getAll((data) => {
      runInAction(() => {
        this.allAgencies = data;
        if (!this.formData?.agency_id)
          this.formData = { ...this.formData, agency_id: data[0]?.id };
        this.loading = false;
        cb && cb();
      });
    });
  };

  @action setDetaultAgencyFilter = (isSuperAdmin, cb) => {
    this.loading = true;
    if (isSuperAdmin)
      this.agencyService.getAll((data) => {
        runInAction(() => {
          this.allAgencies = data;
          //set default agency
          if (Array.isArray(this.allAgencies) && this.allAgencies[0]) {
            let defaultAgency = this.allAgencies.find(
              (item) => item.id === this.filterData.agency_id
            );
            if (!defaultAgency) {
              this.filterData.agency_id = this.allAgencies[0].id;
            }
          }
          cb && cb();
        });
      });
    else {
      const currentAgency = this.parentStore.myStore.currentAgency;
      this.filterData = {
        ...this.filterData,
        ...{ agency_id: currentAgency.agency_id },
      };
      cb && cb();
    }
  };
  @action getCorrridorDetails = (id, fb) => {
    this.loadingCorridorDetail = true;
    this.moduleService?.fetchCorridorDetails(
      id,
      (data) => {
        runInAction(() => {
          this.formData = {
            ...data,
            corridor_intersections: data?.corridor_intersections?.map((el) => {
              return {
                id: el.intersection.id,
                name: el.intersection.name,
                status: el.intersection.status,
                speed: el.speed,
                upstream: el.upstream,
                distance: el.distance,
                downstream: el.downstream,
              };
            }),
          };
          this.loadingCorridorDetail = false;
        });
      },
      () => {
        runInAction(() => {
          this.loadingCorridorDetail = false;
        });
        fb && fb();
      }
    );
  };
  @action setStatus = (data = null, status) => {
    this.loading = true;
    if (!data) {
      data = this.selectedItems;
    }
    this.moduleService?.updateStatus(
      { ids: data, status },
      () => {
        this.getData();
      },
      () => {
        runInAction(() => {
          this.loading = false;
        });
      }
    );
  };
  @action saveEditCorridor = (id, values, cb, fb) => {
    this.formData = {
      ...values,
      corridor_intersections: [...values.corridor_intersections],
    };
    this.loadingSubmitDetailPage = true;
    this.moduleService.saveCorridor(
      id,
      this.formData,
      () => {
        runInAction(() => {
          this.loadingSubmitDetailPage = false;
        });

        cb && cb();
      },
      (errMessage) => {
        runInAction(() => {
          this.loadingSubmitDetailPage = false;
          fb && fb(errMessage);
        });
      }
    );
  };
  @action submitCreateCorridor = (values, cb, fb) => {
    this.formData = {
      ...values,
      corridor_intersections: [...values.corridor_intersections],
    };
    this.loadingSubmitDetailPage = true;
    this.moduleService.createCorridor(
      this.formData,
      () => {
        runInAction(() => {
          this.loadingSubmitDetailPage = false;
          cb && cb();
        });
      },
      (errMessage) => {
        runInAction(() => {
          this.loadingSubmitDetailPage = false;
          fb && fb(errMessage);
        });
      }
    );
  };
  @action saveFormData = (values) => {
    this.formData = { ...values };
  };
  @action resetFormData = () => {
    this.formData = {
      agency_id: null,
      corridor_intersections: [],
      global_downstream_phase: 1,
      global_upstream_phase: 1,
      name: "",
      speed: 0,
      status: "",
    };
  };
}

export default CorridorStore;
