import BaseApi from './BaseApi';
import Api from './Api';

class AlarmRecordService extends BaseApi {

    constructor(parentStore) {
        super("alarm/records", parentStore);
        this.dataKey = "alarm_records";
    }
    /**
    * fetchAllRecordByDate
    * get all alarm record  by filter date
    * @param {Object} filterDate date from to
    * @return  {Promise} -  return promise function for store
    */
    fetchAllRecordByDate = (filterDate) => {
        let paramData = this.addPagingToFilterData(filterDate);
        return new Promise((resolve, reject) => {
            const api = new Api(this.moduleName + "/summaries", this.parentStore);
            api.getRequest(paramData, response => {
                resolve(response?.data)
            }, (response) => {
                reject(response)
            });
        })
    }

    /**
     * fetchAllCategories
     * get all categories of alarm records
     * @return  {Promise} -  return promise function for store
     */
    fetchAllCategories = () => {
        return new Promise((resolve, reject) => {
            const api = new Api("alarm/categories", this.parentStore);
            api.getRequest({}, response => {
                resolve(response?.data)
            }, (response) => {
                reject(response)
            });
        })
    }

    /**
     * fetchAllAnalysisType
     * get all analysis type of alarm records
     * @return  {Promise} -  return promise function for store
     */
    fetchAllAnalysisType = () => {
        return new Promise((resolve, reject) => {
            const api = new Api("alarm/analyses", this.parentStore);
            api.getRequest({}, response => {
                resolve(response?.data)
            }, (response) => {
                reject(response)
            });
        })
    }

    /**
    * forwardMessages
    * this function forward message to target users
    * @param   {Object} users  target user
    * @param   {int} record_id  record id
    * @return  {null} -  setState of current filter data     
    */
    forwardMessages = (users, record_id) => {
        return new Promise((resolve, reject) => {
            const api = new Api(this.moduleName + "/notifications", this.parentStore);
            api.postRequest({ ...users, record_id }, response => {
                resolve(response?.data)
            }, (response) => {
                reject(response)
            });
        })
    }

}

export default AlarmRecordService;