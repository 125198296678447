import { CHART_COLOR_SCHEMA, GRAPHIC_CHART_TYPE } from "./colorSchema";
export const HISTORIC_TIMSPACE_MODE = {
  PROGRAMMED: "PROGRAMMED",
  ACTUAL: "ACTUAL",
};
export const CORRIDOR_OFFSET_OPTIMIZATION_MODE = {
  TABLE: "TABLE",
  PIE: "PIE",
};
export const OFFSET_OPTIMIZATION_MODE = {
  BAR: "BAR",
  PIE: "PIE",
};
export const FONT_STRING_CAVAS =
  "siemenfont, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif";
export const LEGEND_MIN_MAX = [
  {
    key: `group1_min`,
    label: `Group 1 Band`,
    color: CHART_COLOR_SCHEMA.RED,
    unit: "%",
    yAxis: "left",
    extendField: "group1_max",
    toolTip: (value) => parseFloat(value).toFixed(2),
    type: GRAPHIC_CHART_TYPE.line,
    legendTooltip: "analysis.group2",
  },
  {
    key: `group2_min`,
    label: `Group 2 Band`,
    extendField: "group2_max",
    areaColor: "",
    color: CHART_COLOR_SCHEMA.BLUE,
    unit: "%",
    yAxis: "left",
    toolTip: (value) => parseFloat(value).toFixed(2),
    type: GRAPHIC_CHART_TYPE.line,
    legendTooltip: "analysis.group2",
  },
];
export const ROOT_LEGEND_MINMAX = [
  {
    key: `group1_min`,
    label: `Group 1 min`,
    color: CHART_COLOR_SCHEMA.RED,
    areaColor: "rgba(255, 0, 0, 0.25)",
    unit: "%",
    yAxis: "left",
    extendField: "group1_max",
    toolTip: (value) => parseFloat(value).toFixed(2),
    type: GRAPHIC_CHART_TYPE.line,
    legendTooltip: "analysis.group2",
  },
  {
    key: `group1_max`,
    label: `Group 1 max`,
    color: CHART_COLOR_SCHEMA.RED,
    unit: "%",
    yAxis: "left",
    toolTip: (value) => parseFloat(value).toFixed(2),
    type: GRAPHIC_CHART_TYPE.line,
    legendTooltip: "analysis.group2",
  },
  {
    key: `group2_min`,
    label: `Group 2 min`,
    areaColor: "",
    color: CHART_COLOR_SCHEMA.BLUE,
    areaColor: "rgba(0, 5, 251, 0.25)",
    unit: "%",
    yAxis: "left",
    extendField: "group2_max",
    toolTip: (value) => parseFloat(value).toFixed(2),
    type: GRAPHIC_CHART_TYPE.line,
    legendTooltip: "analysis.group2",
  },
  {
    key: `group2_max`,
    label: `Group 2 max`,
    areaColor: "",
    color: CHART_COLOR_SCHEMA.BLUE,
    unit: "%",
    yAxis: "left",
    toolTip: (value) => parseFloat(value).toFixed(2),
    type: GRAPHIC_CHART_TYPE.line,
    legendTooltip: "analysis.group2",
  },
];
export const OPTIMIZATION_TYPE = {
  OFFSET_OPTIMIZATION: "OFFSET_OPTIMIZATION",
  TRAFFIC_PROFILE: "TRAFFIC_PROFILE",
  HISTORIC_TIME_SPACE: "HISTORIC_TIME_SPACE",
  CORRIDOR_OFFSET_OPTIMIZATION: "CORRIDOR_OFFSET_OPTIMIZATION",
};
export const BIAS_DIRECTION = {
  DOWNSTREAM: "DOWNSTREAM",
  UPSTREAM: "UPSTREAM",
};
export const TRAFFICE_PROFILE_TYPE = {
  WEEKEND_WEEKDAY: "WEEKDAY_WEEKEND",
  DAY_OF_WEEK: "DAY_OF_WEEK",
};
export const LEVEL_COLOR = [
  "#7274b4",
  "#8ca7f4",
  "#72daf4",
  "#bff474",
  "#f2f374",
  "#f2c074",
  "#f2a6a6",
  "#f274f4",
  "#b27474",
  "#fc5454",
];
export const OFFSET_OPTIMIZER_RANDOM_COLOR = [
  "#e6194b",
  "#3cb44b",
  "#f58231",
  "#911eb4",
  "#46f0f0",
  "#f032e6",
  "#bcf60c",
  "#fabebe",
  "#008080",
  "#e6beff",
  "#9a6324",
  "#fffac8",
  "#800000",
  "#aaffc3",
  "#808000",
  "#ffd8b1",
  "#000075",
  "#808080",
  "#ffffff",
  "#000000",
];
export const OFFSET_OPTIMIZER_COLOR = {
  EXISTING: CHART_COLOR_SCHEMA.GREEN,
  PREDICT_GAIN: CHART_COLOR_SCHEMA.DARK_GREEN,
  PREDICT_LOSS: CHART_COLOR_SCHEMA.RED,
  GAP: CHART_COLOR_SCHEMA.LIGHT_GREY,
};

export const OFFSET_BARCHART_COLOR = {
  EXISTING_AOG: CHART_COLOR_SCHEMA.GREEN,
  PREDICTED_AOG: CHART_COLOR_SCHEMA.DURATION
};

export const HISTORIC_SEEK_TYPE = {
  LAST_PREV: "LAST_PREV",
  PREV_2_MIN: "PREV_2_MIN",
  PREV_1_MIN: "PREV_1_MIN",
  NEXT_1_MIN: "NEXT_1_MIN",
  NEXT_2_MIN: "NEXT_2_MIN",
  LAST_NEXT: "LAST_NEXT",
};
