import { Form, InputNumber, Select, Button } from "antd";
import SiwaveMultipleDatePicker from "components/SiwaveMultipleDatePicker";
import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import { DATE_FORMAT, DEFAULT_PIN, SELECTED_PIN } from "utils/constants";
import "./style.scss";
import { Map, Marker, TileLayer } from "react-leaflet";
import Control from "react-leaflet-control";
const Option = Select.Option;
const COMBOBOX = "combobox";
@inject("rootStore")
@observer
class TrafficProfileFilter extends Component {
  constructor(props) {
    super(props);
  }
  checkValidDateRange = (obj, value, callback) => {
    const { t } = this.props;
    if (
      Array.isArray(value) &&
      value.length >= 7 // dates range >= 7 days
    )
      callback();
    else callback(t("optimization.date_range_invalid"));
  };
  /**
   * forcus on target marker
   * @return  {Component}
   */
  render() {
    const {
      t,
      showFullMap,
      handleIntersectionChange,
      rootStore: {
        optimizerStore: {
          listIntersections,
          currentMapZoom,
          currentMapCenter,
          formData,
          allBinSizes,
          allClusterTypes,
          setFormField,
        },
      },
      form: { getFieldDecorator, validateFields },
    } = this.props;
    return (
      <>
        <Form.Item label={t("analysis.intersection")}>
        {getFieldDecorator("intersection_id", {
          rules: [
            {
              required: true,
              message: t("analysis.intersection_required"),
            },
          ],
          initialValue: formData.intersection_id,
        })(
          <Select
            showSearch
            onChange={(value) => handleIntersectionChange(value, COMBOBOX)}
            placeholder={t("analysis.intersection_placholder")}
            optionFilterProp="value"
            filterOption={(input, option) =>
              option?.props?.children
                ?.toLowerCase()
                ?.indexOf(input?.toLowerCase()) >= 0
            }>
            {listIntersections?.map((e) => (
              <Select.Option key={e.id} value={e.id}>
                {e.name}
              </Select.Option>
            ))}
          </Select>
        )}
        </Form.Item>
        <Form.Item>
          <Map
            style={{ height: "200px" }}
            className="intersection-map"
            zoom={currentMapZoom}
            center={currentMapCenter}
            onViewportChanged={this.handleMapChangeView}>
            <TileLayer
              url={process.env.REACT_APP_OPEN_STREET_MAP}
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            />
            <Control position="bottomright">
              <Button
                icon="fullscreen"
                className="map-fullscreen"
                onClick={showFullMap}
              />
            </Control>
            {listIntersections?.map((intersection) => (
              <Marker
                icon={
                  formData?.intersection_id === intersection?.id
                    ? SELECTED_PIN
                    : DEFAULT_PIN
                }
                position={[intersection.latitude, intersection.longitude]}
                key={intersection.id}
                onClick={(e) =>
                  handleIntersectionChange(intersection.id, MAP, e)
                }></Marker>
            ))}
          </Map>
        </Form.Item>
        <Form.Item label={t("optimization.num_of_profiles")}>
          {getFieldDecorator("num_of_profiles", {
            rules: [
              {
                required: true,
                message: t("optimization.num_of_profiles") + t("is_required"),
              },
              {
                validator: (obj, value, callback) => {
                  if (value < 2 || value > 10)
                    callback(t("optimization.num_of_profiles_invalid"));
                  else callback();
                },
              },
            ],
            initialValue: formData.num_of_profiles,
          })(
            <InputNumber
              onChange={(value) => {
                setFormField("num_of_profiles", value);
              }}
              className="w-full"
            />
          )}
        </Form.Item>

        <Form.Item label={t("optimization.bin_size")}>
          {getFieldDecorator("bin_size", {
            rules: [
              {
                required: true,
                message: t("analysis.analysis_required"),
              },
            ],
            initialValue: formData.bin_size,
          })(
            <Select
              onChange={(value) => {
                setFormField("bin_size", value);
                validateFields(["min_profile_duration"]);
              }}>
              {allBinSizes?.map((e) => (
                <Option value={e?.value} key={e?.key}>
                  {e?.name}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>

        <Form.Item label={t("optimization.min_profile_duration")}>
          {getFieldDecorator("min_profile_duration", {
            rules: [
              {
                required: true,
                message:
                  t("optimization.min_profile_duration") + t("is_required"),
              },
              {
                validator: (obj, value, callback) => {
                  if (
                    value * 60 < Number(formData.bin_size) ||
                    value * 60 > 86400
                  )
                    callback(t("optimization.min_profile_duration_invalid"));
                  else callback();
                },
              },
            ],
            initialValue: formData.min_profile_duration / 60,
          })(
            <InputNumber
              onChange={(value) =>
                setFormField("min_profile_duration", value * 60)
              }
              className="w-full"
            />
          )}
        </Form.Item>

        <Form.Item label={t("optimization.cluster_types")}>
          {getFieldDecorator("cluster_types", {
            rules: [
              {
                required: true,
                message: t("analysis.analysis_required"),
              },
            ],
            initialValue: formData.cluster_type,
          })(
            <Select
              onChange={(value) => {
                setFormField("cluster_type", value);
              }}>
              {allClusterTypes?.map((e) => (
                <Option value={e} key={e}>
                  {t(e)}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>

        <Form.Item
          className="form-disable-position"
          label={t("report_result.date_range")}>
          {getFieldDecorator("dates", {
            rules: [
              {
                required: true,
                message: t("report_template.select_date_range_required"),
              },
              { validator: this.checkValidDateRange },
            ],
            initialValue: formData.dates,
            getValueFromEvent: (value) => {
              setFormField(
                "dates",
                value.map((e) => e.format(DATE_FORMAT.day_only_backend))
              );
              return value;
            },
          })(
            <SiwaveMultipleDatePicker
              placeholder={t("report_result.date_range")}
              format={DATE_FORMAT.day_only_backend}
            />
          )}
        </Form.Item>
      </>
    );
  }
}

export default TrafficProfileFilter;
