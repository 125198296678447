import {
  Badge,
  Button,
  Col,
  Descriptions,
  Modal,
  Row,
  Spin,
  Tooltip,
} from "antd";
import { inject, observer } from "mobx-react";
import moment from "moment";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import {
  DATE_FORMAT,
  OFFSET_BARCHART_COLOR,
  TIMEOUT_RESIZE,
  WEEK_DAY_OPTION,
  OFFSET_OPTIMIZATION_MODE,
} from "utils/constants";
import i18n from "../../../../i18n";
import { exportCSVFile } from "../../../../utils/renderExportFile";
import AdjustmentResult from "./AdjustmentResult";
import PrintPreview from "./PrintPreview";
import "./style.scss";
import TableResult from "./TableResult";
import {
  getShortDateRangeString,
  getFullDateRangeString,
} from "../../../../utils/helper";
import IntersectionOffsetBarChart from "./BarChart/IntersectionOffsetBarChart";

const BAR_LEGEND = (
  <div className="chart-legend flex justify-end">
    <div>
      <Badge
        className="border-square mx-8"
        color={OFFSET_BARCHART_COLOR.EXISTING_AOG}
        text={i18n.t("optimization.existing_aog_0")}
      />
      <Badge
        className="border-square mx-8"
        color={OFFSET_BARCHART_COLOR.PREDICTED_AOG}
        text={i18n.t("optimization.predicted_aog")}
      />
    </div>
  </div>
);
@inject("rootStore")
@observer
class OffsetOptimization extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resizing: false,
    };
    this.state = {
      tableChartHeight: 200,
      printMode: false,
      visibleTableChart: false,
      visibleTableInfo: false,
      chartType: OFFSET_OPTIMIZATION_MODE.BAR,
    };
    this.headerOffsetTable = React.createRef();
    this.offsetTable = React.createRef();
    this.timeoutResize = null;
  }
  /**
   * componentDidMount
   *
   */
  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }
  handleResize = () => {
    if (this.offsetTable.current && this.headerOffsetTable.current) {
      this.setState({ resizing: true });
      if (this.timeoutResize) clearTimeout(this.timeoutResize);
      this.timeoutResize = setTimeout(() => {
        this.setState({
          resizing: false,
          tableChartHeight:
            this.offsetTable.current?.offsetHeight -
            this.headerOffsetTable.current?.offsetHeight,
        });
      }, TIMEOUT_RESIZE);
    }
  };
  tranformDataChart = (data) => {
    return data.offset_results?.map((item) => {
      const object = {
        offset: item.offset,
      };
      item.predicted_list.forEach((predict) => {
        object["predicted_phase_" + predict.phases] = predict.predicted_aog;
      });
      object.total_aog = item.total_aog;
      return object;
    });
  };
  convertCoverPageToExportJsonFormat = (coverData) => {
    const { t } = this.props;
    let coverTranform = [];
    Object.keys(coverData).forEach((e) => {
      switch (e) {
        case "optimization_type": {
          coverTranform.push({
            title: t("optimization." + e),
            data: coverData[e].name,
          });
          break;
        }
        case "owner_id": {
          break;
        }
        case "optimization_type": {
          coverTranform.push({
            title: t("optimization." + e),
            data: coverData[e].name,
          });
          break;
        }
        case "owner": {
          coverTranform.push({
            title: t("optimization." + e),
            data: coverData[e].name,
          });
          break;
        }
        case "week_days": {
          coverTranform.push({
            title: t("optimization." + e),
            data: coverData[e].join(", "),
          });
          break;
        }
        default: {
          coverTranform.push({
            title: t("optimization." + e),
            data: coverData[e],
          });
        }
      }
    });
    return coverTranform;
  };
  convertDataToExportJsonFormat = (resultData) => {
    const { t } = this.props;
    let titleRow = {};
    if (resultData.length) {
      let someElement = resultData[0];
      Object.keys(someElement).forEach((e) => {
        if (e.includes("predicted_phase"))
          titleRow[e] =
            t("optimization.phase") + " " + e.replace("predicted_phase_", "");
        else titleRow[e] = t("optimization." + e);
      });
    }
    return [titleRow, ...resultData];
  };

  handleChangeMode = (mode) => {
    this.setState({
      chartType: mode,
    });
  };

  render() {
    const {
      t,
      rootStore: {
        optimizerStore,
        myStore: { currentProfile },
      },
    } = this.props;
    const data = optimizerStore.selectedItem;
    const offsetTranformData = this.tranformDataChart(data);
    return (
      <div className="offset-table h-full" ref={this.offsetTable}>
        <Modal
          title={t("print.modal-title")}
          visible={this.state.printMode}
          width="80vw"
          centered
          destroyOnClose={true}
          footer={[]}
          onCancel={() => this.setState({ printMode: false })}>
          <PrintPreview
            offsetTranformData={offsetTranformData}
            reporter={currentProfile.name}
            data={data}
            bar_legend={BAR_LEGEND}
          />
        </Modal>
        <Modal
          title={t("optimization.view_adjustment_table")}
          visible={this.state.visibleTableChart}
          width="80vw"
          centered
          okButtonProps={{ display: "none" }}
          footer={[
            <Button
              key="back"
              onClick={() =>
                this.setState({
                  visibleTableChart: false,
                  visibleTableInfo: false,
                })
              }>
              Close
            </Button>,
          ]}
          onCancel={() =>
            this.setState({
              visibleTableChart: false,
              visibleTableInfo: false,
            })
          }>
          <div
            className="flex justify-center w-full"
            style={{ height: "70vh" }}>
            {this.state.resizing || !this.state.visibleTableChart ? (
              <Spin size="large" />
            ) : (
              <AdjustmentResult
                t={t}
                height={"70vh"}
                chartType={"aog"}
                gapdata={[]}
                onChartZoom={() => {}}
                rootData={data}
                data={{
                  offset_results: offsetTranformData,
                  plan_statistics: [],
                  current_offset: data.current_offset,
                }}
              />
            )}
          </div>
        </Modal>
        <Modal
          title={t("optimization.adjusment_detail_table")}
          visible={this.state.visibleTableInfo}
          width="60vw"
          centered
          okButtonProps={{ display: "none" }}
          footer={[
            <Button
              key="back"
              onClick={() =>
                this.setState({
                  visibleTableChart: false,
                  visibleTableInfo: false,
                })
              }>
              Close
            </Button>,
          ]}
          onCancel={() =>
            this.setState({
              visibleTableChart: false,
              visibleTableInfo: false,
            })
          }>
          <div className="flex justify-center">
            <TableResult data={data?.best_result} />
          </div>
        </Modal>

        <div ref={this.headerOffsetTable}>
          {/* info */}
          <div className="bg-background px-8 py-8">
            <Descriptions
              title={
                <div className="flex justify-space-beetwen">
                  <div>
                    <h1>{t("optimization.offset_optimization")} </h1>
                  </div>
                  <div>
                    <Button
                      icon="arrow-left"
                      className="mx-8"
                      onClick={() => {
                        this.props.history.push("/analysis/optimizer");
                      }}>
                      {t("detail.back")}
                    </Button>
                    <Button
                      icon="file-excel"
                      onClick={() =>
                        exportCSVFile(
                          this.convertCoverPageToExportJsonFormat(
                            data.general_data
                          ),
                          [
                            {
                              title: t("optimization.adjusment_detail_table"),
                              data: this.convertDataToExportJsonFormat(
                                data.best_result
                              ),
                            },
                            {
                              title: t("optimization.adjusment_detail"),
                              data: this.convertDataToExportJsonFormat(
                                offsetTranformData
                              ),
                            },
                          ],
                          t("optimization.offset_print_title"),
                          data.general_data.name
                        )
                      }>
                      {t("report_result.export_csv")}
                    </Button>
                    <Button
                      type="primary"
                      icon="printer"
                      className="mx-8"
                      onClick={() => this.setState({ printMode: true })}>
                      {t("report_result.print_table")}
                    </Button>
                  </div>
                </div>
              }
              column={{ md: 4 }}>
              <Descriptions.Item label={t("intersection.name")}>
                {data?.general_data.name}
              </Descriptions.Item>
              <Descriptions.Item label={t("optimization.plan_number")}>
                {data?.general_data.plan_number}
              </Descriptions.Item>
              <Descriptions.Item label={t("analysis.phase_name")}>
                {data?.phase}
              </Descriptions.Item>
              <Descriptions.Item label={t("optimization.created_by")}>
                {data?.general_data.owner?.name}
              </Descriptions.Item>
              <Descriptions.Item label={t("report_result.time_range")}>
                {data?.general_data.start_time} - {data?.general_data.end_time}
              </Descriptions.Item>
              <Descriptions.Item label={t("report_result.date_range")}>
                <Tooltip
                  title={
                    <span className="ant-tooltip-pre-wrap">
                      {getFullDateRangeString(data?.general_data, t)}
                    </span>
                  }>
                  {getShortDateRangeString(data?.general_data, t)}
                </Tooltip>
              </Descriptions.Item>
              <Descriptions.Item label={t("report_result.day_of_week")}>
                {data?.general_data.week_days
                  ?.map((e) => {
                    return WEEK_DAY_OPTION.find((i) => i.value == e).label;
                  })
                  .join(", ")}
              </Descriptions.Item>
              <Descriptions.Item label={t("optimization.created_at")}>
                {moment(data?.general_data.created_at).format(
                  DATE_FORMAT.full_with_tz
                )}
              </Descriptions.Item>
            </Descriptions>
          </div>

          <div className="offset-view-option flex justify-end items-center mt-8 mb-8">
            <div className="offset-tables flex justify-space-around">
              <a
                onClick={() =>
                  this.setState({
                    visibleTableChart: true,
                  })
                }>
                {t("optimization.view_adjustment_table")}
              </a>
              <a
                onClick={() =>
                  this.setState({
                    visibleTableInfo: true,
                  })
                }>
                {t("optimization.view_aog_table")}
              </a>
            </div>
          </div>

          <div className="px-8 mt-8 bg-background">
            <Row type="flex" align="middle" className="justify-space-beetwen">
              <Col lg={8} sm={24} className="mt-8">
                <div className="flex items-center">
                  <div className="flex items-center mx-8">
                    {t("optimization.existing_offset")}: &nbsp;
                    <span className="font-24">{data?.current_offset} </span>
                  </div>
                  <div className="flex items-center">
                    {t("optimization.new_offset")}: &nbsp;
                    <span className="font-24">{data?.best_offset} </span>
                  </div>
                </div>
              </Col>
              <Col lg={10} sm={24} className="mt-8">
                {BAR_LEGEND}
              </Col>
            </Row>
          </div>
        </div>

        <div
          className="wrap-table-chart"
          style={{ height: this.state.tableChartHeight }}>
          {!this.state.resizing ? (
            <>
              <IntersectionOffsetBarChart data={data?.best_result} />
            </>
          ) : (
            <div className="w-full h-full flex justify-center">
              <Spin size="large" className="flex items-center" />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(withTranslation()(OffsetOptimization));
