import React, { Component } from "react";
import SplitMonitorProgrammed from "./SplitMonitorProgrammed";
import SplitMonitorActual from "./SplitMonitorActual";

class SplitMonitor extends Component {
  constructor(props) {
    super(props)
  }
  
  renderChart = () => {
    const { data, isPrintMode } = this.props
    if (Object.keys(data).length === 0) {
      return null;
    } else if (data?.cycles?.length !== undefined) {
      return <SplitMonitorActual data={data} isPrintMode={isPrintMode} />; 
    } else {
      return <SplitMonitorProgrammed data={data} isPrintMode={isPrintMode} />;
    }
  }

  render() {
    return(
      <div style={{display: "flex", flexDirection:"column", height:"100%", paddingBottom: "10px"}}>
        {this.renderChart()}
      </div>
    )
  }
}

export default SplitMonitor;