import React, { Component } from "react";
import { withRouter } from "react-router";
import { PageHeader, Button, Tabs, Modal, Spin } from "antd";
import { observer, inject } from "mobx-react";
import { withTranslation } from "react-i18next";
import SelectIntersectionStore from "stores/SelectIntersectionStore";
import { ACTIONS_LIST, RESOURCE_LIST, REPORT_TAB } from "utils/constants";
import helper from "utils/helper";
import GeneralInfoTab from "./GeneralInfoTab";
import SelectIntersectionTab from "components/SelectIntersectionTab";
import ScheduleTab from "./ScheduleTab";
import AvailableIntersectionStore from "stores/AvailableIntersectionStore";

const { confirm } = Modal;
const { TabPane } = Tabs;
const pathApi = "performance-metric/templates";
const moduleNameConstant = "REPORT_TEMPLATE";
/**
 * Component for showing details of the user.
 *
 * @component
 */
@inject("rootStore")
@observer
class AnalysisScheduleDetailPage extends Component {
  state = {
    loading: false,
  };
  constructor(props) {
    super(props);
    this.selectIntersectionStore = new SelectIntersectionStore(
      props.match.params.id,
      "performance-metric/templates",
      props.rootStore
    );
    this.addMoreIntersectionStore = new AvailableIntersectionStore(
      props.rootStore,
      pathApi,
      moduleNameConstant
    );
  }
  generalDetail = null;
  reportSchedule = null;
  reportIntersection = null;
  componentDidMount() {
    const {
      match: { params },
      rootStore: {
        analysisScheduleStore: {
          presetItemFromAnalysis,
          changeReportTemplateTab,
          getTemplateDetail,
          setTemplateId,
          getAllAnalysisTypes,
          setInitialForm,
        },
      },
    } = this.props;

    const cloneId = helper.getQueryString(location.search, "cloneid");
    this.setState({
      loading: true,
    });
    getAllAnalysisTypes(() => {
      if (presetItemFromAnalysis) {
        setInitialForm(presetItemFromAnalysis);
        this.selectIntersectionStore.addItem([
          {
            id: presetItemFromAnalysis.intersection.id,
            name: presetItemFromAnalysis.intersection.name,
            status: "AVAILABLE",
          },
        ]);
      }
      if (cloneId) {
        getTemplateDetail(cloneId, () => {
          this.setState({ loading: false });
        });
      } else {
        if (params?.id) {
          setTemplateId(params.id);
          getTemplateDetail(params.id, () => {
            this.setState({ loading: false });
          });
        } else {
          setTemplateId(null);
          this.setState({ loading: false });
        }
      }
      changeReportTemplateTab(REPORT_TAB.GENERAL_TAB);
    });
  }
  renderHeaderAction = () => {
    const {
      t,
      history,
      rootStore: {
        analysisScheduleStore: { currentTab, context_id },
        myStore,
      },
    } = this.props;
    if (context_id)
      return (
        currentTab !== REPORT_TAB.INTERSECTION_TAB && (
          <div className="header-function">
            <Button
              onClick={() => {
                history.push("/report/template/performance-metric");
              }}>
              {t("alarm_rules.cancel")}
            </Button>
            {myStore.hasPermission(
              ACTIONS_LIST.EDIT,
              RESOURCE_LIST.REPORT_TEMPLATE_DETAIL,
              true
            ) && (
              <Button
                onClick={() => {
                  if (currentTab === REPORT_TAB.GENERAL_TAB)
                    this.generalDetail?.handleUpdate();
                  else {
                    this.reportSchedule.handleUpdate();
                  }
                }}>
                {t("alarm_rules.save")}
              </Button>
            )}
          </div>
        )
      );
    else
      return (
        <div className="header-function">
          <Button onClick={this.goToPrevStep}>{t("alarm_rules.back")}</Button>
          <Button onClick={this.goToNextStep}>
            {currentTab === REPORT_TAB.SCHEDULE_TAB
              ? t("alarm_rules.save")
              : t("alarm_rules.next_step")}
          </Button>
        </div>
      );
  };
  goToNextStep = () => {
    const {
      history,
      rootStore: {
        analysisScheduleStore: {
          currentTab,
          changeReportTemplateTab,
          setIntersectionList,
          submit,
          formData,
        },
      },
    } = this.props;

    switch (currentTab) {
      case REPORT_TAB.GENERAL_TAB: {
        this.generalDetail?.handleNext();
        break;
      }
      case REPORT_TAB.INTERSECTION_TAB: {
        setIntersectionList(
          this.selectIntersectionStore?.scope,
          this.selectIntersectionStore?.listData
        );
        changeReportTemplateTab(REPORT_TAB.SCHEDULE_TAB);
        break;
      }
      case REPORT_TAB.SCHEDULE_TAB: {
        this.reportSchedule?.handleNext(() => {
          submit(
            () => {
              history.push("/report/template/performance-metric");
            },
            (error) => {
              changeReportTemplateTab(REPORT_TAB.GENERAL_TAB);
              this.generalDetail?.setFormError(error);
            }
          );
        });
        break;
      }
      default:
        break;
    }
    if (currentTab === REPORT_TAB.GENERAL_TAB) {
      this.addMoreIntersectionStore.handleFilterDataReset({
        agency_id: formData?.general_data?.agency_id,
      });
    }
  };
  componentWillUnmount() {
    const {
      rootStore: { analysisScheduleStore },
    } = this.props;
    analysisScheduleStore.resetForm();
  }
  goToPrevStep = () => {
    const {
      history,
      rootStore: {
        analysisScheduleStore: { currentTab, changeReportTemplateTab },
      },
    } = this.props;

    if (currentTab === REPORT_TAB.GENERAL_TAB) {
      // history.push("/report/template/performance-metric");
      history.goBack();
    } else if (currentTab === REPORT_TAB.INTERSECTION_TAB) {
      changeReportTemplateTab(REPORT_TAB.GENERAL_TAB);
    } else {
      changeReportTemplateTab(REPORT_TAB.INTERSECTION_TAB);
    }
  };
  handleChangeTab = (key) => {
    const generalDetail = this.generalDetail;
    const {
      t,
      rootStore: {
        analysisScheduleStore: {
          context_id,
          currentTab,
          changeReportTemplateTab,
          isEditFormDirty,
          getTemplateDetail,
        },
      },
    } = this.props;
    if (key !== currentTab) {
      if (currentTab !== REPORT_TAB.INTERSECTION_TAB && isEditFormDirty) {
        confirm({
          title: t("common.confirm_redirect_title"),
          content: t("common.confirm_redirect_content"),
          okText: t("common.leave"),
          okType: "danger",
          cancelText: t("common.stay"),
          onOk() {
            generalDetail?.props?.form?.resetFields();
            getTemplateDetail(context_id, changeReportTemplateTab(key));
          },
        });
      } else {
        changeReportTemplateTab(key);
      }
    }
  };
  render() {
    const {
      t,
      match: { params },
      rootStore: {
        analysisScheduleStore,
        analysisScheduleStore: { currentTab, context_id, formData },
      },
    } = this.props;

    return this.state.loading ? (
      <div className="w-full h-full flex items-center justify-center">
        <Spin size="large" spinning />
      </div>
    ) : (
      <div className="wrap-alarm-rule-detail-page">
        <PageHeader
          title={
            context_id
              ? t("analysis_schedule.edit_template_title")
              : t("analysis_schedule.create_template_title")
          }
          extra={this.renderHeaderAction()}>
          <div className={!context_id ? "template-report-create" : ""}>
            <Tabs
              className="edit-rule-tab"
              defaultActiveKey={REPORT_TAB.INTERSECTION_TAB}
              type="card"
              activeKey={currentTab}
              onTabClick={context_id && this.handleChangeTab}>
              <TabPane
                disabled={
                  currentTab === REPORT_TAB.GENERAL_TAB || context_id
                    ? false
                    : true
                }
                forceRender={false}
                tab="General"
                key={REPORT_TAB.GENERAL_TAB}>
                <GeneralInfoTab
                  resetListIntersection={
                    this.selectIntersectionStore.resetListIntersection
                  }
                  id={params?.id}
                  childRef={(ref) => (this.generalDetail = ref)}
                />
              </TabPane>
              <TabPane
                disabled={
                  currentTab === REPORT_TAB.INTERSECTION_TAB || context_id
                    ? false
                    : true
                }
                tab="Intersections"
                key={REPORT_TAB.INTERSECTION_TAB}>
                <SelectIntersectionTab
                  addMoreIntersectionStore={this.addMoreIntersectionStore}
                  context_id={context_id}
                  idFieldName={"template_id"}
                  targetStore={analysisScheduleStore}
                  agency_id={formData.general_data.agency_id}
                  moduleName={"report_template"}
                  resourceList={{
                    main: "REPORT_TEMPLATE",
                    detail: "REPORT_TEMPLATE_DETAIL",
                    detailsIntersection: "REPORT_TEMPLATE_DETAIL_INTERSECTION",
                  }}
                  childRef={(ref) => (this.reportIntersection = ref)}
                  currentStore={this.selectIntersectionStore}
                  pathApi="performance-metric/templates"></SelectIntersectionTab>
              </TabPane>
              <TabPane
                disabled={
                  currentTab === REPORT_TAB.SCHEDULE_TAB || context_id
                    ? false
                    : true
                }
                forceRender={false}
                tab="Schedule"
                key={REPORT_TAB.SCHEDULE_TAB}>
                <ScheduleTab
                  childRef={(ref) => (this.reportSchedule = ref)}></ScheduleTab>
              </TabPane>
            </Tabs>
          </div>
        </PageHeader>
      </div>
    );
  }
}

export default withRouter(withTranslation()(AnalysisScheduleDetailPage));
