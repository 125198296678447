import React, { Component } from "react";
import { Table } from "antd";
import { withTranslation } from "react-i18next";
import { observer, inject } from "mobx-react";
import "./style.scss";

@inject("rootStore")
@observer
class HelperPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  dataSource = () =>  {
    const { t } = this.props
    return [
      {
        key: "1",
        no: "1",
        name: t("alarm_helper.table_name_detector"),
        unit: t("alarm_helper.table_unit_detector"),
        target: t("alarm_helper.table_target_detector"),
      },
      {
        key: "2",
        no: "2",
        name: t("alarm_helper.table_name_phase_calls"),
        unit: t("alarm_helper.table_unit_raw_count"),
        target: t("alarm_helper.table_target_phase_all"),
      },
      {
        key: "3",
        no: "3",
        name: t("alarm_helper.table_name_ped_phase_calls"),
        unit: t("alarm_helper.table_unit_raw_count"),
        target: t("alarm_helper.table_target_phase_all"),
      },
      {
        key: "4",
        no: "4",
        name: t("alarm_helper.table_name_gap_out"),
        unit: t("alarm_helper.table_unit_raw_count"),
        target: t("alarm_helper.table_target_phase_all"),
      },
      {
        key: "5",
        no: "5",
        name: t("alarm_helper.table_name_max_out"),
        unit: t("alarm_helper.table_unit_raw_count"),
        target: t("alarm_helper.table_target_phase_all"),
      },
      {
        key: "6",
        no: "6",
        name: t("alarm_helper.table_name_force"),
        unit: t("alarm_helper.table_unit_raw_count"),
        target: t("alarm_helper.table_target_phase_all"),
      },
      {
        key: "7",
        no: "7",
        name: t("alarm_helper.table_name_arrival_green"),
        unit: t("alarm_helper.table_unit_detector_percent"),
        target: t("alarm_helper.table_target_phase_all"),
      },
      {
        key: "8",
        no: "8",
        name: t("alarm_helper.table_name_arrival_yellow"),
        unit: t("alarm_helper.table_unit_detector_percent"),
        target: t("alarm_helper.table_target_phase_all"),
      },
      {
        key: "9",
        no: "9",
        name: t("alarm_helper.table_name_arrival_red"),
        unit: t("alarm_helper.table_unit_detector_percent"),
        target: t("alarm_helper.table_target_phase_all"),
      },
      {
        key: "10",
        no: "10",
        name: t("alarm_helper.table_name_max_ped_delay"),
        unit: t("alarm_helper.table_unit_second"),
        target: t("alarm_helper.table_target_phase_all"),
      },
      {
        key: "11",
        no: "11",
        name: t("alarm_helper.table_name_avg_ped_delay"),
        unit: t("alarm_helper.table_unit_second"),
        target: t("alarm_helper.table_target_phase_all"),
      },
      {
        key: "12",
        no: "12",
        name: t("alarm_helper.table_name_no_data"),
        unit: t("alarm_helper.table_unit_hours"),
        target: t("alarm_helper.table_target_all"),
      },
      {
        key: "13",
        no: "13",
        name: t("alarm_helper.table_name_transition"),
        unit: t("alarm_helper.table_target_percent"),
        target: t("alarm_helper.table_target_all"),
      },
      {
        key: "14",
        no: "14",
        name: t("alarm_helper.table_name_max_gor"),
        unit: t("alarm_helper.table_target_percent"),
        target: t("alarm_helper.table_target_phase_all"),
      },
      {
        key: "15",
        no: "15",
        name: t("alarm_helper.table_name_avg_gor"),
        unit: t("alarm_helper.table_target_percent"),
        target: t("alarm_helper.table_target_phase_all"),
      },
      {
        key: "16",
        no: "16",
        name: t("alarm_helper.table_name_max_ror5"),
        unit: t("alarm_helper.table_target_percent"),
        target: t("alarm_helper.table_target_phase_all"),
      },
      {
        key: "17",
        no: "17",
        name: t("alarm_helper.table_name_avg_ror5"),
        unit: t("alarm_helper.table_target_percent"),
        target: t("alarm_helper.table_target_phase_all"),
      },
      {
        key: "18",
        no: "18",
        name: t("alarm_helper.table_name_split"),
        unit: t("alarm_helper.table_unit_raw_count"),
        target: t("alarm_helper.table_target_phase_all"),
      },
      {
        key: "19",
        no: "19",
        name: t("alarm_helper.table_name_poor"),
        unit: t("alarm_helper.table_unit_raw_count"),
        target: t("alarm_helper.table_target_phase_all"),
      },
      {
        key: "20",
        no: "20",
        name: t("alarm_helper.table_name_excess"),
        unit: t("alarm_helper.table_unit_raw_count"),
        target: t("alarm_helper.table_target_phase_all"),
      },
      {
        key: "21",
        no: "21",
        name: t("alarm_helper.table_name_max_approach_delay"),
        unit: t("alarm_helper.table_unit_second"),
        target: t("alarm_helper.table_target_phase_all"),
      },
      {
        key: "22",
        no: "22",
        name: t("alarm_helper.table_name_avg_approach_delay"),
        unit: t("alarm_helper.table_unit_second"),
        target: t("alarm_helper.table_target_phase_all"),
      },
      {
        key: "23",
        no: "23",
        name: t("alarm_helper.table_name_max_queue_length"),
        unit: t("alarm_helper.table_unit_vehicle"),
        target: t("alarm_helper.table_target_phase_all"),
      },
      {
        key: "24",
        no: "24",
        name: t("alarm_helper.table_name_avg_queue_length"),
        unit: t("alarm_helper.table_unit_vehicle"),
        target: t("alarm_helper.table_target_phase_all"),
      },
    ]
  }

  listUnitDetails = () => {
    const {t} = this.props;
    return [
      t("alarm_helper.list_unit_raw_count"),
      t("alarm_helper.list_unit_vehicle_hour"),
      t("alarm_helper.list_unit_percentage"),
      t("alarm_helper.list_unit_Hour"),
      t("alarm_helper.list_unit_second"),
      t("alarm_helper.list_unit_vehicle"),
    ];
  }

  getTableColumn = () => {
    const { t } = this.props;
    return [
      {
        title: t("alarm_helper.no"),
        dataIndex: "no",
        key: "no",
        width: 80,
      },
      {
        title: t("alarm_helper.name"),
        dataIndex: "name",
        key: "name",
        width: 300,
      },
      {
        title: t("alarm_helper.unit"),
        dataIndex: "unit",
        key: "unit",
      },
      {
        title: t("alarm_helper.target"),
        dataIndex: "target",
        key: "target",
        width: 150,
      },
    ];
  };
  render() {
    const { t } = this.props;
    return (
      <div>
        <div>
          <p>{t("alarm_helper.title")}</p>
        </div>
        <Table
          pagination={false}
          dataSource={this.dataSource()}
          scroll={{ y: "300px" }}
          columns={this.getTableColumn()}
          className="helper-table"
        ></Table>
        <div style={{ marginTop: "32px" }}>
          <div>
            <b>{t("alarm_helper.unit_title")}</b>
            <ul style={{ listStyleType: "none" }}>
              {this.listUnitDetails().map((e, id) => (
                <li key={id}>{e}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(HelperPopup);
