import React from "react";
import PieChart from "components/PieChart";
class AOGPieChart extends React.Component {
  constructor(props) {
    super(props);
    this.chartRef = React.createRef();
    this.hiddenLegend = [];
  }

  /**
   * render
   *
   * @return  {Component}
   */
  render() {
    return (
      <div
        style={{
          height: this.props.height ? this.props.height : "250px",
          width: this.props.width ? this.props.width : "100%",
        }}
      >
        <PieChart {...this.props} />
      </div>
    );
  }
}

export default AOGPieChart;
