import BaseApi from "./BaseApi";
import Api from "./Api";

/**
 * Service for user management.
 *
 * @class
 */
class DashboardService extends BaseApi {
  constructor(parentStore) {
    super("dashboard", parentStore);
    this.keyField = "user_id";
  }

  /**
   * getTopTenOpenAlarms
   * get all user without filter
   * @param   {Object} payload  agency id
   * @return  {null} -  call the callback function
   */
  getTopTenOpenAlarms = (payload) => {
    return new Promise((resolve, reject) => {
      const api = new Api(
        this.moduleName + "/top-rank/intersections",
        this.parentStore
      );
      api.getRequest(
        payload,
        (response) => {
          resolve(response?.data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  };
  saveMapSettingByAgency = (payload) => {
    return new Promise((resolve, reject) => {
      const api = new Api("account/settings/maps", this.parentStore);
      api.patchRequest(
        payload,
        (response) => {
          resolve(response?.data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  };
  getMapSettingByAgency = (payload) => {
    return new Promise((resolve, reject) => {
      const api = new Api("account/settings", this.parentStore);
      api.getRequest(
        payload,
        (response) => {
          resolve(response?.data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  };
  /**
   * getChart
   * get all user without filter
   * @param   {Object} payload  agency id
   * @return  {null} -  call the callback function
   */
  getChart = (payload) => {
    return new Promise((resolve, reject) => {
      const api = new Api(
        this.moduleName + "/chart/elements/all",
        this.parentStore
      );
      api.getRequest(
        payload,
        (response) => {
          resolve(response?.data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  };
  /**
   * getAllIntersectionsWithBounds
   * this function get intersection by area ( topleft - bottomright)
   * @param   {String} searchParams  curent search params
   * @return  {null} -  call the callback function
   */
  getAllIntersectionsWithBounds = (searchParams) => {
    Object.keys(searchParams).forEach(
      (key) =>
        (searchParams[key] == "" || searchParams[key] == "null") &&
        delete searchParams[key]
    );
    return new Promise((resolve, reject) => {
      const api = new Api(
        this.moduleName + "/map/intersections",
        this.parentStore
      );
      api.getRequest(
        searchParams,
        (response) => {
          resolve(response?.data);
        },
        (response) => {
          reject(response);
        }
      );
    });
  };

  /**
   * fetchAgencySettingForMap
   * this function get agency setting info for dashboad only
   * @param   {String} agency_id  curent agency id
   * @param   {Function} cb  callback function after success
   * @param   {Function} fb  failback function after fail
   * @return  {null} -  call the callback function
   */
  fetchAgencySettingForDashboardMap = (agency_id, cb, fb) => {
    const api = new Api(
      this.moduleName + "/map/" + agency_id + "/settings",
      this.parentStore
    );
    api.getRequest(
      {},
      (response) => {
        cb && cb(response?.data);
      },
      (errors) => {
        fb && fb(errors);
      }
    );
  };

  getDashboardSettingByAgency = (payload) => {
    return new Promise((resolve, reject) => {
      const api = new Api("account/settings", this.parentStore);
      api.getRequest(
        payload,
        (response) => {
          resolve(response?.data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  };

  saveDashboardSetting = (payload) => {
    return new Promise((resolve, reject) => {
      const api = new Api(
        "account/settings/dashboard/widgets",
        this.parentStore
      );
      api.putRequest(
        payload,
        (response) => {
          resolve(response?.data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  };

  patchDashboardSetting = (payload) => {
    return new Promise((resolve, reject) => {
      const api = new Api(
        "account/settings/dashboard/widgets",
        this.parentStore
      );
      api.patchRequest(
        payload,
        (response) => {
          resolve(response?.data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  };

  getAllIntersectionByAgencyId = (payload) => {
    return new Promise((resolve, reject) => {
      const api = new Api("intersections/all", this.parentStore);
      api.getRequest(
        payload,
        (response) => {
          resolve(response?.data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  };

  getVolumeCounts = (payload) => {
    return new Promise((resolve, reject) => {
      const api = new Api("dashboard/volume-count", this.parentStore);
      api.getRequest(
        payload,
        (response) => {
          resolve(response?.data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  };

  getSummaryReport = (payload) => {
    return new Promise((resolve, reject) => {
      const api = new Api(
        "dashboard/widget-data/summary-report",
        this.parentStore
      );
      api.getRequest(
        payload,
        (response) => {
          resolve(response?.data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  };

  getNotification = (payload) => {
    return new Promise((resolve, reject) => {
      const api = new Api("dashboard/notification", this.parentStore);
      api.getRequest(
        payload,
        (response) => {
          resolve(response?.data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  };
}

export default DashboardService;
