import { Badge, Col, Empty, List, Row } from "antd";
import * as d3 from "d3";
import { toJS } from "mobx";
import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import DoughnutChart from "../../../components/DoughnutChart";
import "./style.scss";

@inject("rootStore")
@observer
class InfoAlarmMessage extends Component {
  constructor(props) {
    super(props);
    this.colors = d3.scaleOrdinal(d3.schemeCategory10);
    this.format = d3.format(".1f");
    this.chartContainerRef = React.createRef();
    this.wrapRef = React.createRef();
    this.state = {
      chartSize: 200,
      compact: true,
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = () => {
    const { width: wrapWidth } = this.wrapRef.current?.getBoundingClientRect();
    try {
      const { width, height } =
        this.chartContainerRef.current?.getBoundingClientRect();
      this.setState({
        chartSize: Math.min(width, height),
        compact: wrapWidth < 450,
      });
    } catch (e) {
      this.setState({
        chartSize: 200,
        compact: wrapWidth < 450,
      });
    }
  };

  render() {
    const {
      t,
      rootStore: { dashboardStore },
      skeleton,
    } = this.props;
    const { chartSize, compact } = this.state;

    return (
      <div className="wrap-info-alarm-message" ref={this.wrapRef}>
        {skeleton ? (
          <div className="widget-header-title">
            {t("dashboard.chart-header")}
          </div>
        ) : (
          <>
            <div className="widget-header">
              <div className="widget-header-title">
                {t("dashboard.chart-header")}
              </div>
              <div className="widget-header-subtitle">&nbsp;</div>
            </div>
            {dashboardStore.totalOpenAlarms > 0 ? (
              <div className="wrap-chart">
                <div
                  className={compact ? "chart-box-compact" : "chart-box"}
                  ref={this.chartContainerRef}
                >
                  <DoughnutChart
                    key={`size-${chartSize}px`}
                    data={toJS(dashboardStore.listCharts)}
                    title={t("dashboard.chart-title")}
                    totalData={toJS(dashboardStore.totalOpenAlarms)}
                    width={chartSize}
                    height={chartSize}
                    innerRadius={chartSize / 3 - 10}
                    outerRadius={chartSize / 2 - 10}
                  />
                </div>

                <div
                  className={
                    compact ? "chart-info-box-compact" : "chart-info-box"
                  }
                >
                  <List
                    className="alarm-list-message"
                    itemLayout="horizontal"
                    dataSource={dashboardStore.listCharts}
                    renderItem={(item) =>
                      item && (
                        <List.Item>
                          <Row className="chart-description">
                            <Col span={2} className="pie-color">
                              <Badge
                                style={{ height: "14px" }}
                                color={this.colors(item.date)}
                              />
                            </Col>
                            <Col span={10} className="pie-name">
                              {item?.name}
                            </Col>
                            <Col span={8} className="pie-value">
                              {this.format(
                                (item?.value / dashboardStore.totalOpenAlarms) *
                                  100
                              )}
                              % | {item?.count}
                            </Col>
                          </Row>
                        </List.Item>
                      )
                    }
                  />
                </div>
              </div>
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
          </>
        )}
      </div>
    );
  }
}

export default withTranslation()(InfoAlarmMessage);
