import React, { Component } from 'react';
import { Form, Col, Modal, Row, Select, Tag, Tooltip, InputNumber, Icon, Button, Table, Input } from 'antd';
import { withTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
const { Option } = Select;
const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};
const MAX_LENGTH_TAG = 4;

@inject("rootStore")
@observer
class ModalCustomAnalysis extends Component {
  state = {
    inputVisible: false,
    inputValue: null,
    allTags: [],
  };
  showInput = () => {
    this.setState({ inputVisible: true }, () => this.input.focus());
  };
  handleAdd = (value) => {
    if (!this.state.allTags?.find((e) => e === value))
      this.setState({
        allTags: [...this.state.allTags, value],
        inputVisible: false,
        inputValue: "",
      });
    else
      this.setState({
        inputVisible: false,
      });
  };
  saveInputRef = (input) => (this.input = input);
  handleInputChange = (e) => {
    if (Number(e) < 0) return;
    this.setState({ inputValue: Number(e) });
  };
  handleInputConfirm = () => {
    const { inputValue } = this.state;
    if (inputValue || inputValue === 0) this.handleAdd(inputValue);
    else
      this.setState({
        inputVisible: false,
      });
  };
  handleClose = async (removedTag) => {
    this.setState({
      allTags: this.state.allTags.filter((e) => e !== removedTag),
    });
  };
  handleSubmitForm = (e) => {
    e.preventDefault();
    const {
		customAnalysisStore: {
			curentDownloadInfo: { formDownload, nameAnalysis, agency_uuid },
			selectedAnalysis,
			updateCurrentAnalysisStore,
			postNewCustomAnalysisStore,
			setDownloadFormData
		},
		isEdit,
		closeModal,
		form: { validateFields, resetFields },
		rootStore: {
			commonStore: { isSuperAdmin, isFromStatic },
		},
	} = this.props;

    validateFields((err, values) => {
      	if (!err?.name_custom_analysis) {
			const formData = formDownload.map((e) => {
			return {
				"event_code": e.event_code,
				"params": e.params
			}
			})
			if (isEdit) {
				updateCurrentAnalysisStore(selectedAnalysis.id, {
					name: values?.name_custom_analysis,
					events: formData,
				});
			}
			else {
				if (isSuperAdmin() && !isFromStatic) {
					postNewCustomAnalysisStore({
						name: nameAnalysis,
						events: formData,
						agency_uuid: agency_uuid,
					});
				} else {
					postNewCustomAnalysisStore({
						name: nameAnalysis,
						events: formData,
					});
				}
			}
			resetFields();
			closeModal();
			setDownloadFormData({
				selectedCategoryEvent: null,
			});
		}
    });
  }


  	handleDeleteCurrentAnalysis = () => {
		const {
			closeModal,
			resetFieldsDeleteCustomAnalysis,
			customAnalysisStore: {
				deleteCurrentCustomAnalysisStore,
				selectedAnalysis,
			},
			form: { resetFields },
		} = this.props;
		deleteCurrentCustomAnalysisStore(selectedAnalysis.id);
		resetFields();
		closeModal();
		resetFieldsDeleteCustomAnalysis();
	}

  addAllEvents = () => {
    const {
		customAnalysisStore: {
			listAllEvent,
			curentDownloadInfo: { selectedCategoryEvent, formDownload },
			setDownloadFormData,
		},
	} = this.props;
    const allEventList = listAllEvent[selectedCategoryEvent].event_list.map(event => {
      return (
        {
          event_code: event.event_code,
          param_type: event.param_type,
          params: this.state.allTags,
          event_name: event.event_label,
        }
      )
    })
    const filteredTableData = formDownload.filter(selectedEvent => {
      for (let i = 0; i < allEventList.length; i += 1) {
        if (selectedEvent.event_code === allEventList[i].event_code) return false;
      }
      return true;
    })

    const newTableData = [...filteredTableData, ...allEventList]

    setDownloadFormData({
      formDownload: newTableData,
    });
  }

  render() {
    const {
		t,
		openModal,
		closeModal,
		form: { getFieldDecorator, resetFields },
		isEdit,
		rootStore: {
			customAnalysisStore: {
				listAgencies,
				listCustomAnalysis,
				listAllEvent,
				selectedAnalysis,
				setDownloadFormData,
				curentDownloadInfo: {
					selectedCategoryEvent,
					selectedEvent,
					formDownload,
				},
			},
			commonStore: { isSuperAdmin, isFromStatic },
			myStore: { currentProfile, getCurrentRoleName },
	},
    } = this.props;
    const isOwner = listCustomAnalysis.find(customAnalysisItem => customAnalysisItem.id === selectedAnalysis?.id)?.owner_id === currentProfile.id;
    const isOperator = getCurrentRoleName() == "OPERATOR";
    const isEditCustomAnalysis = isOwner || !isOperator;
    const { inputVisible, inputValue, allTags } = this.state;
    return (
		<>
			<Form onSubmit={this.handleSubmitForm} id='form_analysis'>
				<Modal
					title={
						isEdit ? 'Edit Custom Analysis Template' : 'Create a new Custom Analysis Template'
					}
					onCancel={() => {
						resetFields('select_category_event'),
						resetFields('name_custom_analysis'),
						resetFields('agency'),
						closeModal(),
						setDownloadFormData({
							selectedCategoryEvent: null,
						});
					}}
					centered
					visible={openModal}
					width={1000}
					footer={
						isEdit
							? [
								<Button
									key='back'
									onClick={() => {
										resetFields('select_category_event'), 
										resetFields('name_custom_analysis'),
										resetFields('agency')
										closeModal(),
										setDownloadFormData({
											selectedCategoryEvent: null,
										});
									}}
								>
									{t('intersection.cancel')}
								</Button>,
								isEditCustomAnalysis && (
									<Button
										style={{ color: 'red' }}
										key='delete'
										type='danger'
										onClick={
											isEditCustomAnalysis &&
											(() => {
												this.handleDeleteCurrentAnalysis(),
												setDownloadFormData({
													selectedCategoryEvent: null,
												});
											})
										}
									>
										{t('delete')}
									</Button>
								),
								isEditCustomAnalysis && (
									<Button
										form='form_analysis'
										key={'add_edit'}
										htmlType='submit'
										type='primary'
										// loading={loading}
									>
										{t('save')}
									</Button>
								),
							]
							: 
							[
								<Button
									key='back'
									onClick={() => {
										resetFields('select_category_event'), 
										resetFields('name_custom_analysis'),
										resetFields('agency'),
										closeModal(),
										setDownloadFormData({
											selectedCategoryEvent: null,
										});
									}}
								>
									{t('intersection.cancel')}
								</Button>,
								<Button
									// loading={loading}
									form='form_analysis'
									key={'add_edit'}
									htmlType='submit'
									type='primary'
								>
									{t('save')}
								</Button>,
							]
					}
				>
					<div className='flex items-start mb-16 gap-5'>
						{isSuperAdmin() && !isFromStatic && listAgencies.length > 0 && !isEdit && (
							<Col className='w-full'>
								<Form.Item
									label={t('analysis.agencies')}
									style={{ marginBottom: 0 }}
								>
									{getFieldDecorator('agency', {
										rules: [
											{
												required: true,
												message: t('custom_analysis.validate_field_agency'),
											},
										],
									})(
										<Select
											onChange={(e) => {
												isSuperAdmin() &&
													!isFromStatic &&
													listAgencies.length > 0 &&
													setDownloadFormData({
														agency_uuid: e,
													});
											}}
											showSearch
											optionFilterProp='filter'
											filterOption={(input, option) => {
												return (
													option?.props?.filter
														?.toLowerCase()
														?.indexOf(input?.toLowerCase()) >= 0
												);
											}}
										>
											{listAgencies?.map((e, id) => (
												<Option filter={e.name} key={id} value={e.id}>
													{e.name}
												</Option>
											))}
										</Select>
									)}
								</Form.Item>
							</Col>
						)}
						<Col className='w-full'>
							<Form.Item
								label={t('custom_analysis.name')}
								style={{ marginBottom: 0 }}
							>
								{getFieldDecorator('name_custom_analysis', {
									initialValue:
										selectedAnalysis && isEdit ? selectedAnalysis.name : '',
									rules: [
										{
											required: true,
											message: t('custom_analysis.validate_field_name'),
										},
									],
								})(
									<Input
										disabled={isEdit && !isEditCustomAnalysis}
										onChange={(e) =>
											setDownloadFormData({
												nameAnalysis: e.target.value,
											})
										}
										placeholder={t('custom_analysis.name')}
										maxLength={128}
									/>
								)}
							</Form.Item>
						</Col>
					</div>
					<h3 className='bordered-top pt-16 pb-8 m-0 font-16'>Filter Data</h3>
					<div>
						<Row>
							<div className='flex gap-5'>
								<Col className='w-full'>
									<Form.Item
										label={t('intersection.select_category_event')}
										{...formItemLayout}
									>
										{getFieldDecorator('select_category_event', {
											initialValue: selectedCategoryEvent,
										})(
											<Select
												disabled={isEdit && !isEditCustomAnalysis}
												onChange={(value) => {
													setDownloadFormData({
														selectedCategoryEvent: value,
													});
													setDownloadFormData({
														selectedEvent: 'all',
													});
													resetFields('event_code');
												}}
											>
												<Option value={null}>
													{t('intersection.all_event_category')}
												</Option>
												{Object.keys(listAllEvent).map((key) => (
													<Option value={key} key={key}>
														{listAllEvent[key]?.group_name}
													</Option>
												))}
											</Select>
										)}
									</Form.Item>
								</Col>
								<Col className='w-full'>
									{selectedCategoryEvent && (
										<Form.Item
											label={t('intersection.select_event')}
											{...formItemLayout}
										>
											{getFieldDecorator('event_code', {
												rules: [
													{
														required: true,
														message:
															t('intersection.select_event') +
															t('is_required'),
													},
												],
												initialValue: selectedEvent,
												// valuePropName: selectedEvent
											})(
												<Select
													disabled={isEdit && !isEditCustomAnalysis}
													showSearch
													filterOption={(input, option) => {
														return (
															option?.props?.children
																?.toLowerCase()
																?.indexOf(input?.toLowerCase()) >= 0
														);
													}}
													onChange={(value) => {
														const existingItem = formDownload.find(
															(e) => e.event_code === value
														);
														setDownloadFormData({
															selectedEvent: value,
														});
														if (existingItem)
															this.setState({
																allTags: existingItem.params,
															});
														else
															this.setState({
																allTags: [],
															});
													}}
													// value={selectedEvent}
													placeholder={t('intersection.select_event')}
												>
													<Option value={'all'}>All events</Option>
													{listAllEvent[
														selectedCategoryEvent
													]?.event_list.map((e) => (
														<Option value={e.event_code} key={e.event_code}>
															{e.event_label}
														</Option>
													))}
												</Select>
											)}
										</Form.Item>
									)}
								</Col>
							</div>
							{selectedCategoryEvent ||
							(selectedAnalysis?.events.length > 0 &&
								selectedCategoryEvent !== null) ? (
								<Col span={24}>
									<Form.Item
										{...formItemLayout}
										label={t('intersection.enter_params_type', {
											value: `${listAllEvent[selectedCategoryEvent].group_name}:`,
										})}
										className='mb-8'
									>
										<div className='input-tag'>
											{this.state.allTags?.map((item) => {
												const tag = item;
												const tagString = tag?.toString();
												const isLongTag = tagString.length > MAX_LENGTH_TAG;
												const tagElem = (
													<Tag
														disabled={isEdit && !isEditCustomAnalysis}
														className='default-tag'
														key={tag}
														closable={!item.is_default}
														onClose={() => this.handleClose(tag)}
													>
														{isLongTag
															? `${tagString.slice(0, MAX_LENGTH_TAG)}...`
															: tagString}
													</Tag>
												);
												if (!item.is_default)
													return isLongTag ? (
														<Tooltip title={tag} key={tag}>
															{tagElem}
														</Tooltip>
													) : (
														tagElem
													);
											})}
											{inputVisible && (
												<InputNumber
													disabled={isEdit && !isEditCustomAnalysis}
													min={0}
													ref={this.saveInputRef}
													type='text'
													size='default'
													value={inputValue}
													onChange={this.handleInputChange}
													onBlur={this.handleInputConfirm}
													onPressEnter={this.handleInputConfirm}
												/>
											)}
											{!inputVisible && (
												<Tag
													disabled={isEdit && !isEditCustomAnalysis}
													className='p-4 px-8'
													onClick={this.showInput}
													style={{
														background: '#fff',
														borderStyle: 'dashed',
													}}
												>
													<Icon type='plus' /> {t('intersection.new_param')}
												</Tag>
											)}
										</div>
									</Form.Item>
								</Col>
							) : (
								<></>
							)}
						</Row>
						{selectedCategoryEvent || selectedAnalysis?.events.length > 0 ? (
							<Row>
								<div className='w-full flex justify-space-beetwen mb-8'>
									<span>{t('intersection.filter_label')}</span>
									<div className="flex gap-2">
										{selectedCategoryEvent !== null ? (
											<Button
												icon='plus'
												type='primary'
												onClick={() => {
													this.props.form.validateFields((err) => {
														if (!err) {
															if (selectedEvent === 'all') {
																this.addAllEvents();
																return;
															} else {
																const selectedEventFind = listAllEvent[
																	selectedCategoryEvent
																]?.event_list.find(
																	(e) => e.event_code === selectedEvent
																);
																const filterOldata = formDownload.filter(
																	(e) => e.event_code !== selectedEvent
																);
																setDownloadFormData({
																	formDownload: [
																		{
																			event_code: selectedEvent,
																			param_type:
																				selectedEventFind.param_type,
																			params: allTags,
																			event_name:
																				selectedEventFind.event_label,
																		},
																		...filterOldata,
																	],
																});
															}
														}
													});
												}}
											>
												{t('intersection.add_filter')}
											</Button>
										) : (
											<></>
										)}
										<Button
											disabled={isEdit && !isEditCustomAnalysis}
											icon='delete'
											type='primary'
											onClick={() => {
												setDownloadFormData({
													formDownload: [],
												});
											}}
										>
											{t('intersection.remove_all_filters')}
										</Button>
									</div>
								</div>
								<h3 className='bordered-top pt-16 pb-8 m-0 font-16'>Displayed Data</h3>
								<Table
									pagination={{ pageSize: 8 }}
									className='spm-table'
									columns={[
										{
											title: t('intersection.select_event'),
											dataIndex: 'event_name',
											key: 'event_name',
										},
										{
											title: t('intersection.param_type'),
											key: 'param_type',
											dataIndex: 'param_type',
										},
										{
											title: t('intersection.params_value'),
											dataIndex: 'params',
											key: 'params',
											render: (data) => {
												return data?.length ? data.join(', ') : t('all');
											},
										},
										{
											title: t('table.actions'),
											key: 'action',
											render: (item) => {
												return (
													(
														<Button
															disabled={isEdit && !isEditCustomAnalysis}
															icon='delete'
															type='link'
															onClick={() => {
																const newList = formDownload.filter(
																	(e) => e.event_code !== item.event_code
																);
																setDownloadFormData({
																	formDownload: newList,
																});
															}}
														/>
													)
												);
											},
										},
									]}
									rowKey='event_code'
									dataSource={formDownload}
								/>
							</Row>
						) : (
							<></>
						)}
					</div>
				</Modal>
			</Form>
		</>
	);
  }
}

const ModalCustomAnalysisForm = Form.create({ name: 'custom_analysis_modal' })(ModalCustomAnalysis);

export default withTranslation()(ModalCustomAnalysisForm);


