import React from "react";
import * as d3 from "d3";
import moment from 'moment';
import { FONT_STRING_CAVAS } from "utils/constants";
import BaseRingChart from "../BaseRingChart";
class RingChartProgrammed extends BaseRingChart {
  constructor(props) {
    super(props);
    this.dataRing1 = this.data.ring1;
    this.dataRing2 = this.data.ring2;
    this.margin = {left: 180, right: 25}
  }

  getInitialAxisProgrammed = () => {
    const endTimeRing1 = moment.duration(moment(this.data.ring1.slice(-1)[0].end_time).format("HH:mm:ss").toString()) - moment.duration(moment(this.data.ring1[0].start_time).format("HH:mm:ss").toString())
    const endTimeRing2 = moment.duration(moment(this.data.ring2.slice(-1)[0].end_time).format("HH:mm:ss").toString()) - moment.duration(moment(this.data.ring2[0].start_time).format("HH:mm:ss").toString())
    const maxTime = Math.max(endTimeRing1, endTimeRing2)
    this.rangeX = [0, maxTime];
    this.rangeY = [0, 100];
    
    this.xScale = d3
      .scaleLinear()
      .domain(this.rangeX)
      .range([0, this.width - this.margin.left - this.margin.right]);
    this.yScale = d3
      .scaleLinear()
      .domain(this.rangeY)
      .range([this.height, 0]);
  };

  drawLabelsProgrammed() {
    let ctx = this.ctx;
    ctx = this.canvas.node().getContext('2d');

    //Data labels:
    ctx.font = `14px ${FONT_STRING_CAVAS}`;
    ctx.textAlign = "start";
    ctx.fillStyle = "#606060";
    const splitTime = 'Cycle length: ';
    const offsetTime = 'Offset time: ';
    ctx.fillText(splitTime, -this.margin.left, 45);
    ctx.fillText(offsetTime, -this.margin.left, 69);
    
    ctx.font = `14px ${FONT_STRING_CAVAS}`;
    ctx.fillText(`${moment.duration(moment(this.data.ring1.slice(-1)[0].end_time) - moment(this.data.ring1[0].start_time))/1000}s`, -this.margin.left + ctx.measureText(splitTime).width, 45);
    ctx.fillText(`${this.data.offset_time}s`, -this.margin.left + ctx.measureText(offsetTime).width, 69);

    //Ring 1 and ring 2 labels:
    ctx.textAlign = "end";
    ctx.fillText("Ring 1", -10, 45);
    ctx.fillText("Ring 2", -10, 69);

    //Numbers on x axis:
    ctx.fillStyle = "#111111";
    const calculateNumberTicks = (this.width < 300) ? 2 : Math.floor(5/650 * this.width);

    ctx.font = `12px ${FONT_STRING_CAVAS}`;

    let xTickCount = calculateNumberTicks,
      xTicks = this.xScale.ticks(xTickCount),
	    xTickFormat = this.xScale.tickFormat();

    xTicks.forEach((d) => {
      ctx.textAlign = "center";
      ctx.fillText(xTickFormat(d/1000), this.xScale(d), 95);
    })
  }

  handleMouseMoveProgrammed = () => {
    const width = this.areaRef.current.offsetWidth;
    const height = this.areaRef.current.offsetHeight;

    var mouseX = d3.event.layerX || d3.event.offsetX;
    var mouseY = d3.event.layerY || d3.event.offsetY;

    const realXCoord = mouseX - this.margin.left;

    d3.event.preventDefault();
    this.hoverCtx.clearRect(0, 0, width, height);

    const lineCtx = d3
      .line()
      .x((d) => d.x)
      .y((d) => d.y)
      .context(this.hoverCtx);
    this.hoverCtx.beginPath();
    lineCtx([
      {
        x: mouseX,
        y: 0,
      },
      {
        x: mouseX,
        y: this.height,
      },
    ]);
    lineCtx([
      {
        x: 0,
        y: mouseY,
      },
      {
        x: width,
        y: mouseY,
      },
    ]);
    this.hoverCtx.lineWidth = 0.5;
    this.hoverCtx.strokeStyle = "black";
    this.hoverCtx.stroke();
    this.hoverCtx.closePath();
    
    this.dataRing1.forEach((point) => {
      if (
          this.xScale(moment.duration(moment(point.start_time) - moment(this.dataRing1[0].start_time))) <= realXCoord 
          && this.xScale(moment.duration(moment(point.end_time) - moment(this.dataRing1[0].start_time))) >= realXCoord 
          && mouseY >= 35 
          && mouseY <= 55
      ) {
        //draw x y axis of mouse
        this.hoverCtx.beginPath();
        this.hoverCtx.fillStyle = "white";
        this.hoverCtx.fillRect(
          mouseX > width / 2 ? mouseX - 80 : mouseX + 40,
          mouseY > height / 2 ? mouseY - 30 : mouseY + 10,
          60,
          20
        );
        this.hoverCtx.closePath();
        
        this.hoverCtx.beginPath();
        this.hoverCtx.font = `12px sans-serif`;
        this.hoverCtx.fillStyle = "black";
        const startFillText =
          mouseX > width / 2 ? mouseX - 75 : mouseX + 45;
        this.hoverCtx.fillText(
          `P${point.phase}: ${moment.duration(moment(point.end_time) - moment(point.start_time))/1000}s`,
          startFillText,
          mouseY > height / 2 ? mouseY - 15 : mouseY + 25
        );
        this.hoverCtx.closePath();
      }
    })

    this.dataRing2.forEach((point) => {
      if (
          this.xScale(moment.duration(moment(point.start_time) - moment(this.dataRing1[0].start_time))) <= realXCoord 
          && this.xScale(moment.duration(moment(point.end_time) - moment(this.dataRing1[0].start_time))) >= realXCoord 
          && mouseY >= 59 
          && mouseY <= 79
      ) {
        //draw x y axis of mouse
        this.hoverCtx.beginPath();
        this.hoverCtx.fillStyle = "white";
        this.hoverCtx.fillRect(
          mouseX > width / 2 ? mouseX - 80 : mouseX + 40,
          mouseY > height / 2 ? mouseY - 30 : mouseY + 10,
          60,
          20
        );
        this.hoverCtx.closePath();
        
        this.hoverCtx.beginPath();
        this.hoverCtx.font = `12px sans-serif`;
        this.hoverCtx.fillStyle = "black";
        const startFillText =
          mouseX > width / 2 ? mouseX - 75 : mouseX + 45;
        this.hoverCtx.fillText(
          `P${point.phase}: ${moment.duration(moment(point.end_time) - moment(point.start_time))/1000}s`,
          startFillText,
          mouseY > height / 2 ? mouseY - 15 : mouseY + 25
        );
        this.hoverCtx.closePath();
      }
    })
  }

  drawHiddenCanvas = () => {
    const base = d3.select(this.areaRef.current);
    base.selectAll(".hiddenCanvasSplitMonitor").remove();

    this.hiddenCanvas = base
      .append("canvas")
      .classed("hiddenCanvasSplitMonitor", true)
      .attr("width", this.width)
      .attr("height", this.height);
    this.hoverCtx = this.hiddenCanvas.node().getContext("2d");

    d3.select(this.hiddenCanvas.node()).on("mousemove", this.handleMouseMoveProgrammed);
    d3.select(this.hiddenCanvas.node()).on("mouseleave", this.handleMouseOut);
  };

  componentDidMount() {
    this.drawCanvas();
    this.getInitialAxisProgrammed();
    this.drawRings();
    this.drawLabelsProgrammed();
    this.drawHiddenCanvas();
  }

  render() {
    return(
      <div className="split-monitor-container">
        <div ref={this.areaRef} width={this.width} className="hidden-content-split-monitor"></div>
        <div className="ring-chart" ref={this.chartRef} style={{width:"100%", height: "100%"}}></div>
      </div>
    )
  }
}

export default RingChartProgrammed;