import { Button, Modal, Spin } from "antd";
import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import { TIMEOUT_RESIZE } from "utils/constants";
import AdjustmentResult from "./AdjustmentResult";

@inject("rootStore")
@observer
class ModalAogChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resizing: false,
    };
    this.timeoutResize = null;
  }
  /**
   * componentDidMount
   *
   */
  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }
  handleResize = () => {
    this.setState({ resizing: true });
    if (this.timeoutResize) clearTimeout(this.timeoutResize);
    this.timeoutResize = setTimeout(() => {
      this.setState({
        resizing: false,
      });
    }, TIMEOUT_RESIZE);
  };
  tranformDataChart = (data) => {
    return data?.offset_results?.map((item) => {
      const object = {
        offset: item.offset,
      };
      item.predicted_list.forEach((predict) => {
        object["predicted_phase_" + predict.phases] = predict.predicted_aog;
      });
      object.total_aog = item.total_aog;
      return object;
    });
  };
  render() {
    const { aogData, t, onClose } = this.props;
    const data = aogData;
    const offsetTranformData = this.tranformDataChart(aogData);

    return (
      <Modal
        title={t("optimization.adjusment_detail_table")}
        visible={aogData ? true : false}
        width="70vw"
        centered
        okButtonProps={{ display: "none" }}
        footer={[
          <Button key="back" onClick={onClose}>
            Close
          </Button>,
        ]}
        destroyOnClose
        onCancel={onClose}>
        {" "}
        <div
          className="w-full h-full flex items-center justify-center"
          style={{ height: "70vh" }}>
          {this.state.resizing ? (
            <Spin spinning></Spin>
          ) : (
            <AdjustmentResult
              t={t}
              height={"70vh"}
              chartType={"aog"}
              gapdata={[]}
              onChartZoom={() => {}}
              rootData={data}
              data={{
                offset_results: offsetTranformData,
                plan_statistics: [],
                current_offset: data?.current_offset,
              }}
            />
          )}{" "}
        </div>
      </Modal>
    );
  }
}

export default withRouter(withTranslation()(ModalAogChart));
