import { Icon, Tooltip } from "antd";
import ChartPlan from "components/ChartPlan";
import React, { Component } from "react";

class WrapChartComponent extends Component {
  constructor(props) {
    super(props);
    // create a ref to store the textInput DOM element
    this.chartPlan = React.createRef();
    this.chartData = React.createRef();
    this.state = {
      isZomming: false,
    };
  }
  handleChartZoom = (newXScale) => {
    this.chartPlan?.current?.updateChart(newXScale);
    this.setState({
      isZomming: true,
    });
  };
  clearZoomingChart = () => {
    this.chartData?.current?.resetZoom();
    this.setState({
      isZomming: false,
    });
  };

  render() {
    const { t, isCompareMode, isPrintMode, children, dataProps } = this.props;
    return (
      <div
        style={{ height: this.props.height, position: "relative" }}
        className="mb-16">
        {!isCompareMode && (
          <div className="chart-info-wrap flex justify-space-beetwen">
            <div className="font-bold">
              {
                dataProps?.data?.phase === 'all' ? (
                  t("analysis.all_phases")
                ) : (
                  t("analysis.phase_name") + " " + dataProps.data.phase
                )
              }
            </div>
          </div>
        )}
        {this.state.isZomming && (
          <Tooltip placement="top" title={t("analysis.zoom_explaination")}>
            <Icon
              onClick={this.clearZoomingChart}
              className="chart-zoom-mark"
              type="reload"
            />
          </Tooltip>
        )}
        {!isCompareMode ? (
          <div className="chart-container">
            <div className="chart-plan-container">
              <ChartPlan {...dataProps} ref={this.chartPlan} />
            </div>
            <div className="chart-detail-container">
              {" "}
              {React.cloneElement(children, {
                onChartZoom: this.handleChartZoom,
                ref: this.chartData,
              })}
            </div>
          </div>
        ) : (
          <div
            className={
              isPrintMode ? "chart-container compare-chart" : "chart-container"
            }>
            <div className="chart-detail-container">
              {" "}
              {React.cloneElement(children, {
                onChartZoom: this.handleChartZoom,
                ref: this.chartData,
              })}
            </div>
            <div className="chart-plan-container">
              <ChartPlan {...dataProps} ref={this.chartPlan} />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default WrapChartComponent;
