import { Badge, Button, Col, Icon, Input, Select, Tag, Tooltip } from "antd";
import { inject, observer } from "mobx-react";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import { ACTIONS_LIST, DATE_FORMAT, RESOURCE_LIST } from "utils/constants";
import BaseManagementComponents from "components/BaseManagementComponents";
import i18n from "i18n";
import "./style.scss";
import { getFullDateRangeString, getShortDateRangeString } from "utils/helper";

const { Option } = Select;

const MAP_PROGRESS_STATUS = {
  COMPLETED: {
    key: "COMPLETED",
    label: i18n.t("completed"),
    color: "green",
  },
  IN_PROGRESS: {
    key: "IN_PROGRESS",
    label: i18n.t("in_progress"),
    color: "blue",
  },
  ERROR: {
    key: "ERROR",
    label: i18n.t("error"),
    color: "red",
  },
};

@inject("rootStore")
@observer
class OffsetHistory extends BaseManagementComponents {
  constructor(props) {
    super(props.rootStore.optimizerStore, props);
    const { t } = props;
    this.enableRowSelection = true;
    this.enableCreateButton = false;
    this.rowKey = "id";
    this.moduleName = "optimization";
    this.resourceName = RESOURCE_LIST.OPTIMIZATION;
    this.pageTitle = t("optimization.optimization_history");
    this.columns = this.getTableColumns();
    this.enableRedirectPage = true;
    this.state = {
      expand: true,
    };
    this.buttonActions = [
      {
        key: "delete_all",
        title: t("report_template.delete_all"),
        type: "delete",
        resource: RESOURCE_LIST.OPTIMIZATION_HISTORY,
        permission: ACTIONS_LIST.DELETE,
        action: this.currentStore?.delete,
        confirm: true,
        confirmTitle: t("report_template.delete_confirm_title"),
        confirmContent: t("report_template.delete_confirm_content"),
      },
    ];
    this.tableInlineActions = [
      {
        key: "delete_all",
        tooltip: t("report_template.delete"),
        resource: RESOURCE_LIST.OPTIMIZATION_HISTORY,
        action: this.handleTableAction,
        permission: ACTIONS_LIST.DELETE,
        icon: "delete",
        passingData: (item) => {
          return ["delete_all", [item?.id]];
        },
        visible: (item) => {
          return item?.status !== MAP_PROGRESS_STATUS.IN_PROGRESS.key;
        },
      },
      {
        key: "delete_disable",
        tooltip: t("optimization.disable_delete"),
        resource: RESOURCE_LIST.OPTIMIZATION_HISTORY,
        permission: ACTIONS_LIST.VIEW,
        action: this.handleTableAction,
        className: "disable-button",
        icon: "delete",
        notEnable: () => true,
        visible: (item) =>
          !props.rootStore.myStore.hasPermission(
            ACTIONS_LIST.DELETE,
            RESOURCE_LIST.OPTIMIZATION_HISTORY,
            false,
            item.owner_id
          ),
      },
      {
        key: "view_result",
        tooltip: t("optimization.view_result"),
        action: this.goToDetailPage,
        resource: RESOURCE_LIST.OPTIMIZATION_HISTORY,
        permission: ACTIONS_LIST.VIEW,
        child: <Icon style={{ fontSize: "16px" }} type="arrow-right" />,
        visible: (item) => {
          return item?.status === MAP_PROGRESS_STATUS.COMPLETED.key;
        },
        passingData: (item) => {
          return [item];
        },
      },
    ];
  }

  componentDidMount = () => {};
  goToDetailPage = (item) => {
    this.props.history.push(
      "/analysis/optimizer/" + item.id + "?type=" + item.optimization_type.key
    );
  };

  fetchReportTemplateByAgency = (isSuperAdmin) => {
    this.currentStore?.setDetaultAgencyFilter(isSuperAdmin, () => {
      this.currentStore?.getData(() => {});
    });
    this.currentStore?.getAllUsers();
    // this.currentStore?.getAllIntersections();
  };

  getTableColumns = () => {
    const { t } = this.props;
    return [
      {
        title: t("optimization.created_at"),
        key: "created_at",

        ellipsis: true,
        render: (item) =>
          moment(item?.created_at).format(DATE_FORMAT.full_with_tz),
        sorter: true,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: t("optimization.created_by"),
        key: "created_by",
        ellipsis: true,
        render: (item) => t(item?.owner?.name),
      },
      {
        title: t("intersection") + "/" + t("Corridor"),
        key: "name",
        sorter: true,
        ellipsis: true,
        dataIndex: "name",
      },
      {
        title: t("optimization.type"),
        key: "optimization_type",
        render: (item) => t(item?.optimization_type.name),
      },
      {
        title: t("report_result.time_range"),
        key: "time_range",
        ellipsis: true,

        render: (item) =>
          item?.start_time && item?.end_time
            ? item?.start_time + " - " + item?.end_time
            : "",
      },
      {
        title: t("report_result.date_range"),
        key: "date_range",
        ellipsis: false,

        render: (item) => (
          <Tooltip
            title={
              <span className="ant-tooltip-pre-wrap">
                {getFullDateRangeString(item, t)}
              </span>
            }>
            {getShortDateRangeString(item, t)}
          </Tooltip>
        ),
      },
      {
        title: t("report_template.status"),
        dataIndex: "status",
        key: "status",
        sorter: true,

        render: (status) => (
          <Tag color={MAP_PROGRESS_STATUS[status].color} key={status}>
            <Badge color={MAP_PROGRESS_STATUS[status].color} />
            {MAP_PROGRESS_STATUS[status].label}
          </Tag>
        ),
      },
    ];
  };

  renderFilterComponent = () => {
    const {
      t,
      rootStore: { commonStore },
    } = this.props;

    return (
      <div className="flex w-full justify-space-beetwen">
        <div className="w-full">
          <Col lg={8} xl={5}>
            <Tooltip title={t("tsp_dashboard.search_by_int_name")}>
              <Input
                value={this.currentStore?.filterData?.text}
                onChange={(e) =>
                  this.currentStore?.handleFilterDataChange(
                    "text",
                    e?.target.value
                  )
                }
                placeholder={t("tsp_dashboard.search_by_int_name")}
              />
            </Tooltip>
          </Col>
          {commonStore.isSuperAdmin() && (
            <Col lg={8} xl={3}>
              <Tooltip
                title={t("report_template.filter_agency")}
                key={this.currentStore.listAgencies.length}>
                <Select
                  showSearch
                  onChange={(value) =>
                    this.currentStore.handleFilterDataChange("agency_id", value)
                  }
                  value={this.currentStore?.filterData.agency_id}
                  optionFilterProp="filter"
                  filterOption={(input, option) => {
                    return (
                      option?.props?.filter
                        ?.toLowerCase()
                        ?.indexOf(input?.toLowerCase()) >= 0
                    );
                  }}>
                  <Option
                    key={null}
                    value={null}
                    filter={t("agency.all_agencies")}>
                    {t("agency.all_agencies")}
                  </Option>
                  {this.currentStore.listAgencies?.map((e) => (
                    <Option key={e?.id} value={e?.id} filter={e?.name}>
                      {e?.name}
                    </Option>
                  ))}
                </Select>
              </Tooltip>
            </Col>
          )}
          <Col lg={16} xl={4}>
            <Tooltip title={t("optimization.filter_by_optimization_type")}>
              <Select
                onChange={(value) =>
                  this.currentStore.handleFilterDataChange("type", value)
                }
                defaultValue={this.currentStore?.filterData?.type}>
                <Option value={null}>
                  {t("optimization.filter_all_optimization_type")}
                </Option>
                {this.currentStore?.listOptimizationTypes?.map((e) => (
                  <Option value={e?.key} key={e?.key}>
                    {e?.name}
                  </Option>
                ))}
              </Select>
            </Tooltip>
          </Col>
          <Col lg={8} xl={4}>
            <Tooltip title={t("optimization.filter_status")}>
              <Select
                onChange={(value) =>
                  this.currentStore.handleFilterDataChange("status", value)
                }
                defaultValue={this.currentStore?.filterData?.status}>
                <Option value={null}>{t("optimization.all_status")}</Option>
                {Object.keys(MAP_PROGRESS_STATUS).map((e) => (
                  <Option
                    value={MAP_PROGRESS_STATUS[e]?.key}
                    key={MAP_PROGRESS_STATUS[e]?.key}>
                    {MAP_PROGRESS_STATUS[e].label}
                  </Option>
                ))}
              </Select>
            </Tooltip>
          </Col>
        </div>
        <div>
          <div>
            <Tooltip placement="top" title={t("dashboard.refresh_tooltip")}>
              <Button
                loading={this.currentStore.loading}
                type="primary"
                icon="reload"
                size="large"
                className="auto-reload-btn"
                onClick={() => {
                  this.currentStore.getData();
                }}
              />
            </Tooltip>
          </div>
        </div>
      </div>
    );
  };
  toggle = () => {
    const { expand } = this.state;
    this.setState({ expand: !expand });
  };
  render() {
    return (
      <div className="wrap-alarm-rule bg-background">{super.render()}</div>
    );
  }
}

OffsetHistory.propTypes = {
  rootStore: PropTypes.any,
  history: PropTypes.any,
};

export default withRouter(withTranslation()(OffsetHistory));
