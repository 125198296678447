import { observable, action } from "mobx";
import moment from "moment";
import SummaryTableService from "../../services//TSPServices/SummaryTableService";
import BaseStore from "../BaseStore";

/**
 * Store for intersections
 *
 * @class
 */
class SummaryTableStore extends BaseStore {
  constructor(parentStore) {
    super(new SummaryTableService(parentStore), parentStore);

    this.filterData = {
      ...this.filterData,
      ...{
        text: "",
        agency_id: parentStore?.myStore?.currentAgency?.agency_id,
        from_time:
          Math.floor((moment().valueOf() - 86400000) / 3600000) * 3600000,
        to_time: Math.floor(moment().valueOf() / 3600000) * 3600000,
        range: 86400000,
        sort: "",
      },
    };
  }
  @observable selectedItem = null;
  //data for filtering
  //data for map
  @action getDataById = (item_id, cb) => {
    this.itemData = this.listData.find((e) => e.intersection_uuid === item_id);
    cb && cb();
  };
}

export default SummaryTableStore;
