import * as d3 from "d3";
import React, { Component, createRef } from "react";
import { withTranslation } from "react-i18next";
import { CHART_COLOR_SCHEMA } from "utils/constants";
import './style.scss'

class AbnormalChartSVG extends Component {
  fullWidth = 0;
  fullHeight = 0;
  marginX = 64;
  marginY = 16;
  yOffsetInfo = 48;
  tooltipWidth = 140;
  tooltipMargin = 20;
  crossHairObject = {};
  
  constructor(props) {
    super(props);
    this.chartRef = createRef();
  }

  renderChartCrossHair = () => {
    let currentContext = this;
    //render overlay rect cover all chart + add crosshair on that
    let overLayGroup = this.chartObject
      .append("g")
      .attr("class", "overlay-group");
    let crossHairGroup = overLayGroup
      .append("g")
      .attr("class", "cross-hair-group");
    //add crosshair line
    this.crossHairObject.crosshair_x = crossHairGroup
      .append("line")
      .attr("class", "cross-hair-line")
      .attr("stroke", "var(--text)")
      .attr("display", "none")
      .attr("x1", 0)
      .attr("y1", this.marginY)
      .attr("x2", 0)
      .attr("y2", this.fullHeight + this.marginY);
    this.crossHairObject.crosshair_y = crossHairGroup
      .append("line")
      .attr("class", "cross-hair-line")
      .attr("stroke", "var(--text)")
      .attr("display", "none")
      .attr("x1", this.marginX)
      .attr("y1", 0)
      .attr("x2", this.fullWidth + this.marginX)
      .attr("y2", 0);
    //add overlay rect
    overLayGroup
      .append("rect")
      .attr("class", "overlay-area")
      .attr("width", this.fullWidth)
      .attr("height", this.fullHeight)
      .attr("fill-opacity", "0")
      .attr("transform", "translate(" + this.marginX + "," + this.marginY + ")")
      .on("mousemove", function () {
        let mouse = d3.mouse(this);
        currentContext.handleMouseMove(mouse);
      })
      .on("mouseout", this.handleMouseOut);

    //handler mouse hover /movein/ moveout of overlay to draw cross hair and detail box
  };

  drawHighlight = (day, i, x, y) => {
    if (day) {
      this.highlightObject = this.chartObject
        .append("g")
        .attr("class", "hightline-group");
      const tooltipHeight = 40;
      const xTooltipPosition =
        x < this.fullWidth / 2
          ? this.marginX + x + this.tooltipMargin
          : this.marginX + x - this.tooltipWidth - this.tooltipMargin;
      const yTooltipPosition =
        y < this.fullHeight / 2
          ? y + tooltipHeight / 2 + this.tooltipMargin
          : y - tooltipHeight / 2 - this.tooltipMargin;

      this.highlightObject
        .append("rect")
        .attr("width", this.tooltipWidth) //hard code the width of box
        .attr("height", tooltipHeight)
        .attr("class", "tooltip-box")
        .attr("stroke", "var(--text)")
        .attr("fill", "var(--background)")
        .attr(
          "transform",
          "translate(" + xTooltipPosition + "," + yTooltipPosition + ")"
        );
      let labelIndex = 1;
      const textArray = [
        {
          text: `Hour: ${i}`,
          color: "#606060",
        },
        {
          text: `Raw Count: ${day[i] || 0}`,
          color: "#606060",
        },
      ];
      textArray.forEach((txt) => {
        this.highlightObject
          .append("text")
          .text(txt.text) //@TODO:hard code here
          .attr("text-anchor", "start")
          .attr("class", "tooltip-info-text")
          .attr(
            "transform",
            "translate(" +
              (xTooltipPosition + 16) +
              "," +
              (yTooltipPosition + 16 * labelIndex) +
              ")"
          )
          .attr("fill", txt.color);
        labelIndex += 1;
      });
    }
  };

  handleMouseMove = (mousePosition) => {
    this.highlightObject?.remove();

    if (Array.isArray(mousePosition) && mousePosition.length === 2) {
      let xPosition = mousePosition[0];
      let yPosition = mousePosition[1];
      
      this.crossHairObject?.crosshair_x
        ?.attr("display", "block")
        .attr("x1", xPosition + this.marginX)
        .attr("x2", xPosition + this.marginX);
      
      this.crossHairObject?.crosshair_y
        ?.attr("display", "block")
        .attr("y1", yPosition + this.marginY)
        .attr("y2", yPosition + this.marginY);

      this.props.data.forEach((day, index) => {
        if (Object.keys(day).length === 1) return;
        
        const minTime = Number(this.props.metadata.from_time.split(":")[0]);
        const maxTime = Number(this.props.metadata.to_time.split(":")[0]);

        for (let i = minTime; i < (maxTime + 1); i += 1) {
          
          const xCoordMin = this.xScaleObject((index + 1) - (0.48 * (maxTime-minTime)/24) + 0.035*(i-minTime+1));
          const xCoordMax = this.xScaleObject((index + 1) - (0.48 * (maxTime-minTime)/24) + 0.035*(i-minTime+1)) + this.xScaleObject(1)*0.03
  
          if (xPosition >= xCoordMin && xPosition <= xCoordMax) {
            this.drawHighlight(day, i, xPosition, yPosition);
          }
        }
      })
    }
  };
  handleMouseOut = () => {
    //hide crossHair
    this.crossHairObject?.crosshair_x?.attr("display", "none");
    this.crossHairObject?.crosshair_y?.attr("display", "none");
    //hide highlight data point
    this.highlightObject?.remove();
  };
  drawXAxis = () => {
    this.xScaleObject = d3
      .scaleLinear()
      .domain([0, this.props.data.length + 1])
      .range([0, this.fullWidth]);

    //create axisObject
    let xAxisObject = d3
      .axisBottom(this.xScaleObject)
      .tickSize(0)
      .tickFormat(() => null);

    //draw x axis line
    this.xAxis = this.chartObject
      .append("g")
      .attr("class", "x-axis-botttom")
      .attr(
        "transform",
        "translate(" +
          this.marginX +
          "," +
          (this.fullHeight + this.marginY) +
          ")"
      )
      .call(xAxisObject);
  };

  drawYAxis = () => {
    let maxY = 0;
    const maxValues = [];
    this.props.data.forEach((day => {
      const dayMax = Object.values(day).filter(entry => (typeof entry !== 'string'));
      maxValues.push(Math.max(...dayMax));
    }))

    maxY = Math.max(...maxValues);

    this.yScaleObject = d3
      .scaleLinear()
      .domain([maxY * 1.15, 0])
      .range([0, this.fullHeight]);

    let yAxisObject = d3.axisLeft(this.yScaleObject).tickSize(-this.fullWidth);

    this.yAxis = this.chartObject
      .append("g")
      .attr("clip-path", "url(#clip-plan)")
      .attr("transform", "translate(" + this.marginX + "," + this.marginY + ")")
      .attr("class", "y-axis-bar-chart")
      .call(yAxisObject);
  };
  drawAxis() {
    this.drawXAxis();
    this.drawYAxis();
  }

  drawBarChart = (group, index, barChartGroup) => {

    barChartGroup
      .attr(
        "transform",
        "translate(" + this.marginX + ", " + this.marginY + ")"
      )

    if (Object.keys(group).length === 1) return;
    const minTime = Number(this.props.metadata.from_time.split(":")[0]) //Math.min(...hours);
    const maxTime = Number(this.props.metadata.to_time.split(":")[0]) //Math.max(...hours);
    for (let i = minTime; i < (maxTime + 1); i += 1) {
      
      const xCoord = this.xScaleObject((index + 1) - (0.48 * (maxTime-minTime)/24) + 0.035*(i-minTime+1));
      const yCoord = group[i] ? this.yScaleObject(group[i]) : this.fullHeight*0.99;
      const width = this.xScaleObject(1)*0.03;
      const height = group[i] ? (this.fullHeight - this.yScaleObject(group[i])) : this.fullHeight*0.01
      barChartGroup
        .append("rect")
        .style("fill", CHART_COLOR_SCHEMA.GREEN)
        .attr("clip-path", "url(#clip)")
        .attr("x", xCoord)
        .attr("y", yCoord)
        .attr("width", width)
        .attr("class", "bar")
        .attr("height", height);
    }
  };

  drawInfo = (group, index, barChartGroup) => {
    const pointX = this.xScaleObject(index + 1);
    // up down total
    barChartGroup
      .append("text")
      .text(`${group.date}`)
      .attr("text-anchor", "middle")
      .style("font-size", "16px")
      .attr("x", pointX)
      .attr("y", this.fullHeight + this.marginY + 10)
      .attr("fill", group.is_coord_phase ? CHART_COLOR_SCHEMA.GREEN_SIEMENS : 'var(--text)');
  };
  
  drawDateData(group, index) {
    let barChartGroup = this.chartObject
      .append("g")
      .attr("class", "lines-chart-group")
      
    this.drawBarChart(group, index, barChartGroup);
    this.drawInfo(group, index, barChartGroup);
  }

  drawData = () => {
    this.props.data.forEach((group, id) => {
      this.drawDateData(group, id);
    });
  };

  componentDidUpdate() {
    if (this.props.data) {
      this.chartObject = d3.select(this.chartRef?.current);
      this.chartObject.html('');

      this.fullWidth =
        this.chartRef.current.getBoundingClientRect().width - this.marginX * 2;
      this.fullHeight =
        this.chartRef.current.parentNode.offsetHeight -
        this.marginY * 2 -
        this.yOffsetInfo;
  
      this.drawAxis();
      this.drawData();
      this.renderChartCrossHair();
    }
  }

  render() {
    return <svg ref={this.chartRef} width="100%" height="100%" />;
  }
}

export default withTranslation()(AbnormalChartSVG);
