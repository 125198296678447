import React, { Component, Fragment } from "react";
import { withTranslation } from "react-i18next";
import { Badge, Checkbox, Popover } from "antd";
import "./style.scss";

class AnalysisLegend extends Component {
  /**
   * handleShowHidePhase
   *
   * update the Legend filter for showing chart data
   *
   */
  handleShowHideLegend = (legend) => {
    const { hiddenLegend, updateHiddenLegend } = this.props;
    let newHiddenLegend = [...hiddenLegend];

    if (newHiddenLegend?.includes(legend)) {
      newHiddenLegend = newHiddenLegend.filter((item) => item != legend);
    } else {
      newHiddenLegend.push(legend);
    }
    return updateHiddenLegend(newHiddenLegend);
  };
  getLegendContent = (legend) => {
    const { hiddenLegend } = this.props;

    return legend?.disabled ? (
      <Badge
        className="border-square mx-8"
        color={legend.color}
        text={legend.label}
      />
    ) : (
      <Checkbox
        checked={!hiddenLegend.includes(legend?.key)}
        onChange={() => this.handleShowHideLegend(legend?.key)}
        style={{ "--checkbox-chart-color": legend?.color }}>
        {legend?.label}
      </Checkbox>
    );
  };
  render() {
    const { t, totalLegend } = this.props;

    return (
      <div className="wrap-analysis-legend">
        <Popover content={<div>{t("analysis.legend_not_available_data")}</div>}>
          <div className="wrap-gap-data">
            <div className="gap" />
            <div className="gap-text">{t("analysis.gap-data")}</div>
          </div>
        </Popover>
        {Array.isArray(totalLegend) &&
          totalLegend.map((legend) =>
            legend.hidden ? (
              <Fragment key={legend.key}></Fragment>
            ) : legend?.legendTooltip ? (
              <Popover
                key={legend?.key}
                content={<div>{t(legend?.legendTooltip)}</div>}>
                {this.getLegendContent(legend)}
              </Popover>
            ) : (
              <Fragment key={legend.key}>
                {this.getLegendContent(legend)}
              </Fragment>
            )
          )}
      </div>
    );
  }
}

export default withTranslation()(AnalysisLegend);
