import BaseApi from "../BaseApi";
import Api from "../Api";
/**
 * Service for intersections
 *
 * @class
 */
class TopologyConfigService extends BaseApi {
  constructor(parentStore) {
    super("intersection-configs/topologies", parentStore);
    this.dataKey = "int_configs";
  }

  getTopologyDetail = (queryObject, cb, fb) => {
    const api = new Api(
      "intersection-configs/topologies/details",
      this.parentStore
    );
    api.getRequest(
      queryObject,
      (response) => {
        cb && cb(response.data);
      },
      () => {
        fb && fb();
      }
    );
  };
}

export default TopologyConfigService;
