import ChartDetail from "components/ChartDetail";
import * as d3 from "d3";
import { ANALYSIS_LEGEND, CUSTOM_ANALYSIS_KEY } from "utils/constants";

const LABEL_TEXT = 21;


class CustomAnalysisChart extends ChartDetail {
  constructor(props) {
    super(props);
    this.state = {
      eventList: [],
    };
    this.value_field = "date_time";
    this.data_field = "allDataChart";
    this.CHART_AXIS_SETTING = [
      {
        position: "left",
        key: "event_code",
        label: "Custom Analysis",
        data: this.props.data.dataChartArr,
        tick: true,
      },
    ];

    this.TooltipStyle.width = 260;
  }
  renderHightLinePoint = (targetObject, x, y) => {
    const tootTipHeight =
      (targetObject.length + 2) * this.TooltipStyle.defaultChartHeight; //2 is space top/bottom
    let chartObject = d3.select(this.chartRef?.current);
    if (this.hightLineObject) {
      this.hightLineObject?.remove();
    }

    if (chartObject) {
      this.hightLineObject = chartObject
        .append("g")
        .attr("class", "hightline-group");
      const xTooltipPosition =
        x < this.fullWidth / 2
          ? this.ChartStyle.marginLeftRight + x + this.TooltipStyle.marginLeft
          : this.ChartStyle.marginLeftRight +
          x -
          this.TooltipStyle.marginLeft -
          this.TooltipStyle.width;
      const yTooltipPosition =
        y < this.fullHeight / 2
          ? y + tootTipHeight / 2 + this.TooltipStyle.marginTop
          : y - tootTipHeight + this.TooltipStyle.marginTop;
      this.hightLineObject
        .append("rect")
        .attr("width", this.TooltipStyle.width) //hard code the width of box
        .attr("height", tootTipHeight)
        .attr("class", "tooltip-box")
        .attr("stroke", "var(--text)")
        .attr("fill", "var(--background)")
        .attr(
          "transform",
          "translate(" + xTooltipPosition + "," + yTooltipPosition + ")"
        );
      //render hightline point

      // let labelHeight = yTooltipPosition;
      let labelIndex = 1.5;
      //add default text of time
      this.hightLineObject
        .append("text")
        .text(
          "Time: " +
          new Date(targetObject[0]?.data?.time)?.toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
            hour12: false,
          })
        ) //@TODO:hard code here
        .attr("text-anchor", "middle")
        .attr("class", "tooltip-info-text")
        .attr(
          "transform",
          "translate(" +
          (xTooltipPosition + this.TooltipStyle.width / 2) +
          "," +
          (yTooltipPosition +
            this.TooltipStyle.defaultChartHeight * labelIndex) +
          ")"
        )
        .attr("fill", "var(--text)");
      labelIndex += 1;
      targetObject.forEach((item) => {
        //find the target point setting
        const setting = this.chartLegend.find((e) => e.key === item.key);
        //find y posistion of point
        //get point value
        const pointValue = item.data.value;
        const paramValue = item.data.param
        //get point position
        const yPosistion = this.newYObject[setting.yAxis](pointValue);
        this.hightLineObject
          .append("circle")
          .attr("r", this.HIGHT_LINE_POINT)
          .attr("fill", setting.color)
          .attr("class", "hight-line-point")
          .attr(
            "transform",
            "translate(" +
            (this.ChartStyle.marginLeftRight + x) +
            "," +
            (yPosistion + this.ChartStyle.marginTopBottom) +
            ")"
          )
          .on("mouseout", () => {
            this.handleMouseOut();
          });

        this.hightLineObject
          .append("text")
          .text(
            this.renderTooltipText(
              setting.key,
              setting.unit,
              setting.toolTip(pointValue), setting.toolTip(paramValue)
            ),
          ) //the way we show tooltip defined on ANALYSIS_PLAN_INFO in constant file
          .attr("text-anchor", "middle")
          .attr("class", "tooltip-info-text")
          .attr(
            "transform",
            "translate(" +
            (xTooltipPosition + this.TooltipStyle.width / 2) +
            "," +
            (yTooltipPosition +
              this.TooltipStyle.defaultChartHeight * labelIndex) +
            ")"
          )
          .attr("fill", setting.color);
        labelIndex += 1;
      });

      //render hight-line infor box
    }
  };
  renderTooltipText = (key, unit, value, param) => {
    const convertValue = this.state.eventList.find(item => item.event_code == value);
    return `${convertValue?.event_label} - Param: ${param}`
  };
  renderYaxisLineForChart = () => {
    const {listGroupEventFilter} = this.props;
    const isNotOnlyDetector = listGroupEventFilter.find(item => item !== CUSTOM_ANALYSIS_KEY.DETECTOR);
    const isNotOnlyCoordination = listGroupEventFilter.find(item => item !== CUSTOM_ANALYSIS_KEY.COORDINATION);
    const isNotOnlyCabinet = listGroupEventFilter.find(item => item !== CUSTOM_ANALYSIS_KEY.CABINET);
    let yAxisObjects = this.CHART_AXIS_SETTING;
    if (Array.isArray(yAxisObjects)) {
      yAxisObjects.forEach((item) => {
        //find the max value of target
        const maxValue = this.findMaxValueInObjectArray(item.data, item.key);
        const tickSize = maxValue * this.YAXIS_TICK_SPACE;
        const renderYAxis = () => {
          if (!isNotOnlyDetector) {
            return [90 + tickSize, 70]
          } else if (!isNotOnlyCoordination) {
            return [150 + tickSize, 120]
          } else if (!isNotOnlyCabinet) {
            return [180 + tickSize, 160]
          } else {
            return [maxValue + tickSize, 0]
          }
        }
        let yScaleObject = d3
          .scaleLinear()
          .domain(renderYAxis()) //add extra space on top to make sure out data not reach the top
          .range([0, this.fullHeight]);
        let isLeftMode = item.position === "left";
        let yaxisObject = null;
        if (isLeftMode) {
          yaxisObject = d3.axisLeft(yScaleObject);
        } else {
          yaxisObject = d3.axisRight(yScaleObject);
        }

        if (item.tick) {
          const yAxisTicks = yScaleObject
            .ticks()
            .filter((tick) => Number.isInteger(tick));
          yaxisObject = yaxisObject
            .tickSize(-this.fullWidth)
            .tickValues(yAxisTicks)
            .tickFormat(item.tickFormat);
          // yaxisObject.call(yaxisObject.tickValues(yAxisTicks));
        }
        // yaxisObject;
        this.yScaleObject[item.position] = yScaleObject;
        this.yAxis[item.position] = this.chartObject
          .append("g")
          .attr("clip-path", "url(#clip-plan)")
          .attr(
            "transform",
            "translate(" +
            (this.ChartStyle.marginLeftRight +
              (isLeftMode ? 0 : this.fullWidth)) +
            "," +
            this.ChartStyle.marginTopBottom +
            ")"
          )
          .attr("class", "y-axis")
          .call(yaxisObject);
        //NOTE: rule of x and y change when rotate what why we have complicated x, y calculation
        this.chartObject
          .append("text")
          .attr("transform", isLeftMode ? "rotate(-90)" : "rotate(90)")
          .attr("x", isLeftMode ? -(this.fullHeight / 2) : this.fullHeight / 2)
          .attr(
            "y",
            (isLeftMode
              ? 0
              : -(this.fullWidth + this.ChartStyle.marginLeftRight * 2)) +
            LABEL_TEXT
          )
          .attr("class", "y-axis-label")
          .attr("height", (d) => { return d * 10 })
          .style("fill", "var(--text)")
          .style("text-anchor", "middle")
          .style("font-size", "14px")
          // hard code
          .text(
            "Event code"
          );
      });
    }

    this.newYObject = this.yScaleObject;
  };
  getDataObject = (chartData) => {
    let result = [];
    chartData?.forEach((e) => {
      result.push({
        time: e.date_time,
        value: e.event_code,
        param: e.parameter
      });
    });
    return result;
  };
  tranformChartDataToFronEndDataFormat = (chartData) => {
    let dataChart = [];
    for (let i = 0; i < this.chartLegend.length; i++) {
      let obj1 = this.chartLegend[i];
      let key = obj1.key;
      for (let j = 0; j < chartData.length; j++) {
        let obj2 = chartData[j];
        if (obj2.hasOwnProperty(key)) {
          let newObj = {
            ...obj1,
            data: this.getDataObject(obj2[key]),
            x: this.xScaleObject,
            y: this.yScaleObject[obj1.yAxis],
          };
          // this.availableLineData.push(newObj);
          dataChart.push(newObj)
        }
      }
      this.availableLineData = dataChart
    }
    return this.availableLineData
  };
  componentDidUpdate() {
    const { from_time, to_time, data, isPrintMode, gapdata } =
      this.props;

    const fromTime = new Date(from_time)?.getTime();
    const toTime = new Date(to_time)?.getTime();
    this.chartObject.html('');
    const chartData = data[this.data_field];
    this.planData = data?.allDataChart;

    this.renderXaxisLineForChart(fromTime, toTime, this.planData);
    this.renderChartBackgroundColor(this.planData);
    /// create y scale object
    this.renderYaxisLineForChart();

    //generate chart data

    //convert chartData to front-end format
    //note have to run behin renderXaxisLineForChart for xScale Object
    this.tranformChartDataToFronEndDataFormat(chartData, this.planData)

    //note have to run this function first tranformChartDataToFronEndDataFormat
    // this.renderZoomHandle();
    //generate chart crosshair
    if (!isPrintMode) {
      this.renderChartCrossHair(chartData);
    }
    this.renderChartData();
    /// render gap data
    if (gapdata) {
      this.renderGapDataForChart(gapdata);
    } //render zoom handler
  }

  componentDidMount() {
    const { from_time, to_time, data, isPrintMode, chartType, gapdata, listAllEvent } =
    this.props;
    let eventList = [];
    Object.values(listAllEvent).forEach(item => {
      eventList.push(...item.event_list)
    });
    this.setState({ eventList });

    if (!this.data_field && !this.value_field) {
      return;
    }
    this.chartLegend = ANALYSIS_LEGEND[chartType];

    //general Info
    const fromTime = new Date(from_time)?.getTime();
    const toTime = new Date(to_time)?.getTime();
    const chartData = data[this.data_field];
    this.planData = data?.allDataChart;
    //Draw Chart Plan
    //draw xAxisObject
    //get plan object from dom
    this.chartObject = d3.select(this.chartRef?.current);
    if (
      Array.isArray(this.planData) &&
      Array.isArray(chartData) &&
      this.chartObject
    ) {
      //calculate the witdh of xAxist bar base on the scren size
      let parentWidth = parseFloat(
        window.getComputedStyle(this.chartObject.node())?.width
      );
      this.fullWidth = parentWidth * 0.9;
      this.ChartStyle.marginLeftRight = parentWidth * 0.05;
      //calculate the height of xAxist bar base on the scren size
      let parentHeight = parseFloat(
        window.getComputedStyle(this.chartObject.node())?.height
      );
      this.ChartStyle.marginTopBottom = parentHeight * 0.05;
      this.fullHeight =
        parentHeight * 0.86 -
        this.ChartStyle.extraFocusHeight -
        this.ChartStyle.marginTopBottom;

      this.ChartStyle.extraFocusPosition = this.fullHeight;
      /// create y scale Object
      //draw background color base on plan data

      this.renderXaxisLineForChart(fromTime, toTime, this.planData);
      this.renderChartBackgroundColor(this.planData);
      /// create y scale object
      this.renderYaxisLineForChart();

      //generate chart data

      //convert chartData to front-end format
      //note have to run behin renderXaxisLineForChart for xScale Object
      this.tranformChartDataToFronEndDataFormat(chartData, this.planData);

      //note have to run this function first tranformChartDataToFronEndDataFormat
      // this.renderZoomHandle();
      //generate chart crosshair
      if (!isPrintMode) {
        this.renderChartCrossHair(chartData);
      }
      this.renderChartData();
      /// render gap data
      if (gapdata) {
        this.renderGapDataForChart(gapdata);
      } //render zoom handler
    }
  }


  /**

   * render

   *

   * @return  {Component}

   */

  render() {
    return super.render();
  }
}

export default CustomAnalysisChart;
