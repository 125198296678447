import ChartDetail from "components/ChartDetail";

class PreemptTSPDetail extends ChartDetail {
  constructor(props) {
    super(props);
    this.value_field = "time";
    this.data_field = "tsp_check_in";
    this.CHART_AXIS_SETTING = [
      {
        position: "left",
        key: "number",
        label: "Seconds (s)",
        tick: true,
        data: [
          ...this.props.data.tsp_check_in,
          ...this.props.data.adj_early_green,
          ...this.props.data.adj_extend_green,
          ...this.props.data.tsp_check_out,
        ],
      },
    ];
  }
  /**
   * findMaxValueInObjectArray
   *
   * @param {Array} array  data to find the max value
   * @param {String} key key of compare field
   * @param {Object} defaultValue defaultValue
   */
  findMaxValueInObjectArray = (array) => {
    let maxValue = 0;
    if (Array.isArray(array)) {
      array.forEach((item) => {
        maxValue = maxValue > item.number ? maxValue : item.number;
      });
    }
    return maxValue;
  };

  getDataObject = (_chartData, legend) => {
    const { from_time, to_time, data } = this.props;
    const allData = data[legend.key];
    let lineData = [];
    allData?.forEach((item) => {
      if (
        from_time.getTime() <= new Date(item[this.value_field]).getTime() &&
        new Date(item[this.value_field]).getTime() <= to_time.getTime()
      )
        lineData.push({
          time: item.time,
          value: item.number,
        });
    });

    return lineData;
  };

  /**
   * render
   *
   * @return  {Component}
   */
  render() {
    return super.render();
  }
}

export default PreemptTSPDetail;
