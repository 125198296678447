import BaseApi from '../../BaseApi';
/**
 * Service for user management.
 * 
 * @class
 */
class AlarmAvailableSubscriberService extends BaseApi {

  constructor(parentStore) {
    super("alarm/rules/subscribers/available", parentStore);
    this.moduleName = "alarm/rules/subscribers/available";
    this.dataKey = "subscribers";
    this.keyField = 'id';
  }

}

export default AlarmAvailableSubscriberService;