import BaseApi from "./BaseApi";
import Api from "./Api";

class AuthService extends BaseApi {
  constructor(parentStore) {
    super("auth", parentStore);
  }
  /**
   * login
   * this function login user infor to server
   * @param   {object} loginInfo  login infomation
   * @return  {Promise} -  return promise function for store
   */
  login = (loginInfo) => {
    return new Promise((resolve, reject) => {
      const api = new Api(this.moduleName + "/login", this.parentStore);
      api.postRequest(
        loginInfo,
        (response) => {
          resolve(response?.data);
        },
        (response) => {
          reject(response);
        }
      );
    });
  };

  /**
   * logout
   * this function logout user
   * @return  {Promise} -  return promise function for store
   */
  logout = () => {
    return new Promise((resolve, reject) => {
      const api = new Api(this.moduleName + "/logout", this.parentStore);
      api.postRequest(
        {},
        (response) => {
          resolve(response?.data);
        },
        (response) => {
          reject(response);
        }
      );
    });
  };

  /**
   * refreshToken
   * this function refresh expried token
   * @return  {Promise} -  return promise function for store
   */
  refreshToken = () => {
    return new Promise((resolve, reject) => {
      const api = new Api(this.moduleName + "/token", this.parentStore);
      api.postRequest(
        {},
        (response) => {
          resolve(response?.data);
        },
        (response) => {
          reject(response);
        }
      );
    });
  };

  /**
   * recoverPassword
   * this function send reset link to user email
   * @param   {String} email  user email token
   * @return  {Promise} -  return promise function for store
   */
  recoverPassword = (email) => {
    return new Promise((resolve, reject) => {
      const api = new Api("account/reset-password/init", this.parentStore);
      api.putRequest(
        email,
        (response) => {
          resolve(response?.data);
        },
        (response) => {
          reject(response);
        }
      );
    });
  };

  /**
   * recoverPassword
   * this function send reset link to user email
   * @param   {String} email  user email token
   * @return  {Promise} -  return promise function for store
   */
  resetPassword = (passwordObj) => {
    return new Promise((resolve, reject) => {
      const api = new Api("account/reset-password/finish", this.parentStore);
      api.putRequest(
        passwordObj,
        (response) => {
          resolve(response?.data);
        },
        (response) => {
          reject(response);
        }
      );
    });
  };

  /**
   * check
   * this function check current token is vaild or not
   * @return  {Promise} -  return promise function for store
   */
  check = () => {
    return new Promise((resolve, reject) => {
      const api = new Api(this.moduleName + "/check", this.parentStore);
      api.getRequest(
        {},
        (response) => {
          if (response?.data?.login_succeeded) {
            resolve(response?.data.auth_info);
          } else {
            reject(response);
          }
        },
        (response) => {
          reject(response);
        }
      );
    });
  };

  /**
   * verify
   * this function verify otp code of current user
   * @param   {Object} payload  eg:"token_code":"90018877"
   * @return  {Promise} -  return promise function for store
   */
  verify = (payload) => {
    return new Promise((resolve, reject) => {
      const api = new Api(this.moduleName + "/otp/verify", this.parentStore);
      api.postRequest(
        payload,
        (response) => {
          resolve(response?.data);
        },
        (response) => {
          reject(response);
        }
      );
    });
  };

  /**
   * resentOTP
   * this function resent otp code into current user email
   * @return  {Promise} -  return promise function for store
   */
  resentOTP = () => {
    return new Promise((resolve, reject) => {
      const api = new Api(this.moduleName + "/otp", this.parentStore);
      api.putRequest(
        {},
        (response) => {
          resolve(response?.data);
        },
        (response) => {
          reject(response);
        }
      );
    });
  };
}

export default AuthService;
