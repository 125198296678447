import Api from "./Api";
import BaseApi from "./BaseApi";
import queryString from "query-string";

/**
 * Service for intersections
 *
 * @class
 */
class IntersectionService extends BaseApi {
  constructor(parentStore) {
    super("intersections", parentStore);
  }
  //@TODO: NGOCPT remove this function . call from base
  /**
   * getRuleCategories
   * this function return all categories type
   * @param   {object} params  request params
   * @param   {function} cb  callback function
   * @param   {function} fb  falseback function
   * @return  {null} -  call the callback function
   */
  getAllIntersections = (params, cb, fb) => {
    const api = new Api("intersections/all", this.parentStore);
    api.getRequest(
      params,
      (response) => {
        cb && cb(response);
      },
      () => {
        fb && fb();
      }
    );
  };
  //@TODO: NGOCPT please explain this function
  /**
   * getRuleCategories
   * this function return all categories type
   * @param   {function} cb  callback function
   * @param   {function} fb  falseback function
   * @return  {null} -  call the callback function
   */
  getAllIntersectionsByAgency = (filter, cb, fb) => {
    let url = "intersections/all";
    if (filter) url = "intersections/all?" + queryString.stringify(filter);
    const api = new Api(url, this.parentStore);
    api.getRequest(
      {},
      (response) => {
        cb && cb(response);
      },
      () => {
        fb && fb();
      }
    );
  };

  /**
   * getAllIntersectionsWithlocation
   * @param   {object} searchParams  callback function
   * @param   {function} fb  falseback function
   * @return  {null} -  call the callback function
   */
  getAllIntersectionsWithlocation = (searchParams) => {
    Object.keys(searchParams).forEach(
      (key) =>
        (searchParams[key] == "" || searchParams[key] == "null") &&
        delete searchParams[key]
    );
    return new Promise((resolve, reject) => {
      const api = new Api(this.moduleName + "/nearbysearch", this.parentStore);
      api.getRequest(
        searchParams,
        (response) => {
          resolve(response?.data);
        },
        (response) => {
          reject(response);
        }
      );
    });
  };

  /**
   * downloadIntersectionRawData
   * @param   {object} downloadInfo  downloadInfo
   * @return  {Promise} -  return Promise
   */
  downloadIntersectionRawData = (downloadInfo) => {
    return new Promise((resolve, reject) => {
      const api = new Api("perflog/export", this.parentStore);
      api.postRequest(
        downloadInfo,
        (response) => {
          resolve(response?.data);
        },
        (response) => {
          reject(response);
        }
      );
    });
  };
  /**
   * getAllPerlogEvent
   * @return  {Promise} -  return Promise
   */
  getAllPerlogEvent = () => {
    return new Promise((resolve, reject) => {
      const api = new Api("perflog/event-types", this.parentStore);
      api.getRequest(
        null,
        (response) => {
          resolve(response?.data);
        },
        (response) => {
          reject(response);
        }
      );
    });
  };
  /**
   * downloadIntersectionWithPassword
   * @param   {object} downloadResource  parans of URL download got from email
   * @param   {object} downloadInfo  if you need to custom request handle
   * @return  {Promise} -  return Promise
   */

  downloadIntersectionWithPassword = (params) => {
    return new Promise((resolve, reject) => {
      const api = new Api("perflog/export-entry", this.parentStore);
      api.getRequest(
        params,
        (response) => {
          resolve(response?.data);
        },
        (response) => {
          reject(response);
        }
      );
    });
  };
}

export default IntersectionService;
