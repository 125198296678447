import React from "react";
import { Form, Input, Select } from "antd";
import { observer, inject } from "mobx-react";
import { withTranslation } from "react-i18next";
import BaseDetailPopupComponent from "../../../components/BaseDetailPopupComponent";
import { DEFAULT_STATUS_LIST, FORM_ITEM_LAYOUT } from "utils/constants";

const { Option } = Select;

/**
 * Component for showing create agency form
 *
 * @component
 */
@inject("rootStore")
@observer
class AgencyDetail extends BaseDetailPopupComponent {
  /**
   * contructor of component
   * @param   {object} props  props of component
   */
  constructor(props) {
    super(props.rootStore?.agencyStore, props);
    this.formFields = [
      "name",
      "organization",
      "address",
      "phone",
      "email",
      "status",
    ];
  }

  /**
   * renderReadOnlyForm
   * For view detail page, just label only
   *
   */
  renderReadOnlyForm(formData) {
    const { t } = this.props;
    return (
      <div>
        <Form.Item label={t("agency.name")} {...FORM_ITEM_LAYOUT}>
          {formData?.name}
        </Form.Item>
        <Form.Item label={t("agency.organization")} {...FORM_ITEM_LAYOUT}>
          {formData?.organization}
        </Form.Item>
        <Form.Item label={t("agency.address")} {...FORM_ITEM_LAYOUT}>
          {formData?.address}
        </Form.Item>
        <Form.Item label={t("agency.phone")} {...FORM_ITEM_LAYOUT}>
          {formData?.phone}
        </Form.Item>
        <Form.Item label={t("agency.email")} {...FORM_ITEM_LAYOUT}>
          {formData?.email}
        </Form.Item>
        <Form.Item label={t("agency.status")} {...FORM_ITEM_LAYOUT}>
          {formData?.status}
        </Form.Item>
      </div>
    );
  }

  /**
   * renderCreateOrEditForm
   * For view edit page with validation and submit form
   *
   */
  renderCreateOrEditForm(formData, getFieldDecorator) {
    const { t } = this.props;
    return (
      <div>
        <Form.Item label={t("agency.name")} {...FORM_ITEM_LAYOUT}>
          {getFieldDecorator("name", {
            rules: [{ required: true, message: t("agency.name_required") }],
            initialValue: formData?.name,
          })(
            <Input maxLength={255} placeholder={t("agency.name_placeholder")} />
          )}
        </Form.Item>
        <Form.Item label={t("agency.organization")} {...FORM_ITEM_LAYOUT}>
          {getFieldDecorator("organization", {
            rules: null,
            initialValue: formData?.organization,
          })(
            <Input
              maxLength={255}
              placeholder={t("agency.organization_placeholder")}
            />
          )}
        </Form.Item>
        <Form.Item label={t("agency.address")} {...FORM_ITEM_LAYOUT}>
          {getFieldDecorator("address", {
            rules: null,
            initialValue: formData?.address,
          })(
            <Input
              maxLength={255}
              placeholder={t("agency.address_placeholder")}
            />
          )}
        </Form.Item>
        <Form.Item label={t("agency.phone")} {...FORM_ITEM_LAYOUT}>
          {getFieldDecorator("phone", {
            rules: null,
            initialValue: formData?.phone,
          })(
            <Input
              maxLength={255}
              placeholder={t("agency.phone_placeholder")}
            />
          )}
        </Form.Item>
        <Form.Item label={t("agency.email")} {...FORM_ITEM_LAYOUT}>
          {getFieldDecorator("email", {
            rules: [
              {
                type: "email",
                message: t("agency.email_format"),
              },
              { required: false, message: t("agency.email_required") },
            ],
            initialValue: formData.email,
          })(
            <Input
              maxLength={255}
              placeholder={t("agency.email_placeholder")}
            />
          )}
        </Form.Item>
        <Form.Item label={t("agency.status")} {...FORM_ITEM_LAYOUT}>
          {getFieldDecorator("status", {
            rules: [{ required: true }],
            initialValue: formData?.status,
          })(
            <Select>
              {DEFAULT_STATUS_LIST?.map((e) => (
                <Option key={e.key} value={e.key}>
                  {t(e.value)}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>
      </div>
    );
  }

  /**
   * renderDetailData
   * @param   {object} formData
   * @param   {object} getFieldDecorator
   * this function return the agency detail box
   * @return  {Component}
   */
  renderDetailData(formData, getFieldDecorator) {
    const { editable } = this.props;
    if (editable) {
      return this.renderCreateOrEditForm(formData, getFieldDecorator);
    } else {
      return this.renderReadOnlyForm(formData);
    }
  }
}
const AgencyDetailForm = Form.create({ name: "agency-detail" })(AgencyDetail);

export default withTranslation()(AgencyDetailForm);
