import { Form, Table } from "antd";
import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import "./style.scss";
/**
 * Component for base managment module.
 * it's comon behavior of all management componenent
 *
 * @component
 */
@inject("rootStore")
@observer
class AlarmSummaryModal extends Component {
  /**
   * return column set of table
   */
  getTableColumns = () => {
    const { t } = this.props;
    return [
      {
        title: t("alarm_records.no"),
        dataIndex: "rule_id",
        render: (value, row, index) => {
          return index + 1;
        },
        key: "no",
        ellipsis: true,
      },
      {
        title: t("alarm_records.name"),
        dataIndex: "rule_name",
        key: "rule_name",
        ellipsis: true,
      },
      {
        title: t("alarm_records.description"),
        dataIndex: "description",
        key: "description",
        ellipsis: true,
      },
      {
        title: t("alarm_records.intersection_name"),
        dataIndex: "intersection_name",
        key: "intersection_name",
        ellipsis: true,
      },
      {
        title: t("alarm_records.trigged_alarm"),
        dataIndex: "number_triggered_alarm",
        key: "number_triggered_alarm",
        ellipsis: true,
      },
    ];
  };
  /**
   * render
   *
   * @return  {Component}
   */
  render() {
    let formItemLayout = {
      labelCol: { span: 24 },
      wrapperCol: { span: 24 },
    };
    const {
      t,
      rootStore: { myStore, alarmRecordStore },
    } = this.props;

    let categoryName = alarmRecordStore?.getCategoryName(
      alarmRecordStore?.filterData?.category_id
    );
    let analysisName = alarmRecordStore?.getAnalysisName(
      alarmRecordStore?.filterData?.analysis_id
    );
    return (
      <div className="alarm-records-summary-form">
        <Form layout={"inline"}>
          <Form.Item
            className={"alarm-records-summary-form-item"}
            label={t("alarm_records.reporter")}
            {...formItemLayout}
          >
            {myStore?.currentProfile?.name}
          </Form.Item>
          <Form.Item
            className={"alarm-records-summary-form-item"}
            label={t("alarm_records.from_date")}
            {...formItemLayout}
          >
            {alarmRecordStore?.filterData.from_time}
          </Form.Item>
          <Form.Item
            className={"alarm-records-summary-form-item"}
            label={t("alarm_records.to_date")}
            {...formItemLayout}
          >
            {alarmRecordStore?.filterData?.to_time}
          </Form.Item>

          <Form.Item
            className={"alarm-records-summary-form-item"}
            label={t("alarm_records.category")}
            {...formItemLayout}
          >
            {categoryName ? categoryName : t("alarm_records.all_categories")}
          </Form.Item>
          <Form.Item
            className={"alarm-records-summary-form-item"}
            label={t("alarm_records.analysis")}
            {...formItemLayout}
          >
            {analysisName ? analysisName : t("alarm_records.all_analysis_type")}
          </Form.Item>
          <Form.Item
            className={"alarm-records-summary-form-item"}
            label={t("alarm_records.agency")}
            {...formItemLayout}
          >
            {alarmRecordStore?.getAgencyName(
              alarmRecordStore?.filterData?.agency_id
            )}
          </Form.Item>
          <Form.Item
            className={"alarm-records-summary-form-item"}
            label={t("alarm_records.intersection")}
            {...formItemLayout}
          >
            {alarmRecordStore?.filterData?.intersection}
          </Form.Item>
          <Form.Item
            className={"alarm-records-summary-form-item"}
            label={t("alarm_records.search")}
            {...formItemLayout}
          >
            {alarmRecordStore?.filterData?.text}
          </Form.Item>
        </Form>

        <div className="wrap-table">
          <Table
            rowClassName={(record, index) => {
              if (index % 2 == 0) return "odd-row";
              else return "even-row";
            }}
            className="alarm-record-summary spm-table"
            columns={this.getTableColumns()}
            rowKey={"rule_id"}
            dataSource={alarmRecordStore.allSummaryRecords}
            loading={this.currentStore?.loading}
            pagination={false}
          />
        </div>
      </div>
    );
  }
}

export default withTranslation()(AlarmSummaryModal);
