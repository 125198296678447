import { Button, Col, Collapse, Row, Select } from "antd";
import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import { LANGUAGE_SETTING, USER_SIDE_PANE_TABS } from "utils/constants";
import ChangePasswordTabForm from "./ChangePasswordProfileComp";
import "./style.scss";
import UserAgencyTabForm from "./UserAgencyForm";
import UserDataTabForm from "./UserDataForm";
import UserKeyTabForm from "./UserKeyArea";
import AlarmFrequencyTabForm from "./AlarmFrequency";

const Option = Select.Option;
const Panel = Collapse.Panel;
/**
 * Component for user side drawer.
 *
 * @component
 */
@inject("rootStore")
@observer
class UserSidePane extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeKey: [
        this.props.defaultTab
          ? this.props.defaultTab
          : USER_SIDE_PANE_TABS.UserDataTab,
      ],
    };
  }

  //form childs
  changePasswordForm = null;
  userDataForm = null;
  userAgencyForm = null;
  alarmSummarySettingsForm = null;
  alarmSummarySettingsForm = null;

  /**
   * componentDidMount
   * binding this component to parent childRef
   * in this case when leave the side panel check all the form is dirty or not
   * @return  {null} -
   */
  componentDidMount() {
    const { childRef } = this.props;
    childRef(this);
  }

  /**
   * checkFormIsChanged
   * check changed in every tab
   * and if tab changed , active that tab
   * @return  {boolean} -
   */
  checkFormIsChanged = () => {
    if (this.userDataForm?.isFormChanged()) {
      this.setState({ activeKey: USER_SIDE_PANE_TABS.UserDataTab });
      return true;
    }
    if (this.userAgencyForm?.isFormChanged()) {
      this.setState({ activeKey: USER_SIDE_PANE_TABS.AgencyTab });
      return true;
    }
    if (this.changePasswordForm?.isFormChanged()) {
      this.setState({ activeKey: USER_SIDE_PANE_TABS.PasswordTab });
      return true;
    }
    if (this.alarmSummarySettingsForm?.isFormChanged()) {
      this.setState({ activeKey: USER_SIDE_PANE_TABS.AlarmFrequencyTab });
      return true;
    }
    return false;
  };

  /**
   * submitChangePassword
   * stop default event of action
   * call a child form to handleSubmit
   * @return  {null} -
   */
  submitChangePassword = (event) => {
    event?.stopPropagation();
    this.changePasswordForm.handleSubmit();
  };

  /**
   * submitChangeUserData
   * stop default event of action
   * call a child form to handleSubmit
   * @return  {null} -
   */
  submitChangeUserData = (event) => {
    event?.stopPropagation();
    this.userDataForm.handleSubmit();
  };

  /**
   * submitChangeUserDefaultAgency
   * stop default event of action
   * call a child form to handleSubmit
   * @return  {null} -
   */
  submitChangeUserDefaultAgency = (event) => {
    event?.stopPropagation();
    this.userAgencyForm.handleSubmit();
  };

  /**
   * submitChangeAlarmSettings
   * stop default event of action
   * call a child form to handleSubmit
   * @return  {null} -
   */
  submitChangeAlarmSettings = (event) => {
    event?.stopPropagation();
    this.alarmSummarySettingsForm.handleSubmit();
  }

  /**
   * onCollapseChange
   * update current active tab
   * @return  {null} -
   */
  onCollapseChange = (key) => {
    this.setState({ activeKey: key });
  };
  /**
   * render
   *
   * @return  {Component}
   */
  render() {
    const { activeKey } = this.state;
    const {
      rootStore: { commonStore, myStore },
      t,
    } = this.props;
    let user = myStore?.getCurrentProfile();
    return (
      <div className="wrap-user-side-pane">
        <Collapse
          bordered={false}
          activeKey={activeKey}
          onChange={this.onCollapseChange}
          expandIconPosition="left"
          accordion
        >
          <Panel
            header={t("profile.userData")}
            key={USER_SIDE_PANE_TABS.UserDataTab}
            extra={
              activeKey?.includes(USER_SIDE_PANE_TABS.UserDataTab) && (
                <Button
                  loading={myStore?.loading}
                  icon="save"
                  type="link"
                  onClick={(e) => this.submitChangeUserData(e)}
                >
                  {t("profile.save_profile")}
                </Button>
              )
            }
          >
            <UserDataTabForm childRef={(ref) => (this.userDataForm = ref)} />
          </Panel>
          {!commonStore.isSuperAdmin() && (
            <Panel
              header={t("profile.agency")}
              key={USER_SIDE_PANE_TABS.AgencyTab}
              extra={
                activeKey?.includes(USER_SIDE_PANE_TABS.AgencyTab) && (
                  <Button
                    loading={myStore?.loading}
                    icon="save"
                    type="link"
                    onClick={(e) => this.submitChangeUserDefaultAgency(e)}
                  >
                    {t("profile.save_profile")}
                  </Button>
                )
              }
            >
              <UserAgencyTabForm
                childRef={(ref) => (this.userAgencyForm = ref)}
              />
            </Panel>
          )}
          <Panel
            header={t("profile.password")}
            key={USER_SIDE_PANE_TABS.PasswordTab}
            extra={
              activeKey?.includes(USER_SIDE_PANE_TABS.PasswordTab) && (
                <Button
                  loading={myStore?.loading}
                  icon="save"
                  type="link"
                  onClick={(e) => this.submitChangePassword(e)}
                >
                  {t("profile.save_profile")}
                </Button>
              )
            }
          >
            <ChangePasswordTabForm
              childRef={(ref) => (this.changePasswordForm = ref)}
            />
          </Panel>
          <Panel
            header={t("profile.userKey")}
            key={USER_SIDE_PANE_TABS.UserKeyTab}
          >
            <UserKeyTabForm />
          </Panel>
          {!commonStore.isSuperAdmin() && (
            <Panel
              header={t("profile.alarm_summary_settings")}
              key={USER_SIDE_PANE_TABS.AlarmFrequencyTab}
              extra={
                activeKey?.includes(USER_SIDE_PANE_TABS.AlarmFrequencyTab) && (
                  <Button
                    loading={myStore?.loading}
                    icon="save"
                    type="link"
                    onClick={(e) => this.submitChangeAlarmSettings(e)}
                  >
                    {t("profile.save_profile")}
                  </Button>
                )
              }
            >
              <AlarmFrequencyTabForm 
                childRef={(ref) => (this.alarmSummarySettingsForm = ref)}
              />
            </Panel>
          )}
        </Collapse>
        <Row className="footer">
          <Col span={24}>
            <span className="language-select-label">
              {t("profile.language")}:
            </span>
            <Select
              className="language-select"
              size="small"
              labelInValue
              value={{
                key: user?.language
                  ? user?.language
                  : process.env.REACT_APP_DEFAULT_LANG,
              }}
              style={{ width: 120 }}
              onChange={myStore?.changeLanguage}
            >
              {LANGUAGE_SETTING?.map((e) => {
                return (
                  <Option key={e.value} value={e.value}>
                    {e.label}
                  </Option>
                );
              })}
            </Select>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withRouter(withTranslation()(UserSidePane));
