import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { withTranslation } from "react-i18next";
import { Form, Timeline, Avatar, Card, Tag } from "antd";
import helper from "utils/helper";
import { TIMEZONES } from "utils/timezone";
import { DATE_FORMAT } from "utils/constants/index";
import moment from "moment";

@inject("rootStore")
@observer
class AbnormalReportDetail extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const {
      t,
      rootStore: {
        analysisScheduleResultStore: {
          loading,
          reportData,
        },
      },
    } = this.props;

    return (
      <Card
        title={
          <div style={{ textAlign: "center", fontSize: "20px" }}>
            {t("analysis_schedule.performace_metric_report")}
          </div>
        }
        bordered={false}
        loading={loading}
        className="report-result-card"
      >
        {reportData?.created_at && (
          <Form className="report-detail-form">
            <Form.Item label={t("report_result.report_name")}>
              {reportData?.name}
            </Form.Item>
            <Form.Item label={t("report_result.description")}>
              {reportData?.description}
            </Form.Item>
            <Form.Item label={t("report_result.time_zone")}>
              {TIMEZONES.find((e) => e.value === reportData?.timezone)?.label}
            </Form.Item>
            <Form.Item label={t("report_result.target_date")}>
              <Tag.CheckableTag checked={true}>{reportData.chart_data.charts[0].target_date}</Tag.CheckableTag>
            </Form.Item>
            <Form.Item label={t("report_result.compare_previous", {days: `${reportData?.chart_data?.charts[0] ? reportData.chart_data.charts[0].period : 0}`})}>
              {reportData?.chart_data?.charts[0]?.data[0]?.raw_counts ? 
                (reportData.chart_data.charts[0].data[0].raw_counts?.map((day) => {
                  if (day.data !== reportData.chart_data.charts[0].target_date) {
                    return(
                      <Tag.CheckableTag
                        key={day.data}
                        checked={true}
                      >
                        {day.data}
                      </Tag.CheckableTag>
                    )
                  }
                }))
                :
                null
              }
            </Form.Item>
            <Form.Item label={t("report_result.time_range")}>
              <Timeline className="report-time-line">
                <Timeline.Item>
                  {" "}
                  {moment(
                    reportData?.metadata.from_time,
                    DATE_FORMAT.hour
                  ).format(DATE_FORMAT.hour)}
                </Timeline.Item>
                <Timeline.Item>
                  {" "}
                  {moment(
                    reportData?.metadata.to_time,
                    DATE_FORMAT.hour
                  ).format(DATE_FORMAT.hour)}
                </Timeline.Item>
              </Timeline>
            </Form.Item>

            <Form.Item label={t("report_result.created_at")}>
              {moment(reportData?.created_at).format(DATE_FORMAT.full_with_tz)}
            </Form.Item>
            <Form.Item label={t("report_result.owner")}>
              {reportData?.owner?.name}
            </Form.Item>
            <Form.Item label={t("report_result.agency")}>
              <div className="report-detail-agency">
                <div className="agency-item">
                  <Avatar style={{ backgroundColor: helper.getRandomColor() }}>
                    {helper
                      .getFirstsLetterFromWords(reportData?.agency_name)
                      ?.substring(0, 2)}
                  </Avatar>
                  <span className="agency-name">{reportData?.agency_name}</span>
                </div>
              </div>
            </Form.Item>
          </Form>
        )}
      </Card>
    );
  }
}

export default withTranslation()(AbnormalReportDetail);
