import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import Charts from "components/ChartItem";
import { Spin } from "antd";
import SiemenFont from "fonts/Siemens_Sans_Roman.ttf";
// import RingChart from "components/Charts/SplitMonitor/RingChart/index";
import SplitMonitorEvents from "components/Charts/SplitMonitor/SplitMonitorEvents";
import moment from "moment";

@inject("rootStore")
@observer
class PrintSplitMonitorCharts extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      numberCycles: null,
      to_time: null,
    }
  }

  processPrintDataSplitMonitor = (data) => {
    const cycleData = data[1].cycles;
    const numberPages = Math.ceil(cycleData.length/5);
    let printData = [];
    for (let i = 0; i < numberPages; i++) {
      let pageData = []
      for (let j = (i * 5 + 0); j < (i + 1)*5; j++) {
        if (j > cycleData.length-1) break;
        pageData.push({cycleData: cycleData[j], cycleIndex: j})
      }
      printData.push(pageData)
    }   

    this.setState({data: printData, numberCycles: cycleData.length, to_time: data[1].to_time})
  }

  calculateChartWidth = (cycle) => {
    const chartScale = (650 - 180 - 25)/120000
    const cycleDuration = moment(cycle.end_time) - moment(cycle.start_time);
    const width = 180 + 25 + cycleDuration * chartScale

    return width;
  }

  componentDidMount() {
    const {
      rootStore: {
        chartStore: { chartData },
      },
    } = this.props;

    this.processPrintDataSplitMonitor(chartData)
  }

  render() {
    return(
      <div className={"loading-screen"}>
        <Spin
          style={{ fontFamily: "siemenfont" }}
          size="large"
          className={"centralize"}
        >
          <div className="chart-data">
            <div
              key={"chart_" + 0}
              className="chart-item chart-item-print"
              style={{ height: "720px", width: "1280px" }}
            >
              <Charts
                style={{ fontFamily: SiemenFont }}
                index={0}
                isPrintMode={true}
              />
            </div>

            {this.state.data.map((page, index) => {
              return (
                <div
                  key={"split_monitor_actual_" + index}
                  className="chart-item chart-item-print"
                  style={{ height: "720px", width: "1280px", display: "flex", flexDirection: "column", alignItems: "center" }}
                >
                  <SplitMonitorEvents />
                  <div>
                    {/* {page.map((data) => {
                      if (data.cycleIndex !== this.state.numberCycles - 1) {
                        return <RingChart width={this.calculateChartWidth(data.cycleData)} isLastCycle={false} lastCycleEnds="" key={data.cycleIndex} type="actual" cycleNumber={data.cycleIndex+1} data={data.cycleData} />
                      } else {
                        return <RingChart width={this.calculateChartWidth(data.cycleData)} isLastCycle={true} lastCycleEnds={this.state.to_time} key={data.cycleIndex} type="actual" cycleNumber={data.cycleIndex+1} data={data.cycleData} />
                      }
                    })} */}
                  </div>
                </div>
              );
            })}
          </div>
        </Spin>
      </div>
    )
  }
}

export default PrintSplitMonitorCharts;