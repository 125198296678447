import { Form, Select } from "antd";
import { inject, observer } from "mobx-react";
import moment from "moment";
import React from "react";
import { withTranslation } from "react-i18next";
import { DATE_FORMAT } from "utils/constants";
import BaseDetailPopupComponent from "../../../../components/BaseDetailPopupComponent";
import Helper from "../../../../utils/helper";

const { Option } = Select;
/**
 * Component for showing details of the user.
 *
 * @component
 */
@inject("rootStore")
@observer
class AlarmsRecordsDetail extends BaseDetailPopupComponent {
  /**
   * contructor of component
   * @param   {object} props  props of component
   */
  constructor(props) {
    super(props.rootStore?.alarmRecordStore, props);
    this.formFields = ["user_ids"];
    this.moduleName = "alarm_records";
  }

  /**
   * componentDidMount
   *
   */
  componentDidMount = () => {
    const { childRef } = this.props;
    childRef && childRef(this);
    const { editable } = this.props;
    if (editable) {
      this.currentStore?.getAllForwardUser();
    }
  };

  /**
   * handleSubmit
   * this function submit value to update data
   * @return  {Component}
   */
  handleSubmit = (e) => {
    e.preventDefault();
    this.props?.form?.validateFields((err, values) => {
      if (!err) {
        this.currentStore
          ?.forwardMessages(values, this.currentStore?.itemData.id)
          .then(() => {
            this.currentStore?.closeCreateOrEditForm();
          })
          .catch((errors) => {
            errors?.map((error) => {
              let message = error?.message;
              let err = {};
              err[error?.field] = {
                value: values[error?.field],
                errors: [new Error(message)],
              };
              this.props?.form?.setFields(err);
            });
          });
      }
    });
  };

  /**
   * renderReadOnlyForm
   * For view detail page, just label only
   *
   */
  renderReadOnlyForm(alarm_record) {
    const {
      t,
      rootStore: { myStore },
    } = this.props;
    const currentLanguage = myStore?.currentProfile?.language;
    return (
      <div>
        <Form.Item label={t("alarm_records.id")}>{alarm_record?.id}</Form.Item>
        <Form.Item label={t("alarm_records.name")}>
          {Helper.getTranslateText(alarm_record?.name, currentLanguage)}
        </Form.Item>
        <Form.Item label={t("alarm_records.content")}>
          {Helper.getTranslateText(alarm_record?.content, currentLanguage)}
        </Form.Item>
        <Form.Item label={t("alarm_records.category_name")}>
          {alarm_record?.category_name}
        </Form.Item>
        <Form.Item label={t("alarm_records.analysis_name")}>
          {alarm_record?.analysis_name}
        </Form.Item>
        <Form.Item label={t("alarm_records.agency_name")}>
          {alarm_record?.agency_name}
        </Form.Item>
        <Form.Item label={t("alarm_records.intersection_name")}>
          {alarm_record?.intersection_name}
        </Form.Item>
        <Form.Item label={t("alarm_records.alarm_time")}>
          {alarm_record?.alarm_time &&
            moment(alarm_record?.alarm_time, DATE_FORMAT.backend_with_tz)
              .zone(alarm_record?.alarm_time?.split(" ")[1])
              .format(DATE_FORMAT.full_with_tz)}
        </Form.Item>
        <Form.Item label={t("alarm_records.rule_condition")}>
          {alarm_record?.rule_condition}
        </Form.Item>
      </div>
    );
  }

  /**
   * renderCreateOrEditForm
   * For view edit page with validation and submit form
   *
   */
  renderCreateOrEditForm(formData, getFieldDecorator) {
    const { t } = this.props;
    return (
      <div>
        <Form.Item label={t("alarm_records.forward_users")}>
          {getFieldDecorator("user_ids", {
            rules: [
              {
                required: true,
                message: t("alarm_records.forward_users_required"),
              },
            ],
          })(
            <Select
              mode="multiple"
              placeholder={t("alarm_records.forward_users_placeholder")}
              showSearch
							optionFilterProp='filter'
              filterOption={(input, option) => {
                return (
                  option?.props?.children
                    ?.toLowerCase()
                    ?.indexOf(input?.toLowerCase()) >= 0
                );
              }}
            >
              {this.currentStore?.allForwardUsers?.map((e) => (
                <Option key={e.id} value={e.id}>
                  {t(e.email)}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>
      </div>
    );
  }
  /**
   * renderDetailData
   * @param   {object} formData
   * @param   {object} getFieldDecorator
   * this function return the agency detail box
   * @return  {Component}
   */
  renderDetailData(formData, getFieldDecorator) {
    const { editable } = this.props;
    if (editable) {
      return this.renderCreateOrEditForm(formData, getFieldDecorator);
    } else {
      return this.renderReadOnlyForm(formData);
    }
  }
}

const AlarmsRecordsDetailForm = Form.create({
  name: "alrams-record-detail-from",
})(AlarmsRecordsDetail);

export default withTranslation()(AlarmsRecordsDetailForm);
