import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  InputNumber,
  Radio,
  Row,
  Select,
  TimePicker,
} from "antd";
import { inject, observer } from "mobx-react";
import moment from "moment";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import {
  DATE_FORMAT,
  MANUAL_SCOPE,
  TIME_TO_RUN_REPORT,
  TRIGGER_TYPE,
  WEEK_DAY_OPTION,
} from "utils/constants";
import PopupTrend from "./PopupTrend";
import "./style.scss";
import { TIMEZONES } from "utils/timezone";
const { OptGroup } = Select;
let arrRow = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
let DAY_OF_MONTH = [];
for (let i = 0; i < 3; i++) {
  DAY_OF_MONTH.push(
    <OptGroup key={i}>
      {arrRow.map((e) => {
        let keyId = i * 10 + e;
        return (
          <Select.Option key={keyId}>
            {keyId < 10 ? "0" + keyId : keyId}
          </Select.Option>
        );
      })}
    </OptGroup>
  );
}
DAY_OF_MONTH.push(
  <OptGroup key={111}>
    <Select.Option key={31}>31</Select.Option>
  </OptGroup>
);

const HORIZONTAL_LAYOUT = "horizontal";

@inject("rootStore")
@observer
class ReportTemplateDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formLayout: HORIZONTAL_LAYOUT,
    };
    this.formItemLayout = {
      labelCol: { span: 24 },
      wrapperCol: { span: 24 },
    };
    this.formItemLayoutNoLabel = {
      wrapperCol: { span: 24 },
    };
  }
  componentDidMount() {
    const { childRef } = this.props;
    childRef && childRef(this);
  }
  onChangeTime = (field, value) => {
    this.onChangeField(field, value?.format(DATE_FORMAT.hour));
    return value;
  };
  onChangeDate = (field, value) => {
    this.onChangeField(field, value?.format(DATE_FORMAT.day_only_backend));
    return value;
  };
  onChangeField = (field, value) => {
    const {
      rootStore: {
        reportTemplateStore: { setFormField },
      },
    } = this.props;
    setFormField(field, value);
    return value;
  };
  setFormError = (errors) => {
    errors?.map((e) => {
      const fieldObject = {};
      let fieldName = e.field;
      fieldObject[fieldName] = {
        errors: errors.filter((ei) => ei.field == e.field),
      };
      this.props.form.setFields(fieldObject);
    });
  };
  handleUpdate = () => {
    const {
      rootStore: {
        reportTemplateStore: {
          getTemplateDetail,
          submitUpdateSchedule,
          formData,
        },
      },
    } = this.props;
    this.props.form.validateFields((err) => {
      if (!err) {
        submitUpdateSchedule(
          () => {
            // history.push("/summary-report/templates");
            getTemplateDetail(formData.id);
          },
          (error) => {
            this.setFormError(error);
          }
        );
      }
    });
  };

  handleNext = (cb) => {
    this.props.form.validateFields((err) => {
      if (!err) {
        cb();
      }
    });
  };
  renderTimeOfDay = () => {
    const {
      t,
      form: { getFieldDecorator },
      rootStore: {
        reportTemplateStore: { formData },
      },
    } = this.props;
    const moduleName = "report_template";
    return (
      formData.schedule.scope !== MANUAL_SCOPE && (
        <Form.Item >
          <Col fontSize="18px"><b>{t(moduleName + ".label_set_trigger_time")}</b></Col>
          {getFieldDecorator("schedule.time", {
            rules: [
              {
                required: !(formData?.schedule?.scope === MANUAL_SCOPE),
                message: t(moduleName + ".time_of_day_required"),
              },
            ],
            initialValue: formData.schedule.time
              ? moment(formData?.schedule.time, DATE_FORMAT.hour)
              : null,
            getValueFromEvent: (e) => {
              return this.onChangeTime("schedule.time", e);
            },
          })(
            <TimePicker
              disabled={formData?.schedule?.scope === MANUAL_SCOPE}
              format={DATE_FORMAT.hour}
            />
          )}
        </Form.Item>
      )
    );
  };
  render() {
    const {
      t,
      form: { getFieldDecorator, resetFields },
      rootStore: {
        reportTemplateStore: {
          formData,
          setOccuringTime,
          timeValueSelectedOccuring,
          setTimeValueSelectedOccuring,
        },
      },
    } = this.props;
    let visibleTrendSetting = ![TRIGGER_TYPE.once_time_scheduled_trigger, MANUAL_SCOPE].includes(
      formData?.schedule?.scope
    );
    const moduleName = "report_template";
    return (
      <div className="wrap-alarm-rule-detail-page">
        <Form layout={this.state.formLayout} onSubmit={this.handleSubmit}>
          <div>
            <Row>
              <Col xl={12} xs={24}>
                <Form.Item
                  className="trigger-type-form"
                >
                  <Col fontSize="18px"><b>{t(moduleName + ".trigger_type")}</b></Col>
                  <Radio.Group
                    name="radiogrouptype"
                    value={formData.schedule.scope}
                    onChange={(e) => {
                      this.onChangeField("schedule.scope", e.target.value);
                      setTimeValueSelectedOccuring(e.target.value);
                    }}
                  >
                    <Radio value={TRIGGER_TYPE.manual_trigger}>
                      {t("report_template.manual_trigger")}
                    </Radio>
                    <Radio value={TRIGGER_TYPE.once_time_scheduled_trigger}>
                      {t("report_template.once_time_scheduled_trigger")}
                    </Radio>
                  </Radio.Group>
                  <Radio 
                    value={TRIGGER_TYPE.recurring_scheduled_trigger} 
                    checked={formData.schedule.scope ===
                      TIME_TO_RUN_REPORT.DAY_OF_MONTH ||
                      formData.schedule.scope ===
                      TIME_TO_RUN_REPORT.DAY_OF_WEEK ||
                      formData.schedule.scope ===
                      TIME_TO_RUN_REPORT.EVERY_DAY || timeValueSelectedOccuring ===
                      TRIGGER_TYPE.recurring_scheduled_trigger ? true : false
                    } 
                    onChange={() => {
                      setOccuringTime();
                      this.onChangeField("schedule.scope", TIME_TO_RUN_REPORT.EVERY_DAY);
                    }}>
                    {t("report_template.recurring_scheduled_trigger")}
                  </Radio>
                </Form.Item>
                <Row
                  style={{
                    display:
                      formData.schedule.scope ===
                        TRIGGER_TYPE.once_time_scheduled_trigger
                        ? "inline-block"
                        : "none",
                  }}
                >
                  <Col fontSize="18px"><b>{t(moduleName + ".label_set_trigger_date")}</b></Col>
                  <Form.Item>
                    {getFieldDecorator("schedule.value.date", {
                      rules: [
                        {
                          required: true,
                          message: t(moduleName + ".schedule_once_required"),
                        },
                      ],
                      initialValue: moment(
                        formData?.schedule?.value?.date,
                        DATE_FORMAT.day_only_backend
                      ),
                      getValueFromEvent: (e) => {
                        return this.onChangeDate("schedule.value.date", e);
                      },
                    })(<DatePicker format={DATE_FORMAT.day_only_backend} />)}
                  </Form.Item>
                </Row>
                <Col
                  span={24}
                  className="radio-checked-time"
                  style={{
                    display:
                      (formData.schedule.scope ===
                        TIME_TO_RUN_REPORT.DAY_OF_MONTH ||
                        formData.schedule.scope ===
                        TIME_TO_RUN_REPORT.DAY_OF_WEEK ||
                        formData.schedule.scope ===
                        TIME_TO_RUN_REPORT.EVERY_DAY) ||
                        timeValueSelectedOccuring ===
                        TRIGGER_TYPE.recurring_scheduled_trigger
                        ? "inline-block"
                        : "none",
                  }}
                >
                  <Col fontSize="18px"><b>{t("report_template.set_recurring")}</b></Col>
                  <Row>
                    <Col xl={4}>
                      <Radio.Group
                        defaultValue={TIME_TO_RUN_REPORT.EVERY_DAY}
                        name="radiogroup"
                        value={formData.schedule.scope}
                        onChange={(e) => {
                          this.onChangeField("schedule.scope", e.target.value);
                        }}
                      >
                        <Radio
                          defaultChecked={true}
                          value={TIME_TO_RUN_REPORT.EVERY_DAY}
                          className="radio-recurring"
                        >
                          {t("report_template.EVERY_DAY")}
                        </Radio>
                        <Radio
                          value={TIME_TO_RUN_REPORT.DAY_OF_WEEK}
                          className="radio-recurring"
                        >
                          {t("report_template.DAY_OF_WEEK")}
                        </Radio>
                        <Radio
                          value={TIME_TO_RUN_REPORT.DAY_OF_MONTH}
                          className="radio-recurring"
                        >
                          {t("report_template.DAY_OF_MONTH")}
                        </Radio>
                      </Radio.Group>
                    </Col>
                    <Col xl={20}>
                      <Row>
                        <Form.Item validateStatus="success" help="">
                          {getFieldDecorator("schedule.value.offset", {
                            rules: [
                              {
                                required: true,
                                message: t(
                                  moduleName + ".schedule_offset_required"
                                ),
                              },
                            ],
                            initialValue: formData?.schedule?.value.offset,
                            getValueFromEvent: (e) => {
                              return this.onChangeField(
                                "schedule.value.offset",
                                e
                              );
                            },
                          })(<InputNumber min={1} />)}
                          {t(moduleName + ".schedule_days_from")}&nbsp;
                          {getFieldDecorator("schedule.value.date", {
                            rules: [
                              {
                                required: true,
                                message: t(
                                  moduleName + ".schedule_date_required"
                                ),
                              },
                            ],
                            initialValue: moment(
                              formData?.schedule?.value.date,
                              DATE_FORMAT.day_only_backend
                            ),
                            getValueFromEvent: (e) => {
                              return this.onChangeDate(
                                "schedule.value.date",
                                e
                              );
                            },
                          })(<DatePicker format={DATE_FORMAT.day_only} />)}
                        </Form.Item>
                      </Row>
                      <Row>
                        <Form.Item
                          className="label"
                          {...this.formItemLayout}
                          validateStatus="success"
                          help=""
                        >
                          {getFieldDecorator("schedule.value.day_of_week", {
                            rules: [
                              {
                                required: true,
                                message: t(
                                  moduleName + ".week_days_required"
                                ),
                              },
                            ],
                            initialValue:
                              formData?.schedule?.value?.day_of_week,
                            getValueFromEvent: (e) => {
                              return this.onChangeField(
                                "schedule.value.day_of_week",
                                e
                              );
                            },
                          })(<Checkbox.Group options={WEEK_DAY_OPTION} />)}
                        </Form.Item>
                      </Row>
                      
                      <Form.Item validateStatus="success" help="">
                        {getFieldDecorator("schedule.value..day_of_month", {
                          rules: [
                            {
                              required: true,
                              message: t(
                                moduleName + ".schedule_day_month_required"
                              ),
                            },
                          ],
                          initialValue:
                            formData?.schedule?.value?.day_of_month,
                          getValueFromEvent: (e) => {
                            return this.onChangeField(
                              "schedule.value.day_of_month",
                              e
                            );
                          },
                        })(
                          <Select
                            mode="multiple"
                            style={{ width: "680px" }}
                            placeholder="Please select"
                          >
                            {DAY_OF_MONTH}
                          </Select>
                        )}
                      </Form.Item>
                      <Button
                        icon="delete"
                        type='primary'
                        onClick={() => {
                          this.onChangeField(
                            "schedule.value.day_of_month",
                            []
                          ),
                          resetFields('schedule.value..day_of_month');
                        }}
                      >
                        {t('report_template.remove_all')}
                      </Button>
                    </Col>
                  </Row>
                </Col>
                <Col
                  style={{
                    display:
                      formData?.schedule?.scope !== TRIGGER_TYPE.manual_trigger
                        ? "inline-block"
                        : "none",
                  }}
                  span={24}
                >
                  <Form.Item >
                    <Col><b>{t("timezone")}</b></Col>
                    {
                      TIMEZONES.find((e) => e.value === formData?.time_zone)
                        .label
                    }
                  </Form.Item>
                  {this.renderTimeOfDay()}
                </Col>
              </Col>
              <Col xl={12} xs={24}>
                <Row>
                  <PopupTrend disabled={!visibleTrendSetting} />
                </Row>
              </Col>
            </Row>
          </div>
        </Form >
      </div >
    );
  }
}

const WrapForm = Form.create({ name: "alarm_rule" })(ReportTemplateDetail);

export default withRouter(withTranslation()(WrapForm));
