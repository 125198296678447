import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Button, Typography, Select, Radio, DatePicker } from "antd";
import "../../style.scss";
import { inject, observer } from "mobx-react";
import {
  DASHBOARD_INTERSECTION_SELECTION_TYPE,
  DASHBOARD_WIDGETS,
  MAP_PERIODS,
  TIME_PERIODS,
} from "../../../../utils/constants/dashboardConstant";
import RightSideBar from "../../../../components/RightSideBar";
import { DATE_FORMAT, TIMESTAMP } from "../../../../utils/constants";
import moment from "moment";

const { Option } = Select;
const { RangePicker } = DatePicker;

/**
 * Component for showing user info pannel
 *
 * @component
 */
@inject("rootStore")
@observer
class VolumeCountsSetting extends Component {
  constructor(props) {
    super(props);
  }

  cancelVolumeCountsSetting = () => {
    const {
      rootStore: {
        dashboardStore: { resetVolumeCountsSetting, toggleWidgetSetting },
      },
      onClose,
    } = this.props;

    resetVolumeCountsSetting && resetVolumeCountsSetting();
    toggleWidgetSetting(false);
    onClose && onClose();
  };

  saveVolumeCountsSetting = () => {
    const {
      rootStore: {
        dashboardStore: { patchDashboardSetting },
      },
      onClose,
    } = this.props;

    patchDashboardSetting &&
      patchDashboardSetting(DASHBOARD_WIDGETS.VOLUME_COUNT);
    onClose && onClose();
  };

  isValidPeriod = (periodType, timeRange) => {
    const { t } = this.props;
    if (periodType === TIME_PERIODS.CUSTOM) {
      if (timeRange?.length !== 2 || !timeRange[0] || !timeRange[1]) {
        return t("dashboard.time_period_in_valid");
      }
      if (
        moment.unix(timeRange[1].unix()) - moment.unix(timeRange[0].unix()) ===
        0
      ) {
        return t("dashboard.time_in_valid");
      }
      if (timeRange[1] - timeRange[0] >= TIMESTAMP.ONE_DAY) {
        return t("dashboard.time_period_in_valid");
      }
    }
    return "";
  };

  /**
   * render
   *
   * @return  {Component}
   */
  render() {
    const {
      t,
      rootStore: {
        dashboardStore: {
          widgetSetting,
          intersectionOptions,
          volumeCountsSetting,
          changeVolumeCountsSetting,
          savingSetting,
        },
      },
    } = this.props;
    return (
      <RightSideBar
        visible={widgetSetting}
        title={t("dashboard.widget_settings")}
        onClose={this.cancelVolumeCountsSetting}>
        <div className="setting-info-panel">
          <Typography.Paragraph>
            {t("dashboard.volume-count-header") +
              " - " +
              t("dashboard.widget_settings_subtitle")}
          </Typography.Paragraph>
          <div className="setting-section">
            <span className="setting-label">{t("map_setting.period")}:</span>
            <Select
              className="setting-select"
              size="small"
              defaultValue={volumeCountsSetting.period}
              onChange={(period) =>
                changeVolumeCountsSetting({ period: period })
              }
              // labelInValue
            >
              {MAP_PERIODS?.map((e) => {
                return (
                  <Option key={e.value} value={e.value}>
                    {t(e.label)}
                  </Option>
                );
              })}
            </Select>
            {volumeCountsSetting.period === TIME_PERIODS.CUSTOM && (
              <>
                <RangePicker
                  style={{ width: "100%", marginTop: "0.5rem" }}
                  format={DATE_FORMAT.full}
                  value={volumeCountsSetting.timeRange}
                  allowClear={false}
                  onChange={(val) =>
                    changeVolumeCountsSetting({ timeRange: val })
                  }
                  showTime
                />
                <Typography.Text type="danger">
                  {this.isValidPeriod(
                    volumeCountsSetting.period,
                    volumeCountsSetting.timeRange
                  )}
                </Typography.Text>
              </>
            )}
          </div>
          <div className="setting-section">
            <p className="setting-label">
              {t("dashboard.selected_intersection_list")}
              <br />
              {t("dashboard.selected_intersection_description")}
            </p>
            <Radio.Group
              className="w-full"
              onChange={(e) =>
                changeVolumeCountsSetting({
                  select_type: e.target.value,
                  selected_intersections: [],
                })
              }
              value={volumeCountsSetting.select_type}>
              <Radio
                value={DASHBOARD_INTERSECTION_SELECTION_TYPE.INCLUDE_ALL}
                className="w-full">
                {t("dashboard.include_all_intersections")}
              </Radio>
              <Radio
                value={DASHBOARD_INTERSECTION_SELECTION_TYPE.INCLUDE_SPECIFIC}
                className="w-full setting-select">
                {t("dashboard.include_specific_intersections")}
              </Radio>
              <Radio
                value={DASHBOARD_INTERSECTION_SELECTION_TYPE.EXCLUDE_SPECIFIC}
                className="w-full setting-select">
                {t("dashboard.exclude_specific_intersections")}
              </Radio>
            </Radio.Group>
            <Select
              mode="multiple"
              className="setting-select"
              placeholder={t("dashboard.select_intersections")}
              defaultValue={volumeCountsSetting.selected_intersections}
              onChange={(val) => {
                changeVolumeCountsSetting({ selected_intersections: val });
              }}
              disabled={
                volumeCountsSetting.select_type ===
                DASHBOARD_INTERSECTION_SELECTION_TYPE.INCLUDE_ALL
              }
              optionFilterProp="label">
              {intersectionOptions.map((el) => (
                <Option key={el.id} value={el.id} label={el.name}>
                  {el.name}
                </Option>
              ))}
            </Select>
          </div>
          <div className="flex justify-end w-full mt-8 setting-footer">
            <Button
              type="default"
              onClick={this.cancelVolumeCountsSetting}
              disabled={savingSetting}>
              {t("cancel")}
            </Button>
            <Button
              type="primary"
              style={{ marginLeft: 5 }}
              onClick={this.saveVolumeCountsSetting}
              loading={savingSetting}
              disabled={
                !volumeCountsSetting.changed ||
                this.isValidPeriod(
                  volumeCountsSetting.period,
                  volumeCountsSetting.timeRange
                )
              }>
              {t("save")}
            </Button>
          </div>
        </div>
      </RightSideBar>
    );
  }
}

export default withTranslation()(VolumeCountsSetting);
