import React, { Component } from "react";
import { observer, inject } from "mobx-react";

import { withTranslation } from "react-i18next";
import "./style.scss";
import { Badge, Col, List, Row } from "antd";
import VolumeCountsChart from "../../../components/Charts/VolumeCountsChart";
import { TIME_PERIODS } from "../../../utils/constants/dashboardConstant";
import { DATE_FORMAT } from "../../../utils/constants";

@inject("rootStore")
@observer
class VolumeCounts extends Component {
  constructor(props) {
    super(props);
    this.wrapRef = React.createRef();
    this.state = {
      compact: true,
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = () => {
    const { width } = this.wrapRef.current?.getBoundingClientRect();
    this.setState({
      compact: width < 450,
    });
  };

  renderItems = (item) => {
    const { compact } = this.state;
    return (
      <List.Item>
        <Row className="volume-count-rows">
          <Col span={compact ? 4 : 3} className="volume-count-cell">
            <Badge
              count={item.numerical_order}
              style={{ backgroundColor: "#1890ff" }}
            />
          </Col>
          <Col span={compact ? 14 : 7} className="volume-count-cell">
            {item.intersection_name}
          </Col>
          <Col span={compact ? 6 : 4} className="volume-count-cell text-center">
            {item.total_count}
          </Col>
          {!compact && (
            <Col span={10} className="volume-count-cell">
              <VolumeCountsChart volumeCounts={item.volume_counts} />
            </Col>
          )}
        </Row>
      </List.Item>
    );
  };

  render() {
    const {
      t,
      rootStore: {
        dashboardStore: {
          volumeCounts: { data, loading },
          volumeCountsSetting: { period, timeRange },
        },
      },
      skeleton,
    } = this.props;

    return (
      <div className="wrap-volume-count" ref={this.wrapRef}>
        {skeleton ? (
          <div className="widget-header-title">
            {t("volume_counts.page-title")}
          </div>
        ) : (
          <>
            <div className="widget-header">
              <div className="widget-header-title">
                {t("volume_counts.page-title")}
              </div>
              <div className="widget-header-subtitle">
                {period === TIME_PERIODS.CUSTOM
                  ? timeRange[0]?.utc().format(DATE_FORMAT.withTimeZone) +
                    " - " +
                    timeRange[1]?.utc().format(DATE_FORMAT.withTimeZone)
                  : t("map_setting." + period?.toLowerCase())}
              </div>
            </div>
            <div className="wrap-volume-count-content styled-scroll">
              <List
                itemLayout="horizontal"
                dataSource={data}
                loading={loading}
                renderItem={this.renderItems}
              />
            </div>
          </>
        )}
      </div>
    );
  }
}

export default withTranslation()(VolumeCounts);
