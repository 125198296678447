import { Col, Input, Select, Tooltip } from "antd";
import StatusTag from "components/StatusTag";
import { inject, observer } from "mobx-react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import {
  ACTION_STATUS_VISIBILITY_MAP,
  ACTIONS_LIST,
  ACTIVATE,
  ACTIVE,
  DEACTIVATE,
  INACTIVE,
  RESEND_EMAIL,
  RESOURCE_LIST,
  UNACTIVATED,
  USER_STATUS_LIST,
} from "utils/constants";
import BaseManagementComponents from "../../components/BaseManagementComponents";
import UserDetail from "./UserDetail";
import React from "react";

const { Option } = Select;

/**
 * Component for showing lists of the user.
 *
 * @component
 */
@inject("rootStore")
@observer
class Users extends BaseManagementComponents {
  constructor(props) {
    super(props.rootStore?.userStore, props);
    const {
      t,
      rootStore: { myStore },
    } = props;
    this.resourceName = RESOURCE_LIST.USER;
    this.rowKey = "user_id";
    this.moduleName = "user";
    this.columns = this.getTableColumns();
    this.pageTitle = t("user_management");
    this.buttonActions = [
      {
        key: "active_all",
        title: t("user.active_all"),
        type: "check",
        resource: RESOURCE_LIST.USER_ACTIVE,
        action: this.currentStore?.activeUser,
        permission: ACTIONS_LIST.EDIT,
        confirm: true,
        confirmTitle: t("user.active_user_confirm_title"),
        confirmContent: t("user.active_user_confirm_content"),
        notEnable: this.disableTableButton(ACTIVATE),
      },
      {
        key: "deactive_all",
        title: t("user.deactive_all"),
        resource: RESOURCE_LIST.USER_INACTIVE,
        action: this.currentStore?.deActiveUser,
        permission: ACTIONS_LIST.EDIT,
        confirm: true,
        type: "stop",
        confirmTitle: t("user.inactive_user_confirm_title"),
        confirmContent: t("user.inactive_user_confirm_content"),
        notEnable: this.disableTableButton(DEACTIVATE),
      },
      {
        key: "resend_email",
        title: 'Send Activation Email',
        resource: RESOURCE_LIST.USER_UNACTIVATED,
        action: this.currentStore?.sendActivationEmail,
        permission: ACTIONS_LIST.EDIT,
        confirm: true,
        type: "mail",
        confirmTitle: 'resend email',
        confirmContent: 'resend email',
        notEnable: this.disableTableButton(RESEND_EMAIL),
      },
      {
        key: "delete",
        title: t("user.delete"),
        resource: RESOURCE_LIST.USER_DETAIL,
        action: this.currentStore?.deleteUser,
        permission: ACTIONS_LIST.REMOVE,
        confirm: true,
        type: "delete",
        confirmTitle: t("user.delete_user_confirm_title"),
        confirmContent: t("user.delete_user_confirm_content"),
      },
    ];
    this.tableInlineActions = [
      {
        key: "edit",
        tooltip: t("user.edit"),
        resource: RESOURCE_LIST.USER_DETAIL,
        action: this.currentStore?.showCreateOrEditForm,
        permission: ACTIONS_LIST.EDIT,
        icon: "edit",
        visible: (item) => item?.user_id !== myStore?.currentProfile?.id,
        passingData: (item) => {
          return [t("user.edit_form_tile"), item?.user_id];
        },
      },
      {
        key: "deactive_all",
        tooltip: t("user.deactive"),
        action: this.handleTableAction,
        resource: RESOURCE_LIST.USER_INACTIVE,
        permission: ACTIONS_LIST.EDIT,
        icon: "stop",
        passingData: (item) => {
          return ["deactive_all", [item?.user_id]];
        },
        visible: (item) => {
          return !item?.disable && item?.status === ACTIVE;
        },
      },
      {
        key: "active_all",
        tooltip: t("user.active"),
        action: this.handleTableAction,
        resource: RESOURCE_LIST.USER_ACTIVE,
        permission: ACTIONS_LIST.EDIT,
        icon: "check",
        passingData: (item) => {
          return ["active_all", [item?.user_id]];
        },
        visible: (item) => {
          return !item?.disable && item?.status === INACTIVE;
        },
      },
      {
        key: "resend_email",
        tooltip: t("user.active"),
        action: this.handleTableAction,
        resource: RESOURCE_LIST.USER_UNACTIVATED,
        permission: ACTIONS_LIST.EDIT,
        icon: "mail",
        passingData: (item) => {
          return ["resend_email", [item?.user_id]];
        },
        visible: (item) => {
          return !item?.disable && item?.status === UNACTIVATED;
        },
      },
      {
        key: "delete",
        tooltip: t("user.delete"),
        action: this.handleTableAction,
        resource: RESOURCE_LIST.USER_DETAIL,
        permission: ACTIONS_LIST.REMOVE,
        icon: "delete",
        passingData: (item) => {
          return ['delete', [item?.user_id]];
        },
        visible: (item) => item?.user_id !== myStore?.currentProfile?.id,
      },
    ];
  }

  /**
   *
   * comparesSelectedListStatus
   * returns true if the status argument is the same as all selected user arguments and false otherwise
   * @returns {boolean}
   */
  compareSelectedListStatus = (status) => {
    const selectedItemsID = this.currentStore?.selectedItems;
    const selectedItems = this.currentStore?.listData.filter(item => selectedItemsID.includes(item?.user_id))

    return selectedItems.every(item => item?.status === status);
  }

  /**
   *
   * disableSelectedListStatus
   * this function first takes the input action and gets the status the user needs to be in in order to enable the action.
   * returns a function which compares the status of selected users with the status determined from the map
   * @returns {function}
   */
  disableTableButton = (action) => {
    const status = ACTION_STATUS_VISIBILITY_MAP[action]

    return () => {
      return !this.compareSelectedListStatus(status);
    }
  }

  /**
   * componentDidMount
   *
   */
  componentDidMount = () => {
    const { t } = this.props;
    document.title = t("user.page-title");
    super.componentDidMount();
    if (this.props.rootStore?.commonStore.isSuperAdmin()) {
      this.currentStore.getAllAgencies();
    }
    this.currentStore.clearSelectedItems();
  };

  /**
   * return column set of table
   */
  getTableColumns = () => {
    const { t } = this.props;
    return [
      {
        title: t("user.email"),
        dataIndex: "email",
        render: (value, row) =>
          this.renderKeyColumn(value, row, RESOURCE_LIST.USER_DETAIL),
        key: "email",
        sorter: true,
        sortDirections: ["descend", "ascend"],
        ellipsis: true,
      },
      {
        title: t("user.name"),
        dataIndex: "name",
        key: "name",
        sorter: true,
        sortDirections: ["descend", "ascend"],
        ellipsis: true,
      },
      {
        title: t("user.phone"),
        dataIndex: "phone",
        key: "phone",
        ellipsis: true,
      },
      {
        title: t("user.agencies"),
        dataIndex: "roles",
        key: "roles",
        render: (roles) => (
          <span>
            {roles
              ?.map(function (elem) {
                return elem.agency_name;
              })
              .join(", ")}
          </span>
        ),
        ellipsis: true,
      },
      {
        title: t("user.status"),
        dataIndex: "status",
        key: "status",
        sorter: true,
        render: (status) => <StatusTag status={status}/>,
        ellipsis: true,
      },
    ];
  };

  //-------- render common component ----------
  /**
   * renderFilterComponent
   * this function return the filter box
   * @return  {Component}
   */
  renderFilterComponent = () => {
    const {
      t,
      rootStore: { commonStore },
    } = this.props;
    return (
      <div>
        <Col span={4}>
          <Tooltip placement="top" title={t("users.search_tooltip")}>
            <Input
              maxLength={255}
              defaultValue={this.currentStore?.filterData?.text}
              onChange={(e) =>
                this.currentStore?.handleFilterDataChange(
                  "text",
                  e.target?.value
                )
              }
              placeholder={t("user.search_placholer")}
              onPressEnter={this.currentStore?.handleFilerAction}
            />
          </Tooltip>
        </Col>
        {commonStore.isSuperAdmin() && (
          <Col span={4}>
            <Tooltip placement="top" title={t("users.agency_tooltip")}>
              <Select
                labelInValue
                onChange={(value) =>
                  this.currentStore?.handleFilterDataChange(
                    "agency_id",
                    value.key
                  )
                }
                defaultValue={{ key: this.currentStore?.filterData.agency_id }}>
                <Option value="null"> {t("user.all_agencies")} </Option>
                {this.currentStore?.listAllUserAgency?.map((e) => (
                  <Option key={e.id} value={e.id}>
                    {e.name}
                  </Option>
                ))}
              </Select>
            </Tooltip>
          </Col>
        )}
        <Col span={4}>
          <Tooltip placement="top" title={t("users.status_tooltip")}>
            <Select
              onChange={(value) =>
                this.currentStore?.handleFilterDataChange("status", value)
              }
              defaultValue={this.currentStore?.filterData?.status}>
              <Option value="null">{t("user.all_status")}</Option>
              {USER_STATUS_LIST?.map((e) => (
                <Option key={e.key} value={e.key}>
                  {t(e.value)}
                </Option>
              ))}
            </Select>
          </Tooltip>
        </Col>
      </div>
    );
  };
  /**
   * renderDetailData
   * this function return the user detail box
   * @return  {Component}
   */
  renderDetailData = () => {
    return (
      <UserDetail
        editable={this.currentStore?.formEditable}
        onClose={this.handleDrawerOnClose}
        childRef={(ref) => (this.defailFromRef = ref)}
      />
    );
  };

  /**
   * render
   *
   * @return  {Component}
   */
  render() {
    return super.render();
  }
}

export default withRouter(withTranslation()(Users));
