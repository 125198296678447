import * as d3 from "d3";
import React, { Component, createRef } from "react";
import { withTranslation } from "react-i18next";
import { CHART_COLOR_SCHEMA } from "utils/constants";
import { toFixed2Offset } from "utils/helper";
import './style.scss'

class IntersectionOffsetBarChart extends Component {
  fullWidth = 0;
  fullHeight = 0;
  marginX = 64;
  marginY = 16;
  yOffsetInfo = 48;
  tooltipWidth = 170;
  tooltipMargin = 20;
  crossHairObject = {};
  processedData = null;
  
  constructor(props) {
    super(props);
    this.chartRef = createRef();
  }

  processData = () => {
    this.processedData = this.props.data?.map((item) => {
      const predictPercent =
        item.predicted_aog_percent % 1 === 0
          ? item.predicted_aog_percent
          : Number(item.predicted_aog_percent).toFixed(2);
      const existingPercent =
        item.existing_aog_percent % 1 === 0
          ? item.existing_aog_percent
          : Number(item.existing_aog_percent).toFixed(2);
      return {
        phase: item.phases,
        is_coord_phase: item.is_coord_phase,
        existing_aog: item.existing_aog,
        predicted_aog: item.predicted_aog,
        predicted_aog_percent: predictPercent,
        existing_aog_percent: existingPercent
      }
    })
  }

  renderChartCrossHair = () => {
    let currentContext = this;
    //render overlay rect cover all chart + add crosshair on that
    let overLayGroup = this.chartObject
      .append("g")
      .attr("class", "overlay-group");
    let crossHairGroup = overLayGroup
      .append("g")
      .attr("class", "cross-hair-group");
    //add crosshair line
    this.crossHairObject.crosshair_x = crossHairGroup
      .append("line")
      .attr("class", "cross-hair-line")
      .attr("stroke", "var(--text)")
      .attr("display", "none")
      .attr("x1", 0)
      .attr("y1", this.marginY)
      .attr("x2", 0)
      .attr("y2", this.fullHeight + this.marginY);
    this.crossHairObject.crosshair_y = crossHairGroup
      .append("line")
      .attr("class", "cross-hair-line")
      .attr("stroke", "var(--text)")
      .attr("display", "none")
      .attr("x1", this.marginX)
      .attr("y1", 0)
      .attr("x2", this.fullWidth + this.marginX)
      .attr("y2", 0);
    //add overlay rect
    overLayGroup
      .append("rect")
      .attr("class", "overlay-area")
      .attr("width", this.fullWidth)
      .attr("height", this.fullHeight)
      .attr("fill-opacity", "0")
      .attr("transform", "translate(" + this.marginX + "," + this.marginY + ")")
      .on("mousemove", function () {
        let mouse = d3.mouse(this);
        currentContext.handleMouseMove(mouse);
      })
      .on("mouseout", this.handleMouseOut);

    //handler mouse hover /movein/ moveout of overlay to draw cross hair and detail box
  };

  drawHighlightIntersection = (targetInt, x, y) => {
    if (targetInt) {
      this.highlightObject = this.chartObject
        .append("g")
        .attr("class", "hightline-group");
      const tooltipHeight = 40;
      const xTooltipPosition =
        x < this.fullWidth / 2
          ? this.marginX + x + this.tooltipMargin
          : this.marginX + x - this.tooltipWidth - this.tooltipMargin;
      const yTooltipPosition =
        y < this.fullHeight / 2
          ? y + tooltipHeight / 2 + this.tooltipMargin
          : y - tooltipHeight / 2 - this.tooltipMargin;

      this.highlightObject
        .append("rect")
        .attr("width", this.tooltipWidth) //hard code the width of box
        .attr("height", tooltipHeight)
        .attr("class", "tooltip-box")
        .attr("stroke", "var(--text)")
        .attr("fill", "var(--background)")
        .attr(
          "transform",
          "translate(" + xTooltipPosition + "," + yTooltipPosition + ")"
        );
      let labelIndex = 1;
      const textArray = [
        {
          text: `Existing AoG: ${targetInt.existing_aog}`,
          color: CHART_COLOR_SCHEMA.GREEN,
        },
        {
          text: `Predicted AoG: ${targetInt.predicted_aog}`,
          color: CHART_COLOR_SCHEMA.DURATION,
        },
      ];
      textArray.forEach((txt) => {
        this.highlightObject
          .append("text")
          .text(txt.text) //@TODO:hard code here
          .attr("text-anchor", "start")
          .attr("class", "tooltip-info-text")
          .attr(
            "transform",
            "translate(" +
              (xTooltipPosition + 16) +
              "," +
              (yTooltipPosition + 16 * labelIndex) +
              ")"
          )
          .attr("fill", txt.color);
        labelIndex += 1;
      });
    }
  };
  handleMouseMove = (mousePosition) => {
    this.highlightObject?.remove();

    if (Array.isArray(mousePosition) && mousePosition.length === 2) {
      let xPosition = mousePosition[0];
      let yPosition = mousePosition[1];
      //find intersection
      const findIntersection = this.processedData.find(
        (_int, id) => Math.abs(this.xScaleObject(id + 1) - xPosition) <= 20
      );
      if (findIntersection)
        this.drawHighlightIntersection(findIntersection, xPosition, yPosition);
      //move crossHair
      this.crossHairObject?.crosshair_x
        ?.attr("display", "block")
        .attr("x1", xPosition + this.marginX)
        .attr("x2", xPosition + this.marginX);
      this.crossHairObject?.crosshair_y
        ?.attr("display", "block")
        .attr("y1", yPosition + this.marginY)
        .attr("y2", yPosition + this.marginY);
    }
  };
  handleMouseOut = () => {
    //hide crossHair
    this.crossHairObject?.crosshair_x?.attr("display", "none");
    this.crossHairObject?.crosshair_y?.attr("display", "none");
    //hide highlight data point
    this.highlightObject?.remove();
  };
  drawXAxis = () => {
    this.xScaleObject = d3
      .scaleLinear()
      .domain([0, this.processedData.length + 1])
      .range([0, this.fullWidth]);

    //create axisObject
    let xAxisObject = d3
      .axisBottom(this.xScaleObject)
      .tickSize(0)
      .tickFormat(() => null);

    //draw x axis line
    this.xAxis = this.chartObject
      .append("g")
      .attr("class", "x-axis-botttom")
      .attr(
        "transform",
        "translate(" +
          this.marginX +
          "," +
          (this.fullHeight + this.marginY) +
          ")"
      )
      .call(xAxisObject);
  };

  drawYAxis = () => {
    let maxAoG = 0;
    this.processedData.forEach((phase => {
      if (phase.existing_aog > maxAoG) {
        maxAoG = phase.existing_aog;
      }
      if (phase.predicted_aog > maxAoG) {
        maxAoG = phase.predicted_aog;
      } 
    }))

    this.yScaleObject = d3
      .scaleLinear()
      .domain([maxAoG * 1.15, 0])
      .range([0, this.fullHeight]);

    let yAxisObject = d3.axisLeft(this.yScaleObject).tickSize(-this.fullWidth);

    this.yAxis = this.chartObject
      .append("g")
      .attr("clip-path", "url(#clip-plan)")
      .attr("transform", "translate(" + this.marginX + "," + this.marginY + ")")
      .attr("class", "y-axis-bar-chart")
      .call(yAxisObject);
  };
  drawAxis() {
    this.drawXAxis();
    this.drawYAxis();
  }

  drawIntersectionBarChart = (group, index, barChartGroup) => {
    const defaultBarWidth = 20;
    const pointX = this.xScaleObject(index + 1);

    barChartGroup
      .attr(
        "transform",
        "translate(" + this.marginX + ", " + this.marginY + ")"
      )

    if (group.is_coord_phase) {
      barChartGroup
        .append("rect")
        .style("fill", CHART_COLOR_SCHEMA.GREEN_SIEMENS)
        .attr("fill-opacity", "12%")
        .attr("width", defaultBarWidth*2*3)
        .attr("height", this.fullHeight)
        .attr("x", pointX - defaultBarWidth*3)
        .attr("text-anchor", "middle")
    }

    barChartGroup
      .append("rect")
      .style("fill", CHART_COLOR_SCHEMA.GREEN)
      .attr("clip-path", "url(#clip)")
      .attr("x", pointX - defaultBarWidth)
      .attr("y", this.yScaleObject(group.existing_aog))
      .attr("width", defaultBarWidth)
      .attr("class", "bar")
      .attr("height", this.fullHeight - this.yScaleObject(group.existing_aog));

    barChartGroup
      .append("rect")
      .style("fill", CHART_COLOR_SCHEMA.DURATION)
      .attr("clip-path", "url(#clip)")
      .attr("x", pointX - defaultBarWidth + 20)
      .attr("y", this.yScaleObject(group.predicted_aog))
      .attr("width", defaultBarWidth)
      .attr("class", "bar")
      .attr("height", this.fullHeight - this.yScaleObject(group.predicted_aog));
  };

  drawIntersectionInfo = (group, index, barChartGroup) => {
    const pointX = this.xScaleObject(index + 1);
    // up down total
    barChartGroup
      .append("text")
      .text(`PHASE ${index + 1}`)
      .attr("text-anchor", "middle")
      .style("font-size", "16px")
      .attr("x", pointX)
      .attr("y", this.fullHeight + this.marginY + 20)
      .attr("fill", group.is_coord_phase ? CHART_COLOR_SCHEMA.GREEN_SIEMENS : 'var(--text)');
  };
  
  drawIntersectionTrending = (group, index, barChartGroup) => {
    const pointX = this.xScaleObject(index + 1);

    const pointY = this.yScaleObject(
      group.existing_aog > group.predicted_aog
        ? group.existing_aog
        : group.predicted_aog
    );

    if (group.predicted_aog > group.existing_aog) {
      barChartGroup
        .append("text")
        .text("+" + toFixed2Offset(group.predicted_aog_percent - group.existing_aog_percent) + "%")
        .attr("text-anchor", "middle")
        .style("font-size", "14px")
        .attr("x", pointX)
        .attr("y", pointY - this.fullHeight*0.02)
        .attr("fill", CHART_COLOR_SCHEMA.GREEN);
    } else if (group.predicted_aog < group.existing_aog) {
      barChartGroup
        .append("text")
        .text("-" + toFixed2Offset(group.existing_aog_percent - group.predicted_aog_percent) + "%")
        .attr("text-anchor", "middle")
        .style("font-size", "14px")
        .attr("x", pointX)
        .attr("y", pointY - this.fullHeight*0.02)
        .attr("fill", CHART_COLOR_SCHEMA.RED);
    }
  };

  drawIntersectionData(group, index) {
    let barChartGroup = this.chartObject
      .append("g")
      .attr("class", "lines-chart-group")
      
    this.drawIntersectionBarChart(group, index, barChartGroup);
    this.drawIntersectionInfo(group, index, barChartGroup);
    this.drawIntersectionTrending(group, index, barChartGroup);
  }

  drawAogData = () => {
    this.processedData.forEach((group, id) => {
      this.drawIntersectionData(group, id);
    });
  };
  componentDidMount() {
    this.processData();
    this.chartObject = d3.select(this.chartRef?.current);

    this.fullWidth =
      this.chartRef.current.getBoundingClientRect().width - this.marginX * 2;
    this.fullHeight =
      this.chartRef.current.parentNode.offsetHeight -
      this.marginY * 2 -
      this.yOffsetInfo;

    this.drawAxis();
    this.drawAogData();
    this.renderChartCrossHair();
  }
  render() {
    return <svg ref={this.chartRef} width="100%" height="100%" />;
  }
}

export default withTranslation()(IntersectionOffsetBarChart);
