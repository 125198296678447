import { Form } from "antd";
import StatusTag from "components/StatusTag";
import { inject } from "mobx-react";
import moment from "moment";
import { withTranslation } from "react-i18next";
import { DATE_FORMAT, FORM_ITEM_LAYOUT } from "utils/constants";
import BaseDetailPopupComponent from "components/BaseDetailPopupComponent";
import React from "react";
/**
 * Component for showing create agency form
 *
 * @component
 */
@inject("rootStore")
class AlarmDetailPopup extends BaseDetailPopupComponent {
  /**
   * contructor of component
   * @param   {object} props  props of component
   */
  constructor(props) {
    super(props.rootStore?.alarmRuleStore, props);
    this.formFields = ["name"];
  }

  /**
   * renderReadOnlyForm
   * For view detail page, just label only
   *
   */
  renderReadOnlyForm(formData) {
    const { t } = this.props;
    return (
      <div>
        <Form.Item label={t("alarm_rules.name")} {...FORM_ITEM_LAYOUT}>
          {formData?.name}
        </Form.Item>
        <Form.Item label={t("alarm_rules.description")} {...FORM_ITEM_LAYOUT}>
          {t(formData?.description)}
        </Form.Item>
        <Form.Item label={t("alarm_rules.category")} {...FORM_ITEM_LAYOUT}>
          {t(formData?.category?.name)}
        </Form.Item>
        <Form.Item
          label={t("alarm_rules.rule_condition")}
          {...FORM_ITEM_LAYOUT}>
          {t(formData?.plain_condition)}
        </Form.Item>
        <Form.Item label={t("alarm_rules.analysis_id")} {...FORM_ITEM_LAYOUT}>
          {t(formData?.analysis?.name)}
        </Form.Item>
        <Form.Item label={t("alarm_rules.owner")} {...FORM_ITEM_LAYOUT}>
          {t(formData?.owner?.name)}
        </Form.Item>
        <Form.Item label={t("alarm_rules.status")} {...FORM_ITEM_LAYOUT}>
          <StatusTag status={formData?.status} />
        </Form.Item>
        <Form.Item label={t("alarm_rules.created_at")} {...FORM_ITEM_LAYOUT}>
          {moment(formData?.created_at).format(DATE_FORMAT.full)}
        </Form.Item>
      </div>
    );
  }

  /**
   * renderDetailData
   * @param   {object} formData
   * @param   {object} getFieldDecorator
   * this function return the detail box
   * @return  {Component}
   */
  renderDetailData(formData) {
    return this.renderReadOnlyForm(formData);
  }
}
const FormWrap = Form.create({ name: "alarm-rule-detail" })(AlarmDetailPopup);

export default withTranslation()(FormWrap);
