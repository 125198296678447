import { Form, Input, InputNumber, Select, TimePicker } from "antd";
import SiwaveMultipleDatePicker from "components/SiwaveMultipleDatePicker";
import { inject, observer } from "mobx-react";
import moment from "moment";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import {
  BIAS_DIRECTION,
  DATE_FORMAT,
  TIME_PICKER_FORMAT,
} from "utils/constants";
import SiwaveLazySelectBox from "./SiwaveLazySelectBox";

@inject("rootStore")
@observer
class CorridorOffsetFilter extends Component {
  constructor(props) {
    super(props);
  }

  /**
   * forcus on target marker
   * @return  {Component}
   */
  render() {
    const {
      t,
      rootStore,
      rootStore: {
        optimizerStore: { formData, setFormField },
      },
      form: { getFieldDecorator, validateFields, resetFields },
    } = this.props;

    return (
      <>
        <Form.Item label={t("Corridor")}>
          {getFieldDecorator("corridor_id", {
            rules: [
              {
                required: true,
                message: t("field_required", {
                  field: t("Corridor"),
                }),
              },
            ],
            initialValue: formData.corridor_id,
            getValueFromEvent: (value) => {
              setFormField("corridor_id", value);
              return value;
            },
          })(
            <SiwaveLazySelectBox
              path={`corridors`}
              firstParams={{
                agency_id: formData.agency_id,
              }}
              rootStore={rootStore}
              moduleName="corridors"
              placeholder={t("select_item", {
                item: t("corridor"),
              })}
            />
          )}
        </Form.Item>
        <Input.Group className="flex justify-space-beetwen w-full">
          <Form.Item label={t("optimization.plan_number")}>
            {getFieldDecorator("plan_number", {
              validateFirst: true,
              rules: [
                {
                  required: true,
                  message: t("optimization.plan_number_required"),
                },
                {
                  validator(rule, value, callback) {
                    if (!Number.isInteger(value)) {
                      callback(
                        t("must_integer", {
                          field: t("optimization.plan_number_required"),
                        })
                      );
                      return;
                    }
                    if (value >= 1 && value <= 255) callback();
                    else
                      callback(
                        t("range_beetwen", {
                          field: t("optimization.plan_number"),
                          min: 1,
                          max: 255,
                        })
                      );
                  },
                },
              ],
              initialValue: formData.plan_number,
            })(
              <InputNumber
                onChange={(value) => {
                  setFormField("plan_number", value);
                }}
                className="w-128"
              />
            )}
          </Form.Item>
          <Form.Item label={t("intersection_config.cycle_length")}>
            {getFieldDecorator("show_programmed", {
              initialValue: formData.cycle_length,
              rules: [
                {
                  required: true,
                  message: t("optimization.plan_number_required"),
                },
                {
                  validator(_rule, value, callback) {
                    if (!Number.isInteger(value)) {
                      callback(
                        t("must_integer", {
                          field: t("intersection_config.cycle_length"),
                        })
                      );
                    }
                    if (value < 0) {
                      callback(
                        t("min_value", {
                          field: t("intersection_config.cycle_length"),
                          min: 0,
                        })
                      );
                      return;
                    }
                    callback();
                  },
                },
              ],
              getValueFromEvent: (value) => {
                setFormField("cycle_length", value);
                return value;
              },
            })(<InputNumber step={1} className="w-128" />)}
          </Form.Item>
        </Input.Group>

        <Input.Group className="flex w-full justify-space-beetwen">
          <Form.Item label={t("report_result.time_range")}>
            {getFieldDecorator("start_time", {
              rules: [
                {
                  required: true,
                  message: t("analysis.from_time_required"),
                },
              ],

              initialValue: moment(formData.start_time, DATE_FORMAT.hour),
            })(
              <TimePicker
                placeholder={t("analysis.from_time_placeholder")}
                onChange={(time, timeString) => {
                  setFormField(
                    "start_time",
                    moment(timeString, TIME_PICKER_FORMAT).format(
                      TIME_PICKER_FORMAT
                    )
                  );
                }}
                format={TIME_PICKER_FORMAT}
              />
            )}
          </Form.Item>

          <Form.Item label={" "} colon={false}>
            {getFieldDecorator("end_time", {
              rules: [
                {
                  required: true,
                  message: t("optimization.to_time_required"),
                },
              ],
              initialValue: moment(formData.end_time, DATE_FORMAT.hour),
            })(
              <TimePicker
                placeholder={t("analysis.to_time_placeholder")}
                onChange={(time, timeString) => {
                  setFormField(
                    "end_time",
                    moment(timeString, TIME_PICKER_FORMAT).format(
                      TIME_PICKER_FORMAT
                    )
                  );
                  resetFields(["from_time"]);
                  validateFields(["from_time"]);
                }}
                format={TIME_PICKER_FORMAT}
              />
            )}
          </Form.Item>
        </Input.Group>

        <Form.Item
          className="form-disable-position"
          label={t("report_result.date_range")}>
          {getFieldDecorator("dates", {
            rules: [
              {
                required: true,
                message: t("report_template.select_date_range_required"),
              },
            ],
            initialValue: formData.dates,
            getValueFromEvent: (value) => {
              setFormField(
                "dates",
                value.map((e) => e.format(DATE_FORMAT.day_only_backend))
              );
              return value;
            },
          })(
            <SiwaveMultipleDatePicker
              placeholder={t("report_result.date_range")}
              format={DATE_FORMAT.day_only_backend}
            />
          )}
        </Form.Item>
        <Input.Group className="flex w-full justify-space-beetwen">
          <Form.Item label={t("optimization.bias")}>
            {getFieldDecorator("bias", {
              rules: [
                {
                  required: true,
                  message: t("field_required", {
                    field: t("optimization.bias"),
                  }),
                },
                {
                  validator(_rule, value, callback) {
                    if (value < 0) {
                      callback(
                        t("min_value", {
                          field: t("intersection_config.cycle_length"),
                          min: 0,
                        })
                      );
                      return;
                    }
                    callback();
                  },
                },
              ],
              getValueFromEvent: (value) => {
                setFormField("bias", value);
                setFormField("bias_direction", null);
                resetFields(["bias_direction"]);
                return value;
              },
              initialValue: formData.bias,
            })(<InputNumber className="w-128" />)}
          </Form.Item>

          <Form.Item label={t("optimization.bias_direction")} colon={false}>
            {getFieldDecorator("bias_direction", {
              rules:
                formData.bias === 0
                  ? []
                  : [
                      {
                        required: true,
                        message: t("field_required", {
                          field: t("optimization.bias_direction"),
                        }),
                      },
                    ],
              getValueFromEvent: (value) => {
                setFormField("bias_direction", value);
                return value;
              },
              initialValue: formData.bias_direction,
            })(
              <Select
                style={{ width: 128 }}
                disabled={formData.bias === 0}
                placeholder={t("optimization.bias_direction")}>
                <Select.Option
                  key={BIAS_DIRECTION.DOWNSTREAM}
                  value={BIAS_DIRECTION.DOWNSTREAM}>
                  {t("downstream")}
                </Select.Option>
                <Select.Option
                  key={BIAS_DIRECTION.UPSTREAM}
                  value={BIAS_DIRECTION.UPSTREAM}>
                  {t("upstream")}
                </Select.Option>
              </Select>
            )}
          </Form.Item>
        </Input.Group>
      </>
    );
  }
}

export default withRouter(withTranslation()(CorridorOffsetFilter));
