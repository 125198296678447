import { action, runInAction } from "mobx";
import BaseStore from "./BaseStore";
import AlarmRuleSubscriberService from "../services/alarm/detail/AlarmRuleSubscriberService";
import { RESOURCE_LIST } from "utils/constants";

class AlarmRuleSubscriberStore extends BaseStore {
  constructor(id, parentStore) {
    super(new AlarmRuleSubscriberService(id, parentStore), parentStore);
    this.resourceName = RESOURCE_LIST.ALARM_RULE;
    this.filterData = {
      ...this.filterData,
      ...{
        text: "",
        status: "null",
      },
    };
  }

  @action updateSubscriberAdd = (items) => {
    const ids = items.map((e) => e.id);
    this.loading = true;
    this.moduleService?.add(
      ids,
      () => {
        this.getData();
        runInAction(() => {
          this.loading = false;
        });
      },
      () => {
        runInAction(() => {
          this.loading = false;
        });
      }
    );
  };

  @action updateSubscriberDelete = (ids) => {
    if (ids[0] == null) ids = this.selectedItems;
    this.loading = true;
    this.moduleService?.delete(
      ids,
      () => {
        this.getData();
        runInAction(() => {
          this.loading = false;
        });
      },
      () => {
        runInAction(() => {
          this.loading = false;
        });
      }
    );
  };

  @action addItem = (items, cb) => {
    items.map((e) => {
      if (!this.listData.map((child_e) => child_e?.id).includes(e?.id)) {
        this.listData.push(e);
      }
    });
    cb && cb();
  };

  @action removeItem = (ids, cb) => {
    if (ids[0] == null) ids = this.selectedItems;
    this.listData = this.listData.filter((e) => !ids?.includes(e.id));
    cb && cb();
  };
}

export default AlarmRuleSubscriberStore;
