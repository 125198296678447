import React from "react";
import { Badge, Button, Col, Input, Row, Table, Tag, Tooltip } from "antd";
import { inject, observer } from "mobx-react";
import BaseManagementComponents from "components/BaseManagementComponents";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ACTIONS_LIST, AVAILABLE, INTERSECTION_SCOPE, RESOURCE_LIST, } from "utils/constants";
import AddMoreIntersection from "./AddMoreIntersection";
import SpinLoading from "components/PrivateLayout/SpinLoading";
import "./style.scss";

/**
 * Component for showing details of the user.
 *
 * @component
 */
@inject("rootStore")
@observer
class SelectIntersectionTab extends BaseManagementComponents {
  constructor(props) {
    const { context_id, t } = props;
    super(props.currentStore, props);
    this.resourceList = props.resourceList;
    this.moduleName = props.moduleName;
    this.resourceName = !context_id
      ? RESOURCE_LIST[this.resourceList?.main]
      : RESOURCE_LIST[this.resourceList.detailsIntersection];
    this.enableCreateButton = false;
    this.columns = this.getTableColumns();
    this.headerButtonActions = [
      {
        key: "create",
        className: "create-button",
        title: this.props.t(this.moduleName + ".add_intersection_btn"),
        icon: "plus",
        onClick: () => {
          this.currentStore?.showCreateOrEditForm(
            this.props.t(this.moduleName + ".add_intersection_btn")
          );
        },
        isALink: this.enableRedirectPage,
        resource: !context_id
          ? RESOURCE_LIST[this.resourceList?.main]
          : RESOURCE_LIST[this.resourceList.detailsIntersection],
        permission: !context_id ? ACTIONS_LIST.CREATE : ACTIONS_LIST.ADD,
        linkTo: this.moduleName + "/create",
      },
    ];
    this.buttonActions = [
      {
        key: "delete_all",
        title: t(this.moduleName + ".remove_all"),
        action: this.submitDelete,
        resource: !context_id
          ? RESOURCE_LIST[this.resourceList?.main]
          : RESOURCE_LIST[this.resourceList.detailsIntersection],
        permission: !context_id ? ACTIONS_LIST.CREATE : ACTIONS_LIST.REMOVE,
        confirm: true,
        confirmTitle: t(this.moduleName + ".delete_confirm_title"),
        confirmContent: t(this.moduleName + ".delete_confirm_content"),
      },
    ];
    this.tableInlineActions = [
      {
        key: "delete_all",
        tooltip: t(this.moduleName + ".remove"),
        action: this.handleTableAction,
        visible: (item) => {
          return !item?.disable;
        },
        resource: !context_id
          ? RESOURCE_LIST[this.resourceList?.main]
          : RESOURCE_LIST[this.resourceList.detailsIntersection],
        permission: !context_id ? ACTIONS_LIST.CREATE : ACTIONS_LIST.REMOVE,
        icon: "delete",
        passingData: (item) => {
          return ["delete_all", item?.id];
        },
      },
    ];
  }

  addMoreInterChild = null;
  renderTableRows = () => {
    let columns = [...this.columns];

    this.hasRowSelection = this.tableInlineActions.length > 0;
    return columns;
  };
  getTableColumns = () => {
    const { t, context_id } = this.props;
    let columns = [
      {
        title: t("intersection.name"),
        key: "name",
        ellipsis: true,
        render: (item) => <Button type="link">{item.name}</Button>,
        sorter: context_id ? true : false,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: t("intersection.status"),
        dataIndex: "status",
        key: "status",
        render: (status = AVAILABLE) => (
          <Tag
            color={status === AVAILABLE ? "green" : "volcano"}
            key={status}
            className="tag-status">
            <Badge color={status === AVAILABLE ? "green" : "volcano"}/>
            {status?.toUpperCase()}
          </Tag>
        ),
        // sorter: context_id ? true :  false,
        // sortDirections: ["descend", "ascend"],
        ellipsis: true,
      },
    ];
    return columns;
  };

  componentDidMount() {
    const { childRef } = this.props;
    if (this.currentStore.context_id) {
      this.currentStore?.getData();
    }
    childRef(this);
  }

  //override

  renderHeaderButtonActions = () => {
    const {
      rootStore: { myStore },
    } = this.props;
    let defaultHeaderActions = [
      {
        key: "create",
        className: "create-button",
        title: this.props.t(this.moduleName + ".create"),
        icon: "plus",
        onClick: () => {
          this.currentStore?.showCreateOrEditForm(
            this.props.t(this.moduleName + ".create_form_title")
          );
        },
        isALink: this.enableRedirectPage,
        permission: ACTIONS_LIST.CREATE,
        linkTo: this.moduleName + "/create",
      },
    ];
    let allButton = [...this.headerButtonActions];
    if (this.enableCreateButton) {
      allButton = [...this.headerButtonActions, ...defaultHeaderActions];
    }
    let headerButtons = [];
    allButton.map((e) => {
      if (
        myStore.hasPermission(
          e.permission,
          e.resource ? e.resource : this.resourceName,
          true
        ) &&
        this.currentStore?.scope === INTERSECTION_SCOPE.SPECIFIC
      ) {
        if (e.isALink) {
          headerButtons.push(
            <Link key={e.key} to={e.linkTo}>
              <Button className={e.className} icon={e.icon}>
                {e.title}
              </Button>
            </Link>
          );
        } else {
          headerButtons.push(
            <Button
              key={e.key}
              className={e.className}
              icon={e.icon}
              onClick={e.onClick}>
              {e.title}
            </Button>
          );
        }
      }
    });
    if (this.currentStore?.selectedItems?.length > 0) {
      headerButtons = [];
    }
    return headerButtons;
  };
  //render filter component of the list
  renderFilterComponent = () => {
    const { context_id, t } = this.props;
    return (
      <div className="wrap-filter">
        <Col span={16}>
          <Tooltip
            placement="top"
            title={t(this.moduleName + ".search_inter_tooltip")}>
            <Input
              size="large"
              defaultValue={this.currentStore?.filterData?.text}
              onChange={(e) =>
                this.currentStore?.handleFilterDataChange(
                  "text",
                  e.target.value,
                  context_id ? true : false
                )
              }
              placeholder={"Search"}
              maxLength={128}
            />
          </Tooltip>
        </Col>
      </div>
    );
  };

  //render detail content ll show in right drawer
  renderDetailData = () => {
    const {
      context_id,
      idFieldName,
      agency_id,
      rootStore: {
        myStore,
        commonStore: { isSuperAdmin },
      },
      addMoreIntersectionStore,
    } = this.props;
    const currentAgency = myStore.currentAgency;
    if (isSuperAdmin()) {
      if (context_id) {
        addMoreIntersectionStore.filterData.agency_id = agency_id;
        addMoreIntersectionStore.filterData[idFieldName] = context_id;
      } else {
        addMoreIntersectionStore.filterData.agency_id = agency_id;
      }
    } else {
      addMoreIntersectionStore.filterData.agency_id = currentAgency?.agency_id;
    }
    if (context_id) {
      addMoreIntersectionStore.filterData[idFieldName] = context_id;
    }
    return (
      <AddMoreIntersection
        isShowColumnStatus={true}
        selectedItems={context_id ? [] : this.currentStore.listData}
        addMoreIntersectionStore={addMoreIntersectionStore}
        childRef={(ref) => {
          this.defailFromRef = ref;
          this.addMoreInterChild = ref;
        }}
        id={context_id}></AddMoreIntersection>
    );
  };

  removeAllIntersection = () => {
    const { context_id, addMoreIntersectionStore } = this.props;
    this.currentStore.setScope(INTERSECTION_SCOPE.SPECIFIC);
    if (context_id) {
      this.currentStore?.switchIntersectionScopeEditMode(
        INTERSECTION_SCOPE.SPECIFIC,
        "*",
        addMoreIntersectionStore.removeExcludeIdsFilter
      );
    } else {
      this.currentStore?.switchIntersectionScopeCreateMode(
        INTERSECTION_SCOPE.SPECIFIC
      );
      this.currentStore.resetListIdAllData();
      addMoreIntersectionStore.handleFilterDataReset({ exclude_int_ids: null });
    }

    this.clearAllSelectedItem();
  };
  //add intersection
  addSpecificIntersection = () => {
    const { addMoreIntersectionStore } = this.props;
    let context_id = this.currentStore.context_id;
    const newList = addMoreIntersectionStore?.getSelectedItems();
    if (context_id) {
      this.currentStore?.updateIntersectionAdd(
        newList,
        this.resetSelectedItemInListAvailable
      );
      this.handleDrawerOnClose(true);
    } else {
      this.currentStore?.addItem(newList, () => {
        this.handleDrawerOnClose(true);
      });
      this.currentStore.setListDataSelected(
        addMoreIntersectionStore.selectedItems
      );
      addMoreIntersectionStore.handleFilterDataReset({
        exclude_int_ids: this.currentStore.listIdAllData,
      });
    }
    this.clearAllSelectedItem();
  };
  removeSpecificIntersection = () => {
    const { context_id, addMoreIntersectionStore } = this.props;
    this.currentStore.setScope(INTERSECTION_SCOPE.SPECIFIC);
    if (context_id) {
      this.currentStore?.updateIntersectionDelete(
        this.props.currentStore.selectedItems,
        addMoreIntersectionStore.getData
      );
    } else {
      this.currentStore.removeItem(this.currentStore.selectedItems);
      this.currentStore.setFilterDataSelected(this.currentStore.selectedItems);
      addMoreIntersectionStore.handleFilterDataReset({
        exclude_int_ids: this.currentStore.listIdAllData,
      });
    }
    this.clearAllSelectedItem();
  };

  addAllIntersection = () => {
    const { agency_id, context_id, addMoreIntersectionStore } = this.props;
    this.currentStore.setScope(INTERSECTION_SCOPE.ALL);
    if (!context_id) {
      this.currentStore?.switchIntersectionScopeCreateMode(
        INTERSECTION_SCOPE.ALL,
        agency_id,
        () =>
          addMoreIntersectionStore.handleFilterDataReset({
            exclude_int_ids: this.currentStore.listIdAllData,
          })
      );
    } else {
      this.currentStore?.switchIntersectionScopeEditMode(
        INTERSECTION_SCOPE.ALL,
        "*",
        addMoreIntersectionStore.getData
      );
    }
    this.clearAllSelectedItem();
  };

  resetSelectedItemInListAvailable = () => {
    const { addMoreIntersectionStore } = this.props;
    addMoreIntersectionStore.clearSelectedItems();
    addMoreIntersectionStore.getData();
  };

  clearAllSelectedItem = () => {
    const { addMoreIntersectionStore } = this.props;
    addMoreIntersectionStore.clearSelectedItems();
    this.currentStore.clearSelectedItems();
  };

  //submit delete intersection from alarm rule
  submitDelete = (item) => {
    const { context_id } = this.props;
    if (context_id) {
      this.currentStore?.updateIntersectionDelete([item]);
    } else {
      this.currentStore?.removeItem([item]);
    }
  };

  //add intersection function

  render() {
    const { t, context_id } = this.props;
    let columns = this.renderTableRows();

    let rowSelection = null;
    //passing data of table
    const data = context_id
      ? this.currentStore.listData
      : this.currentStore?.getListCreateIntersection;
    //binding paging info of table
    const pagination = this.currentStore?.filterData?.pagination;

    if (
      this.forceEnableRowSelection ||
      (this.enableRowSelection && this.hasRowSelection)
    ) {
      rowSelection = {
        selectedRowKeys: this.currentStore?.selectedItems,
        onChange: this.currentStore?.onSelectRowChange,
      };
    }
    const disableRmSpecific = this.currentStore.selectedItems?.length <= 0;
    const disableRmAllIntersection = this.currentStore?.listData?.length <= 0;
    const disableAddSpecific = this.props.addMoreIntersectionStore?.selectedItems?.length <= 0;
    const disableAddAllIntersection = this.props.addMoreIntersectionStore?.listData?.length <= 0;
    return (
      <div className="wrap-alarm-rule-intersection">
        <Row>
          <Col xl={11} md={24}>
            <b style={{ paddingLeft: "26px" }}>
              {t("intersection.label_list_all_intersection")}
            </b>
            <div className="wrap-form-detail">
              {this.renderDetailData && this.renderDetailData()}
            </div>
          </Col>
          <Col xl={2} className="icon-group icon-group__intersection-tab">
            <Row>
              <Tooltip title={t("intersection_tab.add")}>
                <Button
                  size="large"
                  disabled={disableAddSpecific}
                  icon="arrow-right"
                  onClick={() => this.addSpecificIntersection()}
                  className="icon"
                />
              </Tooltip>
            </Row>
            <Row>
              <Tooltip title={t("intersection_tab.remove")}>
                <Button
                  size="large"
                  disabled={disableRmSpecific}
                  icon="arrow-left"
                  onClick={() => this.removeSpecificIntersection()}
                  className="icon"
                />
              </Tooltip>
            </Row>
            <Row>
              <Tooltip title={t("intersection_tab.add-all")}>
                <Button
                  size="large"
                  disabled={disableAddAllIntersection}
                  icon="vertical-left"
                  onClick={() => this.addAllIntersection()}
                  className="icon"
                />
              </Tooltip>
            </Row>
            <Row>
              <Tooltip title={t("intersection_tab.remove-all")}>
                <Button
                  size="large"
                  disabled={disableRmAllIntersection}
                  icon="vertical-right"
                  onClick={() => this.removeAllIntersection()}
                  className="icon"
                />
              </Tooltip>
            </Row>
          </Col>
          <Col xl={11} md={24}>
            <b>{t("intersection.label_list_selected_intersection")}</b>
            <Row
              style={{
                marginBottom: "10px",
                height: "40px",
                marginTop: "36px",
              }}>
              {this.renderFilterComponent()}
            </Row>
            <Row>
              <Table
                // scroll={{ x: true }}
                rowClassName={(record, index) => {
                  if (index % 2 === 0) return "odd-row";
                  else return "even-row";
                }}
                className="spm-table"
                rowSelection={rowSelection}
                columns={columns}
                rowKey={this.rowKey}
                dataSource={data}
                pagination={
                  context_id
                    ? pagination
                    : {
                      pageSize: Number(process.env.REACT_APP_PAGE_SIZE),
                    }
                }
                loading={
                  this.currentStore?.loading && {
                    indicator: <SpinLoading visible={true}></SpinLoading>,
                  }
                }
                onRow={this?.onRow}
                onChange={this.currentStore?.handleTableChange}
              />
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withTranslation()(SelectIntersectionTab);
