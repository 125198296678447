import { Button, Form, PageHeader, Tabs } from "antd";
import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import {ACTIONS_LIST, RESOURCE_LIST, SUPPER_ADMIN_GROUP} from "utils/constants";
import AgencyIntersectionStore from "../../../stores/AgencyIntersectionStore";
import AgencySettingStore from "../../../stores/AgencySettingStore";
import AgencyUserStore from "../../../stores/AgencyUserStore";
import GeneralTab from "./GeneralTab";
import IntersectionTab from "./IntersectionTab";
import SettingTab from "./SettingTab";
import "./style.scss";
import UsersTab from "./UsersTab";
import LicenseTab from "./LicenseTab";
import AgencyLicenseStore from "../../../stores/AgencyLicenseStore";
const { TabPane } = Tabs;
const GeneralTabIndex = "1";
const LicenseTabIndex = "2";
const UsersTabIndex = "3";
const IntersectionTabIndex = "4";
const SettingTabIndex = "5";
/**
 * Component for showing agency detail information or intersection belong to agency or user tab.
 *
 * @component
 */
@inject("rootStore")
@observer
class EditAgency extends Component {
  /**
   * contructor of component
   * @param   {object} props  props of component
   */
  constructor(props) {
    super(props);
    this.currentStore = props.rootStore.agencyStore;
    this.currentAgencyId = this.props?.match?.params?.id;
    this.isGeneralTabEditable = !this.props.location?.state?.disableForm;
    this.agencyUserStore = new AgencyUserStore(
      this.currentAgencyId,
      this.props.rootStore
    );
    this.agencySettingStore = new AgencySettingStore(
      this.currentAgencyId,
      this.props.rootStore
    );
    this.agencyIntersectionStore = new AgencyIntersectionStore(
      this.currentAgencyId,
      this.props.rootStore
    );
    this.agencyLicenseStore = new AgencyLicenseStore(
        this.currentAgencyId,
        this.props.rootStore
    );
    this.state = {
      activeKey: GeneralTabIndex,
    };
    this.panes = [
      {
        title: props.t("agencydetail.general_tab"),
        key: GeneralTabIndex,
        closable: false,
      },
      {
        title: props.t("agencydetail.license_tab"),
        key: LicenseTabIndex,
        closable: false,
      },
      {
        title: props.t("agencydetail.setting_tab"),
        key: SettingTabIndex,
        closable: false,
      },
      {
        title: props.t("agencydetail.users_tab"),
        key: UsersTabIndex,
        closable: false,
      },
      {
        title: props.t("agencydetail.intersections_tab"),
        key: IntersectionTabIndex,
        closable: false,
      },
    ];
    this.generalTabRef = null;
  }

  getButtonActions = () => {
    const { t, rootStore } = this.props;
    let defaultGeneralActions = [
      <Button key="cancel" onClick={this.handleCancelButton}>
        {t("agency.cancel")}
      </Button>,
    ];
    if (
      rootStore.myStore.hasPermission(
        ACTIONS_LIST.EDIT,
        RESOURCE_LIST.AGENCY_DETAIL,
        true
      )
      && (this.state.activeKey !== LicenseTabIndex ||
            SUPPER_ADMIN_GROUP.includes(rootStore.myStore?.getCurrentRoleName()))
    ) {
      defaultGeneralActions.push(
        <Button
          key="save"
          type="primary"
          loading={
            this.state.activeKey === GeneralTabIndex
              ? this.agencyUserStore?.loading
              : this.agencySettingStore.loading
          }
          disabled={
            this.state.activeKey === SettingTabIndex
              ? this.agencySettingStore?.errorList
              : false
          }
          onClick={this.handleSaveButton}
        >
          {t("agency.save")}
        </Button>
      );
    }
    return defaultGeneralActions;
  };
  /**
   * handleCancelButton
   * this function handle cancel button on header
   * before leave page check form is changed . if data chagned show confirm of chaning data will be lost
   * @return  {null}
   */
  handleCancelButton = () => {
    this.generalTabRef &&
      this.generalTabRef.checkFormClearToLeave(() => {
        this.props?.history?.goBack();
      });
  };

  /**
   * handleSaveButton
   * this function handle save button on header
   * it will call generalTab handleSubmitbutton
   * @param {object} e Click Event
   * @return  {null}
   */
  handleSaveButton = (e) => {
    if (this.state.activeKey === GeneralTabIndex) {
      this.generalTabRef && this.generalTabRef.handleSubmit(e);
    }
    if (this.state.activeKey === SettingTabIndex) {
      this.agencySettingStore && this.agencySettingStore.handleSubmit();
    }
    if (this.state.activeKey === LicenseTabIndex) {
      this.agencyLicenseStore && this.agencyLicenseStore.handleSubmit();
    }
  };

  /**
   * renderGeneralTab
   * this function return the detail data of agency
   * @return  {Component}
   */
  renderGeneralTab() {
    return (
      <GeneralTab
        agency_id={this.currentAgencyId}
        editable={this.isGeneralTabEditable}
        childRef={(ref) => (this.generalTabRef = ref)}
      />
    );
  }

  /**
   * renderAgencyDetailUser
   * this function return the detail data of user
   * @return  {Component}
   */
  renderAgencyDetailUser() {
    return (
      <UsersTab
        store={this.agencyUserStore}
        agency_id={this.currentAgencyId}
        editable={this.isGeneralTabEditable}
      />
    );
  }

  /**
   * renderIntersectionTab
   * this function return the detail data of intersection that belong to agency
   * @return  {Component}
   */
  renderIntersectionTab() {
    return (
      <IntersectionTab
        store={this.agencyIntersectionStore}
        agency_id={this.currentAgencyId}
        editable={this.isGeneralTabEditable}
      />
    );
  }

  /**
   * renderAgencySettingTab
   * this function return the detail data of intersection that belong to agency
   * @return  {Component}
   */
  renderAgencySettingTab() {
    return (
      <SettingTab
        store={this.agencySettingStore}
        agency_id={this.currentAgencyId}
        editable={this.isGeneralTabEditable}
      />
    );
  }
  /**
   * renderLicenseTab
   * this function return the detail data of agency
   * @return  {Component}
   */
  renderLicenseTab() {
    return (
        <LicenseTab
            store={this.agencyLicenseStore}
            agency_id={this.currentAgencyId}
            editable={this.isGeneralTabEditable}
        />
    );
  }
  /**
   * onChange
   * this function change active tab
   * @return  {Component}
   */
  onChange = (activeKey) => {
    if (activeKey == GeneralTabIndex || activeKey == SettingTabIndex || activeKey == LicenseTabIndex) {
      this.setState({ activeKey });
    } else {
      this.generalTabRef &&
        this.generalTabRef.checkFormClearToLeave(() => {
          this.setState({ activeKey });
        });
    }
  };

  /**
   * renderAgencyDetail
   * this function return the detail data of each tab
   * @return  {Component}
   */
  renderAgencyDetail() {
    if (this.state.activeKey == GeneralTabIndex) {
      return this.renderGeneralTab();
    }
    if (this.state.activeKey == IntersectionTabIndex) {
      return this.renderIntersectionTab();
    }
    if (this.state.activeKey == UsersTabIndex) {
      return this.renderAgencyDetailUser();
    }
    if (this.state.activeKey == SettingTabIndex) {
      return this.renderAgencySettingTab();
    }
    if (this.state.activeKey == LicenseTabIndex) {
      return this.renderLicenseTab();
    }
  }

  /**
   * componentDidMount
   * clear all error list
   */
  componentDidMount = () => {
    const { t } = this.props;
    document.title = t("agency.page-title");
  };

  /**
   * render
   *
   * @return  {Component}
   */
  render() {
    const { 
      t,
      rootStore: {
        agencyStore: filterData
      } 
    } = this.props;
    
    let pageHeaderClass = "edit-agency-page";
    let extraButtons = null;
    if (
      this.state.activeKey === GeneralTabIndex ||
      this.state.activeKey === SettingTabIndex ||
      this.state.activeKey === LicenseTabIndex
    ) {
      pageHeaderClass += " button-float-left";
      extraButtons = this.getButtonActions();
    }
    return (
      <PageHeader
        title={t("agency.edit_page") + ' - ' + filterData?.itemData?.name}
        extra={extraButtons}
        className={pageHeaderClass}
      >
        <Tabs
          className="edit-agency-tabs"
          type="card"
          onChange={this.onChange}
          activeKey={this.state.activeKey}
        >
          {this.panes?.map((pane) => (
            <TabPane tab={pane.title} key={pane.key} closable={pane.closable}>
              {this.renderAgencyDetail()}
            </TabPane>
          ))}
        </Tabs>
      </PageHeader>
    );
  }
}

const EditAgencyForm = Form.create({ name: "edit_agency" })(EditAgency);

export default withRouter(withTranslation()(EditAgencyForm));
