import React from "react";
import * as d3 from "d3";
import moment from 'moment';
import { FONT_STRING_CAVAS } from "utils/constants";
import BaseRingChart from "../BaseRingChart";

class RingChartActual extends BaseRingChart {
  constructor(props) {
    super(props);
    this.margin = {left: 180, right: 300};
  }

  getInitialAxisActual = () => {
    this.earliestTime = this.data.start_time;
    this.latestTime = this.data.end_time;

    if (this.isLastCycle) {
      const endTime = moment.duration(moment(this.lastCycleEnds).format("HH:mm:ss").toString()) - moment.duration(moment(this.earliestTime).format("HH:mm:ss").toString());
      this.rangeX = [0, endTime]
    } else {
      this.rangeX = [0, this.data.duration*1000 || moment(this.latestTime) - moment(this.earliestTime)]
    }
    
    this.rangeY = [0, 100];
    
    this.xScale = d3
      .scaleLinear()
      .domain(this.rangeX)
      .range([0, this.width - this.margin.left - this.margin.right]);
    this.yScale = d3
      .scaleLinear()
      .domain(this.rangeY)
      .range([this.height, 0]);
  };

  drawLabelsActual() {
    let ctx = this.ctx;
    ctx = this.canvas.node().getContext('2d');

    //Data labels:
    ctx.textAlign = "start";
    ctx.font = `14px ${FONT_STRING_CAVAS}`;
    ctx.textAlign = "start";
    ctx.fillStyle = "#606060";

    const noCycle = 'Cycle: '
    ctx.fillText(noCycle, -this.margin.left, 45);
    // ctx.fillText(`Start time: ${moment(this.data.start_time).format("HH:mm:ss")}`, -this.margin.left, 45);
    // ctx.fillText(`End time: ${moment(this.data.end_time).format("HH:mm:ss")}`, -this.margin.left, 69);
    ctx.fillText(`Plan: ${this.data.plan}`, -this.margin.left, 69);
    
    ctx.fillText(
      `Skipped phase: ${
        this.data.ring1.skipped_phases.length ? 
          `[${this.data.ring1?.skipped_phases.map(item => `P${item.phase}: ${item.duration}s`).join(', ')}]` 
            : 
          "None"
      }`,
      this.width - this.margin.left - this.margin.right + 10, 
      45
    );
    ctx.fillText(
      `Skipped phase: ${
        this.data.ring2.skipped_phases.length ?
         `[${this.data.ring2?.skipped_phases.map(item => `P${item.phase}: ${item.duration}s`).join(', ')}]`
          :
        "None"
      }`, 
      this.width - this.margin.left - this.margin.right + 10, 
      69
    );

    ctx.font = `14px ${FONT_STRING_CAVAS}`;
    ctx.fillText(`${this.props.cycleNumber}`, -this.margin.left + ctx.measureText(noCycle).width, 45);

    //Ring 1 and ring 2 labels:
    ctx.font = `14px ${FONT_STRING_CAVAS}`;
    ctx.fillStyle = "#606060";
    ctx.fillText(`Ring 1 - ${(!this.data.ring1.duration) ? `NA` : `${this.data.ring1.duration}s`}`, -100, 45);
    ctx.fillText(`Ring 2 - ${(!this.data.ring2.duration) ? `NA` : `${this.data.ring2.duration}s`}`, -100, 69);

    ctx.font = `12px ${FONT_STRING_CAVAS}`;
    ctx.fillStyle = "#111111";
    
    //Numbers on x axis:
    const calculateNumberTicks = (this.width < 800) ? 2 : Math.floor(5/650 * this.width);

    let xTickCount = calculateNumberTicks,
      xTicks = Array.from(Array(xTickCount).keys());
  
    const xTickValues = xTicks.map((value) => {
      let oldTime = moment(this.earliestTime)
      let timeDiff = moment.duration(value * this.rangeX[1]/(xTickCount-1), 'ms')
      let add = moment(oldTime).add(timeDiff)
      return `${add.format("H:mm:ss")}`
    })

    xTicks.forEach((d,i) => {
      ctx.textAlign = "center";
      ctx.fillText(xTickValues[i], this.xScale(i*this.rangeX[1]/(xTickCount-1)), 95);
    })
  }

  componentDidMount() {
    this.drawCanvas();
    this.getInitialAxisActual();
    this.drawRings();
    this.drawLabelsActual();
    this.drawScatterLine();
    if (this.data.events) {
      this.drawEvents();
      this.drawHiddenCanvas();
    }  
  }

  render() {
    return(
      <div className="split-monitor-container">
        <div ref={this.areaRef} width={this.width} className="hidden-content-split-monitor"></div>
        <div className="ring-chart" ref={this.chartRef} style={{width:"100%", height: "100%"}}></div>
      </div>
    )
  }
}

export default RingChartActual;