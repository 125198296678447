import { Avatar, Button, Form, Input } from "antd";
import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import InputFiles from "react-input-files";
import { AVATAR_FILE_SIZE, AVATAR_FILE_TYPE } from "utils/constants";
import helper from "../../../../../utils/helper";

/**
 * Component for user side drawer.
 * user data information tab
 *
 * @component
 */
@inject("rootStore")
@observer
class UserDataForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tempAvatar: null,
    };
    this.formFields = ["avatar", "name", "phone"];
  }
  /**
   * isFormChanged
   * check current form is changed data with current setting formFields
   * @return  {boolean} true/false
   */
  isFormChanged = () => {
    return this.props.form?.isFieldsTouched(this.formFields);
  };
  /**
   * componentDidMount
   * binding this component to parent childRef
   * in this case click on save button calling handlesubmit
   * incase leaving page call check form data changed
   * @return  {null} -
   */
  componentDidMount() {
    const { childRef } = this.props;
    childRef(this);
  }

  /**
   * handleSubmit
   *
   * @return  {Object}
   */
  handleSubmit = () => {
    const {
      rootStore: { myStore },
    } = this.props;
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let submitValue = {
          ...values,
        };
        myStore?.saveUserProfile(
          submitValue,
          () => {
            myStore?.updateCurrentProfileInfo(submitValue);
            myStore?.setVisibleUserSidePanel(false);
          },
          (errors) => {
            errors?.map((error) => {
              let message = error?.message;
              let err = {};
              err[error?.field] = {
                value: values[error?.field],
                errors: [new Error(message)],
              };
              this.props.form?.setFields(err);
            });
          }
        );
      }
    });
  };

  /**
   * avatarValidation
   * validation rule of Avatar
   * @param {Object} rule rule infomation
   * @param {Object} data new value( return from getValueFromEvent function )
   * @param {Function} callback notifi for getFieldDecorator pass or fail
   * @return  {File}
   */
  avatarValidation = (rule, data, callback) => {
    if (data) {
      const { t } = this.props;
      const fileSize = data?.size;
      const fileType = data?.type;
      if (fileSize > AVATAR_FILE_SIZE) {
        callback(t("profile.image_over_size"));
      } else if (!AVATAR_FILE_TYPE.includes(fileType)) {
        callback(t("profile.image_worng_type"));
      } else {
        helper
          .fileToBase64(data)
          .then((result) => {
            this.setState({ tempAvatar: result });
          })
          .catch(() => {
            //@todo: handle error
          });
      }
    }

    callback();
  };

  /**
   * getValueFromEvent
   * define how getFieldDecorator get value form Input files filed
   * @param {Array} value Input files element
   *
   * @return  {File}
   */
  getValueFromEvent = (value) => {
    return value?.target?.files[0];
  };
  /**
   * render
   *
   * @return  {Component}
   */
  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      rootStore: { myStore },
      t,
    } = this.props;
    let user = myStore?.getCurrentProfile();
    const avaLetter = helper
      .getFirstsLetterFromWords(user?.name)
      ?.substring(0, 2);
    return (
      <div className="wrap-user-data-form">
        <Form>
          <Form.Item>
            {getFieldDecorator("avatar", {
              getValueFromEvent: this.getValueFromEvent,
              rules: [{ validator: this.avatarValidation }],
            })(
              <div className="avatar">
                <Avatar
                  size={86}
                  src={
                    this.state.tempAvatar
                      ? this.state.tempAvatar
                      : user?.avatar_url
                  }
                >
                  {avaLetter}
                </Avatar>
                <div className="avatar-change-button">
                  <InputFiles multiple={false} onChange={() => {}}>
                    <Button type="link" icon="edit">
                      {t("profile.change_avatar")}
                    </Button>
                  </InputFiles>
                </div>
              </div>
            )}
          </Form.Item>
          <Form.Item label={t("profile.name")}>
            {getFieldDecorator("name", {
              initialValue: user?.name,
              rules: [{ required: true, message: t("profile.name_required") }],
            })(<Input maxLength={255} placeholder={t("profile.name")}></Input>)}
          </Form.Item>
          <Form.Item label={t("profile.email")}>{user?.email}</Form.Item>
          <Form.Item label={t("profile.phone")}>
            {getFieldDecorator("phone", {
              initialValue: user?.phone,
              rules: [{ required: true, message: t("profile.phone_required") }],
            })(
              <Input maxLength={255} placeholder={t("profile.phone")}></Input>
            )}
          </Form.Item>
        </Form>
      </div>
    );
  }
}

const UserDataTabForm = Form.create({ name: "user-data-form" })(UserDataForm);

export default withTranslation()(UserDataTabForm);
