import { Button, Form, Select } from "antd";
import { inject, observer } from "mobx-react";
import moment from "moment";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import {
  DATE_FORMAT,
  OPTIMIZATION_TYPE,
  TIME_PICKER_FORMAT,
} from "utils/constants";
import CorridorOffsetFilter from "./CorridorOffsetFilter";
import HistoricTimeSpaceFilter from "./HistoricTimeSpaceFilter";
import MapPopup from "./MapPopup";
import OffsetOptimizationFilter from "./OffsetOptimizationFilter";
import "./style.scss";
import TrafficProfileFilter from "./TrafficProfileFilter";

const Option = Select.Option;
const MAP = "map";

@inject("rootStore")
@observer
class OptimizerFIlter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowFullMap: false,
    };
  }

  componentDidMount() {
    const {
      rootStore: {
        optimizerStore: { getAllFilterData },
      },
    } = this.props;
    getAllFilterData();
  }

  checkVaildTime = (time, value, callback) => {
    const {
      t,
      rootStore: {
        optimizerStore: { formData },
      },
    } = this.props;
    const fromTimeObject = moment(formData.start_time, DATE_FORMAT.hour);
    const toTimeObject = moment(formData.end_time, DATE_FORMAT.hour);
    if (fromTimeObject.isBefore(toTimeObject)) {
      return callback();
    } else {
      callback(t("analysis.from_time_greater_to_time"));
    }
  };
  /**
   * handleIntersectionChange
   * forcus on target marker
   * @return  {Component}
   */
  handleIntersectionChange = (intersectionId, type) => {
    const {
      form: { setFieldsValue },
      rootStore: {
        optimizerStore: { setFormField },
      },
    } = this.props;
    if (!intersectionId) {
      setFieldsValue({ intersection_id: undefined });
    } else {
      setFormField("intersection_id", intersectionId);
      if (type === MAP) {
        setFieldsValue({
          intersection_id: intersectionId,
        });
      }
    }
  };

  /**
   * handleMapChangeView
   * this function trigger when the map is change position or zoom level
   *
   * @return  {null}
   */
  handleMapChangeView = (viewport) => {
    const {
      rootStore: {
        optimizerStore: { updateCurrentZoomLevel },
      },
    } = this.props;
    if (typeof viewport?.zoom == "number") {
      updateCurrentZoomLevel(viewport?.zoom);
    }
  };
  showFullMap = () => {
    this.setState({ isShowFullMap: true });
  };
  renderExtraField = () => {
    const {
      rootStore: {
        optimizerStore: { formData },
      },
      form,
      t,
    } = this.props;

    switch (formData.type) {
      case OPTIMIZATION_TYPE.OFFSET_OPTIMIZATION:
        return (
          <OffsetOptimizationFilter
            handleIntersectionChange={this.handleIntersectionChange}
            showFullMap={this.showFullMap}
            form={form}
            t={t}
          />
        );
      case OPTIMIZATION_TYPE.TRAFFIC_PROFILE:
        return (
          <TrafficProfileFilter
            handleIntersectionChange={this.handleIntersectionChange}
            showFullMap={this.showFullMap}
            form={form}
            t={t}
          />
        );
      case OPTIMIZATION_TYPE.HISTORIC_TIME_SPACE:
        return <HistoricTimeSpaceFilter form={form} t={t} />;
      case OPTIMIZATION_TYPE.CORRIDOR_OFFSET_OPTIMIZATION:
        return <CorridorOffsetFilter form={form} t={t} />;

      default:
        return <></>;
    }
  };

  render() {
    const {
      t,
      rootStore: {
        commonStore,
        optimizerStore: {
          listAgencies,
          formData,
          listOptimizationTypes,

          loading,
          filterDataChanged,

          beginOptimize,
          resetFilter,
          setFormField,
        },
      },
      form: { getFieldDecorator, validateFields, setFieldsValue },
    } = this.props;
    return (
      <div className="wrap-optimizer-filter">
        <Form className="styled-scroll">
          {commonStore.isSuperAdmin() && listAgencies.length > 0 && (
            <Form.Item label={t("analysis.agencies")}>
              <Select
                onChange={(value) => {
                  this.handleIntersectionChange(null);
                  setFormField("agency_id", value);
                  setFormField("corridor_id", undefined);
                  // setFieldsValue({
                  //   corridor_id: undefined,
                  // });
                }}
                value={formData.agency_id}>
                {listAgencies?.map((e) => (
                  <Option value={e?.id} key={e?.id}>
                    {e?.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}
          {listOptimizationTypes.length ? (
            <Form.Item label={t("optimization.type")}>
              {getFieldDecorator("type", {
                rules: [
                  {
                    required: true,
                    message: t("analysis.optimization_required"),
                  },
                ],
                initialValue: formData.type,
              })(
                <Select
                  onChange={(value) => {
                    setFormField("start_date", formData.start_date);
                    setFormField("end_date", formData.start_date);
                    setFormField("dates", []);
                    setFormField("start_time", "00:00");
                    setFormField("end_time", "23:59");
                    setFormField("type", value);
                  }}>
                  {listOptimizationTypes?.map((e) => (
                    <Option value={e?.key} key={e?.key}>
                      {e?.name}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          ) : (
            <></>
          )}
          {this.renderExtraField()}
        </Form>
        <div className="filter-footer">
          <Button
            type="link"
            onClick={() => {
              setFieldsValue({
                start_time: moment("00:00", TIME_PICKER_FORMAT),
                end_time: moment("23:59", TIME_PICKER_FORMAT),
              });
              resetFilter();
            }}>
            {t("optimization.reset")}
          </Button>
          <Button
            loading={loading}
            disabled={!filterDataChanged}
            type="primary"
            onClick={() => {
              validateFields((err) => {
                if (!err) {
                  beginOptimize();
                }
              });
            }}>
            {t("optimization.begin_optimize")}
          </Button>
        </div>

        {/* Modal box */}
        {this.state.isShowFullMap && (
          <MapPopup
            visible={this.state.isShowFullMap}
            onMapSelect={(intersectioId) =>
              this.handleIntersectionChange(intersectioId, MAP)
            }
            onClose={() => {
              this.setState({ isShowFullMap: false });
            }}
          />
        )}
      </div>
    );
  }
}

const WrapForm = Form.create({ name: "optimization_filter" })(OptimizerFIlter);

export default withRouter(withTranslation()(WrapForm));
