import AgencyService from "../services/AgencyService";
import BaseStore from "./BaseStore";
import { action, runInAction, observable } from "mobx";
import { RESOURCE_LIST, SYSTEM_ROLE } from "utils/constants";

/**
 * Store for user management.
 *
 * @class
 */
class AgencyStore extends BaseStore {
  constructor(parentStore) {
    super(new AgencyService(parentStore), parentStore);
    this.filterData = {
      ...this.filterData,
      ...{
        text: "",
        status: "null",
        sort: "",
      },
    };
    this.resourceName = RESOURCE_LIST.AGENCY;
  }
  //dh api key for current agency
  @observable currentDHApiKey = null;

  /**
   * activeAgencies
   * this function active agencies
   * @param   {Array} data  list id of agencies, default null
   * @return  {null} -
   */
  @action activeAgencies = (data = null) => {
    this.loading = true;
    if (!data) {
      data = this.selectedItems;
    }
    this.moduleService?.updateAgenciesStatus(
      "/active",
      { ids: data },
      () => {
        this.getData();
      },
      () => {
        runInAction(() => {
          this.loading = false;
        });
      }
    );
  };

  /**
   * getAgencyApiKey
   * this function active agencies
   * operator can not call this api
   * @return  {null} -
   */
  @action getAgencyApiKey = (agencyId) => {
    const isOperator =
      this.parentStore?.myStore?.getCurrentRoleName() === SYSTEM_ROLE.OPERATOR;
    if (!isOperator) {
      this.loading = true;
      this.moduleService?.getApiKey(
        "/" + agencyId + "/dh-api-key",
        (response) => {
          runInAction(() => {
            this.loading = false;
            this.currentDHApiKey = response.api_key;
          });
        },
        () => {
          runInAction(() => {
            this.loading = false;
          });
        }
      );
    }
  };

  /**
   * deActiveAgencies
   * this function active agencies
   * @param   {Array} data  list id of agencies, default null
   * @return  {null} -
   */
  @action deActiveAgencies = (data = null) => {
    this.loading = true;
    if (!data) {
      data = this.selectedItems;
    }
    this.moduleService?.updateAgenciesStatus(
      "/inactive",
      { ids: data },
      () => {
        this.getData();
      },
      () => {
        runInAction(() => {
          this.loading = false;
        });
      }
    );
  };

  /**
   * deleteAgency
   * this function active agencies
   * @param   {Array} data  list id of agencies, default null
   * @return  {null} -
   */
  @action deleteAgency = (data = null) => {
    this.loading = true;
    if (!data) {
      data = this.selectedItems;
    }
    this.moduleService?.deleteAgency(
      { ids: data },
      () => {
        this.getData();
      },
      () => {
        runInAction(() => {
          this.loading = false;
        });
      }
    );
  };
}

export default AgencyStore;
