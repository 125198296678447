import BaseApi from "./BaseApi";
import Api from "./Api";
class AgencyService extends BaseApi {
  constructor(parentStore) {
    super("agencies", parentStore);
    this.keyField = "id";
  }
  /**
   * getApiKey
   * this function api key from DH
   * @param   {String} subpath  url path
   * @param   {Function} cb  callback function after success
   * @param   {Function} fb  failback function after fail
   * @return  {null} -  call the callback function
   */
  getApiKey = (subpath, cb, fb) => {
    const api = new Api(this.moduleName + subpath, this.parentStore);
    api.getRequest(
      {},
      (response) => {
        cb && cb(response?.data);
      },
      () => {
        fb && fb();
      }
    );
  };

  /**
   * updateAgenciesStatus
   * this function active/deactive agencies
   * @param   {String} subpath  url path
   * @param   {Array} data  list id of agencies
   * @param   {Function} cb  callback function after success
   * @param   {Function} fb  failback function after fail
   * @return  {null} -  call the callback function
   */
  updateAgenciesStatus = (subpath, data, cb, fb) => {
    const api = new Api(this.moduleName + subpath, this.parentStore);
    //@TODO: move to patch request
    api.patchRequest(
      data,
      () => {
        cb && cb();
      },
      () => {
        fb && fb();
      }
    );
  };
  /**
   * addUserToAgency
   * this function add user to agencies
   * @param   {String} subpath  url path
   * @param   {Object} data   email and role of user
   * @param   {Function} cb  callback function after success
   * @param   {Function} fb  failback function after fail
   * @return  {null} -  call the callback function
   */
  addUserToAgency = (subpath, data, cb, fb) => {
    const api = new Api(this.moduleName + subpath, this.parentStore);
    api.patchRequest(
      data,
      () => {
        cb && cb();
      },
      (error) => {
        fb && fb(error);
      }
    );
  };
  /**
   * removeUserFromAgency
   * this function remove user from agencies
   * @param   {String} subpath  url path
   * @param   {Array} data  list id of agencies
   * @param   {Function} cb  callback function after success
   * @param   {Function} fb  failback function after fail
   * @return  {null} -  call the callback function
   */
  removeUserFromAgency = (subpath, data, cb, fb) => {
    const api = new Api(this.moduleName + subpath, this.parentStore);
    api.patchRequest(
      data,
      () => {
        cb && cb();
      },
      () => {
        fb && fb();
      }
    );
  };
  /**
   * fetchAgencySetting
   * this function get agency setting info
   * @param   {String} agency_id  curent agency id
   * @param   {Function} cb  callback function after success
   * @param   {Function} fb  failback function after fail
   * @return  {null} -  call the callback function
   */
  fetchAgencySetting = (agency_id, cb, fb) => {
    const api = new Api(
      this.moduleName + "/" + agency_id + "/settings",
      this.parentStore
    );
    api.getRequest(
      {},
      (response) => {
        cb && cb(response?.data);
      },
      (errors) => {
        fb && fb(errors);
      }
    );
  };

  /**
   * saveAgencySetting
   * this function save agency setting info
   * @param   {String} agency_id  curent agency id
   * @param   {String} payload  new agency setting
   * @param   {Function} cb  callback function after success
   * @param   {Function} fb  failback function after fail
   * @return  {null} -  call the callback function
   */
  saveAgencySetting = (agency_id, payload, cb, fb) => {
    const api = new Api(
      this.moduleName + "/" + agency_id + "/settings",
      this.parentStore
    );
    api.putRequest(
      payload,
      (response) => {
        cb && cb(response?.data);
      },
      (errors) => {
        fb && fb(errors);
      }
    );
  };

  getLicense = (agency_id, cb, fb) => {
    const api = new Api(
        this.moduleName + "/" + agency_id + "/licenses",
        this.parentStore
    );
    api.getRequest(
        {},
        (response) => {
          cb && cb(response?.data);
        },
        (errors) => {
          fb && fb(errors);
        }
    );
  };

  saveLicenseSetting = (agency_id, payload, cb, fb) => {
    const api = new Api(
        this.moduleName + "/" + agency_id + "/licenses",
        this.parentStore
    );
    api.putRequest(
        payload,
        (response) => {
          cb && cb(response?.data);
        },
        (errors) => {
          fb && fb(errors);
        }
    );
  };

  deleteAgency = (data, cb, fb) => {
    const api = new Api(this.moduleName + '/delete', this.parentStore);
    api.deleteRequest(
      () => {
        cb && cb();
      },
      () => {
        fb && fb();
      },
      data
    );
  }
}

export default AgencyService;
