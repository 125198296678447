import React from "react";
import { Form, Timeline } from "antd";
import BaseDetailPopupComponent from "components/BaseDetailPopupComponent";
import StatusTag from "components/StatusTag";
import { inject } from "mobx-react";
import moment from "moment";
import { withTranslation } from "react-i18next";
import {
  DATE_FORMAT,
  DATE_RAGNGE_SELECT,
  FORM_ITEM_LAYOUT,
  RELATIVE_DATE_UNIT,
  WEEK_DAY_OPTION,
} from "utils/constants";

/**
 * Component for showing create agency form
 *
 * @component
 */
@inject("rootStore")
class ReportTemplateDetailPopup extends BaseDetailPopupComponent {
  /**
   * contructor of component
   * @param   {object} props  props of component
   */
  constructor(props) {
    super(props.rootStore.analysisScheduleStore, props);
    this.formFields = ["name"];
  }

  /**
   * renderReadOnlyForm
   * For view detail page, just label only
   *
   */
  renderReadOnlyForm(formData) {
    const { t } = this.props;
    return (
      <div>
        <Form.Item label={t("alarm_rules.analysis_id")} {...FORM_ITEM_LAYOUT}>
          {t(formData?.metric_type_label)}
        </Form.Item>
        <Form.Item
          label={t("report_template.description")}
          {...FORM_ITEM_LAYOUT}>
          {t(formData?.description)}
        </Form.Item>

        <Form.Item label={t("report_result.time_zone")}>
          {formData?.time_zone}
        </Form.Item>
        <Form.Item label={t("report_result.date_range")}>
          {formData?.date_range?.scope ===
          DATE_RAGNGE_SELECT.DATE_RANGE.value ? (
            <Timeline className="report-time-line">
              <Timeline.Item>
                {moment(
                  formData.date_range?.start_date,
                  DATE_FORMAT.day_only_backend
                ).format(DATE_FORMAT.day_only)}
              </Timeline.Item>
              <Timeline.Item>
                {moment(
                  formData.date_range?.end_date,
                  DATE_FORMAT.day_only_backend
                ).format(DATE_FORMAT.day_only)}
              </Timeline.Item>
            </Timeline>
          ) : (
            <div>
              {t("report_template.previous") +
                formData?.date_range?.offset +
                " " +
                RELATIVE_DATE_UNIT.find(
                  (e) => e.value === formData?.date_range?.unit
                )?.unit}
            </div>
          )}
        </Form.Item>

        <Form.Item
          label={t("report_template.day_of_week")}
          {...FORM_ITEM_LAYOUT}>
          {formData?.week_days
            ?.map((e) => {
              return WEEK_DAY_OPTION.find((i) => i.value == e).label;
            })
            .join(", ")}
        </Form.Item>
        <Form.Item label={t("report_result.time_range")}>
          {moment(
            formData?.metadata?.from_time,
            DATE_FORMAT.time_only_full
          ).format(DATE_FORMAT.hour)}
          {" - "}
          {moment(
            formData?.metadata?.to_time,
            DATE_FORMAT.time_only_full
          ).format(DATE_FORMAT.hour)}
        </Form.Item>

        <Form.Item
          label={t("report_template.created_by")}
          {...FORM_ITEM_LAYOUT}>
          {t(formData?.owner?.name)}
        </Form.Item>
        <Form.Item label={t("report_template.status")} {...FORM_ITEM_LAYOUT}>
          <StatusTag status={formData?.status} />
        </Form.Item>
        <Form.Item
          label={t("report_template.created_at")}
          {...FORM_ITEM_LAYOUT}>
          {moment(formData?.created_at).format(DATE_FORMAT.full)}
        </Form.Item>
      </div>
    );
  }

  /**
   * renderDetailData
   * @param   {object} formData
   * @param   {object} getFieldDecorator
   * this function return the detail box
   * @return  {Component}
   */
  renderDetailData(formData) {
    return this.renderReadOnlyForm(formData);
  }
}
const FormWrap = Form.create({ name: "alarm-rule-detail" })(
  ReportTemplateDetailPopup
);

export default withTranslation()(FormWrap);
