import { Button, Empty, Radio, Spin, Timeline, Typography } from "antd";
import DetailView from "components/MOEAnalysis/DetailView";
import SumamaryView from "components/MOEAnalysis/SumamaryView";
import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { ANALYSIS_TYPE, PATTERNS } from "utils/constants";
import { getExcelFile } from "./excelExport";
import { toFixed2Offset } from "utils/helper";

@inject("rootStore")
@observer
class AnalysisMOE extends Component {
  constructor(props) {
    super(props);

    this.state = {
      viewPattern: PATTERNS.CODE_FOR_ALL,
    };
  }

  renderViewType = () => {
    const {
      rootStore: {
        chartStore: { currentChartTypeFrontEnd, allChartData },
      },
    } = this.props;
    const { viewPattern } = this.state;

    switch (currentChartTypeFrontEnd) {
      case ANALYSIS_TYPE.MOE_SUMMARY:
        return (
          <SumamaryView
            data={allChartData}
            viewPattern={this.state.viewPattern}
          />
        );

      case ANALYSIS_TYPE.MOE_DETAIL_VIEW:
        const dataChart = allChartData.filter((item) => {
          if (viewPattern === PATTERNS.CODE_FOR_ALL) {
            return true;
          } else {
            return item.pattern === viewPattern;
          }
        });
        return <DetailView data={dataChart}/>;

      default:
        return <></>;
    }
  };

  renderPatternFilter = () => {
    const {
      rootStore: {
        chartStore: { chartAdditionalData },
      },
    } = this.props;

    return (
      <div className="flex gap-2">
        <Button
          onClick={() => {
            this.setState({ viewPattern: PATTERNS.CODE_FOR_ALL });
          }}
          type={this.state.viewPattern === PATTERNS.CODE_FOR_ALL ? "primary" : ""}>
          All
        </Button>
        {chartAdditionalData?.allPattern?.map((item) => {
          return (
            <Button
              key={item}
              onClick={() => {
                this.setState({ viewPattern: item });
              }}
              type={this.state.viewPattern === item ? "primary" : ""}>
              {item}
            </Button>
          );
        })}
      </div>
    );
  };

  render() {
    const {
      t,
      rootStore: {
        chartStore: {
          chartInfo,
          rootChartData,
          currentChartTypeFrontEnd,
          setCurrentChartTypeFrontEnd,
          chartAdditionalData,
        },
        analysisStore: { gettingChart },
      },

      resizing,
      convertTimeFormat,
    } = this.props;

    return gettingChart ? (
      <Spin size="large" className={"centralize"}/>
    ) : chartInfo && !resizing ? (
      <div className="wrap-analysis-result">
        <div className="wrap-analysis-header">
          <div className="analysis-header">
            <div className="left-content">
              <div className="analysis-title">
                <h1>
                  {t("analysis.perfomance-metric")}
                  {": "}
                  {t("analysis." + chartInfo?.analysis_type)}
                </h1>
                <div className="analysis-sub-title">
                  <span>{chartInfo?.title}</span>
                </div>
              </div>
              <div className="time-line">
                <div className="time-range">
                  <Timeline>
                    <Timeline.Item label={t("analysis.from_time")}>
                      {convertTimeFormat(chartInfo?.from_time)}
                    </Timeline.Item>
                    <Timeline.Item label={t("analysis.to_time")}>
                      {convertTimeFormat(chartInfo?.to_time)}
                    </Timeline.Item>
                  </Timeline>
                  {chartInfo?.isCompareMode && (
                    <Timeline>
                      <Timeline.Item label={t("analysis.from_time")}>
                        {convertTimeFormat(chartInfo?.comapre_from_time)}
                      </Timeline.Item>
                      <Timeline.Item label={t("analysis.to_time")}>
                        {convertTimeFormat(chartInfo?.comapre_to_time)}
                      </Timeline.Item>
                    </Timeline>
                  )}
                </div>
              </div>
            </div>
            <div className="right-content">
              <div className="print-function">
                <div className="flex gap-2">
                  <Radio.Group
                    size="large"
                    value={currentChartTypeFrontEnd}
                    onChange={(event) => {
                      setCurrentChartTypeFrontEnd(
                        ANALYSIS_TYPE.MOE_ANALYSIS,
                        event.target.value
                      );
                      this.setState({ viewPattern: PATTERNS.CODE_FOR_ALL });
                    }}
                    buttonStyle="solid">
                    <Radio.Button
                      // disabled={!allChartData.length}
                      size="large"
                      value={ANALYSIS_TYPE.MOE_SUMMARY}>
                      {t("analysis.summary_view")}
                    </Radio.Button>
                    <Radio.Button
                      // disabled={!allChartData.length}
                      size="large"
                      value={ANALYSIS_TYPE.MOE_DETAIL_VIEW}>
                      {t("analysis.detail_view")}
                    </Radio.Button>
                  </Radio.Group>
                  <Button
                    onClick={() => {
                      getExcelFile(rootChartData);
                    }}
                    // disabled={!allChartData.length}
                    size="large"
                    icon="printer">
                    {t("analysis.export_in_excel")}
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="flex w-full warning-mess"></div>
          {chartInfo.isGapData && (
            <div className="">{t("analysis.gap_warning")} &nbsp; </div>
          )}

          {!chartInfo.isFullyAppoarch && (
            <div>{t("analysis.appoarch_warning")}</div>
          )}
        </div>

        <div className="wrap-cycle-length">
          <div className="flex-5-5-auto">
            <div className="widget-header-title">
              {t("analysis.cycle_length")}
            </div>
            <div className="widget-content">
              <div>
                Number of Cycles = {chartAdditionalData?.numberOfCycles}
              </div>
              <div>
                Actual Average Cycle ={" "}
                {toFixed2Offset(chartAdditionalData?.actualAverageCycle)} Sec
              </div>
              <div>
                Minimum / Max Cycle Length ={" "}
                {chartAdditionalData?.minimumCycleLength} /{" "}
                {chartAdditionalData?.maxCycleLength} Sec
              </div>
            </div>
            {chartAdditionalData?.numberOfCycles === 0 ? (
              <Typography.Text type="warning" className="font-12 ">
                Note : The selected time range do not have COOORD_PATTERN_CHANGE
                event. Please select wider time range
              </Typography.Text>
            ) : (
              <></>
            )}
          </div>
          <div className="flex-5-5-auto">
            <div className="widget-header-title">PATTERNS</div>
            <div className="widget-content">{this.renderPatternFilter()}</div>
          </div>
        </div>

        <div className="flex-1-1-auto overflow-auto flex flex-col bg-background">
          <div className="flex gap-2 px-8 py-8 mb-4">
            <div className="widget-header-title">STATISTIC SUMMARY</div>
          </div>
          <div className="flex-1-1-auto overflow-auto">
            {this.renderViewType()}
          </div>
        </div>
      </div>
    ) : (
      <div className="wrap-analysis-result-empty">
        <Empty/>
      </div>
    );
  }
}

export default withTranslation()(AnalysisMOE);
