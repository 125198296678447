import { action, computed, observable, runInAction } from "mobx";
import { AVAILABLE, INTERSECTION_SCOPE } from "utils/constants";
import IntersectionService from "../services/IntersectionService";
import SelectIntersectionService from "../services/SelectIntersectionService";
import BaseStore from "./BaseStore";

class SelectIntersectionStore extends BaseStore {
  constructor(id, pathApi, parentStore, intIdsFields) {
    super(
      new SelectIntersectionService(id, pathApi, parentStore, intIdsFields),
      parentStore
    );
    this.interSectionService = new IntersectionService(parentStore);
    this.context_id = id;
  }

  context_id = null; // null: create Mode
  @observable scope = INTERSECTION_SCOPE.SPECIFIC;
  @observable listIdAllData = [];

  //overrride
  @action handleTableChange = (pagination, filters, sorter) => {
    this.filterData.pagination.current = pagination.current;
    let sortString = "";
    if (sorter.order) {
      sortString = sorter.columnKey + ":" + sorter.order;
    }
    this.filterData = { ...this.filterData, sort: sortString };
    if (this.context_id) this.getData();
  };

  @computed get getListCreateIntersection() {
    return this.filterData.text?.trim()
      ? this.listData.filter((e) =>
        e.name
          ?.toLowerCase()
          .includes(this.filterData.text?.trim().toLowerCase())
      )
      : this.listData;
  }

  @action setListDataSelected = (data) => {
    runInAction(() => {
      this.listIdAllData = this.listIdAllData.concat(data);
    });
  };
  @action setFilterDataSelected = (data) => {
    runInAction(() => {
      this.listIdAllData = this.listIdAllData.filter(
        (item) => !data.includes(item)
      );
    });
  };

  /**
   * getData
   * this function get all data from server with filter value
   * setpage on loading beforload and clean it after loading done
   * after get the data done: update the total item for paging compoment
   *
   * @return  {null} -
   */
  @action getData = (cb) => {
    this.loading = true;
    this.moduleService?.fetchDataWithParams(
      this.filterData,
      (items, totalCount, data) => {
        if (items.length === 0 && totalCount !== 0) {
          this.filterData.pagination.current = 1;
          this.getData();
        } else {
          let itemView = items;
          if (data.scope === INTERSECTION_SCOPE.ALL)
            itemView = items.map((e) => {
              return {
                ...e,
                disable: true,
              };
            });
          runInAction(() => {
            this.loading = false;
            this.listData = itemView;
            this.filterData.pagination.total = totalCount;
            this.scope = data?.scope;
          });
          cb && cb();
        }
      },
      () => {
        runInAction(() => {
          this.loading = false;
        });
      }
    );
  };
  @action setScope = (value) => {
    this.scope = value;
    if (value === INTERSECTION_SCOPE.SPECIFIC)
      this.listData = this.listData.map((e) => {
        return {
          ...e,
          disable: false,
        };
      });
  };
  @action resetListIdAllData = () => {
    runInAction(() => {
      this.listIdAllData = [];
    });
  };

  @action switchIntersectionScopeCreateMode = (scope, agency_id, cb) => {
    if (scope === INTERSECTION_SCOPE.ALL) {
      this.loading = true;
      this.interSectionService?.getAllIntersectionsByAgency(
        { agency_id, status: AVAILABLE },
        (response) => {
          runInAction(() => {
            this.listIdAllData = response.data.map((item) => item.id);
            this.filterData.pagination.total = response.data.length;
            this.listData = response?.data.map((e) => {
              return {
                ...e,
                disable: true,
              };
            });
            cb && cb({ exclude_int_ids: this.listIdAllData });
            this.loading = false;
          });
        },
        () => {
          runInAction(() => {
            this.loading = false;
          });
        }
      );
    } else {
      runInAction(() => {
        this.filterData.pagination.total = 0;
        this.listData = [];
      });
    }
  };
  @action switchIntersectionScopeEditMode = (scope, intersection_list, cb) => {
    if (scope === INTERSECTION_SCOPE.ALL)
      this.moduleService?.add(
        scope,
        intersection_list,
        () => {
          this.getData();
          runInAction(() => {
            this.loading = false;
          });
          cb && cb();
        },
        () => {
          runInAction(() => {
            this.loading = false;
          });
        }
      );
    else {
      this.moduleService?.delete(
        INTERSECTION_SCOPE.ALL,
        "*",
        () => {
          this.getData();
          runInAction(() => {
            this.loading = false;
          });
          cb && cb();
        },
        () => {
          runInAction(() => {
            this.loading = false;
          });
        }
      );
    }
  };

  @action updateIntersectionAdd = (items, cb) => {
    const ids = items.map((e) => e.id);
    this.loading = true;
    this.moduleService?.add(
      INTERSECTION_SCOPE.SPECIFIC,
      ids,
      () => {
        this.getData();
        runInAction(() => {
          this.loading = false;
        });
        cb && cb();
      },
      () => {
        runInAction(() => {
          this.loading = false;
        });
      }
    );
  };

  @action updateIntersectionDelete = (ids, cb) => {
    if (ids[0] == null) ids = this.selectedItems;
    this.loading = true;
    this.moduleService?.delete(
      INTERSECTION_SCOPE.SPECIFIC,
      ids,
      () => {
        this.getData();
        runInAction(() => {
          this.loading = false;
        });
        cb && cb();
      },
      () => {
        runInAction(() => {
          this.loading = false;
        });
      }
    );
  };

  @action addItem = (items, cb) => {
    items.map((e) => {
      if (!this.listData.map((child_e) => child_e?.id).includes(e?.id)) {
        this.listData.push(e);
      }
      if (!this.context_id)
        this.filterData.pagination.total = this.listData.length;
    });
    cb && cb();
  };
  @action resetListIntersection = () => {
    this.listData = [];
    this.filterData.pagination.total = 0;
  };
  @action removeItem = (ids, cb) => {
    if (ids[0] == null) ids = this.selectedItems;
    this.listData = this.listData.filter((e) => !ids.includes(e.id));
    this.filterData.pagination.total = this.listData.length;
    cb && cb();
  };
  @action clearSelectedItems = () => {
    this.selectedItems = [];
  };
}

export default SelectIntersectionStore;
