import React, {Component} from "react";
import {inject, observer} from "mobx-react";
import {withRouter} from "react-router";
import {withTranslation} from "react-i18next";
import {Col, Form, Row} from "antd";
import LicenseWidgetLayout from "../../../../components/LicenseWidgetLayout";

/**
 * Component for showing agency detail (or edit form)
 *
 * @component
 */
@inject("rootStore")
@observer
class LicenseTab extends Component {
    /**
     * contructor of component
     * @param   {object} props  props of component
     */
    constructor(props) {
        super(props);
    }

    componentDidMount = () => {
        const {store} = this.props;
        store.getLicenses();

    };
    handleLicenseEnable = (id, enable) => {
        const {store} = this.props;
        store.changeLicense(id, enable);
    }

    /**
     * render
     * @return  {Component}
     */
    render() {
        const {
            t,
            store,
            rootStore: {},
        } = this.props;
        return (
            <div>
                <div>{"The license configuration defines the list of available functions for the agency!"}</div>
                <Form onSubmit={this.handleSubmit}>
                    <Row gutter={16}>
                        {
                            store.licenses.data.map((e) => {
                                return (
                                    <Col key={e.id} span={4}>
                                        <LicenseWidgetLayout
                                            key={e.id}
                                            name={t(e.name)}
                                            description={t(e.description)}
                                            disabled={e.always_enabled}
                                            checked={store.licenses.licenseSetting.includes(e.id)}
                                            onChange={(event) => {
                                                this.handleLicenseEnable(e.id, event.target.checked)
                                            }}
                                        />
                                    </Col>
                                )
                            })
                        }
                    </Row>
                </Form>
            </div>
        )
    }
}

const LicenseTabForm = Form.create({name: "license"})(LicenseTab);

export default withRouter(withTranslation()(LicenseTabForm));
