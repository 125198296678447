import { action, runInAction } from "mobx";
import IntersectionService from "../services/IntersectionService";
import BaseStore from "./BaseStore";

class DownloadStore extends BaseStore {
  constructor(parentStore) {
    super(new IntersectionService(parentStore), parentStore);
  }

  /**
   * handleDownloadAccess
   * @param   {object} params  parans of URL download got from UI
   * @return  {Promise} -  return Promise
   */
  @action handleDownloadAccess = (params) => {
    this.clearErrorList();
    this.loading = true;
    return new Promise((resolve) => {
      this.moduleService
        ?.downloadIntersectionWithPassword(params)
        .then((data) => {
          resolve(data.signed_url);
        })
        .catch((errors) => {
          runInAction(() => {
            this.loading = false;
            this.setErrorList(errors);
          });
        });
    });
  };
}
export default DownloadStore;
