import moment from "moment";
import { ANALYSIS_TYPE, PATTERNS } from "utils/constants";
import { transferMOEData } from "utils/helper";
import { utils, write } from "xlsx";
import i18n from "../../../../i18n";

const transformMatrixDataTable = (rawData) => {
  const phaseNames = Object.keys(rawData || {});
  const tableData = [];
  if (phaseNames.length && rawData) {
    const attributeNames = Object.keys(rawData[phaseNames[0]]);

    attributeNames.forEach((attribute) => {
      const pushObject = {
        Phase: i18n.t("analysis.moe." + attribute),
      };
      phaseNames.forEach((phase) => {
        pushObject[phase] =
          !rawData[phase][attribute] || rawData[phase][attribute] === "NaN"
            ? " "
            : rawData[phase][attribute];
      });
      tableData.push(pushObject);
    });
  }

  return tableData;
};

const tranformDetailView = (data) => {
  return data.map((item) => ({
    "Date/Time": moment(item.from_time).format("M/D/YYYY LTS"),
    // Ring: item.ring,
    Pattern: item.pattern,
    Cycle:
      item?.cycle?.actual_length && item?.cycle?.programmed_length
        ? `${item?.cycle?.actual_length}/${item?.cycle?.programmed_length}`
        : "0/0",
    "Phase 1":
      item.phase_1?.actual_length && item.phase_1?.programmed_length
        ? `${item.phase_1?.actual_length}/${item.phase_1?.programmed_length}`
        : "0/0",
    "Phase 2":
      item.phase_2?.actual_length && item.phase_2?.programmed_length
        ? `${item.phase_2?.actual_length}/${item.phase_2?.programmed_length}`
        : "0/0",
    "Phase 3":
      item.phase_3?.actual_length && item.phase_3?.programmed_length
        ? `${item.phase_3?.actual_length}/${item.phase_3?.programmed_length}`
        : "0/0",

    "Phase 4":
      item.phase_4?.actual_length && item.phase_4?.programmed_length
        ? `${item.phase_4?.actual_length}/${item.phase_4?.programmed_length}`
        : "0/0",
    "Phase 5":
      item.phase_5?.actual_length && item.phase_5?.programmed_length
        ? `${item.phase_5?.actual_length}/${item.phase_5?.programmed_length}`
        : "0/0",
    "Phase 6":
      item.phase_6?.actual_length && item.phase_6?.programmed_length
        ? `${item.phase_6?.actual_length}/${item.phase_6?.programmed_length}`
        : "0/0",
    "Phase 7":
      item.phase_7?.actual_length && item.phase_7?.programmed_length
        ? `${item.phase_7?.actual_length}/${item.phase_7?.programmed_length}`
        : "0/0",
    "Phase 8":
      item.phase_8?.actual_length && item.phase_8?.programmed_length
        ? `${item.phase_8?.actual_length}/${item.phase_8?.programmed_length}`
        : "0/0",
    Transition: `${item?.transition ? "Yes" : "No"}`,
    TSP: item.TSP ? "Yes" : "No",
    PE: item.PE ? "Yes" : "No",
  }));
};
export const getExcelFile = (dataInput) => {
  const workbook = utils.book_new();
  const tranformDataMatrix = transformMatrixDataTable(
    transferMOEData({ ...dataInput }, ANALYSIS_TYPE.MOE_SUMMARY)?.allChartData[PATTERNS.CODE_FOR_ALL]?.phase_summary
  );

  if (tranformDataMatrix) {
    const worksheetSummaryView = utils.json_to_sheet(tranformDataMatrix);
    utils.book_append_sheet(workbook, worksheetSummaryView, "Summary View");
  }

  const dataDetailView = tranformDetailView(
    transferMOEData(dataInput, ANALYSIS_TYPE.MOE_DETAIL_VIEW).allChartData
  );
  if (dataDetailView) {
    const worksheetDetailView = utils.json_to_sheet(dataDetailView);
    utils.book_append_sheet(workbook, worksheetDetailView, "Detail View");
  }

  const buffer = write(workbook, { type: "array", bookType: "xlsx" });

  const blob = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download =
    i18n.t("analysis." + dataInput.analysis_type) +
    " " +
    moment(dataInput.from_time).format("HHMM") +
    "-" +
    moment(dataInput.to_time).format("HHMM") +
    " " +
    moment(dataInput.from_time).format("YYYYMMDD") +
    ".xlsx";
  link.click();
};
