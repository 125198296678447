import Api from "./Api";
import BaseApi from "./BaseApi";
/**
 * Service for user management.
 *
 * @class
 */
class UserService extends BaseApi {
  constructor(parentStore) {
    super("users", parentStore);
    this.keyField = "user_id";
  }
  /**
   * updateUsersStatus
   * this function update users status
   * @param   {String} subpathAction  sub url path
   * @param   {Array} data  list id of users
   * @param   {Function} cb  callback function after success
   * @param   {Function} fb  failback function after fail
   * @return  {null} -  call the callback function
   */
  updateUsersStatus = (subpathAction, data, cb, fb) => {
    const api = new Api(this.moduleName + subpathAction, this.parentStore);
    api.patchRequest(
      data,
      () => {
        cb && cb();
      },
      () => {
        fb && fb();
      }
    );
  };

  /**
   * sendActivationEmail
   * this function sends an activation email to an unactivated user
   * @param   {Array} data  list id of users
   * @param   {Function} cb  callback function after success
   * @param   {Function} fb  failback function after fail
   * @return  {null} -  call the callback function
   */
  sendActivationEmail = (data, cb, fb) => {
    const api = new Api(this.moduleName + '/activation-email', this.parentStore);
    api.postRequest(
      data,
      () => {
        cb && cb();
      },
      () => {
        fb && fb();
      }
    );
  };

  

/**
   * deleteUsers
   * this function deletes users
   * @param   {Array} data  list id of users
   * @param   {Function} cb  callback function after success
   * @param   {Function} fb  failback function after fail
   * @return  {null} -  call the callback function
   */
  deleteUsers = (data, cb, fb) => {
    const api = new Api(this.moduleName + '/delete', this.parentStore);
    api.deleteRequest(
      () => {
        cb && cb();
      },
      () => {
        fb && fb();
      },
      data
    );
  };

  /**
   * getAllUser
   * get all user without filter
   * @param   {Function} cb  callback function after success
   * @param   {Function} fb  failback function after fail
   * @return  {null} -  call the callback function
   */
  getAllUser = (cb, fb) => {
    const api = new Api(this.moduleName + "/all", this.parentStore);
    api.getRequest(
      {},
      (response) => {
        cb && cb(response);
      },
      () => {
        fb && fb();
      }
    );
  };

  /**
   * getAllUserByAgency
   * get all user without filter
   * @param   {String} agency_id  callback function after success
   * @param   {Function} cb  callback function after success
   * @param   {Function} fb  failback function after fail
   * @return  {null} -  call the callback function
   */
  getAllUserByAgency = (agency_id, cb, fb) => {
    let url = this.moduleName + "/all";
    if (agency_id) url = this.moduleName + "/all?agency_id=" + agency_id;
    const api = new Api(url, this.parentStore);
    api.getRequest(
      {},
      (response) => {
        cb && cb(response);
      },
      () => {
        fb && fb();
      }
    );
  };

  /**
   * forwardMessages
   * this function forward message to target users
   * @param   {Object} users  target user
   * @param   {int} record_id  record id
   * @return  {null} -  setState of current filter data
   */
  forwardMessages = (users, record_id) => {
    this.loading = true;
    return new Promise((resolve, reject) => {
      this.moduleService
        ?.forwardMessages(users, record_id)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error?.errors);
        })
        .finally(() => {
          runInAction(() => {
            this.loading = false;
          });
        });
    });
  };
}

export default UserService;
