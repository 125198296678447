import Api from "./Api";
import BaseApi from "./BaseApi";

/**
 * Service for corridor
 *
 * @class
 */
class CorridorService extends BaseApi {
  constructor(parentStore) {
    super("corridors", parentStore);
  }
  /**
   * updateStatus
   * this function update status
   * @param   {String} subpathAction  sub url path
   * @param   {Array} data  list id of entities
   * @param   {Function} cb  callback function after success
   * @param   {Function} fb  failback function after fail
   * @return  {null} -  call the callback function
   */
  updateStatus = (data, cb, fb) => {
    const api = new Api(this.moduleName + "/activate", this.parentStore);
    api.patchRequest(
      data,
      () => {
        cb && cb();
      },
      () => {
        fb && fb();
      }
    );
  };
  /**
   * Get corridor
   * this function update status
   */
  fetchCorridorDetails = (id, cb, fb) => {
    const api = new Api(this.moduleName + "/" + id, this.parentStore);
    api.getRequest(
      id,
      (res) => {
        cb && cb(res.data);
      },
      () => {
        fb && fb();
      }
    );
  };
  /**
   * Save corridor
   * this function update status
   */
  saveCorridor = (id, data, cb, fb) => {
    const api = new Api(this.moduleName + "/" + id, this.parentStore);
    api.putRequest(
      data,
      () => {
        cb && cb();
      },
      (errMessage) => {
        fb && fb(errMessage);
      }
    );
  };
  /**
   * Create corridor
   * this function update status
   */
  createCorridor = (data, cb, fb) => {
    const api = new Api(this.moduleName, this.parentStore);
    api.postRequest(
      data,
      () => {
        cb && cb();
      },
      (errMessage) => {
        fb && fb(errMessage);
      }
    );
  };
}

export default CorridorService;
