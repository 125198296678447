import AgencyService from "../services/AgencyService";
import BaseStore from "./BaseStore";
import {action, runInAction, observable} from "mobx";
import {RESOURCE_LIST} from "utils/constants";
import {showNotification} from "../utils/helper";
import i18n from "../i18n";

/**
 * Store for user management.
 *
 * @class
 */

class AgencyLicenseStore extends BaseStore {
    @observable licenses = {
        data: [],
        licenseSetting: [],
    };

    constructor(agencyId, parentStore) {
        super(new AgencyService(parentStore), parentStore);
        this.agencyId = agencyId; //set current agency
        this.resourceName = RESOURCE_LIST.AGENCY;
    }

    /**
     * handleSubmit
     * save current currentAgencySetting to backend
     * @return  {null} -
     */
    @action handleSubmit = () => {
        //if still have error => not allow to save
        if (this.errorList) {
            return;
        }
        this.loading = true;
        return new Promise((resolve, reject) => {
            this.moduleService?.saveLicenseSetting(
                this.agencyId,
                {license_ids: this.licenses.licenseSetting},
                (response) => {
                    runInAction(() => {
                        this.loading = false;
                        showNotification("success", i18n.t("success"), i18n.t("update_success"));
                        resolve(response);
                    });
                },
                (errors) => {
                    runInAction(() => {
                        this.loading = false;
                        errors?.errors?.map((error) => {
                            showNotification("error", i18n.t("error"), error.message);
                        })
                    });
                    reject(errors);
                }
            );
        });
    };

    @action changeLicense = (id, enabled) => {
        if (enabled) {
            this.licenses.licenseSetting = [...this.licenses.licenseSetting, id]
        } else {
            this.licenses.licenseSetting = [...this.licenses.licenseSetting].filter((item) => item !== id)
        }
    }

    @action getLicenses = () => {
        this.loading = true;
        return new Promise((resolve, reject) => {
            this.moduleService?.getLicense(
                this.agencyId,
                (response) => {
                    runInAction(() => {
                        this.licenses.data = response.licenses
                        this.licenses.licenseSetting = response.licenses.filter((item) => item.enabled).map((item) => item.id)
                    });
                },
                (errors) => {
                    runInAction(() => {
                        this.loading = false;
                        this.errorList = null;
                    });
                    reject(errors);
                }
            );
        });
    };
}

export default AgencyLicenseStore;
