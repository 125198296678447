import React from "react";
import { withTranslation } from "react-i18next";
import { Col, Button, Row, TimePicker, DatePicker, Alert } from "antd";
import { DATE_FORMAT } from "utils/constants";
import moment from "moment";
class QueryTimePopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      invalidRange: false,
    };
  }
  handleQueryChange = () => {
    const { currentStore } = this.props;
    const range =
      currentStore.filterData.to_time - currentStore.filterData.from_time;
    if (range <= 0 || range > 86400000) {
      this.setState({
        invalidRange: true,
      });
      return;
    } else {
      this.setState({
        invalidRange: false,
      });
      currentStore.handleFilerAction();
      this.props.closeModal();
    }
  };
  /**
   * render
   *
   * @return  {Component}
   */
  render() {
    const { t, currentStore } = this.props;
    return (
      <div>
        <label>{t("tsp_dashboard.from_time")}</label>
        <Row>
          <Col span={12}>
            <TimePicker
              format={DATE_FORMAT.hour}
              minuteStep={this.props.hourOnly ? 60 : 1}
              value={moment(currentStore.filterData?.from_time)}
              onChange={(value) =>
                currentStore.handleFilterDataChange(
                  "from_time",
                  value?.valueOf(),
                  false
                )
              }
            />
          </Col>
          <Col span={12}>
            <DatePicker
              value={moment(currentStore.filterData?.from_time)}
              onChange={(value) =>
                currentStore.handleFilterDataChange(
                  "from_time",
                  value?.valueOf(),
                  false
                )
              }
            />
          </Col>
        </Row>
        <label>{t("tsp_dashboard.to_time")}</label>
        <Row>
          <Col span={12}>
            <TimePicker
              minuteStep={this.props.hourOnly ? 60 : 1}
              format={DATE_FORMAT.hour}
              value={moment(currentStore.filterData?.to_time)}
              onChange={(value) =>
                currentStore.handleFilterDataChange(
                  "to_time",
                  value?.valueOf(),
                  false
                )
              }
            />
          </Col>
          <Col span={12}>
            <DatePicker
              value={moment(currentStore.filterData?.to_time)}
              onChange={(value) =>
                currentStore.handleFilterDataChange(
                  "to_time",
                  value?.valueOf(),
                  false
                )
              }
            />
          </Col>
        </Row>
        {this.state.invalidRange && (
          <Alert
            style={{ marginTop: 8 }}
            message={t("tsp_dashboard.invalid_range")}
            type="error"
            showIcon
          />
        )}
        <Button
          loading={currentStore.loading}
          type="primary"
          style={{ marginTop: 8 }}
          onClick={() => this.handleQueryChange()}
        >
          Ok
        </Button>
      </div>
    );
  }
}

export default withTranslation()(QueryTimePopup);
