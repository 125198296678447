import { Button, Col, DatePicker, Input, Modal, Select, Tooltip } from "antd";
import { inject, observer } from "mobx-react";
import moment from "moment";
import queryString from "query-string";
import React from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import { ACTIONS_LIST, DATE_FORMAT, FULL_PERMISSION, NOTIFICATION_ACTION_TYPE, RESOURCE_LIST, } from "utils/constants";
import BaseManagementComponents from "../../../components/BaseManagementComponents";
import Helper from "../../../utils/helper";
import AlarmsRecordsDetailForm from "./AlarmsRecordsDetail";
import AlarmSummaryModal from "./AlarmSummaryModal";
import "./style.scss";

const ANALYSIS = "ANALYSIS";
const { RangePicker } = DatePicker;
const { Option } = Select;

@inject("rootStore")
@observer
class AlarmRecords extends BaseManagementComponents {
  constructor(props) {
    super(props.rootStore?.alarmRecordStore, props);
    const { t } = props;
    this.resourceName = RESOURCE_LIST.ALARM_RECORD;
    this.enableCreateButton = false;
    this.enableRowSelection = false;
    this.moduleName = "alarm_records";
    this.columns = this.getTableColumns();
    this.pageTitle = t("alarm_records_management");
    this.headerButtonActions = [
      {
        key: "summary",
        className: "alarm-record-summary",
        title: this.props.t("alarm_records.summary"),
        resource: RESOURCE_LIST.ALARM_RECORD_SUMARY,
        permission: ACTIONS_LIST.VIEW,
        onClick: this.currentStore?.toggleSummatyModalVisibility,
      },
    ];
    this.pageCustomClass = "alarm_records_page";
    this.tableInlineActions = [
      {
        key: "forward",
        tooltip: t("alarm_records.forward"),
        resource: RESOURCE_LIST.ALARM_RECORD_FORWARD,
        action: this.currentStore?.showCreateOrEditForm,
        permission: ACTIONS_LIST.CREATE,
        icon: "double-right",
        passingData: (item) => {
          return [t("alarm_records.forward_message_form"), item?.id];
        },
      },
      {
        key: "analysis",
        tooltip: t("alarm_records.analysis_go"),
        action: Helper.handleLinkAction,
        resource: FULL_PERMISSION,
        permission: ACTIONS_LIST.VIEW,
        icon: "bar-chart",
        passingData: (item) => {
          let actionObject = {
            type: NOTIFICATION_ACTION_TYPE.REDIRECT,
            target: ANALYSIS,
            data: {
              agency_id: this.currentStore.filterData.agency_id,
              analysis_id: item.analysis_id,
              from_time: item.from_time,
              to_time:
                Array.isArray(item.alarm_time.split(" ")) &&
                item.alarm_time.split(" ")[0]
                  ? item.alarm_time.split(" ")[0]
                  : null,
              intersection_id: item.intersection_id,
            },
          };
          return [actionObject, this.props.history];
        },
      },
    ];
  }

  /**
   * componentDidMount
   *
   */
  componentDidMount = () => {
    const { t } = this.props;
    document.title = t("alarm_records.page-title");
    if (this.props.location.search.length > 0) {
      let filterObject = queryString.parse(this.props.location.search);
      this.currentStore?.setFilterData(filterObject);
    }

    let currentContext = this;
    Promise.all([
      this.currentStore?.getAllCategories(),
      this.currentStore?.getAllAnalysisType(),
      this.currentStore?.getAllAgencies(),
    ]).then(function () {
      currentContext.currentStore?.getData();
    });
    this.currentStore.clearSelectedItems();
  };

  /**
   * return column set of table
   */
  getTableColumns = () => {
    const { t } = this.props;
    return [
      {
        title: t("alarm_records.id"),
        dataIndex: "id",
        key: "id",
        sorter: true,
        width: "5%",
        sortDirections: ["descend", "ascend"],
        ellipsis: true,
      },
      {
        title: t("alarm_records.name"),
        dataIndex: "name",
        key: "name",
        render: (value, row) =>
          this.renderKeyColumn(value, row, RESOURCE_LIST.ALARM_RECORD_DETAIL),
        sorter: true,
        sortDirections: ["descend", "ascend"],
        ellipsis: true,
      },
      {
        title: t("alarm_records.category_name"),
        dataIndex: "category_name",
        key: "category_name",
        ellipsis: true,
      },
      {
        title: t("alarm_records.analysis_name"),
        dataIndex: "analysis_name",
        key: "analysis_name",
        ellipsis: true,
      },
      {
        title: t("alarm_records.intersection_name"),
        dataIndex: "intersection_name",
        key: "intersection_name",
        ellipsis: true,
      },
      {
        title: t("alarm_records.content"),
        dataIndex: "content",
        key: "content",
        ellipsis: true,
      },
      {
        title: t("alarm_records.alarm_time"),
        dataIndex: "alarm_time",
        key: "alarm_time",
        render: (alarm_time) => {
          const tz = alarm_time.split(" ")[1];
          return moment(alarm_time, DATE_FORMAT.backend_with_tz)
            .zone(tz)
            .format(DATE_FORMAT.full_with_tz);
        },
        sorter: true,
        sortDirections: ["descend", "ascend"],
        ellipsis: true,
      },
    ];
  };

  //-------- render common component ----------
  /**
   * renderFilterComponent
   * this function return the filter box
   * @return  {Component}
   */
  renderFilterComponent = () => {
    const {
      t,
      rootStore: { commonStore },
    } = this.props;
    return (
      <div>
        <Col span={4}>
          <Tooltip placement="top" title={t("alarm_records.search_tooltip")}>
            <Input
              maxLength={255}
              defaultValue={this.currentStore?.filterData?.text}
              onChange={(e) =>
                this.currentStore?.handleFilterDataChange(
                  "text",
                  e.target?.value
                )
              }
              placeholder={t("alarm_records.search_plachoder")}
              onPressEnter={this.currentStore?.handleFilerAction}
            />
          </Tooltip>
        </Col>
        <Col span={4}>
          <Tooltip placement="top" title={t("alarm_records.time_tooltip")}>
            <RangePicker
              allowClear={false}
              defaultValue={[
                moment(this.currentStore?.filterData?.from_time),
                moment(this.currentStore?.filterData?.to_time),
              ]}
              format={DATE_FORMAT.day_only}
              onChange={(e) => {
                this.currentStore?.handleFilterDataChange(
                  "from_time",
                  e?.[0]?.startOf("day").format(DATE_FORMAT.backend),
                  false
                );
                this.currentStore?.handleFilterDataChange(
                  "to_time",
                  e?.[1]?.endOf("day").format(DATE_FORMAT.backend)
                );
              }}
            />
          </Tooltip>
        </Col>
        <Col span={4}>
          <Tooltip
            placement="top"
            title={t("alarm_records.categories_tooltip")}
          >
            <Select
              onChange={(value) =>
                this.currentStore?.handleFilterDataChange("category_id", value)
              }
              defaultValue={this.currentStore?.filterData?.category_id}
            >
              <Option value="null">{t("alarm_records.all_categories")}</Option>
              {this.currentStore?.allCategories?.map((e) => (
                <Option key={e.id} value={e.id}>
                  {t(e.name)}
                </Option>
              ))}
            </Select>
          </Tooltip>
        </Col>
        <Col span={4}>
          <Tooltip
            placement="top"
            title={t("alarm_records.analysis_type_tooltip")}
          >
            <Select
              onChange={(value) =>
                this.currentStore?.handleFilterDataChange("analysis_id", value)
              }
              defaultValue={this.currentStore?.filterData?.analysis_id}
            >
              <Option key={"null"} value="null">
                {t("alarm_records.all_analysis_type")}
              </Option>
              {this.currentStore?.allAnalysisType?.map((e) => (
                <Option key={e.id} value={e.id}>
                  {t(e.name)}
                </Option>
              ))}
            </Select>
          </Tooltip>
        </Col>
        {commonStore.isSuperAdmin() &&
          this.currentStore?.allAgencies.length > 0 && (
            <Col span={4}>
              <Tooltip
                placement="top"
                title={t("alarm_records.agency_tooltip")}
              >
                <Select
                  labelInValue={true}
                  onChange={(value) =>
                    this.currentStore?.handleFilterDataChange(
                      "agency_id",
                      value.key
                    )
                  }
                  value={{ key: this.currentStore?.filterData.agency_id }}
                >
                  {this.currentStore?.allAgencies?.map((e) => (
                    <Option key={e.id} value={e.id}>
                      {e.name}
                    </Option>
                  ))}
                </Select>
              </Tooltip>
            </Col>
          )}
        <Col span={4}>
          <Tooltip
            placement="top"
            title={t("alarm_records.intersection_search_tooltip")}
          >
            <Input
              maxLength={255}
              value={this.currentStore?.filterData?.intersection}
              onChange={(e) =>
                this.currentStore?.handleFilterDataChange(
                  "intersection",
                  e.target?.value
                )
              }
              placeholder={t("alarm_records.intersection_search_plachoder")}
              onPressEnter={this.currentStore?.handleFilerAction}
            />
          </Tooltip>
        </Col>
      </div>
    );
  };
  /**
   * renderDetailData
   * this function return the user detail box
   * @return  {Component}
   */
  renderDetailData = () => {
    return (
      <AlarmsRecordsDetailForm
        editable={this.currentStore?.formEditable}
        onClose={this.handleDrawerOnClose}
        childRef={(ref) => (this.defailFromRef = ref)}
      />
    );
  };

  /**
   * render
   *
   * @return  {Component}
   */
  render() {
    return super.render();
  }

  /**
   * renderAdditionalInfo
   * this function return the popup for preview export data
   * @return  {Component}
   */
  renderAdditionalInfo = () => {
    const { t } = this.props;
    return (
      <Modal
        className="alarm-records-summary-modal"
        width={"60%"}
        title={t("alarm_records.export_summary_title")}
        visible={this.currentStore?.visibleSummartModal}
        onCancel={this.currentStore?.toggleSummatyModalVisibility}
        footer={[
          <Button
            key="back"
            onClick={this.currentStore?.toggleSummatyModalVisibility}
          >
            {t("alarm_records.cancel")}
          </Button>,
          <a
            className="ant-btn-primary ant-btn"
            key="submit"
            target="_blank"
            href={this.currentStore?.exportLink}
            rel="noopener noreferrer"
          >
            {t("alarm_records.export")}
          </a>,
        ]}
      >
        <AlarmSummaryModal/>
      </Modal>
    );
  };
}

export default withRouter(withTranslation()(AlarmRecords));
