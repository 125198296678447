import React, { Component } from "react";
import {
  Checkbox,
  Col,
  DatePicker,
  Form,
  Icon,
  InputNumber,
  Radio,
  Row,
  Select,
  TimePicker,
  Tooltip,
  Typography,
} from "antd";
import { inject, observer } from "mobx-react";
import moment from "moment";
import { withTranslation } from "react-i18next";
import {
  DATE_FORMAT,
  DATE_RAGNGE_SELECT,
  TIME_PICKER_FORMAT,
  TIME_TO_RUN_REPORT,
  RELATIVE_DATE_UNIT,
  WEEK_DAY_OPTION,
} from "utils/constants";

const { Option } = Select;

@inject("rootStore")
@observer
class DefaultSubForm extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    const {
      rootStore: {
        analysisScheduleStore: { setFormField },
      },
    } = this.props

    setFormField("general_data.week_days", [
      "MONDAY",
      "TUESDAY",
      "WEDNESDAY",
      "THURSDAY",
      "FRIDAY",
      "SATURDAY",
      "SUNDAY",
    ]);
    setFormField("general_data.date_range.unit", 'DAY');
    setFormField("general_data.date_range.target_date", null);
  }

  render() {
    const {
      t,
      form: { getFieldDecorator, validateFields, resetFields, getFieldValue },
      rootStore: {
        analysisScheduleStore: {
          formData: { general_data, schedule },
        },
      },
      checkOutRange,
      renderExtraFields,
      renderDayOfWeekWarning,
      onChangeDateRange,
      onChangeDateScope,
      checkVaildTime,
      checkToTime,
      formItemLayout,
      onChangeField,
    } = this.props;
    const moduleName = "report_template";

    return(
      <>
        <Col lg={24}>
          <Form.Item
            className="label"
            label={t(moduleName + ".select_date_range")}
            {...formItemLayout}
            style={{ marginBottom: 0 }}
          >
            {getFieldDecorator("general_data.date_range.scope", {
              rules: [],
              initialValue: (general_data.date_range.start_date) ? (DATE_RAGNGE_SELECT.DATE_RANGE.value) : (DATE_RAGNGE_SELECT.RELATIVE.value),
              getValueFromEvent: (e) => {
                return onChangeDateScope(
                  "general_data.date_range.scope",
                  e.target.value
                );
              },
            })(
              <Radio.Group name="radiogroup">
                <Radio value={DATE_RAGNGE_SELECT.RELATIVE.value}>
                  {DATE_RAGNGE_SELECT.RELATIVE.label}
                </Radio>
                <Radio value={DATE_RAGNGE_SELECT.DATE_RANGE.value}>
                  {DATE_RAGNGE_SELECT.DATE_RANGE.label}
                </Radio>
              </Radio.Group>
            )}
          </Form.Item>
        </Col>
        <Col lg={24} md={24}>
          {getFieldValue('general_data.date_range.scope') === DATE_RAGNGE_SELECT.DATE_RANGE.value ? (
            <Form.Item className="label" {...formItemLayout}>
              {getFieldDecorator("general_data.date_range.value", {
                rules: [
                  {
                    required: true,
                    message: t(
                      moduleName + ".start_end_date_required"
                    ),
                  },
                  { validator: checkOutRange },
                ],
                initialValue: [
                  general_data?.date_range?.start_date
                    ? moment(
                        general_data?.date_range?.start_date,
                        DATE_FORMAT.day_only_backend
                      )
                    : null,
                  general_data?.date_range?.end_date
                    ? moment(
                        general_data?.date_range?.end_date,
                        DATE_FORMAT.day_only_backend
                      )
                    : null,
                ],
                getValueFromEvent: (e) => {
                  return onChangeDateRange(
                    "general_data.date_range",
                    e
                  );
                },
              })(
                <DatePicker.RangePicker
                  className="mr-8"
                  style={{ width: "53.33333333%" }}
                  format={DATE_FORMAT.day_only}
                />
              )}
              {[
                TIME_TO_RUN_REPORT.EVERY_DAY,
                TIME_TO_RUN_REPORT.DAY_OF_WEEK,
                TIME_TO_RUN_REPORT.DAY_OF_MONTH,
              ].includes(schedule?.scope) && (
                <Typography.Text type="warning" className="ml-8">
                  <Icon type="warning" className="mr-4" />
                  {t(moduleName + ".change_date_range_warning")}
                </Typography.Text>
              )}
            </Form.Item>
          ) : (
            <Row>
              <Col span={16}>
                <Form.Item className="label" {...formItemLayout}>
                  {getFieldDecorator("general_data.date_range.unit", {
                    rules: [
                      {
                        required: true,
                        message: t(
                          moduleName + ".date_range_unit_required"
                        ),
                      },
                    ],
                    initialValue: general_data.date_range?.unit,
                    getValueFromEvent: (e) => {
                      return onChangeField(
                        "general_data.date_range.unit",
                        e
                      );
                    },
                  })(
                    <Select style={{ width: "80%" }} placeholder="cc">
                      {
                        <Option value={RELATIVE_DATE_UNIT[0].value}>
                          {t(
                            moduleName +
                              "." +
                              RELATIVE_DATE_UNIT[0].key
                          )}
                        </Option>
                      }
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  className="label"
                  // label={t(moduleName + ".category")}
                  {...formItemLayout}
                >
                  {getFieldDecorator(
                    "general_data.date_range.offset",
                    {
                      rules: [
                        {
                          required: true,
                          message: t(
                            moduleName + ".date_range_offset_required"
                          ),
                        },
                      ],
                      initialValue: general_data?.date_range.offset,
                      getValueFromEvent: (e) => {
                        return onChangeField(
                          "general_data.date_range.offset",
                          e
                        );
                      },
                    }
                  )(
                    <InputNumber
                      placeholder="Offset"
                      min={1}
                      max={
                        RELATIVE_DATE_UNIT.find(
                          (e) =>
                            e.value === general_data?.date_range.unit
                        ).max
                      }
                    />
                  )}
                  &nbsp;
                  {
                    RELATIVE_DATE_UNIT.find(
                      (e) => e.value === general_data?.date_range.unit
                    ).unit
                  }
                  &nbsp;
                  <Tooltip
                    placement="rightBottom"
                    title={
                      <div>
                        <p>{t(moduleName + ".tooltip.limit_day")}</p>
                      </div>
                    }
                  >
                    <Icon
                      className="info-tooltip-icon"
                      type="info-circle"
                    />
                  </Tooltip>
                </Form.Item>
              </Col>
            </Row>
          )}
        </Col>

        <Col lg={24} xs={24}>
          <Form.Item
            className="label"
            label={t(moduleName + ".select_day")}
          >
            {getFieldDecorator("general_data.week_days", {
              rules: [
                {
                  required: true,
                  message: t(moduleName + ".week_days_required"),
                },
              ],
              initialValue: general_data?.week_days,
              getValueFromEvent: (e) => {
                return onChangeField(
                  "general_data.week_days",
                  e
                );
              },
            })(<Checkbox.Group options={WEEK_DAY_OPTION} />)}
            {renderDayOfWeekWarning()}
          </Form.Item>
        </Col>

        <Col span={24}>
          <Row>
            <Col span={6}>
              <Form.Item label={t("analysis.from_time")}>
                {getFieldDecorator(
                  "general_data.metadata.from_time",
                  {
                    rules: [
                      {
                        required: true,
                        message: t("analysis.from_time_required"),
                      },
                      { validator: checkVaildTime },
                    ],
                    getValueFromEvent: (value) => {
                      onChangeField(
                        "general_data.metadata.from_time",
                        value.format(DATE_FORMAT.hour)
                      );
                      resetFields(["general_data.metadata.to_time"]);
                      validateFields([
                        "general_data.metadata.to_time",
                      ]);
                      return value;
                    },
                    initialValue: general_data.metadata.from_time
                      ? moment(
                          general_data.metadata.from_time,
                          DATE_FORMAT.hour
                        )
                      : null,
                  }
                )(
                  <TimePicker
                    className="w-full"
                    placeholder={t("analysis.from_time_placeholder")}
                    format={TIME_PICKER_FORMAT}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label={t("analysis.to_time")}>
                {getFieldDecorator("general_data.metadata.to_time", {
                  rules: [
                    {
                      required: true,
                      message: t("optimization.to_time_required"),
                    },
                    { validator: checkToTime },
                  ],
                  initialValue: general_data.metadata.to_time
                    ? moment(
                        general_data.metadata.to_time,
                        DATE_FORMAT.hour
                      )
                    : null,
                  getValueFromEvent: (value) => {
                    onChangeField(
                      "general_data.metadata.to_time",
                      value.format(DATE_FORMAT.hour)
                    );
                    resetFields(["general_data.metadata.from_time"]);
                    validateFields([
                      "general_data.metadata.from_time",
                    ]);
                    return value;
                  },
                })(
                  <TimePicker
                    className="w-full"
                    placeholder={t("analysis.to_time_placeholder")}
                    format={TIME_PICKER_FORMAT}
                  />
                )}
              </Form.Item>
            </Col>
            <Col lg={12} xs={24}>
              {renderExtraFields()}
            </Col>
          </Row>
        </Col>
      </>
    )
  }
}

export default withTranslation()(DefaultSubForm);