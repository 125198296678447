export const ACTIONS_LIST = {
  VIEW: "read",
  CREATE: "create",
  EDIT: "update",
  DELETE: "delete",
  ADD: "add",
  REMOVE: "remove",
};
export const RESOURCE_LIST = {
  DASHBOARD: "dashboard",
  USER: "users",
  USER_DETAIL: "users.{user_id}",
  AGENCY: "agencies",
  AGENCY_DETAIL: "agencies.{agency_id}",
  ALARM: "alarm.rules",
  ALARM_RULE: "alarm.rules",
  ALARM_RULE_DETAIL: "alarm.rules.{rule_id}",
  ALARM_RECORD: "alarm.records",
  ALARM_RECORD_DETAIL: "alarm.records.{record_id}",
  INTERSECTION: "intersections",
  INTERSECTION_DETAIL: "intersections.{intersection_id}",
  ANALYSIS: "analysis",
  NOTIFICATIONS: "notifications",
  NOTIFICATIONS_DETAIL: "notifications.{notification_id}",
  OTP: "user.otp",
  USER_CHANGE_PASS: "account.change-password",

  /*
    Note: the following 3 resources are only used in the Users page (only super admins have access to the page). The BE currently does not send
    the permission info related to these categories. However, since super admins can do any action, permission info isn't required.
  */
  ///fake resource for version 1
  USER_ACTIVE: "users.active",
  USER_INACTIVE: "users.inactive",
  USER_UNACTIVATED: "users.unactivated",

  AGENCY_INACTIVE: "agencies.inactive",
  AGENCY_ACTIVE: "agencies.active",
  AGENCY_DETAIL_REMOVE_USER: "agencies.{agency_id}.remove-users",
  AGENCY_DETAIL_ASSIGN_USER: "agencies.{agency_id}.assign-user",

  REPORT: "summary-report.templates",
  REPORT_TEMPLATE: "summary-report.templates",
  REPORT_TEMPLATE_DETAIL: "summary-report.templates.{template_id}",
  REPORT_TEMPLATE_DETAIL_INTERSECTION:
    "summary-report.templates.{template_id}.intersections",

  CORRIDOR: "corridors",
  CORRIDOR_DETAIL: "corridors.{corridor_id}",
  CORRIDOR_ACTIVE: "corridors.active",

  PERFORMANCE_METRIC_REPORT: "performance-metric.templates",
  PERFORMANCE_METRIC_REPORT_TEMPLATE: "performance-metric.templates",
  PERFORMANCE_METRIC_REPORT_TEMPLATE_DETAIL:
    "performance-metric.templates.{template_id}",
  PERFORMANCE_METRIC_REPORT_TEMPLATE_DETAIL_INTERSECTION:
    "performance-metric.templates.{template_id}.intersections",

  REPORT_RESULT: "summary-report.results",
  REPORT_RESULT_DETAIL: "summary-report.results.{result_id}",

  PERFORMANCE_METRIC_REPORT_RESULT: "performance-metric.results",
  PERFORMANCE_METRIC_REPORT_RESULT_DETAIL:
    "performance-metric.results.{result_id}",

  OPTIMIZATION: "optimization",
  OPTIMIZATION_HISTORY: "optimization.histories",
  OPTIMIZATION_DETAIL: "optimization.{optimization_id}",

  ALARM_RULE_INACTIVE: "alarm.rules.inactive",
  ALARM_RULE_ACTIVE: "alarm.rules.active",
  ALARM_RULE_SUBCRIBE: "alarm.rules.subscription",
  ALARM_RULE_EMAIL: "alarm.rules.subscription",
  ALARM_RULE_DETAIL_SUBCRIBER: "alarm.rules.{rule_id}.subcribers",
  ALARM_RULE_DETAIL_INTERSECTION: "alarm.rules.{rule_id}.intersections",

  ALARM_RECORD_SUMARY: "alarm.records.summaries",
  ALARM_RECORD_FORWARD: "alarm.records.notifications",
};
export const FULL_PERMISSION = "*";
export const EGNOR_PERMISSION_MODULE = [
  FULL_PERMISSION,
  RESOURCE_LIST.NOTIFICATIONS,
  RESOURCE_LIST.ANALYSIS,
];
export const LICENSE_LIST = {
  TSP_DASHBOARD: "tsp_dashboard",
  INTERSECTION: "intersection",
  CORRIDOR: "corridor",
  ALARM: "alarm",
  PERFORMANCE_METRICS: "performance_metrics",
  OPTIMIZER: "optimizer",
  REPORT: "report",
};
