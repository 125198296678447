import React, { Component } from "react";
import { Form, Button } from "antd";
import { HORIZONTAL_LAYOUT } from "utils/constants";
import "./style.scss";

/**
 * Component for showing details of the item.
 *
 * @component
 */
class BaseDetailPopupComponent extends Component {
  /**
   * contructor of component
   * @param   {object} props  props of component
   */
  constructor(currentStore, props) {
    super(props);
    this.currentStore = currentStore; //current using data store
    this.formLayout = HORIZONTAL_LAYOUT;
    this.moduleName = "detail";
  }

  /**
   * componentDidMount
   * assign this compenent to props - for parent comment access child function
   * @return  {null}
   */
  componentDidMount = () => {
    const { childRef } = this.props;
    childRef && childRef(this);
  };

  /**
   * checkFormDirty
   * check current form is changed data with current setting formFields
   * @return  {boolean}
   */
  checkFormDirty = () => {
    return this.props.form?.isFieldsTouched(this.formFields);
  };

  /**
   * handleSubmit
   *
   * @param   {Object} e  event of action submit form
   * @return  {Object}
   */
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form?.validateFields((err, values) => {
      if (!err) {
        let submitValue = {
          ...values,
        };
        this.currentStore?.saveData(
          submitValue,
          () => {
            this.currentStore?.closeCreateOrEditForm();
            this.currentStore?.getData();
          },
          (errors) => {
            errors?.map((error) => {
              let message = error?.message;
              let err = {};
              err[error?.field] = {
                value: values[error?.field],
                errors: [new Error(message)],
              };
              this.props.form?.setFields(err);
            });
          }
        );
      }
    });
  };
  /**
   * render.
   *
   */
  render() {
    const {
      t,
      form: { getFieldDecorator },
      onClose,
      editable,
    } = this.props;
    return (
      <Form
        className={
          editable ? "wrap-form-detail" : "wrap-form-detail wrap-form-viewonly"
        }
        layout={this.formLayout}
        onSubmit={this.handleSubmit}
      >
        {this.renderDetailData(this.currentStore?.itemData, getFieldDecorator)}
        {editable && (
          <div className="detail-btn-button-group">
            <Button icon="stop" onClick={onClose}>
              {t("detail.cancel")}
            </Button>
            {
              <Button
                icon="save"
                type="primary"
                loading={this.currentStore?.loading}
                htmlType="submit"
              >
                {t(this.moduleName + ".save")}
              </Button>
            }
          </div>
        )}
      </Form>
    );
  }
}

export default BaseDetailPopupComponent;
