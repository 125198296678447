import { Icon } from "antd";
import React, { Component } from "react";
import "./style.scss";

class CoordCollapseTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseRow: [],
    };
    this.tablePaneRef = React.createRef();
    this.minWidthTable = 0;
  }

  collapseRow = (rowId) => {
    let sliceArray;
    if (this.state.collapseRow.findIndex((e) => e === rowId) !== -1)
      sliceArray = this.state.collapseRow.filter((e) => e !== rowId);
    else sliceArray = [...this.state.collapseRow, rowId];
    this.setState({
      collapseRow: sliceArray,
    });
  };

  componentDidMount() {
    let minWidth = this.tablePaneRef?.current?.getElementsByClassName(
      "child-horizontal-table"
    )[0]?.offsetWidth;
    if (minWidth && !this.minWidthTable) this.minWidthTable = minWidth;
  }

  render() {
    return (
      <div
        className="horizontal-collapse-table-pane"
        style={{ height: this.props.maxHeight ? this.props.maxHeight : null }}
      >
        <div
          className={"horizontal-collapse-table"}
          style={{ minWidth: this.minWidthTable }}
        >
          {this.props.data?.map((breakRow, breakRowId) => {
            return (
              <div key={breakRowId} ref={this.tablePaneRef}>
                <div className="horizontal-row-collapse-btn">
                  <a
                    onClick={() => {
                      this.collapseRow(breakRowId);
                    }}
                  >
                    {!this.state.collapseRow.includes(breakRowId) ? (
                      <Icon type="down"/>
                    ) : (
                      <Icon type="right"/>
                    )}
                    &nbsp;
                    {this.props.collapseField?.label}{" "}{breakRow[this.props.collapseField?.key]}
                  </a>
                  <div>
                    {`Cycle length: ${breakRow.cycle_length} - Offset time: ${breakRow.offset_time} - Sequence number: ${breakRow.sequence_number}`}
                  </div>
                </div>
                {!this.state.collapseRow.includes(breakRowId) &&
                  this.props.tableColumn?.map((keyObject, keyObjectId) => (
                    <table
                      key={keyObject.key}
                      className="child-horizontal-table"
                      style={{ tableLayout: "fixed" }}
                    >
                      <tbody>
                      <tr
                        className={
                          keyObject.key === this.props.highlightRowKey
                            ? "highlight-row"
                            : ""
                        }
                      >
                        <th style={{ borderTop: 0 }} colSpan={1}>{keyObject.label}</th>
                        {breakRow?.phases?.map((cell, cellId) => {
                          if (keyObjectId === 0 && cellId !== 7) {
                            return <td style={{ border: 0 }} key={cellId}>&nbsp;</td>;
                          }
                          return (keyObjectId === 0 && cellId === 7) ? (
                            <td style={{ border: 0 }} key={cellId}>{cell[keyObject?.key]}</td>
                          ) : (
                            <td style={{ borderTop: 0 }} key={cellId}>{cell[keyObject?.key]}</td>
                          )
                        })}
                      </tr>
                      </tbody>
                    </table>
                  ))}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default CoordCollapseTable;
