import { Badge, Button, Descriptions, Radio, Tooltip } from "antd";
import { inject, observer } from "mobx-react";
import moment from "moment";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import {
  CHART_COLOR_SCHEMA,
  CORRIDOR_OFFSET_OPTIMIZATION_MODE,
  DATE_FORMAT,
} from "utils/constants";
import { getFullDateRangeString, getShortDateRangeString } from "utils/helper";
import { exportCSVFile } from "utils/renderExportFile";

@inject("rootStore")
@observer
class HeaderCorridorOffsetOptimiztion extends Component {
  constructor(props) {
    super(props);
  }
  convertCoverPageToExportJsonFormat = (coverData) => {
    const { t } = this.props;
    let coverTranform = [];
    Object.keys(coverData).forEach((e) => {
      switch (e) {
        case "name": {
          coverTranform.push({
            title: t("name"),
            data: coverData[e],
          });
          break;
        }
        case "cycle_time": {
          coverTranform.push({
            title: t("intersection_config.cycle_length"),
            data: coverData[e],
          });
          break;
        }

        case "corridor_id":
          break;
        case "optimization_type": {
          coverTranform.push({
            title: t("optimization." + e),
            data: coverData[e].name,
          });
          break;
        }
        case "owner_id": {
          break;
        }
        case "optimization_type": {
          coverTranform.push({
            title: t("optimization." + e),
            data: coverData[e].name,
          });
          break;
        }
        case "owner": {
          coverTranform.push({
            title: t("optimization." + e),
            data: coverData[e].name,
          });
          break;
        }
        case "week_days": {
          coverTranform.push({
            title: t("optimization." + e),
            data: coverData[e].join(", "),
          });
          break;
        }
        default: {
          coverTranform.push({
            title: t("optimization." + e),
            data: coverData[e],
          });
        }
      }
    });
    return coverTranform;
  };
  convertDataToExportJsonFormat = (resultData) => {
    const { t } = this.props;
    let titleRow = {};
    if (resultData.length) {
      let someElement = resultData[0];
      Object.keys(someElement).forEach((e) => {
        if (e.includes("predicted_phase"))
          titleRow[e] =
            t("optimization.phase") + " " + e.replace("predicted_phase_", "");
        else titleRow[e] = t("optimization." + e);
      });
    }
    return [titleRow, ...resultData];
  };

  convertAogBySecondsToExportJsonFormat = (intData) => {
    const { t } = this.props;
    const arrayData = [];
    intData[0].offset_results.forEach((offset, idOffset) => {
      let offsetObject = {
        offset: offset.offset,
      };
      intData.forEach((int) => {
        const currentOffset = int.offset_results[idOffset];
        offsetObject = {
          ...offsetObject,
          [`Phase 2 - ${int.int_name}`]: currentOffset.predicted_list.find(
            (phase) => phase.phases === 2
          ).predicted_aog,
          [`Phase 6- ${int.int_name}`]: currentOffset.predicted_list.find(
            (phase) => phase.phases === 6
          ).predicted_aog,
          [`Total - ${int.int_name}`]: currentOffset.total_aog,
        };
      });
      arrayData.push(offsetObject);
    });

    let titleRow = {};
    if (arrayData.length) {
      let someElement = arrayData[0];
      Object.keys(someElement).forEach((e) => {
        if (e === "offset") titleRow[e] = t("optimization.offset");
        else titleRow[e] = e;
      });
    }
    return [titleRow, ...arrayData];
  };

  getDirectionLabel = (phase, intersection) => {
    const { t } = this.props;
    if (phase === intersection.upstream_phase) return t("upstream");
    if (phase === intersection.downstream_phase) return t("downstream");
    return t("table.total");
  };
  render() {
    const {
      t,
      chartType,
      print,
      handleChangeMode,
      rootStore: {
        optimizerStore: {
          corridorOffsetStore: { tranformCorridorOffsetData },
        },
      },
    } = this.props;
    const data = tranformCorridorOffsetData;
    return (
      <div ref={this.headerOffsetTable} className="wrap-header-corridor-offset">
        {/* info */}
        <div className="bg-background px-8 py-8">
          <Descriptions
            title={
              <div className="flex justify-space-beetwen">
                <div>
                  <h1>{t("optimization.corridor_offset_optimization")}</h1>
                </div>
                <div>
                  <Button
                    icon="arrow-left"
                    className="mx-8"
                    onClick={() => {
                      this.props.history.push("/analysis/optimizer");
                    }}>
                    {t("detail.back")}
                  </Button>
                  <Button
                    icon="file-excel"
                    onClick={() =>
                      exportCSVFile(
                        this.convertCoverPageToExportJsonFormat(
                          data.general_data
                        ),
                        [
                          ...data.result.intersections.map((int) => {
                            return {
                              title: `${int.int_name}, ${t(
                                "optimization.existing_offset"
                              )}, ${int.current_offset},${t(
                                "optimization.new_offset"
                              )}, ${int.best_offset},`,
                              data: this.convertDataToExportJsonFormat(
                                int.best_results.map((phase) => ({
                                  direction: this.getDirectionLabel(
                                    phase.phases,
                                    int
                                  ),
                                  phase: phase.phases,
                                  existing_aog: phase.existing_aog,
                                  existing_aog_percent:
                                    phase.existing_aog_percent,
                                  predicted_aog: phase.predicted_aog,
                                  predicted_aog_percent:
                                    phase.predicted_aog_percent,
                                  bias: phase.bias,
                                }))
                              ),
                            };
                          }),
                          {
                            title: t("optimization.view_adjustment_table"),
                            data: this.convertAogBySecondsToExportJsonFormat(
                              data.result.intersections
                            ),
                          },
                        ],
                        t("optimization.offset_print_title"),
                        data.general_data.name
                      )
                    }>
                    {t("report_result.export_csv")}
                  </Button>
                  <Button
                    type="primary"
                    icon="printer"
                    className="mx-8"
                    onClick={print}>
                    {t("report_result.print_table")}
                  </Button>
                </div>
              </div>
            }
            column={{ md: 5 }}>
            <Descriptions.Item
              label={t("module_name", {
                module: t("Corridor"),
              })}>
              {data?.general_data.name}
            </Descriptions.Item>
            <Descriptions.Item label={t("optimization.plan_number")}>
              {data?.general_data.plan_number}
            </Descriptions.Item>
            <Descriptions.Item label={t("intersection_config.cycle_length")}>
              {data?.general_data.cycle_time}
            </Descriptions.Item>
            <Descriptions.Item label={t("optimization.bias")}>
              {data?.general_data.bias}
            </Descriptions.Item>
            <Descriptions.Item label={t("optimization.bias_direction")}>
              {data?.general_data.bias_direction}
            </Descriptions.Item>
            <Descriptions.Item label={t("report_result.time_range")}>
              {data?.general_data.start_time} - {data?.general_data.end_time}
            </Descriptions.Item>
            <Descriptions.Item label={t("report_result.date_range")}>
              <Tooltip
                title={
                  <span className="ant-tooltip-pre-wrap">
                    {getFullDateRangeString(data?.general_data, t)}
                  </span>
                }>
                {getShortDateRangeString(data?.general_data, t)}
              </Tooltip>
            </Descriptions.Item>
            <Descriptions.Item label={t("optimization.created_by")}>
              {data?.general_data.owner?.name}
            </Descriptions.Item>

            {/* <Descriptions.Item label={t("report_result.day_of_week")}>
              {data?.general_data.week_days
                ?.map((e) => {
                  return WEEK_DAY_OPTION.find((i) => i.value == e).label;
                })
                .join(", ")}
            </Descriptions.Item> */}
            <Descriptions.Item label={t("optimization.created_at")}>
              {moment(data?.general_data.created_at).format(
                DATE_FORMAT.full_with_tz
              )}
            </Descriptions.Item>
          </Descriptions>
        </div>

        <div className="offset-view-option flex justify-space-beetwen items-center mt-8 mb-8">
          <div>
            <Badge
              className="border-square mx-8"
              color={CHART_COLOR_SCHEMA.GREEN_SIEMENS}
              text={t("optimization.existing_aog_0")}
            />
            <Badge
              className="border-square mx-8"
              color={CHART_COLOR_SCHEMA.DURATION}
              text={t("optimization.predicted_aog")}
            />
          </div>
          <Radio.Group
            buttonStyle="solid"
            value={chartType}
            onChange={(e) => handleChangeMode(e.target.value)}>
            <Radio.Button value={CORRIDOR_OFFSET_OPTIMIZATION_MODE.TABLE}>
              {t(`optimization.table_view`)}
            </Radio.Button>{" "}
            <Radio.Button value={CORRIDOR_OFFSET_OPTIMIZATION_MODE.PIE}>
              {t(`optimization.offset.CHART`)}
            </Radio.Button>
          </Radio.Group>
        </div>
      </div>
    );
  }
}

export default withRouter(withTranslation()(HeaderCorridorOffsetOptimiztion));
