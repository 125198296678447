import BaseApi from "../BaseApi";
import Api from "../Api";
/**
 * Service for user management.
 *
 * @class
 */
class AlarmRuleService extends BaseApi {
  constructor(parentStore) {
    super("alarm/rules", parentStore);
    this.moduleName = "alarm/rules";
    this.dataKey = "rules";
    this.keyField = "id";
  }

  /**
   * create alarm rule
   * @param   {function} payload  payload data
   * @param   {function} cb  callback function
   * @param   {function} fb  falseback function
   * @return  {null} -  call the callback function
   */
  create = (payload, cb, fb) => {
    const api = new Api(this.moduleName, this.parentStore);
    api.postRequest(
      payload,
      (response) => {
        cb && cb(response?.data);
      },
      (error) => {
        fb && fb(error);
      }
    );
  };

  getMetric = (payload, cb, fb) => {
    const api = new Api("alarm/traffic-metrics/all", this.parentStore);
    api.getRequest(
      payload,
      (response) => {
        cb && cb(response?.data);
      },
      (error) => {
        fb && fb(error);
      }
    );
  };

  /**
   * update alarm rule
   * @param   {function} payload  payload data
   * @param   {function} cb  callback function
   * @param   {function} fb  falseback function
   * @return  {null} -  call the callback function
   */
  update = (payload, cb, fb) => {
    const api = new Api(this.moduleName + "/" + payload.id, this.parentStore);
    api.putRequest(
      payload,
      (response) => {
        cb && cb(response?.data);
      },
      (error) => {
        fb && fb(error);
      }
    );
  };

  /**
   * updateStatus
   * this function update status
   * @param   {String} subpathAction  sub url path
   * @param   {Array} data  list id of entities
   * @param   {Function} cb  callback function after success
   * @param   {Function} fb  failback function after fail
   * @return  {null} -  call the callback function
   */
  updateStatus = (subpathAction, data, cb, fb) => {
    const api = new Api(this.moduleName + subpathAction, this.parentStore);
    api.patchRequest(
      data,
      () => {
        cb && cb();
      },
      () => {
        fb && fb();
      }
    );
  };

  /**
   * subscribe
   * this function subscribe rules
   * @param   {Array} data  list id of entities
   * @param   {Function} cb  callback function after success
   * @param   {Function} fb  failback function after fail
   * @return  {null} -  call the callback function
   */
  subscribe = (data, options, cb, fb) => {
    const api = new Api(this.moduleName + "/subscription", this.parentStore);
    api.patchRequest(
      { ...data },
      () => {
        cb && cb();
      },
      () => {
        fb && fb();
      }
    );
  };

  /**
   * unsubscribe
   * this function unsubscribe rules
   * @param   {Array} data  list id of entities
   * @param   {Function} cb  callback function after success
   * @param   {Function} fb  failback function after fail
   * @return  {null} -  call the callback function
   */
  unsubscribe = (data, cb, fb) => {
    const api = new Api(this.moduleName + "/subscription", this.parentStore);
    api.patchRequest(
      { ...data, ...{ action: "UNSUBSCRIBE" } },
      () => {
        cb && cb();
      },
      () => {
        fb && fb();
      }
    );
  };

  /**
   * delete
   * @param   {Array} data  list id of entities
   * @param   {Function} cb  callback function after success
   * @param   {Function} fb  failback function after fail
   * @return  {null} -  call the callback function
   */
  delete = (data, cb, fb) => {
    const api = new Api(
      this.moduleName + "?rule_ids=" + data.join(","),
      this.parentStore
    );
    api.deleteRequest(
      () => {
        cb && cb();
      },
      () => {
        fb && fb();
      }
    );
  };

  /**
   * getRuleDetail
   * this function return detail of rule
   * @param   {int} id  rule id
   * @param   {function} cb  callback function
   * @param   {function} fb  falseback function
   * @return  {null} -  call the callback function
   */
  getDetail = (id, cb, fb) => {
    const api = new Api(this.moduleName + "/" + id, this.parentStore);
    api.getRequest(
      {},
      (response) => {
        cb && cb(response?.data);
      },
      () => {
        fb && fb();
      }
    );
  };

  /**
   * getRuleAnalysis
   * this function return all analysis type
   * @param   {function} cb  callback function
   * @param   {function} fb  falseback function
   * @return  {null} -  call the callback function
   */
  getRuleAnalysis = (cb, fb) => {
    const api = new Api("alarm/analyses", this.parentStore);
    api.getRequest(
      {},
      (response) => {
        cb && cb(response?.data);
      },
      () => {
        fb && fb();
      }
    );
  };

  /**
   * getRuleCategories
   * this function return all categories type
   * @param   {function} cb  callback function
   * @param   {function} fb  falseback function
   * @return  {null} -  call the callback function
   */
  getRuleCategories = (agency_id, cb, fb) => {
    const api = new Api("alarm/categories", this.parentStore);
    api.getRequest(
      { agency_id: agency_id },
      (response) => {
        cb && cb(response?.data);
      },
      () => {
        fb && fb();
      }
    );
  };

  /**
   * getRuleCategories
   * this function return all categories type
   */
  getRuleAvailableUser = (ruleId, cb, fb) => {
    const api = new Api("alarm/rules/subscribers/available", this.parentStore);
    api.getRequest(
      { rule_id: ruleId },
      (response) => {
        cb && cb(response?.data?.subscribers, response?.data?.total_count);
      },
      () => {
        fb && fb();
      }
    );
  };

  /**
   * getRuleCategories
   * this function return all categories type
   */
  getRuleAvailableIntersection = (ruleId, cb, fb) => {
    const api = new Api(
      "alarm/rules/intersections/available",
      this.parentStore
    );
    api.getRequest(
      { rule_id: ruleId },
      (response) => {
        cb && cb(response?.data?.intersections, response?.data?.total_count);
      },
      () => {
        fb && fb();
      }
    );
  };

  /**
   * Run an alarm rule manually
   * @param ruleId
   * @param date local DateTime string
   * @param cb on-success callback
   * @param fb on-error callback
   */
  triggerAlarmRuleManually = (ruleId, date, cb, fb) => {
    const api = new Api(`alarm/rules/${ruleId}/execute`, this.parentStore);
    api.postRequest(
      {
        execute_time: date,
      },
      (response) => {
        cb && cb(response);
      },
      (err) => {
        fb && fb(err);
      }
    );
  };
}

export default AlarmRuleService;
