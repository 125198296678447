import BaseApi from './BaseApi';
import Api from './Api';

class AccountService extends BaseApi {

    constructor(parentStore) {
        super("account", parentStore);
    }
    /**
     * updateLanguage
     * this function update current language of current user
     * @param   {String} language  new language
     * @param   {Function} cb  callback function after success
     * @param   {Function} fb  failback function after fail
     * @return  {null} -  call the callback function
     */
    updateLanguage = (language, cb, fb) => {
        const api = new Api(this.moduleName + "/language", this.parentStore);
        api.patchRequest({ 'language': language }, (response) => {
            cb && cb(response?.data)
        }, () => {
            fb && fb()
        });
    }

    /**
     * switchAgency
     * this function switch user current agency
     * @param   {Object} newAgency
     * @return  {promise} 
     */
    switchAgency = (newAgency) => {
        return new Promise((resolve, reject) => {
            const api = new Api("auth/change-agency", this.parentStore);
            api.postRequest(newAgency, response => {
                resolve(response)
            }, error => {
                reject(error)
            });
        })
    };

    /**
     * getAllAvaiableAgencies
     * get all available agencies for current user
     * @return  {promise} 
     */
    getAllAvaiableAgencies = () => {
        return new Promise((resolve, reject) => {
            const api = new Api(this.moduleName + "/agencies", this.parentStore);
            api.getRequest({}, response => {
                resolve(response?.data)
            }, error => {
                reject(error)
            });
        })
    }

    /**
    * getUserDetail -
    * get current user detail
    * @return  {Promise} -  call the callback function
    */
    getUserDetail = () => {
        return new Promise((resolve, reject) => {
            const api = new Api(this.moduleName, this.parentStore);
            api.getRequest({}, response => {
                resolve(response?.data)
            }, error => {
                reject(error)
            });
        })
    }

    /**
    * saveCurrentProfile -
    * this function save data to DB
    * @param   {Object} data  the body data to push to server
    * @return  {Promise} -  call the callback function
    */
    saveCurrentProfile = (data) => {
        return new Promise((resolve, reject) => {
            let api = new Api(this.moduleName, this.parentStore);
            api.putRequest(data, (response) => {
                resolve(response)
            }, (erros) => {
                reject(erros)
            }, {
                "Content-Type": "multipart/form-data"
            });
        })

    }
    /**
    * saveCurrentProfile -
    * this function save data to DB
    * @param   {Object} data  the body data to push to server
    * @return  {Promise} -  call the callback function
    */
    changeDefaultAgency = (defaultAgency) => {
        return new Promise((resolve, reject) => {
            let api = new Api(this.moduleName + "/default-agency", this.parentStore);
            api.putRequest(defaultAgency, (response) => {
                resolve(response)
            }, (erros) => {
                reject(erros)
            });
        })
    }

    /**
     * recoverPassword
     * this function send reset link to user email
     * @param   {String} email  user email token
     * @return  {Promise} -  return promise function for store
     */
    changePassword = (newPassObj) => {
        return new Promise((resolve, reject) => {
            const api = new Api(this.moduleName + "/change-password", this.parentStore);
            api.putRequest(newPassObj, (response) => {
                resolve(response?.data)
            }, (response) => {
                reject(response)
            });
        })
    }

    /**
     * accessViewUserKey
     * this function send current user to get request access to view userkey
     * @param   {String} email  user email token
     * @return  {Promise} -  return promise function for store
     */
    accessViewUserKey = (currentPassObj) => {
        return new Promise((resolve, reject) => {
            const api = new Api(this.moduleName + "/user-key", this.parentStore);
            api.postRequest(currentPassObj, (response) => {
                resolve(response?.data)
            }, (response) => {
                reject(response)
            });
        })
    }

    /**
     * accessViewUserKey
     * this function send current user to get request access to view userkey
     * @param   {String} email  user email token
     * @return  {Promise} -  return promise function for store
     */
    renewUserKey = (currentPassObj) => {
        return new Promise((resolve, reject) => {
            const api = new Api(this.moduleName + "/new-user-key", this.parentStore);
            api.postRequest(currentPassObj, (response) => {
                resolve(response?.data)
            }, (response) => {
                reject(response)
            });
        })
    }

    /**
     * getAlarmSummaryFrequency
     * this function gets the alarm summary setting for a user. Each agency a user belongs to is a different setting
     * @param   {String} agencyId
     * @return  {Promise}
     */
    getAlarmSummaryFrequency = (agencyId) => {
        return new Promise((resolve, reject) => {
            const api = new Api(this.moduleName + '/settings/notifications?agency_id=' + agencyId, this.parentStore);
            api.getRequest({}, response => {
                resolve(response?.data)
            }, error => {
                reject(error)
            })
        })
    }

    /**
     * changeAlarmSummaryFrequency
     * this function sets the alarm summary setting for a user. Each agency a user belongs to is a different setting
     * @param   {Object} alarmFrequencyObject
     * @return  {Promise}
     */
    changeAlarmSummaryFrequency = (alarmFrequencyObject) => {
        return new Promise((resolve, reject) => {
            const api = new Api(this.moduleName + '/settings/notifications', this.parentStore);
            api.putRequest(alarmFrequencyObject, (response) => {
                resolve(response?.data)
            }, (response) => {
                reject(response)
            });
        })
    }

}

export default AccountService;