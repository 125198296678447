import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import AnalysisFilter from "./AnalysisFilter";
import AnalysisResult from "./AnalysisResult";
import "./style.scss";

@inject("rootStore")
@observer
class Analysis extends Component {
  /**
   * componentDidMount
   *
   */
  componentDidMount() {
    const { t } = this.props;
    document.title = t("analysis.page-title");
  }
  render() {
    return (
      <div className="wrap-analysis">
        <div className="wrap-filter">
          <AnalysisFilter />
        </div>
        <div className="wrap-chart-area">
          <AnalysisResult />
        </div>
      </div>
    );
  }
}

export default withTranslation()(Analysis);
