import React from "react";
import * as d3 from "d3";
import moment from 'moment';
import { FONT_STRING_CAVAS } from "utils/constants";
import BaseRingChart from "../BaseRingChart";
class RingChartTimeline extends BaseRingChart {
  constructor(props) {
    super(props);
    this.margin = {left: 60, right: 25};
  }

  getInitialAxisTimeline = () => {
    this.earliestTime = this.props.from_time;
    this.latestTime = this.props.to_time;

    this.rangeX = [0, moment(this.latestTime) - moment(this.earliestTime)]
    this.rangeY = [0, 100];
    
    this.xScale = d3
      .scaleLinear()
      .domain(this.rangeX)
      .range([0, this.width - this.margin.left - this.margin.right]);
    this.yScale = d3
      .scaleLinear()
      .domain(this.rangeY)
      .range([this.height, 0]);
  }

  drawLabelsTimeline = () => {
    let ctx = this.ctx;
    ctx = this.canvas.node().getContext('2d');

    //Data labels:
    ctx.textAlign = "start";
    ctx.font = `14px ${FONT_STRING_CAVAS}`;

    //Ring 1 and ring 2 labels:
    ctx.font = `14px ${FONT_STRING_CAVAS}`;
    ctx.fillStyle = "#606060";
    // ctx.rotate(Math.PI/2)
    ctx.fillText("Ring 1", -50, 45);
    ctx.fillText("Ring 2", -50, 69);

    //Numbers on x axis:
    ctx.font = `12px ${FONT_STRING_CAVAS}`;
    ctx.fillStyle = "#111111";
    const calculateNumberTicks = (this.width < 300) ? 2 : Math.floor(5/650 * this.width);

    let xTickCount = calculateNumberTicks,
      xTicks = Array.from(Array(xTickCount).keys());
  
    const xTickValues = xTicks.map((value) => {
      let oldTime = moment(this.earliestTime)
      // let timeDiff = moment.duration(value * this.maxCycleLength*1000/(xTickCount-1), 'ms')
      let timeDiff = moment.duration(value * this.rangeX[1]/(xTickCount-1), 'ms')
      let add = moment(oldTime).add(timeDiff)
      return `${add.format("H:mm:ss")}`
    })

    xTicks.forEach((d,i) => {
      ctx.textAlign = "center";
      // ctx.fillText(xTickValues[i], this.xScale(i*this.maxCycleLength*1000/(xTickCount-1)), 95);
      ctx.fillText(xTickValues[i], this.xScale(i*this.rangeX[1]/(xTickCount-1)), 95);
    })
  }

  componentDidMount() {
    this.drawCanvas();
    this.getInitialAxisTimeline();
    this.drawRings();
    this.drawLabelsTimeline();
    this.drawScatterLine();
    if (this.data.events) {
      this.drawEvents();
      this.drawHiddenCanvas();
    }
  }

  render() {
    return(
      <div className="split-monitor-container">
        <div ref={this.areaRef} width={this.width} className="hidden-content-split-monitor"></div>
        <div className="ring-chart" ref={this.chartRef} style={{width:"100%", height: "100%"}}></div>
      </div>
    )
  }
}

export default RingChartTimeline;