import {
  Alert,
  Button,
  Col,
  DatePicker,
  Form,
  Icon,
  InputNumber,
  Row,
  Select,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { DATE_FORMAT } from "utils/constants";
import "./style.scss";
/**
 * Component for base managment module.
 * it's comon behavior of all management componenent
 *
 * @component
 */
const MAX_LENGTH_TAG = 4;
const { Option } = Select;
const { RangePicker } = DatePicker;
const MAX_DAY_RANGE = 30;
const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};
import moment from "moment";
import { convertDate } from "utils/helper";
@inject("rootStore")
@observer
class IntersectionDownloadModal extends Component {
  state = {
    inputVisible: false,
    inputValue: null,
    allTags: [],
  };
  constructor(props) {
    super(props);
  }
  /**
   * checkOutRange
   * check time range validation
   * rule: start time and end time not over 30 days
   * @params {object} rule
   * @params {object} data
   * @params {object} callback
   * @return  {null}
   */
  checkOutRange = (rule, data, callback) => {
    const {
      dataStore: {
        curentDownloadInfo: { from_time, to_time },
      },
    } = this.props;

    const { t, disableExportBtn } = this.props;
    if (Array.isArray(data) && data.length === 2) {
      if (data[1].diff(data[0], "days") > MAX_DAY_RANGE) {
        callback(t("intersection.time_range_over"));
        disableExportBtn(true);
      } else {
        callback();
        disableExportBtn(false);
      }
    }
    if (
      from_time.format("MMMM Do YYYY, h:mm:ss") ===
      to_time.format("MMMM Do YYYY, h:mm:ss")
    ) {
      disableExportBtn(true);
      callback(t("intersection.time_equal"));
    }
  };

  handleClose = async (removedTag) => {
    this.setState({
      allTags: this.state.allTags.filter((e) => e !== removedTag),
    });
  };
  handleAdd = (value) => {
    if (!this.state.allTags?.find((e) => e === value))
      this.setState({
        allTags: [...this.state.allTags, value],
        inputVisible: false,
        inputValue: "",
      });
    else
      this.setState({
        inputVisible: false,
      });
  };
  showInput = () => {
    this.setState({ inputVisible: true }, () => this.input.focus());
  };

  handleInputChange = (e) => {
    this.setState({ inputValue: Number(e) });
  };

  handleInputConfirm = () => {
    const { inputValue } = this.state;
    if (inputValue || inputValue === 0) this.handleAdd(inputValue);
    else
      this.setState({
        inputVisible: false,
      });
  };
  componentDidMount = () => {
    const {
      dataStore: {
        curentDownloadInfo: { int_uuid },
        listData,
        setDownloadFormData,
      },
      disableExportBtn,
    } = this.props;
    setDownloadFormData({
      from_time: moment(
        new Date().toLocaleString("en-US", {
          timeZone: listData.filter((item) => item.id === int_uuid)[0].timezone,
        })
      ).day(-6),
      to_time: moment(
        new Date().toLocaleString("en-US", {
          timeZone: listData.filter((item) => item.id === int_uuid)[0].timezone,
        })
      ),
    });
    disableExportBtn(false);
  };

  saveInputRef = (input) => (this.input = input);
  /**
   * render
   *
   * @return  {Component}
   */
  render() {
    const {
      dataStore: {
        listData,
        listAllEvent,
        curentDownloadInfo: {
          int_uuid,
          name,
          selectedCategoryEvent,
          from_time,
          to_time,
          selectedEvent,
          formDownload,
        },
        setDownloadFormData,
      },
      t,
      disableExportBtn,
      susscessMesage,
      form: { getFieldDecorator },
    } = this.props;
    const { inputVisible, inputValue, allTags } = this.state;

    return (
      <div className="download-raw-data-form">
        {susscessMesage && (
          <Alert
            message={susscessMesage}
            type="success"
            closable={false}
            showIcon
            className="mb-16"
          />
        )}
        <Form>
          <Typography>{t("alarm_rules.time_zone")}</Typography>
          <Row>
            <Select
              className="intersection-timezone"
              value={"timezone"}
              disabled>
              <Option value={"timezone"}>
                {listData.filter((item) => item.id === int_uuid)[0].timezone}
              </Option>
            </Select>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item
                className={"mb-8"}
                label={t("intersection.time_range")}
                {...formItemLayout}>
                {getFieldDecorator("time_range", {
                  rules: [
                    { validator: this.checkOutRange },
                    {
                      required: true,
                    },
                  ],
                  initialValue: [from_time, to_time],
                })(
                  <RangePicker
                    className="w-320"
                    allowClear={false}
                    showTime={{
                      format: DATE_FORMAT.hour_only,
                    }}
                    format={DATE_FORMAT.date_and_hour}
                    disabledDate={(current) => {
                      const timeZone = listData.filter(
                        (item) => item.id === int_uuid
                      )[0].timezone;

                      //To disable dates after today, we need to have today's date:
                      const today = new Date();
                      const date = new Date(current);

                      //Converting to the correct timezone for comparison:
                      const convertedToday = convertDate(today, timeZone);
                      const convertedDate = convertDate(date, timeZone);

                      //If date after today, disable = true:
                      return convertedDate > convertedToday;
                    }}
                    disabledTime={(current, type) => {
                      const timeZone = listData.filter(
                        (item) => item.id === int_uuid
                      )[0].timezone;
                      const getHour = moment(
                        new Date().toLocaleString("en-US", {
                          timeZone: timeZone,
                        })
                      ).hour();
                      const today = moment(
                        new Date().toLocaleString("en-US", {
                          timeZone: timeZone,
                        })
                      ).format(DATE_FORMAT.day_only);
                      const endDate = moment(current[1]?._d).format(
                        DATE_FORMAT.day_only
                      );
                      const range = (start, end) => {
                        const result = [];
                        for (let i = start; i < end; i++) {
                          result.push(i);
                        }
                        return result;
                      };
                      if (type === "end" && endDate === today) {
                        return {
                          disabledHours: () => {
                            return range(getHour + 1, 24);
                          },
                        };
                      }
                      if (
                        type === "start" &&
                        from_time?.format("MMM Do YY") ===
                          to_time?.format("MMM Do YY")
                      ) {
                        return {
                          disabledHours: () => range(to_time.hour(), 24),
                        };
                      }
                    }}
                    onChange={(timeValue) => {
                      if (
                        !timeValue[1]?.hasOwnProperty("_d") ||
                        !timeValue[0]?.hasOwnProperty("_d")
                      ) {
                        disableExportBtn(true);
                      }
                      setDownloadFormData({
                        from_time: timeValue[0],
                        to_time: timeValue[1],
                      });
                    }}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={12} className="flex justify-end">
              <Form.Item
                labelAlign="right"
                className={"w-320 mb-8"}
                label={int_uuid}
                {...formItemLayout}>
                {name}
              </Form.Item>
            </Col>
          </Row>
          <div>
            <Row>
              <Col span={12}>
                <Form.Item
                  label={t("intersection.select_category_event")}
                  {...formItemLayout}
                  className="w-320  mb-8">
                  {getFieldDecorator("select_category_event", {
                    initialValue: selectedCategoryEvent,
                  })(
                    <Select
                      onChange={(value) => {
                        setDownloadFormData({
                          selectedCategoryEvent: value,
                        });
                        setDownloadFormData({
                          selectedEvent: listAllEvent[value]?.event_list
                            ? listAllEvent[value].event_list[0].event_code
                            : null,
                        });
                      }}>
                      <Option value={null}>
                        {t("intersection.all_event_category")}
                      </Option>
                      {Object.keys(listAllEvent).map((key) => (
                        <Option value={key} key={key}>
                          {listAllEvent[key]?.group_name}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col span={12} className="flex justify-end">
                {selectedCategoryEvent && (
                  <Form.Item
                    label={t("intersection.select_event")}
                    className="w-320  mb-8"
                    {...formItemLayout}>
                    {getFieldDecorator("event_code", {
                      rules: [
                        {
                          required: true,
                          message:
                            t("intersection.select_event") + t("is_required"),
                        },
                      ],
                      initialValue: selectedEvent,
                    })(
                      <Select
                        showSearch
                        filterOption={(input, option) => {
                          return (
                            option?.props?.children
                              ?.toLowerCase()
                              ?.indexOf(input?.toLowerCase()) >= 0
                          );
                        }}
                        onChange={(value) => {
                          const existingItem = formDownload.find(
                            (e) => e.event_code === value
                          );
                          setDownloadFormData({
                            selectedEvent: value,
                          });
                          if (existingItem)
                            this.setState({
                              allTags: existingItem.params,
                            });
                          else
                            this.setState({
                              allTags: [],
                            });
                        }}
                        placeholder={t("intersection.select_event")}>
                        {listAllEvent[selectedCategoryEvent]?.event_list.map(
                          (e) => (
                            <Option value={e.event_code} key={e.event_code}>
                              {e.event_label}
                            </Option>
                          )
                        )}
                      </Select>
                    )}
                  </Form.Item>
                )}
              </Col>
              {selectedCategoryEvent ? (
                <Col span={24}>
                  <Form.Item
                    {...formItemLayout}
                    label={t("intersection.enter_params_type", {
                      value: listAllEvent[
                        selectedCategoryEvent
                      ]?.event_list.find((e) => e.event_code === selectedEvent)
                        ?.param_type,
                    })}
                    className="mb-8">
                    <div className="input-tag">
                      {this.state.allTags?.map((item) => {
                        const tag = item;
                        const tagString = tag?.toString();
                        const isLongTag = tagString.length > MAX_LENGTH_TAG;
                        const tagElem = (
                          <Tag
                            className="default-tag"
                            key={tag}
                            closable={!item.is_default}
                            onClose={() => this.handleClose(tag)}>
                            {isLongTag
                              ? `${tagString.slice(0, MAX_LENGTH_TAG)}...`
                              : tagString}
                          </Tag>
                        );
                        if (!item.is_default)
                          return isLongTag ? (
                            <Tooltip title={tag} key={tag}>
                              {tagElem}
                            </Tooltip>
                          ) : (
                            tagElem
                          );
                      })}
                      {inputVisible && (
                        <InputNumber
                          ref={this.saveInputRef}
                          type="text"
                          size="default"
                          value={inputValue}
                          onChange={this.handleInputChange}
                          onBlur={this.handleInputConfirm}
                          onPressEnter={this.handleInputConfirm}
                        />
                      )}
                      {!inputVisible && (
                        <Tag
                          className="p-4 px-8"
                          onClick={this.showInput}
                          style={{ background: "#fff", borderStyle: "dashed" }}>
                          <Icon type="plus" /> {t("intersection.new_param")}
                        </Tag>
                      )}
                    </div>
                  </Form.Item>
                </Col>
              ) : (
                <></>
              )}
            </Row>
            {selectedCategoryEvent ? (
              <Row>
                <div className="w-full flex justify-space-beetwen mb-8">
                  <div>{t("intersection.filter_label")}</div>
                  <div>
                    <Button
                      icon="plus"
                      type="primary"
                      onClick={() => {
                        this.props.form.validateFields((err) => {
                          if (!err) {
                            const selectedEventFind = listAllEvent[
                              selectedCategoryEvent
                            ]?.event_list.find(
                              (e) => e.event_code === selectedEvent
                            );
                            const filterOldata = formDownload.filter(
                              (e) => e.event_code !== selectedEvent
                            );
                            setDownloadFormData({
                              formDownload: [
                                {
                                  event_code: selectedEvent,
                                  param_type: selectedEventFind.param_type,
                                  params: allTags,
                                  event_name: selectedEventFind.event_label,
                                },
                                ...filterOldata,
                              ],
                            });
                          }
                        });
                      }}>
                      {t("intersection.add_filter")}
                    </Button>
                  </div>
                </div>
                <Table
                  pagination={{ pageSize: 8 }}
                  className="spm-table"
                  columns={[
                    {
                      title: t("intersection.select_event"),
                      dataIndex: "event_name",
                      key: "event_name",
                    },
                    {
                      title: t("intersection.param_type"),
                      key: "param_type",
                      dataIndex: "param_type",
                    },
                    {
                      title: t("intersection.params_value"),
                      dataIndex: "params",
                      key: "params",
                      render: (data) => {
                        return data?.length ? data.join(", ") : t("all");
                      },
                    },
                    {
                      title: t("table.actions"),
                      key: "action",
                      render: (item) => {
                        return (
                          <Button
                            icon="delete"
                            type="link"
                            onClick={() => {
                              const newList = formDownload.filter(
                                (e) => e.event_code !== item.event_code
                              );
                              setDownloadFormData({
                                formDownload: newList,
                              });
                            }}
                          />
                        );
                      },
                    },
                  ]}
                  rowKey="event_code"
                  dataSource={formDownload}
                />
              </Row>
            ) : (
              <></>
            )}
          </div>
        </Form>
      </div>
    );
  }
}
const IntersectionDownloadModalForm = Form.create({
  name: "intersection_download_raw",
})(IntersectionDownloadModal);
export default withTranslation()(IntersectionDownloadModalForm);
