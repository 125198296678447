import { createBrowserHistory } from "history";
import { Provider } from "mobx-react";
import React from "react";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import { Router } from "react-router-dom";
import App from "./App";
// import GlobalFonts from "./fonts/font";
import i18n from "./i18n";
import RootStore from "./stores/RootStore";
// import "./styles/react-datetime.scss";

require("dotenv").config();
const history = createBrowserHistory();

const path = (/#!(\/.*)$/.exec(location.hash) || [])[1];
if (path) {
  history?.replace(path);
}
//only run this app if cookies is enable
//cuz we authenticate by cookies
if (cookieEnabled) {
  let rootStore = new RootStore(history);
  ReactDOM.render(
    <Provider rootStore={rootStore}>
      <Router history={history}>
        <I18nextProvider i18n={i18n}>
          {/* <GlobalFonts></GlobalFonts> */}
          <App rootStore={rootStore} />
        </I18nextProvider>
      </Router>
    </Provider>,
    document.getElementById("root")
  );
}
