import { Avatar, Icon, Menu, Popover, Switch, Tooltip } from "antd";
import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { matchPath, withRouter } from "react-router";
import { Link } from "react-router-dom";
import { THEME_DARK } from "utils/constants";
import { privateRoutes } from "../../../Routes";
import { ReactComponent as LogoMoon } from "../../../svgs/moon.svg";
import helper from "../../../utils/helper";
import UserInfoPanel from "../Header/UserInfoPanel";
import "./style.scss";
const { SubMenu } = Menu;

/**
 * Component for Menu on the header bar
 *
 * @component
 */
@inject("rootStore")
@observer
class MenuBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true,
    };
  }

  /**
   * findTheMatchedRoute
   *
   * @return  {String} Matched path
   */
  findTheMatchedRoute = (currentPath) => {
    const { myStore } = this.props;
    let path = currentPath;
    privateRoutes
      ?.filter((item) => {
        return (
          !item.hiddenFromMenu &&
          (item.permission == null ||
            myStore.hasPermission(item.action, item.permission))
        );
      })
      .find((item) => {
        let matchedPath = null;
        if (item.childs && Array.isArray(item.childs)) {
          item.childs.find((subItem) => {
            matchedPath = matchPath(currentPath, {
              path: item.path + subItem.path,
              exact: false,
              strict: true,
            });
            return true;
          });
        } else {
          matchedPath = matchPath(currentPath, {
            path: item.path,
            exact: true,
            strict: true,
          });
        }
        if (matchedPath) {
          path = matchedPath.path;
          return true;
        }
      });
    //hard code for special case of agency detail
    if (path === "/agencies/:id") return "/agencies";
    return path;
  };

  /**
   * render
   *
   * @return  {Component}
   */
  render() {
    const {
      onSwitchTheme,
      rootStore: { commonStore, myStore },
      t,
    } = this.props;
    let user = myStore.getCurrentProfile();
    let agency = myStore.getCurrentAgency();
    const currentTheme = commonStore.getThemeStyle();
    let selectedPath = this.findTheMatchedRoute(location.pathname);
    return (
      // <div>
      <Menu
        selectedKeys={[selectedPath]}
        defaultSelectedKeys={["/"]}
        id="toggle-menu"
        mode="inline"
      >
        <Menu.Item>
          <div className="function-menu-item">
            <Tooltip placement="bottom" title={t("header.help")}>
              <a href="/assets/User_Guide.pdf" target="_blank">
                <Icon type="question-circle" style={{ fontSize: 16 }} />
              </a>
            </Tooltip>
            <Switch
              style={{ maxWidth: "25px" }}
              checkedChildren={<LogoMoon></LogoMoon>}
              unCheckedChildren={<LogoMoon></LogoMoon>}
              checked={currentTheme === THEME_DARK}
              onChange={(e) => onSwitchTheme(e)}
            />
          </div>
        </Menu.Item>
        {privateRoutes
          ?.filter((item) => {
            return (
              !item.hiddenFromMenu &&
              (item.permission == null ||
                myStore.hasPermission(item.action, item.permission, true))
            );
          })
          .map((item) => {
            return !item.childs || item.render_route ? (
              <Menu.Item key={item.path}>
                <Link to={item.path}>
                  {item.icon}
                  <span className="nav-text">{t(item.text)}</span>
                </Link>
              </Menu.Item>
            ) : (
              <SubMenu
                key={item.path}
                title={
                  <span>
                    {item.icon}
                    <span className="nav-text">{t(item.text)}</span>
                  </span>
                }
              >
                {item.childs.map((subItem) => {
                  return !subItem.hiddenFromMenu &&
                    myStore.hasPermission(
                      subItem.action,
                      subItem.permission,
                      true
                    ) ? (
                    <Menu.Item key={item.path + subItem.path}>
                      <Link to={item.path + subItem.path}>
                        {subItem.icon}
                        <span className="nav-text">{t(subItem.text)}</span>
                      </Link>
                    </Menu.Item>
                  ) : null;
                })}
              </SubMenu>
            );
          })}
        <div>
          <Popover
            className={"user-footer-menu"}
            onVisibleChange={commonStore.setVisibleUserPanel}
            trigger="click"
            visible={commonStore.showUserPanel}
            placement="left"
            getPopupContainer={() =>
              document.getElementsByClassName("wrap-header")[0]
            }
            content={<UserInfoPanel currentUser={myStore?.currentProfile} />}
          >
            <div className="profile">
              <Avatar src={user?.avatar_url}>
                {!user?.avarta_url &&
                  helper.getFirstsLetterFromWords(user?.name)?.substring(0, 2)}
              </Avatar>
            </div>
            {!commonStore.isSuperAdmin() ? (
              <Link
                to={"/agencies/" + agency?.agency_id}
                className="agency-info"
              >
                <div className="agency-info-text">
                  <b>{agency?.agency_name}</b>
                </div>
                <div className="agency-info-sub">{agency?.role_name}</div>
              </Link>
            ) : (
              <div className="agency-info">
                <div className="agency-info-text">
                  <b>{"SIEMENS"}</b>
                </div>
                <div className="agency-info-sub">{agency?.role_name}</div>
              </div>
            )}
          </Popover>
        </div>
      </Menu>
      // </div>
    );
  }
}
export default withRouter(withTranslation()(MenuBar));
