import { Col, Select, Tooltip, Input } from "antd";
import BaseManagementComponents from "components/BaseManagementComponents/index";
import { inject, observer } from "mobx-react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import {
  ACTIONS_LIST,
  ACTIVE,
  RESOURCE_LIST,
  INACTIVE,
  DEFAULT_STATUS_LIST,
} from "utils/constants";
const { Option } = Select;
import React from "react";
import StatusTag from "components/StatusTag";
import moment from "moment";
import { DATE_FORMAT } from "utils/constants/index";
@inject("rootStore")
@observer
class Corridor extends BaseManagementComponents {
  constructor(props) {
    super(props.rootStore?.corridorStore, props);
    this.moduleName = "corridor";
    this.resourceName = RESOURCE_LIST.CORRIDOR;
    this.columns = this.getTableColumns();
    this.state = {
      actionObject: null,
    };
    this.enableRedirectPage = true;
    const { t } = props;
    this.pageTitle = t("corridors");
    this.export_name = "";
    this.buttonActions = [
      {
        key: "active_all",
        title: t("active_item", {
          item: t("corridor"),
        }),
        action: (ids) => this.currentStore.setStatus(ids, ACTIVE),
        resource: RESOURCE_LIST.CORRIDOR_ACTIVE,
        permission: ACTIONS_LIST.EDIT,
        type: "check",
        confirm: true,
        confirmTitle: t("active_item", {
          item: t("corridor"),
        }),
        confirmContent: t("active_confirm_content", {
          item: t("corridor"),
        }),
      },
      {
        key: "deactive_all",
        title: t("deactive_item", {
          item: t("corridor"),
        }),
        resource: RESOURCE_LIST.CORRIDOR_ACTIVE,
        action: (ids) => this.currentStore.setStatus(ids, INACTIVE),
        permission: ACTIONS_LIST.EDIT,
        type: "stop",
        confirm: true,
        confirmTitle: t("deactive_item", {
          item: t("corridor"),
        }),
        confirmContent: t("deactive_confirm_content", {
          item: t("corridor"),
        }),
      },
    ];
    this.tableInlineActions = [
      {
        key: "deactive_all",
        tooltip: t("deactive_item", {
          item: t("corridor"),
        }),
        action: this.handleTableAction,
        resource: RESOURCE_LIST.CORRIDOR_DETAIL,
        permission: ACTIONS_LIST.EDIT,
        icon: "stop",
        passingData: (item) => {
          return ["deactive_all", [item?.id]];
        },
        visible: (item) => {
          return item?.status === ACTIVE;
        },
      },
      {
        key: "active_all",
        tooltip: t("active_item", {
          item: t("corridor"),
        }),
        action: this.handleTableAction,
        resource: RESOURCE_LIST.CORRIDOR_DETAIL,
        permission: ACTIONS_LIST.EDIT,
        icon: "check",
        passingData: (item) => {
          return ["active_all", [item?.id]];
        },
        visible: (item) => {
          return item?.status !== ACTIVE;
        },
      },
      {
        key: "edit",
        tooltip: t("report_template.edit"),
        resource: RESOURCE_LIST.CORRIDOR_DETAIL,
        action: this.goToDetailPage,
        permission: ACTIONS_LIST.EDIT,
        icon: "edit",
        passingData: (item) => {
          return [item?.id];
        },
      },
    ];
  }
  goToCreatePage = () => {
    const { history } = this.props;
    history.push("/corridor/create");
  };
  goToDetailPage = (id) => {
    const { history } = this.props;
    history.push("/corridor/" + id);
  };
  componentDidMount() {
    const {
      rootStore: {
        commonStore,
        corridorStore: { setFilterData, setDetaultAgencyFilter, getData },
      },
    } = this.props;
    if (this.props.location?.search?.length > 0) {
      let filterObject = queryString?.parse(this.props.location.search);
      setFilterData(filterObject);
    }
    setDetaultAgencyFilter(commonStore.isSuperAdmin(), () => {
      getData(() => {});
    });
  }
  getTableColumns = () => {
    const { t } = this.props;
    return [
      {
        title: t("module_name", {
          module: t("Corridor"),
        }),
        dataIndex: "name",
        key: "name",
        sorter: true,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: t("number_of_intersection"),
        dataIndex: "num_of_int",
        key: "num_of_int",
      },
      {
        title: t("corridor.global_corridor_default"),
        key: "type",
        render: (item) => {
          return ` ${t("upstream")}: ${item.global_upstream_phase} - ${t(
            "downstream"
          )}: ${item.global_downstream_phase}`;
        },
      },
      {
        title: t("corridor.speed"),
        dataIndex: "speed",
        key: "speed",
      },
      {
        title: t("last_updated_by"),
        dataIndex: "last_modifier.name",
        key: "last_modifier",
      },
      {
        title: t("last_updated_at"),
        dataIndex: "last_modified_at",
        key: "last_modified_at",
        render: (dateString) => {
          return moment(dateString).format(DATE_FORMAT.full);
        },
      },
      {
        title: t("status"),
        dataIndex: "status",
        key: "status",
        sorter: true,
        render: (status) => <StatusTag status={status} />,
        sortDirections: ["descend", "ascend"],
      },
    ];
  };
  renderFilterComponent = () => {
    const {
      t,
      rootStore: {
        commonStore,
        corridorStore: { handleFilterDataChange, filterData, allAgencies },
      },
    } = this.props;

    return (
      <div className="wrap-filter">
        <Col lg={8} xl={6}>
          <Tooltip title={t("search")}>
            <Input
              value={filterData?.text}
              onChange={(e) => handleFilterDataChange("text", e?.target.value)}
              placeholder={t("search")}
            />
          </Tooltip>
        </Col>
        <Col lg={8} xl={4}>
          <Tooltip
            placement="top"
            title={t("filter_by", {
              field: t("status"),
            })}>
            <Select
              onChange={(value) => handleFilterDataChange("status", value)}
              defaultValue={filterData?.status}>
              <Option value={null}>{t("report_template.all_status")}</Option>
              {DEFAULT_STATUS_LIST?.map((e) => (
                <Option key={e.key} value={e.key}>
                  {t(e.value)}
                </Option>
              ))}
            </Select>
          </Tooltip>
        </Col>
        {commonStore.isSuperAdmin() && (
          <Col lg={8} xl={4}>
            <Tooltip
              title={t("filter_by", {
                field: t("agency"),
              })}
              key={allAgencies?.length && allAgencies?.[0]?.id}>
              {filterData.agency_id && (
                <Select
                  showSearch
                  onChange={(value) =>
                    handleFilterDataChange("agency_id", value)
                  }
                  defaultValue={filterData?.agency_id}
                  optionFilterProp="filter"
                  filterOption={(input, option) => {
                    return (
                      option?.props?.filter
                        ?.toLowerCase()
                        ?.indexOf(input?.toLowerCase()) >= 0
                    );
                  }}>
                  {allAgencies?.map((e) => (
                    <Option key={e?.id} value={e?.id} filter={e?.name}>
                      {e?.name}
                    </Option>
                  ))}
                </Select>
              )}
            </Tooltip>
          </Col>
        )}
      </div>
    );
  };
  //   render() {
  //     const { t } = this.props;
  //     return <div className=""></div>;
  //   }
}

export default withRouter(withTranslation()(Corridor));
