import { Button, Slider, Tooltip } from "antd";
import { inject, observer } from "mobx-react";
import "rc-color-picker/assets/index.css";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { DATE_FORMAT } from "utils/constants";
import { TIME_PERIODS } from "utils/constants/dashboardConstant";
import Helper from "utils/helper";
import "./style.scss";

@inject("rootStore")
@observer
class MapHeader extends Component {
  constructor(props) {
    super(props);
    this.sliderRef = React.createRef();
  }

  render() {
    const {
      t,
      rootStore: {
        dashboardStore,
        dashboardStore: {
          changeMapSetting,
          saveDashboardSetting,
          resetMapCenterPointZoomLevel,
        },
      },
    } = this.props;
    let marks = {};
    dashboardStore?.selectedColorList?.color?.map((item, index) => {
      const isShowFull =
        index === 0 ||
        index === dashboardStore?.selectedColorList?.color.length - 1 ||
        (this.sliderRef.current?.offsetWidth &&
          (this.sliderRef.current.offsetWidth /
            (dashboardStore.maxColorGradient -
              dashboardStore.minColorGradient)) *
            (item.from_value -
              dashboardStore?.selectedColorList?.color[index - 1].from_value) >
            20);
      const textTooltip = Helper.getDisplayTextColor(
        item,
        "",
        t,
        dashboardStore.maxColorGradient
      );
      const view = (
        <div className="flex flex-col align-items-center w-fit">
          <div
            style={{
              width: 6,
              height: 6,
              background: item.color,
              borderRadius: "100%",
            }}></div>
          {isShowFull ? (
            <div className="font-12 white-space-no-wrap">{textTooltip}</div>
          ) : null}
        </div>
      );
      marks[item.from_value] = {
        style: {
          color: item.color,
        },
        label: isShowFull ? (
          view
        ) : (
          <Tooltip title={textTooltip}>{view}</Tooltip>
        ),
      };
    });
    let filterValue = [
      dashboardStore.clientSideFilterData.minValue,
      dashboardStore.clientSideFilterData.maxValue,
    ];

    return (
      <div className="heatmap-header">
        <div className=" flex-0-0-auto">
          <div className="widget-header-title">{t("heatmap.map_title")}</div>
          <div className="widget-header-subtitle">
            {dashboardStore.mapWidgetSetting?.period === TIME_PERIODS.CUSTOM
              ? dashboardStore.mapWidgetSetting.timeRange[0]
                  ?.utc()
                  .format(DATE_FORMAT.withTimeZone) +
                " - " +
                dashboardStore.mapWidgetSetting.timeRange[1]
                  ?.utc()
                  .format(DATE_FORMAT.withTimeZone)
              : t(
                  "map_setting." +
                    dashboardStore.mapWidgetSetting?.period?.toLowerCase()
                )}
          </div>
        </div>

        <div className="flex-1-1-auto flex items-center gap-2 justify-end ">
          <div className="flex gap-1 flex-0-0-auto">
            <Tooltip title={t("map_setting.save_zoom_level")}>
              <Button
                onClick={() => {
                  changeMapSetting({ saveZoomLevel: true });
                  saveDashboardSetting();
                }}
                type="primary"
                icon="save"></Button>
            </Tooltip>
            <Tooltip title={t("map_setting.reset_zoom_level")}>
              <Button
                icon="reload"
                onClick={() => {
                  resetMapCenterPointZoomLevel();
                }}></Button>
            </Tooltip>
          </div>
          <div
            className="ml-32 mr-32 relative"
            style={{ width: "50%" }}
            ref={this.sliderRef}>
            <div className="absolute font-12" style={{ top: -4 }}>
              {dashboardStore.selectedColorList.unit
                ? "Unit: " + dashboardStore.selectedColorList.unit
                : null}
            </div>
            {dashboardStore.maxColorGradient && (
              <Slider
                range
                marks={marks}
                value={filterValue}
                className="dashboard-main-slider"
                onChange={(value) => {
                  let min = value[0];
                  let max = value[1];
                  if (min !== dashboardStore.clientSideFilterData.minValue) {
                    dashboardStore.updateClientSideFilterData("minValue", min);
                  } else if (
                    max !== dashboardStore.clientSideFilterData.maxValue
                  ) {
                    dashboardStore.updateClientSideFilterData("maxValue", max);
                  }
                }}
                min={dashboardStore.minColorGradient}
                max={dashboardStore.maxColorGradient}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(MapHeader);
