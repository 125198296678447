import { action, observable, runInAction } from "mobx";
import { CHECK_TOKEN_INTERVAL, TIME_REFRESH_TOKEN } from "utils/constants";
import AuthService from "../services/AuthService";
import localStorageHelper from "../utils/localStorageHelper";
import BaseStore from "./BaseStore";
const SIWAVE_LOGIN_TEMP_KEY = "loginAttempt";
class AuthStore extends BaseStore {
  constructor(parentStore) {
    super(new AuthService(parentStore), parentStore);
  }
  //thread check token expired
  checkTokenIntervel = null;
  //show hide login captcha
  @observable showCapcha = false;
  @observable screenLoading = true;
  tokenExpireTime = null;
  refreshTokenExpireTime = null;
  /**
   * login
   * this function handle login button from UI
   * send login api to server
   * success:
   *       save token to cookie
   *       save user infomation to local storage (user, agency, permission)
   *       then redirect to home
   * fail:
   *      raise fail login atempt - if reach max number show captcha
   *      display server error if have
   *
   * @param   {object} loginInfo  login infomation
   * @return  {null} -
   */
  @action login = (loginInfo) => {
    this.loading = true;
    this.moduleService
      ?.login(loginInfo)
      .then((user) => {
        localStorageHelper.setSessionStorage(SIWAVE_LOGIN_TEMP_KEY, 0);
        runInAction(() => {
          this.showCapcha = false;
        });
        if (user.otp_required) {
          this.parentStore?.history?.push("/otp");
        } else if (user.password_expired) {
          this.parentStore?.history?.push("/change-password");
        } else {
          this.parentStore?.history?.push("/");
        }
      })
      .catch((response) => {
        let loginAttempt =
          parseInt(
            localStorageHelper.getSessionStorage(SIWAVE_LOGIN_TEMP_KEY, 0)
          ) + 1;
        localStorageHelper.setSessionStorage(
          SIWAVE_LOGIN_TEMP_KEY,
          loginAttempt
        );
        if (loginAttempt >= process.env.REACT_APP_MAX_LOGIN_ATTEMPT) {
          runInAction(() => {
            this.showCapcha = true;
          });
        }
        runInAction(() => {
          this.setErrorList(response);
        });
      })
      .finally(() => {
        runInAction(() => {
          this.loading = false;
        });
      });
  };

  /**
   * logout
   * this function handle logout button from UI
   * send logout api to server
   * clean all data on localstorage and cookie.
   * redirect user to login page
   *
   * stop thread auto review token
   * disconnect with socket server
   *
   * @param   {object} loginInfo  login infomation
   * @return  {null} -
   */
  @action logout = () => {
    localStorage.clear();
    this.moduleService?.logout().finally(() => {
      this.stopCheckToken();
      this.parentStore?.socketStore?.disconnect();
      this.clearToken();
      window.location.href = "/login";
    });
  };

  /**
   * saveUserToStorage
   * this function save user to storage
   * @param   {object} data  user data
   * @return  {null} -
   */
  saveUserToStorage = (data) => {
    this.parentStore?.myStore?.setCurrentProfile(data);
  };
  /**
   * saveUserToStorage
   * this function save token expire time
   * @param   {object} data  user data
   * @return  {null} -
   */
  saveTokenExpireTime = (data) => {
    this.tokenExpireTime = data.access_token_exp;
    this.refreshTokenExpireTime = data.refresh_token_exp;
  };
  /**
   * saveCurrentAgencyToStorage
   * this function save agency, action, permission to storage
   * @param   {object} data  user data
   * @return  {null} -
   */
  saveCurrentAgencyToStorage = (data) => {
    this.parentStore?.myStore?.setCurrentAgency(data);
  };

  /**
   * clearToken
   * this function delete all infomation into storage
   * @return  {null} -
   */
  clearToken = () => {
    localStorageHelper.setSessionStorage(SIWAVE_LOGIN_TEMP_KEY, 0);
  };

  /**
   * checkToken
   * this function will automatich check outdate of current token
   * if the token nearly outdate it's will call refresh token API
   * @return  {null} -
   */
  startCheckToken = () => {
    if (!this.checkTokenIntervel) {
      this.checkTokenIntervel = setInterval(() => {
        if (this.tokenExpireTime) {
          const currentTimestamp = new Date().getTime(); //convert milisecon to second
          const tokenDuration = Math.round(
            this.tokenExpireTime - currentTimestamp
          );
          if (tokenDuration < TIME_REFRESH_TOKEN) {
            this.moduleService?.refreshToken().then((result) => {
              this.tokenExpireTime = result?.access_token_exp;
            });
          }

          // const refreshTokenDuration = Math.round(
          //   this.refreshTokenExpireTime - currentTimestamp
          // );
          // if (refreshTokenDuration < TIME_REFRESH_TOKEN) {
          //   this.parentStore.history.push("/login");
          // }
        }
      }, CHECK_TOKEN_INTERVAL);
    }
  };
  /**
   * stopCheckToken
   * this function will stop check outdate of current token
   * @return  {null} -
   */
  stopCheckToken = () => {
    clearInterval(this.checkTokenIntervel);
    this.checkTokenIntervel = null;
  };

  /**
   * resetpassword
   * this function handle recovery password button from UI
   * send resetpassword api to server
   * success:
   *       then redirect to login page
   * fail:
   *      display server error if have
   *
   * @param   {object} value  email resset infomation
   * @param   {object} history  Router history
   * @return  {null} -
   */
  @action resetpassword = (email) => {
    this.loading = true;
    this.moduleService
      ?.recoverPassword(email)
      .then(() => {
        this.parentStore?.history?.push("/login");
      })
      .catch((response) => {
        runInAction(() => {
          this.setErrorList(response);
        });
      })
      .finally(() => {
        runInAction(() => {
          this.loading = false;
        });
      });
  };

  /**
   * finishResetPassword
   * this function handle reset password button from UI
   * send newpassword of user to server
   * success:
   *       then redirect to login page
   * fail:
   *      display server error if have
   *
   * @param   {object} value  email resset infomation
   * @param   {object} history  Router history
   * @return  {Promise} - for client to binding error messages
   */
  @action finishResetPassword = (value) => {
    this.loading = true;
    this.moduleService
      ?.resetPassword(value)
      .then(() => {
        this.parentStore?.history?.push("/login");
      })
      .catch((response) => {
        runInAction(() => {
          this.setErrorList(response);
        });
      })
      .finally(() => {
        runInAction(() => {
          this.loading = false;
        });
      });
  };
  /**
   * checkCurrentCookieAuthentication
   * this function check current cookie vaild or not
   * if valid:
   *    save current info to mem
   * if Fail:
   *    return to login page( view handler)
   * @return  {Promise} - for client to binding error messages
   */
  @action checkCurrentCookieAuthentication = () => {
    return new Promise((resolve) => {
      this.moduleService
        ?.check()
        .then((response) => {
          if (response) {
            this.saveUserToStorage(response?.user_profile);
            this.saveCurrentAgencyToStorage(response?.authority);
            this.saveTokenExpireTime(response);
            this.parentStore.commonStore.setIsFromStatic(response?.is_tactic);
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch(() => {
          resolve(false);
        })
        .finally(() => {
          runInAction(() => {
            this.screenLoading = false;
          });
        });
    });
  };
  /**
   * verifyOTP
   * this function verify OTP to user login
   * if ok re-direct to home
   * @param   {object} payload  otp data
   * @return  {Promise} - for client to binding error messages
   */
  @action verifyOTP = (payload) => {
    this.loading = true;
    return new Promise((resolve) => {
      this.moduleService
        ?.verify(payload)
        .then(() => {
          resolve(true);
          this.parentStore.history.push("/");
        })
        .catch((response) => {
          runInAction(() => {
            this.setErrorList(response);
          });
          resolve(false);
        })
        .finally(() => {
          runInAction(() => {
            this.loading = false;
          });
        });
    });
  };

  /**
   * resendOTP
   * this function resebt OTP to current user email
   * if ok re-direct to home
   * @return  {Promise} - for client to binding error messages
   */
  @action resendOTP = () => {
    this.loading = true;
    return new Promise((resolve) => {
      this.moduleService
        ?.resentOTP()
        .then(() => {
          resolve(true);
        })
        .catch(() => {
          resolve(false);
        })
        .finally(() => {
          runInAction(() => {
            this.loading = false;
          });
        });
    });
  };
}

export default AuthStore;
