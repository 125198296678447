import React, { Component } from "react";

import { Badge, Table } from "antd";
import { LEVEL_COLOR } from "../../../../../utils/constants";
import { withTranslation } from "react-i18next";
import { truncateTimeSecond } from "../../../../../utils/helper";
import "./style.scss";

class DataTable extends Component {
  constructor(props) {
    super(props);
  }

  defaultCellRender = (cell) => (
    <>{cell || cell === 0 ? cell.toFixed(2) : "-"}</>
  );

  render() {
    const { t, title, data } = this.props;
    const columns = [
      {
        title: "Traffic Profile",
        dataIndex: "profile",
        key: "profile",
        width: "7%",
        className: "cell-sm",
        align: "center",
        render: (profile) => (
          <Badge
            className="border-square mr-16"
            color={LEVEL_COLOR[profile - 1]}
            text={t("optimization.profile") + " " + profile}
          />
        ),
      },
      {
        title: "G1 Green Demand (%)",
        children: [
          {
            title: ".Min",
            dataIndex: "group1_min",
            key: "group1_min",
            width: "7%",
            className: "cell-sm",
            align: "center",
            render: this.defaultCellRender,
          },
          {
            title: ".Max",
            dataIndex: "group1_max",
            key: "group1_max",
            width: "7%",
            className: "cell-sm",
            align: "center",
            render: this.defaultCellRender,
          },
          {
            title: ".Avg",
            dataIndex: "group1_avg",
            key: "group1_avg",
            width: "7%",
            className: "cell-sm",
            align: "center",
            render: this.defaultCellRender,
          },
        ],
      },
      {
        title: "G2 Green Demand (%)",
        children: [
          {
            title: ".Min",
            dataIndex: "group2_min",
            key: "group2_min",
            width: "7%",
            className: "cell-sm",
            align: "center",
            render: this.defaultCellRender,
          },
          {
            title: ".Max",
            dataIndex: "group2_max",
            key: "group2_max",
            width: "7%",
            className: "cell-sm",
            align: "center",
            render: this.defaultCellRender,
          },
          {
            title: ".Avg",
            dataIndex: "group2_avg",
            key: "group2_avg",
            width: "7%",
            className: "cell-sm",
            align: "center",
            render: this.defaultCellRender,
          },
        ],
      },
      {
        title: "Group 1 Ratio (%)",
        children: [
          {
            title: ".Min",
            dataIndex: "group1_ratio_min",
            key: "group1_ratio_min",
            width: "7%",
            className: "cell-sm",
            align: "center",
            render: this.defaultCellRender,
          },
          {
            title: ".Max",
            dataIndex: "group1_ratio_max",
            key: "group1_ratio_max",
            width: "7%",
            className: "cell-sm",
            align: "center",
            render: this.defaultCellRender,
          },
          {
            title: ".Avg",
            dataIndex: "group1_ratio_avg",
            key: "group1_ratio_avg",
            width: "7%",
            className: "cell-sm",
            align: "center",
            render: this.defaultCellRender,
          },
        ],
      },
      {
        title: "Time Assignment",
        dataIndex: "time_assignment",
        key: "time_assignment",
        width: "30%",
        className: "cell-xl",
        align: "center",
        render: (timeAssignments) => (
          <div className="full-filled-cell">
            {timeAssignments.map((time, index) => (
              <span key={time.from_time + "-" + time.to_time + "-" + index}>
                {truncateTimeSecond(time.from_time) +
                  "-" +
                  truncateTimeSecond(time.to_time)}
              </span>
            ))}
          </div>
        ),
      },
    ];

    return (
      <div className="data-table">
        <div className=" flex justify-space-beetwen w-full mb-8">
          <div className="font-bold w-full">{title}</div>
        </div>
        <Table
          columns={columns}
          dataSource={data}
          bordered={false}
          size="middle"
          pagination={false}
          className="table-striped-rows"
        />
      </div>
    );
  }
}

export default withTranslation()(DataTable);
