import React, { Component } from "react";
import {
  DATE_FORMAT,
  AGGREGATION_UNIT,
  WEEK_DAY_OPTION,
  VALUE_RESULT_TABLE,
} from "utils/constants";
import moment from "moment";
import { withTranslation } from "react-i18next";
import {
  PDFViewer,
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";

import SiemenFont from "fonts/Siemens_Sans_Roman.ttf";
import SiemenBold from "fonts/Siemens_Sans_Bold.ttf";
import SiemenItalicBold from "fonts/Siemens_Sans_Bold_Italic.ttf";

Font.register({
  family: "siemenfont",
  src: SiemenFont,
  fonts: [
    { src: SiemenFont }, // font-style: normal, font-weight: normal
    { src: SiemenBold, fontWeight: "bold", fontWeight: 700 },
    { src: SiemenItalicBold, fontStyle: "italic", fontWeight: 900 },
  ],
});
const styles = StyleSheet.create({
  cover: {
    fontFamily: "siemenfont",
    backgroundColor: "#becdd7",
    height: "100%",
    width: "100%",
  },
  logo: {
    width: "150px",
    margin: "30px",
    marginLeft: "70%",
  },
  title: {
    backgroundColor: "#009999",
    width: "350px",
    height: "300px",
    padding: "16px",
    marginTop: "55%",
    marginLeft: "30px",
    color: "#fff",
  },
  line: {
    width: "280px",
    marginLeft: "10px",
    marginTop: "10px",
    height: "2px",
    backgroundColor: "#fff",
  },
  site: {
    backgroundColor: "#fff",
    width: "350px",
    height: "30x",
    marginTop: "20px",
    padding: "0 16px",
    marginLeft: "30px",
  },
  siteText: {
    color: "black",
    fontWeight: "900",
    fontSize: "10px",
    textAlign: "justify",
    marginLeft: "10px",
    marginTop: "10px",
    fontWeight: "bold",
  },
  companyText: {
    fontWeight: "bold",
    marginLeft: "10px",
    marginTop: "10px",
    fontSize: "22px",
  },
  titleText: {
    backgroundColor: "#fff",
    marginTop: "10px",
    width: "auto",
    width: "140px",
    color: "#000",
    marginLeft: "8px",
    padding: "4px",
    fontWeight: "bold",
    fontSize: "16px",
  },
  titleTypeText: {
    marginLeft: "10px",
    marginTop: "10px",
    fontWeight: "900",
    fontSize: "12px",
  },
  desText: {
    marginLeft: "10px",
    marginTop: "6px",
    fontSize: "12px",
  },
  header: {
    fontFamily: "siemenfont",
    fontSize: "8px",
    marginTop: "10px",
    fontWeight: "bold",
  },
  headerInfo: {
    fontFamily: "siemenfont",
    color: "#becdd7",
    marginTop: "10px",
    fontSize: "8px",
  },
  footer: {
    fontFamily: "siemenfont",
    textAlign: "left",
    fontSize: "8px",
    fontWeight: "bold",
  },
  footerPage: {
    fontFamily: "siemenfont",
    fontSize: "8px",
  },
  row: {
    fontFamily: "siemenfont",
    flexGrow: 1,
    flexDirection: "row",
    height: "10px",
    width: "100%",
  },
  rowFooter: {
    position: "absolute",
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    left: "0px",
    padding: "0 16px",
    bottom: "16px",
  },
  rowHeader: {
    position: "absolute",
    top: "10px",
    flexGrow: 1,
    flexDirection: "row",
    height: "10px",
    width: "100%",
    marginLeft: "60%",
  },
  lineTable: {
    width: "100%",
    marginLeft: "20px",
    marginTop: "10px",
    height: "0.5px",
    backgroundColor: "#000",
  },
  resultPage: {
    fontFamily: "siemenfont",
    position: "relative",
    top: "40px",
    padding: "0px: 16px",
    marginTop: "5px",
  },
  tableHeadRow: {
    flexGrow: 1,
    flexDirection: "row",
    width: "100%",
    backgroundColor: "#E4F0F6",
    borderBottom: "1px solid black",
    padding: "16px 0",
  },
  tableBodyRow: {
    flexGrow: 1,
    flexDirection: "row",
    width: "100%",
    borderBottom: "1px solid black",
  },
  intersectionColumn: {
    width: "15vw",
    flexDirection: "column",
    fontSize: "9px",
    marginLeft: "14px",
    textAlign: "left",
  },
  valueColumn: {
    width: "11vw",
    flexDirection: "column",
    fontSize: "9px",
    textAlign: "left",
  },
  rowHighLight: {
    backgroundColor: "#FAFAFA",
  },
  cellText: {
    padding: "4px 0",
  },
  GOOD: {
    width: 0,
    height: 0,
    borderLeft: "4px solid white",
    borderRight: "4px solid white",
    borderBottom: "6px solid #2ecc71",
  },
  BAD: {
    width: 0,
    height: 0,
    borderTop: "6px solid #e74c3c",
    borderLeft: "4px solid white",
    borderRight: "4px solid white",
    borderBottom: "1px solid white",
  },
  NORMAL: {
    width: "9px",
    height: "2px",
    backgroundColor: "#f1c40f",
  },
  flex: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "row",
    alignItems: "center",
  },
  trending: {
    marginRight: "4px",
    width: "9px",
  },
});

const pageSize = 8;
class PrintPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      pageSize: "A4",
    };
  }
  componentDidMount() {}
  renderCoverPage = (coverData, numberOfIntersection, prevDay) => {
    const { t } = this.props;
    return (
      <View style={styles.cover}>
        <Image
          style={styles.logo}
          src={process.env.PUBLIC_URL + "/img/logo.png"}
        />
        <View style={styles.title}>
          <Text style={styles.companyText}>YUTRAFFIC Insights</Text>
          <Text style={styles.titleText}>
            {" "}
            {t("report_result.print_title")}
          </Text>
          <View style={styles.line}></View>
          <Text style={styles.titleTypeText}>
            {t("report_result.name")}: &nbsp;{coverData?.name}
          </Text>
          <Text style={styles.desText}>
            {t("report_result.agency_print")}:&nbsp; {coverData?.agency_name}
          </Text>
          <Text style={styles.desText}>
            {t("report_result.time_range")}: &nbsp;
            {coverData?.from_time}
            {" - "}
            {coverData?.to_time}
          </Text>
          <Text style={styles.desText}>
            {t("report_result.date_range")}: &nbsp;
            {coverData?.from_date}
            {" - "}
            {coverData?.to_date}
          </Text>
          <Text style={styles.desText}>
            {t("report_result.time_zone")}: &nbsp;
            {coverData?.time_zone}
          </Text>
          <Text style={styles.desText}>
            {t("report_result.day_of_week")}:&nbsp;
            {coverData?.week_days
              .map((e) => {
                return WEEK_DAY_OPTION.find((i) => i.value == e).label;
              })
              .join(", ")}
          </Text>
          <Text style={styles.desText}>
            {t("report_result.aggregation")}:&nbsp;
            {t(
              AGGREGATION_UNIT.find((e) => e.value === coverData.aggregation)
                .text
            )}
          </Text>
          <Text style={styles.desText}>
            {t("report_result.selected_intersection")}: &nbsp;
            {numberOfIntersection}
          </Text>
          {prevDay && (
            <Text style={styles.desText}>
              {t("report_result.prev_result_time")}: &nbsp;
              {prevDay}
            </Text>
          )}
        </View>
        <View style={styles.site}>
          <Text style={styles.siteText}>{t("print.site")}</Text>
        </View>
      </View>
    );
  };
  renderTableDataPaging = (index) => {
    const { tableData } = this.props;
    let componentRow = [];
    let max = index + pageSize;
    if (index + pageSize > tableData.length) max = tableData.length;
    for (let j = index; j < max; j++)
      componentRow.push(
        <View
          key={j}
          style={
            j % 2 == 0
              ? { ...styles.tableBodyRow, ...styles.rowHighLight }
              : styles.tableBodyRow
          }
        >
          {Object.keys(tableData[j]).map((e, index) => {
            if (e !== "key")
              if (e === "intersection_name")
                return (
                  <View
                    key={e + index}
                    style={{ ...styles.intersectionColumn, ...styles.flex }}
                  >
                    <Text>{tableData[j][e]}</Text>
                  </View>
                );
              else return this.getCellItem(tableData[j][e], e + index);
          })}
        </View>
      );
    return componentRow;
  };
  getCellItem = (item, key) => {
    const { t } = this.props;
    return (
      <View key={key} style={styles.valueColumn}>
        {VALUE_RESULT_TABLE.map((keyName) => {
          return (
            <View key={keyName} style={styles.flex}>
              <View
                style={{
                  ...styles[item[keyName].trending],
                  ...styles.trending,
                }}
              ></View>
              <View>
                <Text style={styles.cellText}>
                  {t("result_table." + keyName) + item[keyName].value}
                </Text>
              </View>
            </View>
          );
        })}
      </View>
    );
  };
  renderAllDataPage = () => {
    const { printInfo, t, tableColumn, tableData } = this.props;
    let componentArray = [];

    for (let i = 0; i < tableData.length; i += pageSize) {
      componentArray.push(
        <Page key={i} size="A4" orientation="landscape">
          <View style={styles.rowHeader}>
            <Text style={styles.header}>{t("print.header")} | </Text>
            <Text style={styles.headerInfo}>
              {t("report_result_print.header_created_by")} : &nbsp;
              {printInfo.owner?.name} |{" "}
            </Text>
            <Text style={styles.headerInfo}>
              {t("report_result_print.header_created_at")} : &nbsp;
              {moment().format(DATE_FORMAT.full)} |{" "}
            </Text>
          </View>
          <View style={styles.resultPage}>
            <View style={styles.tableHeadRow}>
              {tableColumn.map((e) => (
                <View
                  key={e.dataIndex + i}
                  style={
                    e.dataIndex === "intersection_name"
                      ? styles.intersectionColumn
                      : styles.valueColumn
                  }
                >
                  <Text>{e.title}</Text>
                </View>
              ))}
            </View>
            {this.renderTableDataPaging(i)}
          </View>
          <View style={{ ...styles.row, ...styles.rowFooter }}>
            <Text style={styles.footer}>
              {"© " + moment().year() + t("print.footer")}
            </Text>
            <Text style={styles.footerPage}>
              {t("print.footer-page")} : {Math.ceil(i / 10) + 1}
            </Text>
          </View>
        </Page>
      );
    }
    return componentArray;
  };
  renderPDFFile = () => {
    const { printInfo, tableData, prevDay } = this.props;
    return (
      <PDFViewer className="chart-print-preview">
        <Document>
          <Page size="A4" orientation="portrait">
            {this.renderCoverPage(printInfo, tableData?.length, prevDay)}
          </Page>
          {this.renderAllDataPage()}
        </Document>
      </PDFViewer>
    );
  };
  render() {
    return this.renderPDFFile();
  }
}

export default withTranslation()(PrintPreview);
