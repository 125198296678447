import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { withTranslation } from "react-i18next";
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import DetailTrafficProfileChart from "./Charts/DetailTrafficProfileChart";
import MinMaxTrafficProfileChart from "./Charts/MinMaxTrafficProfileChart";
import {
  DATE_FORMAT,
  GRAPHIC_CHART_TYPE,
  OFFSET_OPTIMIZER_RANDOM_COLOR,
  TRAFFIC_PROFILE_CHART_MODE,
  ROOT_LEGEND_MINMAX,
  LEGEND_MIN_MAX,
} from "utils/constants";
import PrintPreview from "./PrintPreview";
import { Checkbox, Modal } from "antd";
import moment from "moment";
const DEFAULT_END_TIME = "00:00:00";

@inject("rootStore")
@observer
class DayOfWeekChartList extends Component {
  constructor(props) {
    super(props);
    this.data = [];
    this.rootData = [];
  }
  onChangeLegend = (index, legends, check, chartMode) => {
    const { onChangeLegend, hiddenLegend } = this.props;
    let newLegend = {
      ...hiddenLegend,
    };
    if (!check) {
      if (newLegend[chartMode][index])
        newLegend[chartMode][index] = [
          ...newLegend[chartMode][index],
          ...legends,
        ];
      else newLegend[chartMode][index] = [...legends];
    } else {
      newLegend[chartMode][index] = newLegend[chartMode][index]?.filter(
        (e) => !legends.includes(e)
      );
    }
    onChangeLegend(newLegend);
  };
  getLegend = (index, chartMode) => {
    const weekday = this.rootData[index].timeline;
    const data = this.rootData[index].days[weekday];
    let legend = [];
    if (chartMode === TRAFFIC_PROFILE_CHART_MODE.MAX_MIN) {
      legend = LEGEND_MIN_MAX;
    } else
      data?.forEach((day, indexDay) => {
        legend.push({
          key: `group-1-${weekday}-${day.date}`,
          label: `G1-${weekday.slice(0, 3)}-${day.date}`,
          color: OFFSET_OPTIMIZER_RANDOM_COLOR[indexDay],
          unit: "%",
          yAxis: "left",
          toolTip: (value) => parseFloat(value).toFixed(2),
          type: GRAPHIC_CHART_TYPE.line,
          legendTooltip: "analysis.group2",
        });
        legend.push({
          key: `group-2-${weekday}-${day.date}`,
          label: `G2-${weekday.slice(0, 3)}-${day.date}`,
          color: OFFSET_OPTIMIZER_RANDOM_COLOR[indexDay],
          unit: "%",
          yAxis: "left",
          toolTip: (value) => parseFloat(value).toFixed(2),
          type: GRAPHIC_CHART_TYPE.line,
          legendTooltip: "analysis.group2",
        });
      });
    return legend;
  };
  tranformPlanData = (planData) => {
    return planData.map((e) => {
      return {
        ...e,
        from_time: moment(e.from_time, DATE_FORMAT.hour).valueOf(),
        to_time: moment(
          e.to_time === DEFAULT_END_TIME ? "23:59" : e.to_time,
          DATE_FORMAT.hour
        ).valueOf(),
      };
    });
  };
  tranformChartData = (index) => {
    const weekday = this.rootData[index].timeline;
    const data = this.rootData[index].days[weekday];
    let tranformData = data[0].data_points?.map((dataDay) => {
      return {
        from_time: moment(dataDay.from_time, DATE_FORMAT.hour).valueOf(),
        to_time: moment(
          dataDay.to_time === DEFAULT_END_TIME ? "23:59" : dataDay.to_time,
          DATE_FORMAT.hour
        ).valueOf(),
      };
    });

    data?.forEach((day) => {
      day.data_points.forEach((dataDay, indexData) => {
        tranformData[indexData][`group-1-${weekday}-${day.date}`] =
          dataDay.group1;
        tranformData[indexData][`group-2-${weekday}-${day.date}`] =
          dataDay.group2;
      });
    });
    return tranformData;
  };
  rowRenderer = ({ index, style, isPrintMode, chartModePrint }) => {
    const {
      rootStore: {
        optimizerStore: { selectedItem },
      },
      hiddenLegend,
      from_time,
      to_time,
    } = this.props;
    const chartMode = chartModePrint ? chartModePrint : this.props.chartMode;
    this.rootData = selectedItem.charts;
    const weekday = this.rootData[index].timeline;
    const dataTranform = this.tranformChartData(index);
    const LEGEND = this.getLegend(index, chartMode);
    const PLAN_DATA = this.tranformPlanData(
      this.rootData[index].cluster_blocks
    );
    const maxminDataTranform = this.tranformPlanData(
      this.rootData[index].cluster_data
    );
    const fromTime = moment(from_time, DATE_FORMAT.hour).valueOf();
    const toTime = moment(to_time, DATE_FORMAT.hour).valueOf();
    return (
      <div className="ListItem px-8 py-8" style={style} key={index}>
        <div className=" flex justify-space-beetwen w-full">
          <div className="font-bold w-full ml-8">{weekday}</div>
        </div>
        <div className="h-full flex">
          <div className="w-full">
            {chartMode === TRAFFIC_PROFILE_CHART_MODE.DETAIL ? (
              <DetailTrafficProfileChart
                isPrintMode={isPrintMode}
                chartLegend={LEGEND}
                plan_statistics={PLAN_DATA}
                from_time={fromTime}
                to_time={toTime}
                hiddenLegend={
                  hiddenLegend[chartMode][index]
                    ? hiddenLegend[chartMode][index]
                    : []
                }
                data={{
                  days: dataTranform,
                }}
              />
            ) : (
              <MinMaxTrafficProfileChart
                isPrintMode={isPrintMode}
                chartLegend={ROOT_LEGEND_MINMAX}
                plan_statistics={PLAN_DATA}
                from_time={fromTime}
                to_time={toTime}
                hiddenLegend={
                  hiddenLegend[chartMode][index]
                    ? hiddenLegend[chartMode][index]
                    : []
                }
                data={maxminDataTranform}
              />
            )}
          </div>
          {!isPrintMode && (
            <div
              className="w-240  styled-scroll"
              style={{ marginTop: "1.25%", marginBottom: "2.5%" }}
            >
              <div className="flex chart-info-legend">
                <div>
                  {LEGEND.map((legend) => (
                    <div className="w-full py-8" key={legend.key}>
                      <Checkbox
                        checked={
                          !hiddenLegend[chartMode][index]?.includes(legend.key)
                        }
                        style={{ "--checkbox-chart-color": legend.color }}
                        onChange={(e) => {
                          this.onChangeLegend(
                            index,
                            [legend.key, legend.extendField],
                            e.target.checked,
                            chartMode
                          );
                        }}
                      >
                        {legend.label}
                      </Checkbox>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };
  render() {
    const {
      t,
      rootStore: {
        optimizerStore: { selectedItem },
      },
      visiblePrint,
      setPrintModeVisible,
    } = this.props;

    return (
      <div className="w-full h-full">
        <Modal
          title={t("print.modal-title")}
          centered
          width="80vw"
          visible={visiblePrint}
          destroyOnClose={true}
          footer={[]}
          onCancel={() => setPrintModeVisible(false)}
        >
          <PrintPreview
            getLegend={this.getLegend}
            printInfo={selectedItem.general_data}
            Chart={this.rowRenderer}
            data={selectedItem?.charts}
          />
        </Modal>
        <AutoSizer>
          {({ height, width }) => (
            <List
              className="list"
              height={height}
              itemCount={selectedItem?.charts.length}
              width={width}
              itemSize={width * 0.3}
            >
              {this.rowRenderer.bind(this)}
            </List>
          )}
        </AutoSizer>
      </div>
    );
  }
}

export default withTranslation()(DayOfWeekChartList);
