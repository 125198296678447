import React, { Component } from 'react'
import { Result, Button } from 'antd'
import { inject } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import './style.scss'

@inject('rootStore')
class UnAuthenticate extends Component {

  render() {
    const { t, rootStore: { authStore } } = this.props;
    //stop check Toeken
    authStore.stopCheckToken();
    return <div className="unauthen-wrap">
      <Result
        title={t('message.401')}
        extra={
          <Button type="primary" key="console" onClick={() => authStore?.logout()}>
            {t('common.back_login')}
          </Button>
        }
      />
    </div>
  }
}

export default withTranslation()(UnAuthenticate);