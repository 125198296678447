import AgencyService from "../services/AgencyService";
import BaseStore from "./BaseStore";
import { action, runInAction, observable } from "mobx";
import {
  AVAILABLE,
  DATE_FORMAT,
  RESOURCE_LIST,
  TIME_PICKER_FORMAT,
} from "utils/constants";
import IntersectionService from "services/IntersectionService";
import CustomAnalysisService from "services/CustomAnalysisService";
import helper from "utils/helper";
import * as moment from "moment";

/**
 * Store for user management.
 *
 * @class
 */

const FORM_DATA_MODAL = {
  nameAnalysis: "",
  agency_uuid: "",
  int_uuid: "",
  name: "",
  selectedCategoryEvent: null,
  selectedEvent: "all",
  formDownload: [],
};
const DATA_CHART = {
  height: "50%",
  isPrintMode: false,
  chartType: "custom_analysis",
};
class CustomAnalysisStore extends BaseStore {
  constructor(parentStore) {
    super(new CustomAnalysisService(parentStore), parentStore);
    this.agencyService = new AgencyService(parentStore); //agency service
    this.resourceName = RESOURCE_LIST.AGENCY;
    this.filterData = {
      ...this.filterData,
      ...{
        nameAnalysis: "",
        intersectionName: "",
        date: moment(),
        time_start: moment("00:00", TIME_PICKER_FORMAT),
        time_end: moment("23:59", TIME_PICKER_FORMAT),
        agency_id: null,
        compare_from_time: "",
        compare_to_time: "",
      },
    };
  }
  @observable listAllEvent = [];
  @observable curentDownloadInfo = { ...FORM_DATA_MODAL };
  @observable listAgencies = [];
  @observable listCustomAnalysis = [];
  @observable selectedAnalysis = null;
  @observable loading = false;
  @observable listIntersectionByAgency = [];
  @observable objDataChart = null;
  @observable objRunAnalysis = null;
  @observable nameIntersection = null;
  @observable listGroupEventFilter = [];
  @observable listLegend = [];
  @observable objChartFilterByParam = {};
  @observable objChartFilterByEventGroup = {};
  @observable chartInfor = null;
  @observable dataAnalysisChart = {
    ...DATA_CHART,
    data: {
      data_analysis: [],
    },
    // hiddenLegend: listDataChart,

    from_time: "",
    to_time: "",
  };
  @observable dataFilterChart = {
    listEventGroup: [],
    listParam: [],
  };
  @observable isRunAnalysis = false;
  //Custom Analysis

  @action setDownloadFormData = (value) => {
    this.curentDownloadInfo = {
      ...this.curentDownloadInfo,
      ...value,
    };
  };

  @action handleFilterDataChange = (target, value) => {
    if (this.filterData) {
      this.filterData[target] = value;
    }
  };

  @action getListAllCustomAnalysisStore = (agency_uuid) => {
    this.loading = true;
    return new Promise((resolve) => {
      this.moduleService
        .getListAllCustomAnalysis(agency_uuid)
        .then((response) => {
          runInAction(() => {
            this.listCustomAnalysis = response.result;
            this.getCustomAnalysisById(response.result[0]?.id ?? null);
          });
          resolve();
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          runInAction(() => {
            this.loading = false;
          });
        });
    });
  };

  @action getAgencySettingByAgencyId = () => {
    this.loading = true;
    return new Promise((resolve, reject) => {
      this.agencyService?.fetchAgencySetting(
        this.filterData.agency_id,
        (response) => {
          this.currentColorSettingMatrix = response?.display;
          resolve(response);
          runInAction(() => {
            this.loading = false;
            if (response?.location && response?.location?.lat) {
              this.currentMapCenter = [
                response?.location.lat,
                response?.location.lon,
              ];
            }
          });
        },
        (errors) => {
          runInAction(() => {
            this.loading = false;
          });
          reject(errors);
        }
      );
    });
  };

  @action getCustomAnalysisById = (idData) => {
    this.selectedAnalysis = this.listCustomAnalysis.find(
      (e) => e.id === idData
    );
  };

  @action setNameIntersectionData = (data) => {
    runInAction(() => {
      this.nameIntersection = data;
    });
  };

  @action resetForm = () => {
    this.curentDownloadInfo = {
      ...FORM_DATA_MODAL,
    };
  };

  /**
   * getAllPerlogEvent
   * @return  {Promise} -  return Promise
   */
  getAllPerlogEvent = () => {
    const intersectionService = new IntersectionService(this.parentStore);
    intersectionService
      .getAllPerlogEvent()
      .then((data) => {
        runInAction(() => {
          this.listAllEvent = data;
          // console.log("helo")
          // console.log(Object.keys(data[0]))
        });
      })
      .catch(() => {})
      .finally(() => {
        runInAction(() => {
          this.loading = false;
        });
      });
  };

  /**
   * getAllAgencies
   * get all agencies
   * @return  {null} -  call the callback function
   */
  @action getAllAgencies = () => {
    this.listAgencies = [];
    return new Promise((resolve, reject) => {
      this.agencyService.getAll(
        (agencies) => {
          runInAction(() => {
            this.listAgencies = agencies;
            this.getAllIntersectionsByAgencyStore(agencies[0].id);
            this.getListAllCustomAnalysisStore(agencies[0].id);
            //set default agency
            if (this.listAgencies && this.listAgencies.length > 0) {
              let defaultAgency = this.listAgencies.find(
                (item) => item.id === this.filterData.agency_id
              );
              if (!defaultAgency) {
                this.filterData.agency_id = this.listAgencies[0]?.id;
              }
            }
            resolve(agencies);
          });
        },
        (errors) => {
          reject(errors);
        }
      );
    });
  };
  @action postNewCustomAnalysisStore = (data) => {
    return new Promise((resolve) => {
      this.moduleService
        .postNewCustomAnalysis(data)
        .then(() => {
          runInAction(() => {
            this.getListAllCustomAnalysisStore(this.filterData.agency_id);
          });
          resolve();
        })
        .catch((error) => {
          if (error && Array.isArray(error.errors)) {
            let message = "";
            error.errors.map((item) => {
              message += item.message + "\n";
            });
            if (message != "") {
              helper.showNotification("error", error.key, message);
            }
          }
        })
        .finally(() => {
          runInAction(() => {
            this.loading = false;
          });
        });
    });
  };
  @action updateCurrentAnalysisStore = (id, payload) => {
    this.loading = true;
    return new Promise((resolve) => {
      this.moduleService
        .updateCurrentCustomAnalysis(id, payload)
        .then(() => {
          runInAction(async () => {
            const currentSelectdAnalysis = { ...this.selectedAnalysis };
            await this.getListAllCustomAnalysisStore(this.filterData.agency_id);
            this.getCustomAnalysisById(currentSelectdAnalysis.id);
          });
          resolve();
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          runInAction(() => {
            this.loading = false;
          });
        });
    });
  };
  @action deleteCurrentCustomAnalysisStore = (id) => {
    return new Promise((resolve) => {
      this.moduleService
        .deleteCurrentCustomAnalysis(id)
        .then(() => {
          runInAction(async () => {
            await this.getListAllCustomAnalysisStore(this.filterData.agency_id);
            this.getCustomAnalysisById(this.listCustomAnalysis[0].id);
          });
          resolve();
        })
        .catch((error) => {
          if (error && Array.isArray(error.errors)) {
            let message = "";
            error.errors.map((item) => {
              message += item.message + "\n";
            });
            if (message != "") {
              helper.showNotification("error", error.key, message);
            }
          }
        })
        .finally(() => {
          runInAction(() => {
            this.loading = false;
          });
        });
    });
  };
  @action getAllIntersectionsByAgencyStore = (id) => {
    return new Promise((resolve) => {
      this.moduleService
        .getAllIntersectionsByAgency({
          agency_id: id,
          status: AVAILABLE,
        })
        .then((response) => {
          const dataLisiIntersection = response.map((e) => {
            return {
              name: e.name,
              id: e.id,
            };
          });
          runInAction(() => {
            this.listIntersectionByAgency = dataLisiIntersection;
          });
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          runInAction(() => {});
        });
    });
  };
  @action setAllDataChart = (data) => {
    runInAction(() => {
      this.dataAnalysisChart = {
        ...this.dataAnalysisChart,
        data: {
          allDataChart: data,
          dataChartArr: Object.values(this.objDataChart).flat(),
        },
      };
    });
  };
  @action filterAnalysisByParamandEventGroup = (field, value) => {
    runInAction(() => {
      this.dataFilterChart[field] = value;
      if (
        this.dataFilterChart?.listParam?.length > 0 &&
        this.dataFilterChart?.listEventGroup?.length > 0
      ) {
        let resultEventGroup = {};
        for (let i = 0; i < this.dataFilterChart.listEventGroup?.length; i++) {
          const key = this.dataFilterChart.listEventGroup[i];
          if (this.objDataChart?.hasOwnProperty(key)) {
            resultEventGroup[key] = this.objDataChart[key];
          }
        }
        const listParamTrasform = this.dataFilterChart?.listParam?.map((item) =>
          parseInt(item)
        );
        let resultParam = {};
        for (let key in resultEventGroup) {
          resultParam[key] = resultEventGroup[key].filter((item) =>
            listParamTrasform.includes(item.parameter)
          );
        }
        this.listLegend = Object.keys(resultParam);
        let itemData = [];
        for (let key in resultParam) {
          let obj = {};
          obj[key] = resultParam[key];
          itemData.push(obj);
        }
        this.setAllDataChart(itemData);
      } else if (
        this.dataFilterChart.listEventGroup?.length === 0 &&
        this.dataFilterChart.listParam?.length > 0
      ) {
        const listParamTrasform = this.dataFilterChart?.listParam.map((item) =>
          parseInt(item)
        );
        let result = {};
        for (let key in this.objDataChart) {
          result[key] = this.objDataChart[key].filter((item) =>
            listParamTrasform.includes(item.parameter)
          );
        }
        const resultByParam = Object.keys(result).reduce((acc, key) => {
          if (result[key].length > 0) {
            acc[key] = result[key];
          }
          return acc;
        }, {});
        this.listLegend = Object.keys(resultByParam);

        let itemDataChart = [];
        for (var key in resultByParam) {
          var obj = {};
          obj[key] = resultByParam[key];
          itemDataChart.push(obj);
        }
        this.setAllDataChart(itemDataChart);
      } else if (
        this.dataFilterChart.listEventGroup?.length > 0 &&
        this.dataFilterChart.listParam?.length === 0
      ) {
        const result = {};
        for (let i = 0; i < this.dataFilterChart.listEventGroup.length; i++) {
          const key = this.dataFilterChart.listEventGroup[i];
          if (this.objDataChart.hasOwnProperty(key)) {
            result[key] = this.objDataChart[key];
          }
        }
        this.listLegend = Object.keys(result);
        let itemData = [];
        for (var key in result) {
          var obj = {};
          obj[key] = result[key];
          itemData.push(obj);
        }

        this.setAllDataChart(itemData);
      } else {
        this.listLegend = Object.keys(this.objDataChart);
        let itemData = [];
        for (var key in this.objDataChart) {
          var obj = {};
          obj[key] = this.objDataChart[key];
          itemData.push(obj);
        }
        this.setAllDataChart(itemData);
      }
    });
  };

  @action runCustomAnalysisStore = (values) => {
    this.isRunAnalysis = true;
    this.objRunAnalysis = values;
    return new Promise((resolve) => {
      this.moduleService
        .runCustomAnalysis(values)
        .then((response) => {
          runInAction(() => {
            this.chartInfor = {
              name: this.selectedAnalysis?.name,
              fromTime: moment(this.objRunAnalysis?.from_time).format(
                "dddd, MMMM D, YYYY / h:mm A"
              ),
              toTime: moment(this.objRunAnalysis?.to_time).format(
                "dddd, MMMM D, YYYY / h:mm A"
              ),
              nameIntersection: this.nameIntersection,
            };
            this.listGroupEventFilter = Object.keys(response);
            this.listLegend = Object.keys(response);
            let itemData = [];
            for (var key in response) {
              var obj = {};
              obj[key] = response[key];
              itemData.push(obj);
            }
            this.objDataChart = response;
            this.dataAnalysisChart = {
              ...this.dataAnalysisChart,
              data: {
                allDataChart: itemData,
                dataChartArr: Object.values(response).flat(),
              },
              allDataAnalysis: response,
              from_time: values?.from_time,
              to_time: values?.to_time,
            };
            this.isRunAnalysis = false;
          });
          resolve(response);
        })
        .catch((error) => {
          runInAction(() => {
            this.objDataChart = null;
          });
          if (error && Array.isArray(error.errors)) {
            let message = "";
            error.errors.map((item) => {
              message += item.message + "\n";
            });
            if (message != "") {
              helper.showNotification("error", error.key, message);
            }
          }
        })
        .finally(() => {
          runInAction(() => {
            this.isRunAnalysis = false;
          });
        });
    });
  };
  @action getAllFilterData = async () => {
    this.loading = true;
    // if not ? => taticmode
    if (this.parentStore?.myStore?.currentAgency?.agency_id)
      this.filterData.agency_id =
        this.parentStore?.myStore?.currentAgency?.agency_id;
    if (this.parentStore.commonStore.isSuperAdmin()) {
      await this.getAllAgencies();
      this.getCustomAnalysisById(this.listCustomAnalysis[0]?.id);
    } else {
      //get agency setting prepare for agency color
      this.getAgencySettingByAgencyId();
    }
    this.getAllPerlogEvent();
    this.getAllIntersectionsByAgencyStore(this.filterData.agency_id);
    await this.getListAllCustomAnalysisStore(this.filterData.agency_id);
    this.getCustomAnalysisById(this.listCustomAnalysis[0]?.id);
  };

  @action updateFilterData = (target, value, isUpdateData = true) => {
    //update filter data
    if (this.dataAnalysisChart && target) {
      this.dataAnalysisChart[target] = value;
    }
    if (isUpdateData) {
      this.dataAnalysisChart.data.dataChartArr =
        this.filterDataWithNewTimeRange(
          this.dataAnalysisChart.data.allDataChart
        );
    }
  };

  filterDataWithNewTimeRange = (allChartData) => {
    //prepare data for filter time
    let from_time = new Date(this.dataAnalysisChart.from_time);
    let to_time = new Date(this.dataAnalysisChart.to_time);
    //filter data by time
    let chartData = allChartData.map((item) => {
      let result = {
        ...item,
      }; //Note:clone object to avoid override the master data
      let newPlanStatistics = [];
      const filterFromTime = from_time.getTime();
      const filterToTime = to_time.getTime();
      //find the plan in time range first
      newPlanStatistics = Object.values(result)[0].filter((plan) => {
        const planDateTime = new Date(plan.date_time).getTime();
        return planDateTime >= filterFromTime && planDateTime <= filterToTime;
      });

      //overide the start / end time exactly same with filter time
      newPlanStatistics = newPlanStatistics.map((e) => {
        let plan = { ...e }; //Note:clone object to avoid override the master data
        const planFromTime = new Date(plan.from_time).getTime();
        const planToTime = new Date(plan.to_time).getTime();
        if (planFromTime < filterFromTime) {
          plan.from_time = moment(from_time).format(DATE_FORMAT.backend);
        }
        if (planToTime > filterToTime) {
          plan.to_time = moment(to_time).format(DATE_FORMAT.backend);
        }
        return plan;
      });
      //update the plan_statistics
      result.plan_statistics = newPlanStatistics;
      //NOTE: we just have update the plan statics here
      //the data of the chart will filter by getDataObject function on chart detail
      //cuz data format will be diff between chart type. so we do it at view logic
      return result;
    });
    return chartData;
  };

  //End custom analysis
}

export default CustomAnalysisStore;
