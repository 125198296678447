import React from 'react';
import { Col, Input, Tooltip } from 'antd';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import BaseManagementComponents from '../../../../../../components/BaseManagementComponents';
import "./style.scss";

/**
 * Component for showing details.
 * 
 * @component
 */
@inject('rootStore')
@observer
class AddMoreSub extends BaseManagementComponents {

  constructor(props) {
    super(props.addMoreSubStore, props);
    this.rowKey = "id";
    this.moduleName = "user";
    this.forceEnableRowSelection = true;
    this.enableCreateButton = false;
    this.pageCustomClass = 'wrap-alarm-add-more-sub';
    this.columns = this.getTableColumns();
  }

  /**
     * return column set of table
     */
  getTableColumns = () => {
    const { t } = this.props;
    return [
      {
        title: t('user.email'),
        dataIndex: 'email',
        key: 'email',
        sorter: true,
        sortDirections: ['descend', 'ascend'],
        ellipsis: true,
      },
      {
        title: t('user.name'),
        dataIndex: 'name',
        key: 'name',
        sorter: true,
        sortDirections: ['descend', 'ascend'],
        ellipsis: true,
      },
    ];
  }

  checkFormDirty = () => {
    const { addMoreSubStore } = this.props;
    return addMoreSubStore.selectedItems.length > 0;
  }

  //-------- render common component ----------
  /**
   * renderFilterComponent
   * this function return the filter box
   * @return  {Component} 
   */
  renderFilterComponent = () => {
    const { t } = this.props;
    return (
      <div>
        <Col span={16} >
          <Tooltip placement="top" title={t('alarm_rules.search_tooltip')}>
            <Input defaultValue={this.currentStore?.filterData?.text} onChange={(e) => this.currentStore?.handleFilterDataChange("text", e.target.value)} placeholder={t('alarm_rules.search_placholer')} maxLength={128} />
          </Tooltip>
        </Col>
      </div>
    );
  }

  componentDidMount() {
    super.componentDidMount();
    const { childRef, addMoreSubStore, selectedItems } = this.props;
    addMoreSubStore.setSelectedItem(selectedItems);
    childRef(this);
  }

  render() {
    return super.render();
  }
}

export default withTranslation()(AddMoreSub);