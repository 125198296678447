import Api from "./Api";
import BaseApi from "./BaseApi";

class NotificationService extends BaseApi {
  constructor(parentStore) {
    super("notifications", parentStore);
  }

  /**
   * mapping current user with connection id
   * @param   {number} connection_id  connection id
   */
  mapping = (connection_id) => {
    return new Promise((resolve, reject) => {
      const api = new Api("web-socket-connections", this.parentStore);
      api.postRequest(
        {
          connection_id: connection_id,
        },
        (response) => {
          resolve(response?.data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  };

  /**
   * update read status of a notification
   * @param   {Array<number>} ids notification id list
   * @param   {String} readStatus read status
   */
  updateReadStatus = (ids, readStatus) => {
    return new Promise((resolve, reject) => {
      const api = new Api("notifications", this.parentStore);
      api.patchRequest(
        { noti_ids: ids, read_status: readStatus },
        () => {
          resolve();
        },
        (err) => {
          reject(err);
        }
      );
    });
  };

  /**
   * update flag status of a notification
   * @param   {Array<number>} ids notification id list
   * @param   {String} flagStatus flag status
   */
  updateFlagStatus = (ids, flagStatus) => {
    return new Promise((resolve, reject) => {
      const api = new Api("notifications", this.parentStore);
      api.patchRequest(
        { noti_ids: ids, flag_status: flagStatus },
        () => {
          resolve();
        },
        (err) => {
          reject(err);
        }
      );
    });
  };

  /**
   * delete a notification
   * @param   {Array<number>} ids notification id list
   */
  deleteNotif = (ids, cb, fb) => {
    const api = new Api(
      this.moduleName + "?noti_ids=" + ids.join(","),
      this.parentStore
    );
    api.deleteRequest(
      () => {
        cb && cb();
      },
      (erros) => {
        fb && fb(erros);
      }
    );
  };

  /**
   * getAllTypes
   * get all types of notification
   * @return   {Promise}
   */
  getAllTypes = () => {
    return new Promise((resolve, reject) => {
      const api = new Api(this.moduleName + "/types", this.parentStore);
      api.getRequest(
        {},
        (response) => {
          resolve(response?.data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  };
}

export default NotificationService;
