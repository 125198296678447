import BaseApi from "./BaseApi";
import Api from "./Api";
/**
 * Service for user management.
 *
 * @class
 */
class TSPDashboardService extends BaseApi {
    constructor(parentStore) {
        super("tsp-dashboard", parentStore);
        this.keyField = "user_id";
    }
    getAllEventTypes = () => {
        return new Promise((resolve, reject) => {
            const api = new Api(
                "tsp/event-types",
                this.parentStore
            );
            api.getRequest(
                null,
                (response) => {
                    resolve(response?.data);
                },
                (response) => {
                    reject(response);
                }
            );
        });
    }
    /**
     * getAllIntersectionsWithBounds
     * this function get intersection by area ( topleft - bottomright)
     * @param   {String} searchParams  curent search params
     * @return  {null} -  call the callback function
     */
    getAllIntersectionsWithBounds = (searchParams) => {
        Object.keys(searchParams).forEach(
            (key) =>
                (searchParams[key] == "" || searchParams[key] == "null") &&
                delete searchParams[key]
        );
        return new Promise((resolve, reject) => {
            const api = new Api(
                this.moduleName + "/map/intersections",
                this.parentStore
            );
            api.getRequest(
                searchParams,
                (response) => {
                    resolve(response?.data);
                },
                (response) => {
                    reject(response);
                }
            );
        });
    };

    /**
     * fetchAgencySettingForMap
     * this function get agency setting info for dashboad only
     * @param   {String} agency_id  curent agency id
     * @param   {Function} cb  callback function after success
     * @param   {Function} fb  failback function after fail
     * @return  {null} -  call the callback function
     */
    fetchAgencySettingForDashboardMap = (agency_id, cb, fb) => {
        const api = new Api(
            "dashboard/map/" + agency_id + "/settings",
            this.parentStore
        );
        api.getRequest(
            {},
            (response) => {
                cb && cb(response?.data);
            },
            (errors) => {
                fb && fb(errors);
            }
        );
    };
}

export default TSPDashboardService;
