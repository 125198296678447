import { Select } from "antd";
import React, { Component, createRef } from "react";
import Api from "services/Api";
import { TIMEOUT_LOAD_MORE } from "utils/constants";
import LoadingIconSvg from "../loading";
import isEqual from "lodash/isEqual";
class SiwaveLazySelectBox extends Component {
  state = {
    typing: false,
    searchText: "",
    searching: false,
    response: {
      current: 0,
      pageSize: parseInt(process.env.REACT_APP_PAGE_SIZE) || null,
      total: 0,
      data: [],
    },
  };

  timeOutSearch = null;
  constructor(props) {
    super(props);
    this.selectRef = createRef();
    const apiPort = process.env.REACT_APP_API_PORT
      ? ":" + process.env.REACT_APP_API_PORT
      : "";
    this.apiUrl =
      process.env.REACT_APP_API_HOST +
      apiPort +
      process.env.REACT_APP_API_PREFIX;
  }
  onSearch = () => {
    const { path, moduleName, rootStore, firstParams } = this.props;

    const api = new Api(path, rootStore);
    this.setState({ searching: true });
    api.getRequest(
      {
        ...firstParams,
        text: this.state.searchText,
      },
      (res) => {
        this.setState({
          response: {
            ...this.state.response,
            total: res.data.total_count,
            data: res.data?.[moduleName],
            current: 0,
          },
          searching: false,
        });
      },
      () => {
        this.setState({ searching: false });
      }
    );
  };
  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.firstParams, this.props.firstParams))
      this.fetchFirstData();
  }
  componentDidMount() {
    this.fetchFirstData();
  }
  onLoadMore = () => {
    const { path, moduleName, rootStore, firstParams } = this.props;
    if (
      this.state.response.data.length < this.state.response.total &&
      !this.state.searching
    ) {
      const api = new Api(path, rootStore);
      this.setState({ searching: true });
      api.getRequest(
        {
          ...firstParams,
          text: this.state.searchText,
          page: this.state.response.current + 1,
        },
        (res) => {
          this.setState({
            response: {
              ...this.state.response,
              total: res.data.total_count,
              data: [...this.state.response.data, ...res.data?.[moduleName]],
              current: this.state.response.current + 1,
            },
            searching: false,
          });
        },
        () => {
          this.setState({ searching: false });
        }
      );
    }
  };
  fetchFirstData = () => {
    const { path, moduleName, rootStore, firstParams } = this.props;
    const api = new Api(path, rootStore);
    this.setState({ searching: true });

    api.getRequest(
      {
        ...firstParams,
      },
      (res) => {
        this.setState({
          response: {
            ...this.state.response,
            total: res.data.total_count,
            data: res.data?.[moduleName],
            current: 0,
          },
          searching: false,
        });
        // onChange &&
        //   onChange(
        //     res.data?.[moduleName]?.length ? res.data[moduleName][0].id : null
        //   );
      },
      () => {
        this.setState({ searching: false });
      }
    );
  };

  /**
   * forcus on target marker
   * @return  {Component}
   */
  render() {
    const { customRender, ...otherProps } = this.props;
    return (
      <>
        <Select
          ref={this.selectRef}
          onPopupScroll={(event) => {
            const target = event.target;
            if (
              target.scrollTop + target.offsetHeight + 24 >=
              target.scrollHeight
            ) {
              if (
                this.state.response.data.length < this.state.response.total &&
                !this.state.searching
              )
                this.onLoadMore();
            }
          }}
          autoClearSearchValue={false}
          defaultActiveFirstOption={false}
          filterOption={false}
          onSearch={(params) => {
            this.setState({
              typing: true,
            });

            this.setState({
              typing: true,
              searchText: params,
            });
            if (this.timeOutSearch) clearTimeout(this.timeOutSearch);
            this.timeOutSearch = setTimeout(() => {
              this.setState({
                typing: false,
              });
              this.onSearch(params);
            }, TIMEOUT_LOAD_MORE);
          }}
          showSearch
          dropdownRender={(menu) => {
            return (
              <div className="">
                {menu}

                {this.state.searching || this.state.typing ? (
                  <div
                    style={{ height: 24 }}
                    className="flex justify-center w-full bg-background ">
                    <LoadingIconSvg />
                  </div>
                ) : (
                  <></>
                )}
              </div>
            );
          }}
          {...otherProps}>
          {this.state.response.data?.map((item) =>
            customRender ? (
              customRender(item)
            ) : (
              <Select.Option key={item.id} value={item.id}>
                {item.name}
              </Select.Option>
            )
          )}
        </Select>
      </>
    );
  }
}

export default SiwaveLazySelectBox;
