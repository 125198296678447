import { Form, Input, Select } from "antd";
import { inject, observer } from "mobx-react";
import React from "react";
import { withTranslation } from "react-i18next";
import { FORM_ITEM_LAYOUT, USER_STATUS_LIST } from "utils/constants";
import BaseDetailPopupComponent from "../../../components/BaseDetailPopupComponent";
import RoleCompoenent from "./RoleComponent";
import "./style.scss";

const { Option } = Select;

/**
 * Component for showing details of the user.
 *
 * @component
 */
@inject("rootStore")
@observer
class UserDetail extends BaseDetailPopupComponent {
  /**
   * contructor of component
   * @param   {object} props  props of component
   */
  constructor(props) {
    super(props.rootStore?.userStore, props);
    this.formFields = ["email", "name", "phone", "status", "roles"];
  }

  /**
   * componentDidMount
   *
   */
  componentDidMount = () => {
    const { childRef } = this.props;
    childRef && childRef(this);
    const {
      rootStore: { userStore },
    } = this.props;
    userStore.getAllRoles();
    userStore.getAllAgencies();
  };

  /**
   * renderReadOnlyForm
   * For view detail page, just label only
   *
   */
  renderReadOnlyForm(formData) {
    const {
      editable,
      t,
      rootStore: { commonStore },
    } = this.props;
    return (
      <div>
        <Form.Item
          className="user-key-label"
          label={t("user.email")}
          {...FORM_ITEM_LAYOUT}
        >
          {formData.email}
        </Form.Item>
        <Form.Item label={t("user.name")} {...FORM_ITEM_LAYOUT}>
          {formData.name}
        </Form.Item>
        <Form.Item label={t("user.phone")} {...FORM_ITEM_LAYOUT}>
          {formData.phone}
        </Form.Item>
        <Form.Item label={t("user.status")} {...FORM_ITEM_LAYOUT}>
          {formData.status}
        </Form.Item>
        <Form.Item>
          <hr className="user-seprate-line"/>
        </Form.Item>
        <Form.Item label={t("user.roles")} {...FORM_ITEM_LAYOUT}>
          <RoleCompoenent
            formData={formData}
            editable={editable}
            commonStore={commonStore}
            t={t}
          />
        </Form.Item>
      </div>
    );
  }

  /**
   * renderCreateOrEditForm
   * For view edit page with validation and submit form
   *
   */
  renderCreateOrEditForm(formData, getFieldDecorator) {
    const {
      editable,
      t,
      rootStore: { commonStore, userStore, myStore },
    } = this.props;
    let isCreateForm = formData.user_id == 0;
    return (
      <div>
        <Form.Item
          className="user-key-bold-label"
          label={t("user.email")}
          {...FORM_ITEM_LAYOUT}
        >
          {getFieldDecorator("email", {
            rules: [
              { required: true, message: t("user.email_required") },
              {
                type: "email",
                message: t("user.email_format"),
              },
            ],
            initialValue: formData.email,
          })(
            <Input
              maxLength={255}
              placeholder={t("user.email_placeholder")}
            />
          )
          }
        </Form.Item>
        <Form.Item
          className="user-key-bold-label"
          label={t("user.name")}
          {...FORM_ITEM_LAYOUT}
        >
          {getFieldDecorator("name", {
            rules: [{ required: true, message: t("user.name_required") }],
            initialValue: formData.name,
          })(
            <Input
              maxLength={255}
              placeholder={t("user.name_placeholder")}
            />
          )
          }
        </Form.Item>
        <Form.Item
          className="user-key-bold-label"
          label={t("user.phone")}
          {...FORM_ITEM_LAYOUT}
        >
          {isCreateForm
            ? getFieldDecorator("phone", {
              rules: [{ required: true, message: t("user.phone_required") }],
              initialValue: formData.phone,
            })(
              <Input
                maxLength={255}
                placeholder={t("user.phone_placeholder")}
              />
            )
            : formData.phone}
        </Form.Item>
        {!isCreateForm && (
          <Form.Item label={t("user.status")} {...FORM_ITEM_LAYOUT}>
            {commonStore.isSuperAdmin() &&
            formData.user_id !== myStore?.currentProfile?.id
              ? getFieldDecorator("status", {
                rules: [
                  { required: true, message: t("user.status_required") },
                ],
                initialValue: formData.status,
              })(
                <Select>
                  {USER_STATUS_LIST?.map((e) => (
                    <Option key={e.key} value={e.key}>
                      {t(e.value)}
                    </Option>
                  ))}
                </Select>
              )
              : formData.status}
          </Form.Item>
        )}
        <Form.Item>
          <hr className="user-seprate-line"/>
        </Form.Item>
        <Form.Item label={t("user.roles")} {...FORM_ITEM_LAYOUT}>
          {getFieldDecorator("roles", {
            rules: [
              { validator: this.currentStore?.checkRoles, type: "array" },
            ],
            initialValue: formData.roles,
          })(
            <RoleCompoenent
              formData={formData}
              editable={editable}
              commonStore={commonStore}
              listNormalRoles={this.currentStore?.listNormalRoles}
              listAllRoles={this.currentStore?.listAllRoles}
              listAllUserAgency={this.currentStore?.listAllUserAgency}
              t={t}
              userStore={userStore}
              handleRoleChange={this.currentStore?.handleRoleChange}
              handleAddNewRole={this.currentStore?.handleAddNewRole}
              handleAgencyChange={this.currentStore?.handleAgencyChange}
              handleAgencyRoleChange={this.currentStore?.handleAgencyRoleChange}
              handleDeleteAgencyRole={this.currentStore?.handleDeleteAgencyRole}
            />
          )}
        </Form.Item>
      </div>
    );
  }

  /**
   * render.
   *
   */
  renderDetailData(formData, getFieldDecorator) {
    const { editable } = this.props;
    if (editable) {
      return this.renderCreateOrEditForm(formData, getFieldDecorator);
    } else {
      return this.renderReadOnlyForm(formData);
    }
  }
}

const WrapForm = Form.create({ name: "user_detail" })(UserDetail);

export default withTranslation()(WrapForm);
