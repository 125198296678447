import React, { Component } from "react";
import { Timeline, Button, Empty, Spin, Modal, Tooltip } from "antd";
import { withTranslation } from "react-i18next";
import { observer, inject } from "mobx-react";
import MiniMap from "components/Charts/MiniMap";
import AnalysisLegend from "containers/Analysis/AnalysisLegend";
import ChartPhase from "components/Charts/ChartPhase";
import AutoSizer from "react-virtualized-auto-sizer";
import PrintPreview from ".././PrintPreview";
import { ANALYSIS_LEGEND } from "utils/constants";

@inject("rootStore")
@observer
class AnalysisDefault extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      t,
      rootStore: {
        myStore: { currentProfile },
        chartStore: {
          chartInfo,
          allChartData,
          filterData,
          updateFilterData,
          getChartByIndex,
          currentChartTypeFrontEnd,
        },
        analysisStore: {
          gettingChart,
          printPReview,
          togglePrintPReviewVisibility,
        },
      },
      loading,
      resizing,
      convertTimeFormat,
      print,
      renderExtraOptions,
      renderListItem,
      goToScheduleLink,
    } = this.props;
    const totalPhase = allChartData?.map((e) => e.phase);

    return gettingChart ? (
      <Spin size="large" className={"centralize"} />
    ) : chartInfo && !resizing ? (
      <div className="wrap-analysis-result">
        <div className="wrap-analysis-header">
          <div className="analysis-header">
            <div className="left-content">
              <div className="analysis-title">
                <h1>
                  {t("analysis.perfomance-metric")}
                  {": "}
                  {t("analysis." + chartInfo?.analysis_type)}
                </h1>
                <div className="analysis-sub-title">
                  <span>{chartInfo?.title}</span>
                </div>
              </div>
              <div className="time-line">
                <div className="time-range">
                  <Timeline>
                    <Timeline.Item label={t("analysis.from_time")}>
                      {convertTimeFormat(chartInfo?.from_time)}
                    </Timeline.Item>
                    <Timeline.Item label={t("analysis.to_time")}>
                      {convertTimeFormat(chartInfo?.to_time)}
                    </Timeline.Item>
                  </Timeline>
                  {chartInfo?.isCompareMode && (
                    <Timeline>
                      <Timeline.Item label={t("analysis.from_time")}>
                        {convertTimeFormat(chartInfo?.comapre_from_time)}
                      </Timeline.Item>
                      <Timeline.Item label={t("analysis.to_time")}>
                        {convertTimeFormat(chartInfo?.comapre_to_time)}
                      </Timeline.Item>
                    </Timeline>
                  )}
                </div>
              </div>
            </div>
            <div className="right-content">
              <div className="print-function">
                <div className="flex">
                  <div className="mr-8">{renderExtraOptions()}</div>
                  <Button
                    disabled={!allChartData.length}
                    size="large"
                    icon="printer"
                    onClick={print}>
                    {t("analysis.print-title")}
                  </Button>
                  <Tooltip title={t("analysis.create_schedule_tooltip")}>
                    <Button
                      size="large"
                      icon="snippets"
                      className="mr-8 "
                      onClick={goToScheduleLink}></Button>
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
          <div className="flex w-full warning-mess"></div>
          {chartInfo.isGapData && (
            <div className="">{t("analysis.gap_warning")} &nbsp; </div>
          )}

          {!chartInfo.isFullyAppoarch && (
            <div>{t("analysis.appoarch_warning")}</div>
          )}
          <MiniMap
            dataState={[filterData.from_time, filterData.to_time]}
            updateMapState={(fromTime, toTime) => {
              updateFilterData("from_time", fromTime, false);
              updateFilterData("to_time", toTime);
            }}
            fullState={[chartInfo?.from_time, chartInfo?.to_time]}
          />
          <div></div>
          <div className="filter-function">
            <ChartPhase
              totalPhase={totalPhase}
              hiddenPhase={filterData?.hiddenPhase}
              updateHiddenPhase={(hiddenPhase) => {
                localStorage.setItem(
                  "siwave_hidden_phase",
                  JSON.stringify(hiddenPhase)
                );
                updateFilterData("hiddenPhase", hiddenPhase);
              }}
            />
            <AnalysisLegend
              totalLegend={ANALYSIS_LEGEND[currentChartTypeFrontEnd]}
              hiddenLegend={filterData?.hiddenLegend}
              updateHiddenLegend={(hiddenLegend) => {
                updateFilterData("hiddenLegend", hiddenLegend);
              }}
            />
          </div>
        </div>
        <div className="analysis-body body-normal styled-scroll">
          {loading ? (
            <Spin size="large" className={"centralize"} />
          ) : allChartData.length ? (
            <AutoSizer>{renderListItem.bind(this)}</AutoSizer>
          ) : (
            <div className="w-full h-full flex items-center justify-center">
              <Empty></Empty>
            </div>
          )}
        </div>
        <Modal
          className="alarm-records-summary-modal"
          centered
          width={"80%"}
          title={t("print.modal-title")}
          visible={printPReview}
          onCancel={togglePrintPReviewVisibility}
          footer={null}
          destroyOnClose={true}>
          <PrintPreview
            totalPage={totalPhase}
            getChartByIndex={getChartByIndex}
            printInfo={{
              ...chartInfo,
              chartType: currentChartTypeFrontEnd,
              totalLegend: ANALYSIS_LEGEND[currentChartTypeFrontEnd],
            }}
            printFilter={filterData}
            reporter={currentProfile?.name}
          />
        </Modal>
      </div>
    ) : (
      <div className="wrap-analysis-result-empty">
        <Empty></Empty>
      </div>
    );
  }
}

export default withTranslation()(AnalysisDefault);
