import React, { Component } from "react";
import { Drawer } from "antd";

class RightSideBar extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { visible, title, children, width, onClose } = this.props;
    return (
      <Drawer
        title={title}
        width={width || "25%"}
        className="spm-right-site-bar"
        placement="right"
        destroyOnClose
        onClose={onClose}
        visible={visible}
      >
        {children}
      </Drawer>
    );
  }
}

export default RightSideBar;
