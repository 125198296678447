import { action, computed, observable } from "mobx";
import OptimizeService from "services/OptimizeService";

// Store for analysis
class CorridorOffsetOptimizerStore {
  constructor(parentStore) {
    this.parentStore = parentStore;
    this.moduleService = new OptimizeService(parentStore);
  }

  @observable selectedItem = null;
  @observable loading = false;
  @observable loadingActual = false;

  @action setSelectedItem = (value) => {
    // this.selectedItem = value;
    this.selectedItem = value;
  };

  @computed get tranformCorridorOffsetData() {
    return {
      ...this.selectedItem,
      general_data: {
        ...this.selectedItem.general_data,
        bias: this.selectedItem.result.bias,
        bias_direction: this.selectedItem.result.bias_direction,
      },
      result: {
        ...this.selectedItem.result,
        intersections: this.selectedItem.result.intersections?.map((int) => {
          const up_stream_object = int.best_results.find(
            (phase) => phase.phases === int.upstream_phase
          );
          const down_stream_object = int.best_results.find(
            (phase) => phase.phases === int.downstream_phase
          );
          const total_object = int.best_results.find(
            (phase) => phase.phases === 0
          );
          return {
            ...int,
            up_stream_object: up_stream_object,
            down_stream_object: down_stream_object,
            total_object: total_object,
          };
        }),
      },
    };
  }
}

export default CorridorOffsetOptimizerStore;
