import React, { createRef } from "react";
import { observer, inject } from "mobx-react";
import BaseManagementComponents from "../../components/BaseManagementComponents";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import queryString from "query-string";
import {
  Card,
  Tooltip,
  Input,
  Col,
  Select,
  Icon,
  Radio,
  Modal,
  Button,
} from "antd";
import {
  RESOURCE_LIST,
  AVAILABLE_STATUS_LIST,
  INTERSECTION_TAB_INDEX,
  MAP_DELAY_LOAD_DATA,
  DATE_FORMAT,
  SELECTED_PIN,
  DEFAULT_PIN,
  ACTIONS_LIST,
  EXPORT_FORMAT,
} from "utils/constants";
import IntersectionDetialForm from "./IntersectionDetail";
import Helper from "../../utils/helper";
import { Map, Marker, Popup, TileLayer } from "react-leaflet";
import moment from "moment";
import IntersectionDownloadModal from "./IntersectionDownloadModal";
import "./style.scss";
import StatusTag from "components/StatusTag";

const { Option } = Select;
const AUTO_CLOSE_DOWNLOAD_MODAL_DELAY = 3000;
/**
 * Component for showing intersection in list or map.
 *
 * @component
 */
@inject("rootStore")
@observer
class Intersection extends BaseManagementComponents {
  constructor(props) {
    super(props.rootStore?.intersectionStore, props);
    this.columns = this.getTableColumns();
    this.enableActionBox = false;
    this.enableCreateButton = false;
    this.moduleName = "intersection";
    this.pageTitle = props.t("intersection_management");
    this.resourceName = RESOURCE_LIST.INTERSECTION;
    this.enableRowSelection = false;
    this.map = createRef();
    //map data info
    this.currentTopLeft = {}; //current topLeft position of map
    this.currentBottomRight = {}; //current bottomright position of map
    this.timeoutCheckInterval = null; //max time out to make a request get data on map
    const { t } = this.props;
    this.tableInlineActions = [
      {
        key: "download",
        tooltip: t("intersection.download_raw"),
        resource: RESOURCE_LIST.INTERSECTION,
        action: this.currentStore?.toggleDownloadModalVisibility,
        permission: ACTIONS_LIST.VIEW,
        icon: "download",
        passingData: (item) => {
          return [true, item?.id, item?.name];
        },
      },
      {
        key: "edit",
        tooltip: t("intersection.config_edit"),
        resource: RESOURCE_LIST.INTERSECTION,
        action: (item) => this.handleRedirectToConfigPage(item),
        permission: ACTIONS_LIST.VIEW,
        icon: "edit",
        passingData: (item) => {
          return [item];
        },
      },
    ];
    this.state = {
      disableExport: false,
      export_type: null,
    };
  }

  /**
   * disableExportBtn
   *
   */
  disableExportBtn = (disable) => {
    this.setState({ disableExport: disable });
  };
  handleRedirectToConfigPage = (item) => {
    this.props.history.push(
      "/intersection-config?" +
        queryString.stringify(
          {
            int_uuid: item?.id,
            agency_id:
              this.props.rootStore?.intersectionStore?.filterData?.agency_id,
          },
          { arrayFormat: "brackets" }
        )
    );
  };
  /**
   * handleDownloadFileDone
   *
   * This function handle when download is done
   * disable export button
   * show done message
   * after 3 seconds , auto close box and un-reset export button, set null messsage
   */
  handleDownloadFileDone = () => {
    const { t } = this.props;
    this.setState({
      disableExport: true,
      susscessMesage: t("intersection.download_done"),
    });
    setTimeout(() => {
      this.currentStore?.toggleDownloadModalVisibility(false);
      this.setState({
        disableExport: false,
        susscessMesage: null,
      });
    }, AUTO_CLOSE_DOWNLOAD_MODAL_DELAY);
  };
  /**
   * caculateMapHeight
   *
   * caculate the height for map layout
   */
  caculateMapHeight() {
    let screenHeight =
      document.getElementsByClassName("ant-layout-content")[0]?.offsetHeight;
    let headerActionHeight = document.getElementsByClassName(
      "ant-page-header-heading"
    )[0]?.offsetHeight;
    let filterHeight =
      document.getElementsByClassName("filter")[0]?.offsetHeight;
    return screenHeight - headerActionHeight - filterHeight - 108;
  }
  /**
   * return column set of table
   */
  getTableColumns = () => {
    const { t } = this.props;
    return [
      {
        title: t("intersection.name"),
        dataIndex: "name",
        key: "name",
        sorter: true,
        width: "30%",
        sortDirections: ["descend", "ascend"],
        render: (value, row) =>
          this.renderKeyColumn(value, row, RESOURCE_LIST.INTERSECTION_DETAIL),
      },
      {
        title: t("intersection.model"),
        dataIndex: "model",
        key: "model",
        sorter: true,
        sortDirections: ["descend", "ascend"],
        ellipsis: true,
      },
      {
        title: t("intersection.version"),
        dataIndex: "version",
        key: "version",
        sorter: true,
        sortDirections: ["descend", "ascend"],
        ellipsis: true,
      },
      {
        title: t("intersection.agencies"),
        dataIndex: "agencies",
        key: "agencies",
        render: (agencies) =>
          agencies
            ?.map(function (elem) {
              return elem.name;
            })
            .join(", "),
        width: "10%",
        ellipsis: true,
      },
      {
        title: t("intersection.status"),
        dataIndex: "status",
        key: "status",
        render: (status) => <StatusTag status={status} />,
        width: "10%",
        ellipsis: true,
      },
      {
        title: t("intersection.perflog_updated_time"),
        dataIndex: "perflog_recent_time",
        key: "perflog_recent_time",
        render: (perflog_recent_time) =>
          perflog_recent_time
            ? moment(perflog_recent_time).format(DATE_FORMAT.full)
            : "N/A",
        width: "10%",
        ellipsis: true,
      },
    ];
  };

  /**
   * componentDidMount
   * if current is super admin get list all agency for filter
   *
   */
  componentDidMount() {
    const { t } = this.props;
    document.title = t("intersection.page-title");
    this.currentStore.getAllAgencies();
    this.currentStore.getAllPerlogEvent();
  }

  /**
   * componentWillUnmount
   *
   * update default tabindex for next time comming
   *
   */
  componentWillUnmount() {
    this.renderCustomContent = null;
    this.currentStore?.updateCurrentIndexTab(
      INTERSECTION_TAB_INDEX.INTERSECTION_LIST_TAB
    );
  }

  /**
   * handleTabChange
   * this function to change showing between list and map
   * @return  {Component}
   */
  handleTabChange = (e) => {
    const activetab = e.target.value;
    this.currentStore?.updateCurrentIndexTab(activetab);
    if (activetab === INTERSECTION_TAB_INDEX.INTERSECTION_MAP_TAB) {
      //enable map component
      this.renderCustomContent = this.renderIntersectionsMap;
    } else {
      //disable the custom content
      this.currentStore?.handleRestFilterChange({
        top_left: null,
        bottom_right: null,
      });

      this.renderCustomContent = null;
    }
  };

  /**
   * handleMapReady
   * update the current topleft bottomright for the first time loading the map
   * @return  {null}
   */
  handleMapReady = () => {
    //set topleft button right position for the map
    let mapInst = this.map.current?.leafletElement;
    if (mapInst) {
      this.currentTopLeft = mapInst.getBounds()?.getNorthWest();
      this.currentBottomRight = mapInst.getBounds()?.getSouthEast();
      this.currentStore?.handleFilterDataChange(
        "top_left",
        this.currentTopLeft?.lat + "," + this.currentTopLeft?.lng,
        false
      );
      this.currentStore?.handleFilterDataChange(
        "bottom_right",
        this.currentBottomRight?.lat + "," + this.currentBottomRight?.lng
      );
    }
  };

  /**
   * handleMapChangeView
   * this function trigger when the map is change position or zoom level
   *
   * @return  {null}
   */
  handleMapChangeView = () => {
    let mapInst = this.map?.current.leafletElement;

    if (mapInst) {
      let currentTopLeft = mapInst.getBounds()?.getNorthWest();
      this.currentBottomRight = mapInst.getBounds()?.getSouthEast();
      this.currentTopLeft = currentTopLeft;
      let context = this;
      //clear the old timeout check
      if (this.timeoutCheckInterval) {
        clearTimeout(this.timeoutCheckInterval);
      }
      //delay 1.5 s to check location user wanna load? if true start loading data
      this.timeoutCheckInterval = setTimeout(function () {
        context?.loadIntersectionData(currentTopLeft);
      }, MAP_DELAY_LOAD_DATA);
    }
  };

  /**
   * loadIntersectionData
   * this function check logic
   * if current topleft is the same of lastest current top left => it's mean user chosen the target want to load
   * then start loading data
   * if not stop delay loa
   *
   * @return  {null}
   */
  loadIntersectionData = (currentTopLeft) => {
    if (
      currentTopLeft.lat === this.currentTopLeft.lat &&
      currentTopLeft.lng === this.currentTopLeft.lng
    ) {
      this.currentStore?.handleFilterDataChange(
        "top_left",
        this.currentTopLeft.lat + "," + this.currentTopLeft.lng,
        false
      );
      this.currentStore?.handleFilterDataChange(
        "bottom_right",
        this.currentBottomRight.lat + "," + this.currentBottomRight.lng
      );
    }
    this.timeoutCheckInterval = null;
  };
  //-------- render common component ----------
  /**
   * renderFilterComponent
   * this function return the filter box
   * @return  {Component}
   */
  renderFilterComponent = () => {
    const {
      t,
      rootStore: { commonStore },
    } = this.props;
    return (
      <div>
        <Col span={4}>
          <Radio.Group
            onChange={this.handleTabChange}
            value={this.currentStore?.currentTab}
            buttonStyle="solid">
            <Radio.Button value={INTERSECTION_TAB_INDEX.INTERSECTION_LIST_TAB}>
              <Icon type="unordered-list" /> {t("intersection.list")}
            </Radio.Button>
            <Radio.Button value={INTERSECTION_TAB_INDEX.INTERSECTION_MAP_TAB}>
              <Icon type="global" /> {t("intersection.map")}
            </Radio.Button>
          </Radio.Group>
        </Col>
        <Col span={4}>
          <Tooltip placement="top" title={t("intersection.search_tooltip")}>
            <Input
              maxLength={255}
              defaultValue={this.currentStore?.filterData?.text}
              onChange={(e) =>
                this.currentStore?.handleFilterDataChange(
                  "text",
                  e.target?.value
                )
              }
              placeholder={t("intersection.search_placholer")}
              onPressEnter={this.currentStore?.handleFilerAction}
            />
          </Tooltip>
        </Col>
        {commonStore.isSuperAdmin() &&
          this.currentStore?.listAllAgency?.length > 0 && (
            <Col span={4}>
              <Tooltip placement="top" title={t("intersection.agency_tooltip")}>
                <Select
                  labelInValue
                  onChange={(value) => {
                    this.currentStore?.handleFilterDataChange(
                      "agency_id",
                      value?.key
                    );
                    //we have to add this one
                    //cuz when change agency it will get back new location of agency
                    //it complecated to be waiting new location tobe set the call map change
                    //so simple solution is waiting alitter bit to check the map change
                    //this may not correct while request is longer than delay time.
                    //but this is a temper solution
                    if (
                      this.currentStore.currentTab ===
                      INTERSECTION_TAB_INDEX.INTERSECTION_MAP_TAB
                    ) {
                      //call map view change to get lastest position of map
                      setTimeout(this.handleMapChangeView, MAP_DELAY_LOAD_DATA);
                    }
                  }}
                  defaultValue={{
                    key: this.currentStore?.filterData.agency_id,
                  }}>
                  {this.currentStore?.listAllAgency?.map((e) => (
                    <Option key={e.id} value={e.id}>
                      {e.name}
                    </Option>
                  ))}
                </Select>
              </Tooltip>
            </Col>
          )}
        <Col span={4}>
          <Tooltip placement="top" title={t("intersection.status_tooltip")}>
            <Select
              onChange={(value) =>
                this.currentStore?.handleFilterDataChange("status", value)
              }
              defaultValue={this.currentStore?.filterData?.status}>
              <Option value="null">{t("intersection.all_status")}</Option>
              {AVAILABLE_STATUS_LIST?.map((e) => (
                <Option key={e.key} value={e.key}>
                  {t(e.value)}
                </Option>
              ))}
            </Select>
          </Tooltip>
        </Col>
      </div>
    );
  };

  /**
   * renderDetailData
   * this function return the intersection detail box
   * @return  {Component}
   */
  renderDetailData = () => {
    return (
      <IntersectionDetialForm
        editable={false}
        onClose={this.handleDrawerOnClose}
        handleRedirectToConfigPage={this.handleRedirectToConfigPage}
      />
    );
  };
  /**
   * handleOpenIntersectionDetail
   * forcus on target marker
   * @return  {Component}
   */
  handleOpenIntersectionDetail = (intersectionId) => {
    this.currentStore.setSelectedIntersection(intersectionId);
  };
  /**
   * handleOpenIntersectionDetail
   * out-forcus on target marker
   * @return  {Component}
   */
  handleCloseIntersectionDetail = () => {
    this.currentStore.setSelectedIntersection(null);
  };
  /**
   * renderIntersectionsMap
   * this function render intersection in map
   * @return  {Component}
   */
  renderIntersectionsMap() {
    const { t, history } = this.props;

    return (
      <div className="intersection-map">
        <Map
          ref={this.map}
          style={{ height: this.caculateMapHeight() }}
          zoom={this.currentStore?.initalAgencyLocation?.zoomLevel}
          center={this.currentStore?.initalAgencyLocation?.centerPoint}
          onViewportChanged={this.handleMapChangeView}
          whenReady={this.handleMapReady}>
          <TileLayer
            url={process.env.REACT_APP_OPEN_STREET_MAP}
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />
          {this.currentStore?.listData?.map(
            (intersection) =>
              intersection && (
                <Marker
                  icon={
                    intersection.id === this.currentStore.selectedIntersectionId
                      ? SELECTED_PIN
                      : DEFAULT_PIN
                  }
                  position={[intersection.latitude, intersection.longitude]}
                  key={intersection.id}
                  onClick={() =>
                    this.handleOpenIntersectionDetail(intersection.id)
                  }>
                  <Popup
                    className="intersect-detail-card"
                    onClose={this.handleCloseIntersectionDetail}>
                    <Card
                      title={
                        <div>
                          <div className="card-header">{intersection.name}</div>
                          <div>
                            {t("intersection.id")}:
                            <br />
                            {intersection?.id}
                          </div>
                        </div>
                      }
                      bordered={false}>
                      <div>{t("intersection.reports")}:</div>
                      {Array.isArray(Object.keys(intersection.action_map)) &&
                        Object.keys(intersection.action_map)?.map((e) => (
                          <a
                            key={e}
                            onClick={() =>
                              Helper.handleLinkAction(
                                intersection.action_map[e],
                                history
                              )
                            }>
                            {intersection.action_map[e]?.label}
                            <br />
                          </a>
                        ))}
                    </Card>
                  </Popup>
                </Marker>
              )
          )}
        </Map>
      </div>
    );
  }

  /**
   * render
   *
   * @return  {Component}
   */
  render() {
    return super.render();
  }
  /**
   * renderAdditionalInfo
   * this function return the popup for preview export data
   * @return  {Component}
   */
  renderAdditionalInfo = () => {
    const { t } = this.props;
    return (
      <Modal
        className="download-raw-data-modal"
        width={"750px"}
        destroyOnClose={true}
        title={t("intersection.download_raw_data_title")}
        visible={this.currentStore?.visibleDownloadModal}
        onCancel={() => this.currentStore?.toggleDownloadModalVisibility(false)}
        footer={[
          <Button
            key="back"
            onClick={() =>
              this.currentStore?.toggleDownloadModalVisibility(false)
            }>
            {t("intersection.cancel")}
          </Button>,
          <Button
            key="submit_csv"
            type="primary"
            disabled={this.state.disableExport}
            loading={
              this.currentStore?.loading &&
              this.state.export_type === EXPORT_FORMAT.CSV
            }
            onClick={() => {
              this.setState({
                export_type: EXPORT_FORMAT.CSV,
              });
              this.currentStore?.downloadRawData(
                this.handleDownloadFileDone,
                EXPORT_FORMAT.CSV
              );
            }}>
            {t("report_result.export_csv")}
          </Button>,
          <Button
            key="submit_pdf"
            type="primary"
            disabled={this.state.disableExport}
            loading={
              this.currentStore?.loading &&
              this.state.export_type === EXPORT_FORMAT.PDF
            }
            onClick={() => {
              this.setState({
                export_type: EXPORT_FORMAT.PDF,
              });
              this.currentStore?.downloadRawData(
                this.handleDownloadFileDone,
                EXPORT_FORMAT.PDF
              );
            }}>
            {t("report_result.print_table")}
          </Button>,
        ]}>
        <IntersectionDownloadModal
          susscessMesage={this.state.susscessMesage}
          disableExportBtn={this.disableExportBtn}
          dataStore={this.currentStore}
        />
      </Modal>
    );
  };
}

export default withRouter(withTranslation()(Intersection));
