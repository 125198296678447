export const TIMEZONES = [
  { value: "-12:00", label: "(GMT-12:00) International Date Line West"},
  { value: "-11:00", label: "(GMT-11:00) Midway Island, Samoa"},
  { value: "-10:00", label: "(GMT-10:00) Hawaii"},
  { value: "-09:00", label: "(GMT-09:00) Alaska"},
  { value: "-08:00", label: "(GMT-08:00) Pacific Time (US and Canada); Tijuana"},
  { value: "-07:00", label: "(GMT-07:00) Mountain Time (US and Canada), Chihuahua, La Paz, Mazatlan, Arizona"},
  { value: "-06:00", label: "(GMT-06:00) Central Time (US and Canada), Saskatchewan, Guadalajara, Mexico City, Monterrey, Central America"},
  { value: "-05:00", label: "(GMT-05:00) Eastern Time (US and Canada), Indiana (East), Bogota, Lima, Quito, Santiago"},
  { value: "-04:00", label: "(GMT-04:00) Atlantic Time (Canada), Caracas, La Paz"},
  { value: "-03:30", label: "(GMT-03:30) Newfoundland and Labrador"},
  { value: "-03:00", label: "(GMT-03:00) Brasilia, Buenos Aires, Georgetown, Greenland"},
  { value: "-02:00", label: "(GMT-02:00) Mid-Atlantic"},
  { value: "-01:00", label: "(GMT-01:00) Azores, Cape Verde Islands"},
  { value: "Z", label: "(GMT) Greenwich Mean Time: Dublin, Edinburgh, Lisbon, London, Casablanca, Monrovia"},
  { value: "+01:00", label: "(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague, Sarajevo, Skopje, Warsaw, Zagreb, Brussels, Copenhagen, Madrid, Paris, Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna, West Central Africa"},
  { value: "+02:00", label: "(GMT+02:00) Bucharest, Cairo, Helsinki, Kiev, Riga, Sofia, Tallinn, Vilnius, Athens, Istanbul, Minsk, Jerusalem, Harare, Pretoria"},
  { value: "+03:00", label: "(GMT+03:00) Moscow, St. Petersburg, Volgograd, Kuwait, Riyadh, Nairobi, Baghdad"},
  { value: "+03:30", label: "(GMT+03:30) Tehran"},
  { value: "+04:00", label: "(GMT+04:00) Abu Dhabi, Muscat, Baku, Tbilisi, Yerevan"},
  { value: "+04:30", label: "(GMT+04:30) Kabul"},
  { value: "+05:00", label: "(GMT+05:00) Ekaterinburg, Islamabad, Karachi, Tashkent"},
  { value: "+05:30", label: "(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi"},
  { value: "+05:45", label: "(GMT+05:45) Kathmandu"},
  { value: "+06:00", label: "(GMT+06:00) Astana, Dhaka, Sri Jayawardenepura, Almaty, Novosibirsk"},
  { value: "+06:30", label: "(GMT+06:30) Yangon Rangoon"},
  { value: "+07:00", label: "(GMT+07:00) Bangkok, Hanoi, Jakarta, Krasnoyarsk"},
  { value: "+08:00", label: "(GMT+08:00) Beijing, Chongqing, Hong Kong SAR, Urumqi, Kuala Lumpur, Singapore, Taipei, Perth, Irkutsk, Ulaanbaatar"},
  { value: "+09:00", label: "(GMT+09:00) Seoul, Osaka, Sapporo, Tokyo, Yakutsk"},
  { value: "+09:30", label: "(GMT+09:30) Darwin, Adelaide"},
  { value: "+10:00", label: "(GMT+10:00) Canberra, Melbourne, Sydney, Brisbane, Hobart, Vladivostok, Guam, Port Moresby"},
  { value: "+11:00", label: "(GMT+11:00) Magadan, Solomon Islands, New Caledonia"},
  { value: "+12:00", label: "(GMT+12:00) Fiji Islands, Kamchatka, Marshall Islands, Auckland, Wellington"},
  { value: "+13:00", label: "(GMT+13:00) Nuku'alofa"}
]