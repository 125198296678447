import * as d3 from "d3";
import ChartDetail from "components/ChartDetail";
const LABEL_TEXT = 21;
class AdjustmentResultChart extends ChartDetail {
  constructor(props) {
    super(props);
    this.value_field = "offset";
    this.data_field = "offset_results";
    this.chartLegend = props.chartLegend;
    this.xAxisLabel = null;
    this.CHART_AXIS_SETTING = [
      {
        position: "left",
        key: "total_aog",
        label: "AoG Volume (Unit: Raw Count)",
        data: this.props.data.offset_results,
        tick: true,
      },
    ];
  }
  rerenderAllChart() {
    this.chartRef.current.innerHTML = "";
    this.componentDidMount();
  }
  handleFilterChartData(hiddenLegend) {
    const { data } = this.props;
    const chartData = data[this.data_field];
    this.planData = data?.plan_statistics;
    this.chartObject.selectAll(".lines-chart-group").remove();

    this.availableLineData = [];
    if (Array.isArray(hiddenLegend) && Array.isArray(this.chartLegend)) {
      this.chartLegend.map((item) => {
        if (!hiddenLegend.includes(item.key)) {
          let dataObject = {
            data: this.getDataObject(chartData, item, this.planData),
            x: this.xScaleObject,
            y: this.yScaleObject[item.yAxis],
            key: item.key,
            color: item.color,
            unit: item.unit,
            type: item.type,
            label: item.label,
            yAxis: item.yAxis,
          };
          this.availableLineData.push(dataObject);
        }
      });
    }
    this.renderChartData();
  }
  renderHightLinePoint = (targetObject, x, y) => {
    const tootTipHeight =
      (targetObject.length + 2) * this.TooltipStyle.defaultChartHeight; //2 is space top/bottom
    let chartObject = d3.select(this.chartRef?.current);
    if (this.hightLineObject) {
      this.hightLineObject?.remove();
    }

    if (chartObject) {
      this.hightLineObject = chartObject
        .append("g")
        .attr("class", "hightline-group");
      const xTooltipPosition =
        x < this.fullWidth / 2
          ? this.ChartStyle.marginLeftRight + x + this.TooltipStyle.marginLeft
          : this.ChartStyle.marginLeftRight +
            x -
            this.TooltipStyle.marginLeft -
            this.TooltipStyle.width;
      const yTooltipPosition =
        y < this.fullHeight / 2
          ? y + tootTipHeight / 2 + this.TooltipStyle.marginTop
          : y - tootTipHeight + this.TooltipStyle.marginTop;
      this.hightLineObject
        .append("rect")
        .attr("width", this.TooltipStyle.width) //hard code the width of box
        .attr("height", tootTipHeight)
        .attr("class", "tooltip-box")
        .attr("stroke", "var(--text)")
        .attr("fill", "var(--background)")
        .attr(
          "transform",
          "translate(" + xTooltipPosition + "," + yTooltipPosition + ")"
        );
      //render hightline point

      // let labelHeight = yTooltipPosition;
      let labelIndex = 1.5;
      //add default text of time
      this.hightLineObject
        .append("text")
        .text("Offset: " + targetObject[0]?.data?.time) //@TODO:hard code here
        .attr("text-anchor", "middle")
        .attr("class", "tooltip-info-text")
        .attr(
          "transform",
          "translate(" +
            (xTooltipPosition + this.TooltipStyle.width / 2) +
            "," +
            (yTooltipPosition +
              this.TooltipStyle.defaultChartHeight * labelIndex) +
            ")"
        )
        .attr("fill", "var(--text)");
      labelIndex += 1;
      targetObject.forEach((item) => {
        //find the target point setting
        const setting = this.chartLegend.find((e) => e.key === item.key);
        //find y posistion of point
        //get point value
        const pointValue = item.data.value;
        //get point position
        const yPosistion = this.newYObject[setting.yAxis](pointValue);
        this.hightLineObject
          .append("circle")
          .attr("r", this.HIGHT_LINE_POINT)
          .attr("fill", setting.color)
          .attr("class", "hight-line-point")
          .attr(
            "transform",
            "translate(" +
              (this.ChartStyle.marginLeftRight + x) +
              "," +
              (yPosistion + this.ChartStyle.marginTopBottom) +
              ")"
          )
          .on("mouseout", () => {
            this.handleMouseOut();
          });

        this.hightLineObject
          .append("text")
          .text(
            this.renderTooltipText(
              setting.label,
              setting.unit,
              setting.toolTip(pointValue)
            )
          ) //the way we show tooltip defined on ANALYSIS_PLAN_INFO in constant file
          .attr("text-anchor", "middle")
          .attr("class", "tooltip-info-text")
          .attr(
            "transform",
            "translate(" +
              (xTooltipPosition + this.TooltipStyle.width / 2) +
              "," +
              (yTooltipPosition +
                this.TooltipStyle.defaultChartHeight * labelIndex) +
              ")"
          )
          .attr("fill", setting.color);
        labelIndex += 1;
      });

      //render hight-line infor box
    }
  };
  renderXaxisLineForChart = (from, to) => {
    //create the scaleObject - this one is important cuz we use this to calculate position base on time(draw plan info)
    this.xScaleObject = d3
      .scaleLinear()
      .domain([from, to])
      .range([0, this.fullWidth]);
    this.newX = this.xScaleObject;
    //create axisObject
    let axisObject = d3
      .axisBottom(this.xScaleObject)
      .tickSize(-this.fullHeight);

    //draw x axis line
    this.xAxis = this.chartObject
      .append("g")
      .attr("class", "x-axis-botttom")
      .attr(
        "transform",
        "translate(" +
          this.ChartStyle.marginLeftRight +
          "," +
          (this.fullHeight + this.ChartStyle.marginTopBottom) +
          ")"
      )
      .call(axisObject);

    this.chartObject
      .append("text")
      .attr("x", this.fullWidth / 2)
      .attr("y", this.fullHeight + this.ChartStyle.marginTopBottom + LABEL_TEXT)
      .attr("class", "y-axis-label")
      .style("fill", "var(--text)")
      .style("text-anchor", "middle")
      .style("font-size", "14px")
      .text("Offset By Second");
  };

  findMaxValueInObjectArray = (array, key, defaultValue = 0) => {
    if (Array.isArray(array)) {
      return Math.max.apply(
        Math,
        array.map(function (data) {
          return data[key];
        })
      );
    }
    return defaultValue;
  };
  getDataObject = (chartData, item) => {
    let result = [];
    chartData.forEach((e) => {
      result.push({
        time: e[this.value_field],
        value: e[item.key],
      });
    });
    return result;
  };
  componentDidMount() {
    const { data, isPrintMode, gapdata } = this.props;
    if (!this.data_field && !this.value_field) {
      return;
    }
    //general Info

    const chartData = data[this.data_field];
    this.planData = data?.plan_statistics;
    //Draw Chart Plan
    //draw xAxisObject
    //get plan object from dom
    this.chartObject = d3.select(this.chartRef?.current);
    if (
      Array.isArray(this.planData) &&
      Array.isArray(chartData) &&
      this.chartObject
    ) {
      //calculate the witdh of xAxist bar base on the scren size
      let parentWidth = parseFloat(
        window.getComputedStyle(this.chartObject.node())?.width
      );
      this.fullWidth = parentWidth * 0.9;
      this.ChartStyle.marginLeftRight = parentWidth * 0.05;
      //calculate the height of xAxist bar base on the scren size
      let parentHeight = parseFloat(
        window.getComputedStyle(this.chartObject.node())?.height
      );
      this.ChartStyle.marginTopBottom = parentHeight * 0.05;
      this.fullHeight =
        parentHeight * 0.86 -
        this.ChartStyle.extraFocusHeight -
        this.ChartStyle.marginTopBottom;

      this.ChartStyle.extraFocusPosition = this.fullHeight;
      /// create y scale Object
      //draw background color base on plan data
      this.renderXaxisLineForChart(
        0,
        this.findMaxValueInObjectArray(chartData, "offset"),
        this.planData
      );
      this.renderChartBackgroundColor(this.planData);
      /// create y scale object
      this.renderYaxisLineForChart();

      //generate chart data

      //convert chartData to front-end format
      //note have to run behin renderXaxisLineForChart for xScale Object
      this.tranformChartDataToFronEndDataFormat(chartData, this.planData);

      //note have to run this function first tranformChartDataToFronEndDataFormat
      // this.renderZoomHandle();
      //generate chart crosshair
      if (!isPrintMode) {
        this.renderChartCrossHair(chartData);
      }
      this.renderChartData();
      /// render gap data
      if (gapdata) {
        this.renderGapDataForChart(gapdata);
      } //render zoom handler
    }
  }

  render() {
    return super.render();
  }
}

export default AdjustmentResultChart;
