import AgencyService from "../services/AgencyService";
import UserService from "../services/UserService";
import BaseStore from "./BaseStore";
import { RESOURCE_LIST } from "utils/constants";
import { action, runInAction, observable } from "mobx";
import {showNotification} from "../utils/helper";
import i18n from "../i18n";

/**
 * Store for user management.
 *
 * @class
 */
class AgencyUserStore extends BaseStore {
  constructor(agencyId, parentStore) {
    super(new UserService(parentStore), parentStore);
    this.resourceName = RESOURCE_LIST.AGENCY_DETAIL;
    this.agencyId = agencyId;
    this.filterData = {
      ...this.filterData,
      ...{
        text: "",
        agency_id: agencyId,
        sort: "",
        status: "null",
      },
    };
    this.agencyService = new AgencyService(parentStore);
  }
  //binding the user on the popup add new user
  user = {
    email: "",
    role_name: "",
  };
  //list of roles for user
  @observable listRoles = [];

  /**
   * getAllRoles
   * get all roles of the application
   * we put roles into caching cuz it's not usually change
   *
   * @return  {null}
   */
  @action getAllRoles = () => {
    this.loading = true;
    this.parentStore?.userStore?.getAllRoles(() => {
      runInAction(() => {
        this.loading = false;
        this.listRoles = this.parentStore?.userStore?.listNormalRoles;
        if (this.listRoles.length > 0) {
          this.user.role_name = this.listRoles[0]?.name;
        }
      });
    });
  };

  /**
   * sendActivationEmail
   * this function sends an activation email to an unactivated user
   * @param   {Array} data  list id of users, default null
   * @return  {null} -
   */
  @action sendActivationEmail = (data = null) => {
    if (!data) {
      data = this.selectedItems;
    }
    this.loading = true;
    this.moduleService.sendActivationEmail(
      { ids: data },
      () => {
        this.getData();
      },
      () => {
        runInAction(() => {
          this.loading = false;
        });
      }
    );
  }

  /**
   * removeUser
   * remove user out of list subcribers
   * @param {object} data  list of id you want to delete
   * if you not passing any specific data we ussing the list data chosen from selected box in table
   * reload whole table after delete
   * @return  {null}
   */
  @action removeUser = (data = null) => {
    this.loading = true;
    if (!data) {
      data = this.selectedItems;
    }

    this.agencyService?.removeUserFromAgency(
      "/" + this.agencyId + "/remove-users",
      { ids: data },
      () => {
        this.getData();
      },
      () => {
        runInAction(() => {
          this.loading = false;
        });
      }
    );
  };

  /**
   * saveData
   * @param   {Object} data  item of data for save
   * @param   {Object} cb  callback after save data successfull
   * @param   {Object} fb  callback after fail
   * @return  {null}
   */
  @action saveData = (data, cb, fb) => {
    this.loading = true;
    this.agencyService?.addUserToAgency(
      "/" + this.agencyId + "/assign-user",
      data,
      () => {
        runInAction(() => {
          showNotification("success", i18n.t("success"), i18n.t("update_success"));
          this.loading = false;
        });
        cb && cb();
      },
      (error) => {
        if (error?.errors?.length > 0) {
          fb && fb(error.errors);
        }
        runInAction(() => {
          this.loading = false;
        });
      }
    );
  };
}

export default AgencyUserStore;
