import React from "react";
import { observer, inject } from "mobx-react";
import { withTranslation } from "react-i18next";
import BasePaternData from "../BasePaternData";
// import './style.scss'
const MAP_DETECTOR_MODE = [
  "0-VEH Detector",
  "1-PED Detector",
  "2-ONE Call Veh Det",
  "3-SBA Stop Bar A VDet",
  "4-SBB Stop Bar B VDet",
  "5-PPL Veh Detector",
  "6-PPT Veh Detector",
  "7-AND Veh Detector",
  "8-Red Protect",
  "9-Bike Mode",
];
@inject("rootStore")
@observer
class Detector extends BasePaternData {
  /**
   * contructor of component
   * @param   {object} props  props of component
   */
  constructor(props) {
    super(props);
    this.tooltipKeyDescription = "intersection_config.detectordescription";
    this.highlightRowKey = "detector";
  }
  getTableColumn = () => {
    return [
      {
        key: "detector",
        label: "Detector",
      },
      {
        key: "assigned_phase",
        label: "Assigned Phase",
      },
      {
        key: "detector_mode",
        label: "Detector Mode",
      },
    ];
  };
  transformTableData = () => {
    const {
      rootStore: {
        intersectionConfigStore: { currentConfig },
      },
    } = this.props;
    const detectorInfo = currentConfig?.DetectorInfo;
    return detectorInfo?.det_mode?.map((e, id) => {
      return {
        detector: id + 1,
        assigned_phase: detectorInfo?.det_call_phases[id],
        detector_mode: MAP_DETECTOR_MODE[e],
      };
    });
  };
  render() {
    return <div>{super.render()}</div>;
  }
}

export default withTranslation()(Detector);
