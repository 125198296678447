import ChartDetail from "components/ChartDetail";
const EVENT_DATA_MAPPER = {
  green: 1,
  yellow: 8,
  red: 10,
};
const VolumeKey = "volume"; //@TODO:hardcode here
const DetectorKey = "detector"; //@TODO:hardcode here
class CoordinationChart extends ChartDetail {
  constructor(props) {
    super(props);
    this.value_field = "datetime";
    this.data_field = "phase_changes";
    this.CHART_AXIS_SETTING = [
      {
        position: "left",
        key: "phase_time",
        label: "Phase Duration",
        data: [...this.props.data?.phase_changes, ...this.props.data?.det_acts],
      },
      {
        position: "right",
        key: "volume",
        label: "VPH",
        data: this.props.data.volume_bins,
        tick: true,
      },
    ];

    this.TooltipStyle.width = 240;
  }

  componentDidMount = () => {
    super.componentDidMount();
  };

  getDataObject = (chartData, item) => {
    const { from_time, to_time, data } = this.props;
    let allData = chartData;
    let dataField = "datetime";
    let valueField = "phase_time";
    let result = [];
    let checkEvent = true;
    if (item.key === "aog_volume") {
      allData = data.volume_bins;
      dataField = "to_time";
      valueField = "aog_volume";
      checkEvent = false;
    }
    if (item.key === VolumeKey) {
      allData = data.volume_bins;
      dataField = "to_time";
      valueField = "volume";
      checkEvent = false;
    }
    if (item.key === DetectorKey) {
      allData = data.det_acts;
      checkEvent = false;
    }
    let availableData = allData.filter((e) =>
      checkEvent
        ? EVENT_DATA_MAPPER[item.key] === e.event &&
        from_time.getTime() <= new Date(e[dataField]).getTime() &&
        new Date(e[dataField]).getTime() <= to_time.getTime()
        : from_time.getTime() <= new Date(e[dataField]).getTime() &&
        new Date(e[dataField]).getTime() <= to_time.getTime()
    );
    result = availableData.map((e) => {
      return {
        time: e[dataField],
        value: e[valueField],
      };
    });
    return result;
  };

  /**
   * render
   *
   * @return  {Component}
   */
  render() {
    return super.render();
  }
}

export default CoordinationChart;
