import { action, observable, runInAction } from "mobx";
import {
  AVAILABLE,
  DATE_FORMAT,
  DAY_OF_WEEK,
  DELAY_SEARCH_STRING,
  MAX_NUMBER_START_SEARCH,
  RESOURCE_LIST,
  SEARCH_TEXT_FIELD,
  SYSTEM_ROUTES_PATH,
} from "utils/constants";
import moment from "moment";
import BaseStore from "./BaseStore";
import AlarmRuleService from "../services/alarm/AlarmRuleService";
import AgencyService from "../services/AgencyService";
import UserService from "../services/UserService";
import IntersectionService from "../services/IntersectionService";

class AlarmRuleStore extends BaseStore {
  constructor(parentStore) {
    super(new AlarmRuleService(parentStore), parentStore);
    this.filterData = {
      ...this.filterData,
      ...{
        text: "",
        analysis_id: null,
        category_id: null,
        from_date: null,
        to_date: null,
        owner_id: null,
        status: null,
        sort: "",
      },
    };
    this.currentModuleRoute = SYSTEM_ROUTES_PATH.AlarmRule;
    this.resourceName = RESOURCE_LIST.ALARM_RULE;
  }

  @observable allRuleCategories = [];
  @observable allUsers = [];
  @observable allRuleAnalysis = [];
  @observable allIntersections = [];
  @observable allAgencies = [];
  @observable createSubscriberList = [];
  @observable metricList = [];
  @observable createIntersectionList = [];
  @observable createRuleDetail = {};
  @observable ruleDetail = {};
  @observable activeCreateTab = "1";
  @observable ruleCondition = undefined;
  @observable loading = false;
  @observable overNight = false;
  @observable triggerAlarmRulePopup = false;
  @observable manualTriggerAlarmRule = null;
  @observable context_id = null;

  isEditFormDirty = false;
  @action addSubscriber = (ids) => {
    this.createSubscriberList = ids;
  };

  @action setTemplateId = (id) => {
    this.context_id = id;
  };

  @action resetCreateRuleForm = () => {
    this.context_id = null;
    this.createRuleDetail = {
      condition: "",
      time_range: {
        week_days: DAY_OF_WEEK,
        start: null,
        end: null,
        timezone: "Z",
      },
      sampling_period: 1,
    };
  };

  /**
   * handleFilterDataChange
   * this function will trigger when user input filtering or searching data
   * if filter data is search text and search length >=3 or length == 0 automatic start search
   * @param   {String} target  Field name wanna filter
   * @param   {String} value   Filtering data
   * @param   {Boolean} forceLoad   need to refetch data
   * @return  {null} -  setState of current filter data
   */
  @action handleFilterDataChange = (target, value, forceLoad = true) => {
    if (this.filterData) {
      this.filterData[target] = value;
    }
    if (target === "agency_id") this.getAllRuleCategories(value);
    if (target === SEARCH_TEXT_FIELD) {
      if (value?.length !== 0 && value?.length <= MAX_NUMBER_START_SEARCH) {
        return;
      }
      if (this.timeoutSearchString) clearTimeout(this.timeoutSearchString);
      if (forceLoad)
        this.timeoutSearchString = setTimeout(() => {
          this.handleFilerAction();
        }, DELAY_SEARCH_STRING);
    } else if (forceLoad) {
      this.handleFilerAction();
    }
  };
  @action duplicate = (id, cb) => {
    this.moduleService?.getDetail(
      id,
      (response) => {
        runInAction(() => {
          const newRule = {
            name: response?.name,
            status: response?.status,
            analysis_id: response?.analysis?.id,
            category_id: response?.category?.id,
            owner_id: response?.owner?.id,
            description: response?.description,
            condition: response?.condition,
            agency_id: response?.agency_id,
            sampling_period: response?.sampling_period,
            time_range: response?.time_range,
          };
          this.ruleCondition = response?.condition;
          this.createRuleDetail = newRule;
          cb && cb();
        });
      },
      () => {
        cb && cb();
      }
    );
  };

  @action removeSubscriber = (id) => {
    if (this.createSubscriberList.includes(id)) {
      this.createSubscriberList = this.createSubscriberList.filter(
        (e) => e != id
      );
    }
  };

  @action addIntersection = (ids) => {
    this.createIntersectionList = ids;
  };

  @action removeIntersection = (id) => {
    if (this.createIntersectionList.includes(id)) {
      this.createIntersectionList = this.createIntersectionList.filter(
        (e) => e != id
      );
    }
  };

  @action switchIntersectionScope = (scope, ids) => {
    this.setRuleCreateField("intersection_list", ids);
    this.setRuleDetailField("intersection_list", ids);
  };

  @action setDetaultAgencyFilter = (isSuperAdmin, cb) => {
    const agencyService = new AgencyService(this.parentStore);
    if (isSuperAdmin)
      agencyService.getAll((data) => {
        runInAction(() => {
          this.allAgencies = data;
          //set default agency
          if (Array.isArray(this.allAgencies) && this.allAgencies[0]) {
            let defaultAgency = this.allAgencies.find(
              (item) => item.id === this.filterData.agency_id
            );
            if (!defaultAgency) {
              this.filterData.agency_id = this.allAgencies[0].id;
            }
          }
          this.getAllRuleCategories(this.filterData.agency_id);
          cb && cb();
        });
      });
    else {
      const currentAgency = this.parentStore.myStore.currentAgency;
      this.filterData = {
        ...this.filterData,
        ...{ agency_id: currentAgency.agency_id },
      };
      this.getAllRuleCategories(this.filterData.agency_id);
      cb && cb();
    }
  };

  @action getMetric = (cb, fb) => {
    this.loading = true;
    this.moduleService?.getMetric(
      {},
      (response) => {
        runInAction(() => {
          this.metricList = response.categories;
          cb && cb();
        });
      },
      (error) => {
        runInAction(() => {
          fb && fb(error?.errors);
          this.loading = false;
        });
      }
    );
  };

  @action getAllAgencies = (cb) => {
    const agencyService = new AgencyService(this.parentStore);
    agencyService.getAll((data) => {
      runInAction(() => {
        this.allAgencies = data;
        cb && cb();
      });
    });
  };

  @action onChangeCondition = (query) => {
    if (query && this.ruleDetail.condition !== query) {
      this.isEditFormDirty = true;
    }
    this.ruleCondition = query;
    this.createRuleDetail.condition = query;
    this.ruleDetail.condition = query;
  };

  @action setRuleDetailField = (fieldName, value) => {
    this.isEditFormDirty = true;
    const fieldTree = fieldName.split(".");
    if (fieldTree.length > 1) {
      if (!this.ruleDetail[fieldTree[0]]) this.ruleDetail[fieldTree[0]] = {};
      this.ruleDetail[fieldTree[0]][fieldTree[1]] = value;
    } else {
      this.ruleDetail[fieldName] = value;
    }
    this.ruleDetail.condition = this.ruleCondition;
  };

  @action setRuleCreateField = (fieldName, value) => {
    if (fieldName === "agency_id") this.getAllRuleCategories(value);
    const fieldTree = fieldName.split(".");
    if (fieldTree.length > 1) {
      if (!this.createRuleDetail[fieldTree[0]])
        this.createRuleDetail[fieldTree[0]] = {};
      this.createRuleDetail[fieldTree[0]][fieldTree[1]] = value;
    } else {
      this.createRuleDetail[fieldName] = value;
    }
  };

  @action changeCreateRuleTab = (tabId) => {
    this.activeCreateTab = tabId;
  };

  @action submitCreateRule = (cb, fb) => {
    this.loading = true;
    const subscribers = this.createSubscriberList.map((e) => e.id).join(",");
    const intersections = this.createIntersectionList
      .map((e) => e.id)
      .join(",");
    this.createRuleDetail.subscriber_list = subscribers;
    this.createRuleDetail.condition = this.ruleCondition;
    if (this.createRuleDetail.intersection_list !== "*")
      this.createRuleDetail.intersection_list = intersections;
    const payload = this.createRuleDetail;
    payload.time_range.end = this.checkHourPayload(payload.time_range.end);
    payload.time_range.start = this.checkHourPayload(payload.time_range.start);
    this.moduleService?.create(
      payload,
      () => {
        cb && cb();
      },
      (error) => {
        runInAction(() => {
          fb && fb(error?.errors);
          this.loading = false;
        });
      }
    );
  };

  @action submitUpdateRule = (cb, fb) => {
    this.loading = true;
    runInAction(() => {
      this.ruleDetail.condition = this.ruleCondition;
    });
    const payload = this.ruleDetail;
    if (
      payload.analysis_id === undefined ||
      payload.analysis_id === null ||
      payload.analysis_id === ""
    ) {
      payload.analysis_id = this.ruleDetail?.analysis?.id;
    }
    if (
      payload.category_id === undefined ||
      payload.category_id === null ||
      payload.category_id === ""
    ) {
      payload.category_id = this.ruleDetail?.category?.id;
    }
    payload.time_range.end = this.checkHourPayload(payload.time_range.end);
    payload.time_range.start = this.checkHourPayload(payload.time_range.start);
    payload.condition = this.ruleCondition;
    this.moduleService?.update(
      payload,
      () => {
        // helper.showNotification(NOTIFICATION_TYPE.SUCCESS, i18n.t("success"), i18n.t("update_success"))
        cb && cb();
      },
      (error) => {
        runInAction(() => {
          fb && fb(error?.errors);
          this.loading = false;
        });
      }
    );
  };

  checkHourPayload = (value) => {
    if (!value) return null;
    if (value instanceof moment) {
      return value.format(DATE_FORMAT.hour);
    } else return value;
  };

  @action getAllRuleAnalysis = () => {
    if (this.allRuleAnalysis?.length == 0) {
      this.loading = true;
      this.moduleService?.getRuleAnalysis(
        (response) => {
          runInAction(() => {
            this.allRuleAnalysis = response.analyses;
          });
        },
        () => {
          runInAction(() => {
            this.loading = false;
          });
        }
      );
    }
  };

  @action getAllRuleCategories = (agency_id) => {
    // if (this.allRuleCategories?.length == 0) {
    this.loading = true;
    this.moduleService?.getRuleCategories(
      agency_id,
      (response) => {
        runInAction(() => {
          this.allRuleCategories = response.categories;
        });
      },
      () => {
        runInAction(() => {
          this.loading = false;
        });
      }
    );
    // }
  };

  @action getAllUsers = (agency_id) => {
    const userService = new UserService(this.parentStore);
    if (this.allUsers?.length == 0) {
      this.loading = true;
      userService?.getAllUserByAgency(
        agency_id,
        (response) => {
          runInAction(() => {
            this.allUsers = response?.data;
          });
        },
        () => {
          runInAction(() => {
            this.loading = false;
          });
        }
      );
    }
  };

  @action getAllIntersections = (agency_id) => {
    const intersectionService = new IntersectionService(this.parentStore);
    if (this.allIntersections?.length == 0) {
      this.loading = true;
      intersectionService?.getAllIntersectionsByAgency(
        {
          agency_id,
          status: AVAILABLE,
        },
        (response) => {
          runInAction(() => {
            this.allIntersections = response?.data;
          });
        },
        () => {
          runInAction(() => {
            this.loading = false;
          });
        }
      );
    }
  };
  confirmLeaveRuleDetail = (tabIndex) => {
    this.getRuleDetail(this.ruleDetail.id, () => {
      this.changeCreateRuleTab(tabIndex);
    });
  };
  @action getRuleDetail = (id, cb) => {
    this.isEditFormDirty = false;
    this.moduleService?.getDetail(
      id,
      (response) => {
        runInAction(() => {
          this.ruleDetail = response;
          let miliSeCondStart = moment(
            response.time_range.start,
            DATE_FORMAT.hour
          ).valueOf();
          let miliSecondEnd = moment(
            response.time_range.end,
            DATE_FORMAT.hour
          ).valueOf();
          let dur = Math.round((miliSecondEnd - miliSeCondStart) / 60000);
          this.setOverNight(false);
          if (dur <= 0) {
            dur += 1440;
            this.setOverNight(true);
          }
          let durMinutes = dur % 60;
          let durHours = (dur - durMinutes) / 60;
          let durationString =
            (durHours < 10 ? "0" + durHours : durHours) +
            ":" +
            (durMinutes < 10 ? "0" + durMinutes : durMinutes);
          this.ruleDetail.time_range.duration = durationString;

          this.getAllRuleCategories(response?.agency_id);
          this.onChangeCondition(response?.condition);
          cb && cb();
        });
      },
      () => {
        cb && cb();
      }
    );
  };

  @action setOverNight(value) {
    this.overNight = value;
  }

  @action deActive = (data = null) => {
    this.loading = true;
    if (!data) {
      data = this.selectedItems;
    }
    this.moduleService?.updateStatus(
      "/inactive",
      { rule_ids: data },
      () => {
        this.getData();
      },
      () => {
        runInAction(() => {
          this.loading = false;
        });
      }
    );
  };

  @action active = (data = null) => {
    this.loading = true;
    if (!data) {
      data = this.selectedItems;
    }
    this.moduleService?.updateStatus(
      "/active",
      { rule_ids: data },
      () => {
        this.getData();
      },
      () => {
        runInAction(() => {
          this.loading = false;
        });
      }
    );
  };

  @action subscribe = (data = null, options = { action: "SUBSCRIBE" }) => {
    this.loading = true;
    if (!data) {
      data = this.selectedItems;
    }
    this.moduleService?.subscribe(
      { ...options, rule_ids: data },
      options,
      () => {
        this.getData();
      },
      () => {
        runInAction(() => {
          this.loading = false;
        });
      }
    );
  };

  @action unsubscribe = (data = null) => {
    this.loading = true;
    if (!data) {
      data = this.selectedItems;
    }
    this.moduleService?.unsubscribe(
      { rule_ids: data },
      () => {
        this.getData();
      },
      () => {
        runInAction(() => {
          this.loading = false;
        });
      }
    );
  };

  @action delete = (data = null) => {
    this.loading = true;
    if (!data) {
      data = this.selectedItems;
    }
    this.moduleService?.delete(
      data,
      () => {
        //redirect into page 1 avoid load wrong data
        this.filterData.pagination.current = 1;
        this.getData();
      },
      () => {
        runInAction(() => {
          this.loading = false;
        });
      }
    );
  };

  @action openManualTriggerAlarmRulePopup = (alarmRule) => {
    runInAction(() => {
      this.manualTriggerAlarmRule = alarmRule;
      this.triggerAlarmRulePopup = true;
    });
  };

  @action closeManualTriggerAlarmRulePopup = () => {
    runInAction(() => {
      this.manualTriggerAlarmRule = null;
      this.triggerAlarmRulePopup = false;
    });
  };

  @action triggerAlarmRule = (alarmRule, date) => {
    if (!alarmRule) {
      return;
    }
    this.moduleService?.triggerAlarmRuleManually(alarmRule.id, date, () => {
      runInAction(() => {
        this.manualTriggerAlarmRule = null;
        this.triggerAlarmRulePopup = false;
      });
    });
  };

  @action resetRuleDetailState = () => {
    this.ruleDetail = {};
  };
}

export default AlarmRuleStore;
