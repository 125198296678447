import React, { Component, createRef } from "react";
import { observer, inject } from "mobx-react";
import moment from "moment";
import { Button, Radio, Empty, Spin, Modal, Checkbox, Skeleton } from "antd";
import { withTranslation } from "react-i18next";
import {
  DATE_FORMAT,
  ANALYSIS_TYPE,
  TIMEOUT_RESIZE,
  ANALYSIS_LEGEND,
} from "utils/constants";
import ChartItem from "components/ChartItem";
import MiniMap from "components/Charts/MiniMap";
import AnalysisLegend from "containers/Analysis/AnalysisLegend";
import ChartPhase from "components/Charts/ChartPhase";
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import PrintPreview from "containers/Analysis/AnalysisResult/PrintPreview";
import { withRouter } from "react-router";

@inject("rootStore")
@observer
class WrapAnalysisData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      resizing: false,
    };
    this.listRef = createRef();
    this.scrollHeight = 0;
  }
  componentDidMount() {
    window.addEventListener("resize", this.handleWindowResize);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowResize);
  }
  handleWindowResize = () => {
    const {
      rootStore: {
        analysisScheduleResultStore: { printPReview },
      },
    } = this.props;
    //do not resize when in print preview cuz it will re render the PDF file => this is burnning RAM and TIME
    if (!printPReview) {
      this.setState({ resizing: true });
      setTimeout(() => {
        this.setState({ resizing: false });
      }, TIMEOUT_RESIZE);
    }
  };
  convertTimeFormat = (value) => {
    return moment(value, DATE_FORMAT.backend).format(
      DATE_FORMAT.analysis_range
    );
  };

  print = () => {
    // window.print();
    const {
      rootStore: {
        analysisScheduleResultStore: { togglePrintPReviewVisibility },
      },
    } = this.props;

    togglePrintPReviewVisibility();
  };
  renderExtraOptions = () => {
    const {
      t,
      rootStore: {
        chartStore: {
          chartInfo,
          currentChartTypeFrontEnd,

          setCurrentChartTypeFrontEnd,
        },
      },
    } = this.props;
    switch (chartInfo.analysis_type) {
      case ANALYSIS_TYPE.PED_TYPE:
        return (
          <Radio.Group
            value={currentChartTypeFrontEnd}
            style={{ marginRight: "20px" }}
            onChange={(e) => {
              setCurrentChartTypeFrontEnd(
                ANALYSIS_TYPE.PED_TYPE,
                e.target.value
              );
            }}
            buttonStyle="solid"
            size="large"
            className="ped-mode">
            <Radio.Button
              value={ANALYSIS_TYPE.PED_DELAY}
              style={{ fontSize: 14 }}>
              {t("PEDESTRIAN_DELAY")}
            </Radio.Button>
            <Radio.Button
              value={ANALYSIS_TYPE.PED_ACTIVITIES}
              style={{ fontSize: 14 }}>
              {t("PEDESTRIAN_ACTIVITIES")}
            </Radio.Button>
          </Radio.Group>
        );

      case ANALYSIS_TYPE.PT_TYPE:
        return (
          <Checkbox
            checked={
              currentChartTypeFrontEnd === ANALYSIS_TYPE.PT_HIGHLIGHT_TYPE
                ? true
                : false
            }
            onChange={(e) => {
              if (e.target.checked)
                setCurrentChartTypeFrontEnd(
                  ANALYSIS_TYPE.PT_TYPE,
                  ANALYSIS_TYPE.PT_HIGHLIGHT_TYPE
                );
              else
                setCurrentChartTypeFrontEnd(
                  ANALYSIS_TYPE.PT_TYPE,
                  ANALYSIS_TYPE.DEFAULT_PT
                );
            }}
            style={{ lineHeight: "32px" }}>
            {" "}
            {t("analysis.hightline_actual")}
          </Checkbox>
        );
      case ANALYSIS_TYPE.APPOARCH_DELAY:
        return (
          <Radio.Group
            value={currentChartTypeFrontEnd}
            style={{ marginRight: "20px" }}
            onChange={(e) => {
              setCurrentChartTypeFrontEnd(
                ANALYSIS_TYPE.APPOARCH_DELAY,
                e.target.value
              );
            }}
            buttonStyle="solid"
            size="large"
            className="ped-mode">
            <Radio.Button
              value={ANALYSIS_TYPE.APPOARCH_DELAY_PHASE_VIEW}
              style={{ fontSize: 14 }}>
              {t("APPOARCH_DELAY_PHASE_VIEW")}
            </Radio.Button>
            <Radio.Button
              value={ANALYSIS_TYPE.APPOARCH_DELAY_LANE_VIEW}
              style={{ fontSize: 14 }}>
              {t("APPOARCH_DELAY_LANE_VIEW")}
            </Radio.Button>
          </Radio.Group>
        );

      default:
        return <></>;
    }
  };

  renderListItem = ({ height, width }) => {
    const {
      rootStore: {
        chartStore: { chartInfo, chartData },
      },
    } = this.props;

    return (
      <List
        ref={this.listRef}
        className="list"
        height={height}
        itemCount={chartData?.length}
        itemSize={chartInfo?.isCompareMode ? width * 0.8 : width * 0.4}
        width={width}>
        {ChartItem.bind(this)}
      </List>
    );
  };
  render() {
    const {
      t,
      rootStore: {
        myStore: { currentProfile },
        chartStore: {
          chartInfo,
          allChartData,
          filterData,
          updateFilterData,
          getChartByIndex,
          currentChartTypeFrontEnd,
        },
        analysisScheduleResultStore: {
          loading,
          loadingChart,
          printPReview,
          togglePrintPReviewVisibility,
        },
      },
      history,
    } = this.props;

    const totalPhase = allChartData?.map((e) => e.phase);
    return loading || loadingChart ? (
      <div className="bg-background px-8 py-8 h-full">
        <Skeleton />
        <Skeleton />
      </div>
    ) : chartInfo && allChartData.length && !this.state.resizing ? (
      <div className="wrap-analysis-result">
        <div className="wrap-analysis-header">
          <div className="analysis-header flex items-center py-8 px-8 bg-background">
            <div>
              <Button
                size={"large"}
                icon="arrow-left"
                onClick={() =>
                  history.push("/report/result/performance-metric")
                }>
                {t("detail.back")}
              </Button>
            </div>
            <div className="flex ">
              <div>{this.renderExtraOptions()}</div>

              <div>
                <Button icon="printer" size={"large"} onClick={this.print}>
                  {t("analysis.print-title")}
                </Button>
              </div>
            </div>
          </div>
          <div className="flex w-full warning-mess">
            {chartInfo.isGapData && (
              <div className="">{t("analysis.gap_warning")} &nbsp; </div>
            )}

            {!chartInfo.isFullyAppoarch && (
              <div>{t("analysis.appoarch_warning")}</div>
            )}
          </div>
          <MiniMap
            dataState={[filterData.from_time, filterData.to_time]}
            updateMapState={(fromTime, toTime) => {
              updateFilterData("from_time", fromTime, false);
              updateFilterData("to_time", toTime);
            }}
            fullState={[chartInfo?.from_time, chartInfo?.to_time]}
          />
          <div></div>
          <div className="filter-function">
            <ChartPhase
              totalPhase={totalPhase}
              hiddenPhase={filterData?.hiddenPhase}
              updateHiddenPhase={(hiddenPhase) => {
                localStorage.setItem(
                  "siwave_hidden_phase",
                  JSON.stringify(hiddenPhase)
                );
                updateFilterData("hiddenPhase", hiddenPhase);
              }}
            />
            <AnalysisLegend
              totalLegend={ANALYSIS_LEGEND[currentChartTypeFrontEnd]}
              hiddenLegend={filterData?.hiddenLegend}
              updateHiddenLegend={(hiddenLegend) => {
                updateFilterData("hiddenLegend", hiddenLegend);
              }}
            />
          </div>
        </div>
        <div className="analysis-body body-normal styled-scroll">
          {this.state.loading ? (
            <Spin size="large" className={"centralize"} />
          ) : (
            <AutoSizer>{this.renderListItem.bind(this)}</AutoSizer>
          )}
        </div>
        <Modal
          className="alarm-records-summary-modal"
          centered
          width={"80%"}
          title={t("print.modal-title")}
          visible={printPReview}
          onCancel={togglePrintPReviewVisibility}
          footer={null}
          destroyOnClose={true}>
          <PrintPreview
            totalPage={totalPhase}
            getChartByIndex={getChartByIndex}
            printInfo={{
              ...chartInfo,
              chartType: currentChartTypeFrontEnd,
              totalLegend: ANALYSIS_LEGEND[currentChartTypeFrontEnd],
            }}
            printFilter={filterData}
            reporter={currentProfile?.name}
          />
        </Modal>
      </div>
    ) : (
      <>
        <div className="wrap-analysis-result">
          <div className="wrap-analysis-header">
            <div className="analysis-header flex items-center py-8 px-8 bg-background">
              <div>
                <Button
                  size={"large"}
                  icon="arrow-left"
                  onClick={() =>
                    history.push("/report/result/performance-metric")
                  }>
                  {t("detail.back")}
                </Button>
              </div>
            </div>
          </div>
          <div className="analysis-body body-normal styled-scroll flex items-center justify-center">
            <Empty></Empty>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(withTranslation()(WrapAnalysisData));
