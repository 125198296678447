import { Button, Modal } from "antd";
import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Map, Marker, Popup, TileLayer } from "react-leaflet";
import Control from "react-leaflet-control";
import { DEFAULT_PIN, SELECTED_PIN } from "utils/constants";
import "./style.scss";

@inject("rootStore")
@observer
class MapPopup extends Component {
  render() {
    const {
      t,
      onClose,
      visible,
      onMapSelect,
      rootStore: { analysisStore },
    } = this.props;
    return (
      <Modal
        visible={visible}
        width={"80%"}
        title={t("map_popup.title")}
        footer={null}
        onCancel={onClose}
        destroyOnClose={true}
      >
        <Map
          className="map-full-screen"
          zoom={analysisStore?.currentMapZoom}
          center={analysisStore?.currentMapCenter}
        >
          <TileLayer
            url={process.env.REACT_APP_OPEN_STREET_MAP}
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />
          <Control position="bottomright">
            <Button
              icon="fullscreen-exit"
              className="map-fullscreen"
              onClick={onClose}
            />
          </Control>
          {analysisStore?.listIntersections?.map((intersection) => {
            let marker = null;
            if (intersection) {
              marker = (
                <Marker
                  icon={
                    analysisStore.filterData.intersection_id === intersection.id
                      ? SELECTED_PIN
                      : DEFAULT_PIN
                  }
                  position={[intersection.latitude, intersection.longitude]}
                  key={intersection.id}
                  onClick={() => onMapSelect(intersection.id)}
                >
                  <Popup>{intersection.name}</Popup>
                </Marker>
              );
              intersection.marker = marker;
              analysisStore.listAllIntersectionsMarker.push(marker);
            }
            return marker;
          })}
        </Map>
      </Modal>
    );
  }
}

export default withTranslation()(MapPopup);
