import {
  Alert,
  Badge,
  Button,
  Drawer,
  Form,
  Icon,
  List,
  Spin,
  Tooltip,
  Typography,
} from "antd";
import SpinLoading from "components/PrivateLayout/SpinLoading";
import { inject, observer } from "mobx-react";
import moment from "moment";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import { DATE_FORMAT, NOTIFICATION_REDIREC_ACTION } from "utils/constants";
import { PROGRESS_STATUS_TYPE } from "utils/constants/index";
import Helper from "utils/helper";
import "./style.scss";
/**
 * Component for showing details of the user.
 *
 *
 * @component
 */
@inject("rootStore")
@observer
class AllJobProgress extends Component {
  /**
   * contructor of component
   * @param   {object} props  props of component
   */
  constructor(props) {
    super(props);
  }
  /**
   * render.
   *
   */
  /**
   * render button match with response from server
   * @param   {object} item  button of each item
   */
  renderActionButton = (item) => {
    const {
      t,
      history,
      rootStore: { jobProgressStore, reportResultStore },
    } = this.props;
    let arrayBtn = [];
    if (item?.canCancel)
      arrayBtn.push(
        <Button
          onClick={() =>
            jobProgressStore?.handleActionWithProgress(
              jobProgressStore?.PROGRESS_ACTION_BTN_TYPE.CANCEL,
              item?.id
            )
          }
        >
          {t("job_progress.cancel_btn")}
        </Button>
      );
    if (item?.action)
      arrayBtn.push(
        <Button
          onClick={() => {
            if (item?.action?.target === "REPORT_RESULTS") {
              history.push(
                NOTIFICATION_REDIREC_ACTION.REPORT_RESULTS +
                  "/" +
                  item?.action?.data?.report_result_id
              );
              if (
                this.props?.location?.pathname.indexOf(
                  NOTIFICATION_REDIREC_ACTION.REPORT_RESULTS
                ) !== -1
              )
                reportResultStore.getResultData(
                  item?.action?.data?.report_result_id
                );
              jobProgressStore.setDrawerVisible(false);
            } else
              Helper.handleLinkAction(
                item?.action,
                history,
                this.props.rootStore,
                jobProgressStore,
                t
              );
          }}
        >
          {item?.action?.btn_title}
        </Button>
      );
    if (item?.canRemove)
      // For ERROR, COMPLETED, CANCELLED
      arrayBtn.push(
        <Button
          onClick={() =>
            jobProgressStore?.handleActionWithProgress(
              jobProgressStore?.PROGRESS_ACTION_BTN_TYPE.CLEAR,
              item?.id
            )
          }
        >
          {t("job_progress.clear_btn")}
        </Button>
      );
    return arrayBtn;
  };
  /**
   * render.
   *
   */
  /**
   * render Badge status match with response from server
   * @param   {object} item  Badge of each item
   */
  getBadgeStatus = (item) => {
    switch (item.status) {
      case PROGRESS_STATUS_TYPE.PROCESSING:
        return "processing";
      case PROGRESS_STATUS_TYPE.COMPLETED:
        return "success";

      default:
        return "error";
    }
  };
  /**
   * return Tooltip of detail header
   */
  detailHeader = () => {
    const { t } = this.props;
    return (
      <div style={{ width: "100%" }}>
        <Tooltip
          placement="rightBottom"
          title={t("job_progress.header_progress_detail_tooltip")}
        >
          <span>{t("job_progress.header_title")}</span>

          <span className="tooltip">
            <Icon type="info-circle" />
          </span>
        </Tooltip>
      </div>
    );
  };

  render() {
    const { t } = this.props;
    const jobProgress = this.props.rootStore?.jobProgressStore;
    return (
      <Drawer
        title={this.detailHeader()}
        width={this.drawerWithPercent}
        className="spm-detail-drawer"
        placement="right"
        destroyOnClose
        onClose={jobProgress.closeCreateOrEditForm}
        visible={jobProgress?.drawerVisible}
      >
        <Spin
          size="large"
          style={{ height: "100%" }}
          indicator={<SpinLoading visible={true}></SpinLoading>}
          spinning={jobProgress.loading}
        >
          <div className="wrap-job_progress-progress-detail">
            <div>
              <Icon
                type="reload"
                className="reload-progress"
                onClick={() =>
                  jobProgress.handleShowAllJob(
                    t(),
                    jobProgress.DOWNLOAD_TYPE.PROGRESS
                  )
                }
              />
            </div>
            {jobProgress?.errorList && (
              <Alert
                message={jobProgress?.errorList?.message}
                type="error"
                closable
                showIcon
                onClose={jobProgress?.clearErrorList}
                className="spm-error-comp"
              />
            )}
            <List
              itemLayout={"vertical"}
              dataSource={jobProgress?.exportProgressData}
              renderItem={(item) => (
                <List.Item
                  actions={this.renderActionButton(item)}
                  className="job-process-info"
                >
                  <List.Item.Meta title={item.title} style={{ margin: 0 }} />
                  {/* response description and "from time to time" were in a String separated by ";" */}
                  {item.description?.split(";").map((e, id) => {
                    return <div key={id}>{e}</div>;
                  })}
                  <p className="job-create-time">
                    {t("job_progress.download_progress_created") +
                      ": " +
                      moment(item.created_at).format(DATE_FORMAT.full)}
                  </p>
                  <div>
                    <Badge
                      className="job-status"
                      status={this.getBadgeStatus(item)}
                      text={item.status}
                    />
                  </div>
                  {item.error_message && (
                    <Typography>
                      <Typography.Text type="danger">
                        {item.error_message}{" "}
                      </Typography.Text>
                    </Typography>
                  )}
                </List.Item>
              )}
            />
          </div>
        </Spin>
      </Drawer>
    );
  }
}

const AllJobtProgressForm = Form.create({
  name: "jobProgress_progress_detail",
})(AllJobProgress);

export default withRouter(withTranslation()(AllJobtProgressForm));
