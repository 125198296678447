import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import SummaryChart from "./SummaryChart";
import "./style.scss";
import { Empty } from "antd";

@inject("rootStore")
@observer
class SummaryView extends Component {
  translateKey = "analysis.moe";

  constructor(props) {
    super(props);
  }

  transformData = () => {
    const { data, viewPattern } = this.props;

    let phase_summary = data[viewPattern]?.phase_summary;
    let phase_split_time = data[viewPattern]?.phase_split_time;

    return {
      phase_summary,
      phase_split_time,
    };
  };

  transformMatrixDataTable = (rawData) => {
    if (!rawData) return [];
    const phases = Object.keys(rawData);

    if (!phases?.length && !rawData[phases[0]]) return [];

    const attributeNames = Object.keys(rawData[phases[0]])
      .filter((e) => e !== "approach");

    const transposedTable = [
      phases.map(
        (phase) =>
          `Phase ${phase}` +
          (rawData[phase].approach?.length
            ? ` (${rawData[phase].approach?.join(", ")})`
            : "")
      ),
    ].concat(attributeNames.map((attribute) => [attribute]));

    for (const attribute of attributeNames) {
      for (const phase of phases) {
        transposedTable[attributeNames.indexOf(attribute) + 1].push(
          rawData[phase][attribute]
        );
      }
    }

    return transposedTable;
  };

  renderMatrixTable = (tableData) => {
    const { t } = this.props;

    const renderCell = (cell) => {
      // is integer
      if (Number.isInteger(cell)) {
        return cell;
      }

      // is float
      if (Number.isFinite(cell)) {
        return cell.toFixed(2);
      }

      // is 'NaN'
      if (cell === "NaN") {
        return "-";
      }

      return "-";
    };

    return (
      <table className="matrix-table">
        <thead>
        <tr>
          <th></th>
          {tableData?.[0]?.map((header) => (
            <th key={header}>{header}</th>
          ))}
        </tr>
        </thead>
        <tbody>
        {tableData.slice(1).map((row) => (
          <tr key={row[0]}>
            {row.map((cell, index) => (
              <td key={index}>
                {index === 0
                  ? t(`${this.translateKey}.${cell}`)
                  : renderCell(cell)}
              </td>
            ))}
          </tr>
        ))}
        </tbody>
      </table>
    );
  };

  render() {
    const { phase_summary, phase_split_time } = this.transformData();
    const { t } = this.props;
    const matrixDataTable = this.transformMatrixDataTable(phase_summary);
    return matrixDataTable?.length ? (
      <div className="flex gap-4 w-full h-full">
        <div className="matrix-table-pane flex-1-1-auto overflow-auto">
          <div className="mb-8 flex justify-end ">
            <table className="bordered ">
              <tbody>
              <tr>
                <th className="p-4  font-12">Acronym</th>
                <td className="p-4 font-12">F</td>
                <td className="p-4  font-12">M</td>
                <td className="p-4 font-12">G</td>
                <td className="p-4 font-12">P</td>
              </tr>
              <tr>
                <th className="p-4 font-12">Description</th>
                <td className="p-4 font-12">{t("analysis.force_off")}</td>
                <td className="p-4 font-12">{t("analysis.max_out")}</td>
                <td className="p-4 font-12">{t("analysis.gap_out")}</td>
                <td className="p-4 font-12">
                  {t("analysis.pedestrian_call")}
                </td>
              </tr>
              </tbody>
            </table>
          </div>

          {this.renderMatrixTable(matrixDataTable)}
        </div>
        <div
          style={{
            flex: "0 0 50%",
          }}
          className="overflow-auto h-full">
          <SummaryChart data={phase_split_time}/>
        </div>
      </div>
    ) : (
      <Empty className="h-full flex flex-col items-center justify-center"/>
    );
  }
}

export default withTranslation()(SummaryView);
