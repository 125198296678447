import { Form, Select } from "antd";
import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";

const Option = Select.Option;

/**
 * Component for user side drawer.
 * user agency information tab
 *
 * @component
 */
@inject("rootStore")
@observer
class UserAgencyForm extends Component {
  formFields = ["default_agency"];

  /**
   * isFormChanged
   * check current form is changed data with current setting formFields
   * @return  {boolean} true/false
   */
  isFormChanged = () => {
    return this.props.form?.isFieldsTouched(this.formFields);
  };
  /**
   * componentDidMount
   * binding this component to parent childRef
   * in this case click on save button calling handlesubmit
   * incase leaving page call check form data changed
   * @return  {null} -
   */
  componentDidMount() {
    const { childRef } = this.props;
    childRef(this);
  }

  /**
   * handleSubmit
   *
   * @return  {Object}
   */
  handleSubmit = () => {
    const {
      rootStore: { myStore },
    } = this.props;
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let submitValue = {
          ...values,
        };
        myStore?.setDefaultAgency(submitValue).catch((errors) => {
          errors?.map((error) => {
            let message = error?.message;
            let err = {};
            err[error?.field] = {
              value: values[error?.field],
              errors: [new Error(message)],
            };
            this.props.form?.setFields(err);
          });
        });
      }
    });
  };
  /**
   * render
   *
   * @return  {Component}
   */
  render() {
    const {
      rootStore: { myStore },
      t,
      form: { getFieldDecorator },
    } = this.props;
    let defaultAgency = myStore?.getDefaultAgency();
    let currentAgency = myStore?.getCurrentAgency();
    return (
      <div className="wrap-user-agency-form">
        <Form>
          <Form.Item label={t("profile.current_agency")}>
            {currentAgency?.agency_name}
          </Form.Item>
          <Form.Item label={t("profile.agency_role")}>
            {currentAgency?.role_name}
          </Form.Item>
          <Form.Item label={t("profile.default_agency")}>
            {getFieldDecorator("default_agency", {
              initialValue: defaultAgency?.id,
              rules: [
                {
                  required: true,
                  message: t("profile.default_agency_required"),
                },
              ],
            })(
              <Select className="language-select">
                {myStore?.availableAgencies?.map((e) => (
                  <Option key={e.id} value={e.id}>
                    {e.name}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Form>
      </div>
    );
  }
}

const UserAgencyTabForm = Form.create({ name: "user-agency-form" })(
  UserAgencyForm
);

export default withTranslation()(UserAgencyTabForm);
