import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import "./style.scss";
import { Col, Empty, Form, Row, Select, Spin } from "antd";
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router";
import CustomAnalysisChart from "components/Charts/CustomAnalysisChart";
import { CUSTOM_ANALYSIS_CONSTANT } from "utils/constants";
import MiniMap from "components/Charts/MiniMap";
const { Option } = Select;
const { OptGroup } = Select;
@inject("rootStore")
@observer
class CustomAnalysisResult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      idSelectedItem: null,
      isEdit: false,
      tableData: [],
    };
  }
  getPartenFilter = () => {
    let arrayComponent = [];
    let arrRow = [1, 2, 3, 4];
    for (let i = 0; i < 254 / 4; i++) {
      arrayComponent.push(
        <OptGroup key={i}>
          {arrRow.map((e) => {
            let keyId = i * 4 + e;
            if (keyId <= 254)
              return (
                <Select.Option key={keyId} style={{ minWidth: 80 }}>
                  {keyId < 10 ? "0" + keyId : keyId}
                </Select.Option>
              );
            else return null;
          })}
        </OptGroup>
      );
    }
    return arrayComponent;
  };

  render() {
    const {
      t,
      rootStore: {
        customAnalysisStore: {
          objDataChart,
          dataAnalysisChart,
          isRunAnalysis,
          listGroupEventFilter,
          filterAnalysisByParamandEventGroup,
          listLegend,
          chartInfor,
          updateFilterData,
          listAllEvent
        },
      },
    } = this.props;
    const formattedTimeStart = chartInfor?.fromTime;
    const formattedTimeEnd = chartInfor?.toTime;
    const nameAnalysis = chartInfor?.name;
    const intersectionName = chartInfor?.nameIntersection;
    const listEventGroupDisplay = CUSTOM_ANALYSIS_CONSTANT.filter((item) =>
      listGroupEventFilter?.includes(item.key)
    );
    const listLegendDisplay = CUSTOM_ANALYSIS_CONSTANT.filter((item) =>
      listLegend?.includes(item.key)
    );

    return (
      <>
        {isRunAnalysis ? (
          <Spin className={"centralize"} size="large" />
        ) : objDataChart ? (
          <>
            <Row
              key={dataAnalysisChart}
              gutter={[18, 8]}
              style={{
                padding: " 30px 30px 0 30px",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Col xl={15} md={24}>
                <p
                  style={{ fontSize: "15px" }}
                >{`${nameAnalysis} - Intersection: ${intersectionName} - From time: ${formattedTimeStart} - To time: ${formattedTimeEnd}`}</p>
              </Col>
              <Col xl={4} md={24}>
                {" "}
                <Select
                  mode="multiple"
                  allowClear
                  placeholder="Filter by event group"
                  onChange={(e) => {
                    filterAnalysisByParamandEventGroup("listEventGroup", e);
                  }}
                  showSearch
                  optionFilterProp="filter"
                  filterOption={(input, option) => {
                    return (
                      option?.props?.filter
                        ?.toLowerCase()
                        ?.indexOf(input?.toLowerCase()) >= 0
                    );
                  }}
                >
                  {listEventGroupDisplay?.map((e, id) => (
                    <Option filter={e.key} key={id} value={e.key}>
                      {e.label}
                    </Option>
                  ))}
                </Select>
              </Col>
              <Col xl={4} md={24}>
                <Select
                  mode="multiple"
                  style={{ width: "350px" }}
                  onChange={(e) => {
                    filterAnalysisByParamandEventGroup("listParam", e);
                  }}
                  placeholder={t("intersection_config.filter_pattern")}
                >
                  {this.getPartenFilter()}
                </Select>
              </Col>
            </Row>
            <div className='mt-8'>
              <MiniMap
                dataState={[dataAnalysisChart.from_time, dataAnalysisChart.to_time]}
                updateMapState={(fromTime, toTime) => {
                  updateFilterData("from_time", fromTime, false);
                  updateFilterData("to_time", toTime);
                }}
                fullState={[chartInfor?.fromTime, chartInfor?.toTime]}
              />
            </div>
            <CustomAnalysisChart {...dataAnalysisChart} listGroupEventFilter={listGroupEventFilter} listAllEvent={listAllEvent}/>
            <div style={{ display: "flex", justifyContent: "center" }}>
              {listLegendDisplay?.map((item, id) => {
                const color = item.color;
                return (
                  <div
                    style={{ display: "flex", marginRight: "30px" }}
                    key={id}
                  >
                    <div
                      style={{
                        width: "10px",
                        height: "10px",
                        borderRadius: "50px",
                        backgroundColor: `${color}`,
                        marginRight: "3px",
                        marginTop: "6px",
                      }}
                    ></div>
                    <div style={{ paddingBottom: "10px" }}>{item.label}</div>
                  </div>
                );
              })}
            </div>
          </>
        ) : (
          <Empty />
        )}
      </>
    );
  }
}
const CustomAnalysisFormResult = Form.create({ name: "custom_analysis" })(
  CustomAnalysisResult
);
export default withRouter(withTranslation()(CustomAnalysisFormResult));
