import { action, observable, runInAction } from "mobx";
import { AOR, CD, PT } from "mockdata/analysis";
import moment from "moment";
import AgencyService from "services/AgencyService";
import AnalysisService from "services/AnalysisService";
import IntersectionService from "services/IntersectionService";
import {
  ANALYSIS_TYPE,
  AVAILABLE,
  DATE_FORMAT,
  DEFAULT_MAP_CENTER,
  DEFAULT_ZOOM_LEVEL,
  TIME_PICKER_FORMAT,
} from "utils/constants";
import helper from "utils/helper";
import BaseStore from "./BaseStore";

const ANALYSIS_TYPE_FIELD = "analysis_type";
const AGTENCY_FIELD = "agency_id";
const INTERSECTION_TYPE_FIELD = "intersection_id";
const REPORT_OFFLINE_MODE = false;

// Store for analysis
class AnalysisStore extends BaseStore {
  constructor(parentStore) {
    super(new AnalysisService(parentStore), parentStore);
    //init relation services
    this.agencyService = new AgencyService(parentStore); //agency service
    this.intersectionService = new IntersectionService(parentStore); //intersection service
    //default name of intersection
    this.defaultIntersectionName = null;
    //init default filter value
    this.filterData = {
      agency_id: null,
      analysis_type: null,
      intersection_id: null,
      date: moment(),
      from_time: moment("00:00", TIME_PICKER_FORMAT),
      to_time: moment("23:59", TIME_PICKER_FORMAT),
      compare_date: moment(),
      is_compare: false,
    };
    //current color setting
    this.currentColorSettingMatrix = null;
    this.currentColorDayMatrix = null;
    this.selectedColorMatrix = null;
    this.currentCalendarData = moment();
  }

  //the flag for getting chart data for reload Analysis resule
  @observable gettingChart = false;
  @observable printPReview = false;
  //Filter box
  //binding full year calendar color by customCSSclasses
  @observable customCSSclasses = null;
  @observable listAnalysisTypes = [];
  @observable listAgencies = [];
  @observable listIntersections = [];
  listAllIntersectionsMarker = [];
  @observable currentAnalysisType = null;
  @observable loadingDate = false;
  @observable filterDataChanged = true;
  //default focus on map
  @observable currentMapCenter = DEFAULT_MAP_CENTER;
  //default zoom level on map
  @observable currentMapZoom = DEFAULT_ZOOM_LEVEL;

  //end filter box

  /**
   * togglePrintPReviewVisibility
   *
   * @return  {null}
   */
  @action togglePrintPReviewVisibility = () => {
    this.printPReview = !this.printPReview;
  };
  /**
   * updateCurrentZoomLevel
   * update zoom level
   *
   * @param {Interger} zoomLevel: the zoom level
   * @return  {null}
   */
  @action updateCurrentZoomLevel = (zoomLevel) => {
    if (0 <= zoomLevel <= 18) {
      //limite the zoom level for seeing data
      this.currentMapZoom = zoomLevel;
    }
  };

  /**
   * resetFilter
   * reset all filter data and get lastest data for filter
   *
   * @return  {null}
   */
  @action resetFilter = () => {
    this.filterData = {
      agency_id: null,
      analysis_type: null,
      intersection_id: null,
      date: moment(),
      from_time: moment("00:00", TIME_PICKER_FORMAT),
      to_time: moment("23:59", TIME_PICKER_FORMAT),
      compare_date: moment(),
      is_compare: false,
    };
    this.listAnalysisTypes = [];
    this.loadingDate = false;
    this.listIntersections = [];
    this.listAllIntersectionsMarker = [];
    this.currentAnalysisType = null;
    this.filterDataChanged = true;
    //default focus on map
    this.currentMapCenter = DEFAULT_MAP_CENTER;
    //default zoom level on map
    this.currentMapZoom = DEFAULT_ZOOM_LEVEL;
    //get all data again to get lastest data
    this.getAllFilterData();
  };

  /**
   * getAllFilterData
   * get all filter data
   *
   * @return  {null}
   */
  @action getAllFilterData = () => {
    this.loading = true;
    let allNeededData = [this.getAllAnalysisTypes()];
    if (this.parentStore.commonStore.isSuperAdmin()) {
      allNeededData.push(this.getAllAgencies());
    } else {
      if (this.parentStore?.myStore?.currentAgency?.agency_id)
        this.filterData.agency_id =
          this.parentStore?.myStore?.currentAgency?.agency_id;
      // if not ? => taticmode
      //get agency setting prepare for agency color
      this.getAgencySettingByAgencyId();
    }
    //load all needed data at a same time
    Promise.all(allNeededData)
      .then(() => {
        this.getAllIntersectionByCurrentAgencyAndType();
      })
      .finally(() => {
        runInAction(() => {
          this.loading = false;
        });
      });
  };

  /**
   * setCurrentMonthYear
   * set current month year of selection calendar for get the date color
   * @param {Int} month: target month
   * @param {Int} year: target year
   * @return  {Promise}
   */
  setCurrentMonthYear = (month = null, year = null) => {
    return new Promise((resolve) => {
      if (month === null || year === null) {
        this.currentCalendarData = moment(); //default by current year
        resolve();
      } else {
        if (month != null) {
          this.currentCalendarData.month(month);
        }
        if (year != null) {
          this.currentCalendarData.year(year);
        }
        //get data for full month
        this.getAllDateByIntersection(
          this.currentCalendarData.clone().startOf("month"),
          this.currentCalendarData.clone().endOf("month")
        ).then(() => {
          resolve();
        });
      }
    });
  };

  /**
   * getAllDateByIntersection
   * get all date value with current slected intersecion
   * @param {Moment} from: start date
   * @param {Moment} to: to date
   * @return  {Promise}
   */
  @action getAllDateByIntersection = (from, to) => {
    this.loading = true;
    return new Promise((resolve) => {
      this.moduleService
        .getAvailableDays({
          analysis_id: this.filterData.analysis_type,
          from_date: from.format(DATE_FORMAT.day_only_backend),
          to_date: to.format(DATE_FORMAT.day_only_backend),
          int_uuid: this.filterData.intersection_id,
        })
        .then((response) => {
          //set color with days
          runInAction(() => {
            //update color by current setting matrix
            if (this.currentColorSettingMatrix) {
              this.selectedColorMatrix =
                this.currentColorSettingMatrix[response?.data?.metric_id];
              //get the selected color matrix setting by response of mertrix id
              if (this.selectedColorMatrix) {
                let colorLevel = this.selectedColorMatrix.color;
                this.customCSSclasses = {};
                if (Array.isArray(colorLevel)) {
                  //update the level color by current setting
                  colorLevel.forEach((item, index) => {
                    let cssKey = "--level-" + index + "-background-color";
                    document.body.style.setProperty(cssKey, item.color);
                    this.customCSSclasses["level-" + index + "-background"] =
                      [];
                  });
                }
              }
            }
            //update the date have data(color)
            this.currentColorDayMatrix = response?.data?.days;
            if (Array.isArray(this.currentColorDayMatrix)) {
              this.currentColorDayMatrix.map((item) => {
                if (item?.value) {
                  //add the date to the customCSSclasses array to draw color on calendar
                  this.selectedColorMatrix?.color?.forEach((color, index) => {
                    if (item?.value > color.from_value) {
                      this.customCSSclasses[
                        "level-" + index + "-background"
                      ]?.push(item.date);
                    }
                  });
                } else {
                  this.customCSSclasses["level-0-background"]?.push(item.date);
                }
              });
            }
          });
          resolve(this.currentColorDayMatrix);
        })
        .catch((error) => {
          if (error && Array.isArray(error.errors)) {
            let message = "";
            error.errors.map((item) => {
              message += item.message + "\n";
            });
            if (message != "") {
              helper.showNotification("error", error.key, message);
            }
          }
        })
        .finally(() => {
          runInAction(() => {
            this.loading = false;
          });
        });
    });
  };

  /**
   * findTheColorByDate
   * find the color by date
   * this function used for small calendar
   * @param {Moment} date:  date
   * @return  {Null}
   */
  findTheColorByDate = (date) => {
    let result = null;
    if (Array.isArray(this.currentColorDayMatrix)) {
      let dayObject = this.currentColorDayMatrix.find(
        (item) => item.date === date.format(DATE_FORMAT.day_only_backend)
      );
      if (dayObject && Array.isArray(this.selectedColorMatrix?.color)) {
        //if backend not return value but the object date still exist - we set it like default
        if (
          !dayObject.value &&
          Array.isArray(this.selectedColorMatrix?.color)
        ) {
          result = this.selectedColorMatrix?.color[0]?.color;
        } else {
          this.selectedColorMatrix?.color?.forEach((color) => {
            if (dayObject.value >= color.from_value) {
              result = color.color;
            }
          });
        }
      }
    }
    return result;
  };

  /**
   * getDisplayDayTitle
   * @param {Moment} date:  date
   * @return  {String} the label value
   */
  getDisplayDayTitle = (day) => {
    //find the target on currentColorDayMatrix
    let target = this.currentColorDayMatrix?.find(
      (item) => item.date === day.format(DATE_FORMAT.day_only_backend)
    );
    let value = target?.value?.toFixed(1); //change format rounded
    return (
      this.selectedColorMatrix?.label +
      ": " +
      (value ? value + this.selectedColorMatrix?.unit : "N/A")
    );
  };

  /**
   * handleFilterDataChange
   * this function will trigger when user input filtering or searching data
   * if filter data is search text and search length >=3 or length == 0 automatic start search
   * @param   {String} target  Field name wanna filter
   * @param   {String} value   Filtering data
   * @return  {null} -  setState of current filter data
   */
  @action handleFilterDataChange = (target, value) => {
    if (this.filterData) {
      this.filterData[target] = value;
    }
    if (target === ANALYSIS_TYPE_FIELD || target === AGTENCY_FIELD) {
      if (target === ANALYSIS_TYPE_FIELD) {
        this.currentAnalysisType = this.listAnalysisTypes.find(
          (item) => item.id === value
        );
        //set default value of new analyistype for filter data
        this.currentAnalysisType?.extra_fields?.map(
          (item) => (this.filterData[item.id] = item.default_value)
        );
      }
      if (target === AGTENCY_FIELD) {
        let currentAgency = this.listAgencies?.find(
          (item) => item.id === value
        );
        if (currentAgency?.location && currentAgency?.location?.lat) {
          this.currentMapCenter = [
            currentAgency?.location.lat,
            currentAgency?.location.lon,
          ];
        } else {
          this.currentMapCenter = DEFAULT_MAP_CENTER;
        }
        //get Agency Setting prepare for binding color on calendar
        this.getAgencySettingByAgencyId();
        this.filterData.intersection_id = null;
        this.getAllIntersectionByCurrentAgencyAndType();
      }
    }
    if (target === INTERSECTION_TYPE_FIELD) {
      let selectedIntersection = this.listIntersections.find(
        (item) => item.id === value
      );
      if (selectedIntersection) {
        this.currentMapCenter = [
          selectedIntersection.latitude,
          selectedIntersection.longitude,
        ];
        //endable selectable date calendar
        this.loadingDate = true;
      }
    }
    this.handleFilterDataChanged(true);
  };

  /**
   * getAllAnalysisTypes
   * get all analysis type
   * @return  {null} -  call the callback function
   */
  getAllAnalysisTypes = () => {
    return new Promise((resolve, reject) => {
      this.moduleService
        .getAllAnalysisTypes()
        .then((analysisTypes) => {
          runInAction(() => {
            this.listAnalysisTypes = analysisTypes?.analyses;
            //set default analysisType
            if (this.listAnalysisTypes && this.listAnalysisTypes[0]) {
              this.currentAnalysisType = this.listAnalysisTypes.find(
                (item) => item.id == this.filterData.analysis_type
              );
              if (!this.currentAnalysisType) {
                this.currentAnalysisType = { ...this.listAnalysisTypes[0] };
                this.filterData.analysis_type = this.currentAnalysisType.id;
              }
              //set detault extra field value
              if (
                this.currentAnalysisType.extra_fields &&
                Array.isArray(this.currentAnalysisType.extra_fields)
              ) {
                this.currentAnalysisType.extra_fields.map((item) => {
                  if (!this.filterData[item.id]) {
                    this.filterData[item.id] = item.default_value;
                  }
                });
              }
            }
            resolve(this.listAnalysisTypes);
          });
        })
        .catch((error) => reject(error));
    });
  };

  /**
   * getAllIntersectionByCurrentAgency
   * get all analysis type
   * @return  {null} -  call the callback function
   */
  @action getAllIntersectionByCurrentAgencyAndType = () => {
    // this.filterData.intersection_id = null;
    this.loadingDate = false;
    this.listIntersections = [];
    this.listAllIntersectionsMarker = [];
    return new Promise((resolve, reject) => {
      this.intersectionService.getAllIntersections(
        {
          agency_id: this.filterData.agency_id,
          analysis_type: this.filterData.analysis_type,
          status: AVAILABLE,
        },
        (intersection) => {
          runInAction(() => {
            this.listIntersections = intersection?.data;

            //set default intersection
            if (
              this.listIntersections &&
              Array.isArray(this.listIntersections)
            ) {
              let defaultIntersection = this.listIntersections.find(
                (item) => item.id === this.filterData.intersection_id
              );
              if (defaultIntersection) {
                this.defaultIntersectionName = defaultIntersection.name;
                this.currentMapCenter = [
                  defaultIntersection.latitude,
                  defaultIntersection.longitude,
                ];
                //show selected calendar
                this.loadingDate = true;
              } else if (!this.filterData.intersection_id) {
                this.handleFilterDataChange(
                  "intersection_id",
                  this.listIntersections[0]?.id
                );
              }
            }
            resolve(this.listIntersections);
          });
        },
        (errors) => reject(errors)
      );
    });
  };

  /**
   * getAllIntersectionsLocation
   * get all intersection for map
   *
   * @return {null}
   */
  @action getAgencySettingByAgencyId = () => {
    this.loading = true;
    return new Promise((resolve, reject) => {
      this.agencyService?.fetchAgencySetting(
        this.filterData.agency_id,
        (response) => {
          this.currentColorSettingMatrix = response?.display;
          resolve(response);
          runInAction(() => {
            this.loading = false;
            if (response?.location && response?.location?.lat) {
              this.currentMapCenter = [
                response?.location.lat,
                response?.location.lon,
              ];
            }
          });
        },
        (errors) => {
          runInAction(() => {
            this.loading = false;
          });
          reject(errors);
        }
      );
    });
  };

  /**
   * getAllAgencies
   * get all agencies
   * @return  {null} -  call the callback function
   */
  @action getAllAgencies = () => {
    this.listAgencies = [];
    return new Promise((resolve, reject) => {
      this.agencyService.getAll(
        (agencies) => {
          runInAction(() => {
            this.listAgencies = agencies;
            //set default agency
            if (this.listAgencies && this.listAgencies.length > 0) {
              let defaultAgency = this.listAgencies.find(
                (item) => item.id === this.filterData.agency_id
              );
              if (!defaultAgency) {
                this.filterData.agency_id = this.listAgencies[0]?.id;
              }
              if (defaultAgency?.location && defaultAgency?.location?.lat) {
                this.currentMapCenter = [
                  defaultAgency?.location.lat,
                  defaultAgency?.location.lon,
                ];
              } else {
                this.currentMapCenter = DEFAULT_MAP_CENTER;
              }
              //get agency setting prepare for agency color
              this.getAgencySettingByAgencyId();
            }
            resolve(agencies);
          });
        },
        (errors) => {
          reject(errors);
        }
      );
    });
  };

  /**
   * generateReport
   * handle generate chart button
   *
   * @return  {null} -  call the callback function
   */
  generateReport = () => {
    runInAction(() => {
      this.loading = true;
      this.gettingChart = true;
      this.parentStore.chartStore.clearChartData();
    });

    //start call api
    if (REPORT_OFFLINE_MODE) {
      switch (this.filterData.analysis_type) {
        case ANALYSIS_TYPE.AOR_TYPE:
          runInAction(() => {
            this.parentStore.chartStore.preTranformChartData(AOR.data);
          });
          break;
        case ANALYSIS_TYPE.PT_TYPE:
          runInAction(() => {
            this.parentStore.chartStore.preTranformChartData(PT.data);
          });
          break;
        case ANALYSIS_TYPE.CD_TYPE:
          runInAction(() => {
            this.parentStore.chartStore.preTranformChartData(CD.data);
          });
        case ANALYSIS_TYPE.PED_TYPE:
          runInAction(() => {
            this.parentStore.chartStore.preTranformChartData(PED.data);
          });
          break;
      }
      setTimeout(() => {
        runInAction(() => {
          this.loading = false;
          this.gettingChart = false;
          this.handleFilterDataChanged(false);
        });
      }, 2000);
    } else {
      let requestGenerate = [
        this.moduleService.getChartData(
          this.filterData.analysis_type,
          this.filterData.intersection_id,
          this.filterData.agency_id,
          this.transferAnalysisFilterBeforeSend()
        ),
      ];
      if (this.filterData.is_compare) {
        requestGenerate.push(
          this.moduleService.getChartData(
            this.filterData.analysis_type,
            this.filterData.intersection_id,
            this.filterData.agency_id,
            this.transferAnalysisFilterBeforeSend(true)
          )
        );
      }
      Promise.all(requestGenerate)
        .then((response) => {
          runInAction(() => {
            this.parentStore.chartStore.preTranformChartData(
              response[0]?.data,
              response[1]?.data
            );
          });
        })
        .catch((error) => {
          this.parentStore.chartStore.preTranformChartData({}, {});
          console.log(error);
          if (error && Array.isArray(error.errors)) {
            let message = "";
            error.errors.map((item) => {
              message += item.message + "\n";
            });
            if (message != "") {
              helper.showNotification("error", error.key, message);
            }
          }
        })
        .finally(() => {
          runInAction(() => {
            this.loading = false;
            this.gettingChart = false;
            this.handleFilterDataChanged(false);
          });
        });
    }
  };

  /**
   * transferAnalysisFilterBeforeSend
   *
   * @param {boolean} compareMode: compareMode
   * @return  {Object} -  new filter data format
   */
  transferAnalysisFilterBeforeSend = (compareMode = false) => {
    let payload = {};
    let selectedDate = this.filterData.date.clone();
    if (compareMode) {
      selectedDate = this.filterData.compare_date.clone();
    }
    payload.from_time = selectedDate
      .set({
        hour: this.filterData.from_time.hours(),
        minute: this.filterData.from_time.minutes(),
        second: "00",
      })
      .format(DATE_FORMAT.backend);
    payload.to_time = selectedDate
      .set({
        hour: this.filterData.to_time.hours(),
        minute: this.filterData.to_time.minutes(),
        second: "00",
      })
      .format(DATE_FORMAT.backend);
    //the extra filter field
    if (this.currentAnalysisType) {
      this.currentAnalysisType?.extra_fields?.map(
        (item) => (payload[item.id] = this.filterData[item.id])
      );
    }
    return payload;
  };

  /**
   * setFilterData
   * setting filer data for auto selected data
   * @param {Object} data: new filter data
   * @return  {null} -  call the callback function
   */
  @action setFilterData = (data) => {
    this.filterData = { ...this.filterData, ...data };
    this.handleFilterDataChanged(true);
  };

  /**
   * handleFilterDataChanged
   * enable again the button create analysis charts
   *
   * @return  {null} -  call the callback function
   */
  @action handleFilterDataChanged = (isChanged) => {
    this.filterDataChanged = isChanged;
  };
}

export default AnalysisStore;
