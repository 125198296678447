import { action } from "mobx";
import BaseStore from "./BaseStore";
import AlarmAvailableSubscriberService from "../services/alarm/detail/AlarmAvailableSubscriberService";
import { RESOURCE_LIST } from "utils/constants";

class AlarmAvailableUserStore extends BaseStore {
  constructor(parentStore) {
    super(new AlarmAvailableSubscriberService(parentStore), parentStore);
    this.resourceName = RESOURCE_LIST.ALARM;
  }
  //list of full object selected items in table
  selectedFullItems = [];

  /**
   * onSelectRowChange
   * this function will trigger when user select or unselect item row on table
   * @param   {Array} selectedItems  List of selected ITem
   * @return  {null} -  setState of current selectedItems
   */
  @action onSelectRowChange = (selectedItems) => {
    this.selectedItems = selectedItems;
    let newSubs = this.listData?.filter((e) => {
      return selectedItems?.includes(e?.id);
    });
    this.selectedFullItems = this.selectedFullItems.concat(newSubs);
  };

  /**
   * get full object of selected items
   * @return  {null}   return selected item list
   */
  getSelectedItems = () => {
    return this.selectedFullItems;
  };

  @action setSelectedItem = (ids, cb) => {
    this.selectedItems = ids;
    this.selectedFullItems = this.selectedFullItems.filter((item) => {
      return !ids.includes(item.id);
    });
    cb && cb();
  };
}

export default AlarmAvailableUserStore;
