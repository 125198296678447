import { notification } from "antd";
import Axios from "axios";
import {
  DATE_FORMAT,
  NOTIFICATION_ACTION_TYPE,
  NOTIFICATION_REDIREC_ACTION,
  NOTIFICATION_SIDE_PANEL_ACTION,
} from "utils/constants";
import i18n from "i18n";
import moment from "moment";

export * from "./analysisHelper";
export const getFirstsLetterFromWords = (words) => {
  let matches = words?.match(/\b(\w)/g);
  return matches?.join("");
};

export const getRandomColor = () => {
  var letters = "0123456789ABCDEF";
  var color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};
export const toFixed2Offset = (number) => {
  if (!number || isNaN(number)) {
    return NaN;
  }
  return Number.isInteger(number) ? number : number.toFixed(2);
}
export const fileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const getQueryString = (url, field) => {
  if (!url) url = window.location.href;
  field = field.replace(/[\[\]]/g, "\\$&");
  const regex = new RegExp("[?&]" + field + "(=([^&#]*)|&|#|$)");
  let results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  // return decodeURIComponent(results[2]?.replace(/\+/g, ' '));
  return results[2];
};

export const loadTheme = (currentTheme) => {
  Object.keys(currentTheme).forEach((key) => {
    const cssKey = `--${key}`;
    const cssValue = currentTheme[key];
    document.body.style.setProperty(cssKey, cssValue);
  });
};

export const deepCopyObject = (object) => {
  return JSON.parse(JSON.stringify(object));
};

export const removeObjectFields = (obj, removeKeyArray, childKey) => {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (removeKeyArray.includes(key)) {
        delete obj[key];
      }
      if (key === childKey) {
        obj[key].map((e) => {
          removeObjectFields(e, removeKeyArray, childKey);
        });
      }
    }
  }
  return obj;
};

export const showNotification = (type, message, description, callback) => {
  notification[type]({
    message: i18n.t(message),
    description: description,
    onClick: () => {
      callback && callback();
    },
    duration: 5,
    bottom: 50,
    placement: "bottomRight",
  });
};

export const totalValue = (array, field) => {
  let result = 0;
  array.map((e) => {
    result += e[field];
  });
  return result;
};

export const convertDate = (time, timeZone) => {
  return moment(new Date(time.toLocaleString("en-US", { timeZone: timeZone })))
    .utcOffset(0, true)
    .format();
};

/**
 * return the distance per pixel in kilometers
 * @param {double} totalPixel the pixel wanna calculate
 * @param {double} latitude the latitude point
 * @param {double} zoom the zoom level
 * @return  {double}
 */
export const caculateDistanceOnMap = (totalPixel, latitude, zoom) => {
  return (
    (((156543.03392 * Math.cos((latitude * Math.PI) / 180)) /
      Math.pow(2, zoom)) *
      totalPixel) /
    1000
  );
};

export const isDuplicateExists = (w) => {
  return new Set(w).size !== w.length;
};

export const checkExpiredLink = (link, currentStore, t) => {
  return new Promise((resolve, reject) => {
    {
      Axios.get(link, { responseType: "stream" })
        .then(() => {
          currentStore.setLoading(false);
          resolve(link);
        })
        .catch((res) => {
          currentStore.setLoading(false);
          res.errors = [{ message: t("helper.expired_error") }];
          reject(res);
        });
    }
  });
};

export const handleLinkAction = (
  actionObject = null,
  history,
  rootStore = null,
  currentStore,
  t
) => {
  if (actionObject) {
    switch (actionObject.type) {
      case NOTIFICATION_ACTION_TYPE.REDIRECT:
        {
          const searchParams = new URLSearchParams(actionObject.data);
          let url = NOTIFICATION_REDIREC_ACTION[actionObject.target];
          //redirect page
          if (url) {
            if (searchParams && searchParams.toString().length > 0) {
              history.push(url + "?" + searchParams.toString());
            } else {
              history.push(url);
            }
          }
        }
        break;
      case NOTIFICATION_ACTION_TYPE.REDIRECT_BY_PATH:
        {
          const searchParams = new URLSearchParams(actionObject.data.url_param);
          let url = NOTIFICATION_REDIREC_ACTION[actionObject.target];
          if (searchParams && searchParams.toString().length > 0) {
            history.push(
              url + "/" + actionObject.data?.id + "?" + searchParams.toString()
            );
          } else history.push(url + "/" + actionObject.data?.id);
        }
        break;
      case NOTIFICATION_ACTION_TYPE.OPEN_SIDE_PANEL:
        {
          if (rootStore) {
            let targetAction =
              NOTIFICATION_SIDE_PANEL_ACTION[actionObject.target].split(".");
            //this is not hard code the format of targetAction allway 2 item(store and the function name)
            rootStore[targetAction[0]][targetAction[1]].apply(rootStore, [
              actionObject.data,
            ]);
          }
        }
        break;
      case NOTIFICATION_ACTION_TYPE.OPEN_LINK:
        {
          currentStore.setLoading(true);
          currentStore.clearErrorList();
          checkExpiredLink(actionObject?.data, currentStore, t)
            .then(() => (window.location.href = actionObject?.data))
            .catch((err) => currentStore?.setErrorList(err));
        }
        break;
      case NOTIFICATION_ACTION_TYPE.OPEN_POPUP:
        {
          rootStore?.unreadNotificationStore?.setUnReadReleaseNote([
            actionObject,
          ]);
        }
        break;
      // case y:
      //   // code block
      //   break;
      // default:
      //   // code block
    }
  }
};

/**
 * getTranslateText
 * return getTranslateText text with current language
 * @param {Object} text the text object
 * @param {String} currentLanguage
 * @return  {String}
 */
export const getTranslateText = (
  text = "",
  currentLanguage = process.env.REACT_APP_DEFAULT_LANG
) => {
  let translatedText = "";
  if (typeof text === "string") {
    translatedText = text;
  } else {
    translatedText = text[currentLanguage];
  }
  return translatedText;
};
/**
 * getDisplayTextColor
 * return text base on the color and unit
 * @param {Object} color color Object
 * @param {String} unit
 * @param {Object} t translate onkect
 * @return  {String}
 */
export const getDisplayTextColor = (color, unit, t, maxValue = null) => {
  unit = unit !== null ? unit : "";
  // if (color.from_value === 0) {
  //   return t("heatmap.default");
  // }
  if (maxValue) {
    if (color.from_value === maxValue) {
      return t("heatmap.from") + color.from_value + unit;
    }
  }
  return color.from_value + unit;
};

/**
 * Compute percent of a in sum of a and b
 * @param a
 * @param b
 * @returns {number}
 */
export const getRatioPercent = (a = 0, b = 0) => {
  if (a + b === 0) return 0;
  return (a * 100) / (a + b);
};

export const truncateTimeSecond = (time) => {
  return time.replace(/(\d{2}):(\d{2}):(\d{2})/, "$1:$2");
};

export const getExcludeDatesInRange = (dates) => {
  if (!dates?.length) return {};

  const exclude_dates = [];
  const stopDate = moment(dates[dates.length - 1]);
  let currentDate = moment(dates[0]);
  let date;

  while (currentDate <= stopDate) {
    date = moment(currentDate).format(DATE_FORMAT.day_only_backend);
    if (!dates.find((el) => el == date)) {
      exclude_dates.push(date);
    }
    currentDate = moment(currentDate).add(1, "days");
  }

  return {
    start_date: dates[0],
    end_date: dates[dates.length - 1],
    exclude_dates: exclude_dates,
  };
};

export const getOnlyDateRangeString = (item) => {
  return (
    moment(item?.start_date, DATE_FORMAT.day_only_backend).format(
      DATE_FORMAT.day_only
    ) +
    " - " +
    moment(item?.end_date, DATE_FORMAT.day_only_backend).format(
      DATE_FORMAT.day_only
    )
  );
};

export const getShortDateRangeString = (item, translator) => {
  return (
    getOnlyDateRangeString(item) +
    (item.exclude_dates?.length
      ? " (" +
        translator(
          item.exclude_dates?.length > 1
            ? "report_result.short_exclude_day_other"
            : "report_result.short_exclude_day_one",
          {
            day: item.exclude_dates?.length,
          }
        ) +
        ")"
      : "")
  );
};

export const getFullDateRangeString = (item, translator) => {
  return (
    getOnlyDateRangeString(item) +
    "\n" +
    (item.exclude_dates?.length
      ? translator(
          item.exclude_dates?.length > 1
            ? "report_result.exclude_day_other"
            : "report_result.exclude_day_one",
          {
            day: item.exclude_dates?.length,
          }
        ) +
        "\n" +
        item.exclude_dates
          .map((el) =>
            moment(el, DATE_FORMAT.day_only_backend).format(
              DATE_FORMAT.day_only
            )
          )
          .join("\n")
      : "")
  );
};

/**
 * Get Moment time range
 * @param period
 * @param unit
 * @return {[moment.Moment,moment.Moment]}
 */
export const getLastPeriodTime = (period, unit = "hour") => {
  const current = moment();
  const toTime = current.unix();
  const fromTime = current.subtract(period, unit);
  return [fromTime, moment.unix(toTime)];
};

export default {
  getFirstsLetterFromWords,
  getRandomColor,
  fileToBase64,
  getQueryString,
  loadTheme,
  deepCopyObject,
  removeObjectFields,
  showNotification,
  caculateDistanceOnMap,
  totalValue,
  isDuplicateExists,
  handleLinkAction,
  getTranslateText,
  getDisplayTextColor,
  getRatioPercent,
  truncateTimeSecond,
};
