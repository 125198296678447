import ChartDetail from "components/ChartDetail";
class ArrivalChart extends ChartDetail {
  constructor(props) {
    super(props);
    this.value_field = "to_time";
    this.data_field = "aor_bins";

    this.CHART_AXIS_SETTING = [
      {
        position: "left",
        key: "volume",
        label: "VPH",
        data: this.props.data.aor_bins,
      },
      {
        position: "right",
        key: "aor_percent",
        label: "Percentage",
        data: this.props.data.aor_bins,
        tick: true,
      },
    ];
  }

  componentDidMount = () => {
    super.componentDidMount();
  };
  /**
   * render
   *
   * @return  {Component}
   */
  render() {
    return super.render();
  }
}

export default ArrivalChart;
