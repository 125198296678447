import { action, observable, runInAction } from "mobx";
import { RESOURCE_LIST } from "utils/constants";
import { v4 as uuid } from "uuid";
import AgencyService from "../../services/AgencyService";
import IntersectionConfigService from "../../services/IntersectionConfig/IntersectionConfigService";
import BaseStore from "../BaseStore";

/**
 * Store for intersections
 *
 * @class
 */
class IntersectionConfigStore extends BaseStore {
  constructor(parentStore) {
    super(new IntersectionConfigService(parentStore), parentStore);
    this.agencyService = new AgencyService(parentStore); //agency service to get agency data
    //filter data for list intersection
    this.filterData = {
      ...this.filterData,
    };
    this.resourceName = RESOURCE_LIST.INTERSECTION;
    //for loading loop intersections
    this.continuousLoadingThreadId = null;
  }

  //data for filtering
  //data for map
  currentQueryIntersectionAgency = {};
  from_time_utc = null;
  latest = null;
  @observable intersectionInfo = null;
  @observable currentConfig = null;
  @observable spinning = false;
  @observable dirtyTopologyForm = false;
  @observable metaData = {
    directions: [],
    movements: [],
    phases: [],
  };
  @observable topologyConfig = {
    Id: null,
    conflict: null,
    Approaches: [],
  };
  @observable idError = [];
  //params when component did mount will be used to load lasted config
  @action setCurrentIntersectionAgencyQuery = (value) => {
    this.filterData = {
      ...this.filterData,
      ...value,
    };
    this.currentQueryIntersectionAgency = value;
  };
  @action setIdError = (value) => {
    this.idError = value;
  };
  //tranformTopology to client Side
  tranformDataToClientSide = (dataTopology) => {
    return dataTopology?.map((direction) => {
      return {
        ...direction,
        id: uuid(),
        Phases: direction.Phases?.map((phase) => {
          return {
            ...phase,
            id: uuid(),
            Lanes: phase.Lanes?.map((lane) => {
              return {
                ...lane,
                id: uuid(),

                Detectors: lane.Detectors?.map((det) => {
                  return {
                    ...det,

                    id: uuid(),
                  };
                }),
              };
            }),
          };
        }),
      };
    });
  };

  @action getTopologyConfig = (cb, fb) => {
    this.moduleService.getTopologyConfig(
      {
        ...this.currentQueryIntersectionAgency,
        from_time_utc: this.from_time_utc,
        config_id: this.currentConfig?.config_id,
      },
      (data) => {
        runInAction(() => {
          this.topologyConfig = {
            ...data,
            Approaches: this.tranformDataToClientSide(data?.Approaches),
          };
          this.topologyId = data?.Id;
          this.dirtyTopologyForm = false;
        });
        cb && cb();
      },
      () => {
        runInAction(() => {
          this.topologyConfig = {
            Id: null,
            Approaches: [],
            conflict: null,
          };
          this.dirtyTopologyForm = false;
          this.topologyId = null;
        });
        fb && fb();
      }
    );
  };

  // save topology will be have 2 case: update existing (if have id) or create
  @action saveTopologyConfig = (cb, fb) => {
    let body = {
      AgencyUUID: this.currentQueryIntersectionAgency.agency_id,
      IntUUID: this.currentQueryIntersectionAgency.int_uuid,
      ConfigId: this.currentConfig?.config_id,
      FromTimeUtc: this.from_time_utc,
      Id: this.topologyConfig.Id,
      Approaches: this.topologyConfig.Approaches,
    };

    this.moduleService.saveTopologyConfig(
      body,
      () => {
        runInAction(() => {
          this.dirtyTopologyForm = false;
        });
        this.getTopologyConfig(cb, fb);
      },
      (err) => {
        fb && fb(err);
      }
    );
  };
  // update data from form component
  // It 's be active,so I think don't touch them
  @action setFieldTopology = (target, value) => {
    this.dirtyTopologyForm = true;
    const trySetField = (target, value, currentNode) => {
      if (target.length === 1) {
        currentNode[target[0]] = value;
      } else trySetField(target.slice(1), value, currentNode[target[0]]);
    };
    trySetField(target, value, this.topologyConfig.Approaches);
  };
  // update data from form component
  // It 's be active,so I think don't touch them
  @action pushFieldTopology = (target, value) => {
    this.dirtyTopologyForm = true;
    let currentState = [...this.topologyConfig.Approaches];
    let pointer = currentState;
    target.forEach((key) => {
      pointer = pointer[key];
    });
    pointer?.push(value);
    this.topologyConfig.Approaches = currentState;
  };
  // update data from form component
  // It 's be active,so I think don't touch them
  @action removeFieldTopology = (target, index) => {
    this.dirtyTopologyForm = true;
    const tryRemove = (target, index, currentNode) => {
      if (target.length === 1) {
        currentNode[target[0]] = currentNode[target[0]].filter(
          (e, id) => id !== index
        );
      } else tryRemove(target.slice(1), index, currentNode[target[0]]);
    };
    if (!target.length) {
      this.topologyConfig.Approaches = this.topologyConfig.Approaches.filter(
        (e, id) => id !== index
      );
    } else {
      let currentState = [...this.topologyConfig.Approaches];
      tryRemove(target, index, currentState);
      this.topologyConfig.Approaches = currentState;
    }
  };
  //get lasted config when component did mount
  @action getLastedConfig = () => {
    this.spinning = true;
    this.currentConfig = null;
    this.intersectionInfo = null;
    this.moduleService.getLastedConfig(
      this.currentQueryIntersectionAgency,
      (data) => {
        runInAction(() => {
          this.currentConfig = data?.configuration;
          this.intersectionInfo = data?.intersection;
          this.from_time_utc = data?.from_time_utc;
          this.latest = data?.latest;
          // get topology with this config
          if (data)
            this.getTopologyConfig(
              () => {
                runInAction(() => {
                  this.spinning = false;
                });
              },
              () => {
                runInAction(() => {
                  this.spinning = false;
                });
              }
            );
        });
      },
      () => {
        runInAction(() => {
          this.spinning = false;
        });
      }
    );
  };

  // get metadata for topology
  @action getMetaData = () => {
    this.spinning = true;
    this.moduleService.getMetaData(
      (data) => {
        runInAction(() => {
          this.metaData = data?.topologies;
          this.spinning = false;
        });
      },
      () => {
        runInAction(() => {
          this.spinning = false;
        });
      }
    );
  };
  // change config
  @action changeCurrentConfig = (item) => {
    this.spinning = true;
    this.moduleService.changeCurrentConfig(
      item,
      (data) => {
        runInAction(() => {
          this.currentConfig = data?.configuration;
          this.from_time_utc = data?.from_time_utc;
          this.latest = data?.latest;
          if (data)
            this.getTopologyConfig(
              () => {
                runInAction(() => {
                  this.spinning = false;
                });
              },
              () => {
                runInAction(() => {
                  this.spinning = false;
                });
              }
            );
        });
      },
      () => {
        runInAction(() => {
          this.spinning = false;
        });
      }
    );
  };
  @action setTopologyConfig = (data) => {
    this.topologyConfig = {
      ...data,
      Id: this.topologyConfig.Id,
      Approaches: this.tranformDataToClientSide(data?.Approaches),
    };
  };
  /**
   * getData
   * this function get all data from server with filter value
   * setpage on loading beforload and clean it after loading done
   * after get the data done: update the total item for paging compoment
   *
   * @return  {null} -
   */
  @action getData = (cb) => {
    this.loading = true;
    this.moduleService?.fetchDataWithParams(
      this.filterData,
      (items, totalCount) => {
        runInAction(() => {
          this.loading = false;

          this.listData = items.map((e, id) => {
            return {
              ...e,
              _id: id,
            };
          });
          this.filterData.pagination.total = totalCount;
          // this.filterData.pagination.total = 100;
        });
        cb && cb();
      },
      () => {
        runInAction(() => {
          this.listData = [];
          this.loading = false;
        });
      }
    );
  };
}

export default IntersectionConfigStore;
