import { action, computed, observable, runInAction, toJS } from "mobx";
import moment from "moment";
import CorridorService from "services/CorridorService";
import {
  AVAILABLE,
  DATE_FORMAT,
  DEFAULT_MAP_CENTER,
  DEFAULT_ZOOM_LEVEL,
  NOTIFICATION_TYPE,
} from "utils/constants";
import i18n from "i18n";
import AgencyService from "services/AgencyService";
import IntersectionService from "services/IntersectionService";
import OptimizeService from "services/OptimizeService";
import helper, { getExcludeDatesInRange, getRatioPercent } from "utils/helper";
import BaseStore from "stores/BaseStore";
import HistoricTimeSpaceStore from "./HistoricTimeSpaceStore";
import { OPTIMIZATION_TYPE } from "utils/constants/optimizerConstant";
import CorridorOffsetOptimizerStore from "./CorridorOffsetOptimizerStore";
const DEFAULT_FORM_DATA = {
  agency_id: null,
  type: null,
  intersection_id: null,
  plan_number: 1,
  phase: null,
  start_time: "00:00",
  end_time: "23:59",
  min_profile_duration: 900,
  start_date: moment().format(DATE_FORMAT.day_only_backend),
  end_date: moment().format(DATE_FORMAT.day_only_backend),
  show_programmed: false,
  corridor_id: undefined,
  dates: [],
  week_days: [
    "MONDAY",
    "TUESDAY",
    "WEDNESDAY",
    "THURSDAY",
    "FRIDAY",
    "SATURDAY",
    "SUNDAY",
  ],
  bias: 0,
  bias_direction: null,
  cycle_length: 0,
  bin_size: 900,
  num_of_profiles: 5,
  cluster_type: "DAY_OF_WEEK",
};
// Store for analysis
class OptimizerStore extends BaseStore {
  constructor(parentStore) {
    super(new OptimizeService(parentStore), parentStore);
    this.historicTimespaceStore = new HistoricTimeSpaceStore(parentStore);
    this.corridorOffsetStore = new CorridorOffsetOptimizerStore(parentStore);
    //init relation services
    this.agencyService = new AgencyService(parentStore); //agency service
    this.intersectionService = new IntersectionService(parentStore); //intersection service
    this.corridorService = new CorridorService(parentStore); //intersection service
    //default name of intersection
    this.defaultIntersectionName = null;
    //init default filter value
    this.filterData = {
      agency_id: null,
      ...this.filterData,
      type: null,
      status: null,
    };
  }
  @observable formData = DEFAULT_FORM_DATA;
  @observable selectedItem = null;
  @observable allPhaseNumber = [];
  @observable allClusterTypes = [];
  @observable allBinSizes = [];
  @observable listOptimizationTypes = [];
  @observable listAgencies = [];
  @observable listIntersections = [];
  @observable filterDataChanged = false;

  //default focus on map
  @observable currentMapCenter = DEFAULT_MAP_CENTER;
  //default zoom level on map
  @observable currentMapZoom = DEFAULT_ZOOM_LEVEL;
  /**
   * updateCurrentZoomLevel
   * update zoom level
   *
   * @param {Interger} zoomLevel: the zoom level
   * @return  {null}
   */
  @action updateCurrentZoomLevel = (zoomLevel) => {
    if (0 <= zoomLevel <= 18) {
      //limite the zoom level for seeing data
      this.currentMapZoom = zoomLevel;
    }
  };
  /**
   * resetFilter
   * reset all filter data and get lastest data for filter
   *
   * @return  {null}
   */
  @action resetFilter = () => {
    this.formData = { ...DEFAULT_FORM_DATA };
    this.listIntersections = [];
    this.filterDataChanged = true;
    //default focus on map
    this.currentMapCenter = DEFAULT_MAP_CENTER;
    //default zoom level on map
    this.currentMapZoom = DEFAULT_ZOOM_LEVEL;
    //get all data again to get lastest data
    this.getAllFilterData();
  };
  @action calculateCenterPoint = (agency) => {
    const topLeft = agency.top_left;
    const bottomRight = agency.bottom_right;
    let lat = (topLeft?.lat + bottomRight?.lat) / 2;
    let lon = (topLeft?.lon + bottomRight?.lon) / 2;
    let currentAcreage = Math.abs(
      (topLeft?.lat - bottomRight?.lat) * (topLeft?.lon - bottomRight?.lon)
    );

    let baseZoomLevel = (180 * 360) / currentAcreage;
    //Acreage will be decrease 4 ^ n times with n is zoom-level
    let zoomLevel = Math.floor(Math.log(baseZoomLevel) / Math.log(4));
    zoomLevel =
      typeof zoomLevel === "number" && isFinite(zoomLevel)
        ? zoomLevel
        : DEFAULT_ZOOM_LEVEL;
    if (lat && lon) {
      this.currentMapCenter = [lat, lon];
      this.currentMapZoom = zoomLevel;
    } else {
      this.currentMapCenter = DEFAULT_MAP_CENTER;
      this.currentMapZoom = DEFAULT_ZOOM_LEVEL;
    }
  };
  @action setFormField = (field, value) => {
    this.filterDataChanged = true;
    const fieldTree = field.split(".");
    switch (fieldTree.length) {
      case 1:
        {
          this.formData[field] = value;
        }
        break;
      case 2:
        {
          this.formData[fieldTree[0]][fieldTree[1]] = value;
        }
        break;
      case 3:
        {
          this.formData[fieldTree[0]][fieldTree[1]][fieldTree[2]] = value;
        }
        break;
    }
    if (field === "agency_id") {
      let agency = this.listAgencies.find((e) => e.id === value);
      if (agency) this.calculateCenterPoint(agency);
      this.formData.intersection_id = null;
      this.getAllIntersectionByCurrentAgencyAndType();
    }
    if (field === "intersection_id") {
      const currentInt = this.listIntersections.find((e) => e.id === value);
      if (currentInt)
        this.currentMapCenter = [currentInt.latitude, currentInt.longitude];
    }
    //get Agency Setting prepare for binding color on calendar
  };
  @action getAgencySettingByAgencyId = () => {
    this.loading = true;
    return new Promise((resolve, reject) => {
      this.agencyService?.fetchAgencySetting(
        this.filterData.agency_id,
        (response) => {
          this.calculateCenterPoint(response);
        },
        (errors) => {
          runInAction(() => {
            this.loading = false;
          });
          reject(errors);
        }
      );
    });
  };
  /**
   * getAllFilterData
   * get all filter data
   *
   * @return  {null}
   */
  @action getAllFilterData = () => {
    const promises = Promise.all([
      this.getAllMetaData(),
      this.getAllAgencies(),
    ]);
    promises.catch(() => {});
  };
  getAllMetaData = () => {
    this.listOptimizationTypes = [];
    this.allPhaseNumber = [];
    this.allBinSizes = [];
    this.allClusterTypes = [];
    return new Promise((resolve, reject) => {
      this.moduleService
        .getAllMetaData()
        .then((response) => {
          runInAction(() => {
            this.listOptimizationTypes = response?.types;
            this.allPhaseNumber = response?.phase_numbers;
            this.allBinSizes = response?.bin_sizes;
            this.allClusterTypes = response?.cluster_types;
            this.setFormField("type", response.types[0].key);
            this.setFormField("phase", response.phase_numbers[0].phase_number);
          });
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          runInAction(() => {
            this.loading = false;
          });
        });
    });
  };

  /**
   * getAllIntersectionByCurrentAgency
   * get all analysis type
   * @return  {null} -  call the callback function
   */
  @action getAllIntersectionByCurrentAgencyAndType = () => {
    // this.filterData.intersection_id = null;
    this.loadingDate = false;
    this.listIntersections = [];
    return new Promise((resolve, reject) => {
      this.intersectionService.getAllIntersections(
        {
          agency_id: this.formData.agency_id,
          status: AVAILABLE,
        },
        (intersection) => {
          runInAction(() => {
            this.listIntersections = intersection?.data;
            if (!this.formData.intersection_id) {
              this.formData.intersection_id = this.listIntersections[0]?.id;
            }
            resolve(this.listIntersections);
          });
        },
        (errors) => reject(errors)
      );
    });
  };
  @action beginOptimize = () => {
    this.loading = true;
    console.log(this.formData);
    this.moduleService
      .beginOptimize({
        ...this.formData,
        // start_date, end_date, and exclude_dates
        ...getExcludeDatesInRange(this.formData.dates),
      })
      .then(() => {
        this.getData();
      })
      .catch((error) => {
        error?.map((e) =>
          helper.showNotification(
            NOTIFICATION_TYPE.ERROR,
            i18n.t("error"),
            e.message
          )
        );
      })
      .finally(() => {
        runInAction(() => {
          this.loading = false;
        });
      });
  };
  @action setSelectedItem = (value) => {
    switch (value?.general_data.optimization_type.key) {
      case OPTIMIZATION_TYPE.HISTORIC_TIME_SPACE:
        this.historicTimespaceStore.setSelectedItem(value);
        break;
      case OPTIMIZATION_TYPE.CORRIDOR_OFFSET_OPTIMIZATION:
        this.corridorOffsetStore.setSelectedItem(value);
        break;
      default:
        this.selectedItem = value;
        break;
    }
    this.selectedItem = value;
  };
  @action delete = (id = null) => {
    this.loading = true;
    if (!id) {
      id = this.selectedItems;
    }
    this.moduleService?.delete(
      { ids: id },
      () => {
        //redirect into page 1 avoid load wrong data
        this.filterData.pagination.current = 1;
        this.handleFilerAction();
      },
      () => {
        runInAction(() => {
          this.loading = false;
        });
      }
    );
  };
  @action getOptimizeDetail = (id, filterObject) => {
    this.loading = true;
    this.selectedItem = null;
    // this.setSelectedItem(timespace_mock.data);
    // runInAction(() => (this.loading = false));
    this.moduleService
      .getOneItem(id, filterObject)
      .then((data) => {
        this.setSelectedItem(data);
      })
      .catch(() => {})

      .finally(() => {
        runInAction(() => (this.loading = false));
      });
  };
  /**
   * getAllAgencies
   * get all agencies
   * @return  {null} -  call the callback function
   */
  @action getAllAgencies = () => {
    this.listAgencies = [];
    return new Promise((resolve, reject) => {
      if (this.parentStore.commonStore.isSuperAdmin()) {
        this.agencyService.getAll(
          (agencies) => {
            runInAction(() => {
              this.listAgencies = agencies;
              if (this.listAgencies && this.listAgencies.length > 0) {
                this.handleFilterDataChange("agency_id", null);
                this.setFormField("agency_id", this.listAgencies[0].id);
              }
              resolve(agencies);
            });
          },
          (errors) => {
            reject(errors);
          }
        );
      } else {
        if (this.parentStore?.myStore?.currentAgency?.agency_id) {
          this.handleFilterDataChange(
            "agency_id",
            this.parentStore?.myStore?.currentAgency?.agency_id
          );
          this.setFormField(
            "agency_id",
            this.parentStore?.myStore?.currentAgency?.agency_id
          );
          this.getAgencySettingByAgencyId();
        }
        resolve(true);
      }
    });
  };

  /**
   * Convert selectedItem object to array
   * into show in Data Table
   */
  @computed get dataTable() {
    return this.selectedItem.charts.map((el) => {
      const cluster_info = [];
      for (const key in toJS(el.cluster_info)) {
        const time_assignment = el.cluster_blocks
          .filter((block) => block.cluster == key)
          .map((item) => ({
            from_time: item.from_time,
            to_time: item.to_time,
          }));
        const group1_ratio_min = getRatioPercent(
          el.cluster_info[key].group1_min,
          el.cluster_info[key].group2_min
        );
        const group1_ratio_max = getRatioPercent(
          el.cluster_info[key].group1_max,
          el.cluster_info[key].group2_max
        );
        const group1_ratio_avg = getRatioPercent(
          el.cluster_info[key].group1_avg,
          el.cluster_info[key].group2_avg
        );
        cluster_info.push({
          profile: parseInt(key) + 1,
          ...toJS(el.cluster_info[key]),
          group1_ratio_min,
          group1_ratio_max,
          group1_ratio_avg,
          time_assignment,
        });
      }
      return {
        timeline: el.timeline,
        cluster_info,
      };
    });
  }
}

export default OptimizerStore;
