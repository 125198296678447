import { Col, Input, Select, Tooltip } from "antd";
import StatusTag from "components/StatusTag";
import { inject, observer } from "mobx-react";
import React from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";

import BaseManagementComponents from "components/BaseManagementComponents";
import {
  ACTIONS_LIST,
  ACTIVE,
  DEFAULT_STATUS_LIST,
  RESOURCE_LIST,
} from "utils/constants";
import AgencyDetailForm from "./AgencyDetail";
const { Option } = Select;

/**
 * Component for showing lists of the agencies.
 *
 * @component
 */
@inject("rootStore")
@observer
class Agencies extends BaseManagementComponents {
  /**
   * contructor of component
   * @param   {object} props  props of component
   */
  constructor(props) {
    super(props.rootStore.agencyStore, props);
    this.state = {
      ...this.state,
      formEditable: true,
      filterData: {
        text: "",
        status: "null",
      },
      currentAction: props.t("agency.select_action"),
    };
    this.resourceName = RESOURCE_LIST.AGENCY;
    this.moduleName = "agencies";
    const { t } = props;
    this.pageTitle = t("agency_management");
    this.rowKey = "id";
    this.columns = [
      {
        title: t("agency.id"),
        dataIndex: "id",
        key: "id",
        width: "25%",
        render: (value, row) =>
          this.renderKeyColumn(value, row, RESOURCE_LIST.AGENCY_DETAIL),
        ellipsis: true,
      },
      {
        title: t("agency.name"),
        dataIndex: "name",
        key: "name",
        sorter: true,
        sortDirections: ["descend", "ascend"],
        ellipsis: true,
      },
      {
        title: t("agency.status"),
        dataIndex: "status",
        key: "status",
        render: (status) => <StatusTag status={status} />,
        // sorter: true,
        // sortDirections: ["descend", "ascend"],
        ellipsis: true,
      },
      {
        title: t("agency.address"),
        dataIndex: "address",
        key: "address",
        sorter: true,
        sortDirections: ["descend", "ascend"],
        ellipsis: true,
      },
    ];

    this.buttonActions = [
      {
        key: "active_all",
        title: t("agency.active_all"),
        action: this.currentStore?.activeAgencies,
        resource: RESOURCE_LIST.AGENCY_ACTIVE,
        confirm: true,
        type: "check",
        permission: ACTIONS_LIST.EDIT,
        confirmTitle: t("agency.active_agency_confirm_title"),
        confirmContent: t("agency.active_agency_confirm_content"),
      },
      {
        key: "deactive_all",
        title: t("agency.deactive_all"),
        action: this.currentStore?.deActiveAgencies,
        resource: RESOURCE_LIST.AGENCY_INACTIVE,
        confirm: true,
        type: "stop",
        permission: ACTIONS_LIST.EDIT,
        confirmTitle: t("agency.inactive_agency_confirm_title"),
        confirmContent: t("agency.inactive_agency_confirm_content"),
      },
      {
        key: "delete",
        title: t("agency.delete"),
        resource: RESOURCE_LIST.AGENCY_DETAIL,
        action: this.currentStore?.deleteAgency,
        permission: ACTIONS_LIST.REMOVE,
        confirm: true,
        type: "delete",
        confirmTitle: t("agency.delete_agency_confirm_title"),
        confirmContent: t("agency.delete_agency_confirm_content"),
      },
    ];

    this.tableInlineActions = [
      {
        key: "edit",
        tooltip: t("agency.edit"),
        resource: RESOURCE_LIST.AGENCY_DETAIL,
        permission: ACTIONS_LIST.EDIT,
        action: this.handleRedirectEdit,
        icon: "edit",
        passingData: (item) => {
          return [item?.id];
        },
      },
      {
        key: "deactive_all",
        tooltip: t("agency.deactive"),
        action: this.handleTableAction,
        resource: RESOURCE_LIST.AGENCY_INACTIVE,
        permission: ACTIONS_LIST.EDIT,
        icon: "stop",
        passingData: (item) => {
          return ["deactive_all", [item?.id]];
        },
        visible: (item) => {
          return item?.status === ACTIVE;
        },
      },
      {
        key: "active_all",
        tooltip: t("agency.active"),
        action: this.handleTableAction,
        resource: RESOURCE_LIST.AGENCY_ACTIVE,
        permission: ACTIONS_LIST.EDIT,
        icon: "check",
        passingData: (item) => {
          return ["active_all", [item?.id]];
        },
        visible: (item) => {
          return item?.status != ACTIVE;
        },
      },
      {
        key: "delete",
        tooltip: t("agency.delete"),
        action: this.handleTableAction,
        resource: RESOURCE_LIST.AGENCY_DETAIL,
        permission: ACTIONS_LIST.REMOVE,
        icon: "delete",
        passingData: (item) => {
          return ['delete', [item?.id]];
        },
      },
    ];
  }
  /**
   * handleRedirectEdit
   * @return  {redirect}
   */
  handleRedirectEdit = (id) => {
    this.props.history?.push("/agencies/" + id);
  };
  /**
   * renderFilterComponent
   * this function return the filter box
   * @return  {Component}
   */
  renderFilterComponent = () => {
    const { t } = this.props;
    return (
      <div>
        <Col span={4}>
          <Tooltip placement="top" title={t("agency.search_tooltip")}>
            <Input
              maxLength={255}
              defaultValue={this.currentStore?.filterData?.text}
              onChange={(e) =>
                this.currentStore?.handleFilterDataChange(
                  "text",
                  e.target?.value
                )
              }
              placeholder={t("agency.search_placholer")}
              onPressEnter={this.currentStore?.handleFilerAction}
            />
          </Tooltip>
        </Col>
        <Col span={2}>
          <Tooltip placement="top" title={t("agency.status_tooltip")}>
            <Select
              onChange={(value) =>
                this.currentStore?.handleFilterDataChange("status", value)
              }
              defaultValue={this.currentStore?.filterData?.status}>
              <Option value="null">{t("user.all_status")}</Option>
              {DEFAULT_STATUS_LIST?.map((e) => (
                <Option key={e.key} value={e.key}>
                  {t(e.value)}
                </Option>
              ))}
            </Select>
          </Tooltip>
        </Col>
      </div>
    );
  };

  /**
   * renderDetailData
   * this function return the agency detail box (ready for create action)
   * @return  {Component}
   */
  renderDetailData = () => {
    return (
      <AgencyDetailForm
        editable={this.currentStore?.formEditable}
        onClose={this.handleDrawerOnClose}
        childRef={(ref) => (this.defailFromRef = ref)}
      />
    );
  };

  componentDidMount = () => {
    const { t } = this.props;

    document.title = t("agency.page-title");
    super.componentDidMount();
    this.currentStore.clearSelectedItems();
  };

  /**
   * render
   * @return  {Component}
   */
  render() {
    return super.render();
  }
}

export default withRouter(withTranslation()(Agencies));
