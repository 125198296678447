import { inject, observer } from "mobx-react";
import { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import React from "react";
import { Row, Input, Col, InputNumber, Button, Select, Typography } from "antd";
import AntFieldmik from "components/AntFieldmik";
import { Field } from "formik";

const { Option } = Select;

/**
 * Component for showing details of the user.
 *
 * @component
 */
@inject("rootStore")
@observer
class FormCorridorDetail extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      t,
      toggleModal,
      id,
      rootStore: {
        commonStore: { isSuperAdmin },
        corridorStore: { allAgencies },
      },
    } = this.props;

    return (
      <>
        <Row gutter={16}>
          <Col lg={6} xl={8}>
            <AntFieldmik
              name="name"
              validate={(value) => {
                if (!value?.length)
                  return t("field_required", {
                    field: t("module_name", { module: t("Corridor") }),
                  });
              }}
              required
              label={t("module_name", {
                module: t("Corridor"),
              })}>
              {({ field, form: { setFieldValue, validateField } }) => {
                return (
                  <Input
                    size="large"
                    placeholder={t("module_name", {
                      module: t("Corridor"),
                    })}
                    {...field}
                    onChange={async (e) => {
                      setFieldValue("name", e.target.value);
                      await Promise.resolve();
                      validateField("name");
                    }}
                  />
                );
              }}
            </AntFieldmik>
          </Col>
          <Col lg={4} xl={4}>
            <AntFieldmik
              name="speed"
              validate={(value) => {
                if (!value && value !== 0)
                  return t("field_required", {
                    field: t("speed"),
                  });
                if (value < 0)
                  return t("min_value", {
                    field: t("corridor.speed"),
                    min: 0,
                  });
              }}
              required
              label={t("corridor.speed")}>
              {({
                field: { value },
                form: { values, setFieldValue, validateField },
              }) => {
                return (
                  <InputNumber
                    size="large"
                    className="w-full"
                    placeholder={t("speed")}
                    value={value}
                    onChange={async (value) => {
                      setFieldValue("speed", value);
                      setFieldValue(
                        "corridor_intersections",
                        values.corridor_intersections?.map((item) => ({
                          ...item,
                          speed: value,
                        }))
                      );
                      await Promise.resolve();
                      validateField("speed");
                    }}
                  />
                );
              }}
            </AntFieldmik>
          </Col>

          <Col lg={4} xl={4}>
            <AntFieldmik
              name="global_downstream_phase"
              validate={(value) => {
                if (!value && value !== 0)
                  return t("field_required", {
                    field: t("corridor.global_downstream"),
                  });
                if (value < 1 || value > 16)
                  return t("range_beetwen", {
                    field: t("corridor.global_downstream"),
                    min: 1,
                    max: 16,
                  });
              }}
              required
              label={t("corridor.global_downstream")}>
              {({
                field: { value },
                form: { setFieldValue, validateField },
              }) => {
                return (
                  <InputNumber
                    parser={(value) => value.replace(/[^0-9]/g, "")}
                    size="large"
                    className="w-full"
                    placeholder={t("corridor.global_downstream")}
                    value={value}
                    onChange={async (value) => {
                      setFieldValue("global_downstream_phase", value);
                      await Promise.resolve();
                      validateField("global_downstream_phase");
                    }}
                  />
                );
              }}
            </AntFieldmik>
          </Col>
          <Col lg={4} xl={4}>
            <AntFieldmik
              name="global_upstream_phase"
              validate={(value) => {
                if (!value && value !== 0)
                  return t("field_required", {
                    field: t("corridor.global_upstream"),
                  });
                if (value < 1 || value > 16)
                  return t("range_beetwen", {
                    field: t("corridor.global_upstream"),
                    min: 1,
                    max: 16,
                  });
              }}
              required
              label={t("corridor.global_upstream")}>
              {({
                field: { value },
                form: { setFieldValue, validateField },
              }) => {
                return (
                  <InputNumber
                    parser={(value) => value.replace(/[^0-9]/g, "")}
                    size="large"
                    className="w-full"
                    placeholder={t("corridor.global_upstream")}
                    value={value}
                    onChange={async (value) => {
                      setFieldValue("global_upstream_phase", value);
                      await Promise.resolve();
                      validateField("global_upstream_phase");
                    }}
                  />
                );
              }}
            </AntFieldmik>
          </Col>
          {isSuperAdmin() && (
            <Col lg={6} xl={4}>
              <AntFieldmik
                name="agency_id"
                validate={(value) => {
                  if (!value)
                    return t("field_required", {
                      field: t("agency"),
                    });
                }}
                required
                label={t("select_item", { item: t("agency") })}>
                {({
                  field: { value },
                  form: { setFieldValue, validateField },
                }) => {
                  return (
                    <Select
                      disabled={id ? true : false}
                      value={value}
                      size="large"
                      placeholder={t("select_item", {
                        item: t("agency"),
                      })}
                      onChange={async (value) => {
                        setFieldValue("agency_id", value);
                        setFieldValue("corridor_intersections", []);

                        await Promise.resolve();
                        validateField("agency_id");
                      }}>
                      {allAgencies?.map((e) => (
                        <Option key={e.id} value={e.id}>
                          {e.name}
                        </Option>
                      ))}
                    </Select>
                  );
                }}
              </AntFieldmik>
            </Col>
          )}
        </Row>
        <Row>
          <Col span={12}>
            <Field>
              {({ form: { errors } }) => {
                return (
                  <div className={"flex items-center w-full"}>
                    <Button icon="plus" size="large" onClick={toggleModal}>
                      {t("add_item", { item: t("intersection") })}
                    </Button>
                    <Typography.Text type="danger" className="ml-8">
                      {typeof errors?.corridor_intersections === "string"
                        ? errors?.corridor_intersections
                        : ""}
                    </Typography.Text>
                  </div>
                );
              }}
            </Field>
          </Col>
        </Row>
      </>
    );
  }
}

export default withRouter(withTranslation()(FormCorridorDetail));
