import React from "react";
import { Form, Input, Select } from "antd";
import { observer, inject } from "mobx-react";
import { withTranslation } from "react-i18next";
import BaseDetailPopupComponent from "../../../../../components/BaseDetailPopupComponent";

const { Option } = Select;
/**
 * Component for showing details of the user.
 *
 * @component
 */
@inject("rootStore")
@observer
class NewUser extends BaseDetailPopupComponent {
  /**
   * contructor of component
   * @param   {object} props  props of component
   */
  constructor(props) {
    super(props.store, props);
    this.formFields = ["email", "role_name"];
  }

  /**
   * componentDidMount
   *
   */
  componentDidMount = () => {
    const { childRef } = this.props;
    childRef && childRef(this);
    this.currentStore?.getAllRoles();
  };

  /**
   * render.
   *
   */
  renderDetailData(formData, getFieldDecorator) {
    const { t } = this.props;
    return (
      <div>
        <Form.Item label={t("user.email")}>
          {getFieldDecorator("email", {
            rules: [
              { type: "email", message: t("user.email_format") },
              { required: true, message: t("user.email_required") },
            ],
            initialValue: this.currentStore?.user?.email,
          })(<Input placeholder={t("user.email_placeholder")} />)}
        </Form.Item>
        {this.currentStore?.listRoles.length > 0 && (
          <Form.Item label={t("user.role")}>
            {getFieldDecorator("role_name", {
              rules: [{ required: true, message: t("user.role_required") }],
              initialValue: this.currentStore?.user?.role_name,
            })(
              <Select>
                {this.currentStore?.listRoles?.map((e) => (
                  <Option key={e.name} value={e.name}>
                    {e.name}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
        )}
      </div>
    );
  }
}

const NewUserForm = Form.create({ name: "new_user_form" })(NewUser);

export default withTranslation()(NewUserForm);
