const lightTheme = {
  'background': '#fff',
  'text': '#606060',
  'gray-text': '#909090',
  'button': '#009999',
  'error': '#E74C3C',
  'highlight': '#e9f6f9',
  'wrap-background': '#E5E8E8',
  'content-background': '#fff',
  'panel-background': '#E5E8E8',
  'border-color': '#ccc',
  'main-color': '#009999',
  'highlight-color': '#c3d9d5',
  'odd-color': '#FAFAFA',
  'logo-color': "#000"
}
const darkTheme = {
  'background': '#1d1e27',
  'text': '#adaeb4',
  'gray-text': '#adaeb4',
  'button': '#2874A6',
  'error': '#C0392B',
  'highlight': '#1A5276',
  'wrap-background': '#17171f',
  'content-background': '#1d1e27',
  'panel-background': '#000c17',
  'border-color': '#444444',
  'main-color': '#009999',
  'highlight-color': '#202927',
  'odd-color': '#21222e',
  'logo-color': "#fff"
}

export default {
  lightTheme,
  darkTheme
}
