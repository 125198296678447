import { inject, observer } from "mobx-react";
import { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import React from "react";
import { Drawer, Button } from "antd";
import AddMoreIntersection from "components/SelectIntersectionTab/AddMoreIntersection";
import { DETAIL_POPUP_WIDTH, RESOURCE_LIST } from "utils/constants";
import AvailableIntersectionStore from "stores/AvailableIntersectionStore";

/**
 * Component for showing details of the user.
 *
 * @component
 */
@inject("rootStore")
@observer
class FormCorridorDetail extends Component {
  constructor(props) {
    super(props);
    this.addMoreIntersectionStore = new AvailableIntersectionStore(
      props.rootStore,
      "corridors",
      RESOURCE_LIST.REPORT_TEMPLATE
    );
  }

  render() {
    const {
      t,
      form: {
        values: {
          corridor_intersections,
          agency_id,
          speed,
          global_downstream_phase,
          global_upstream_phase,
        },
        setFieldValue,
      },
      context_id,
      toggleModal,
      visible,
      rootStore: {
        commonStore: { isSuperAdmin },
      },
    } = this.props;
    if (isSuperAdmin()) {
      if (context_id) {
        this.addMoreIntersectionStore.handleFilterDataChange(
          "agency_id",
          agency_id,
          false
        );
      } else {
        this.addMoreIntersectionStore.handleFilterDataChange(
          "agency_id",
          agency_id,
          false
        );
      }
    } else {
      this.addMoreIntersectionStore.handleFilterDataChange(
        "agency_id",
        agency_id,
        false
      );
    }
    if (context_id) {
      this.addMoreIntersectionStore.setSelectedItem(corridor_intersections);
    }

    return (
      <Drawer
        width={DETAIL_POPUP_WIDTH}
        title={t("report_template.add_intersection_btn")}
        className="spm-detail-drawer"
        visible={visible}
        destroyOnClose
        onClose={toggleModal}>
        <AddMoreIntersection
          selectedItems={corridor_intersections}
          addMoreIntersectionStore={this.addMoreIntersectionStore}
          childRef={(ref) => {
            this.addMoreInterChild = ref;
          }}
          id={null}></AddMoreIntersection>
        <div className="wrap-footer-detail">
          <Button
            onClick={() => {
              const newList = this.addMoreIntersectionStore?.getSelectedItems();
              let newListFormItem = corridor_intersections.filter((el) => {
                return newList.map((child_e) => child_e?.id).includes(el.id);
              });

              newList.forEach((el) => {
                if (
                  !corridor_intersections
                    .map((child_e) => child_e?.id)
                    .includes(el.id)
                ) {
                  newListFormItem.push({
                    ...el,
                    id: el.id,
                    downstream: global_downstream_phase,
                    upstream: global_upstream_phase,
                    distance: 0,
                    speed: speed,
                  });
                }
              });
              setFieldValue("corridor_intersections", newListFormItem);
              toggleModal();
            }}>
            {t("report_template.add_intersections")}
          </Button>
        </div>
      </Drawer>
    );
  }
}

export default withRouter(withTranslation()(FormCorridorDetail));
