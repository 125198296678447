import { Col, DatePicker, Icon, Input, Select, Tooltip } from "antd";
import BaseManagementComponents from "components/BaseManagementComponents";
import StatusTag from "components/StatusTag";
import { inject, observer } from "mobx-react";
import moment from "moment";
import PropTypes from "prop-types";
import queryString from "query-string";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import {
  ACTIONS_LIST,
  ACTIVE,
  DATE_FORMAT,
  DEFAULT_STATUS_LIST,
  INACTIVE,
  RESOURCE_LIST,
  UNAVAILABLE,
  WEEK_DAY_OPTION,
} from "utils/constants";
import AlarmDetailPopup from "./AlarmDetailPopup";
import React from "react";
import "./style.scss";
import TriggerAlarmRulePopupComponent from "../../../components/TriggerAlarmRulePopupComponent";

const { Option } = Select;
const { RangePicker } = DatePicker;
const createUrl = "/alarms/rules/create";
@inject("rootStore")
@observer
class AlarmRule extends BaseManagementComponents {
  constructor(props) {
    super(props.rootStore.alarmRuleStore, props);
    const { t } = props;
    this.rowKey = "id";
    this.moduleName = "rules";
    this.resourceName = RESOURCE_LIST.ALARM_RULE;
    this.pageTitle = t("alarm_rules.alarm_rules");
    this.columns = this.getTableColumns();
    this.enableRedirectPage = true;
    (this.state = {
      expand: true,
    }),
      (this.buttonActions = [
        {
          key: "active_all",
          title: t("alarm_rules.active_all"),
          action: this.currentStore?.active,
          resource: RESOURCE_LIST.ALARM_RULE_ACTIVE,
          permission: ACTIONS_LIST.EDIT,
          type: "check",
          confirm: true,
          confirmTitle: t("alarm_rules.active_confirm_title"),
          confirmContent: t("alarm_rules.active_confirm_content"),
        },
        {
          key: "deactive_all",
          title: t("alarm_rules.deactive_all"),
          resource: RESOURCE_LIST.ALARM_RULE_INACTIVE,
          action: this.currentStore?.deActive,
          permission: ACTIONS_LIST.EDIT,
          type: "stop",
          confirm: true,
          confirmTitle: t("alarm_rules.inactive_confirm_title"),
          confirmContent: t("alarm_rules.inactive_confirm_content"),
        },
        {
          key: "subscribe_all",
          title: t("alarm_rules.subscribe_all"),
          resource: RESOURCE_LIST.ALARM_RULE_SUBCRIBE,
          permission: ACTIONS_LIST.EDIT,
          action: this.currentStore?.subscribe,
          confirm: true,
          type: "bell",
          confirmTitle: t("alarm_rules.subscribe_confirm_title"),
          confirmContent: t("alarm_rules.subscribe_confirm_content"),
        },
        {
          key: "unsubscribe_all",
          title: t("alarm_rules.unsubscribe_all"),
          type: "bell",
          resource: RESOURCE_LIST.ALARM_RULE_SUBCRIBE,
          permission: ACTIONS_LIST.EDIT,
          action: this.currentStore?.unsubscribe,
          confirm: true,
          confirmTitle: t("alarm_rules.unsubscribe_confirm_title"),
          confirmContent: t("alarm_rules.unsubscribe_confirm_content"),
        },
        {
          key: "subscribe_email",
          title: t("alarm_rules.subscribe_email_all"),
          type: "mail",
          resource: RESOURCE_LIST.ALARM_RULE_SUBCRIBE,
          permission: ACTIONS_LIST.EDIT,
          action: (id) =>
            this.currentStore?.subscribe(id, {
              email_receive: true,
              action: "SUBSCRIBE",
            }),
          confirm: true,
          confirmTitle: t("alarm_rules.subscribe_email_confirm_title"),
          confirmContent: t("alarm_rules.subscribe_email_confirm_content"),
        },
        {
          key: "unsubscribe_email",
          title: t("alarm_rules.unsubscribe_email_all"),
          type: "mail",
          resource: RESOURCE_LIST.ALARM_RULE_SUBCRIBE,
          permission: ACTIONS_LIST.EDIT,
          action: (id) =>
            this.currentStore?.subscribe(id, {
              email_receive: false,
              action: "SUBSCRIBE",
            }),
          confirm: true,
          confirmTitle: t("alarm_rules.unsubscribe_email_confirm_title"),
          confirmContent: t("alarm_rules.unsubscribe_email_confirm_content"),
        },
        {
          key: "delete_all",
          title: t("alarm_rules.delete_all"),
          type: "delete",
          resource: RESOURCE_LIST.ALARM_RULE,
          permission: ACTIONS_LIST.DELETE,
          action: this.currentStore?.delete,
          confirm: true,
          confirmTitle: t("alarm_rules.delete_confirm_title"),
          confirmContent: t("alarm_rules.delete_confirm_content"),
        },
      ]);
    this.tableInlineActions = [
      {
        key: "trigger",
        tooltip: t("alarm_rules.trigger"),
        action: this.currentStore?.openManualTriggerAlarmRulePopup,
        resource: RESOURCE_LIST.ALARM_RULE,
        permission: ACTIONS_LIST.EDIT,
        child: <Icon style={{ fontSize: "16px" }} type="play-square" />,
        passingData: (item) => {
          return [item];
        },
        disabled: (item) =>
          item.status === UNAVAILABLE || item.status === INACTIVE,
      },
      {
        key: "duplicate",
        tooltip: t("alarm_rules.duplicate"),
        action: this.handleDuplicate,
        resource: RESOURCE_LIST.ALARM_RULE,
        permission: ACTIONS_LIST.CREATE,
        icon: "copy",
        passingData: (item) => {
          return [item?.id];
        },
      },
      {
        key: "subscribe_all",
        tooltip: t("alarm_rules.subscribe"),
        action: this.handleTableAction,
        resource: RESOURCE_LIST.ALARM_RULE_SUBCRIBE,
        permission: ACTIONS_LIST.EDIT,
        child: <Icon style={{ fontSize: "16px" }} type="bell" />,
        passingData: (item) => {
          return ["subscribe_all", [item?.id]];
        },
        visible: (item) => {
          return !item?.is_subscribed;
        },
      },
      {
        key: "unsubscribe_all",
        tooltip: t("alarm_rules.unsubscribe"),
        action: this.handleTableAction,
        resource: RESOURCE_LIST.ALARM_RULE_SUBCRIBE,
        permission: ACTIONS_LIST.EDIT,
        child: <Icon style={{ fontSize: "16px" }} type="bell" theme="filled" />,
        passingData: (item) => {
          return ["unsubscribe_all", [item?.id]];
        },
        visible: (item) => {
          return item?.is_subscribed;
        },
      },
      {
        key: "subscribe_email",
        tooltip: t("alarm_rules.subscribe_email"),
        action: this.handleTableAction,
        resource: RESOURCE_LIST.ALARM_RULE_EMAIL,
        permission: ACTIONS_LIST.EDIT,
        child: <Icon style={{ fontSize: "16px" }} type="mail" />,
        passingData: (item) => {
          return ["subscribe_email", [item?.id]];
        },
        visible: (item) => {
          return !item?.email_receive;
        },
        notEnable: (item) => {
          return !item?.is_subscribed;
        },
      },
      {
        key: "unsubscribe_email",
        tooltip: t("alarm_rules.unsubscribe_email"),
        action: this.handleTableAction,
        resource: RESOURCE_LIST.ALARM_RULE_EMAIL,
        permission: ACTIONS_LIST.EDIT,
        child: <Icon style={{ fontSize: "16px" }} type="mail" theme="filled" />,
        passingData: (item) => {
          return ["unsubscribe_email", [item?.id]];
        },
        visible: (item) => {
          return item?.email_receive;
        },
        // notEnable: (item) => {
        //   return !item?.is_subscribed;
        // },
      },
      {
        key: "deactive_all",
        tooltip: t("alarm_rules.deactive"),
        action: this.handleTableAction,
        resource: RESOURCE_LIST.ALARM_RULE_INACTIVE,
        permission: ACTIONS_LIST.EDIT,
        icon: "stop",
        passingData: (item) => {
          return ["deactive_all", [item?.id]];
        },
        visible: (item) => {
          return item?.status === ACTIVE;
        },
      },
      {
        key: "active_all",
        tooltip: t("alarm_rules.active"),
        action: this.handleTableAction,
        resource: RESOURCE_LIST.ALARM_RULE_ACTIVE,
        permission: ACTIONS_LIST.EDIT,
        icon: "check",
        passingData: (item) => {
          return ["active_all", [item?.id]];
        },
        visible: (item) => {
          return item?.status !== ACTIVE;
        },
      },
      {
        key: "edit",
        tooltip: t("alarm_rules.edit"),
        resource: RESOURCE_LIST.ALARM_RULE_DETAIL,
        action: this.goToDetailPage,
        permission: ACTIONS_LIST.EDIT,
        icon: "edit",
        passingData: (item) => {
          return [item?.id];
        },
      },
      {
        key: "delete_all",
        tooltip: t("alarm_rules.delete"),
        action: this.handleTableAction,
        resource: RESOURCE_LIST.ALARM_RULE,
        permission: ACTIONS_LIST.DELETE,
        child: <Icon style={{ fontSize: "16px" }} type="delete" />,
        passingData: (item) => {
          return ["delete_all", [item?.id]];
        },
      },
    ];
  }

  componentDidMount = () => {
    const {
      t,
      rootStore: { commonStore },
    } = this.props;
    document.title = t("alarm_rules.page-title");
    if (this.props.location?.search?.length > 0) {
      let filterObject = queryString?.parse(this.props.location.search);
      this.currentStore?.setFilterData(filterObject);
    }
    this.fetchAlarmRuleByAgency(commonStore.isSuperAdmin());
    this.currentStore.clearSelectedItems();
  };

  goToCreatePage = () => {
    const {
      history,
      rootStore: { alarmRuleStore },
    } = this.props;
    alarmRuleStore.resetCreateRuleForm();
    history.push("/alarms/rules/create");
  };

  fetchAlarmRuleByAgency = (isSuperAdmin) => {
    this.currentStore?.setDetaultAgencyFilter(isSuperAdmin, () => {
      this.currentStore?.getData();
    });

    this.currentStore?.getAllRuleAnalysis();
    this.currentStore?.getAllUsers();
    // this.currentStore?.getAllIntersections();
    //  this.currentStore?.getAllRuleCategories();
  };

  getTableColumns = () => {
    const {
      t,
      rootStore: { myStore },
    } = this.props;
    return [
      {
        title: t("alarm_rules.name"),
        key: "name",
        ellipsis: true,
        render: (item) =>
          myStore.hasPermission(
            ACTIONS_LIST.VIEW,
            RESOURCE_LIST.ALARM_RULE_DETAIL,
            false,
            item.owner_id
          ) ? (
            <div>
              <a
                onClick={() =>
                  this.currentStore?.showCreateOrEditForm(
                    t("alarm_rules.show_form"),
                    item.id,
                    false
                  )
                }>
                {item.name}
              </a>
            </div>
          ) : (
            item.name
          ),
        width: "16%",
        sorter: true,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: t("alarm_rules.analysis_id"),
        key: "analysis_id",
        render: (item) => item?.analysis?.name,
        width: "8%",
      },
      {
        title: t("alarm_rules.category"),
        key: "category_id",
        ellipsis: true,
        render: (item) => t(item?.category?.name),
        width: "8%",
      },
      {
        title: t("alarm_rules.owner"),
        key: "owner",
        ellipsis: true,
        width: "8%",
        render: (item) => t(item?.owner?.name),
      },
      {
        title: t("alarm_rules.triggered_alarm"),
        key: "triggered_alarm",
        dataIndex: "no_triggered_alarms",
        ellipsis: true,
        width: "5%",
      },
      {
        title: t("alarm_rules.status"),
        dataIndex: "status",
        key: "status",
        render: (status) => <StatusTag status={status} />,
        width: 100,
      },
      {
        title: t("alarm_rules.weekdays"),
        dataIndex: "time_range",
        key: "time_range",
        render: (item) =>
          item?.week_days
            .map((e) => {
              return WEEK_DAY_OPTION.find((i) => i.value == e).label;
            })
            .join(", "),
      },

      {
        title: t("alarm_rules.created"),
        dataIndex: "created_at",
        key: "created_at",
        render: (created_at) => moment(created_at).format(DATE_FORMAT.full),
        sorter: true,
        sortDirections: ["descend", "ascend"],
      },
    ];
  };

  /**
   * renderDetailData
   * @return  {Component}
   */
  renderDetailData = () => {
    return (
      <AlarmDetailPopup
        childRef={(ref) => (this.defailFromRef = ref)}></AlarmDetailPopup>
    );
  };

  goToDetailPage = (id) => {
    const {
      history,
      rootStore: {
        alarmRuleStore: { setTemplateId },
      },
    } = this.props;
    id && setTemplateId(id);
    history.push("/alarms/rules/" + id);
  };

  handleDuplicate = (id) => {
    const { history } = this.props;
    history.push(createUrl + "?cloneid=" + id);
  };

  renderFilterComponent = () => {
    const {
      t,
      rootStore: { commonStore },
    } = this.props;
    const ruleCategories = this.currentStore?.allRuleCategories;
    const ruleAnalysis = this.currentStore?.allRuleAnalysis;
    const allAgencies = this.currentStore?.allAgencies;
    const allUsers = this.currentStore?.allUsers;

    return (
      <div className="wrap-filter">
        <Col lg={8} xl={4}>
          <Tooltip title={t("alarm_rules.filter_text")}>
            <Input
              value={this.currentStore?.filterData?.text}
              onChange={(e) => {
                this.currentStore?.handleFilterDataChange(
                  "text",
                  e?.target.value
                );
              }}
              placeholder={t("alarm_rules.search_placholer")}
            />
          </Tooltip>
        </Col>
        <Col lg={16} xl={5}>
          <Tooltip title={t("alarm_rules.filter_date")}>
            <RangePicker
              defaultValue={[
                this.currentStore?.filterData?.from_date &&
                  moment.unix(this.currentStore?.filterData?.from_date / 1000),
                this.currentStore?.filterData?.to_date &&
                  moment.unix(this.currentStore?.filterData?.to_date / 1000),
              ]}
              format={DATE_FORMAT.day_only}
              onChange={(e) => {
                this.currentStore.handleFilterDataChange(
                  "from_date",
                  e?.[0]?.startOf("day").utc().format("x"),
                  false
                );
                this.currentStore.handleFilterDataChange(
                  "to_date",
                  e?.[1]?.endOf("day").utc().format("x")
                );
              }}
            />
          </Tooltip>
        </Col>
        <Col lg={8} xl={4}>
          <Tooltip title={t("alarm_rules.filter_analysis")}>
            <Select
              onChange={(value) =>
                this.currentStore.handleFilterDataChange("analysis_id", value)
              }
              defaultValue={this.currentStore?.filterData?.analysis_id}>
              <Option value={null}>{t("alarm_rules.all_analysis_type")}</Option>
              {ruleAnalysis.map((e) => (
                <Option value={e?.id} key={e?.id}>
                  {e?.name}
                </Option>
              ))}
            </Select>
          </Tooltip>
        </Col>
        <Col lg={8} xl={4}>
          <Tooltip title={t("alarm_rules.filter_owner")}>
            <Select
              onChange={(value) =>
                this.currentStore.handleFilterDataChange("owner_id", value)
              }
              defaultValue={this.currentStore?.filterData?.owner_id}
              showSearch
              optionFilterProp="filter"
              filterOption={(input, option) => {
                return (
                  option?.props?.filter
                    ?.toLowerCase()
                    ?.indexOf(input?.toLowerCase()) >= 0
                );
              }}>
              <Option value={null}>{t("alarm_rules.all_owner")}</Option>
              {allUsers.map((e) => (
                <Option value={e?.id} key={e?.id} filter={e?.name}>
                  <b>{e?.name}</b> ({e?.email})
                </Option>
              ))}
            </Select>
          </Tooltip>
        </Col>
        <Col lg={8} xl={3}>
          <Tooltip title={t("alarm_rules.filter_category")}>
            <Select
              onChange={(value) =>
                this.currentStore.handleFilterDataChange("category_id", value)
              }
              defaultValue={this.currentStore?.filterData?.category_id}>
              <Option value={null}>{t("alarm_rules.all_categories")}</Option>
              {ruleCategories.map((e) => (
                <Option value={e?.id} key={e?.id}>
                  {t(e?.name)}
                </Option>
              ))}
            </Select>
          </Tooltip>
        </Col>
        {commonStore.isSuperAdmin() && (
          <Col lg={8} xl={3}>
            <Tooltip
              title={t("alarm_rules.filter_agency")}
              key={allAgencies.length && allAgencies?.[0]?.id}>
              {this.currentStore?.filterData.agency_id && (
                <Select
                  showSearch
                  onChange={(value) =>
                    this.currentStore.handleFilterDataChange("agency_id", value)
                  }
                  defaultValue={this.currentStore?.filterData.agency_id}
                  optionFilterProp="filter"
                  filterOption={(input, option) => {
                    return (
                      option?.props?.filter
                        ?.toLowerCase()
                        ?.indexOf(input?.toLowerCase()) >= 0
                    );
                  }}>
                  {allAgencies?.map((e) => (
                    <Option key={e?.id} value={e?.id} filter={e?.name}>
                      {e?.name}
                    </Option>
                  ))}
                </Select>
              )}
            </Tooltip>
          </Col>
        )}
        <Col
          lg={8}
          xl={4}
          style={{ display: this.state.expand ? "block" : "none" }}>
          <Tooltip placement="top" title={t("alarm_rules.filter_status")}>
            <Select
              onChange={(value) =>
                this.currentStore?.handleFilterDataChange("status", value)
              }
              defaultValue={this.currentStore?.filterData?.status}>
              <Option value={null}>{t("alarm_rules.all_status")}</Option>
              {DEFAULT_STATUS_LIST?.map((e) => (
                <Option key={e.key} value={e.key}>
                  {t(e.value)}
                </Option>
              ))}
            </Select>
          </Tooltip>
        </Col>
        <Col
          lg={16}
          xl={9}
          style={{ display: this.state.expand ? "block" : "none" }}>
          <Tooltip placement="top" title={t("alarm_rules.filter_by_weekday")}>
            <Select
              mode="multiple"
              allowClear={true}
              style={{ width: "100%" }}
              placeholder={t("alarm_rules.filter_by_weekday")}
              optionLabelProp="label"
              defaultValue={this.currentStore?.filterData?.week_days}
              onChange={(value) => {
                this.currentStore?.handleFilterDataChange("week_days", value);
              }}>
              {WEEK_DAY_OPTION?.map((e, id) => (
                <Option key={id} value={e.value} label={e.label}>
                  {e.label}
                </Option>
              ))}
            </Select>
          </Tooltip>
        </Col>
        <Col span={12} offset={24}>
          <a style={{ marginLeft: 8, fontSize: 12 }} onClick={this.toggle}>
            <b>
              {" "}
              {this.state.expand
                ? t("alarm_rules.collapse")
                : t("alarm_rules.expand")}{" "}
            </b>
            <Icon type={this.state.expand ? "up" : "down"} />
          </a>
        </Col>
      </div>
    );
  };
  toggle = () => {
    const { expand } = this.state;
    this.setState({ expand: !expand });
  };
  render() {
    return <div className="wrap-alarm-rule">{super.render()}</div>;
  }

  renderAdditionalInfo() {
    return (
      <TriggerAlarmRulePopupComponent
        visible={this.currentStore?.triggerAlarmRulePopup}
        alarm={this.currentStore?.manualTriggerAlarmRule}
        onSave={this.currentStore?.triggerAlarmRule}
        onCancel={this.currentStore?.closeManualTriggerAlarmRulePopup}
      />
    );
  }
}

AlarmRule.propTypes = {
  rootStore: PropTypes.any,
  history: PropTypes.any,
};

export default withRouter(withTranslation()(AlarmRule));
