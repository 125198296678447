import ChartDetail from "components/ChartDetail";
class ArrivalỎnGeen extends ChartDetail {
  constructor(props) {
    super(props);
    this.value_field = "to_time";
    this.data_field = "aog_bins";
    this.CHART_AXIS_SETTING = [
      {
        position: "left",
        key: "volume",
        label: "VPH",
        data: this.props.data.aog_bins,
        tick: true,
      },
      {
        position: "right",
        key: "aog_percent",
        label: "Percentage",
        data: this.props.data.aog_bins,
      },
    ];
  }

  componentDidMount = () => {
    super.componentDidMount();
  };
  /**
   * render
   *
   * @return  {Component}
   */
  render() {
    return super.render();
  }
}

export default ArrivalỎnGeen;
