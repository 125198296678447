import ChartDetail from "components/ChartDetail";
import * as d3 from "d3";
const LABEL_TEXT = 21;

class PreemptRequest extends ChartDetail {
  constructor(props) {
    super(props);
    this.value_field = "time";
    this.data_field = "preempt_requests";
    this.CHART_AXIS_SETTING = [
      {
        position: "left",
        key: "preempt_requests",
        label: "Preempt/TSP Number",
        tick: true,
        data: [
          ...this.props.data.preempt_requests,
          ...this.props.data.tsp_check_in_list,
        ],
        tickFormat: d3.format("d"),
      },
    ];
  }
  renderYaxisLineForChart = () => {
    let yAxisObjects = this.CHART_AXIS_SETTING;
    if (Array.isArray(yAxisObjects)) {
      yAxisObjects.forEach((item) => {
        //find the max value of target
        const maxValue = this.findMaxValueInObjectArray(item.data, item.key);
        const tickSize = maxValue * this.YAXIS_TICK_SPACE;
        let yScaleObject = d3
          .scaleLinear()
          .domain([maxValue + tickSize, 0]) //add extra space on top to make sure out data not reach the top
          .range([0, this.fullHeight]);
        let isLeftMode = item.position === "left";
        let yaxisObject = null;
        if (isLeftMode) {
          yaxisObject = d3.axisLeft(yScaleObject);
        } else {
          yaxisObject = d3.axisRight(yScaleObject);
        }
        if (item.tick) {
          const yAxisTicks = yScaleObject
            .ticks()
            .filter((tick) => Number.isInteger(tick));
          yaxisObject = yaxisObject
            .tickSize(-this.fullWidth)
            .tickValues(yAxisTicks)
            .tickFormat(item.tickFormat);
          // yaxisObject.call(yaxisObject.tickValues(yAxisTicks));
        }
        // yaxisObject;
        this.yScaleObject[item.position] = yScaleObject;
        this.yAxis[item.position] = this.chartObject
          .append("g")
          .attr("clip-path", "url(#clip-plan)")
          .attr(
            "transform",
            "translate(" +
              (this.ChartStyle.marginLeftRight +
                (isLeftMode ? 0 : this.fullWidth)) +
              "," +
              this.ChartStyle.marginTopBottom +
              ")"
          )
          .attr("class", "y-axis")
          .call(yaxisObject);
        console.log(this.props.data.phase, this, this.props.data);
        //NOTE: rule of x and y change when rotate what why we have complicated x, y calculation
        this.chartObject
          .append("text")
          .attr("transform", isLeftMode ? "rotate(-90)" : "rotate(90)")
          .attr("x", isLeftMode ? -(this.fullHeight / 2) : this.fullHeight / 2)
          .attr(
            "y",
            (isLeftMode
              ? 0
              : -(this.fullWidth + this.ChartStyle.marginLeftRight * 2)) +
              LABEL_TEXT
          )
          .attr("class", "y-axis-label")
          .style("fill", "var(--text)")
          .style("text-anchor", "middle")
          .style("font-size", "14px")
          // hard code
          .text(
            this.props.data.phase === "Preempt Request"
              ? "Preempt Number"
              : "TSP Number"
          );
      });
    }
    this.newYObject = this.yScaleObject;
  };
  renderHightLinePoint = (targetObject, x, y) => {
    const tootTipHeight =
      (targetObject.length + 2) * this.TooltipStyle.defaultChartHeight; //2 is space top/bottom
    let chartObject = d3.select(this.chartRef?.current);
    if (this.hightLineObject) {
      this.hightLineObject?.remove();
    }

    if (chartObject) {
      this.hightLineObject = chartObject
        .append("g")
        .attr("class", "hightline-group");
      const xTooltipPosition =
        x < this.fullWidth / 2
          ? this.ChartStyle.marginLeftRight + x + this.TooltipStyle.marginLeft
          : this.ChartStyle.marginLeftRight +
            x -
            this.TooltipStyle.marginLeft -
            this.TooltipStyle.width;
      const yTooltipPosition =
        y < this.fullHeight / 2
          ? y + tootTipHeight / 2 + this.TooltipStyle.marginTop
          : y - tootTipHeight + this.TooltipStyle.marginTop;
      this.hightLineObject
        .append("rect")
        .attr("width", this.TooltipStyle.width) //hard code the width of box
        .attr("height", tootTipHeight)
        .attr("class", "tooltip-box")
        .attr("stroke", "var(--text)")
        .attr("fill", "var(--background)")
        .attr(
          "transform",
          "translate(" + xTooltipPosition + "," + yTooltipPosition + ")"
        );
      //render hightline point

      // let labelHeight = yTooltipPosition;
      let labelIndex = 1.5;
      //add default text of time
      this.hightLineObject
        .append("text")
        .text(
          "Input Active: " +
            new Date(targetObject[0]?.data?.time)?.toLocaleString("en-US", {
              hour: "numeric",
              minute: "numeric",
              second: "numeric",
              hour12: true,
            })
        ) //@TODO:hard code here
        .attr("text-anchor", "middle")
        .attr("class", "tooltip-info-text")
        .attr(
          "transform",
          "translate(" +
            (xTooltipPosition + this.TooltipStyle.width / 2) +
            "," +
            (yTooltipPosition +
              this.TooltipStyle.defaultChartHeight * labelIndex) +
            ")"
        )
        .attr("fill", "var(--text)");
      labelIndex += 1;
      targetObject.forEach((item) => {
        //find the target point setting
        const setting = this.chartLegend.find((e) => e.key === item.key);
        //find y posistion of point
        //get point value
        const pointValue = item.data.value;
        //get point position
        const yPosistion = this.newYObject[setting.yAxis](pointValue);
        this.hightLineObject
          .append("circle")
          .attr("r", this.HIGHT_LINE_POINT)
          .attr("fill", setting.color)
          .attr("class", "hight-line-point")
          .attr(
            "transform",
            "translate(" +
              (this.ChartStyle.marginLeftRight + x) +
              "," +
              (yPosistion + this.ChartStyle.marginTopBottom) +
              ")"
          )
          .on("mouseout", () => {
            this.handleMouseOut();
          });

        this.hightLineObject
          .append("text")
          .text(
            this.renderTooltipText(
              setting.label,
              setting.unit,
              setting.toolTip(pointValue)
            )
          ) //the way we show tooltip defined on ANALYSIS_PLAN_INFO in constant file
          .attr("text-anchor", "middle")
          .attr("class", "tooltip-info-text")
          .attr(
            "transform",
            "translate(" +
              (xTooltipPosition + this.TooltipStyle.width / 2) +
              "," +
              (yTooltipPosition +
                this.TooltipStyle.defaultChartHeight * labelIndex) +
              ")"
          )
          .attr("fill", setting.color);
        labelIndex += 1;
      });

      //render hight-line infor box
    }
  };
  updateChart = (xObj, yObj) => {
    const { onChartZoom } = this.props;
    this.newX = xObj;
    this.newYObject = yObj;
    for (var id in yObj) {
      const yAxisTicks = yObj[id]
        .ticks()
        .filter((tick) => Number.isInteger(tick));

      if (id == "left") {
        this.yAxis[id].call(
          d3
            .axisLeft(yObj[id])
            .tickValues(yAxisTicks)
            .tickSize(-this.fullWidth)
            .tickFormat(d3.format("d"))
        );
      } else {
        this.yAxis[id].call(d3.axisRight(yObj[id]));
      }
    }
    for (var id in this.availableLineData) {
      this.availableLineData[id].x = this.newX;
      this.availableLineData[id].y =
        this.newYObject[this.availableLineData[id].yAxis];
    }
    this.xAxis.call(d3.axisBottom(this.newX).tickSize(-this.fullHeight));
    onChartZoom && onChartZoom(this.newX);
    this.chartObject.selectAll(".backgournd-group").remove();
    this.chartObject.selectAll(".lines-chart-group").remove();
    this.chartObject.selectAll(".gapdata-group").remove();
    this.renderChartBackgroundColor(this.planData);
    this.renderGapDataForChart(this.props.gapdata);
    this.handleMouseOut();
    this.renderChartData();
  };
  /**
   * findMaxValueInObjectArray
   *
   * @param {Array} array  data to find the max value
   * @param {String} key key of compare field
   * @param {Object} defaultValue defaultValue
   */
  findMaxValueInObjectArray = (array) => {
    let maxValue = 0;
    if (Array.isArray(array)) {
      array.forEach((item) => {
        maxValue = maxValue > item.number ? maxValue : item.number;
      });
    }
    return maxValue;
  };

  getDataObject = (_chartData, legend) => {
    const { from_time, to_time, data } = this.props;
    const allData = data[legend.key];
    let lineData = [];
    allData.forEach((item) => {
      if (
        from_time.getTime() <= new Date(item[this.value_field]).getTime() &&
        new Date(item[this.value_field]).getTime() <= to_time.getTime()
      )
        lineData.push({
          time: item.time,
          value: item.number,
        });
    });

    return lineData;
  };

  /**
   * render
   *
   * @return  {Component}
   */
  render() {
    return super.render();
  }
}

export default PreemptRequest;
