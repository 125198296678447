import React, {Component} from "react";
import {Checkbox, Col, Row} from "antd";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import "./style.scss";
import {withTranslation} from "react-i18next";

class LicenseWidgetLayout extends Component {
    static defaultProps = {
        name: "",
        image: "/svg/logo.svg",
        description: "",
    };

    constructor(props) {
        super(props);
    }


    render() {
        const {image, name, description, checked, onChange, disabled} = this.props;
        return (
            <div
                className={
                    `grid-widget-license 
                    ${disabled ? "grid-widget-disabled-license" : ""} 
                    ${checked ? "highlight-license" : ""}`
                }
            >
                <div className="header-card">
                    <Row style={{height: "100%"}}>
                        <Col span={10} style={{height: "100%"}}>
                            <img src={image} alt={name}/>
                        </Col>
                        <Col span={14}>
                            <div className="title-license">{name}</div>
                        </Col>
                    </Row>
                </div>
                <div className="description-license">
                    <p>{description}</p>
                </div>
                <Checkbox
                    className="w-full check-box-license"
                    style={{marginLeft: "8px"}}
                    checked={checked}
                    onChange={onChange}
                />
            </div>
        );
    }
}

export default withTranslation()(LicenseWidgetLayout);
