import ChartDetail from "components/ChartDetail";

const EVENT_DATA_MAPPER = {
  green: 21,
  yellow: 22,
  red: 23,
};
const ITEM_KEY = {
  PED_CHANGES: "ped_changes",
  DET_ACTS: "det_acts",
};
class PedestrianActivitiesChart extends ChartDetail {
  constructor(props) {
    super(props);
    this.value_field = "datetime";
    this.data_field = "ped_delays";
    this.CHART_AXIS_SETTING = [
      {
        position: "left",
        key: "phase_time",
        label: "Phase Duration",
        tick: true,
        data: [...this.props.data?.ped_changes, ...this.props.data?.det_acts],
      },
    ];
  }

  getDataObject = (chartData, item) => {
    const { from_time, to_time, data } = this.props;
    let allData = chartData;
    let result = [];
    let checkEvent = true;
    if (item.key === ITEM_KEY.DET_ACTS) {
      allData = data.det_acts;
      checkEvent = false;
    } else allData = data.ped_changes;

    let availableData = allData.filter((e) =>
      checkEvent
        ? EVENT_DATA_MAPPER[item.key] === e.event &&
          from_time.getTime() <= new Date(e[this.value_field]).getTime() &&
          new Date(e[this.value_field]).getTime() <= to_time.getTime()
        : from_time.getTime() <= new Date(e[this.value_field]).getTime() &&
          new Date(e[this.value_field]).getTime() <= to_time.getTime()
    );
    result = availableData.map((e) => {
      return {
        time: e.datetime,
        value: e.phase_time,
      };
    });

    return result;
  };

  /**
   * render
   *
   * @return  {Component}
   */
  componentDidMount = () => {
    super.componentDidMount();
  };
  render() {
    return super.render();
  }
}

export default PedestrianActivitiesChart;
