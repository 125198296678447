import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { withTranslation } from "react-i18next";
import { Form, Timeline, Avatar, Card } from "antd";
import helper from "utils/helper";
import { WEEK_DAY_OPTION, AGGREGATION_UNIT } from "utils/constants";

@inject("rootStore")
@observer
class ReportDetall extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const {
      t,
      rootStore: { reportResultStore },
    } = this.props;
    const dataCover = reportResultStore.reportDetail;
    return (
      <Card
        title={
          <div style={{ textAlign: "center", fontSize: "20px" }}>
            {t("report_result.report_detail_title")}
          </div>
        }
        bordered={false}
        loading={reportResultStore.loading}
        className="report-result-card"
      >
        {dataCover?.created_at && (
          <Form className="report-detail-form">
            <Form.Item label={t("report_result.report_name")}>
              {dataCover?.name}
            </Form.Item>
            <Form.Item label={t("report_result.time_zone")}>
              {dataCover?.time_zone}
            </Form.Item>
            <Form.Item label={t("report_result.time_range")}>
              {dataCover?.from_time}
              {" - "}
              {dataCover?.to_time}
            </Form.Item>
            <Form.Item label={t("report_result.date_range")}>
              <Timeline className="report-time-line">
                <Timeline.Item>{dataCover?.from_date}</Timeline.Item>
                <Timeline.Item> {dataCover?.to_date}</Timeline.Item>
              </Timeline>
            </Form.Item>
            <Form.Item label={t("report_result.aggregation")}>
              {t(
                AGGREGATION_UNIT.find((e) => e.value === dataCover?.aggregation)
                  ?.text
              )}
            </Form.Item>
            <Form.Item label={t("report_result.day_of_week")}>
              {dataCover?.week_days
                .map((e) => {
                  return WEEK_DAY_OPTION.find((i) => i.value == e).label;
                })
                .join(", ")}
            </Form.Item>
            <Form.Item label={t("report_result.created_at")}>
              {dataCover?.created_at}
            </Form.Item>
            <Form.Item label={t("report_result.owner")}>
              {dataCover?.owner?.name}
            </Form.Item>
            <Form.Item label={t("report_result.agency")}>
              <div className="report-detail-agency">
                <div className="agency-item">
                  <Avatar style={{ backgroundColor: helper.getRandomColor() }}>
                    {helper
                      .getFirstsLetterFromWords(dataCover?.agency_name)
                      ?.substring(0, 2)}
                  </Avatar>
                  <span className="agency-name">{dataCover?.agency_name}</span>
                </div>
              </div>
            </Form.Item>
            <Form.Item
              style={{ display: "flex" }}
              label={t("report_result.selected_intersection")}
            >
              <span style={{ fontSize: "20px" }}>
                {reportResultStore.resultData?.length}
              </span>
            </Form.Item>
          </Form>
        )}
      </Card>
    );
  }
}

export default withTranslation()(ReportDetall);
