import React, { Component } from "react";
import { withRouter } from "react-router";
import { observer, inject } from "mobx-react";
import moment from "moment";
import { Select, Form, Row, Col, Input, TimePicker, DatePicker } from "antd";
import { Map, Marker, TileLayer } from "react-leaflet";
import { withTranslation } from "react-i18next";

import "./style.scss";
import { DATE_FORMAT, DEFAULT_MAP_CENTER, SELECTED_PIN } from "utils/constants";
import { TIMEZONES } from "../../../../utils/timezone";

@inject("rootStore")
@observer
class IntersectionConfigGeneral extends Component {
  /**
   * contructor of component
   * @param   {object} props  props of component
   */
  constructor(props) {
    super(props);
    this.state = {};
  }

  /**
   * render
   *
   * @return  {Component}
   */
  render() {
    const {
      t,
      form: { getFieldDecorator },
      rootStore: {
        intersectionConfigStore: { currentConfig },
      },
    } = this.props;

    const intersectionInfo = currentConfig?.IntersectionInfo;
    return (
      <div>
        <Form>
          <Row className="general-info-form">
            <Col xl={12} xs={24}>
              <Row>
                <Col xl={14} xs={12}>
                  <Form.Item label={t("intersection.name")}>
                    {getFieldDecorator("end_time", {
                      rules: [],
                      initialValue: intersectionInfo?.Name,
                      getValueFromEvent: (e) => {
                        return e;
                      },
                    })(<Input size="large" disabled />)}
                  </Form.Item>
                </Col>
                <Col xl={8} lg={12} xs={12}>
                  <Form.Item label={t("intersection.model")}>
                    {getFieldDecorator("end_time", {
                      rules: [],
                      initialValue: intersectionInfo?.Model,
                      getValueFromEvent: (e) => {
                        return e;
                      },
                    })(<Input size="large" disabled className="short-input" />)}
                  </Form.Item>
                </Col>
                <Col xl={7} lg={12} xs={12}>
                  <Form.Item label={t("intersection.time_written")}>
                    {getFieldDecorator("written_time", {
                      rules: [],
                      initialValue: intersectionInfo?.DateWritten
                        ? moment(
                            intersectionInfo?.DateWritten,
                            DATE_FORMAT.backend
                          )
                        : null,
                      getValueFromEvent: (e) => {
                        return e;
                      },
                    })(<TimePicker size="large" disabled />)}
                  </Form.Item>
                </Col>
                <Col xl={7} lg={12} xs={12}>
                  <Form.Item label={t("intersection.date_written")}>
                    {getFieldDecorator("written_date", {
                      rules: [],
                      initialValue: intersectionInfo?.DateWritten
                        ? moment(
                            intersectionInfo?.DateWritten,
                            DATE_FORMAT.backend
                          )
                        : null,
                      getValueFromEvent: (e) => {
                        return e;
                      },
                    })(<DatePicker size="large" disabled />)}
                  </Form.Item>
                </Col>
                <Col xl={8} lg={12} xs={12}>
                  <Form.Item label={t("intersection.version")}>
                    {getFieldDecorator("version", {
                      rules: [],
                      initialValue: intersectionInfo?.Version,
                      getValueFromEvent: (e) => {
                        return e;
                      },
                    })(<Input size="large" disabled className="short-input" />)}
                  </Form.Item>
                </Col>
                <Col xl={14} xs={12}>
                  <Form.Item label={t("intersection.timezone")}>
                    {getFieldDecorator("version", {
                      rules: [],
                      initialValue: intersectionInfo?.timezone,
                      getValueFromEvent: (e) => {
                        return e;
                      },
                    })(
                      <Select className="timezone" disabled size="large">
                        {TIMEZONES.map((e) => (
                          <Select.Option value={e?.value} key={e?.value}>
                            {e?.label}
                          </Select.Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col xl={12} xs={24}>
              <Row>
                <Col xl={8} xs={12}>
                  <Form.Item label={t("agency.latitude")}>
                    {getFieldDecorator("end_time", {
                      rules: [],
                      initialValue: intersectionInfo?.location?.lat,
                      getValueFromEvent: (e) => {
                        return e;
                      },
                    })(<Input size="large" disabled />)}
                  </Form.Item>
                </Col>
                <Col xl={8} xs={12}>
                  <Form.Item label={t("agency.longitude")}>
                    {getFieldDecorator("end_time", {
                      rules: [],
                      initialValue: intersectionInfo?.location?.lng,
                      getValueFromEvent: (e) => {
                        return e;
                      },
                    })(<Input size="large" disabled />)}
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Map
                    style={{ height: "400px", width: "100%" }}
                    center={
                      intersectionInfo
                        ? [
                            intersectionInfo?.location?.lat,
                            intersectionInfo?.location?.lng,
                          ]
                        : DEFAULT_MAP_CENTER
                    }
                    zoom={12}
                  >
                    <TileLayer
                      url={process.env.REACT_APP_OPEN_STREET_MAP}
                      attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    />
                    {intersectionInfo && (
                      <Marker
                        icon={SELECTED_PIN}
                        position={[
                          intersectionInfo?.location?.lat,
                          intersectionInfo?.location?.lng,
                        ]}
                      ></Marker>
                    )}
                  </Map>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

const WrapForm = Form.create({ name: "intersection_config" })(
  IntersectionConfigGeneral
);
export default withRouter(withTranslation()(WrapForm));
