import React, { Component } from "react";
import { CHART_COLOR_SCHEMA } from "utils/constants/colorSchema";
import { Badge } from "antd";
import { withTranslation } from "react-i18next";

const BADGE_COLORS = {
  GAP_OUT: CHART_COLOR_SCHEMA.GREEN,
  MAX_OUT: CHART_COLOR_SCHEMA.RED,
  FORCE_OFF: CHART_COLOR_SCHEMA.LOWER_GREEN,
};

class SplitMonitorEvents extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { t } = this.props;

    return (
      <div
        className="chart-legend flex justify-end"
        style={{ padding: "20px 0px" }}>
        <div>
          <Badge
            className="border-circle mx-8"
            color={BADGE_COLORS.GAP_OUT}
            text={t("analysis.gap_out")}
          />
          <Badge
            className="border-circle mx-8"
            color={BADGE_COLORS.MAX_OUT}
            text={t("analysis.max_out")}
          />
          <Badge
            className="border-circle mx-8"
            color={BADGE_COLORS.FORCE_OFF}
            text={t("analysis.force_off")}
          />
        </div>
      </div>
    );
  }
}

export default withTranslation()(SplitMonitorEvents);
