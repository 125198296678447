import BaseApi from "../BaseApi";
/**
 * Service for user management.
 *
 * @class
 */
class SummaryTableService extends BaseApi {
  constructor(parentStore) {
    super("tsp/summary-events", parentStore);
    this.moduleName = "tsp/summary-events";
    this.dataKey = "tsp_events";
    this.keyField = "id";
  }
}

export default SummaryTableService;
