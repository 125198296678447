import React, { Component } from "react";
import { Form, Input, Button, Row, Col } from "antd";
import { observer, inject } from "mobx-react";
import { withTranslation } from "react-i18next";
import { USER_KEY_ACTION } from "utils/constants";
import "./style.scss";

@inject("rootStore")
@observer
class UserKeyArea extends Component {
  /**
   * handleCancelRequest
   * set requestConfirm to hide input password form
   * @return  {Object}
   */
  handleCancelRequest = () => {
    const {
      rootStore: { myStore },
    } = this.props;
    myStore?.updateUserKeyObj({ requestConfirm: false });
  };

  /**
   * handleRequestAction
   * set requestConfirm to show input password and set acction with target action
   * @return  {Object}
   */
  handleRequestAction = (action) => {
    const {
      rootStore: { myStore },
    } = this.props;
    myStore?.updateUserKeyObj({ requestConfirm: true, action });
  };

  /**
   * componentWillUnmount
   * reset the form to default - next time ask the password again
   * @return  {Object}
   */
  componentWillUnmount() {
    const {
      rootStore: { myStore },
    } = this.props;
    myStore?.cleanUserKeyObj();
  }
  /**
   * handleSubmit
   *
   * @return  {Object}
   */

  handleSubmit = () => {
    const {
      rootStore: { myStore },
    } = this.props;
    this.props?.form?.validateFields((err, values) => {
      if (!err) {
        let submitValue = {
          ...values,
        };
        myStore?.requestAccessUserKey(submitValue).catch((errors) => {
          this.setFormError(errors);
        });
        //     errors?.map(error => {
        //         let message = error?.message
        //         let err = {};
        //         err[error?.field] = {
        //             value: values[error?.field],
        //             errors: [new Error(message)],
        //         }
        //         this.props.form?.setFields(err);
        //     })
        // });
      }
    });
  };
  setFormError = (errors) => {
    errors?.map((e) => {
      const fieldObject = {};
      let fieldName = e.field;
      if (e.field === "user_key_expired") fieldName = "current_password";
      //specific for time_range case
      fieldObject[fieldName] = {
        errors: errors.filter((ei) => ei.field == e.field),
      };
      this.props.form.setFields(fieldObject);
    });
  };
  /**
   * render
   *
   * @return  {Component}
   */
  render() {
    const {
      form: { getFieldDecorator },
      rootStore: {
        myStore: { userKeyObj },
      },
      t,
    } = this.props;
    return (
      <div className="wrap-user-key-area">
        <Row>
          <Col span={24}>
            <Form>
              {userKeyObj?.requestConfirm ? (
                <Form.Item label={t("profile.confirm_password")}>
                  <Row gutter={8}>
                    <Col span={15}>
                      {getFieldDecorator("current_password", {
                        rules: [
                          {
                            required: true,
                            message: t("reset_pass.requried"),
                          },
                        ],
                      })(
                        <Input.Password
                          autoComplete="off"
                          maxLength={128}
                          placeholder={t(
                            "profile.current_password_placeholder"
                          )}
                        />
                      )}
                    </Col>
                    <Col span={4}>
                      <Button type="primary" onClick={this.handleSubmit}>
                        {t("profile.sent")}
                      </Button>
                    </Col>
                    <Col span={3}>
                      <Button onClick={this.handleCancelRequest}>
                        {t("profile.cancel")}
                      </Button>
                    </Col>
                  </Row>
                </Form.Item>
              ) : (
                <Form.Item label={t("profile.user_key")}>
                  <Row>
                    <Col span={24}>
                      <Input.Password
                        autoComplete="off"
                        placeholder={t("profile.user_key_placholer")}
                        readOnly
                        value={userKeyObj?.key}
                        disabled={userKeyObj?.needConfirm}
                      />
                    </Col>
                  </Row>
                </Form.Item>
              )}
            </Form>
          </Col>
          <Col span={12}>
            <Button
              type="link"
              icon="eye"
              disabled={userKeyObj?.requestConfirm || !userKeyObj?.needConfirm}
              onClick={() =>
                this.handleRequestAction(USER_KEY_ACTION.USER_KEY_VIEW)
              }
            >
              {t("common.get_key")}
            </Button>
          </Col>
          <Col span={12}>
            <Button
              type="link"
              icon="sync"
              disabled={userKeyObj?.requestConfirm}
              onClick={() =>
                this.handleRequestAction(USER_KEY_ACTION.USER_KEY_RENERW)
              }
            >
              {t("common.generate_key")}
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

const UserKeyTabForm = Form.create({ name: "normal_change_password_profile" })(
  UserKeyArea
);

export default withTranslation()(UserKeyTabForm);
