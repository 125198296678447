import React, { Component, createRef } from "react";
import { observer, inject } from "mobx-react";
import moment from "moment";
import { Button, Empty, Spin, Modal, Skeleton } from "antd";
import { withTranslation } from "react-i18next";
import {
  DATE_FORMAT,
  TIMEOUT_RESIZE,
} from "utils/constants";
import { withRouter } from "react-router";
import AbnormalDataTable from "./AbnormalDataTable";
import AbnormalDataChart from "./AbnormalDataChart";

@inject("rootStore")
@observer
class AbnormalAnalysisData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      resizing: false,
      chartVisible: false,
      chartIndex: null,
    };
    this.listRef = createRef();
    this.scrollHeight = 0;
  }
  componentDidMount() {
    window.addEventListener("resize", this.handleWindowResize);
    this.handleWindowResize();
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowResize);
  }
  handleWindowResize = () => {
    const {
      rootStore: {
        analysisScheduleResultStore: { printPReview },
      },
    } = this.props;
    //do not resize when in print preview cuz it will re render the PDF file => this is burnning RAM and TIME
    if (!printPReview) {
      this.setState({ resizing: true });
      setTimeout(() => {
        this.setState({ resizing: false });
      }, TIMEOUT_RESIZE);
    }
  };
  convertTimeFormat = (value) => {
    return moment(value, DATE_FORMAT.backend).format(
      DATE_FORMAT.analysis_range
    );
  };

  print = () => {
    // window.print();
    const {
      rootStore: {
        analysisScheduleResultStore: { togglePrintPReviewVisibility },
      },
    } = this.props;

    togglePrintPReviewVisibility();
  };

  render() {
    const {
      t,
      rootStore: {
        analysisScheduleResultStore: {
          loading,
          loadingChart,
          printPReview,
          togglePrintPReviewVisibility,
          reportData,
        },
      },
      history,
    } = this.props;

    return loading || loadingChart ? (
      <div className="bg-background px-8 py-8 h-full">
        <Skeleton />
        <Skeleton />
      </div>
    ) : reportData.chart_data.charts.length ? (
      <div className="wrap-analysis-result">
        <div className="wrap-analysis-header">
          <div className="analysis-header flex items-center py-8 px-8 bg-background">
            <div>
              <Button
                size={"large"}
                icon="arrow-left"
                onClick={() =>
                  history.push("/report/result/performance-metric")
                }>
                {t("detail.back")}
              </Button>
            </div>
            <div className="flex ">
              <div>
                {/* <Button icon="printer" size={"large"} onClick={this.print}>
                  {t("analysis.print-title")}
                </Button> */}
              </div>
            </div>
          </div>
          <div></div>
          <div className="filter-function">
            <div></div>
          </div>
        </div>
        <div className="analysis-body styled-scroll" style={{height: "100%", borderRadius: "5px"}}>
          <div className="wrap-chart-item" style={{height:"100%", padding: "30px", overflow:"scroll"}}>
            <div className="wrap-chart-detail">
              {(this.state.loading || this.state.resizing) ? (
                <Spin size="large" className={"centralize"} />
              ) : (
                <AbnormalDataTable 
                  data={reportData.chart_data.charts[0]}
                  setChartVisible={(chartIndex) => {this.setState({chartVisible: true, chartIndex: chartIndex})}}
                />
              )}
            </div>
          </div>
        </div>
        <Modal
          className="alarm-records-summary-modal"
          centered
          width={"80%"}
          title={t("print.modal-title")}
          visible={printPReview}
          onCancel={togglePrintPReviewVisibility}
          footer={null}
          destroyOnClose={true}>
          {/* <PrintSplitMonitor
            totalPage={totalPhase}
            getChartByIndex={getChartByIndex}
            printInfo={{
              ...chartInfo,
              chartType: currentChartTypeFrontEnd,
              totalLegend: ANALYSIS_LEGEND[currentChartTypeFrontEnd],
            }}
            printFilter={filterData}
            reporter={currentProfile?.name}
          /> */}
        </Modal>
        <Modal
          title={
            `${t("report_result.view_chart")} 
            - 
            ${(this.state.chartIndex!==null) && reportData.chart_data.charts[0].data[this.state.chartIndex].event_code}
            - 
            ${(this.state.chartIndex!==null) && reportData.chart_data.charts[0].data[this.state.chartIndex].event_name}`
          }
          visible={this.state.chartVisible}
          width="80vw"
          centered
          okButtonProps={{ display: "none" }}
          footer={[
            <Button
              key="back"
              onClick={() =>
                this.setState({
                  chartVisible: false,
                  chartIndex: null,
                })
              }>
              Close
            </Button>,
          ]}
          onCancel={() =>
            this.setState({
              chartVisible: false,
              chartIndex: null,
            })
          }>
          <div
            className="flex justify-center w-full"
            style={{ height: "70vh" }}>
            {(this.state.resizing) ? (
              <Spin size="large" />
            ) : (
              (this.state.chartIndex !== null) &&
              <AbnormalDataChart 
                data={reportData.chart_data.charts[0].data[this.state.chartIndex].chart}
                rawCounts={reportData.chart_data.charts[0].data[this.state.chartIndex].raw_counts}
                params={reportData.chart_data.charts[0].data[this.state.chartIndex].params}
                metadata={reportData.metadata}
              />
            )}
          </div>
        </Modal>
      </div>
    ) : (
      <>
        <div className="wrap-analysis-result">
          <div className="wrap-analysis-header">
            <div className="analysis-header flex items-center py-8 px-8 bg-background">
              <div>
                <Button
                  size={"large"}
                  icon="arrow-left"
                  onClick={() =>
                    history.push("/report/result/performance-metric")
                  }>
                  {t("detail.back")}
                </Button>
              </div>
            </div>
          </div>
          <div className="analysis-body body-normal styled-scroll flex items-center justify-center">
            <Empty></Empty>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(withTranslation()(AbnormalAnalysisData));
