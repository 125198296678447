import {
  SOCKET_KEEP_MESSAGE_INTERVAL,
  NOTIFICATION_TYPE,
  SOCKET_DELAY_RECONNECT,
  SOCKET_TOPIC,
} from "utils/constants";
import helper from "../utils/helper";
import i18n from "../i18n";
import SocketService from "../services/SocketService";
const CONNECTED_MESSAGE = "Connect!";
const KEEP_ALIVE_MESSAGE = "Keep connection!";
const MAX_TIME_RETRY = 3;

class SocketStore {
  constructor(parentStore) {
    this.parentStore = parentStore;
    this.socketService = new SocketService(parentStore);
  }

  socketObject = null;
  keepAliveInterval = null;
  tryToConnectAttempt = 0;
  foreStopSocket = false;
  connectionId = null;
  /**
   * connect
   * connect current session to socket server and listen message response
   *
   * @return   {null}
   */
  disconnect = () => {
    if (process.env.REACT_APP_SOCKET_DEBUG) {
      console.log("[Socket Debug] Active close to socket server");
    }
    if (this.socketObject) {
      clearInterval(this.keepAliveInterval);
      this.keepAliveInterval = null;
      this.foreStopSocket = true;
      this.socketObject.close();
      this.socketObject = null;
      this.tryToConnectAttempt = 0;
      this.connectionId = null;
    }
  };
  /**
   * connect
   * connect current session to socket server and listen message response
   *
   * @return   {null}
   */
  connect = () => {
    if (!this.socketObject) {
      if (process.env.REACT_APP_SOCKET_DEBUG) {
        console.log("[Socket Debug] connecting to socket server");
      }
      this.socketObject = new WebSocket(process.env.REACT_APP_SOCKET_ENDPOINT);
      this.tryToConnectAttempt++;
      this.socketObject.onclose = this.onSocketDisconnected;
      this.socketObject.onmessage = this.onReceivedMesssage;
      this.socketObject.onerror = this.onSocketError;
      this.socketObject.onopen = this.onSocketConnected;
    } else {
      if (process.env.REACT_APP_SOCKET_DEBUG) {
        console.log(
          "[Socket Debug] try to connect socket but connection is exited."
        );
      }
    }
  };

  /**
   * onSocketConnected
   * handler on socket connected
   *
   * send a message to socket to reveice socketID
   * start a interval auto send mesage to socket to keep connection alive
   *
   * @return   {null}
   */
  onSocketConnected = () => {
    this.tryToConnectAttempt = 0;
    if (process.env.REACT_APP_SOCKET_DEBUG) {
      console.log("[Socket Debug] socket connected.");
    }
    this.socketObject?.send(CONNECTED_MESSAGE);
    if (!this.keepAliveInterval) {
      this.keepAliveInterval = setInterval(() => {
        this.socketObject?.send(KEEP_ALIVE_MESSAGE);
      }, SOCKET_KEEP_MESSAGE_INTERVAL);
    }
  };

  /**
   * onSocketDisconnected
   * handler on socket disconnected
   *
   * retry connect 3 time
   * if fail to connect 3 time then notification to user
   *
   * @return   {null}
   */
  onSocketDisconnected = (socket) => {
    clearInterval(this.keepAliveInterval);
    this.keepAliveInterval = null;
    if (process.env.REACT_APP_SOCKET_DEBUG) {
      console.log("[Socket Debug] socket disconnected.", socket);
    }
    this.socketObject = null;
    if (this.tryToConnectAttempt < MAX_TIME_RETRY && !this.foreStopSocket) {
      if (process.env.REACT_APP_SOCKET_DEBUG) {
        console.log("[Socket Debug] try to re-connect.");
      }
      setTimeout(this.connect(), SOCKET_DELAY_RECONNECT);
    } else {
      if (!this.foreStopSocket) {
        helper.showNotification(
          NOTIFICATION_TYPE.ERROR,
          i18n.t("socket.error"),
          i18n.t("socket.connect_fail")
        );
      }
    }
  };

  /**
   * onSocketError
   * handler on socket error
   *
   * show error for user
   *
   * @return   {null}
   */

  onSocketError = (socket) => {
    if (process.env.REACT_APP_SOCKET_DEBUG) {
      console.log("[Socket Debug] socket error.", socket);
    }
    helper.showNotification(
      NOTIFICATION_TYPE.ERROR,
      i18n.t("socket.error"),
      i18n.t("socket.connect_error")
    );
  };

  subcribeTopic = async (listTopic) => {
    try {
      await this.socketService.subcribeTopic(this.connectionId, listTopic);
    } catch (error) {
      throw error;
    }
  };
  unsubcribeTopic = async (listTopic) => {
    try {
      await this.socketService.unsubcribeTopic(this.connectionId, listTopic);
    } catch (error) {
      throw error;
    }
  };
  /**
   * onReceivedMesssage
   * handler on socket recevied message
   *
   * if the body has connection id => send api map current user and connection id
   * if the body has notification => add new notificaiton to notidication store
   * else return not corrent format
   * show error for user
   *
   * @return   {null}
   */
  handleMessageData = (data) => {
    switch (data?.topic) {
      case SOCKET_TOPIC.NOTI_TOPIC.key: {
        this.parentStore?.unreadNotificationStore?.addNewNotification(
          data?.notification
        );
        break;
      }
      case SOCKET_TOPIC.TSP_EVENT_TOPIC.key: {
        this.parentStore?.TSPStore?.setCurrentNewEvent(data?.tsp_event);
        break;
      }
      default: {
        break;
      }
    }
  };
  onReceivedMesssage = async (message) => {
    if (process.env.REACT_APP_SOCKET_DEBUG) {
      console.log("[Socket Debug] socket received message.", message?.data);
    }
    // console.log(message.data)
    try {
      let messageData = JSON.parse(message?.data);
      if (messageData?.connection_id) {
        if (this.connectionId != messageData?.connection_id) {
          this.connectionId = messageData?.connection_id;
          let listTopic = [SOCKET_TOPIC.NOTI_TOPIC.key];
          if (
            this.parentStore.history.location.pathname ===
            SOCKET_TOPIC.TSP_EVENT_TOPIC.path
          )
            listTopic.push(SOCKET_TOPIC.TSP_EVENT_TOPIC.key);
          await this.subcribeTopic(listTopic);
        }
      } else {
        this.handleMessageData(messageData);
      }
    } catch (e) {
      console.log(e);
      helper.showNotification(
        NOTIFICATION_TYPE.WARNING,
        i18n.t("socket.warning"),
        i18n.t("socket.worng_fomat")
      );
    }
  };
}

export default SocketStore;
